import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value == null || typeof value !== 'number') {
      return null;
    }

    const kFactor = 1024;
    const mFactor = kFactor * kFactor;
    const gFactor = mFactor * kFactor;

    const gValue = value / gFactor;
    if (gValue > 1.1) {
      return `${gValue.toFixed(0)} Gb`;
    }

    const mValue = value / mFactor;
    if (mValue > 1.1) {
      return `${mValue.toFixed(0)} Mb`;
    }

    const kValue = value / kFactor;
    if (kValue > 1.1) {
      return `${kValue.toFixed(0)} Kb`;
    }

    return `${value.toFixed(0)} b`;
  }

}
