import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsCustomerContact} from '@lifeislife/lifeislife-ws-api';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {CustomerContactConverter} from './customer-contact.converter';
import {CustomerContact} from '../../domain/customer-contact/customer-contact';
import {CustomerContactSearch} from '../../domain/customer-contact/customer-contact-search';
import {Trustee} from '../../domain/trustee/trustee';
import {Ref} from '../../domain/shared/ref';
import {CustomerContactWsClient} from '../../client/resources/front/customer/customer-contact-ws-client';
import {HttpParams} from '@angular/common/http';
import {CustomerContactType} from '../../domain/customer-contact/customer-contact-type';
import {CustomerContactSearchConverter} from './customer-contact-search.converter';
import {CustomerContactTypeWsClient} from '../../client/resources/front/customer/customer-contact-type-ws-client';
import {CustomerContactTypeModel} from '../../domain/customer-contact/customer-contact-type-model';
import {CustomerContactTypeModelConverter} from './customer-contact-type-model.converter';

@Injectable({
  providedIn: 'root',
})
export class CustomerContactService {


  constructor(private client: CustomerContactWsClient,
              private contactTypeClient: CustomerContactTypeWsClient,
  ) {
  }

  getAllContactTypesModels$(): Observable<CustomerContactTypeModel[]> {
    return this.contactTypeClient.searchAll$().pipe(
      map(r => r.map(ws => CustomerContactTypeModelConverter.convertIn(ws))),
    );
  }

  getContactTypeModel$(type: CustomerContactType): Observable<CustomerContactTypeModel | null> {
    if (type == null) {
      return of(null);
    }
    return this.getAllContactTypesModels$().pipe(
      map(list => {
        const model = list.find(l => l.contactType === type);
        if (model) {
          return model;
        } else {
          // We should have a model for all our CustomerCOntactType when synced with server
          this.contactTypeClient.clearModelCache();
          return null;
        }
      }),
    );
  }

  getCustomerContact$(ref: Ref<CustomerContact>): Observable<CustomerContact> {
    return ref == null ? of(null) : this.client.doGet(ref.id).pipe(
      map(wsCustomerContact => CustomerContactConverter.convertIn(wsCustomerContact)),
    );
  }

  searchCustomerContacts$(customerContactSearch: CustomerContactSearch, pagination: SimplePagination)
    : Observable<SearchResult<CustomerContact>> {
    const wsSearch = CustomerContactSearchConverter.toWsCustomerContactSearch(customerContactSearch);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => CustomerContactConverter.convertSearchResultsIn$(results)),
    );
  }

  validateCustomerContact$(customerContact: CustomerContact): Observable<ValidationResult<CustomerContact>> {
    const wsCustomerContact = CustomerContactConverter.convertOut(customerContact);
    return this.client.doValidate(wsCustomerContact).pipe(
      map((results: WsCustomerContact | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveCustomerContact$(customerContact: CustomerContact): Observable<Ref<CustomerContact>> {
    const wsCustomerContact = CustomerContactConverter.convertOut(customerContact);
    return this.client.doSave(wsCustomerContact);
  }

  deleteCustomerContact$(id: number, force?: boolean): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        force: force ? 'true' : 'false',
      },
    });
    return this.client.doRemove(id, params);
  }


  createFilter(trusteeRef?: Ref<Trustee>): CustomerContactSearch {
    return {
      trusteeRef: trusteeRef,
      contactFilter: {
        trusteeRef: trusteeRef,
      },
    };
  }

  createNewCustomerContact(customerId: number, contactId: number): CustomerContact {
    return {
      contactRef: {id: contactId},
      customerRef: {id: customerId},
      customerContactType: CustomerContactType.OTHER,
      id: null,
      position: null,
    };
  }

  private createValidationResult(results: WsCustomerContact | WsConstraintViolation[]): ValidationResult<CustomerContact> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsCustomerContact => CustomerContactConverter.convertIn(wsCustomerContact),
      propertyNameMappings: CustomerContactConverter.getValidationPropertyNameMappings(),
    });
  }

}
