import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {User} from '../../domain/user/user';
import {LifeisLifeWsAuth} from '../../client/domain/auth/lifeis-life-ws-auth';
import {UserAuthService} from './user-auth.service';
import {AuthState} from './auth-state';
import {Role} from '../../domain/role/role';
import {ParamMap} from '@angular/router';

@Injectable()
export class UserAuthServiceNoop implements UserAuthService {

  state$: Observable<AuthState>;
  user$: Observable<User | null>;
  roles$: Observable<Role[]>;

  constructor() {
    this.state$ = of(new AuthState());
    this.user$ = of(null);
    this.roles$ = of([]);
  }

  restoreAuthIfRequired$(routeParams: ParamMap, role: Role.TRUSTEE | Role.CUSTOMER): Observable<AuthState> {
    return of(null);
  }


  authenticateBasic$(login: string, password: string, remember?: boolean): Observable<User> {
    return of(null);
  }

  attemptRestoreFromStorage$(): Observable<User | null> {
    return of(null);
  }

  attemptRestoreFromQueryString$(): Observable<User> {
    return of(null);
  }

  refreshState() {
  }

  deauthenticate(reason?: string, localLoggoff?: boolean) {
  }

  isAuthenticated() {
    return false;
  }

  getLoggedUserOrThrow(): User {
    throw new Error('Not logged in');
  }

  getNextUserOrNull$(): Observable<User | null> {
    return of(null);
  }

  authenticate$(auth: LifeisLifeWsAuth, showError: boolean,
                remember?: boolean): Observable<User> {
    return of(null);
  }

}
