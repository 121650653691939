import {WsContact, WsContactSearch, WsCustomerContactType, WsGender, WsTrusteeContactType} from '@lifeislife/lifeislife-ws-api';
import {Contact} from '../../domain/contact/contact';
import {ContactSearch} from '../../domain/contact/contact-search';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {TrusteeContactType} from '../../domain/trustee/trustee-contact-type';
import {CustomerContactType} from '../../domain/customer-contact/customer-contact-type';
import {CustomerSearchConverter} from '../customer/customer-search.converter';
import {TrusteeSearchConverter} from '../trustee/trustee-search.converter';

export class ContactConverter {

  static toContact(value: WsContact): Contact {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      version: value.version,
      company: value.company,
      lastName: value.lastName,
      firstName: value.firstName,
      phone1: value.phone1,
      phone2: value.phone2,
      mobile: value.mobile,
      fax: value.fax,
      email: value.email,
      zip: value.zip,
      city: value.city,
      address1: value.address1,
      address2: value.address2,
      domain: value.domain,
      internal: value.internal,
      personTitleRef: value.personTitleWsRef,
      sendEmail: value.sendEmail,
      sendSms: value.sendSms,
      sendWebpushNotification: value.sendWebpushNotification,
      birthPlace: value.birthPlace,
      birthDate: DateConverterUtils.parseIsoDate(value.birthDate),
      idExpiryDate: DateConverterUtils.parseIsoDate(value.idExpiryDate),
      idDeliveryDate: DateConverterUtils.parseIsoDate(value.idDeliveryDate),
      idCardNumber: value.idCardNumber,
      nationalRegisterNumber: value.nationalRegisterNumber,
      countryRef: value.countryRef,
      userRef: value.userRef,
      female: value.gender === WsGender.FEMALE,
      imported: value.imported,
      lastImportDateTime: DateConverterUtils.parseIsoDateTime(value.lastImportDateTime),
      thirdpartyRef: value.thirdPartyWsRef,
      dubiousTrusteeName: value.dubiousTrusteeName,
      dubiousMail: value.dubiousEmailAddress,
      jwtSubject: value.jwtSubject,
      keycloakUserName: value.keycloakUserName,
      maritalStatusRef: value.maritalStatusRef,
    };
  }

  static toWsContact(value: Contact): WsContact {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      version: value.version,
      company: value.company,
      lastName: value.lastName,
      firstName: value.firstName,
      phone1: value.phone1,
      phone2: value.phone2,
      mobile: value.mobile,
      fax: value.fax,
      email: value.email,
      zip: value.zip,
      city: value.city,
      address1: value.address1,
      address2: value.address2,
      domain: value.domain,
      internal: value.internal,
      personTitleWsRef: value.personTitleRef,
      sendEmail: value.sendEmail,
      sendSms: value.sendSms,
      sendWebpushNotification: value.sendWebpushNotification,
      birthPlace: value.birthPlace,
      birthDate: DateConverterUtils.formatDateToIsoDate(value.birthDate),
      idExpiryDate: DateConverterUtils.formatDateToIsoDate(value.idExpiryDate),
      idDeliveryDate: DateConverterUtils.formatDateToIsoDate(value.idDeliveryDate),
      idCardNumber: value.idCardNumber,
      nationalRegisterNumber: value.nationalRegisterNumber,
      countryRef: value.countryRef,
      userRef: value.userRef,
      gender: value.female ? WsGender.FEMALE : WsGender.MALE,
      imported: value.imported,
      lastImportDateTime: DateConverterUtils.formatDateToIsoDateTime(value.lastImportDateTime),
      thirdPartyWsRef: value.thirdpartyRef,
      dubiousTrusteeName: value.dubiousTrusteeName,
      dubiousEmailAddress: value.dubiousMail,
      jwtSubject: value.jwtSubject,
      keycloakUserName: value.keycloakUserName,
      maritalStatusRef: value.maritalStatusRef,
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsContact]: keyof Contact } {
    return {
      id: 'id',
      version: 'version',
      company: 'company',
      lastName: 'lastName',
      firstName: 'firstName',
      phone1: 'phone1',
      phone2: 'phone2',
      mobile: 'mobile',
      fax: 'fax',
      email: 'email',
      zip: 'zip',
      city: 'city',
      address1: 'address1',
      address2: 'address2',
      domain: 'domain',
      internal: 'internal',
      personTitleWsRef: 'personTitleRef',
      sendEmail: 'sendEmail',
      sendSms: 'sendSms',
      sendWebpushNotification: 'sendWebpushNotification',
      birthPlace: 'birthPlace',
      birthDate: 'birthDate',
      idExpiryDate: 'idExpiryDate',
      idDeliveryDate: 'idDeliveryDate',
      idCardNumber: 'idCardNumber',
      countryRef: 'countryRef',
      nationalRegisterNumber: 'nationalRegisterNumber',
      userRef: 'userRef',
      gender: 'female',
      imported: 'imported',
      lastImportDateTime: 'lastImportDateTime',
      thirdPartyWsRef: 'thirdpartyRef',
      dubiousEmailAddress: 'dubiousMail',
      dubiousTrusteeName: 'dubiousTrusteeName',
      jwtSubject: 'jwtSubject',
      keycloakUserName: 'keycloakUserName',
      maritalStatusRef: 'maritalStatusRef',
    };
  }

}
