<span [ngSwitch]="column.type"
      [class]="column.type"
      [title]="getTitle(column, row)"
>

    <span *ngSwitchCase="'ID'">
      {{ row.trusteeContact.id}}
    </span>

    <span *ngSwitchCase="'CONTACT_TYPE'">
      <app-trustee-contact-type [contactType]="row?.trusteeContact.trusteeContactType"
      ></app-trustee-contact-type>
    </span>

    <span *ngSwitchCase="'TRUSTEE_NAME'">
      <app-trustee [ref]="row?.trusteeContact.trusteeRef"
      ></app-trustee>
    </span>

    <span *ngSwitchCase="'CONTACT_NAME'">
      <app-contact-view [ref]="row?.trusteeContact.contactRef"></app-contact-view>
    </span>


    <span *ngSwitchCase="'ACTIONS'">
        <div class="actions">
            <a class="action delete"
               *ngIf="showDeleteAction"
               (click)="onDeleteClicked(row, $event)"
            >
                <i class="fa fa-times"
                   title="Supprimer"></i>
            </a>
        </div>
    </span>

    <span *ngSwitchDefault>
      {{ getTitle(column, row) }}
    </span>

</span>
