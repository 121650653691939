import {Injectable} from '@angular/core';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {AppConfigService} from '../../../service/config/app-config.service';
import {RequestService} from '../../service/request.service';
import {WsContact, WsDaoEventEntityType, WsLifeIsLifeUser, WsRef} from '@lifeislife/lifeislife-ws-api';
import {CachedWsResourceClient} from '../../private_util/client/cached-http-ws-Resource-client';
import {Observable} from 'rxjs';
import {ResourceCacheService} from '../../../util/cache/resource-cache.service';
import {WsRole} from '@lifeislife/lifeislife-ws-api';

@Injectable({
  providedIn: 'root',
})
export class UserWsClient extends CachedWsResourceClient<WsLifeIsLifeUser, WsLifeIsLifeUser> {

  constructor(private injectedRequestService: RequestService,
              private injectedAuthService: AuthProvider,
              private injectectedCacheService: ResourceCacheService,
              private injectedAppConfigService: AppConfigService) {
    super('/front/user', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.LIFEISLIFE_USER, injectectedCacheService);
  }

  findUserContact$(userId: number): Observable<WsRef<WsContact>> {
    const auth = this.injectedAuthService.getAuth();
    const url = `${this.getResourceUrl()}/${userId}/contact`;

    return this.injectedRequestService.sendRequest<WsRef<WsContact>>({
      method: 'GET', url: url,
    }, auth);
  }


  findUserRoles$(userId: number): Observable<WsRole[]> {
    const auth = this.injectedAuthService.getAuth();
    const url = `${this.getResourceUrl()}/${userId}/roles`;

    return this.injectedRequestService.sendRequest<WsRole[]>({
      method: 'GET', url: url,
    }, auth);
  }
}
