import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsContact, WsWebPushSubscriptionRequest} from '@lifeislife/lifeislife-ws-api';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {Contact} from '../../domain/contact/contact';
import {ContactSearch} from '../../domain/contact/contact-search';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {Trustee} from '../../domain/trustee/trustee';
import {Country} from '../../domain/country/country';
import {ContactWsClient} from '../../client/resources/front/contact/contact-ws-client';
import {WithId} from '../../client/domain/with-id';
import {ContactConverter} from './contact.converter';
import {ContactSearchConverter} from './contact-search.converter';
import {ContactClaimType} from '../../domain/contact/contact-claim-type';
import {ContactClaim} from '../../domain/contact/contact-claim';
import {ContactClaimConverter} from './contact-claim.converter';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {WsContactClaimType} from '@lifeislife/lifeislife-ws-api';

@Injectable({
  providedIn: 'root',
})
export class ContactService {


  constructor(private client: ContactWsClient,
  ) {
  }

  getContact$(ref: Ref<Contact>, forceFetch?: boolean): Observable<Contact> {
    if (ref == null) {
      return of(null);
    }

    return this.client.doGet(ref.id, forceFetch).pipe(
      map(wsContact => ContactConverter.toContact(wsContact)),
    );
  }

  getContactName(contact: Contact, lastnameFirst = false): string {
    if (contact == null || contact.id == null) {
      return 'Nouveau contact';
    }
    if (lastnameFirst) {
      return `${contact.lastName == null ? '' : contact.lastName} ${contact.firstName == null ? '' : contact.firstName}`;
    } else {
      return `${contact.firstName == null ? '' : contact.firstName} ${contact.lastName == null ? '' : contact.lastName}`;
    }
  }

  createNewContact(trusteeRef?: Ref<Trustee>, countryRef?: Ref<Country>): Contact {
    return {
      countryRef: countryRef,
      sendEmail: true,
      sendSms: true,
    } as Contact;
  }

  searchContacts$(contactFilter: ContactSearch, pagination: SimplePagination): Observable<SearchResult<Contact>> {
    const wsSearch = ContactSearchConverter.toWsContactSearch(contactFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateContact$(contact: Contact): Observable<ValidationResult<Contact>> {
    const wsContact = ContactConverter.toWsContact(contact);
    return this.client.doValidate(wsContact).pipe(
      map((results: WsContact | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveContact$(contact: Contact): Observable<WithId> {
    const wsContact = ContactConverter.toWsContact(contact);
    return this.client.doSave(wsContact);
  }

  deleteContact$(ref: Ref<Contact>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  mergeContact$(target: Contact, sourceRef: Ref<Contact>): Observable<Ref<Contact>> {
    const wsContact = ContactConverter.toWsContact(target);
    return this.client.mergeContact$(wsContact, sourceRef).pipe(
      tap(a => {
        this.client.doClearCache(sourceRef.id);
        this.client.doClearCache(target.id);
      }),
    );
  }

  getContactClaim$(ref: Ref<Contact>, type: ContactClaimType): Observable<ContactClaim> {
    const wsType = EnumConverterUtils.convertValue(type, WsContactClaimType);
    return this.client.getContactClaim$(ref, wsType).pipe(
      map(ws => ContactClaimConverter.toContactClaim(ws)),
    );
  }

  deleteContactClaim$(ref: Ref<Contact>, type: ContactClaimType): Observable<any> {
    const wsType = EnumConverterUtils.convertValue(type, WsContactClaimType);
    return this.client.deleteContactClaim$(ref, wsType);
  }

  registerWebPush$(ref: Ref<Contact>, webPUshRequest: PushSubscription, publicKey: string): Observable<Ref<Contact>> {
    const pushJson: WsWebPushSubscriptionRequest = webPUshRequest.toJSON() as any as WsWebPushSubscriptionRequest;
    pushJson.serverKey = publicKey;
    return this.client.registerWsPushRequest$(ref, pushJson);
  }


  createFilter(trusteeRef?: Ref<Trustee>): ContactSearch {
    return {
      trusteeRef: trusteeRef == null ? null : trusteeRef,
    };
  }

  getValidationPropertyNameMappings() {
    return ContactConverter.getValidationPropertyNameMappings();
  }

  private createValidationResult(results: WsContact | WsConstraintViolation[]): ValidationResult<Contact> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsContact => ContactConverter.toContact(wsContact),
      propertyNameMappings: ContactConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsContact>): Observable<SearchResult<Contact>> {
    return of(Object.assign({}, results, <Partial<SearchResult<Contact>>>{
      list: results.list.map(result => ContactConverter.toContact(result)),
    }));
  }
}
