/**
 * Created by cghislai on 18/04/16.
 */

export class SearchResult<T> {

  count: number;
  errored?: boolean;

  list: T[] = [];

  constructor(errored?: boolean) {
    this.errored = errored;
    this.count = 0;
  }

}
