import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {WsCustomerInviteCode, WsTrusteeInviteCode} from '@lifeislife/lifeislife-ws-api';
import {TrusteeInviteCode} from '../../domain/invite/trustee-invite-code';
import {CustomerInviteCode} from '../../domain/invite/customer-invite-code';

export class UserInviteCodeConverter {


  static convertTrusteeInviteCodeIn(value: WsTrusteeInviteCode): TrusteeInviteCode {
    if (value == null) {
      return undefined;
    }
    return {
      expirationTime: DateConverterUtils.parseIsoDateTime(value.expirationTime),
      code: value.code,
      trusteeContactRef: value.wsTrusteeContactWsRef,
    };
  }

  static convertTrusteeInviteOut(value: TrusteeInviteCode): WsTrusteeInviteCode {
    if (value == null) {
      return undefined;
    }
    return {
      expirationTime: DateConverterUtils.formatDateToIsoDateTime(value.expirationTime),
      code: value.code,
      wsTrusteeContactWsRef: value.trusteeContactRef,
    };
  }

  static getTrusteeValidationPropertyNameMappings(): { [M in keyof WsTrusteeInviteCode]: keyof TrusteeInviteCode } {
    return {
      expirationTime: 'expirationTime',
      code: 'code',
      wsTrusteeContactWsRef: 'trusteeContactRef',
    };
  }

  static convertCustomerInviteCodeIn(value: WsCustomerInviteCode): CustomerInviteCode {
    if (value == null) {
      return undefined;
    }
    return {
      expirationTime: DateConverterUtils.parseIsoDateTime(value.expirationTime),
      code: value.code,
      uri: value.uri,
      customerContactRef: value.wsCustomerContactWsRef,
      verificationCode: value.verificationCode,
    };
  }

  static convertCustomerInviteOut(value: CustomerInviteCode): WsCustomerInviteCode {
    if (value == null) {
      return undefined;
    }
    return {
      expirationTime: DateConverterUtils.formatDateToIsoDateTime(value.expirationTime),
      code: value.code,
      uri: value.uri,
      wsCustomerContactWsRef: value.customerContactRef,
      verificationCode: value.verificationCode,
    };
  }

  static getCustomerValidationPropertyNameMappings(): { [M in keyof WsCustomerInviteCode]: keyof CustomerInviteCode } {
    return {
      expirationTime: 'expirationTime',
      code: 'code',
      uri: 'uri',
      wsCustomerContactWsRef: 'customerContactRef',
      verificationCode: 'verificationCode',
    };
  }

}
