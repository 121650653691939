<div class="account-creation-route">

  <h1 i18n>Bienvenue sur LifeisLife</h1>

  <div *ngIf="inviteCodeExpired" class="expired" i18n>
    Cette invitation est périmée
  </div>

  <p>
    Veuillez choisir un identifiant pour pouvoir vous reconnecter et vérifiez vos informations.
  </p>

  <div class="token" hidden>
    {{ inviteToken$ | async | json }}
  </div>

  <form *ngIf="!inviteCodeExpired" #creationForm>
    <div class="user-form">
      <app-user-form [ngModel]="userFormHelper.editingValue$ | async"
                     (ngModelChange)="userFormHelper.updateEditingValue($event)"
                     [validationResults]="userFormHelper.validationResults$ | async"
                     [userSelectVisible]="false"
                     [showPassword]="true"
                     [(password)]="password"
                     name="user"
      ></app-user-form>
    </div>

    <div class="contact-form">
      <app-contact-form [ngModel]="contactFormHelper.editingValue$ | async"
                        (ngModelChange)="contactFormHelper.updateEditingValue($event)"
                        [validationResults]="contactFormHelper.validationResults$ | async"
                        [trusteeSelectVisible]="false"
                        [showSettingsBlock]="false"
                        [showAddressBlock]="false"
                        [showIdBlock]="false"
                        [showTelBlock]="false"
                        name="user-contact"
      ></app-contact-form>
    </div>

    <div class="actions">
      <input type="submit" hidden>
      <button type="button" pButton
              label="Enregistrer" i18n-label
              [disabled]="creationForm.invalid"
              (click)="onSubmit()"
      ></button>
    </div>
  </form>

</div>
