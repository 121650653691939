import {ApplicationMenuItemData} from '../main/routing/application-menu-item-data';
import {AppFeature} from '../main/model/app-feature';

export const CustomerApplicationMenuData: any & Record<string, ApplicationMenuItemData> = {

  login: {
    title: 'Connexion',
    skipMenu: true,
  },

  welcome: {
    title: 'LifeisLife',
    menuIcon: 'fa fa-home',
    menuLink: ['/welcome'],
  },

  insuranceSubscription: {
    title: `Assurances`,
    id: 'insurance-subscription',
    menuIcon: 'fa fa-life-ring',
    menuLink: ['/insuranceSubscription'],
    allAppFeatures: [AppFeature.insurance_subscription_list],
  },


  creditSubscription: {
    title: `Crédits`,
    id: 'credit-subscription',
    menuIcon: 'fa fa-credit-card',
    menuLink: ['/creditSubscription'],
    allAppFeatures: [AppFeature.credit_subscription_list],
  },

  investmentSubscription: {
    title: `Placements`,
    id: 'investment-subscription',
    menuIcon: 'fa fa-bank',
    menuLink: ['/investmentSubscription'],
    allAppFeatures: [AppFeature.investment_subscription_list],
  },
};
