import {Injectable} from '@angular/core';
import {NotificationConverter} from './notification.converter';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {ContactNotification} from '../../domain/notification/contact-notification';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {NotificationSearch} from '../../domain/notification/notification-search';
import {ValidationResult} from '../../domain/shared/validation-result';
import {WsConstraintViolation, WsNotification, WsRef, WsStoredFile} from '@lifeislife/lifeislife-ws-api';
import {ObjectConverterUtil} from '../object-converter-util';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {Ticket} from '../../domain/ticket/ticket';
import {NotificationWsClient} from '../../client/resources/front/notification-ws-client';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  constructor(private client: NotificationWsClient,
  ) {
  }


  getNotification$(ref?: Ref<ContactNotification>): Observable<ContactNotification> {
    return !isValidRef(ref) ? of(null) : this.client.doGet(ref.id).pipe(
      map(wsNotification => NotificationConverter.convertIn(wsNotification)),
    );
  }

  searchNotifications$(notificationFilter: NotificationSearch, pagination: SimplePagination)
    : Observable<SearchResult<ContactNotification>> {
    const wsSearch = NotificationConverter.convertFilterOut(notificationFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateNotification$(notification: ContactNotification): Observable<ValidationResult<ContactNotification>> {
    const wsNotification = NotificationConverter.convertOut(notification);
    return this.client.doValidate(wsNotification).pipe(
      map((results: WsNotification | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  acknowledgeNotification$(ref: Ref<ContactNotification>): Observable<any> {
    if (ref == null) {
      return throwError('Invalid ref');
    }
    return this.client.acknowledgeNotification$(ref);
  }

  getNotificationTicketRef$(ref: Ref<ContactNotification>): Observable<Ref<Ticket>> {
    if (ref == null) {
      return throwError('Invalid ref');
    }
    return this.client.getNotificationTicketRef$(ref).pipe(
      map(wsRef => wsRef as Ref<Ticket>),
    );
  }

  getNotificationFileRef$(ref: Ref<ContactNotification>): Observable<Ref<WsStoredFile>> {
    if (ref == null) {
      return throwError('Invalid ref');
    }
    return this.client.getNotificationFileRef$(ref);
  }

  serializeFilter(notificationFilter: NotificationSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(notificationFilter);
    const wsNotificationSearch = NotificationConverter.convertFilterOut(notificationFilter);
    return JSON.stringify(wsNotificationSearch);
  }


  deserializeFilter(valueString: string | null): NotificationSearch {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: any = JSON.parse(valueString);
      const filter = NotificationConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsNotification | WsConstraintViolation[]): ValidationResult<ContactNotification> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsNotification => NotificationConverter.convertIn(wsNotification),
      propertyNameMappings: NotificationConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsNotification>>): Observable<SearchResult<ContactNotification>> {
    const rowTasks = results.list.map(ref => this.getNotification$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<ContactNotification>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<ContactNotification>>>{
          list: rows,
        }),
      ));
  }
}
