import {WsTicketFile, WsTicketFileSearch} from '@lifeislife/lifeislife-ws-api';
import {TicketFile} from '../../domain/ticket/ticket-file';
import {StoredFileConverter} from '../file/stored-file.converter';
import {TicketFileSearch} from '../../domain/ticket/ticket-file-search';
import {TicketSearchConverter} from './ticket-search.converter';

export class TicketFileConverter {

  static convertIn(value: WsTicketFile): TicketFile {
    return {
      id: value.id,
      storedFileRef: value.storedFileWsRef,
      ticketRef: value.ticketWsRef,
    };
  }

  static convertOut(value: TicketFile): WsTicketFile {
    return {
      id: value.id,
      ticketWsRef: value.ticketRef,
      storedFileWsRef: value.storedFileRef,
    };
  }


  static convertFilterIn(value?: WsTicketFileSearch): TicketFileSearch {
    if (value == null) {
      return undefined;
    }
    return {
      storedFileSearch: StoredFileConverter.convertFilterIn(value.wsFileSearch),
      ticketSearch: TicketSearchConverter.convertFilterIn(value.wsTicketSearch),
    };
  }

  static convertFilterOut(value: TicketFileSearch): WsTicketFileSearch {
    if (value == null) {
      return undefined;
    }
    return {
      wsFileSearch: StoredFileConverter.convertFilterOut(value.storedFileSearch),
      wsTicketSearch: TicketSearchConverter.convertFilterOut(value.ticketSearch),
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsTicketFile]: keyof TicketFile } {
    return {
      id: 'id',
      storedFileWsRef: 'storedFileRef',
      ticketWsRef: 'ticketRef',
    };
  }

}
