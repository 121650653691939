import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {LifeislifeMessageType} from '../../domain/mail/lifeislife-message-type';
import {MessageTypeConverter} from './message-type.converter';
import {MessageTypeWsClient} from '../../client/resources/front/message-type-ws-client';
import {MessageFlavor} from '../../domain/mail/message-flavor';
import {MessageChannel} from '../../domain/mail/message-channel';

@Injectable({
  providedIn: 'root',
})
export class MessageTypeService {


  constructor(private client: MessageTypeWsClient,
  ) {
  }

  listMessageTypes$(skipCache?: boolean): Observable<LifeislifeMessageType[]> {
    return this.client.listMessageTypes$(skipCache).pipe(
      map(types => types.map(t => MessageTypeConverter.convertIn(t))),
    );
  }

  getMessageType(name: string): Observable<LifeislifeMessageType> {
    return this.client.getMessageType$(name).pipe(
      map(t => MessageTypeConverter.convertIn(t)),
    );
  }

  getFlavourLabel(flavour: MessageFlavor): string {
    if (flavour == null) {
      return null;
    }
    switch (flavour) {
      case MessageFlavor.SHORT_TEXT:
        return 'Texte court';
      case MessageFlavor.MAIL_PLAIN:
        return 'Mail texte';
      case MessageFlavor.MAIL_HTML:
        return 'Mail html';
    }
    return flavour as string;
  }

  getChannelLabel(channel: MessageChannel): string {
    if (channel == null) {
      return null;
    }
    switch (channel) {
      case MessageChannel.MAIL:
        return 'Mail';
      case MessageChannel.SMS:
        return 'Sms';
      case MessageChannel.WEBPUSH:
        return 'Notif push';
    }
    return channel as string;
  }
}
