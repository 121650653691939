import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrusteeContactTypeComponent} from './trustee-contact-type.component';


@NgModule({
  declarations: [TrusteeContactTypeComponent],
  exports: [TrusteeContactTypeComponent],
  imports: [
    CommonModule,
  ],
})
export class TrusteeContactTypeModule {
}
