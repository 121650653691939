import {Contact} from '../contact/contact';
import {Ref} from '../shared/ref';
import {ContactSearch} from '../contact/contact-search';
import {TrusteeStatus} from './trustee-status';
import {Trustee} from './trustee';
import {ObjectConverterUtil} from '../../service/object-converter-util';
import {TrusteeSearchConverter} from '../../service/trustee/trustee-search.converter';
import {WsTrusteeSearch} from '@lifeislife/lifeislife-ws-api';

export class TrusteeSearch {
  exactTrusteeRef?: Ref<Trustee>;
  name?: string;
  configKey?: string;

  nameContains?: string;
  relatedContactRef?: Ref<Contact>;
  contactSearch?: ContactSearch;

  vatNumberContains?: string;
  exactVatNumber?: string;
  exactLegalName?: string;
  webSiteContains?: string;

  minFeeRatio?: number;
  maxFeeRatio?: number;
  statusList?: TrusteeStatus[];

  minCreationDateTime?: Date;
  maxCreationDateTime?: Date;
  minUpdateDateTime?: Date;
  maxUpdateDateTime?: Date;
  template?: boolean;


  static serializeFilter(trusteeSearch: TrusteeSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(trusteeSearch);
    const wsTrusteeSearch = TrusteeSearchConverter.toWsTrusteeSearch(trusteeSearch);
    return JSON.stringify(wsTrusteeSearch);
  }

  static deserializeFilter(valueString: string | null): TrusteeSearch | null {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsTrusteeSearch = JSON.parse(valueString);
      const filter = TrusteeSearchConverter.toTrusteeSearch(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

}
