import {WsRetirementSimulatorPotentialCustomer} from '@lifeislife/lifeislife-ws-api';
import {RetirementSimulatorPotentialCustomer} from '../../domain/customer/retirement-simulator-potential-customer';
import {MypensionContextConverter} from '../retire/mypension-context-converter';

export class RetirementSimulatorPotentialCustomerConverter {

  static convertOut(value: RetirementSimulatorPotentialCustomer): WsRetirementSimulatorPotentialCustomer {
    if (value == null) {
      return undefined;
    }

    return {
      contactWsRef: {id: value.contactRef.id},
      message: value.message || '',
      simulation: value.simulation,
      trusteeName: value.trusteeName,
      wsMyPensionContactContext: value.contactContext == null ? null
        : MypensionContextConverter.convertContactContextOut(value.contactContext),
    };
  }


}
