import {LifeIsLifeAdmin} from '../../domain/lifeislife/lifeislife-admin';
import {WsLifeIsLifeAdmin} from '@lifeislife/lifeislife-ws-api';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';

export class LifeislifeAdminConverter {

  static convertIn(value: WsLifeIsLifeAdmin): LifeIsLifeAdmin {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      archived: value.archived,
      master: value.master,
      contactRef: value.contactRef,
      creationTime: DateConverterUtils.parseIsoDateTime(value.creationTime),
      updateTime: DateConverterUtils.parseIsoDateTime(value.updateTime),
    };
  }

  static convertOut(value: LifeIsLifeAdmin): WsLifeIsLifeAdmin {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      archived: value.archived,
      master: value.master,
      contactRef: value.contactRef,
      creationTime: DateConverterUtils.formatDateToIsoDateTime(value.creationTime),
      updateTime: DateConverterUtils.formatDateToIsoDateTime(value.updateTime),
      newPassword: undefined,
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsLifeIsLifeAdmin]: keyof LifeIsLifeAdmin } {
    return {
      id: 'id',
      archived: 'archived',
      master: 'master',
      contactRef: 'contactRef',
      updateTime: 'updateTime',
      creationTime: 'creationTime',
      newPassword: undefined,
    };
  }

}
