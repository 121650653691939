import {WsLegalPensionCareerDetails, WsLegalPensionCareerDetailsSearch} from '@lifeislife/lifeislife-ws-api';
import {LegalPensionCareerDetails} from '../../domain/retire/legal-pension-career-details';
import {PensionCareerTypeConverter} from './pension-career-type-converter';
import {LegalPensionCareerDetailsSearch} from '../../domain/retire/legal-pension-career-details-search';

export class LegalPensionCareerDetailsConverter {

  static convertIn(wsLegalPensionCareerDetails: WsLegalPensionCareerDetails): LegalPensionCareerDetails {
    return {
      id: wsLegalPensionCareerDetails.id,
      legalPensionStatusRef: wsLegalPensionCareerDetails.wsLegalPensionStatusWsRef,
      startYear: wsLegalPensionCareerDetails.startYear,
      endYearInclusive: wsLegalPensionCareerDetails.endYearInclusive,
      pensionCareerType: PensionCareerTypeConverter.convertIn(wsLegalPensionCareerDetails.wsPensionCareerType),
      fictitiousExtension: wsLegalPensionCareerDetails.fictitiousExtension,
    };
  }

  static convertSearchFilterOut(search: LegalPensionCareerDetailsSearch): WsLegalPensionCareerDetailsSearch {
    if (search == null) {
      return null;
    }
    return {
      careerType: PensionCareerTypeConverter.convertOut(search.careerType),
      fictitious: search.fictitious,
      legalPensionStatusWsRef: search.legalPensionStatusRef,
    };
  }

}
