import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {LifeisLifeWsAuth} from '../domain/auth/lifeis-life-ws-auth';
import {BehaviorSubject, Observable, of, Subject, throwError, timer} from 'rxjs';
import {catchError, distinctUntilChanged, map, mergeMap, publishReplay, refCount, retryWhen, tap} from 'rxjs/operators';
import {RequestOptions} from '../private_util/requestOptions';
import {ErrorUtils} from '../../util/error/error-utils';

// TODO: split with another service to expose the online/error state only
@Injectable({
  providedIn: 'root',
})
export class RequestService {
  requestIdKey = 'lilRequestIdKey';

  private runningRequestsCount = new BehaviorSubject<number>(0);
  private onlineSource = new BehaviorSubject<boolean>(true);
  private errorSource = new Subject<HttpErrorResponse>();
  private onlineObservable: Observable<boolean>;
  private runningRequests: any[] = [];

  constructor(private http: HttpClient) {
    this.onlineObservable = this.onlineSource.pipe(
      distinctUntilChanged(),
      // debounceTime(3000),
      publishReplay(1), refCount(),
    );
  }

  sendRequest<T>(params: RequestOptions, auth?: LifeisLifeWsAuth): Observable<T | never> {
    const headers = this.createBaseHeaders(auth, params)
      .append('Accept', 'application/json, text/plain');
    const withCredentials = auth != null;

    let request$: Observable<T>;
    if (params.responseType == null) {
      request$ = this.http.request<T>(params.method, params.url, {
        body: params.body,
        params: params.params,
        withCredentials: withCredentials,
        headers: headers,
      });
    } else {
      request$ = this.http.request(params.method, params.url, {
        body: params.body,
        params: params.params,
        withCredentials: withCredentials,
        headers: headers,
        responseType: params.responseType,
      }) as Observable<T>;
    }

    // mergeMap trick to trigger side effect on subscription only
    return of(null).pipe(
      mergeMap(() => this.wrapRequest$(request$)),
    );
  }

  sendRequestFullResponse<T>(params: RequestOptions, auth?: LifeisLifeWsAuth): Observable<HttpResponse<T> | never> {
    const headers = this.createBaseHeaders(auth, params)
      .append('Accept', 'application/json, text/plain');
    const withCredentials = auth != null;

    const request$ = this.http.request<T>(params.method, params.url, {
      body: params.body,
      params: params.params,
      withCredentials: withCredentials,
      headers: headers,
      observe: 'response',
    }) as Observable<HttpResponse<T>>;

    // mergeMap trick to trigger side effect on subscription only
    return of(null).pipe(
      mergeMap(() => this.wrapRequest$(request$)),
    );
  }

  downloadBlob(params: RequestOptions, auth?: LifeisLifeWsAuth, contentType = 'appplication/*'): Observable<Blob | never> {
    const headers = this.createBaseHeaders(auth, params)
      .append('Accept', contentType);
    const withCredentials = auth != null;

    const request$ = this.http.request(params.method, params.url, {
      body: params.body,
      params: params.params,
      withCredentials: withCredentials,
      headers: headers,
      responseType: 'blob',
      observe: 'body',
    });

    // mergeMap trick to trigger side effect on subscription only
    return of(null).pipe(
      mergeMap(() => this.wrapRequest$(request$)),
    );
  }

  /**
   * Returns an observable emitting false whenever a request failed, true while gettings 200s
   */
  isOnlineObservable(): Observable<boolean> {
    return this.onlineObservable;
  }

  /**
   * Returns an observable emitting all error responses
   */
  getErrorsObservable(): Observable<HttpErrorResponse> {
    return this.errorSource;
  }

  /**
   * Returns an observable producing boolean values. True is emitted when at least 1 request started,
   * false is emitted when the last running request has been unsubscribed.
   */
  getRequestRunningObservable(): Observable<boolean> {
    return this.runningRequestsCount.pipe(
      map(count => count > 0),
      distinctUntilChanged(),
      publishReplay(1), refCount(),
    );
  }

  discardAllRequests() {
    this.runningRequests = [];
    this.runningRequestsCount.next(0);
  }

  private wrapRequest$<T>(request$: Observable<T>) {
    this.runningRequests.push(request$);
    this.runningRequestsCount.next(this.runningRequests.length);

    const sharedRequest$ = request$.pipe(
      retryWhen(errors => this.retryWithBackoff$(errors)),
      tap(response => this.handleSuccess(request$)),
      catchError((e, caught) => this.handleError(e, caught, request$)),
      publishReplay(1), refCount(),
    );
    sharedRequest$.subscribe(); // ensure the request is fired, so we count it when it completes
    // alternative: count it on subscription only

    // Monitor stuck requests
    // timer(10000).pipe(
    //   takeUntil(sharedRequest$),
    // ).subscribe(() => this.warnPendingRequest(request$));

    return sharedRequest$;
  }

  private handleSuccess(request: Observable<any>): void {
    this.onlineSource.next(true);
    this.runningRequests = this.runningRequests
      .filter(r => r !== request);
    this.runningRequestsCount.next(this.runningRequests.length);
  }


  private handleError<T>(error: HttpErrorResponse, caught: Observable<T>, request: Observable<T>): Observable<T> {
    this.errorSource.next(error);
    this.runningRequests = this.runningRequests
      .filter(r => r !== request);
    this.runningRequestsCount.next(this.runningRequests.length);

    // TODO: retry once or twice in case error is 0
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('A client-side error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      switch (error.status) {
        case 0: {
          // Failed, and the browser does not have any status code to give us.
          // This may be due to the lack of any network, or because it did not pass CORS.
          this.onlineSource.next(false);
          break;
        }
        case 504: {
          // Gateway timeout -
          this.onlineSource.next(false);
          break;
        }
      }
    }
    return <Observable<T>>throwError(error);
  }

  private warnPendingRequest(request: Observable<any>) {
    console.warn(`A request is taking longer than expected`);
    console.warn(request);
  }


  private createBaseHeaders(auth: LifeisLifeWsAuth, params: RequestOptions) {
    let headers: HttpHeaders = new HttpHeaders()
      // .append('Accept-Charset', 'UTF-8')
      .append('Content-Type', 'application/json; charset=UTF-8')
      .append('X-Requested-With', 'XmlHttpRequest');
    if (auth != null) {
      headers = headers.append('Authorization', auth.authorizationHeader);
    }
    if (params.headers != null) {
      params.headers.keys().forEach(
        (key) => {
          const val = params.headers.get(key);
          headers = headers.set(key, val);
        },
      );
    }
    if (params.throughServiceWorker !== true) {
      headers = headers.set('ngsw-bypass', 'true');
    }
    return headers;
  }


  private retryWithBackoff$(errors: Observable<any>) {
    const maxAttempts = 4;
    const delayMs = 600;

    return errors.pipe(
      mergeMap((error, index) => {
        const retryAttempt = index + 1;
        // TODO: this flag could mean another service, like keycloak, is unavailable => check origins
        const serviceUnavailableError = ErrorUtils.isServiceUnavailableHttpError(error) && retryAttempt <= maxAttempts;

        if (serviceUnavailableError) {
          // retry with backoff
          const retryDelay = retryAttempt * delayMs;
          return timer(retryDelay);
        } else {
          // Rethrow
          return throwError(error);
        }
      }),
    );
  }
}
