import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InsurancePartnerComponent} from './insurance-partner/insurance-partner.component';
import {InsurancePartnerIconsComponent} from './insurance-partner-icons/insurance-partner-icons.component';


@NgModule({
  declarations: [InsurancePartnerComponent, InsurancePartnerIconsComponent],
  exports: [InsurancePartnerComponent, InsurancePartnerIconsComponent],
  imports: [
    CommonModule,
  ],
})
export class InsurancePartnersModule {
}
