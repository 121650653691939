import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {Contact, Customer, CustomerContact, CustomerContactSearch, CustomerContactService, Ref, Trustee} from '@lifeislife/lifeislife-domain';
import {distinctUntilChanged, map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {ObjectUtils} from '../../../../commons/util/ObjectUtils';

/**
 * Displays a contact linked to a customer, with all its customerContactType relations
 */
@Component({
  selector: 'app-customer-contact-multi-view',
  templateUrl: './customer-contact-multi-view.component.html',
  styleUrls: ['./customer-contact-multi-view.component.scss'],
})
export class CustomerContactMultiViewComponent implements OnInit {

  @Input()
  set contactRef(ref: Ref<Contact>) {
    this.contactRefSource$.next(ref);
  }

  @Input()
  set customerRef(ref: Ref<Customer>) {
    this.customerRefSource$.next(ref);
  }

  @Input()
  set trusteeRef(ref: Ref<Trustee>) {
    this.trusteeRefSource$.next(ref);
  }

  contactRefSource$ = new BehaviorSubject<Ref<Contact>>(null);
  private customerRefSource$ = new BehaviorSubject<Ref<Customer>>(null);
  private trusteeRefSource$ = new BehaviorSubject<Ref<Trustee>>(null);

  customerContacts$: Observable<CustomerContact[]>;

  constructor(private customerContactService: CustomerContactService) {
  }

  ngOnInit() {
    this.customerContacts$ = combineLatest(
      this.trusteeRefSource$.pipe(distinctUntilChanged(ObjectUtils.isSameRef)),
      this.contactRefSource$.pipe(distinctUntilChanged(ObjectUtils.isSameRef)),
      this.customerRefSource$.pipe(distinctUntilChanged(ObjectUtils.isSameRef)),
    ).pipe(
      switchMap(results => this.searchCustomerContacts$(results[0], results[1], results[2])),
      publishReplay(1), refCount(),
    );
  }

  private searchCustomerContacts$(trusteeRef: Ref<Trustee> | null, contactRef: Ref<Contact> | null, customerRef: Ref<Customer> | null): Observable<CustomerContact[]> {
    if (trusteeRef == null || contactRef == null || customerRef == null) {
      return of([]);
    }
    const CustomerContactSearch: CustomerContactSearch = this.customerContactService.createFilter(trusteeRef);
    CustomerContactSearch.contactRef = contactRef;
    CustomerContactSearch.customerRef = customerRef;

    return this.customerContactService.searchCustomerContacts$(CustomerContactSearch, null)
      .pipe(map(results => results.list));
  }
}
