import {Injectable} from '@angular/core';
import {RequestService} from '../../../service/request.service';
import {
  WsDaoEventEntityType,
  WsInsuranceCategoryOptionalFeature,
  WsInsuranceSubscription,
  WsInsuranceSubscriptionGroupSummary,
  WsInsuranceSubscriptionSnapshot,
  WsLabelledField,
  WsNewInsuranceSubscription,
  WsRef,
} from '@lifeislife/lifeislife-ws-api';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {Observable} from 'rxjs';
import {FrontendAppConfigKey} from '../../../../domain/config/frontend-app-config-key';
import {FrontendResourceType} from '../../../../util/cache/cache-resource-type';
import {WsInsuranceSubscriptionOptionalValues} from './ws-insurance-subscription-optional-values';
import {map} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class InsuranceSubscriptionWsClient extends CachedWsResourceClient<WsInsuranceSubscription, WsRef<WsInsuranceSubscription>, WsInsuranceSubscriptionGroupSummary> {

  constructor(private injectedRequestService: RequestService,
              private injectedAuthService: AuthProvider,
              private injectectedCacheService: ResourceCacheService,
              private injectedAppConfigService: AppConfigService) {
    super('/front/insuranceSubscription', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.INSURANCE_SUBSCRIPTION, injectectedCacheService);
  }


  updateInsuranceSubscription$(entity: WsInsuranceSubscription,
                               skipStatusChangeNotification?: boolean): Observable<WsRef<WsInsuranceSubscription>> {
    return super.doUpdate(entity, this.createParams(skipStatusChangeNotification));
  }

  createInsuranceSubscription$(entity: WsNewInsuranceSubscription,
                               skipStatusChangeNotification?: boolean): Observable<WsRef<WsInsuranceSubscription>> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/front/insuranceSubscription/`;
    const httpParams = this.createParams(skipStatusChangeNotification);

    return this.injectedRequestService.sendRequest<WsRef<WsInsuranceSubscription>>({
      method: 'POST', url: url, body: entity,
      params: httpParams,
    }, this.authService.getAuth());
  }

  saveInsuranceSubscription$(entity: WsInsuranceSubscription,
                             skipStatusChangeNotification?: boolean): Observable<WsRef<WsInsuranceSubscription>> {
    return super.doSave(entity, this.createParams(skipStatusChangeNotification));
  }

  getOptionalFeaturesEnabled$(ref: WsRef<WsInsuranceSubscription>): Observable<WsLabelledField<WsInsuranceCategoryOptionalFeature>[]> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/front/insuranceSubscription/${ref.id}/optionalFeatures`;
    const fetch$ = this.injectedRequestService.sendRequest<WsLabelledField<WsInsuranceCategoryOptionalFeature>[]>({
      method: 'GET', url: url,
    }, this.injectedAuthService.getAuth()).pipe(
      map(l => {
        return {id: ref.id, optionalFeatures: l} as WsInsuranceSubscriptionOptionalValues;
      }),
    );

    // Do not use cached value for configuration
    return this.injectectedCacheService.getFromNetwork$<WsInsuranceSubscriptionOptionalValues>(
      FrontendResourceType.INSURANCE_OPTIONAL_FEATURES, ref.id, fetch$,
    ).pipe(
      map(values => values == null ? [] : values.optionalFeatures),
    );
  }

  getLastImportSnapshotRef$(ref: WsRef<WsInsuranceSubscription>): Observable<WsRef<WsInsuranceSubscriptionSnapshot>> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/front/insuranceSubscription/${ref.id}/lastImport`;
    const fetch$ = this.injectedRequestService.sendRequest<WsRef<WsInsuranceSubscriptionSnapshot>>({
      method: 'GET', url: url,
    }, this.injectedAuthService.getAuth());

    // Do use cache, even if it means we miss a brand new import
    return this.injectectedCacheService.getFromCacheOrNetwork$<WsRef<WsInsuranceSubscriptionSnapshot>>(
      FrontendResourceType.INSURANCE_SUBSCRIPTION_SNAPSHOT_LAST_IMPORT, ref.id, fetch$,
    );
  }


  private createParams(skipStatusChangeNotification?: boolean): HttpParams {
    const paramsMap = {};
    if (skipStatusChangeNotification) {
      paramsMap['skipStatusChangeNotifications'] = skipStatusChangeNotification;
    }
    const params = new HttpParams({
      fromObject: paramsMap,
    });
    return params;
  }

}
