<div class="phone-number-input">
    <div class="prefix">
        <input type="text" pInputText #prefixInput
               name="phonePrefix"
               size="3"
               autocomplete="nope"
               [readonly]="disabled"
               [class.p-state-error]="hasError"
               [ngModel]="phoneNumberPrefix"
               (ngModelChange)="onPrefixChanged($event)"
        >
    </div>
    <div class="number">
        <input type="text" pInputText #numberInput
               name="phoneNumber"
               class="number-input"
               size="10"
               autocomplete="nope"
               [readonly]="disabled"
               [class.p-state-error]="hasError"
               [ngModel]="phoneNumberNumber"
               (ngModelChange)="onNumberChanged($event)"
        >
    </div>
</div>
