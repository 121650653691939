import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CurrencyInputComponent} from './currency-input/currency-input.component';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {CommonPipesModule} from '../../../commons/pipes/common-pipes.module';


@NgModule({
  declarations: [CurrencyInputComponent],
  exports: [CurrencyInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    CommonPipesModule,
  ],
})
export class MainPricingModule {
}
