import {Component, Input} from '@angular/core';
import {FileSubresourcePath, Ref, StoredFile, WithId} from '@lifeislife/lifeislife-domain';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {LifeislifeControlListener} from '../../lifeislife-control-listener';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-picture-edit',
  templateUrl: './picture-edit.component.html',
  styleUrls: ['./picture-edit.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PictureEditComponent,
    multi: true,
  }],
})
export class PictureEditComponent extends LifeislifeControlListener<Ref<StoredFile>> implements ControlValueAccessor {

  @Input()
  fileUPoadPath: FileSubresourcePath<WithId>;
  @Input()
  previewUri: string | null;
  @Input()
  disabled = false;
  @Input()
  hasError = false;

  constructor(
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit(): void {
  }

  writeValue(obj: any) {
    super.writeValue(obj);
  }

  onUploadSuccess(files: Ref<any>[]) {
    if (files.length === 1) {
      const file = files[0];
      this.value = file;
      this.fireChanges();
    }
  }

  onUploadError(e: any) {
    this.messageService.add({
      severity: `error`,
      summary: `Impossible d'envoyer le fichier`,
      detail: `${e.message || ''}`,
    });
  }
}
