import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Ref, SigningPackageFile, SigningPackageFileService, SubscriptionFile, SubscriptionFileService} from '@lifeislife/lifeislife-domain';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss'],
})
export class FileDownloadComponent {

  @Input()
  contentType = 'application/octet-steam';

  @ViewChild('fileLink', {static: false})
  fileLinkElement: ElementRef;

  constructor(
    private subscriptionFileService: SubscriptionFileService,
    private signingPackageFileService: SigningPackageFileService,
  ) {
  }

  downloadSubscriptionFileRef(ref: Ref<SubscriptionFile>) {
    const file$ = this.subscriptionFileService.getStoredFile$(ref);
    const uri$ = this.subscriptionFileService.getFileDownloadUrl$(ref, 'attachment');
    forkJoin([file$, uri$])
      .subscribe(r => {
        const name = r[0].fileName;
        const uri = r[1];
        this.downloadFile(uri, name);
      });
  }

  downloadSigningPackageFileRef(ref: Ref<SigningPackageFile>) {
    const file$ = this.signingPackageFileService.getStoredFile$(ref);
    const url$ = this.signingPackageFileService.getStoredFileDownloadUrl$(ref, 'attachment');
    forkJoin([file$, url$])
      .subscribe(r => {
        const name = r[0].fileName;
        const uri = r[1];
        this.downloadFile(uri, name);
      });
  }

  downloadFile(url: string, fileName?: string) {
    this.triggerDownloadLinkCLik(url, fileName);
  }

  openFileInNewTab(url: string, fileName?: string) {
    this.triggerDownloadLinkCLik(url, fileName, true);
  }

  downloadBlob(blobData: Blob, fileName?: string) {
    const blobURL = URL.createObjectURL(blobData);
    this.openFileInNewTab(blobURL, fileName);
  }

  private triggerDownloadLinkCLik(url: string, fileName?: string, newTab?: boolean) {
    const linkElement: HTMLAnchorElement = this.fileLinkElement.nativeElement;

    let serviceworkSafeUrl = url;
    // When downloading content from a remote resource, we dont want to run it through the service worker,
    // which may end up caching large file content.
    // However, for content already downloaded that are referenced using a blob:// uri, adding the extra query param
    // causes a 'network error' when downloading.
    if (url.indexOf('ngsw-bypass') < 0 && url.indexOf('blob') !== 0) {
      if (url.indexOf('?') < 0) {
        serviceworkSafeUrl = `${url}?ngsw-bypass=true`;
      } else {
        serviceworkSafeUrl = `${url}&ngsw-bypass=false`;
      }
    }

    linkElement.href = serviceworkSafeUrl;
    if (fileName != null && !this.isRunningBuggyAndroidWebview()) {
      // See https://code.google.com/p/android/issues/detail?id=198532&q=webview%20download&colspec=ID%20Status%20Priority%20Owner%20Summary%20Stars%20Reporter%20Opened
      linkElement.download = fileName;
    }
    if (newTab === true) {
      linkElement.target = '_blank';
    } else {
      linkElement.target = '_self';
    }
    linkElement.click();
    linkElement.href = null;
  }

  public isRunningBuggyAndroidWebview(): boolean {
    const isAndroidRegexp = /Android.*AppleWebKit.*Safari|Chrome\/.*Mobile/;
    const isWebviewRegexp = /( Version\/[0-9]\.[0-9] )|(\(.*wv\))/;
    const userAgent = navigator.userAgent;
    return isAndroidRegexp.test(userAgent)
      && isWebviewRegexp.test(userAgent);
  }

  public isRunningSafariMobile(): boolean {
    const isIos = /(iPhone|iPad);.*OS.*Mobile\//;
    const isSafari = /Safari\//;
    const userAgent = navigator.userAgent;
    return isIos.test(userAgent) && isSafari.test(userAgent);
  }
}
