import {LabelledField} from '../../domain/labelled-field';
import {WsLabelledField} from '@lifeislife/lifeislife-ws-api';

export class EnumConverterUtils {

  /**
   * Expect enum declaration to be NON const and mapped to STRING constants.
   * Matching will occur if the string constants are equal.
   *
   * The target enum type will be inspected at runtime (hence the requirement for non const enums),
   * and if a property with name matching the src parameter is found, then it is returned.
   * If no such property exists, an warning is printed.
   *
   * This method never throws. It returns undefined if the src paramter is undefined.
   * It returns the src parameter, typed <uType>, in all other cases (src as any as U).
   *
   * @param src the src enum value
   * @param uType the target enum type
   */
  static convertValue<T = any, U = any>(src: T, uType?: any, skipWarn?: boolean): U {
    if (src == null) {
      return undefined;
    }
    const valueExists = this.checkEnumValueExists(src, uType);
    if (!valueExists) {
      const uValues = Object.values(uType);
      if (!skipWarn) {
        console.warn(`Enum value ${src} not found in ${uType.constructor.name}: ${uValues}`);
      }
      return undefined;
    }
    const uValue = src as any as U;
    return uValue;
  }

  static convertLabelledField<T = any, U = any>(src: LabelledField<T>, uType?: any, skipWarn?: boolean): WsLabelledField<U> {
    return {
      label: src.label,
      field: this.convertValue(src.value, uType),
    };
  }

  static convertWsLabelledField<T = any, U = any>(src: WsLabelledField<T>, uType?: any, skipWarn?: boolean): LabelledField<U> {
    return {
      label: src.label,
      value: this.convertValue(src.field, uType),
    };
  }

  static checkEnumValueExists<T = any, U = any>(src: T, uType?: any): boolean {
    if (src == null) {
      return false;
    }
    const uValue = src as any as U;
    if (uType != null) {
      const uValues = Object.values(uType);
      const uContains = uValues.includes(uValue);
      return uContains;
    }
    return false;
  }

}
