import {Injectable} from '@angular/core';
import {MaritalStatus, MaritalStatusService} from '@lifeislife/lifeislife-domain';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {MaritalStatusSelectItem} from './marital-status-select-item';


@Injectable()
export class MaritalStatusItemService {

  constructor(
    private maritalStatusService: MaritalStatusService,
  ) {
  }

  createAllMaritalStatusItems$(options: {
    noSelectionLabel: string,
    includeNoSelectionOption: boolean
  }): Observable<MaritalStatusSelectItem[]> {
    return this.maritalStatusService.searchMaritalStatuses$().pipe(
      switchMap(l => this.createMaritalStatusItems$(l, options)),
    );
  }

  createMaritalStatusItems$(maritalStatuss: MaritalStatus[], options: {
    noSelectionLabel: string,
    includeNoSelectionOption: boolean
  }): Observable<MaritalStatusSelectItem[]> {
    let allMaritalStatuss;
    if (options.includeNoSelectionOption) {
      allMaritalStatuss = [null, ...maritalStatuss];
    } else {
      allMaritalStatuss = maritalStatuss;
    }
    const item$List: Observable<MaritalStatusSelectItem>[] = allMaritalStatuss.map(type => this.createMaritalStatusItem$(type, options));
    const itemList$ = item$List.length === 0 ? of([]) : forkJoin(item$List);
    return itemList$;
  }

  createMaritalStatusItem$(maritalStatus: MaritalStatus | null, options: {
    noSelectionLabel: string
  }): Observable<MaritalStatusSelectItem> {
    if (maritalStatus == null) {
      return of({
        value: null,
        maritalStatus: null,
        label: options.noSelectionLabel,
      });
    } else {
      return of({
          value: {id: maritalStatus.id},
          label: maritalStatus.label,
          maritalStatus: maritalStatus,
        },
      );
    }
  }

}
