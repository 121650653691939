import {DateTimeSearch} from '../../domain/shared/date-time-search';
import {WsDateTimeSearch} from '@lifeislife/lifeislife-ws-api';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';

export class DateTimeSearchConverter {

  static convertOut(dateTimeSearch: DateTimeSearch): WsDateTimeSearch {
    if (dateTimeSearch == null) {
      return null;
    }
    return {
      minDateTime: DateConverterUtils.formatDateToIsoDateTime(dateTimeSearch.minDateTime),
      maxDateTime: DateConverterUtils.formatDateToIsoDateTime(dateTimeSearch.maxDateTime),
    };
  }

  static convertIn(wsDateTimeSearch: WsDateTimeSearch): DateTimeSearch {
    if (wsDateTimeSearch == null) {
      return null;
    }
    return {
      minDateTime: DateConverterUtils.parseIsoDateTime(wsDateTimeSearch.minDateTime),
      maxDateTime: DateConverterUtils.parseIsoDate(wsDateTimeSearch.maxDateTime),
    };
  }

}
