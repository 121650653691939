import {WsInsuranceProvider, WsInsuranceProviderSearch} from '@lifeislife/lifeislife-ws-api';
import {ProductProviderConverter} from '../corebusiness/product-provider-converter';
import {InsuranceProvider} from '../../domain/insurance/insurance-provider';
import {InsuranceProviderSearch} from '../../domain/insurance/insurance-provider-search';

export class InsuranceProviderConverter {

  static convertIn(value: WsInsuranceProvider): InsuranceProvider {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      productProviderRef: value.productProviderWsRef,
    };
  }

  static convertOut(value: InsuranceProvider): WsInsuranceProvider {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      productProviderWsRef: value.productProviderRef,
    };
  }


  static convertFilterIn(value?: WsInsuranceProviderSearch): InsuranceProviderSearch {
    if (value == null) {
      return undefined;
    }
    return {
      productProviderSearch: ProductProviderConverter.convertFilterIn(value.wsProductProviderSearch),
    };
  }

  static convertFilterOut(value: InsuranceProviderSearch): WsInsuranceProviderSearch {
    if (value == null) {
      return undefined;
    }
    return {
      wsProductProviderSearch: ProductProviderConverter.convertFilterOut(value.productProviderSearch),
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsInsuranceProvider]: keyof InsuranceProvider } {
    return {
      id: 'id',
      productProviderWsRef: 'productProviderRef',
    };
  }

}
