import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateUtils} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'calendar-input',
  templateUrl: './calendar-input.component.html',
  styleUrls: ['./calendar-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CalendarInputComponent,
      multi: true,
    },
  ],
})
export class CalendarInputComponent implements ControlValueAccessor, OnInit {

  @Input()
  minYearDiff = 150;
  @Input()
  maxYearDiff = 5;
  @Input()
  hasError: boolean;
  @Input()
  disabled: boolean;
  @Input()
  readonly: boolean;
  @Input()
  showClearAction = true;
  @Input()
  minDate: Date;
  @Input()
  maxDate: Date;
  @Input()
  appendTo = 'body';
  @Input()
  showYear = true;

  value = new BehaviorSubject<Date>(null);
  yearRange: string;

  private onChangeFn;
  private onTouchedFn;

  constructor() {
  }

  ngOnInit() {
    const thisYear = DateUtils.getYear(new Date());

    const minYear = thisYear - Math.abs(this.minYearDiff);
    const maxYear = thisYear + Math.abs(this.maxYearDiff);
    this.yearRange = `${minYear}:${maxYear}`;
  }

  writeValue(value: Date): void {
    if (DateUtils.isValidDate(value)) {
      this.value.next(new Date(value));
    } else {
      this.value.next(null);
    }
  }

  onValueChange(value: Date) {
    if (!this.readonly && !this.disabled) {
      if (DateUtils.isValidDate(value)) {
        this.value.next(value);
        this.fireChange(value);
      } else {
        this.value.next(null);
        this.fireChange(null);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  private fireChange(newValue: Date) {
    if (this.onTouchedFn) {
      this.onTouchedFn();
    }
    if (this.onChangeFn) {
      if (newValue != null) {
        const startOfDay = DateUtils.toDayOnlyDate(newValue);
        this.onChangeFn(startOfDay);
      } else {
        this.onChangeFn(null);
      }
    }
  }
}
