import {Injectable} from '@angular/core';
import {LegalPensionStatusWsClient} from '../../client/resources/front/retire/legal-pension-status-ws-client';
import {LegalPensionCareerDetailsWsClient} from '../../client/resources/front/retire/legal-pension-career-details-ws-client';
import {SupplementaryPensionStatusWsClient} from '../../client/resources/front/retire/supplementary-pension-status-ws-client';
import {PensionPlanStatusWsClient} from '../../client/resources/front/retire/pension-plan-status-ws-client';
import {forkJoin, Observable, of} from 'rxjs';
import {LegalPensionStatus} from '../../domain/retire/legal-pension-status';
import {Contact} from '../../domain/contact/contact';
import {Ref} from '../../domain/shared/ref';
import {LegalPensionStatusSearch} from '../../domain/retire/legal-pension-status-search';
import {SimplePaginationUtils} from '../../util/pagination/simple-pagination-utils';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {SearchResult} from '../../client/domain/search/search-result';
import {
  WsLegalPensionCareerDetails,
  WsLegalPensionStatus,
  WsPensionPlanStatus,
  WsRef,
  WsSupplementaryPensionStatus,
} from '@lifeislife/lifeislife-ws-api';
import {LegalPensionCareerDetailsSearch} from '../../domain/retire/legal-pension-career-details-search';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {LegalPensionCareerDetails} from '../../domain/retire/legal-pension-career-details';
import {SupplementaryPensionStatus} from '../../domain/retire/supplementary-pension-status';
import {SupplementaryPensionStatusSearch} from '../../domain/retire/supplementary-pension-status-search';
import {PensionPlanStatusSearch} from '../../domain/retire/pension-plan-status-search';
import {PensionPlanStatus} from '../../domain/retire/pension-plan-status';
import {UserAuthService} from '../../util/auth/user-auth.service';
import {PensionPlanType} from '../../domain/retire/pension-plan-type';
import {LegalPensionStatusConverter} from './legal-pension-status-converter';
import {LegalPensionCareerDetailsConverter} from './legal-pension-career-details-converter';
import {SupplementaryPensionStatusConverter} from './supplementary-pension-status-converter';
import {PensionPlanStatusConverter} from './pension-plan-status-converter';

@Injectable({
  providedIn: 'root',
})
export class PensionStatusService {

  constructor(
    private legalPensionStatusWsClient: LegalPensionStatusWsClient,
    private legalPensionCareerDetailsWsClient: LegalPensionCareerDetailsWsClient,
    private supplementaryPensionStatusWsClient: SupplementaryPensionStatusWsClient,
    private pensionPlanStatusWsClient: PensionPlanStatusWsClient,
    private authService: UserAuthService,
  ) {
  }


  getMyLegalPensionStatus$_(): Observable<LegalPensionStatus | null> {
    return this.authService.state$.pipe(
      map(s => s == null ? null : s.contactRef),
      switchMap(ref => this.getContactLegalPensionStatus$(ref)),
      publishReplay(1), refCount(),
    );
  }


  getContactLegalPensionStatus$(contactRef: Ref<Contact>): Observable<LegalPensionStatus | null> {
    const searchFilter: LegalPensionStatusSearch = {
      contactRef: {id: contactRef.id},
    };
    const pagination = SimplePaginationUtils.newPagination(1);
    return this.searchLegalPensionStatus$(searchFilter, pagination).pipe(
      map(r => r.count >= 1 ? r.list[0] : null),
    );
  }

  searchLegalPensionStatus$(searchFilter: LegalPensionStatusSearch, pagination: SimplePagination<any | string>) {
    const wsSearch = LegalPensionStatusConverter.convertSearchFilterOut(searchFilter);
    return this.legalPensionStatusWsClient.doSearch(wsSearch, pagination).pipe(
      switchMap(r => this.convertLegalPensionStatusSearchResultsIn$(r)),
    );
  }

  searchLegalPensionCareerDetails$(searchFilter: LegalPensionCareerDetailsSearch, pagination: SimplePagination)
    : Observable<SearchResult<LegalPensionCareerDetails>> {
    const wsSearch = LegalPensionCareerDetailsConverter.convertSearchFilterOut(searchFilter);
    return this.legalPensionCareerDetailsWsClient.doSearch(wsSearch, pagination).pipe(
      switchMap(r => this.convertLegalPensionCareerDetailsSearchResultsIn$(r)),
    );
  }


  getMyLastSupplementaryPensionStatus$(): Observable<SupplementaryPensionStatus | null> {
    return this.authService.state$.pipe(
      map(s => s == null ? null : s.contactRef),
      switchMap(ref => this.getLastContactSupplementaryPensionStatus$(ref)),
      publishReplay(1), refCount(),
    );
  }

  getLastContactSupplementaryPensionStatus$(contactRef: Ref<Contact>): Observable<SupplementaryPensionStatus | null> {
    const searchFilter: SupplementaryPensionStatusSearch = {
      contactRef: {id: contactRef.id},
    };
    const pagination = SimplePaginationUtils.newPagination(1);
    return this.searchSupplementaryPensionStatus$(searchFilter, pagination).pipe(
      map(r => r.count >= 1 ? r.list[0] : null),
    );
  }

  searchSupplementaryPensionStatus$(searchFilter: SupplementaryPensionStatusSearch, pagination: SimplePagination<any | string>) {
    const wsSearch = SupplementaryPensionStatusConverter.convertSearchFilterOut(searchFilter);
    return this.supplementaryPensionStatusWsClient.doSearch(wsSearch, pagination).pipe(
      switchMap(r => this.convertSupplementaryPensionStatusSearchResultsIn$(r)),
    );
  }

  searchPensionPlanStatus$(searchFilter: PensionPlanStatusSearch, pagination: SimplePagination)
    : Observable<SearchResult<PensionPlanStatus>> {
    const wsSearch = PensionPlanStatusConverter.convertSearchFilterOut(searchFilter);
    return this.pensionPlanStatusWsClient.doSearch(wsSearch, pagination).pipe(
      switchMap(r => this.convertPensionPlanStatusSearchResultsIn$(r)),
    );
  }

  getLegalPensionCareerDetails$(ref: Ref<LegalPensionCareerDetails>) {
    return this.legalPensionCareerDetailsWsClient.doGet(ref.id).pipe(
      map(ws => LegalPensionCareerDetailsConverter.convertIn(ws)),
    );
  }

  getLegalPensionStatus$(ref: Ref<LegalPensionStatus>) {
    return this.legalPensionStatusWsClient.doGet(ref.id).pipe(
      map(ws => LegalPensionStatusConverter.convertIn(ws)),
    );
  }

  getSupplementaryPensionStatus$(ref: Ref<SupplementaryPensionStatus>) {
    return this.supplementaryPensionStatusWsClient.doGet(ref.id).pipe(
      map(ws => SupplementaryPensionStatusConverter.convertIn(ws)),
    );
  }

  getPensionPlanStatusDetails$(ref: Ref<PensionPlanStatus>) {
    return this.pensionPlanStatusWsClient.doGet(ref.id).pipe(
      map(ws => PensionPlanStatusConverter.convertIn(ws)),
    );
  }

  getPensionPlanTypeLabel(type: PensionPlanType): string {
    if (type == null) {
      return '';
    }
    switch (type) {
      case PensionPlanType.EMPLOYEE_EMPLOYER_PLAN:
        return `Plan de l'employeur`;
      case PensionPlanType.EMPLOYEE_OTHER:
        return 'Employé';
      case PensionPlanType.SELF_EMPLOYED_EIP:
        return 'EIP';
      case PensionPlanType.SELF_EMPLOYED_PLCI:
        return 'PLCI';
      case PensionPlanType.SELF_EMPLOYED_OTHER:
        return 'Indépendant';
      case PensionPlanType.UNKNOWN:
        return 'Inconnu';
      default:
        return type as string;
    }
  }

  private convertLegalPensionCareerDetailsSearchResultsIn$(results: SearchResult<WsRef<WsLegalPensionCareerDetails>>): Observable<SearchResult<LegalPensionCareerDetails>> {
    const rowTasks = results.list.map(
      ref => this.getLegalPensionCareerDetails$(ref),
    );
    return rowTasks.length === 0 ? of(new SearchResult<LegalPensionCareerDetails>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<LegalPensionCareerDetails>>>{
          list: rows,
        }),
      ));
  }

  private convertLegalPensionStatusSearchResultsIn$(results: SearchResult<WsRef<WsLegalPensionStatus>>): Observable<SearchResult<LegalPensionStatus>> {
    const rowTasks = results.list.map(
      ref => this.getLegalPensionStatus$(ref),
    );
    return rowTasks.length === 0 ? of(new SearchResult<LegalPensionStatus>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<LegalPensionStatus>>>{
          list: rows,
        }),
      ));
  }

  private convertSupplementaryPensionStatusSearchResultsIn$(results: SearchResult<WsRef<WsSupplementaryPensionStatus>>): Observable<SearchResult<SupplementaryPensionStatus>> {
    const rowTasks = results.list.map(
      ref => this.getSupplementaryPensionStatus$(ref),
    );
    return rowTasks.length === 0 ? of(new SearchResult<SupplementaryPensionStatus>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<SupplementaryPensionStatus>>>{
          list: rows,
        }),
      ));
  }

  private convertPensionPlanStatusSearchResultsIn$(results: SearchResult<WsRef<WsPensionPlanStatus>>): Observable<SearchResult<PensionPlanStatus>> {
    const rowTasks = results.list.map(
      ref => this.getPensionPlanStatusDetails$(ref),
    );
    return rowTasks.length === 0 ? of(new SearchResult<PensionPlanStatus>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<PensionPlanStatus>>>{
          list: rows,
        }),
      ));
  }

}
