import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CapitalizeDirective} from './capitalize-directive';
import {UpperCaseDirective} from './upper-case.directive';
import {ForceFocusDirective} from './force-focus-directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    CapitalizeDirective,
    UpperCaseDirective,
    ForceFocusDirective,
  ],
  exports: [
    CapitalizeDirective,
    UpperCaseDirective,
    ForceFocusDirective,
  ],
})
export class CommonDirectivesModule {

}
