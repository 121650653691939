import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IbanInputComponent} from './iban-input/iban-input.component';
import {InputMaskModule} from 'primeng/inputmask';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [IbanInputComponent],
  imports: [
    CommonModule,
    InputMaskModule,
    FormsModule,
  ],
  exports: [IbanInputComponent],
})
export class BankAccountModule {
}
