import {Injectable} from '@angular/core';
import {RequestService} from '../../service/request.service';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {WsHealthcheckResponse} from '../../domain/health/ws-healthcheck-response';
import {FrontendAppConfigKey} from '../../../domain/config/frontend-app-config-key';
import {AppConfigService} from '../../../service/config/app-config.service';


@Injectable({
  providedIn: 'root',
})
export class UnrestictedHealthClient {

  constructor(private requestService: RequestService,
              private appConfigService: AppConfigService,

  ) {
  }

  checkHealth$(thirdPartyValue?: boolean): Observable<WsHealthcheckResponse> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/unrestricted/health`;
    return this.requestService.sendRequest<WsHealthcheckResponse>({
      method: 'GET',
      url: url,
      params: new HttpParams({
        fromObject: {
          thirdParty: '' + thirdPartyValue,
        },
      }),
    });
  }

}
