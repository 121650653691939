import {LegalPensionStatus} from '../../domain/retire/legal-pension-status';
import {WsLegalPensionStatus, WsLegalPensionStatusSearch} from '@lifeislife/lifeislife-ws-api';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {LegalPensionStatusSearch} from '../../domain/retire/legal-pension-status-search';

export class LegalPensionStatusConverter {

  static convertIn(wsLegalPensionStatus: WsLegalPensionStatus): LegalPensionStatus {
    return {
      id: wsLegalPensionStatus.id,
      contactRef: wsLegalPensionStatus.contactWsRef,
      creationDate: DateConverterUtils.parseIsoDate(wsLegalPensionStatus.creationDate),
      referenceDate: DateConverterUtils.parseIsoDate(wsLegalPensionStatus.referenceDate),
      referenceYear: wsLegalPensionStatus.referenceYear,
      pensionScenarioOk: wsLegalPensionStatus.pensionScenarioOk,
      careerStartDate: DateConverterUtils.parseIsoDate(wsLegalPensionStatus.careerStartDate),
      expectedPensionDate: DateConverterUtils.parseIsoDate(wsLegalPensionStatus.expectedPensionDate),
      expectedPensionAge: wsLegalPensionStatus.expectedPensionAge,
      expectedPensionAmount: wsLegalPensionStatus.expectedPensionAmount,
      rightsAmountAsEmployee: wsLegalPensionStatus.rightsAmountAsEmployee,
      rightsAmountAsOfficial: wsLegalPensionStatus.rightsAmountAsOfficial,
      rightsAmountAsSelfEmployed: wsLegalPensionStatus.rightsAmountAsSelfEmployed,
      rightsAmountAsOther: wsLegalPensionStatus.rightsAmountAsOther,
      solidarityContributionFee: wsLegalPensionStatus.solidarityContributionFee,
      healthInsuranceFee: wsLegalPensionStatus.healthInsuranceFee,
      witholdingTaxFee: wsLegalPensionStatus.witholdingTaxFee,
      otherFee: wsLegalPensionStatus.otherFee,
    };
  }

  static convertSearchFilterOut(search: LegalPensionStatusSearch): WsLegalPensionStatusSearch {
    if (search == null) {
      return null;
    }
    return {
      wsContactWsRef: search.contactRef,
    };
  }
}
