import {ApplicationMenuDef} from '../main/routing/application-menu-def';
import {AppFeature} from '../main/model/app-feature';

export const TrusteeMenuData: any & ApplicationMenuDef = {
  moduleShell: {
    title: '',
    skipMenu: true,
  },
  list: {
    title: 'Liste',
    menuLink: ['/trustee/list'],
    allAppFeatures: [AppFeature.trustee_list],
  },
  new: {
    title: 'Nouvelle fiduciaire',
    menuLink: ['/trustee/new'],
    allAppFeatures: [AppFeature.trustee_create],
  },
  details: {
    title: 'Détails',
    skipMenu: true,

    children: {
      info: {
        title: 'Informations',
        id: 'trustee-info',
        menuIcon: 'fa fa-info',
        relativeMenuLink: ['info'],
        allAppFeatures: [AppFeature.trustee_create],
      },
      contact: {
        title: 'Contacts',
        id: 'trustee-contacts',
        menuIcon: 'fa fa-user',
        relativeMenuLink: ['contact'],
        allAppFeatures: [AppFeature.trustee_contact_list],
      },
      customers: {
        title: 'Clients',
        id: 'trustee-customer',
        menuIcon: 'fa fa-users',
        relativeMenuLink: ['customer'],
        allAppFeatures: [AppFeature.customer_list],
      },
      insuranceSubscriptions: {
        title: `Polices d'assurance`,
        id: 'trustee-insurance-subscriptions',
        menuIcon: 'fa fa-life-ring',
        relativeMenuLink: ['insuranceSubscription'],
        allAppFeatures: [AppFeature.insurance_subscription_list],
      },
      creditSubscriptions: {
        title: `Contrats de crédit`,
        id: 'trustee-credit-subscriptions',
        menuIcon: 'fa fa-credit-card',
        relativeMenuLink: ['creditSubscription'],
        allAppFeatures: [AppFeature.credit_subscription_list],
      },
      investmentSubscriptions: {
        title: `Contrats d'investissement`,
        id: 'trustee-investment-subscriptions',
        menuIcon: 'fa fa-bank',
        relativeMenuLink: ['investmentSubscription'],
        allAppFeatures: [AppFeature.investment_subscription_list],
      },
    },
  },
};
