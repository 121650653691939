import {LifeisLifeWsAuth, SupportedAuthType} from './lifeis-life-ws-auth';
import {Role} from '../../../domain/role/role';

export class WsInviteTokenAuth implements LifeisLifeWsAuth {
  token: string;

  authorizationHeader: string;
  authorizationQueryString: string;
  type: SupportedAuthType;
  role?: Role;

  static fromCodeAndRole(code: string, role: Role): WsInviteTokenAuth {
    if (code == null) {
      return null;
    }
    return {
      token: code,
      authorizationHeader: WsInviteTokenAuth.provideAuthorizationHeader(code),
      authorizationQueryString: WsInviteTokenAuth.provideAuthorizationQueryString(code),
      type: 'invite-token',
      role: role,
    };
  }

  private static provideAuthorizationHeader(token: string): string {
    if (token == null) {
      throw new Error('No auth token');
    }
    return `Invite ${token}`;
  }

  private static provideAuthorizationQueryString(token: string): string {
    const queryString = `invite_token=${btoa(token)}`;
    return queryString;
  }

}
