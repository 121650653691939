import {FrontendAppSwitch} from '@lifeislife/lifeislife-domain';
import {FrontendAppConfigKey} from '@lifeislife/lifeislife-domain';

export const environment = {
  production: true,
  // sendErrors: false,
  // recaptchaPublicKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  // googleApiPublicKey: 'AIzaSyBz2zvgCX7onOAKZaeU9P2fUypYlcbbgrA',
  defaultSwitches: {
    [FrontendAppSwitch.front_dev_banner]: true,
    [FrontendAppSwitch.front_dev_debug]: true,
    [FrontendAppSwitch.front_error_report]: true,
  },
  defaultConfig: {
    [FrontendAppConfigKey.app_name]: 'UAT LifeisLife admin',
    [FrontendAppConfigKey.app_configKey]: 'lifeislife.front',
    [FrontendAppConfigKey.lifeislife_ws_uri]: 'https://api.lifeislifedev.valuya.be/lifeislife-ws',
  },
};
