import {WsRole} from '@lifeislife/lifeislife-ws-api';
import {Role} from '../../domain/role/role';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';

export class RoleConverter {

  static fromWsRole(wsRole: WsRole): Role {
    return EnumConverterUtils.convertValue(wsRole, Role);
  }

  static toWsRole(role: Role): WsRole {
    return EnumConverterUtils.convertValue(role, WsRole);
  }

}
