import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  AppConfigService,
  FileSubresourcePath,
  FrontendAppSwitch,
  Ref,
  SimplePagination,
  SimplePaginationUtils,
  SimpleSort,
  WithId,
} from '@lifeislife/lifeislife-domain';
import {LazyLoadEvent} from 'primeng/api';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {debounceTime, publishReplay, refCount} from 'rxjs/operators';
import {RefWithFileRef} from './ref-with-file-ref';
import {RefWithFileAndNewFileName} from './ref-with-file-and-new-file-name';
import {RefWithFile} from './ref-with-file';
import {FileDownloadComponent} from '../file-download/file-download.component';
import {FileUploadComponent} from '../file-upload/file-upload';

@Component({
  selector: 'app-file-box',
  templateUrl: './file-box.component.html',
  styleUrls: ['./file-box.component.scss'],
})
export class FileBoxComponent implements OnInit {

  @Input()
  rows: RefWithFile[];
  @Input()
  pagination: SimplePagination;
  @Input()
  sort: SimpleSort;
  @Input()
  totalCount: number;
  @Input()
  loading: boolean;
  @Input()
  disabled: boolean;

  @Input()
  newFileUploadPath: FileSubresourcePath<WithId>;
  @Input()
  overlayAppendTo: any;

  @Input()
  downloadOnClick = false;
  @Input()
  showUpload = true;
  @Input()
  showUploadInFooter = true;
  @Input()
  showDelete = false;
  @Input()
  showRename = false;
  /**
   * Footer content must then be provided as content of this component
   */
  @Input()
  showFooter = false;
  @Input()
  showHeader = true;
  @Input()
  showFileCreated: boolean;
  @Input()
  showFileUpdated: boolean;
  @Input()
  showBadgeSigned = false;
  @Input()
  showVisibleToCustomer = false;


  @Output()
  paginationChange = new EventEmitter<SimplePagination>();
  @Output()
  fileClick = new EventEmitter<RefWithFileRef>();
  @Output()
  fileDeleteClick = new EventEmitter<RefWithFileRef>();
  @Output()
  fileRename = new EventEmitter<RefWithFileAndNewFileName>();
  @Output()
  filesUploaded = new EventEmitter<Ref<any>[]>();
  @Output()
  searchQueryChange: Observable<string>;
  @Output()
  visibleToCustomerChange = new EventEmitter<RefWithFile>();

  @Output()
  uploadAborted = new EventEmitter<any>();
  @Output()
  uploadErrored = new EventEmitter<any>();

  searchQueryValue$ = new BehaviorSubject<string>('');
  renamingWithFile: RefWithFile | null;
  mobileUserAgent: boolean;

  @ViewChild(FileUploadComponent, {static: true})
  private fileUploadComponent: FileUploadComponent;
  @ViewChild(FileDownloadComponent, {static: true})
  private fileDownloadComponent: FileDownloadComponent;

  constructor(
    private configService: AppConfigService,
  ) {
    this.searchQueryChange = this.searchQueryValue$.pipe(
      debounceTime(200),
      publishReplay(1), refCount(),
    );
  }

  ngOnInit() {
    this.mobileUserAgent = this.configService.isSwitchCurrentlyEnabled(FrontendAppSwitch.useragent_mobile);
  }

  onLazyLoad(event: LazyLoadEvent) {
    const newPagination = SimplePaginationUtils.applyLazyLoadEvent(this.pagination, event);
    // Force async for change detection
    timer(100)
      .subscribe(a => this.paginationChange.next(newPagination));
  }

  onRowClicked(row: RefWithFile, event: MouseEvent) {
    if (this.downloadOnClick && row.storedFileDownloadUri) {
      this.fileDownloadComponent.openFileInNewTab(row.storedFileDownloadUri, row.storedFile.fileName);
    } else {
      this.fileClick.next(row);
    }
  }

  onUploadFileClick(event: MouseEvent) {
    this.fileUploadComponent.openUploadFileDialog(this.mobileUserAgent);
  }

  onUploadAborted(event: any) {
    this.uploadAborted.next(event);
  }

  onUploadError(event: any) {
    this.uploadErrored.next(event);
  }

  onUploadSuccess(event: Ref<any>[]) {
    this.filesUploaded.next(event);
  }

  onFileDrop(event: FileList) {
    this.fileUploadComponent.uploadFiles(event, this.newFileUploadPath, 'dragndrop');
  }

  onDeleteClick(row: RefWithFile) {
    this.fileDeleteClick.next(row);
  }

  onFileRenamed(row: RefWithFile, name: string) {
    const withName: RefWithFileAndNewFileName = {
      id: row.id,
      newFileName: name,
      storedFileRef: row.storedFileRef,
      storedFile: row.storedFile,
    };
    this.fileRename.next(withName);
  }

  onQueyChanged(value: string) {
    this.searchQueryValue$.next(value);
  }

  onRenamingFileChange(row: RefWithFile, renaming: boolean) {
    if (renaming) {
      this.renamingWithFile = row;
    } else if (this.renamingWithFile === row) {
      this.renamingWithFile = null;
    }
  }

  onVisibleToCustomerChange(row: RefWithFile, visible: boolean) {
    row.visibleToCustomer = visible;
    this.visibleToCustomerChange.next(row);
  }
}
