import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  InsuranceCategory,
  InsuranceCategorySearch,
  InsuranceCategoryService,
  Ref,
  SimplePaginationUtils,
} from '@lifeislife/lifeislife-domain';
import {SelectItem} from 'primeng/api';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {InsuranceCategorySelectItem} from '../insurance-category-select-item';
import {InsuranceCategoryItemService} from '../insurance-category-item.service';

@Component({
  selector: 'llc-insurance-category-select',
  templateUrl: './insurance-category-select.component.html',
  styleUrls: ['./insurance-category-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InsuranceCategorySelectComponent),
    multi: true,
  }],
})
export class InsuranceCategorySelectComponent implements OnInit, ControlValueAccessor {

  @Input()
  disabled = false;
  @Input()
  readonly = false;
  @Input()
  hasError = false;
  @Input()
  completeMethod?: (string) => Observable<InsuranceCategory[]>;
  @Input()
  includeNoSelectionOption = true;
  @Input()
  noSelectionLabel = '';
  @Input()
  includeOtherOption = false;
  @Input()
  otherOptionLabel = '[Autre type de police]';
  @Input()
  placeholder: string = undefined;
  @Input()
  allowStrings: boolean;
  @Input()
  appendTo = 'body';
  @Input()
  showTypeBadge: boolean;
  @Input()
  showFamilyBadge: boolean;
  @Input()
  usePublicLabels: boolean;

  value$ = new BehaviorSubject<Ref<InsuranceCategory> | string | null>(null);

  sugestionsQuery$ = new Subject<string | null>();
  selectedItem$: Observable<InsuranceCategorySelectItem | string | null>;
  suggestions$: Observable<InsuranceCategorySelectItem[]>;

  private onChangeFunction: Function;
  private onTouchedFunction: Function;

  constructor(
    private insuranceCategoryService: InsuranceCategoryService,
    private insuranceCategoryItemService: InsuranceCategoryItemService,
  ) {
  }

  ngOnInit() {
    this.selectedItem$ = this.value$.pipe(
      switchMap(ref => this.fetchInsuranceCategory$(ref)),
      switchMap(insuranceCategory => this.createInsurancecategoryItem$(insuranceCategory)),
      publishReplay(1), refCount(),
    );
    this.suggestions$ = this.sugestionsQuery$.pipe(
      switchMap(query => this.searchSuggestions$(query)),
      publishReplay(1), refCount(),
    );
  }

  writeValue(ref: Ref<InsuranceCategory> | string | null): void {
    this.value$.next(ref);
  }

  registerOnChange(fn: any): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFunction = fn;
  }


  onItemSelect(item: SelectItem) {
    if (item.value === this.insuranceCategoryItemService.OTHER_OPTION_VALUE) {
      this.value$.next(this.insuranceCategoryItemService.OTHER_OPTION_VALUE);
      this.fireChange(this.insuranceCategoryItemService.OTHER_OPTION_VALUE);
      return;
    }
    const insuranceCategoryRef: Ref<InsuranceCategory> = item.value;
    this.value$.next(insuranceCategoryRef);
    this.fireChange(insuranceCategoryRef);
  }

  onItemClear() {
    this.fireChange(undefined);
  }


  searchAllSuggestions() {
    if (this.disabled || this.readonly) {
      return;
    }
    this.sugestionsQuery$.next(null);
  }

  searchSuggestions(query: string | null) {
    this.sugestionsQuery$.next(query);
  }

  private createInsurancecategoryItem$(insuranceCategory: InsuranceCategory | string | null) {
    if (typeof insuranceCategory === 'string') {
      return of(insuranceCategory);
    } else {
      return this.insuranceCategoryItemService.createInsuranceCategoryItem$(insuranceCategory, {
        noSelectionLabel: this.noSelectionLabel,
        otherOptionLabel: this.otherOptionLabel,
        usePublicLabel: this.usePublicLabels,
      });
    }
  }


  private fireChange(value: Ref<InsuranceCategory> | string) {
    if (this.onTouchedFunction) {
      this.onTouchedFunction();
    }
    if (typeof value !== 'string' || this.allowStrings) {
      if (this.onChangeFunction) {
        this.onChangeFunction(value);
      }
    }
  }

  private fetchInsuranceCategory$(ref: Ref<InsuranceCategory> | string | null): Observable<InsuranceCategory | string | null> {
    if (ref == null) {
      return of(null);
    } else if (typeof ref === 'string') {
      return of(ref);
    } else {
      return this.insuranceCategoryService.getInsuranceCategory$(ref);
    }
  }

  private searchSuggestions$(query: string | null): Observable<InsuranceCategorySelectItem[]> {
    let insuranceCategorySuggestions$: Observable<InsuranceCategory[]>;
    if (this.completeMethod) {
      insuranceCategorySuggestions$ = this.completeMethod(query);
    } else {
      insuranceCategorySuggestions$ = this.searchInsuranceCategorySuggestions$(query);
    }
    return insuranceCategorySuggestions$.pipe(
      switchMap(insuranceCategorys => this.insuranceCategoryItemService.createInsuranceCategoryItems$(insuranceCategorys, {
        noSelectionLabel: this.noSelectionLabel,
        includeNoSelectionOption: this.includeNoSelectionOption,
        usePublicLabel: this.usePublicLabels,
        otherOptionLabel: this.otherOptionLabel,
        includeOtherOption: this.includeOtherOption,
      })),
    );
  }

  private searchInsuranceCategorySuggestions$(query: string | null): Observable<InsuranceCategory[]> {
    const insuranceCategorySearch: InsuranceCategorySearch = {
      internalNameContains: query,
    } as InsuranceCategorySearch;
    // 2021-05 increase asked (28 in db), maybe a drop down with explicit search field within popup would be better next time
    const pagination = SimplePaginationUtils.newPagination<any>(40);
    return this.insuranceCategoryService.searchInsuranceCategorys$(insuranceCategorySearch, pagination).pipe(
      map(r => r.list),
    );
  }

  onModelChange(value: Ref<InsuranceCategory> | string) {
    this.fireChange(value);
  }
}
