<div class="trustee-contact-type-select">
  <p-dropdown
    [options]="allItems"
    [ngModel]="selectedValue"
    (ngModelChange)="onChange($event)"
    [readonly]="disabled"
    [styleClass]="hasError ? 'p-state-error' : ''"
    appendTo="body"
    optionLabel="label"
    optionValue="value"
  ></p-dropdown>
</div>
