export enum CustomerEventType {
  CUSTOMER_CREATED = 'CUSTOMER_CREATED',
  CUSTOMER_DELETED = 'CUSTOMER_DELETED',
  CUSTOMER_UPDATED = 'CUSTOMER_UPDATED',
  CUSTOMER_STATUS_UPDATED = 'CUSTOMER_STATUS_UPDATED',
  CREDIT_SUBSCRIPTION_CREATED = 'CREDIT_SUBSCRIPTION_CREATED',
  CREDIT_SUBSCRIPTION_DELETED = 'CREDIT_SUBSCRIPTION_DELETED',
  CREDIT_SUBSCRIPTION_UPDATED = 'CREDIT_SUBSCRIPTION_UPDATED',
  CREDIT_SUBSCRIPTION_STATUS_UPDATED = 'CREDIT_SUBSCRIPTION_STATUS_UPDATED',
  INSURANCE_SUBSCRIPTION_CREATED = 'INSURANCE_SUBSCRIPTION_CREATED',
  INSURANCE_SUBSCRIPTION_DELETED = 'INSURANCE_SUBSCRIPTION_DELETED',
  INSURANCE_SUBSCRIPTION_UPDATED = 'INSURANCE_SUBSCRIPTION_UPDATED',
  INSURANCE_SUBSCRIPTION_STATUS_UPDATED = 'INSURANCE_SUBSCRIPTION_STATUS_UPDATED',
  INVESTMENT_SUBSCRIPTION_CREATED = 'INVESTMENT_SUBSCRIPTION_CREATED',
  INVESTMENT_SUBSCRIPTION_DELETED = 'INVESTMENT_SUBSCRIPTION_DELETED',
  INVESTMENT_SUBSCRIPTION_UPDATED = 'INVESTMENT_SUBSCRIPTION_UPDATED',
  INVESTMENT_SUBSCRIPTION_STATUS_UPDATED = 'INVESTMENT_SUBSCRIPTION_STATUS_UPDATED',
}
