import {Injectable} from '@angular/core';
import {WsAppPreference} from '@lifeislife/lifeislife-ws-api';
import {RequestService} from '../../../service/request.service';
import {Observable} from 'rxjs';
import {SimplePaginationUtils} from '../../../private_util/simple-pagination-utils';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {SimplePagination} from '../../../../util/pagination/simple-pagination';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {FrontendResourceType} from '../../../../util/cache/cache-resource-type';
import {HttpHeaders} from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class AppPreferenceWsClient extends CachedWsResourceClient<WsAppPreference, string> {

  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private injectedAppConfigService: AppConfigService) {
    super('/internal/preference', injectedAppConfigService, injectedRequestService, injectedAuthService,
      FrontendResourceType.PREFERENCE, injectectedCacheService);
  }

  getDbPreference$(name: string, forceFetch?: boolean): Observable<WsAppPreference> {
    const url = `${this.getResourceUrl()}/${name}`;
    return this.requestService.sendRequest<WsAppPreference>({
      method: 'GET', url: url,
    }, this.injectedAuthService.getAuth());
  }

  deleteDbPreference$(name: string): Observable<any> {
    const url = `${this.getResourceUrl()}/${name}`;
    return this.requestService.sendRequest<any>({
      method: 'DELETE', url: url,
    }, this.injectedAuthService.getAuth());
  }

  getPreferenceValue$(name: string): Observable<string> {
    const url = `${this.getResourceUrl()}/${name}/value`;
    return this.requestService.sendRequest<string>({
      method: 'GET', url: url, responseType: 'text',
      headers: new HttpHeaders().append('accept', 'text/plain'),
    }, this.injectedAuthService.getAuth());
  }

  getPreferenceNames(pagination: SimplePagination, query?: string, all ?: boolean): Observable<string[]> {
    const url = `${this.getResourceUrl()}/names`;
    let queryParams = SimplePaginationUtils.toQueryParams(pagination)
      .append('all', all ? 'true' : 'false');
    if (query) {
      queryParams = queryParams.append('query', query);
    }
    return this.requestService.sendRequest<string[]>({
      method: 'GET', url: url,
      params: queryParams,
    }, this.injectedAuthService.getAuth());
  }

}
