import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SigningPackage, SigningPackageStatus} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, publishReplay, refCount} from 'rxjs/operators';

@Component({
  selector: 'llc-signing-package-list-item',
  templateUrl: './signing-package-list-item.component.html',
  styleUrls: ['./signing-package-list-item.component.scss'],
})
export class SigningPackageListItemComponent implements OnInit {

  @Input()
  set package(value: SigningPackage) {
    this.package$.next(value);
  }

  @Input()
  showStatus: boolean;
  @Input()
  showCreated: boolean;
  @Input()
  showUpdate: boolean;
  @Input()
  showDelete: boolean;

  @Output()
  deleteClick = new EventEmitter();

  package$ = new BehaviorSubject<SigningPackage>(null);

  packageIcon$: Observable<string>;

  constructor() {
  }

  ngOnInit(): void {
    this.packageIcon$ = this.package$.pipe(
      map(p => this.getIconCssClass(p)),
      publishReplay(1), refCount(),
    );
  }

  onDeleteClick(event: MouseEvent) {
    this.deleteClick.next(true);
    event.stopPropagation();
    event.preventDefault();
  }

  private getIconCssClass(signingPackage: SigningPackage) {
    if (signingPackage == null) {
      return null;
    }
    switch (signingPackage.status) {
      case SigningPackageStatus.DRAFT:
      case SigningPackageStatus.WAIITING_SIGNATURE:
        return `fa fa-folder-o`;
      default:
        return `fa fa-folder`;
    }
  }
}
