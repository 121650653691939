import {WsMessageChannel, WsNotification, WsNotificationSearch} from '@lifeislife/lifeislife-ws-api';
import {ContactNotification} from '../../domain/notification/contact-notification';
import {NotificationSearch} from '../../domain/notification/notification-search';
import {EventRecordConverter} from '../event/event-record-converter';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {ContactSearchConverter} from '../contact/contact-search.converter';

export class NotificationConverter {

  static convertIn(value: WsNotification): ContactNotification {
    return {
      id: value.id,
      contactRef: value.contactWsRef,
      title: value.title,
      body: value.body,
      archived: value.archived,
      acknowledged: value.acknowledged,
      eventRecordRef: value.eventRecordWsRef,
      dispatchedChannel: EnumConverterUtils.convertValue(value.dispatchedChannel, WsMessageChannel),
    };
  }

  static convertOut(value: ContactNotification): WsNotification {
    return {
      id: value.id,
      contactWsRef: value.contactRef,
      title: value.title,
      body: value.body,
      archived: value.archived,
      acknowledged: value.acknowledged,
      eventRecordWsRef: value.eventRecordRef,
      dispatchedChannel: EnumConverterUtils.convertValue(value.dispatchedChannel, MessageChannel),
    };
  }


  static convertFilterIn(value?: WsNotificationSearch): NotificationSearch {
    if (value == null) {
      return undefined;
    }
    return {
      anyTextContains: value.anyTextContains,
      contactSearch: ContactSearchConverter.toContactSearch(value.wsContactSearch),
      eventRecordSearch: EventRecordConverter.convertFilterIn(value.eventRecordSearch),
      archived: value.archived,
      acknowledged: value.acknowledged,
    };
  }

  static convertFilterOut(value: NotificationSearch): WsNotificationSearch {
    if (value == null) {
      return undefined;
    }
    return {
      anyTextContains: value.anyTextContains,
      wsContactSearch: ContactSearchConverter.toWsContactSearch(value.contactSearch),
      eventRecordSearch: EventRecordConverter.convertFilterOut(value.eventRecordSearch),
      archived: value.archived,
      acknowledged: value.acknowledged,
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsNotification]: keyof ContactNotification } {
    return {
      id: 'id',
      eventRecordWsRef: 'eventRecordRef',
      contactWsRef: 'contactRef',
      acknowledged: 'acknowledged',
      dispatchedChannel: 'dispatchedChannel',
      archived: 'archived',
      body: 'body',
      title: 'title',
    };
  }

}
