import {CacheResourceType} from './cache-resource-type';
import {Observable} from 'rxjs';
import {WithId} from '../../client/domain/with-id';

export abstract class ResourceCache {

  abstract hasCachedValue$(type: CacheResourceType, id: number): Observable<boolean>;

  abstract getCachedValue$<T extends WithId>(type: CacheResourceType, id: number): Observable<T>;

  abstract cacheRequest$<T extends WithId>(type: CacheResourceType, id: number, request$: Observable<T>): Observable<T>;

  abstract cacheValue<T extends WithId>(type: CacheResourceType, id: number, value: T): void;

  abstract clear(type: CacheResourceType, id: number): void;

  abstract clearAll(type: CacheResourceType): void;

  // Allow to mark a resource type as not cachable
  abstract skip(type: CacheResourceType): void;

  // Allow to mark a resource type as cachable
  abstract unskip(type: CacheResourceType): void;

  abstract isSkipped(type: CacheResourceType): boolean;

}
