import {Injectable} from '@angular/core';
import {WsRef} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {Ref} from '../../domain/shared/ref';
import {InsuranceSubscriptionSnapshotWsClient} from '../../client/resources/front/insurance/insurance-subscription-snapshot-ws-client';
import {InsuranceSubscriptionSnapshot} from '../../domain/insurance/insurance-subscription-snapshot';
import {InsuranceSubscriptionSnapshotConverter} from './insurance-subscription-snapshot-converter';
import {InsuranceSubscriptionSnapshotSearch} from '../../domain/insurance/insurance-subscription-snapshot-search';
import {InsuranceSubscriptionSnapshotSearchConverter} from './insurance-subscription-snapshot-search-converter';
import {
  WsInsuranceSubscriptionSnapshot,
  WsInsuranceSubscriptionSnapshotSearch,
} from '@lifeislife/lifeislife-ws-api';

@Injectable({
  providedIn: 'root',
})
export class InsuranceSubscriptionSnapshotService {

  constructor(private client: InsuranceSubscriptionSnapshotWsClient,
  ) {
  }

  getInsuranceSubscriptionSnapshot$(ref: Ref<InsuranceSubscriptionSnapshot>, forceFetch?: boolean): Observable<InsuranceSubscriptionSnapshot> {
    return ref == null ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsSnapshot => InsuranceSubscriptionSnapshotConverter.convertIn(wsSnapshot)),
    );
  }


  searchInsuranceSubscriptionSnapshots$(searchFilter: InsuranceSubscriptionSnapshotSearch, pagination: SimplePagination)
    : Observable<SearchResult<InsuranceSubscriptionSnapshot>> {
    const wsSearch = InsuranceSubscriptionSnapshotSearchConverter.convertFilterOut(searchFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  serializeFilter(searchFilter: InsuranceSubscriptionSnapshotSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(searchFilter);
    const wsSearchFilter = InsuranceSubscriptionSnapshotSearchConverter.convertFilterOut(searchFilter);
    return JSON.stringify(wsSearchFilter);
  }

  deserializeFilter(valueString: string | null): InsuranceSubscriptionSnapshotSearch {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsInsuranceSubscriptionSnapshotSearch = JSON.parse(valueString);
      const filter = InsuranceSubscriptionSnapshotSearchConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsInsuranceSubscriptionSnapshot>>): Observable<SearchResult<InsuranceSubscriptionSnapshot>> {
    const rowTasks = results.list.map(ref => this.getInsuranceSubscriptionSnapshot$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<InsuranceSubscriptionSnapshot>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<InsuranceSubscriptionSnapshot>>>{
          list: rows,
        }),
      ));
  }
}
