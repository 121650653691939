import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {AuthService} from '@lifeislife/lifeislife-domain';
import {MessageService} from 'primeng/api';
import {AppShellContextProviderService} from '../app-shell-context-provider.service';
import {delay, map, publishReplay, refCount} from 'rxjs/operators';

@Component({
  selector: 'app-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
  providers: [AppShellContextProviderService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppShellComponent implements OnInit, OnDestroy {

  loggedIn$: Observable<boolean>;

  // User context set: we know for which customer/trustee the user connects
  activeContextSet$: Observable<boolean>;

  allSet$: Observable<boolean>;
  showMenu$: Observable<boolean>;


  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private shellContextProvider: AppShellContextProviderService,
  ) {
  }

  ngOnInit() {
    this.loggedIn$ = this.authService.getAuthenticatedObservable();
    this.activeContextSet$ = this.shellContextProvider.isActiveContextSet$();

    this.allSet$ = combineLatest(
      this.loggedIn$,
      this.activeContextSet$,
    ).pipe(
      map(r => !r.includes(false)),
      delay(0),
      publishReplay(1), refCount(),
    );

    this.showMenu$ = this.shellContextProvider.getActiveContext$().pipe(
      map(c => c != null && c.activeRole !== null),
      delay(0),
      publishReplay(1), refCount(),
    );

  }

  ngOnDestroy() {
  }

  onToastClick(event: MouseEvent) {
    this.messageService.clear();
  }


}
