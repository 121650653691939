<div id="google-maps" #googleMapsContainer>

    <div class="loading ui-widget" [hidden]="!loading">
        Chargement ....
    </div>
    <div class="error ui-widget" *ngIf="errored">
        Impossible de charger la carte
        <div cass="error-message" [hidden]="errorMessage == null">
            {{ errorMessage }}
        </div>
    </div>
</div>
