import {WsSubscriptionSigningPackage, WsSubscriptionSigningPackageSearch} from '@lifeislife/lifeislife-ws-api';
import {SubscriptionConverter} from './subscription-converter';
import {SubscriptionSigningPackage} from '../../domain/corebusiness/subscription-signing-package';
import {SigningPackageConverter} from '../file/signing-package.converter';
import {SubscriptionSigningPackageSearch} from '../../domain/corebusiness/subscription-signing-package-search';

export class SubscriptionSigningPackageConverter {

  static convertIn(value: WsSubscriptionSigningPackage): SubscriptionSigningPackage {
    return {
      id: value.id,
      signingPackageRef: value.signingPackageWsRef,
      subscriptionRef: value.subscriptionWsRef,
    };
  }

  static convertOut(value: SubscriptionSigningPackage): WsSubscriptionSigningPackage {
    return {
      id: value.id,
      signingPackageWsRef: value.signingPackageRef,
      subscriptionWsRef: value.subscriptionRef,
    };
  }


  static convertFilterIn(value?: WsSubscriptionSigningPackageSearch): SubscriptionSigningPackageSearch {
    if (value == null) {
      return undefined;
    }
    return {
      signingPackageSearch: SigningPackageConverter.convertFilterIn(value.wsSigningPackageSearch),
      subscriptionSearch: SubscriptionConverter.convertFilterIn(value.wsSubscriptionSearch),
    };
  }

  static convertFilterOut(value: SubscriptionSigningPackageSearch): WsSubscriptionSigningPackageSearch {
    if (value == null) {
      return undefined;
    }
    return {
      wsSigningPackageSearch: SigningPackageConverter.convertFilterOut(value.signingPackageSearch),
      wsSubscriptionSearch: SubscriptionConverter.convertFilterOut(value.subscriptionSearch),
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsSubscriptionSigningPackage]: keyof SubscriptionSigningPackage } {
    return {
      id: 'id',
      subscriptionWsRef: 'subscriptionRef',
      signingPackageWsRef: 'signingPackageRef',
    };
  }

}
