import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {BreadcrumbMenuService} from './breadcrumb-menu.service';
import {Observable} from 'rxjs';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit {

  menu$: Observable<MenuItem[]>;

  constructor(
    private menuService: BreadcrumbMenuService,
  ) {
  }

  ngOnInit() {
    this.menu$ = this.menuService.menu$;
  }

}
