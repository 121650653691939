import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserInviteConverter} from './user-invite.converter';
import {UserInviteCodeConverter} from './user-invite-code.converter';
import {UserInviteWsClient} from '../../client/resources/front/user-invite-ws-client';
import {TrusteeInvite} from '../../domain/invite/trustee-invite';
import {CustomerInvite} from '../../domain/invite/customer-invite';
import {TrusteeInviteCode} from '../../domain/invite/trustee-invite-code';
import {CustomerInviteCode} from '../../domain/invite/customer-invite-code';

@Injectable({
  providedIn: 'root',
})
export class UserInviteService {

  constructor(private client: UserInviteWsClient,
  ) {
  }

  inviteAccountant$(invite: TrusteeInvite): Observable<TrusteeInviteCode> {
    const wsUserInvite = UserInviteConverter.convertTrusteeInviteOut(invite);
    return this.client.inviteTrusteeContact$(wsUserInvite).pipe(
      map(wsCode => UserInviteCodeConverter.convertTrusteeInviteCodeIn(wsCode)),
    );
  }

  inviteCustomer$(invite: CustomerInvite): Observable<CustomerInviteCode> {
    const wsUserInvite = UserInviteConverter.convertCustomerInviteOut(invite);
    return this.client.inviteCustomer$(wsUserInvite).pipe(
      map(wsCode => UserInviteCodeConverter.convertCustomerInviteCodeIn(wsCode)),
    );
  }

  inviteAdmin$(invite: CustomerInvite): Observable<CustomerInviteCode> {
    const wsUserInvite = UserInviteConverter.convertCustomerInviteOut(invite);
    return this.client.inviteCustomer$(wsUserInvite).pipe(
      map(wsCode => UserInviteCodeConverter.convertCustomerInviteCodeIn(wsCode)),
    );
  }

}
