import {Injectable} from '@angular/core';
import {BatchWsClient} from '../../client/resources/front/batch-ws-client';
import {map} from 'rxjs/operators';
import {BatchConverter} from './batch-converter';
import {Observable} from 'rxjs';
import {BatchJobName} from '../../domain/batch/batch-job-name';
import {BatchExecution} from '../../domain/batch/batch-execution';
import {DataImportWsClient} from '../../client/resources/internal/data-import-ws-client';

@Injectable({
  providedIn: 'root',
})
export class BatchService {

  constructor(
    private client: BatchWsClient,
    private dataImportClient: DataImportWsClient,
  ) {
  }

  getJobNames$(): Observable<BatchJobName[]> {
    return this.client.getJobNames$().pipe(
      map(names => names.map(n => BatchConverter.fromWsJobName(n))),
    );
  }

  getJobExecutions$(jobname: string, offset = 0, length?: number): Observable<number[]> {
    return this.client.getJobExecutionIdList$(jobname, offset, length);
  }

  getJobEecution(id: number): Observable<BatchExecution> {
    return this.client.getBatchExecution$(id).pipe(
      map(ws => BatchConverter.fromWsBatchExecution(ws)),
    );
  }

  startAgInsuranceLifeBatchJob$(file: File): Observable<any> {
    return this.dataImportClient.startAgBatch(file);
  }

  startInsuranceImportBatchJob$(file: File): Observable<any> {
    return this.dataImportClient.startGenericInsuranceBatch(file);
  }
}
