import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {map, publishReplay, refCount, switchMap, withLatestFrom} from 'rxjs/operators';
import {SimplePaginationUtils} from '@lifeislife/lifeislife-domain';
import {Contact, ContactSearch, ContactService, Ref} from '@lifeislife/lifeislife-domain';
import {ContactSelectItem} from '../contact-select-item';
import {ContactItemsService} from '../contact-items.service';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

@Component({
  selector: 'app-contact-dropdown-select',
  templateUrl: './contact-dropdown-select.component.html',
  styleUrls: ['./contact-dropdown-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ContactDropdownSelectComponent),
    multi: true,
  }],
})
export class ContactDropdownSelectComponent implements OnInit, ControlValueAccessor {

  @Input()
  disabled = false;
  @Input()
  hasError = false;
  @Input()
  includeNoSelectionOption = false;
  @Input()
  noSelectionLabel = '<Aucun>';
  @Input()
  pageSize = 10;
  @Input()
  appendTo = 'body';
  @Input()
  showCompanyName = false;

  @Input()
  set filter(value: ContactSearch) {
    this.filterSource$.next(value);
  }

  filterSource$ = new BehaviorSubject<ContactSearch>(null);
  contactRefSource$ = new BehaviorSubject<Ref<Contact>>(null);
  searchQuerySource$ = new Subject<string>();

  selectedItem$: Observable<ContactSelectItem>;
  suggestions$: Observable<ContactSelectItem[]>;

  private onChangeFunction: any;
  private onTouchedFunction: any;

  constructor(private contactService: ContactService,
              private itemService: ContactItemsService) {
  }

  ngOnInit() {
    this.selectedItem$ = this.contactRefSource$.pipe(
      switchMap(ref => ref == null ? of(null) : this.contactService.getContact$(ref)),
      map(contact => this.itemService.createContactItem(contact, {
        noSelectionItemLabel: this.noSelectionLabel,
        showCompanyName: this.showCompanyName,
      })),
      publishReplay(1), refCount(),
    );
    this.suggestions$ = this.searchQuerySource$.pipe(
      withLatestFrom(this.filterSource$),
      switchMap(results => this.createSuggestionItems$(results[0], results[1])),
    );
  }

  writeValue(value: Ref<Contact>): void {
    this.contactRefSource$.next(value);
  }

  registerOnChange(fn: any): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFunction = fn;
  }

  onItemSelect(item: ContactSelectItem) {
    this.contactRefSource$.next(item.value);
    this.fireChanges(item.value);
  }

  onItemClear() {
    this.fireChanges(null);
  }

  searchAllSuggestions() {
    this.searchQuerySource$.next(null);
  }

  searchSuggestions(query: string) {
    this.searchQuerySource$.next(query);
  }

  private fireChanges(value: Ref<Contact>) {
    if (this.onTouchedFunction) {
      this.onTouchedFunction();
    }
    if (this.onChangeFunction) {
      this.onChangeFunction(value);
    }
  }

  private createSuggestionItems$(searchQuery: string, contactFilter: ContactSearch): Observable<ContactSelectItem[]> {
    const updatedFilter = Object.assign({}, contactFilter, <Partial<ContactSearch>>{
      lastNameContains: searchQuery,
    });
    const pagination = SimplePaginationUtils.newPagination(20);
    return this.contactService.searchContacts$(updatedFilter, pagination).pipe(
      map(results => results.list),
      map(contacts => this.itemService.createContactItems(contacts, {
        includeNoSelectionItem: this.includeNoSelectionOption,
        noSelectionItemLabel: this.noSelectionLabel,
        showCompanyName: this.showCompanyName,
      })));
  }
}

