import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactViewComponent} from './contact-view/contact-view.component';
import {ContactDropdownSelectComponent} from './contact-dropdown-select/contact-dropdown-select.component';
import {InputTextModule} from 'primeng/inputtext';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ContactFormComponent} from './contact-form/contact-form.component';
import {FormsModule} from '@angular/forms';
import {MainModule} from '../../main.module';
import {PersonTitleModule} from '../person-title/person-title.module';
import {MainCountryModule} from '../country/main-country.module';
import {ContactColumnComponent} from './contact-table/contact-column/contact-column.component';
import {ContactInfoComponent} from './contact-info/contact-info.component';
import {DateModule} from '@lifeislife/lifeislife-components';
import {RouterModule} from '@angular/router';
import {CustomerContactTypeBadgeModule} from '../customer-contact/customer-contact-type-badge/customer-contact-type-badge.module';
import {TrusteeContactTypeBadgeModule} from '../trustee-contact/trustee-contact-type-badge/trustee-contact-type-badge.module';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {MaritalStatusSelectModule} from '../marital-status/marital-status-select/marital-status-select.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        DateModule,
        AutoCompleteModule,
        InputTextModule,
        InputSwitchModule,
        PersonTitleModule,
        MainCountryModule,
        MainModule,
        RouterModule,
        CustomerContactTypeBadgeModule,
        TrusteeContactTypeBadgeModule,
        OverlayPanelModule,
        MaritalStatusSelectModule,
    ],
  declarations: [ContactViewComponent, ContactDropdownSelectComponent, ContactFormComponent,
    ContactColumnComponent,
    ContactInfoComponent,
  ],
  exports: [ContactViewComponent, ContactDropdownSelectComponent, ContactFormComponent,
    ContactColumnComponent, ContactInfoComponent,
  ],
})
export class MainContactComponentsModule {
}
