import {WsDaoEventEntityType, WsLifeisLifeEventRecord, WsLifeisLifeEventRecordSearch} from '@lifeislife/lifeislife-ws-api';
import {EventRecord} from '../../domain/event/event-record';
import {DateTimeSearchConverter} from '../shared/date-time-search-converter';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {EventRecordSearch} from '../../domain/event/event-record-search';
import {CustomerSearchConverter} from '../customer/customer-search.converter';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {ApiEntityType} from '../../domain/api-entity-type';
import {ContactSearchConverter} from '../contact/contact-search.converter';

export class EventRecordConverter {

  static convertIn(value: WsLifeisLifeEventRecord): EventRecord {
    return {
      id: value.id,
      dateTime: DateConverterUtils.parseIsoDateTime(value.dateTime),
      eventTypeName: value.eventTypeName,
      message: value.message,
      newValueMessage: value.newValueMessage,
      oldValueMessage: value.oldValueMessage,
      newValueString: value.newValueString,
      oldValueString: value.oldValueString,
      recordEntityId: value.recordEntityId,
      scopedCustomerRef: value.scopedCustomerRef,
      updaterContactRef: value.updaterContactRef,
      version: value.version,
      recordEntityType: EnumConverterUtils.convertValue(value.recordEntityType, ApiEntityType),
    };
  }

  static convertOut(value: EventRecord): WsLifeisLifeEventRecord {
    return {
      id: value.id,
      dateTime: DateConverterUtils.formatDateToIsoDateTime(value.dateTime),
      eventTypeName: value.eventTypeName,
      message: value.message,
      newValueMessage: value.newValueMessage,
      oldValueMessage: value.oldValueMessage,
      newValueString: value.newValueString,
      oldValueString: value.oldValueString,
      recordEntityId: value.recordEntityId,
      scopedCustomerRef: value.scopedCustomerRef,
      updaterContactRef: value.updaterContactRef,
      version: value.version,
      recordEntityType: EnumConverterUtils.convertValue(value.recordEntityType, WsDaoEventEntityType),
    };
  }


  static convertFilterIn(value?: WsLifeisLifeEventRecordSearch): EventRecordSearch {
    if (value == null) {
      return undefined;
    }
    return {
      customerSearch: CustomerSearchConverter.toCustomerSearch(value.wsCustomerSearch),
      dateTimeSearch: DateTimeSearchConverter.convertIn(value.dateTimeSearch),
      eventEntityId: value.eventEntityId,
      eventTypeName: value.eventTypeName,
      newValueContains: value.newValueContains,
      oldValueContains: value.oldValueContains,
      updaterContactSearch: ContactSearchConverter.toContactSearch(value.updaterContactSearch),
    };
  }

  static convertFilterOut(value: EventRecordSearch): WsLifeisLifeEventRecordSearch {
    if (value == null) {
      return undefined;
    }
    return {
      wsCustomerSearch: CustomerSearchConverter.toWsCustomerSearch(value.customerSearch),
      dateTimeSearch: DateTimeSearchConverter.convertOut(value.dateTimeSearch),
      eventEntityId: value.eventEntityId,
      eventTypeName: value.eventTypeName,
      newValueContains: value.newValueContains,
      oldValueContains: value.oldValueContains,
      updaterContactSearch: ContactSearchConverter.toWsContactSearch(value.updaterContactSearch),
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsLifeisLifeEventRecord]: keyof EventRecord } {
    return {
      id: 'id',
      version: 'version',
      updaterContactRef: 'updaterContactRef',
      scopedCustomerRef: 'scopedCustomerRef',
      recordEntityId: 'recordEntityId',
      oldValueMessage: 'oldValueMessage',
      newValueMessage: 'newValueMessage',
      oldValueString: 'oldValueString',
      newValueString: 'newValueString',
      message: 'message',
      eventTypeName: 'eventTypeName',
      dateTime: 'dateTime',
      recordEntityType: 'recordEntityType',
    };
  }

}
