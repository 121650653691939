import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {InsuranceProvider, InsuranceProviderSearch, InsuranceProviderService, Ref, SimplePaginationUtils} from '@lifeislife/lifeislife-domain';
import {SelectItem} from 'primeng/api';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {InsuranceProviderItemService} from '../insurance-provider-item.service';
import {InsuranceProviderSelectItem} from '../insurance-provider-select-item';

@Component({
  selector: 'llc-insurance-provider-select',
  templateUrl: './insurance-provider-select.component.html',
  styleUrls: ['./insurance-provider-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InsuranceProviderSelectComponent),
    multi: true,
  }],
})
export class InsuranceProviderSelectComponent implements OnInit, ControlValueAccessor {

  @Input()
  disabled = false;
  @Input()
  readonly = false;
  @Input()
  hasError = false;
  @Input()
  completeMethod?: (string) => Observable<InsuranceProvider[]>;
  @Input()
  includeNoSelectionOption = false;
  @Input()
  noSelectionLabel = '<Aucun>';
  @Input()
  appendTo = 'body';
  @Input()
  usePublicLabels: boolean;

  value$ = new BehaviorSubject<Ref<InsuranceProvider> | null>(null);
  sugestionsQuery$ = new Subject<string | null>();
  selectedItem$: Observable<InsuranceProviderSelectItem | null>;
  suggestions$: Observable<InsuranceProviderSelectItem[]>;

  private onChangeFunction: Function;
  private onTouchedFunction: Function;

  constructor(
    private insuranceProviderService: InsuranceProviderService,
    private insuranceProviderItemService: InsuranceProviderItemService,
  ) {
  }

  ngOnInit() {
    this.selectedItem$ = this.value$.pipe(
      switchMap(ref => this.fetchInsuranceProvider$(ref)),
      switchMap(insuranceProvider => this.insuranceProviderItemService.createInsuranceProviderItem$(insuranceProvider, {
        noSelectionLabel: '',
        usePublicLabels: this.usePublicLabels,
      })),
      publishReplay(1), refCount(),
    );
    this.suggestions$ = this.sugestionsQuery$.pipe(
      switchMap(query => this.searchSuggestions$(query)),
      publishReplay(1), refCount(),
    );
  }

  writeValue(ref: Ref<InsuranceProvider>): void {
    this.value$.next(ref);
  }

  registerOnChange(fn: any): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFunction = fn;
  }


  onItemSelect(item: SelectItem) {
    const insuranceProviderRef: Ref<InsuranceProvider> = item.value;
    this.value$.next(insuranceProviderRef);
    this.fireChange(insuranceProviderRef);
  }

  onItemClear() {
    this.fireChange(null);
  }


  searchAllSuggestions() {
    this.sugestionsQuery$.next(null);
  }

  searchSuggestions(query: string | null) {
    this.sugestionsQuery$.next(query);
  }

  private fireChange(value: Ref<InsuranceProvider>) {
    if (this.onTouchedFunction) {
      this.onTouchedFunction();
    }
    if (this.onChangeFunction) {
      this.onChangeFunction(value);
    }
  }

  private fetchInsuranceProvider$(ref: Ref<InsuranceProvider>) {
    return ref == null ? of(null) : this.insuranceProviderService.getInsuranceProvider$(ref);
  }

  private searchSuggestions$(query: string | null): Observable<InsuranceProviderSelectItem[]> {
    let insuranceProviderSuggestions$: Observable<InsuranceProvider[]>;
    if (this.completeMethod) {
      insuranceProviderSuggestions$ = this.completeMethod(query);
    } else {
      insuranceProviderSuggestions$ = this.searchInsuranceProviderSuggestions$(query);
    }
    return insuranceProviderSuggestions$.pipe(
      switchMap(insuranceProviders => this.insuranceProviderItemService.createInsuranceProviderItems$(insuranceProviders, {
        noSelectionLabel: this.noSelectionLabel,
        includeNoSelectionOption: this.includeNoSelectionOption,
        usePublicLabels: this.usePublicLabels,
      })),
    );
  }

  private searchInsuranceProviderSuggestions$(query: string | null): Observable<InsuranceProvider[]> {
    const insuranceProviderSearch: InsuranceProviderSearch = {
      productProviderSearch: {
        nameContains: query,
        active: true,
      },
    } as InsuranceProviderSearch;
    const pagination = SimplePaginationUtils.newPagination<any>(10);
    return this.insuranceProviderService.searchInsuranceProviders$(insuranceProviderSearch, pagination).pipe(
      map(r => r.list),
    );
  }
}
