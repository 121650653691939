import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsCustomer, WsCustomerGroupSummary, WsRef} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {Customer} from '../../domain/customer/customer';
import {CustomerSearch} from '../../domain/customer/customer-search';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {CustomerConverter} from './customer.converter';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {CustomerWsClient} from '../../client/resources/front/customer/customer-ws-client';
import {CustomerSearchConverter} from './customer-search.converter';
import {CustomerGroupSummaryConverter} from './customer-group-summary.converter';
import {CustomerGroupSummary} from '../../domain/customer/customer-group-summary';
import {CustomerStatus} from '../../domain/customer/customer-status';
import {InsuranceSubscriptionSearchConverter} from '../insurance/insurance-subscription-search-converter';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {

  constructor(private client: CustomerWsClient,
  ) {
  }

  getCustomer$(ref: Ref<Customer>, forceFetch?: boolean): Observable<Customer> {
    return this.client.doGet(ref.id, forceFetch).pipe(
      map(ws => CustomerConverter.convertIn(ws)),
    );
  }

  refetchCustomer$(ref: Ref<Customer>): Observable<Customer> {
    return this.getCustomer$(ref, true);
  }


  getCustomerLabel$(ref: Ref<Customer>): Observable<string> {
    return this.getCustomer$(ref).pipe(
      map(customer => customer.name),
    );
  }

  getCustomerName(customer: Customer): string {
    if (customer == null || customer.id == null) {
      return `Nouveau client`;
    }
    if (customer.name == null) {
      return `<Aucune dénomination>`;
    }
    return `${customer.name}`;
  }

  getCustomerStatusLabel(status: CustomerStatus): string {
    if (status == null) {
      return '';
    }
    switch (status) {
      case CustomerStatus.ACTIVE:
        return 'Actif';
      case CustomerStatus.INACTIVE:
        return 'Inactif';
      case CustomerStatus.ARCHIVED:
        return 'Archivé';
      case CustomerStatus.PROSPECT:
        return 'Prospect';
      default:
        throw new Error(`Unahdnled status ${status}`);
    }
  }

  searchCustomers$(customerSearch: CustomerSearch, pagination: SimplePagination): Observable<SearchResult<Customer>> {
    const wsSearch = CustomerSearchConverter.toWsCustomerSearch(customerSearch);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  searchCustomersGroupSummaries$(customerSearch: CustomerSearch, pagination: SimplePagination)
    : Observable<SearchResult<CustomerGroupSummary>> {
    const wsSearch = CustomerSearchConverter.toWsCustomerSearch(customerSearch);
    return this.client.doSearchGroupSummaries(wsSearch, pagination).pipe(
      switchMap(results => this.convertGroupSummariesResultsIn$(results)),
    );
  }

  validateCustomer$(customer: Customer): Observable<ValidationResult<Customer>> {
    const wsCustomer = CustomerConverter.convertOut(customer);
    return this.client.doValidate(wsCustomer).pipe(
      map((results: WsCustomer | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveCustomer$(customer: Customer): Observable<Ref<Customer>> {
    const wsCustomer = CustomerConverter.convertOut(customer);
    return this.client.doSave(wsCustomer);
  }

  deleteCustomer$(id: number): Observable<any> {
    return this.client.doRemove(id);
  }

  downloadCustomerSubscriptionsSnapshot(ref: Ref<Customer>): Observable<Blob> {
    if (!isValidRef(ref)) {
      throw new Error(`Invalid ref`);
    }
    return this.client.searchSubscriptionSnapshotReport$(ref.id);
  }

  downloadCustomerSubscriptionsSnaphots$(customerSearch: CustomerSearch): Observable<Blob> {
    const wsCustomerSearch = CustomerSearchConverter.toWsCustomerSearch(customerSearch);
    return this.client.searchSubscriptionSnapshotReports$(wsCustomerSearch);
  }

  downloadCustomerMailingContactReport$(customerSearch: CustomerSearch): Observable<Blob> {
    const wsCustomerSearch = CustomerSearchConverter.toWsCustomerSearch(customerSearch);
    return this.client.searchMailingContactsReport$(wsCustomerSearch);
  }

  //
  // cloneCustomer$(sourceRef: Ref<Customer>, targetRef: Ref<Customer>, options: CustomerCopyOptions): Observable<any> {
  //   if (!isValidRef(sourceRef) || !isValidRef(targetRef)) {
  //     return throwError(new Error('Invalid ref'));
  //   }
  //   const wsOptions = CustomerConverter.convertCopyOptionsOut(options);
  //   return this.client.clone(sourceRef.id, targetRef.id, wsOptions);
  // }

  getValidationPropertyNameMappings() {
    return CustomerConverter.getValidationPropertyNameMappings();
  }


  private createValidationResult(results: WsCustomer | WsConstraintViolation[]): ValidationResult<Customer> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsCustomer => CustomerConverter.convertIn(wsCustomer),
      propertyNameMappings: CustomerConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsCustomer>>): Observable<SearchResult<Customer>> {
    const rowTasks = results.list.map(ref => this.getCustomer$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<Customer>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<Customer>>>{
          list: rows,
        }),
      ));
  }

  private convertGroupSummariesResultsIn$(results: SearchResult<WsCustomerGroupSummary>) {
    const groupSummaries = results.list.map(wsSummary => CustomerGroupSummaryConverter.convertIn(wsSummary));
    const convertedResult = Object.assign({}, results, {
      list: groupSummaries,
    });
    return of(convertedResult);
  }


}
