/**
 * Created by cghislai on 12/04/16.
 */


import {Directive, HostBinding} from '@angular/core';

@Directive({
  selector: '[libUpperCase]',
})
export class UpperCaseDirective {

  @HostBinding('style.textTransform')
  textTransform = 'uppercase';

  constructor() {
  }

}
