<div class="user-form" *ngIf="user != null">

  <div class="col form-table">

    <div class="row" [class.changed]="propertyDiff?.login">
      <label i18n>
        Login
      </label>
      <div>
        <div>
          <input type="text" pInputText
                 name="nom"
                 class="fill"
                 [readonly]="disabled"
                 [(ngModel)]="user.login"
                 (ngModelChange)="fireChanges()"
                 [class.p-state-error]="validationResults?.propertiesErrors.login != null"
          >
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.login"
        ></validation-error-message>
      </div>
    </div>


    <div class="row" *ngIf="showPassword && !disabled">
      <label i18n>Mot de passe</label>
      <div>
        <div>
          <input type="password" pInputText
                 name="password"
                 class="fill"
                 [(ngModel)]="password1"
                 (ngModelChange)="onPasswordsChange()"
                 required minlength="8"
                 #passwordField="ngModel">
        </div>
        <validation-error-message errorMessage="Mot de passe trop court" i18n-errorMessage
                                  *ngIf="passwordField.errors?.minlength != null"
        ></validation-error-message>
        <validation-error-message errorMessage="Mot de passe requis" i18n-errorMessage
                                  *ngIf="passwordField.errors?.required != null"
        ></validation-error-message>
      </div>
    </div>

    <div class="row" *ngIf="showPassword">
      <label i18n>Vérification</label>
      <div>
        <div>
          <input type="password" pInputText
                 name="password2"
                 class="fill"
                 [(ngModel)]="password2"
                 (ngModelChange)="onPasswordsChange()"
                 [class.p-state-error]="passwordMismatch">
        </div>
        <validation-error-message errorMessage="Mots de passe différents" i18n-errorMessage
                                  *ngIf="passwordMismatch"
        ></validation-error-message>
      </div>
    </div>

    <div class="row" *ngIf="showArchived">
      <label i18n>Archivé</label>
      <div>
        <div>
          <p-inputSwitch name="archived"
                         [(ngModel)]="user.archived"
                         (ngModelChange)="fireChanges()"
                         [readonly]="disabled"
                         [class.p-state-error]="validationResults?.propertiesErrors.archived != null"
          ></p-inputSwitch>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.archived"
        ></validation-error-message>
      </div>
    </div>

  </div>

</div>
