import {User} from '../../domain/user/user';
import {LifeisLifeWsAuth} from '../../client/domain/auth/lifeis-life-ws-auth';
import {Ref} from '../../domain/shared/ref';
import {TrusteeContact} from '../../domain/trustee/trustee-contact';
import {CustomerContact} from '../../domain/customer-contact/customer-contact';
import {Role} from '../../domain/role/role';
import {Contact} from '../../domain/contact/contact';

export class AuthState {
  wsAuth: LifeisLifeWsAuth | null = null;
  user: User | null = null;
  roles: Role[];

  contactRef: Ref<Contact> | null = null;
  trusteeContactRefs: Ref<TrusteeContact>[] = [];
  customerContactRefs: Ref<CustomerContact>[] = [];

  status: 'unset' | 'authenticating' | 'deauthenticated' | 'authenticated' = 'unset';

  lastLogin: string = null;
  lastEmail: string = null;

  errorMessage: string;
  error?: any;

  restoreFromStorageAttempted = false;
  restoreFromQueryTokenAttempted = false;
  lastRefreshOn401AttemptedDate = null;
  inviteRestoreFromQueryTokenAttempted = false;

}
