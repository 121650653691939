import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {MessageTemplate} from '../../domain/mail/message-template';
import {map, tap} from 'rxjs/operators';
import {WsConstraintViolation, WsMessageTemplate, WsMessageTemplateSearch} from '@lifeislife/lifeislife-ws-api';
import {MessageTemplateConverter} from './message-template.converter';
import {ObjectConverterUtil} from '../object-converter-util';
import {ValidationResult} from '../../domain/shared/validation-result';
import {MessageTemplateWsClient} from '../../client/resources/front/message-template-ws-client';
import {MessageTypeWsClient} from '../../client/resources/front/message-type-ws-client';

@Injectable({
  providedIn: 'root',
})
export class MessageTemplateService {


  constructor(private client: MessageTemplateWsClient,
              private typeClient: MessageTypeWsClient,
  ) {
  }

  getMessageTemplate$(id: number, forceFetch?: boolean): Observable<MessageTemplate> {
    return this.client.doGet(id, forceFetch).pipe(
      map(wsMessageTemplate => MessageTemplateConverter.convertIn(wsMessageTemplate)),
    );
  }

  deleteMessageTemplate$(id: number): Observable<any> {
    return this.client.doRemove(id).pipe(
      tap(a => this.typeClient.clearCache()),
    );
  }

  fetchMessageTemplate$(id: number): Observable<MessageTemplate> {
    return this.client.doFetch(id).pipe(
      map(wsMessageTemplate => MessageTemplateConverter.convertIn(wsMessageTemplate)),
    );
  }

  saveMessageTemplate$(value: MessageTemplate): Observable<any> {
    const wsTemplate = MessageTemplateConverter.convertOut(value);
    return this.client.doSave(wsTemplate).pipe(
      tap(a => this.typeClient.clearCache()),
    );
  }


  validateMessageTemplate$(value: MessageTemplate): Observable<ValidationResult<MessageTemplate>> {
    const wsTemplate = MessageTemplateConverter.convertOut(value);
    return this.client.doValidate(wsTemplate).pipe(
      map((results: WsMessageTemplate | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  searchMessageTemplate$(typeName: string): Observable<MessageTemplate> {
    if (typeName == null) {
      return throwError(new Error('invalid ref'));
    }
    const wsSearch: WsMessageTemplateSearch = {
      messageTypeName: typeName,
    };
    return this.client.searchMessageTemplate$(wsSearch).pipe(
      map(wsTemplate => wsTemplate == null ? null : MessageTemplateConverter.convertIn(wsTemplate)),
    );
  }

  searchDefaultMessageTemplate$(typeName: string): Observable<MessageTemplate> {
    if (typeName == null) {
      return throwError(new Error('invalid ref'));
    }
    const wsSearch: WsMessageTemplateSearch = {
      messageTypeName: typeName,
    } as WsMessageTemplateSearch;
    return this.client.searchDefaultMessageTemplate$(wsSearch).pipe(
      map(wsTemplate => wsTemplate == null ? null : MessageTemplateConverter.convertIn(wsTemplate)),
    );
  }


  private createValidationResult(results: WsMessageTemplate | WsConstraintViolation[]): ValidationResult<MessageTemplate> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsTemplate => MessageTemplateConverter.convertIn(wsTemplate),
      propertyNameMappings: MessageTemplateConverter.getValidationPropertyNameMappings(),
    });
  }
}
