import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {catchError, map, switchMap} from 'rxjs/operators';
import {AuthState, LifeisLifeWsAuth, Role, UserAuthService} from '@lifeislife/lifeislife-domain';
import {ApplicationRouteData} from '../routing/application-route-data';
import {ApplicationMenuItemData} from '../routing/application-menu-item-data';
import {AppFeatureService} from './app-feature.service';
import {AppFeature} from '../model/app-feature';


@Injectable({
  providedIn: 'root',
})
export class UserLoggedInRouterGuard implements CanActivate {

  constructor(private userAuthService: UserAuthService,
              private appFeatureService: AppFeatureService,
              private router: Router) {
  }

  canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.isUserLoggedIn$(activatedRoute, state);
  }

  private isUserLoggedIn$(activatedRoute: ActivatedRouteSnapshot, routerState?: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const requestedUri = routerState.url;
    const queryParams = activatedRoute.queryParamMap;

    return this.userAuthService.restoreAuthIfRequired$(queryParams, Role.TRUSTEE).pipe(
      switchMap(a => this.checkAuthenticatedGrantedOrRedirect$(a, activatedRoute, requestedUri)),
      catchError(e => {
        console.warn(`Route activation denied:`);
        console.warn(e);
        const urlTree = this.redirectOnAccessDenied(null, requestedUri, activatedRoute, e);
        return of(urlTree);
      }),
    );
  }

  private checkAuthenticatedGrantedOrRedirect$(state: AuthState, activatedRoute: ActivatedRouteSnapshot, requestedUri: string): Observable<boolean | UrlTree> {
    if (state == null) {
      return of(this.redirectOnAccessDenied(null, requestedUri, activatedRoute, null));
    }
    return this.checkAccessGrantedOrThrow$(activatedRoute, state.user, state.roles, state.wsAuth).pipe(
      catchError(e => {
        const wsAuth = state == null ? null : state.wsAuth;
        return of(this.redirectOnAccessDenied(wsAuth, requestedUri, activatedRoute, e));
      }),
    );
  }


  private checkAccessGrantedOrThrow$(activatedRoute, user, roles: Role[], auth) {
    const data: ApplicationRouteData = activatedRoute.data || {} as ApplicationRouteData;
    const menuData: ApplicationMenuItemData = data.menuData || {} as ApplicationMenuItemData;
    const requiredRoles = menuData.userRoleConstraint || [];
    const requireAllFeatures = menuData.allAppFeatures || [];
    const requireAnyFeatures = menuData.anyAppFeature || [];

    if (auth == null) {
      return of(false);
    }
    const rolesGranted = requiredRoles.length === 0 || requiredRoles.find(requiredRole => roles.includes(requiredRole)) != null;
    if (!rolesGranted) {
      throwError(new Error(`User roles does not include any of ${requiredRoles}`));
    }

    return this.appFeatureService.getCurrentApplicationScopedFeatures$().pipe(
      map(appFeatures => this.checkAppFeaturesOrThrow(appFeatures, requireAllFeatures, requireAnyFeatures)),
    );
  }

  private redirectOnAccessDenied(auth: LifeisLifeWsAuth, requestedUri, activatedRoute: ActivatedRouteSnapshot, error: Error): UrlTree {
    const registerRouteRequested = activatedRoute.routeConfig.path === 'register';
    if (!registerRouteRequested && auth != null && auth.type === 'invite-token') {
      const urlTree: UrlTree = this.router.parseUrl(`/register?redirectUrl=${encodeURIComponent(requestedUri)}`);
      return urlTree;
    } else {
      const urlTree: UrlTree = this.router.parseUrl(`/login?redirectUrl=${encodeURIComponent(requestedUri)}`);
      return urlTree;
    }
  }

  private checkAppFeaturesOrThrow(grantedFeatures: AppFeature[], requireAllFeatures: AppFeature[], requireAnyFeatures: AppFeature[]) {
    if (requireAllFeatures.length === 0 && requireAnyFeatures.length === 0) {
      return true;
    }
    if (grantedFeatures.length === 0) {
      throw new Error(`Missing feature not found: one of ${requireAnyFeatures} and all of ${requireAllFeatures}`);
    }
    const foundAny = requireAnyFeatures.find(f => grantedFeatures.indexOf(f) >= 0) != null;
    if (requireAnyFeatures.length > 0 && foundAny == null) {
      throw new Error(`Missing feature not found: one of ${requireAnyFeatures}`);
    }

    const foundMissing = requireAllFeatures.find(f => grantedFeatures.indexOf(f) < 0) != null;
    if (requireAllFeatures.length > 0 && foundMissing) {
      throw new Error(`Missing feature ${foundMissing}. Require all of ${requireAllFeatures}`);
    }
    return true;
  }
}
