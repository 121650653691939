export enum FrontendAppConfigKey {
  app_name = 'app.name',
  app_configKey = 'app.configKey',
  lifeislife_ws_uri = 'lifeislife.ws.uri',
  recaptcha_publicKey = 'recaptcha.publicKey',
  google_api_publicKey = 'google.api.publicKey',
  webpush_vapid_publicKey = 'webpush.vapid.publicKey',
  mail_support_address = 'mail.support.address',
  appInstall_discardDuration_days = 'appInstall.discardDuration.days',
  webpush_discardDuration_days = 'webpush.discardDuration.days',
  company_name = 'company.name',
  company_vat = 'company.vatNumber',
  company_address = 'company.address',
  company_country = 'company.country',
  company_phone = 'company.phone',
  privacy_policy_uri = 'privacy.policy.uri',
}
