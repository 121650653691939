import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, forkJoin, Observable, of, timer} from 'rxjs';
import {
  Contact,
  ContentDisposition,
  CustomerService,
  FileSubresourcePath,
  Ref,
  SearchResult,
  SimplePagination,
  SimplePaginationUtils,
  SortOrder,
  StoredFile,
  StoredFileSearch,
  Ticket,
  TicketFile,
  TicketFileSearch,
  TicketFileService,
  TicketFileSortField,
  TicketSearch,
  TicketService,
  UserAuthService,
} from '@lifeislife/lifeislife-domain';
import {map, publishReplay, refCount, switchMap, take, tap} from 'rxjs/operators';
import {TicketFileWithFile} from './ticket-file-with-file';
import {RefWithFileRef} from './file-box/ref-with-file-ref';

@Injectable()
export class TicketFilesHelperService {

  private reloadSource$ = new BehaviorSubject<any>(null);
  private filter$ = new BehaviorSubject<TicketFileSearch>(null);

  pagination$ = new BehaviorSubject<SimplePagination>(SimplePaginationUtils.newPagination(10, {
    field: TicketFileSortField.ID,
    order: SortOrder.DESCENDING,
  }));
  loading$ = new BehaviorSubject<boolean>(false);
  newFileUploadPath$ = new BehaviorSubject<FileSubresourcePath<Ticket>>(null);

  results$: Observable<SearchResult<TicketFile>>;
  ticketFiles$: Observable<TicketFile[]>;
  ticketFilesWithFiles$: Observable<TicketFileWithFile[]>;
  fileRefs$: Observable<Ref<StoredFile>[]>;
  totalCount$: Observable<number>;

  constructor(
    private ticketFilesService: TicketFileService,
    private ticketService: TicketService,
    private customerService: CustomerService,
    private userAuthService: UserAuthService,
  ) {
    this.results$ = combineLatest([this.filter$, this.pagination$, this.reloadSource$]).pipe(
      switchMap(r => this.search$(r[0], r[1])),
      publishReplay(1), refCount(),
    );
    this.fileRefs$ = this.results$.pipe(
      map(r => r == null ? [] : r.list.map(s => s.storedFileRef)),
      publishReplay(1), refCount(),
    );
    this.totalCount$ = this.results$.pipe(
      map(r => r == null ? 0 : r.count),
      publishReplay(1), refCount(),
    );
    this.ticketFiles$ = this.results$.pipe(
      map(r => r == null ? [] : r.list),
      publishReplay(1), refCount(),
    );
    this.ticketFilesWithFiles$ = this.ticketFiles$.pipe(
      switchMap(r => this.fetchTicketFileFiles$(r)),
      publishReplay(1), refCount(),
    );
  }

  clear() {
    this.filter$.next(null);
  }

  initForTicketRef(ticketRef: Ref<Ticket>) {
    if (ticketRef == null) {
      this.newFileUploadPath$.next(null);
      return;
    }

    this.createFilter$(ticketRef)
      .subscribe(search => this.filter$.next(search));

    this.newFileUploadPath$.next(this.ticketService.getNewTicketFilePath(ticketRef));
  }

  updateWithFileNameQuery(query: string) {
    const curFilter = this.filter$.getValue();
    if (curFilter == null) {
      return;
    }
    const curFileFilter = curFilter.storedFileSearch || {} as StoredFileSearch;
    const nameContainsValue = (query == null || query.length === 0) ? null : query;
    const newFileSearch: StoredFileSearch = Object.assign({}, curFileFilter, <Partial<StoredFileSearch>>{
      nameContains: nameContainsValue,
    });
    const newFilter = Object.assign({}, curFilter, <Partial<TicketFileSearch>>{
      storedFileSearch: newFileSearch,
    });
    this.filter$.next(newFilter);
  }

  reload() {
    this.reloadSource$.next(true);
  }

  setPagination(pagination: SimplePagination) {
    this.pagination$.next(pagination);
  }

  getPagination$() {
    return this.pagination$;
  }

  getDownloadUrl$(withFileRef: RefWithFileRef, disposition: ContentDisposition = 'attachment') {
    return this.ticketFilesService.getFileDownloadUrl$({id: withFileRef.id}, disposition);
  }

  delete$(withFileRef: RefWithFileRef): Observable<any> {
    return this.ticketFilesService.deleteTicketFile$(withFileRef.id).pipe(
      tap(() => this.reload()),
    );
  }

  private search$(searcHFilter: TicketFileSearch, pagination: SimplePagination): Observable<SearchResult<TicketFile>> {
    if (searcHFilter == null || pagination == null) {
      return of(null);
    }
    return timer(100).pipe(
      tap(() => this.loading$.next(true)),
      switchMap(() => this.ticketFilesService.searchTicketFiles$(searcHFilter, pagination)),
      tap(() => this.loading$.next(false)),
    );
  }


  private createFilter$(ticketRef: Ref<Ticket>): Observable<TicketFileSearch> {
    const loggedContactRef$ = this.userAuthService.state$.pipe(
      map(s => s.contactRef),
      take(1),
    );
    return forkJoin([loggedContactRef$]).pipe(
      map(r => this.createFilter(r[0], ticketRef)),
    );
  }

  private createFilter(loggedContactRef: Ref<Contact>, ticketRef: Ref<Ticket>): TicketFileSearch {
    const searchFilter: TicketFileSearch = {
      ticketSearch: {
        exactTicketRef: ticketRef,
        ticketContactSearch: {
          contactSearch: {
            exactContactRef: loggedContactRef,
          },
        },
      } as TicketSearch,
      storedFileSearch: {
        deleted: false,
      },
    };
    return searchFilter;
  }

  private fetchTicketFileFiles$(files: TicketFile[]): Observable<TicketFileWithFile[]> {
    if (files == null || files.length === 0) {
      return of([]);
    }
    const storedFiles$List = files.map(f => this.ticketFilesService.getTicketFile$(f.storedFileRef).pipe(
      map(storefFile => Object.assign({}, f, {
        storedFile: storefFile,
      })),
    ));
    return storedFiles$List.length === 0 ? of([]) : forkJoin(storedFiles$List);
  }

}
