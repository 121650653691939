import {
  AppConfigService,
  AuthService,
  AuthState,
  CustomerContact,
  FrontendAppSwitch,
  Role,
  TrusteeContact,
  TrusteeContactService,
  TrusteeContactType,
} from '@lifeislife/lifeislife-domain';
import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, forkJoin, Observable, of} from 'rxjs';
import {filter, map, publishReplay, refCount, switchMap, take, tap} from 'rxjs/operators';
import {AppShellActiveContext} from './app-shell-active-context';
import {AppShellAvailableContexts} from './app-shell-available-contexts';
import {Router} from '@angular/router';

/**
 * Provide context for the application, as chosen by the logged user.
 * When an user has multiple 'roles' for various trustees/customers, she may
 * choose which hat to put on, and the shell content are restricted to account
 * for this.
 */
@Injectable()
export class AppShellContextProviderService {

  private availableContexts$: Observable<AppShellAvailableContexts>;
  private activeContext$ = new BehaviorSubject<AppShellActiveContext>(null);
  private activeContextSet$: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private appConfigservice: AppConfigService,
    private trusteeContactService: TrusteeContactService,
    private router: Router,
  ) {
    this.availableContexts$ = this.authService.getState$().pipe(
      filter(state => state.status !== 'authenticating' && state.status !== 'unset'),
      switchMap(state => this.createAvailableContext$(state)),
      tap(a => this.debugAvailableContexts(a)),
      tap(context => this.resetActiveContextIfRequired(context)),
      tap(context => this.autoSelectSingleContext(context)),
      publishReplay(1), refCount(),
    );
    this.activeContextSet$ = combineLatest([this.availableContexts$, this.activeContext$]).pipe(
      map(r => this.checkActiveContextSet(r[0], r[1])),
      publishReplay(1), refCount(),
    );

    // Ensure user using code for other applications are logged off
    this.availableContexts$.pipe(
      filter(c => c.loggedIn && !c.roles.includes(Role.TRUSTEE) && !c.roles.includes(Role.ADMIN)),
    ).subscribe(() => {
      this.authService.deauthtenticate(`Le code utilisé n'était pas valide pour cette application`);
      this.router.navigate(['/login']);
    });
    this.activeContext$.subscribe(c => {
      if (c) {
        this.appConfigservice.setContactTypeContext(c.activeRole, c.activeTrusteeContact, c.activeCustomerContact);
      }
    });
  }

  isActiveContextSet$() {
    return this.activeContextSet$;
  }

  getAvailableContext$() {
    return this.availableContexts$;
  }

  getNextAvailableContext$() {
    return this.availableContexts$.pipe(
      take(1),
      publishReplay(1), refCount(),
    );
  }

  getActiveContext$() {
    return this.activeContext$;
  }

  reemitActiveContext$() {
    const value = this.activeContext$.getValue();
    this.activeContext$.next(value);
  }

  activateAdminContext() {
    this.getNextAvailableContext$()
      .subscribe(c => this.activateContext(c, Role.ADMIN, null, null));
  }

  activateTrusteeContext(trusteeContact: TrusteeContact) {
    this.getNextAvailableContext$()
      .subscribe(c => this.activateContext(c, Role.TRUSTEE, null, trusteeContact));
  }

  activateCustomerContext(customerContact: CustomerContact) {
    this.getNextAvailableContext$()
      .subscribe(c => this.activateContext(c, Role.CUSTOMER, customerContact, null));
  }

  activateInviteeCustomerContext(customerContact: CustomerContact) {
    this.getNextAvailableContext$()
      .subscribe(c => this.activateContext(c, Role.INVITEE, customerContact, null));
  }

  activateInviteeTrusteeContext(trusteeContact: TrusteeContact) {
    this.getNextAvailableContext$()
      .subscribe(c => this.activateContext(c, Role.INVITEE, null, trusteeContact));
  }

  private createAvailableContext$(state: AuthState): Observable<AppShellAvailableContexts> {
    if (state.status === 'deauthenticated') {
      return of({
        loggedIn: false,
        customerContact: [],
        roles: [],
        trusteeContact: [],
      });
    } else {
      const trusteeContact$List = state.trusteeContactRefs == null ? [] : state.trusteeContactRefs
        .map(ref => this.trusteeContactService.getTrusteeContact$(ref));
      const trusteeContactList$ = trusteeContact$List.length === 0 ? of([]) : forkJoin(trusteeContact$List);

      return trusteeContactList$.pipe(
        map(trusteeContacts => <AppShellAvailableContexts>{
          trusteeContact: trusteeContacts.filter(tt => this.isAvailableTrusteeContact(tt)),
          customerContact: [],
          loggedIn: true,
          roles: state.roles,
        }),
      );
    }
  }

  private resetActiveContextIfRequired(context: AppShellAvailableContexts) {
    const curContext = this.activeContext$.getValue();
    if (curContext == null) {
      return;
    }
    if (!context.loggedIn) {
      this.activeContext$.next(null);
      return;
    }
    const activeRole = curContext.activeRole;
    const activeRoleAvailable = activeRole == null || context.roles
      .find(r => r === activeRole) != null;
    if (!activeRoleAvailable) {
      this.activeContext$.next(null);
      return;
    }
    const activeCustomerContact = curContext.activeCustomerContact;
    const activeCustomerContactAvailable = activeCustomerContact == null || context.customerContact
      .find(c => c.id === activeCustomerContact.id) != null;
    if (!activeCustomerContactAvailable) {
      this.activeContext$.next(null);
      return;
    }

    const activeTrusteeContact = curContext.activeTrusteeContact;
    const activeTrusteeContactAvailable = activeTrusteeContact == null || context.trusteeContact
      .find(c => c.id === activeTrusteeContact.id) != null;
    if (!activeTrusteeContactAvailable) {
      this.activeContext$.next(null);
      return;
    }
  }

  private autoSelectSingleContext(context: AppShellAvailableContexts) {
    if (!context.loggedIn) {
      return;
    }
    const hasAdminRole = context.roles.find(r => r === Role.ADMIN) != null;
    const hasTrusteeRole = context.roles.find(r => r === Role.TRUSTEE) != null;
    const hasInviteRole = context.roles.find(r => r === Role.INVITEE) != null;

    //
    if (hasAdminRole) {
      this.activeContext$.next({
        activeCustomerContact: null,
        activeTrusteeContact: null,
        activeRole: Role.ADMIN,
      });
      return;
    }

    if (context.trusteeContact.length > 1) {
      return;
    }

    if (context.trusteeContact.length === 1 && hasTrusteeRole) {
      this.activeContext$.next({
        activeRole: Role.TRUSTEE,
        activeTrusteeContact: context.trusteeContact[0],
        activeCustomerContact: null,
      });
    } else if (context.trusteeContact.length === 1 && hasInviteRole) {
      this.activeContext$.next({
        activeRole: Role.INVITEE,
        activeTrusteeContact: context.trusteeContact[0],
        activeCustomerContact: null,
      });
    }
  }

  private checkActiveContextSet(availableContext: AppShellAvailableContexts, activeContext: AppShellActiveContext) {
    if (!availableContext.loggedIn) {
      return true;
    }
    if (activeContext != null && activeContext.activeRole != null) {
      return true;
    }
    return false;
  }

  private activateContext(availableContexts: AppShellAvailableContexts, role: Role,
                          customerContact?: CustomerContact, trusteeContact?: TrusteeContact) {
    if (!availableContexts.loggedIn) {
      console.warn(`Cannot activate context: not logged in`);
      return;
    }
    const hasRole = availableContexts.roles.find(r => r === role) != null;
    if (!hasRole) {
      console.warn(`Cannot activate context: ${role} role not granted`);
      return;
    }
    switch (role) {
      case Role.ADMIN: {
        this.activeContext$.next({
          activeRole: Role.ADMIN,
          activeCustomerContact: null,
          activeTrusteeContact: null,
        });
        break;
      }
      case Role.TRUSTEE: {
        if (trusteeContact == null) {
          throw new Error(`No trustee contact for trustee context`);
        }
        this.activeContext$.next({
          activeRole: Role.TRUSTEE,
          activeTrusteeContact: trusteeContact,
          activeCustomerContact: null,
        });
        break;
      }
      case Role.CUSTOMER: {
        if (customerContact == null) {
          throw new Error(`No customer contact for customer context`);
        }
        this.activeContext$.next({
          activeRole: Role.CUSTOMER,
          activeCustomerContact: customerContact,
          activeTrusteeContact: null,
        });
        break;
      }
      case Role.INVITEE: {
        if (customerContact == null && trusteeContact == null) {
          throw new Error(`No customer or trustee contact for invitee context`);
        }
        this.activeContext$.next({
          activeRole: Role.INVITEE,
          activeTrusteeContact: trusteeContact,
          activeCustomerContact: customerContact,
        });
        break;
      }
      default: {
        console.warn(`Cannot activate context: unhandled role`);
      }
    }
  }

  private debugAvailableContexts(a: AppShellAvailableContexts) {
    const debug = this.appConfigservice.isSwitchCurrentlyEnabled(FrontendAppSwitch.front_dev_debug);
    if (debug) {
      console.log(`Available contexts:`);
      console.log(a);
    }
  }

  private isAvailableTrusteeContact(trusteeContact: TrusteeContact) {
    return trusteeContact.trusteeContactType === TrusteeContactType.LEGAL_REPRESENTATIVE
      || trusteeContact.trusteeContactType === TrusteeContactType.ADMINISTRATIVE
      || trusteeContact.trusteeContactType === TrusteeContactType.ACCOUNTANT
      || trusteeContact.trusteeContactType === TrusteeContactType.OTHER;
  }
}
