<div class="file-drop-area"
     (dragenter)="onDragEnter($event)"
     (dragover)="onDragOver($event)"
     [class.dragging]="draggingOver"
     [class.active]="active"
>
  <div class="drag-overlay lifeislife-overlay-panel"
       [hidden]="!draggingOver"
       (dragleave)="onDragLeave($event)"
       (drop)="onDrop($event)"
  >
    <div class="content">
      <div class="icon">
        <i class="fa fa-upload"></i>
      </div>
      <span i18n>Déposez vos fichiers</span>
    </div>
  </div>
  <ng-content></ng-content>
</div>
