import {Injectable} from '@angular/core';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {WsConstraintViolation, WsRef, WsTicketSigningPackage} from '@lifeislife/lifeislife-ws-api';
import {ObjectConverterUtil} from '../object-converter-util';
import {TicketSigningPackageWsClient} from '../../client/resources/front/ticketing/ticket-signing-package-ws-client';
import {TicketSigningPackage} from '../../domain/ticket/ticket-signing-package';
import {TicketSigningPackageConverter} from './ticket-signing-package.converter';
import {TicketSigningPackageSearch} from '../../domain/ticket/ticket-signing-package-search';

@Injectable({
  providedIn: 'root',
})
export class TicketSigningPackageService {

  constructor(private client: TicketSigningPackageWsClient,
  ) {
  }

  getTicketSigningPackage$(ref?: Ref<TicketSigningPackage>): Observable<TicketSigningPackage> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doGet(ref.id).pipe(
      map(wsTicketSigningPackage => TicketSigningPackageConverter.convertIn(wsTicketSigningPackage)),
    );
  }

  searchTicketSigningPackages$(ticketSigningPackageFilter: TicketSigningPackageSearch, pagination: SimplePagination)
    : Observable<SearchResult<TicketSigningPackage>> {
    const wsSearch = TicketSigningPackageConverter.convertFilterOut(ticketSigningPackageFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateTicketSigningPackage$(ticketSigningPackage: TicketSigningPackage): Observable<ValidationResult<TicketSigningPackage>> {
    const wsTicketSigningPackage = TicketSigningPackageConverter.convertOut(ticketSigningPackage);
    return this.client.doValidate(wsTicketSigningPackage).pipe(
      map((results: WsTicketSigningPackage | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveTicketSigningPackage$(ticketSigningPackage: TicketSigningPackage): Observable<Ref<TicketSigningPackage>> {
    const wsTicketSigningPackage = TicketSigningPackageConverter.convertOut(ticketSigningPackage);
    return this.client.doSave(wsTicketSigningPackage);
  }

  deleteTicketSigningPackage$(id: number): Observable<any> {
    return this.client.doRemove(id);
  }


  private createValidationResult(results: WsTicketSigningPackage | WsConstraintViolation[]): ValidationResult<TicketSigningPackage> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsTicketSigningPackage => TicketSigningPackageConverter.convertIn(wsTicketSigningPackage),
      propertyNameMappings: TicketSigningPackageConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsTicketSigningPackage>>): Observable<SearchResult<TicketSigningPackage>> {
    const rowTasks = results.list.map(ref => this.getTicketSigningPackage$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<TicketSigningPackage>()) : forkJoin(rowTasks).pipe(
      map((rows: TicketSigningPackage[]) => Object.assign({}, results, {
          list: rows,
        }),
      ));
  }

}
