import {WsMaritalStatus} from '@lifeislife/lifeislife-ws-api';
import {MaritalStatus} from '../../domain/contact/marital-status';

export class MaritalStatusConverter {

  static toMaritalStatus(value: WsMaritalStatus): MaritalStatus {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      code: value.code,
      label: value.label,
    };
  }


}
