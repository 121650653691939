import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaritalStatusSelectComponent } from './marital-status-select.component';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [MaritalStatusSelectComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
  ],
  exports: [MaritalStatusSelectComponent]
})
export class MaritalStatusSelectModule { }
