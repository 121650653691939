import {Injectable} from '@angular/core';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {RequestService} from '../../../service/request.service';
import {WsDaoEventEntityType, WsRef, WsTicketFile} from '@lifeislife/lifeislife-ws-api';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {Observable} from 'rxjs';
import {HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TicketFileWsClient extends CachedWsResourceClient<WsTicketFile, WsRef<WsTicketFile>> {

  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private injectedAppConfigService: AppConfigService) {
    super('/front/ticketFile', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.TICKET_FILE, injectectedCacheService);
  }

  getFileDownloadUrl$(ticketFileId: number, contentDisposition?: 'inline' | 'attachment'): Observable<string> {
    const url = `${this.getFileResourceUrl(ticketFileId)}/contentUrl`;
    const headers = new HttpHeaders()
      .append('Accept', 'text/plain');
    let queryParams = new HttpParams();
    if (contentDisposition != null) {
      queryParams = queryParams.append('content-disposition', contentDisposition);
    }

    return this.requestService.sendRequest<string>({
      method: 'GET', url: url, headers: headers, responseType: 'text',
      params: queryParams,
    }, this.authService.getAuth());
  }

  private getFileResourceUrl(subscriptionFileId: number) {
    return `${this.getResourceUrl()}/${subscriptionFileId}/file`;
  }

}
