import {WsCareerDetailsType} from '@lifeislife/lifeislife-ws-api';
import {CareerDetailsType} from '../../domain/retire/career-details-type';

export class CareerDetailsTypeConverter {

  static convertIn(wsStatus: WsCareerDetailsType): CareerDetailsType {
    if (wsStatus == null) {
      return null;
    }
    return wsStatus as string as CareerDetailsType;
  }


  static convertOut(careerStatus: CareerDetailsType): WsCareerDetailsType {
    if (careerStatus == null) {
      return null;
    }
    return careerStatus as string as WsCareerDetailsType;
  }
}
