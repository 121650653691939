import {MessageFlavor} from '../../domain/mail/message-flavor';
import {WsFlavoredMessage, WsMessageFlavor} from '@lifeislife/lifeislife-ws-api';
import {FlavouredMessage} from '../../domain/mail/flavoured-message';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';

export class FlavouredMessageConverter {

  static convertMessagesIn(wsFlavouredMessages: WsFlavoredMessage[]): FlavouredMessage[] {
    return wsFlavouredMessages.map(
      wsMsg => this.convertIn(wsMsg),
    );
  }

  static convertIn(wsFlavouredMessage: WsFlavoredMessage): FlavouredMessage {
    return {
      flavor: EnumConverterUtils.convertValue(wsFlavouredMessage.flavor, MessageFlavor),
      message: wsFlavouredMessage.text,
    };
  }

  static convertOut(flavouredMessage: FlavouredMessage): WsFlavoredMessage {
    return {
      flavor: EnumConverterUtils.convertValue(flavouredMessage.flavor, WsMessageFlavor),
      text: flavouredMessage.message,
    };
  }
}
