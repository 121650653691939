import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {
  ApiEntityType,
  Contact,
  CreditProviderService,
  CreditService,
  CreditSubscriptionService,
  Customer,
  EventRecord,
  InsuranceProviderService,
  InsuranceService,
  InsuranceSubscriptionService,
  InvestmentProviderService,
  InvestmentService,
  InvestmentSubscriptionService,
  Product,
  ProductProvider,
  ProductProviderService,
  ProductService,
  ProductType,
  Ref, SigningPackage,
  Subscription,
  SubscriptionService,
  Trustee,
} from '@lifeislife/lifeislife-domain';
import {concat, Observable, of} from 'rxjs';
import {filter, first, map, switchMap} from 'rxjs/operators';
import {Location} from '@angular/common';
import {ApplicationMenuItemData} from './main/routing/application-menu-item-data';
import {ApplicationRouteData} from './main/routing/application-route-data';
import {InsuranceSubscriptionMenuData} from './insurance-subscription/insurance-subscription-menu-data';

@Injectable()
export class ApplicationNavigationService {

  constructor(
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private creditService: CreditService,
    private insuranceService: InsuranceService,
    private investmentService: InvestmentService,
    private productProviderService: ProductProviderService,
    private creditProviderService: CreditProviderService,
    private insuranceProviderService: InsuranceProviderService,
    private investmentProviderService: InvestmentProviderService,
    private subscriptionService: SubscriptionService,
    private creditSubscriptionService: CreditSubscriptionService,
    private insuranceSubscriptionService: InsuranceSubscriptionService,
    private investmentSubscriptionService: InvestmentSubscriptionService,
  ) {
  }

  navigateToModuleDetailsRoute(id: number | 'new') {
    this.router.navigate([id], {
      relativeTo: this.activatedRoute,
    });
  }

  navigateToBatchJobExecution(id: number) {
    this.router.navigate(['/batch', id]);
  }

  navigateToProduct(id: number) {
    this.productService.getProduct$({id: id})
      .subscribe(p => this.navigateToTypedProduct(p));
  }

  navigateToCredit(id: number | 'new') {
    this.router.navigate(this.createCreditROuterLink(id));

  }

  private createCreditROuterLink(id: number | 'new') {
    return ['/credit', id];
  }

  navigateToInsurance(id: number | 'new') {
    this.router.navigate(this.createInsuranceRouterLink(id));

  }

  private createInsuranceRouterLink(id: number | 'new') {
    return ['/insurance', id];
  }

  navigateToInvestment(id: number | 'new') {
    this.router.navigate(this.createInvestmentRouterLInk(id));

  }

  private createInvestmentRouterLInk(id: number | 'new') {
    return ['/investment', id];
  }

  navigateToProductProvider(id: number) {
    this.productProviderService.getProductProvider$({id: id})
      .subscribe(p => this.navigateToTypedProductProvider(p));
  }

  navigateToCreditProvider(id: number | 'new') {
    this.router.navigate(this.createCreditProviderRouterLink(id));

  }

  private createCreditProviderRouterLink(id: number | 'new') {
    return ['/creditProvider', id];
  }

  navigateToInsuranceProvider(id: number | 'new') {
    this.router.navigate(this.createInsuranceProviderRouterLInk(id));

  }

  private createInsuranceProviderRouterLInk(id: number | 'new') {
    return ['/insuranceProvider', id];
  }

  navigateToInsuranceCategory(id: number | 'new') {
    this.router.navigate(this.createInsuranceCategoryRouterLink(id));
  }

  private createInsuranceCategoryRouterLink(id: number | 'new') {
    return ['/insuranceCategory', id];
  }

  navigateToInvestmentProvider(id: number | 'new') {
    this.router.navigate(this.createInvestmentProviderRouterLink(id));
  }


  private createInvestmentProviderRouterLink(id: number | 'new') {
    return ['/investmentProvider', id];
  }

  navigateToProductSubscription(id: number) {
    this.subscriptionService.getSubscription$({id: id})
      .subscribe(s => this.navigateToTypedProductSubscription(s));
  }


  navigateToCreditSubscription(id: number | 'new' | 'subscription', subscriptionId?: number) {
    this.router.navigate(this.createCreditSubscriptionRouterLink(id, subscriptionId));

  }

  private createCreditSubscriptionRouterLink(id: number | 'new' | 'subscription', subscriptionId: number) {
    const path: any[] = ['/creditSubscription', id];
    if (subscriptionId) {
      path.push({subscriptionId: subscriptionId});
    }
    return path;
  }

  navigateToInsuranceSubscription(id: number | 'new' | 'subscription', subscriptionId?: number) {
    this.router.navigate(this.createInsuranceSubscriptionRouterLInk(id, subscriptionId));

  }

  private createInsuranceSubscriptionRouterLInk(id: number | 'new' | 'subscription', subscriptionId: number) {
    const path: any[] = ['/insuranceSubscription', id];
    if (subscriptionId) {
      path.push({subscriptionId: subscriptionId});
    }
    return path;
  }

  navigateToInvestmentSubscription(id: number | 'new' | 'subscription', subscriptionId?: number) {
    this.router.navigate(this.createInvestmentSubscriptionRouterLink(id, subscriptionId));
  }

  private createInvestmentSubscriptionRouterLink(id: number | 'new' | 'subscription', subscriptionId: number) {
    const path: any[] = ['/investmentSubscription', id];
    if (subscriptionId) {
      path.push({subscriptionId: subscriptionId});
    }
    return path;
  }

  navigateToCustomer(id: number | 'new') {
    this.router.navigate(this.createCustomerRouterLink(id));
  }

  private createCustomerRouterLink(id: number | 'new') {
    return ['/customer', id];
  }

  private createCustomerBankAccountRouterLink(id: number | 'new') {
    return ['/customerBankAccount', id];
  }

  navigateToTrustee(id: number | 'new') {
    this.router.navigate(['/trustee', id]);
  }

  navigateToContact(id: number | 'new') {
    this.router.navigate(this.createContactRouterLink(id));
  }

  navigateToSigningPackage(ref: Ref<SigningPackage>, customerRef?: Ref<Customer>, trusteeRef?: Ref<Trustee>,
                           subscriptionRef?: Ref<Subscription>, typedSubscriptioNRef?: Ref<any>) {
    this.createSigningPackageRouterLink$(ref, trusteeRef, customerRef, subscriptionRef, typedSubscriptioNRef)
      .subscribe(link => this.router.navigate(link));
  }

  private createContactRouterLink(id: number | 'new') {
    return ['/contact', id];
  }


  navigateToMessageTemplate(typeName: string) {
    this.router.navigate(this.createMessageTemplateRouterLink(typeName));
  }

  private createMessageTemplateRouterLink(typeName: string) {
    return ['/messageTemplate', typeName];
  }

  navigateToPersonTitle(id: number | 'new') {
    this.router.navigate(this.createPersonTitleLink(id));
  }

  private createPersonTitleLink(id: number | 'new') {
    return ['/personTitle', id];
  }

  createEventEntityRouterLInk(event: EventRecord): any[] | null {
    const entityType = event.recordEntityType;
    switch (entityType) {
      case ApiEntityType.CREDIT:
        return this.createCreditROuterLink(event.recordEntityId);
      case ApiEntityType.CREDIT_PROVIDER:
        return this.createCreditProviderRouterLink(event.recordEntityId);
      case ApiEntityType.CREDIT_SUBSCRIPTION:
        return this.createCreditSubscriptionRouterLink(event.recordEntityId, undefined);
      case ApiEntityType.CONTACT:
        return this.createContactRouterLink(event.recordEntityId);
      case ApiEntityType.CUSTOMER:
        return this.createContactRouterLink(event.recordEntityId);
      case ApiEntityType.CUSTOMER_BANK_ACCOUNT:
        return this.createCustomerBankAccountRouterLink(event.recordEntityId);
      case ApiEntityType.CUSTOMER_CONTACT:
        break;
      case ApiEntityType.CUSTOMER_EVENT_SUBSCRIPTION:
        break;
      case ApiEntityType.CUSTOMER_NOTE:
        break;
      case ApiEntityType.CUSTOMER_STATUS:
        break;
      case ApiEntityType.CUSTOMER_TYPE:
        break;
      case ApiEntityType.INSURANCE:
        return this.createInsuranceRouterLink(event.recordEntityId);
      case ApiEntityType.INSURANCE_PROVIDER:
        return this.createInsuranceProviderRouterLInk(event.recordEntityId);
      case ApiEntityType.INSURANCE_SUBSCRIPTION:
        return this.createInsuranceSubscriptionRouterLInk(event.recordEntityId, undefined);
      case ApiEntityType.INVESTMENT:
        return this.createInvestmentRouterLInk(event.recordEntityId);
      case ApiEntityType.INVESTMENT_PROVIDER:
        return this.createInvestmentProviderRouterLink(event.recordEntityId);
      case ApiEntityType.INVESTMENT_SUBSCRIPTION:
        return this.createInvestmentSubscriptionRouterLink(event.recordEntityId, undefined);
      case ApiEntityType.LIFEISLIFE_ADMIN:
        break;
      case ApiEntityType.LIFEISLIFE_USER:
        break;
      case ApiEntityType.LIFEISLIFE_LOG:
        break;
      case ApiEntityType.LIFEISLIFE_LOG_ENTRY:
        break;
      case ApiEntityType.LIFEISLIFE_EVENT_RECORD:
        break;
      case ApiEntityType.MAIL:
        break;
      case ApiEntityType.MESSAGE_TEMPLATE:
        break;
      case ApiEntityType.NOTIFICATION:
        break;
      case ApiEntityType.PRICING:
        break;
      case ApiEntityType.PERSON_TITLE:
        break;
      case ApiEntityType.PRODUCT:
        break;
      case ApiEntityType.PRODUCT_PROVIDER:
        break;
      case ApiEntityType.SMS:
        break;
      case ApiEntityType.SIGNING_PACKAGE:
        break;
      case ApiEntityType.SIGNING_PACKAGE_FILE:
        break;
      case ApiEntityType.STORED_FILE:
        break;
      case ApiEntityType.SUBSCRIPTION:
        break;
      case ApiEntityType.SUBSCRIPTION_FILE:
        break;
      case ApiEntityType.SUBSCRIPTION_SIGNING_PACKAGE:
        break;
      case ApiEntityType.THIRDPARTY:
        break;
      case ApiEntityType.TICKET:
        break;
      case ApiEntityType.TICKET_COMMENT:
        break;
      case ApiEntityType.TICKET_FILE:
        break;
      case ApiEntityType.TICKET_SIGNING_PACKAGE:
        break;
      case ApiEntityType.TICKET_INSURANCE_MANDATE_REQUEST:
        break;
      case ApiEntityType.TICKET_CUSTOMER:
        break;
      case ApiEntityType.TICKET_TURSTEE:
        break;
      case ApiEntityType.TICKET_CONTACT:
        break;
      case ApiEntityType.TRUSTEE:
        break;
      case ApiEntityType.TRUSTEE_CONTACT:
        break;
    }
    return null;
  }

  /**
   * Create a router link to customer/id.
   * If the context trustee ref is present, the link will point to /trustee/{trusteId}/customer/{customerId}
   * @param customerRef
   * @param contextTrusteeRef
   */
  createTrusteeDetailsRouterLink(trusteeRef: Ref<Trustee>): any[] {
    return ['/trustee', trusteeRef.id];
  }


  /**
   * Create a router link to customer/id.
   * If the context trustee ref is present, the link will point to /trustee/{trusteId}/customer/{customerId}
   * @param customerRef
   * @param contextTrusteeRef
   */
  createCustomerDetailsRouterLink(customerRef: Ref<Customer>, contextTrusteeRef?: Ref<Trustee>): any[] {
    const routeLink: any[] = ['/'];
    if (contextTrusteeRef && contextTrusteeRef.id) {
      routeLink.push('trustee', contextTrusteeRef.id);
    }
    if (customerRef && customerRef.id) {
      routeLink.push('customer', customerRef.id);
    }
    return routeLink;
  }


  /**
   * Create a router link to contact/{id}/details.
   * If the context trustee ref is present, the link will point to /trustee/{trusteId}/contact/{contactId}/details
   * If the context customer ref is present is addition to the trustee ref, the link will point to /trustee/{trusteId}/customer/{customerId}/contact/{contactId}/details
   * @param contactRef
   * @param trusteeRef
   * @param customerRef
   */
  createContactDetailsRouterLink(contactRef: Ref<Contact>, trusteeRef?: Ref<Trustee>, customerRef?: Ref<Customer>): any[] {
    if (contactRef == null) {
      return null;
    }
    if (trusteeRef != null) {
      if (customerRef != null) {
        return [`/trustee`, trusteeRef.id, 'customer', customerRef.id, 'contact', contactRef.id, 'details'];
      } else {
        return [`/trustee`, trusteeRef.id, 'contact', contactRef.id, 'details'];
      }
    } else {
      return ['/contact', contactRef.id, 'details'];
    }
  }

  createSigningPackageRouterLink$(signingPackageRef: Ref<SigningPackage>,
                                  trusteeRef?: Ref<Trustee>,
                                  customerRef?: Ref<Customer>,
                                  subscriptionRef?: Ref<Subscription>,
                                  typedSusbcriptoinRef?: Ref<any>,
  ): Observable<any[]> {

    if (signingPackageRef == null) {
      return null;
    }

    if (subscriptionRef != null) {
      return this.createSubscriptionDetailsRouterLink$(
        subscriptionRef, typedSusbcriptoinRef,
        trusteeRef, customerRef).pipe(
        map(path => [...path, 'signingPackage', signingPackageRef.id]),
      );
    }

    const pathNames = [];
    if (trusteeRef != null) {
      pathNames.push('/trustee', trusteeRef.id);
    }
    if (customerRef != null) {
      if (pathNames.length > 0) {
        pathNames.push('customer', customerRef.id);
      } else {
        pathNames.push('/customer', customerRef.id);
      }
    }

    if (pathNames.length > 0) {
      pathNames.push('signingPackage', signingPackageRef.id);
    } else {
      pathNames.push('/signingPackage', signingPackageRef.id);
    }
    return of(pathNames);
  }

  /**
   * Create a router link to the subscription.
   * If the context trustee ref is present, the link will be a child of /trustee/{trusteId}.
   * If the context customer ref is present, it will be a child of /customer/{customerId}.
   *
   * Subscription will be fetched to point to /insuranceSubscription, /creditSUbscription etc.. routes.
   * @param customerRef
   * @param contextTrusteeRef
   */
  createSubscriptionDetailsRouterLink$(subscriptionRef: Ref<Subscription>,
                                       typedSubscriptionRef?: Ref<any>,
                                       contextTrusteeRef?: Ref<Trustee>,
                                       contextCustomerRef?: Ref<Customer>): Observable<any[]> {
    return of(null).pipe(
      switchMap(() => this.subscriptionService.getSubscription$(subscriptionRef)),
      map(subscription => this.createSubscriptionDetailsRouterLinkWithSubscription(subscription, typedSubscriptionRef, contextTrusteeRef, contextCustomerRef)),
    );
  }

  private navigateToTypedProduct(product: Product) {
    switch (product.productType) {
      case ProductType.CREDIT:
        this.creditService.getCreditForProduct$({id: product.id})
          .subscribe(credit => this.navigateToCredit(credit.id));
        break;
      case ProductType.INSURANCE:
        this.insuranceService.getInsuranceForProduct$({id: product.id})
          .subscribe(insurance => this.navigateToInsurance(insurance.id));
        break;
      case ProductType.INVESTMENT:
        this.investmentService.getInvestmentForProduct$({id: product.id})
          .subscribe(investment => this.navigateToInvestment(investment.id));
        break;
    }
  }

  private navigateToTypedProductProvider(productProvider: ProductProvider) {
    interface ProviderWithType {
      providerId: number;
      type: ProductType;
    }

    const creditProvider$ = this.creditProviderService.getCreditForProductProvider$({id: productProvider.id})
      .pipe(
        filter(p => p != null),
        map(p => <ProviderWithType>{
          type: ProductType.CREDIT,
          providerId: p.id,
        }));
    const insuranceProvider$ = this.insuranceProviderService.getInsuranceForProductProvider$({id: productProvider.id})
      .pipe(
        filter(p => p != null),
        map(p => <ProviderWithType>{
          type: ProductType.INSURANCE,
          providerId: p.id,
        }));
    const investmentProvider$ = this.investmentProviderService.getInvestmentForProductProvider$({id: productProvider.id})
      .pipe(
        filter(p => p != null),
        map(p => <ProviderWithType>{
          type: ProductType.INVESTMENT,
          providerId: p.id,
        }));
    return concat(
      creditProvider$,
      insuranceProvider$,
      investmentProvider$,
    ).pipe(
      first(),
    ).subscribe(provider => {
      switch (provider.type) {
        case ProductType.CREDIT:
          this.navigateToCreditProvider(provider.providerId);
          break;
        case ProductType.INSURANCE:
          this.navigateToInsuranceProvider(provider.providerId);
          break;
        case ProductType.INVESTMENT:
          this.navigateToInvestmentProvider(provider.providerId);
          break;
      }
    });
  }


  private navigateToTypedProductSubscription(productSubscription: Subscription) {
    switch (productSubscription.productType) {
      case ProductType.CREDIT:
        return this.navigateToCreditSubscription('subscription', productSubscription.id);
      case ProductType.INSURANCE:
        return this.navigateToInsuranceSubscription('subscription', productSubscription.id);
      case ProductType.INVESTMENT:
        return this.navigateToInvestmentSubscription('subscription', productSubscription.id);
    }
  }

  private createSubscriptionDetailsRouterLinkWithSubscription(subscription: Subscription,
                                                              typedSubscriptionRef?: Ref<any>,
                                                              contextTrusteeRef?: Ref<Trustee>,
                                                              contextCustomerRef?: Ref<Customer>)
    : any[] {

    switch (subscription.productType) {
      case ProductType.CREDIT:
        return this.createTypedSubscriptionDetailsRouterLink(`creditSubscription`, subscription, typedSubscriptionRef, contextTrusteeRef, contextCustomerRef);
      case ProductType.INSURANCE:
        return this.createTypedSubscriptionDetailsRouterLink(`insuranceSubscription`, subscription, typedSubscriptionRef, contextTrusteeRef, contextCustomerRef);
      case ProductType.INVESTMENT:
        return this.createTypedSubscriptionDetailsRouterLink(`investmentSubscription`, subscription, typedSubscriptionRef, contextTrusteeRef, contextCustomerRef);
      default:
        throw new Error(`Unhandled product type: ${subscription.productType}`);
    }
  }

  private createTypedSubscriptionDetailsRouterLink(typedSubscriptionPath: string,
                                                   subscriptionRef: Ref<any>,
                                                   typedSubscriptionRef?: Ref<any>,
                                                   contextTrusteeRef?: Ref<Trustee>,
                                                   contextCustomerRef?: Ref<Customer>): any[] {
    const routePathList: any[] = ['/'];
    if (contextTrusteeRef) {
      routePathList.push('trustee', contextTrusteeRef.id);
    }
    if (contextCustomerRef) {
      routePathList.push('customer', contextCustomerRef.id);
    }
    routePathList.push(typedSubscriptionPath);

    if (typedSubscriptionRef) {
      routePathList.push(typedSubscriptionRef.id);
    } else {
      routePathList.push('subscription', {'subscriptionId': subscriptionRef.id});
    }
    return routePathList;
  }


  navigateToMail(id: number) {
    this.router.navigate(['/mail', id]);
  }

  back() {
    this.location.back();
  }

  /**
   * If child route is not the details route provided, navigate to ./<details route>, otherwise navigate to
   * ../list
   */
  createParentDetailsOrListRelativeLink(childRoutes: ActivatedRouteSnapshot[], detailsMenuData: ApplicationMenuItemData): any[] {
    const isDetailsRoute = childRoutes.find(c => (c.data as ApplicationRouteData).menuData.id === detailsMenuData.id) != null;
    if (isDetailsRoute) {
      return ['../', 'list'];
    } else {
      return ['./', ...detailsMenuData.relativeMenuLink];
    }
  }

  private reduceChildLength(cur: number, next: ActivatedRouteSnapshot): number {
    const nextCount = cur + 1;
    if (next.children && next.children.length > 0) {
      return next.children.reduce((c, n) => this.reduceChildLength(c, n), nextCount);
    } else {
      return nextCount;
    }
  }
}
