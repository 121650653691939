import {ApplicationRef, ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Observable, of, Subscription, timer} from 'rxjs';
import {Message, MessageService} from 'primeng/api';
import {AuthService, CacheService, RequestService, UserAuthService} from '@lifeislife/lifeislife-domain';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {debounceTime, filter, map, mergeMap, publishReplay, refCount} from 'rxjs/operators';
import {LifeislifeFrontErrorHandler} from './commons/services/error.handler';
import {MessageSeverity} from './commons/domain/message-severity';
import {PrimengTranslationService} from '@lifeislife/lifeislife-components';
import {ApplicationRouteData} from './main/routing/application-route-data';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [PrimengTranslationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private ROUTE_TITLE_SEPARATOR = ' ‧ ';

  private subscription: Subscription;

  authenticating$: Observable<boolean>;
  private ROUTE_NAVIGATION_ERROR_RELOAD_ATTEMPTED_QUERY_PARAM = 'errRedirect';

  constructor(private messageService: MessageService,
              private userAuthService: UserAuthService,
              private authService: AuthService,
              private cacheService: CacheService,
              private appRef: ApplicationRef,
              private router: Router,
              private route: ActivatedRoute,
              private titleService: Title,
              private errorHandler: LifeislifeFrontErrorHandler,
              private requestService: RequestService,
              private primngService: PrimengTranslationService,
  ) {
  }

  ngOnInit() {
    this.primngService.setFrenchTranslation();

    this.cacheService.getUpdateAvailableObservable()
      .subscribe(() => this.onUpdateAvailable());
    this.cacheService.startPollingUpdates();

    this.router.errorHandler = (err) => {
      this.handleRouteError(err);
    };
    // Use this to check if app get stable ever
    // this.appRef.isStable.subscribe(s => console.log(s));

    const pageTitleSubscription = this.router.events.pipe(
      mergeMap(event => this.getRouteTitleForEvent$(event)),
      filter(title => title !== null),
    ).subscribe(title => this.titleService.setTitle(title));


    const requestsSubscription = this.requestService.isOnlineObservable().pipe(
      debounceTime(2000),
      filter(reachable => reachable === false),
    ).subscribe(() => this.onServerUnreachable());
    //
    // const showTabsSubscription = RouteUtils.getRouteParam('showMenu', this.route)
    //   .pipe(map(value => value !== 'false'))
    //   .subscribe(show => this.showMenu = show);
    //

    this.subscription = new Subscription();
    this.subscription.add(pageTitleSubscription);
    this.subscription.add(requestsSubscription);
    // this.subscription.add(showTabsSubscription);

    this.authenticating$ = this.authService.getState$().pipe(
      map(s => s.status === 'authenticating'),
      publishReplay(1), refCount(),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private onUpdateAvailable() {
    // TODO: ask value
    this.messageService.add({
      severity: MessageSeverity.INFO,
      summary: 'Une nouvelle version est disponible.',
      detail: 'La page va se recharger dans quelques secondes',
      sticky: true,
    });

    const fiveSecondsTimer$ = timer(5000);
    const updateActivated$ = this.cacheService.activateUpdate();
    forkJoin(fiveSecondsTimer$, updateActivated$)
      .subscribe(() => {
        document.location.reload();
      }, e => {
        this.messageService.add({
          severity: MessageSeverity.ERROR,
          summary: 'La mise à jour a échoué',
          detail: 'Veuillez recharger la page pour réessayer',
          sticky: true,
        });
        this.errorHandler.handleError(e);
      });
  }

  private onServerUnreachable() {
    const message: Message = {
      severity: MessageSeverity.WARNING,
      summary: 'Vous apparaissez hors ligne',
      detail: 'Vérifiez votre connexion à internet',
    };
    this.messageService.add(message);
  }

  private getRouteTitleForEvent$(event: any): Observable<string | null> {
    if (event instanceof NavigationEnd) {
      let child = this.route.firstChild;
      let title = '';
      while (child) {
        const titleDataNullable = this.getRouteTitleDataNullable(child);
        if (titleDataNullable != null && titleDataNullable.length > 0) {
          const separator = title.length === 0 ? '' : this.ROUTE_TITLE_SEPARATOR;
          title += `${separator}${titleDataNullable}`;
        }

        if (child.firstChild) {
          child = child.firstChild;
          continue;
        }
        break;
      }

      if (title.length > 0) {
        return of(title);
      }
    }
    return of(null);
  }

  private getRouteTitleDataNullable(child: ActivatedRoute) {
    const data: ApplicationRouteData = child.snapshot.data;
    if (data == null) {
      return null;
    }
    const menuData = data.menuData;
    if (menuData == null) {
      return null;
    }
    const titleData = menuData.title;
    if (titleData == null) {
      return null;
    }
    const routeConfigData: any = child.snapshot.routeConfig.data;
    const routeConfigResolvers: any = child.snapshot.routeConfig.resolve;
    // Only apply title if it comes from route config.
    // route data are passed on children routes
    const titleFromRouteConfig = (routeConfigData != null && routeConfigData.menuData != null && routeConfigData.menuData.title != null)
      || (routeConfigResolvers != null && routeConfigResolvers.title != null);
    if (!titleFromRouteConfig) {
      return null;
    }
    return titleData;
  }

  private handleRouteError(e: any): any {
    const urlObject = new URL(document.location.href);
    const reloadAttemptedParamValue = urlObject.searchParams.get(this.ROUTE_NAVIGATION_ERROR_RELOAD_ATTEMPTED_QUERY_PARAM);
    let redirectUrl;
    if (reloadAttemptedParamValue === 'true') {
      redirectUrl = `${document.location.origin}`;
    } else {
      const redirectUrlObject = new URL(`${urlObject.href}`);
      redirectUrlObject.searchParams.set(this.ROUTE_NAVIGATION_ERROR_RELOAD_ATTEMPTED_QUERY_PARAM, 'true');
      redirectUrl = redirectUrlObject.href;
    }
    if (this.cacheService.isPotentialCacheMismatchError(e)) {
      const requestedChunk = e.request;
      console.warn(`Failed to load module chunk, attempting a refresh to ${redirectUrl}. Failed url: ${requestedChunk}. Error: ${e}`);
      this.cacheService.checkUpdates().subscribe();
      this.messageService.add({
        severity: 'error',
        summary: `La navigation a échouée`,
        detail: `La page va tenter de se recharger...`,
        sticky: true,
      });
      return timer(1500).pipe(
        map(() => document.location.assign(redirectUrl)),
      ).toPromise();
    } else {
      console.warn('navigation error :(');
      console.warn(e);
      return Promise.resolve(redirectUrl);
    }
    //

    // this.errorHandler.handleError(err);
  }
}
