import {Injectable} from '@angular/core';
import {
  WsConstraintViolation,
  WsInsuranceCategory,
  WsInsuranceCategorySearch,
  WsInsuranceCategoryOptionalFeature,
  WsRef,
} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {WithId} from '../../client/domain/with-id';
import {InsuranceCategoryWsClient} from '../../client/resources/front/insurance/insurance-category-ws-client';
import {InsuranceCategoryConverter} from './insurance-category-converter';
import {InsuranceCategory} from '../../domain/insurance/insurance-category';
import {InsuranceCategorySearch} from '../../domain/insurance/insurance-category-search';
import {LabelledField} from '../../domain/labelled-field';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {InsuranceCategoryOptionalFeature} from '../../domain/insurance/insurance-category-optional-feature';

@Injectable({
  providedIn: 'root',
})
export class InsuranceCategoryService {


  constructor(private client: InsuranceCategoryWsClient,
  ) {
  }

  getInsuranceCategory$(ref: Ref<InsuranceCategory>, forceFetch?: boolean): Observable<InsuranceCategory> {
    return (ref == null || !isValidRef(ref)) ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsInsuranceCategory => InsuranceCategoryConverter.convertIn(wsInsuranceCategory)),
    );
  }

  searchInsuranceCategorys$(insuranceSearch: InsuranceCategorySearch, pagination: SimplePagination)
    : Observable<SearchResult<InsuranceCategory>> {
    const wsSearch = InsuranceCategoryConverter.convertFilterOut(insuranceSearch);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateInsuranceCategory$(insurance: InsuranceCategory): Observable<ValidationResult<InsuranceCategory>> {
    const wsInsuranceCategory = InsuranceCategoryConverter.convertOut(insurance);
    return this.client.doValidate(wsInsuranceCategory).pipe(
      map((results: WsInsuranceCategory | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveInsuranceCategory$(insurance: InsuranceCategory): Observable<WithId> {
    const wsInsuranceCategory = InsuranceCategoryConverter.convertOut(insurance);
    return this.client.doSave(wsInsuranceCategory);
  }

  deleteInsuranceCategory$(ref: Ref<InsuranceCategory>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  getAllInsuranceSubscriptionOptionalFeatures$(): Observable<LabelledField<InsuranceCategoryOptionalFeature>[]> {
    return this.client.getAllOptionalFeatures$().pipe(
      map(l => l.map(ws => EnumConverterUtils.convertWsLabelledField(ws, InsuranceCategoryOptionalFeature))),
    );
  }

  getInsuranceSubscriptionOptionalFeaturesFromFamily$(ref: Ref<InsuranceCategory>)
    : Observable<LabelledField<InsuranceCategoryOptionalFeature>[]> {
    return this.client.getFamilyEnabledOptionalFeatures$(ref).pipe(
      map(l => l.map(ws => EnumConverterUtils.convertWsLabelledField(ws, InsuranceCategoryOptionalFeature))),
    );
  }

  getEnabledInsuranceSubscriptionOptionalFeatures$(ref: Ref<InsuranceCategory>): Observable<InsuranceCategoryOptionalFeature[]> {
    if (ref == null) {
      return of([]);
    }
    const persistedFeatures$ = this.getInsuranceCategory$(ref).pipe(
      map(c => c == null ? [] : c.optionalFeatures),
    );
    const familyFeatures$ = this.getInsuranceSubscriptionOptionalFeaturesFromFamily$(ref);
    return forkJoin([persistedFeatures$, familyFeatures$]).pipe(
      map(r => [...r[0], ...r[1]].map(labelledField => labelledField.value)),
    );
  }

  serializeFilter(InsuranceSearch: InsuranceCategorySearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(InsuranceSearch);
    const wsInsuranceCategorySearch = InsuranceCategoryConverter.convertFilterOut(InsuranceSearch);
    return JSON.stringify(wsInsuranceCategorySearch);
  }

  deserializeFilter(valueString: string | null): InsuranceCategorySearch {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsInsuranceCategorySearch = JSON.parse(valueString);
      const filter = InsuranceCategoryConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsInsuranceCategory | WsConstraintViolation[]): ValidationResult<InsuranceCategory> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsInsuranceCategory => InsuranceCategoryConverter.convertIn(wsInsuranceCategory),
      propertyNameMappings: InsuranceCategoryConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsInsuranceCategory>>): Observable<SearchResult<InsuranceCategory>> {
    const rowTasks = results.list.map(ref => this.getInsuranceCategory$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<InsuranceCategory>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<InsuranceCategory>>>{
          list: rows,
        }),
      ));
  }
}
