import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsLifeIsLifeAdmin, WsLifeIsLifeUser} from '@lifeislife/lifeislife-ws-api';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {LifeislifeAdminConverter} from './lifeislife-admin.converter';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {LifeIsLifeAdmin} from '../../domain/lifeislife/lifeislife-admin';
import {ContactConverter} from '../contact/contact.converter';
import {UserConverter} from '../user/user.converter';
import {User} from '../../domain/user/user';
import {Contact} from '../../domain/contact/contact';
import {WsInviteTokenAuth} from '../../client/domain/auth/ws-invite-token-auth';
import {LifeislifeAdminWsClient} from '../../client/resources/front/lifeislife-admin-ws-client';
import {ContactWsClient} from '../../client/resources/front/contact/contact-ws-client';

@Injectable({
  providedIn: 'root',
})
export class LifeIsLifeAdminService {


  constructor(private client: LifeislifeAdminWsClient,
              private contactClient: ContactWsClient,
  ) {
  }

  createForExistingContact$(contact: Contact): Observable<LifeIsLifeAdmin> {
    const admin: WsLifeIsLifeAdmin = {
      contactRef: {id: contact.id},
    } as WsLifeIsLifeAdmin;
    return this.client.doCreate(admin).pipe(
      switchMap(ref => this.getLifeIsLifeAdmin$(ref)),
    );
  }

  createFromInvite$(auth: WsInviteTokenAuth, user: User, contact: Contact, password: string): Observable<LifeIsLifeAdmin> {
    contact.sendEmail = contact.email != null;
    contact.sendSms = contact.mobile != null;

    const wsUser = UserConverter.convertOut(user);
    const wsContact = ContactConverter.toWsContact(contact);

    return this.contactClient.doCreate(wsContact).pipe(
      map(contactRef => Object.assign({}, wsUser, <Partial<WsLifeIsLifeUser>>{
        contactRef: contactRef,
        newPassword: password,
      })),
      switchMap(wsuserToCreate => this.client.create$(auth, wsuserToCreate)),
      map(wsAdmin => LifeislifeAdminConverter.convertIn(wsAdmin)),
    );
  }

  getLifeIsLifeAdmin$(ref?: Ref<LifeIsLifeAdmin>): Observable<LifeIsLifeAdmin> {
    return !isValidRef(ref) ? of(null) : this.client.doGet(ref.id).pipe(
      map(wsLifeIsLifeAdmin => LifeislifeAdminConverter.convertIn(wsLifeIsLifeAdmin)),
    );
  }

  getLifeIsLifeAdminForContact$(contactRef: Ref<Contact>): Observable<LifeIsLifeAdmin | null> {
    return !isValidRef(contactRef) ? of(null) : this.client.getAdminRefForContactRef$(contactRef).pipe(
      switchMap(ref => this.getLifeIsLifeAdmin$(ref)),
    );
  }

  validateLifeIsLifeAdmin$(lifeIsLifeAdmin: LifeIsLifeAdmin): Observable<ValidationResult<LifeIsLifeAdmin>> {
    const wsLifeIsLifeAdmin = LifeislifeAdminConverter.convertOut(lifeIsLifeAdmin);
    return this.client.doValidate(wsLifeIsLifeAdmin).pipe(
      map((results: WsLifeIsLifeAdmin | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveLifeIsLifeAdmin$(lifeIsLifeAdmin: LifeIsLifeAdmin): Observable<any> {
    const wsLifeIsLifeAdmin = LifeislifeAdminConverter.convertOut(lifeIsLifeAdmin);
    return this.client.doSave(wsLifeIsLifeAdmin);
  }

  deleteLifeIsLifeAdmin$(id: number): Observable<any> {
    return this.client.doRemove(id);
  }

  private createValidationResult(results: WsLifeIsLifeAdmin | WsConstraintViolation[]): ValidationResult<LifeIsLifeAdmin> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsLifeIsLifeAdmin => LifeislifeAdminConverter.convertIn(wsLifeIsLifeAdmin),
      propertyNameMappings: LifeislifeAdminConverter.getValidationPropertyNameMappings(),
    });
  }

}
