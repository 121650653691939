import {Injectable} from '@angular/core';
import {AppPreferenceWsClient} from '../../client/resources/internal/preference/app-preference-ws-client';
import {Observable} from 'rxjs';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {AppPreference} from '../../domain/preference/app-preference';
import {switchMap} from 'rxjs/operators';
import {retryWithDelay} from './retry-with-delay';

@Injectable({
  providedIn: 'root',
})
export class AppPreferenceService {

  constructor(
    private client: AppPreferenceWsClient,
  ) {
  }

  getDbPreference$(name: string, forceFetch?: boolean): Observable<AppPreference> {
    return this.client.getDbPreference$(name);
  }

  setDbPreference$(name: string, value: string): Observable<AppPreference> {
    return this.client.doCreate({
      id: null,
      key: name,
      value: value,
    }).pipe(
      switchMap(r => this.getDbPreference$(name)),
    );
  }

  deleteDbPreference$(name: string): Observable<any> {
    return this.client.deleteDbPreference$(name);
  }

  getPreferenceValue$(name: string): Observable<string> {
    return this.client.getPreferenceValue$(name).pipe(
      // Some random net::ERR_FAILEDfor no apparent reason beside lot of requests in parallel
      retryWithDelay(500, 3),
    );
  }

  getPreferenceNames(pagination: SimplePagination, query?: string, all ?: boolean): Observable<string[]> {
    return this.client.getPreferenceNames(pagination, query, all);
  }

}
