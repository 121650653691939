import {WsPersonTitle, WsPersonTitleFilter} from '@lifeislife/lifeislife-ws-api';
import {SearchResult} from '../../client/domain/search/search-result';
import {PersonTitle} from '../../domain/person-title/person-title';
import {PersonTitleFilter} from '../../domain/person-title/person-title-filter';

export class PersonTitleConverter {

  static convertIn(value: WsPersonTitle): PersonTitle {
    return {
      id: value.id,
      title: value.title,
    };
  }

  static convertOut(value: PersonTitle): WsPersonTitle {
    return {
      id: value.id,
      title: value.title,
    };
  }


  static convertFilterIn(value?: WsPersonTitleFilter): PersonTitleFilter {
    if (value == null) {
      return undefined;
    }
    return {};
  }

  static convertFilterOut(value: PersonTitleFilter): WsPersonTitleFilter {
    if (value == null) {
      return undefined;
    }
    return {};
  }

  static convertSearchResultsIn(results: SearchResult<WsPersonTitle>): SearchResult<PersonTitle> {
    return Object.assign({}, results, <Partial<SearchResult<PersonTitle>>>{
      list: results.list.map(result => this.convertIn(result)),
    });
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsPersonTitle]: keyof PersonTitle } {
    return {
      id: 'id',
      title: 'title',
    };
  }

}
