import {ModuleWithProviders, NgModule} from '@angular/core';
import {AppConfigService} from './service/config/app-config.service';
import {AuthService} from './util/auth/auth.service';
import {InviteAuthService} from './util/auth/invite-auth.service';
import {CacheService} from './util/service-worker/cache.service';
import {AuthProvider} from './client/domain/auth/auth-provider';


@NgModule({
  imports: [],
  declarations: [],
  providers: [],
  exports: [],
})
export class LifeislifeDomainModule {

  public static forRoot(): ModuleWithProviders<LifeislifeDomainModule> {
    return {
      ngModule: LifeislifeDomainModule,
      providers: [
        AuthService,
        {provide: AuthProvider, useExisting: AuthService},
        AppConfigService,
        // AutCodeResolver,
        InviteAuthService,
        CacheService,
      ],
    };
  }
}
