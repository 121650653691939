import {Injectable} from '@angular/core';
import {PrimeNGConfig, Translation} from 'primeng/api';

@Injectable()
export class PrimengTranslationService {

  static FRENCH_TRANSLATION: Translation = {
    accept: 'Accepter',
    addRule: 'Ajouter',
    after: 'Après',
    apply: 'Appliquer',
    before: `Avant`,
    cancel: `Annuler`,
    choose: `Choix`,
    contains: `Contient`,
    endsWith: `Termine par`,
    equals: `Égal`,
    gt: `plus grand que`,
    gte: `plus grand ou égal à`,
    is: `égal`,
    isNot: `diffférent`,
    lt: `plus petit que`,
    lte: `plus petit ou égal à`,
    matchAll: `Tous`,
    matchAny: `N'importe lequel`,
    noFilter: `Aucun`,
    notContains: `Ne contient pas`,
    notEquals: `Pas égal à`,
    reject: `Refuser`,
    removeRule: `Enlever`,
    startsWith: `Commence par`,
    upload: `Upload`,
    monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin',
      'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
    monthNamesShort: ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin',
      'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'],
    dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    dayNamesShort: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    weekHeader: 'Sem.',
    today: 'Aujourd\'hui',
    clear: 'Effacer',
  };


  constructor(private config: PrimeNGConfig) {
  }

  setFrenchTranslation() {
    this.config.setTranslation(PrimengTranslationService.FRENCH_TRANSLATION);
  }
}
