import {Injectable} from '@angular/core';
import {WsDaoEventEntityType, WsRef, WsSigningPackage} from '@lifeislife/lifeislife-ws-api';
import {RequestService} from '../../../service/request.service';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {Observable} from 'rxjs';
import {FrontendAppConfigKey} from '../../../../domain/config/frontend-app-config-key';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SigningPackageWsClient extends CachedWsResourceClient<WsSigningPackage, WsRef<WsSigningPackage>> {

  constructor(
    private injectedRequestService: RequestService,
    private injectedAuthService: AuthProvider,
    private injectectedCacheService: ResourceCacheService,
    private injectedAppConfigService: AppConfigService,
  ) {
    super('/front/signingPackage', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.SIGNING_PACKAGE, injectectedCacheService);
  }

  getSignerSignUrl(signingPackageId: number, contactId: number) {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    return `${wsUri}/unrestricted/signing/sign`
      + `?packageId=${signingPackageId}`
      + `&contactId=${contactId}`;
  }

  updateStatusFromProvider$(signingPackageId: number): Observable<any> {
    const url = this.getResourceUrl() + `/${signingPackageId}/status`;
    return this.requestService.sendRequest({
      method: 'PUT', url: url,
    }, this.authService.getAuth());
  }


  rejectSigningPackage$(signingPackageId: number): Observable<any> {
    const url = this.getResourceUrl() + `/${signingPackageId}/status/reject`;
    return this.requestService.sendRequest({
      method: 'PUT', url: url,
    }, this.authService.getAuth());
  }


  resendSigningPackageInvitationMail$(signingPackageId: number): Observable<any> {
    const url = this.getResourceUrl() + `/${signingPackageId}/invitation`;
    return this.requestService.sendRequest({
      method: 'PUT', url: url,
    }, this.authService.getAuth());
  }


  deleteSigningPackage$(signingPackageId: number, keepFiles?: boolean): Observable<any> {
    const url = this.getResourceUrl() + `/${signingPackageId}`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        keepFiles: `${keepFiles || false}`,
      },
    });
    return this.requestService.sendRequest({
      method: 'DELETE', url: url, params: params,
    }, this.authService.getAuth());
  }
}
