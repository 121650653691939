import {TrusteeContactType} from '../../domain/trustee/trustee-contact-type';
import {WsTrusteeContactType} from '@lifeislife/lifeislife-ws-api';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';

export class TrusteeContactTypeConverter {


  static convertIn(value: WsTrusteeContactType): TrusteeContactType {
    return EnumConverterUtils.convertValue(value, TrusteeContactType);
  }

  static convertOut(value: TrusteeContactType): WsTrusteeContactType {
    return EnumConverterUtils.convertValue(value, WsTrusteeContactType);
  }

}
