import {Injectable, Optional} from '@angular/core';
import {BehaviorSubject, combineLatest, concat, forkJoin, Observable, of} from 'rxjs';
import {FrontendAppSwitch} from '../../domain/config/frontend-app-switch';
import {FrontendAppConfigKey} from '../../domain/config/frontend-app-config-key';
import {catchError, debounceTime, defaultIfEmpty, map, publishReplay, refCount, switchMap, take} from 'rxjs/operators';
import {ApplicationCompileTimeSettings, ApplicationConfigSettings} from '../../domain/config/application-config-settings';
import {ApplicationVersion} from '../../domain/config/application-version';
import {AuthState} from '../../util/auth/auth-state';
import {ParsedConfigSettings} from '../../domain/config/parsed-config-settings';
import {ApplicationAuthConfig} from '../../domain/config/application-auth-config';
import {Role} from '../../domain/role/role';
import {ClientConfig} from '../../client/domain/config/client-config';
import {TrusteeContact} from '../../domain/trustee/trustee-contact';
import {CustomerContact} from '../../domain/customer-contact/customer-contact';
import {AppConfigConverter} from './config-converter';
import {Trustee} from '../../domain/trustee/trustee';
import {isSameRef, isValidRef, Ref} from '../../domain/shared/ref';
import {UnrestrictedTrusteeClient} from '../../client/resources/unrestricted/unresticted-trustee-client';
import {OAuthService} from 'angular-oauth2-oidc';
import {HttpClient} from '@angular/common/http';
import {WindowRef} from '../../util/window/window-ref';
import {fromPromise} from 'rxjs/internal-compatibility';

/**
 * Centralized configuration service for feature switches, application configuration and
 * context-dependant feature switches.
 */
@Injectable()
export class AppConfigService {

  // Deployemnt settings: provided at compile time and app startup
  private deploymentSettings$: Observable<ApplicationConfigSettings>;
  // Settings are augmented by application logic. However, we keep a hierarchy
  private authStateAugmentationSource$ = new BehaviorSubject<AuthState>(null);
  private authStateAugmentedSettings$: Observable<ApplicationConfigSettings>;

  private trusteeContextAugmentationSrouce$ = new BehaviorSubject<Ref<Trustee>>(null);
  private trusteeContextAugmentedSettings$: Observable<ApplicationConfigSettings>;

  private contactTypeContextAugmentationSource$ = new BehaviorSubject<[Role, TrusteeContact, CustomerContact]>([null, null, null]);
  private contactTypeAugmentedSettings$: Observable<ApplicationConfigSettings>;

  // Parsed settings source
  settings$: Observable<ParsedConfigSettings>;
  curSettings: ParsedConfigSettings = null;

  constructor(
    private httpClient: HttpClient,
    private applicationVersion: ApplicationVersion,
    private unrestrictedTrusteeWsClient: UnrestrictedTrusteeClient,
    private windowRef: WindowRef,
    // Require compile-time settings. This is used to resolve ws uri for resources that need to be fetched while initializing this service.
    private applicationCompileTimeConfig: ApplicationCompileTimeSettings,
    @Optional() private oAuthService: OAuthService,
  ) {
    this.deploymentSettings$ = this.loadDeploymentSettings$();
    this.authStateAugmentedSettings$ = combineLatest([this.deploymentSettings$, this.authStateAugmentationSource$]).pipe(
      switchMap(r => this.augmentSettingsWithAuthState$(r[0], r[1])),
      publishReplay(1), refCount(),
    );
    this.trusteeContextAugmentedSettings$ = combineLatest([
      this.authStateAugmentedSettings$, this.trusteeContextAugmentationSrouce$,
    ]).pipe(
      switchMap(r => this.augmentSettingsWithTrusteeContext$(r[0], r[1])),
      publishReplay(1), refCount(),
    );
    this.contactTypeAugmentedSettings$ = combineLatest([
      this.trusteeContextAugmentedSettings$, this.contactTypeContextAugmentationSource$,
    ]).pipe(
      switchMap(r => this.augmentSettingsWithContext$(r[0], ...r[1])),
      publishReplay(1), refCount(),
    );

    // Gather app config settings as string/string map from all available sources
    this.settings$ = concat(this.deploymentSettings$, this.contactTypeAugmentedSettings$).pipe(
      map(s => AppConfigConverter.parseSettings(s)),
      publishReplay(1), refCount(),
    );
    this.settings$.subscribe(s => this.setCurSettings(s));
  }

  /**
   * Emits true when the deployment config has been parsed and is referenced in instance field.
   * Before this event, app config is not available.
   */
  getInitialized$(): Observable<boolean> {
    return this.settings$.pipe(
      debounceTime(100),
      take(1),
      map(() => true),
    );
  }

  getFrontSwitchEnabled$(key: FrontendAppSwitch): Observable<boolean> {
    return this.settings$.pipe(
      map(s => s.frontSwitches.get(key) === true),
      publishReplay(1), refCount(),
    );
  }

  geTypedSwitchEnabled$<T>(key: T, type: any): Observable<boolean> {
    return this.settings$.pipe(
      map(s => AppConfigConverter.parseSwitches<T>(s.settings.switches || {}, type)),
      map(c => c.get(key) === true),
      publishReplay(1), refCount(),
    );
  }

  getEnabledTypedSwitches$<T>(type: any): Observable<T[]> {
    return this.settings$.pipe(
      map(s => AppConfigConverter.parseEnabledSwicthesArray<T>(s.settings.switches || {}, type)),
      publishReplay(1), refCount(),
    );
  }

  isSwitchCurrentlyEnabled(appSwitch: FrontendAppSwitch): boolean {
    if (this.curSettings == null && this.applicationCompileTimeConfig) {
      return false;
    }
    const settings = this.curSettings || AppConfigConverter.parseSettings(this.applicationCompileTimeConfig);
    const curSwitches = settings.frontSwitches;
    return curSwitches.get(appSwitch) === true;
  }

  isTypedSwitchCurrentlyEnabled<T>(appSwitch: T, type: any): boolean {
    if (this.curSettings == null && this.applicationCompileTimeConfig) {
      return false;
    }
    const settings = this.curSettings || AppConfigConverter.parseSettings(this.applicationCompileTimeConfig);
    const switches = AppConfigConverter.parseSwitches<T>(settings.settings.switches || {}, type);
    return switches.get(appSwitch) === true;
  }

  getConfigValue$(key: FrontendAppConfigKey): Observable<string> {
    return this.settings$.pipe(
      map(s => s.frontConfig.get(key)),
      publishReplay(1), refCount(),
    );
  }

  getCurrentConfigValue(key: FrontendAppConfigKey): string | null {
    if (this.curSettings == null && this.applicationCompileTimeConfig == null) {
      return null;
    }
    const settings = this.curSettings || AppConfigConverter.parseSettings(this.applicationCompileTimeConfig);
    const curConfig = settings.frontConfig;
    return curConfig.get(key);
  }

  getCurrentConfigIntValue(key: FrontendAppConfigKey): number | null {
    const stringValue = this.getCurrentConfigValue(key);
    const parsedValue = parseInt(stringValue, 10);
    if (isNaN(parsedValue)) {
      return null;
    } else {
      return parsedValue;
    }
  }

  getApplicationVersion(): string {
    return this.applicationVersion.version;
  }

  /**
   * Updates deployment settings with settings granted wrt auth state.
   * Atm, checks switch role grants provided in settings
   * @param authState
   */
  setAuthStateContext(authState: AuthState) {
    if (authState == null || authState.status !== 'authenticated') {
      this.authStateAugmentationSource$.next(null);
      return;
    }
    const curAuthState = this.authStateAugmentationSource$.getValue();
    if (curAuthState && curAuthState.status === authState.status
      && isSameRef(curAuthState.contactRef, authState.contactRef)) {
      return;
    }

    this.authStateAugmentationSource$.next(authState);
  }


  setContactTypeContext(activeRole: Role,
                        trusteeContact: TrusteeContact | null,
                        customerContact: CustomerContact | null) {
    const curContext = this.contactTypeContextAugmentationSource$.getValue();
    if (curContext) {
      if (curContext[0] === activeRole
        && isSameRef(curContext[1], trusteeContact)
        && isSameRef(curContext[2], customerContact)) {
        return;
      }
    }
    this.contactTypeContextAugmentationSource$.next([activeRole, trusteeContact, customerContact]);
  }

  setTrusteeContext(trusteeRef: Ref<Trustee>) {
    const curTrusteeRef = this.trusteeContextAugmentationSrouce$.getValue();
    if (isSameRef(curTrusteeRef, trusteeRef)) {
      return;
    }
    this.trusteeContextAugmentationSrouce$.next(trusteeRef);
  }

  private setCurSettings(parsedConfigSettings: ParsedConfigSettings) {
    const devDebug = parsedConfigSettings.frontSwitches.get(FrontendAppSwitch.front_dev_debug);
    if (this.curSettings != null) {
      const curWsUri = this.curSettings.frontConfig.get(FrontendAppConfigKey.lifeislife_ws_uri);
      const newWsUri = parsedConfigSettings.frontConfig.get(FrontendAppConfigKey.lifeislife_ws_uri);
      if (curWsUri !== newWsUri) {
        console.warn(`Altering ws uri: now ${newWsUri}, ws ${curWsUri}`);
      }
    } else {
      const appName = parsedConfigSettings.frontConfig.get(FrontendAppConfigKey.app_name);
      const appVersion = this.applicationVersion.version;
      const wsUri = parsedConfigSettings.frontConfig.get(FrontendAppConfigKey.lifeislife_ws_uri);
      if (devDebug) {
        console.log(`${appName} ${appVersion} using ws uri ${wsUri}`);
      }
    }

    this.curSettings = parsedConfigSettings;
    if (devDebug) {
      console.log(`New app settings:`);
      console.log(parsedConfigSettings);
    }

  }

  private augmentSettingsWithAuthState$(settings: ApplicationConfigSettings, authState: AuthState): Observable<ApplicationConfigSettings> {
    if (authState == null) {
      return of(settings);
    }
    // TODO
    const customerContactRefs = authState.customerContactRefs;
    const trusteeContactRefs = authState.trusteeContactRefs;
    const roles = authState.roles;

    const debug = this.isSwitchCurrentlyEnabled(FrontendAppSwitch.front_dev_debug);
    if (debug) {
      if (authState) {
        console.log(`New auth state: ${authState.status}, ${authState.roles}`);
      } else {
        console.log(`New auth state: null`);
      }
    }
    const newSettings = AppConfigConverter.augmentRoleBasedSettingsForRoles(settings, roles);
    return of(newSettings);
  }

  private augmentSettingsWithTrusteeContext$(settings: ApplicationConfigSettings,
                                             trusteeRef: Ref<Trustee>): Observable<ApplicationConfigSettings> {
    const debug = this.isSwitchCurrentlyEnabled(FrontendAppSwitch.front_dev_debug);
    if (debug) {
      console.log(`New trustee ref: ${trusteeRef == null ? 'null' : trusteeRef.id}`);
    }
    if (trusteeRef == null || !isValidRef(trusteeRef)) {
      return of(settings);
    }

    const apiUri = this.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    return this.unrestrictedTrusteeWsClient.getTrusteePreferencesMap$(trusteeRef.id, apiUri).pipe(
      map(trusteePrefs => AppConfigConverter.augmentTrusteeBasedSettingsForTrustee(settings, trusteePrefs)),
    );
  }

  private augmentSettingsWithContext$(settings: ApplicationConfigSettings,
                                      activeRole: Role,
                                      trusteeContact: TrusteeContact | null,
                                      customerContact: CustomerContact | null): Observable<ApplicationConfigSettings> {

    const debug = this.isSwitchCurrentlyEnabled(FrontendAppSwitch.front_dev_debug);
    if (debug) {
      console.log(`New contact type context: ${activeRole}`);
      console.log([trusteeContact, customerContact]);
    }

    if (activeRole == null) {
      return of(settings);
    }
    let newSettings = settings;
    newSettings = AppConfigConverter.augmentRoleBasedSettingsForRoles(newSettings, [activeRole]);
    newSettings = AppConfigConverter.augmentTrusteeContactBasedSettingsForTrusteeContact(newSettings, trusteeContact);
    newSettings = AppConfigConverter.augmentCustomerContactBasedSettingsForCustomerContact(newSettings, customerContact);
    return of(newSettings);
  }

  private loadDeploymentSettings$(): Observable<ApplicationConfigSettings> {
    let curSettings: ApplicationConfigSettings = {
      config: {},
      switches: {},
      authConfig: {} as ApplicationAuthConfig,
    };

    // Apply compile time settings
    if (this.applicationCompileTimeConfig) {
      curSettings = AppConfigConverter.augmentSettings(curSettings, this.applicationCompileTimeConfig);
    }

    // Apply mobile userAgent check and standalone launch
    let mobileUserAgent = false;
    let standaloneApplication = false;
    if (this.windowRef && this.windowRef.getWindow() && this.windowRef.getWindow().navigator) {
      const window = this.windowRef.getWindow();
      const navigator = window.navigator;
      mobileUserAgent = /Mobi/i.test(navigator.userAgent);
      standaloneApplication = navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
    }
    curSettings = AppConfigConverter.augmentSettings(curSettings, {
      switches: {
        [FrontendAppSwitch.useragent_mobile]: mobileUserAgent,
        [FrontendAppSwitch.navigator_stanalone]: standaloneApplication,
      },
    });

    // Load deployment settings from app-settings.json, and override with client-config.json and auth-config.json
    const deploymentSettings$ = this.fetchJsonResources$<ApplicationConfigSettings>('app-settings.json').pipe(
      catchError(e => of(null)), defaultIfEmpty(null),
      map(s => AppConfigConverter.augmentSettings(curSettings, s)),
    );
    const deploymentClientConfig$ = this.fetchJsonResources$<ClientConfig>('client-config.json').pipe(
      catchError(e => of(null)), defaultIfEmpty(null),
    );
    const deploymentAuthConfig$ = this.fetchJsonResources$<ApplicationAuthConfig>('auth-config.json').pipe(
      catchError(e => of(null)), defaultIfEmpty(null),
    );

    return forkJoin([deploymentSettings$, deploymentClientConfig$, deploymentAuthConfig$]).pipe(
      map(r => {
        const baseSettings = r[0];
        const clientConfig: ClientConfig = r[1];
        const authConfig: ApplicationAuthConfig = r[2];

        const appConfig = {};
        if (clientConfig && clientConfig.lifeislifeWsResourcesUrl) {
          appConfig[FrontendAppConfigKey.lifeislife_ws_uri] = clientConfig.lifeislifeWsResourcesUrl;
        }
        const augmentedSettings = AppConfigConverter.augmentSettings(baseSettings, {
          config: appConfig,
          authConfig: authConfig,
        });
        return augmentedSettings;
      }),
      switchMap(settings => this.configureAuthServiceWithSettings$(settings)),
      switchMap(settings => this.fetchApiApplicationSettings$(settings)),
      publishReplay(1), refCount(),
    );
  }

  private fetchApiApplicationSettings$(settings: ApplicationConfigSettings): Observable<ApplicationConfigSettings> {
    const wsUri = settings.config[FrontendAppConfigKey.lifeislife_ws_uri];
    if (wsUri == null) {
      return of(settings);
    }

    const configUri = `${wsUri}/unrestricted/config`;
    const switchesUri = `${wsUri}/unrestricted/config/switches`;

    const config$ = this.httpClient.get<Record<string, string>>(configUri);
    const switches$ = this.httpClient.get<Record<string, boolean>>(switchesUri);
    return forkJoin([config$, switches$]).pipe(
      catchError(e => of([{}, {}])),
      map(r => AppConfigConverter.augmentSettings(settings, {
        config: r[0],
        switches: r[1],
      } as Partial<ApplicationConfigSettings>)),
    );
  }

  private configureAuthServiceWithSettings$(settings: ApplicationConfigSettings): Observable<ApplicationConfigSettings> {
    let authTask$: Observable<any> = of(null);
    if (this.oAuthService) {
      if (settings.authConfig && settings.authConfig.issuer) {
        this.oAuthService.configure(settings.authConfig);
        authTask$ = this.loadDiscoveryDocument$();
      } else {
        console.warn(`No oidc config`);
      }
    }
    return authTask$.pipe(map(a => settings));
  }

  private fetchJsonResources$<T>(path: string) {
    return this.httpClient.get<T>(path);
  }

  private loadDiscoveryDocument$(): Observable<any> {
    if (this.oAuthService) {
      const promise = this.oAuthService.loadDiscoveryDocument();
      return fromPromise(promise).pipe(
        catchError(e => {
          console.warn(`Unable to load oidc doc: ${e}`);
          return of(null);
        }),
      );
    } else {
      return of(null);
    }
  }

}
