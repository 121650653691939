import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Contact, PropertyDiff, Ref, Trustee, ValidationResult} from '@lifeislife/lifeislife-domain';
import {forkJoin, Subscription} from 'rxjs';
import {ContactClaim} from '@lifeislife/lifeislife-domain';
import {ContactService} from '@lifeislife/lifeislife-domain';
import {ContactClaimType} from '@lifeislife/lifeislife-domain';
import {LifeislifeFrontErrorHandler} from '../../../../commons/services/error.handler';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ContactFormComponent,
    multi: true,
  }],
})
export class ContactFormComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @Input()
  trusteeRef: Ref<Trustee>;
  @Input()
  trusteeSelectVisible = true;
  @Input()
  disabled: boolean;
  @Input()
  validationResults: ValidationResult<Contact>;
  @Input()
  propertyDiff: PropertyDiff<Contact>;
  @Input()
  showAddressBlock = true;
  @Input()
  showIdBlock = true;
  @Input()
  showTelBlock = true;
  @Input()
  showMaritalStatus: true;
  @Input()
  showSettingsBlock = false;
  @Input()
  showDomain = false;
  @Input()
  showInternal = false;
  @Input()
  showFax: false;
  // Deprecated: not required for lil business
  @Input()
  showCompany = false;
  @Input()
  showImportDetails = false;
  @Input()
  showDubiousTrusteeName = false;
  @Input()
  showDubiousMail = false;
  @Input()
  showClaimIcons = true;

  contact: Contact;
  namesClaimNullable: ContactClaim | null = null;
  emailClaimNullable: ContactClaim | null = null;
  ssinClaimNullable: ContactClaim | null = null;
  mobileClaimNullable: ContactClaim | null = null;

  private onChangeFunction: Function;
  private onTouchedFunction: Function;

  private loadingClaimContactId: number;
  private rxClaimsSubscription: Subscription;

  constructor(
    private contactService: ContactService,
    private errorHandler: LifeislifeFrontErrorHandler,
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.cancelLoadingClaims();
  }

  writeValue(val: Contact) {
    this.contact = val == null ? <Contact>{} : val;
    this.loadClaims();
  }

  registerOnChange(fn: any) {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedFunction = fn;
  }

  fireChanges() {
    this.onTouchedFunction();
    this.onChangeFunction(this.contact);
  }

  private loadClaims() {
    if (this.contact == null || this.contact.id == null) {
      this.cancelLoadingClaims();
      return;
    }
    if (!this.showClaimIcons) {
      this.cancelLoadingClaims();
      return;
    }
    if (this.loadingClaimContactId && this.loadingClaimContactId === this.contact.id) {
      // ALready loading this one
      return;
    }
    this.cancelLoadingClaims();
    this.rxClaimsSubscription = forkJoin([
      this.contactService.getContactClaim$(this.contact, ContactClaimType.SSIN),
      this.contactService.getContactClaim$(this.contact, ContactClaimType.EMAIL),
      this.contactService.getContactClaim$(this.contact, ContactClaimType.MOBILE_PHONE_NUMBER),
      this.contactService.getContactClaim$(this.contact, ContactClaimType.NAMES),
    ]).subscribe(r => {
      this.ssinClaimNullable = r[0];
      this.emailClaimNullable = r[1];
      this.mobileClaimNullable = r[2];
      this.namesClaimNullable = r[3];
    }, e => {
      this.errorHandler.handleError(e);
      this.cancelLoadingClaims();
    });
  }

  private cancelLoadingClaims() {
    if (this.rxClaimsSubscription) {
      this.rxClaimsSubscription.unsubscribe();
      this.rxClaimsSubscription = null;
    }
    this.emailClaimNullable = null;
    this.mobileClaimNullable = null;
    this.ssinClaimNullable = null;
    this.namesClaimNullable = null;
  }
}
