import {WsTrustee, WsTrusteePublicView, WsTrusteeStatus} from '@lifeislife/lifeislife-ws-api';
import {Trustee} from '../../domain/trustee/trustee';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {TrusteeStatus} from '../../domain/trustee/trustee-status';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';

export class TrusteeConverter {

  static convertIn(value: WsTrustee): Trustee {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      creationTime: DateConverterUtils.parseIsoDateTime(value.creationTime),
      updateTime: DateConverterUtils.parseIsoDateTime(value.updateTime),

      name: value.name,
      configKey: value.configKey,
      code: value.code,

      legalName: value.legalName,
      vatNumber: value.vatNumber,

      mainTrusteeContactRef: value.mainTrusteeContactRef,

      trusteeStatus: EnumConverterUtils.convertValue(value.wsTrusteeStatus, TrusteeStatus),
      feeRatio: value.feeRatio,

      smsSenderNumber: value.smsSenderNumber,
      mailSenderAddress: value.mailSenderAddress,

      address: value.address,
      zip: value.zip,
      city: value.city,
      countryRef: value.countryRef,

      webSiteUrl: value.webSiteUrl,
      phoneNumber: value.phoneNumber,

      template: value.template,
    };
  }

  static convertPublicIn(value: WsTrusteePublicView): Partial<Trustee> {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,

      name: value.name,
      configKey: value.configKey,
      code: value.code,

      legalName: value.legalName,
      vatNumber: value.vatNumber,

      smsSenderNumber: value.smsSenderNumber,
      mailSenderAddress: value.mailSenderAddress,

      address: value.address,
      zip: value.zip,
      city: value.city,
      countryRef: value.countryRef,

      webSiteUrl: value.webSiteUrl,
      phoneNumber: value.phoneNumber,
    };
  }

  static convertOut(value: Trustee): WsTrustee {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      creationTime: DateConverterUtils.formatDateToIsoDateTime(value.creationTime),
      updateTime: DateConverterUtils.formatDateToIsoDateTime(value.updateTime),

      name: value.name,
      configKey: value.configKey,
      code: value.code,

      legalName: value.legalName,
      vatNumber: value.vatNumber,

      mainTrusteeContactRef: value.mainTrusteeContactRef,

      wsTrusteeStatus: EnumConverterUtils.convertValue(value.trusteeStatus, WsTrusteeStatus),
      feeRatio: value.feeRatio,

      smsSenderNumber: value.smsSenderNumber,
      mailSenderAddress: value.mailSenderAddress,

      address: value.address,
      zip: value.zip,
      city: value.city,
      countryRef: value.countryRef,

      webSiteUrl: value.webSiteUrl,
      phoneNumber: value.phoneNumber,

      template: value.template,
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsTrustee]: keyof Trustee } {
    return {
      id: 'id',
      creationTime: 'creationTime',
      updateTime: 'updateTime',
      name: 'name',
      configKey: 'configKey',
      code: 'code',
      legalName: 'legalName',
      vatNumber: 'vatNumber',
      mainTrusteeContactRef: 'mainTrusteeContactRef',
      wsTrusteeStatus: 'trusteeStatus',
      feeRatio: 'feeRatio',
      smsSenderNumber: 'smsSenderNumber',
      mailSenderAddress: 'mailSenderAddress',
      address: 'address',
      zip: 'zip',
      city: 'city',
      countryRef: 'countryRef',
      webSiteUrl: 'webSiteUrl',
      phoneNumber: 'phoneNumber',
      template: 'template',
    };
  }

}
