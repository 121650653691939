import {Component, Input, OnInit} from '@angular/core';
import {InsuranceProvider, InsuranceProviderService, ProductProvider, Ref} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';

@Component({
  selector: 'llc-insurance-partner',
  templateUrl: './insurance-partner.component.html',
  styleUrls: ['./insurance-partner.component.scss'],
})
export class InsurancePartnerComponent implements OnInit {

  @Input()
  set ref(value: Ref<InsuranceProvider>) {
    this.refSource$.next(value);
  }

  @Input()
  showLogo: boolean;
  @Input()
  showTitle: boolean;
  @Input()
  asLink: boolean;
  @Input()
  useFallbackLogo: boolean;

  refSource$ = new BehaviorSubject<Ref<InsuranceProvider>>(null);
  name$: Observable<string>;
  logoUrl$: Observable<string>;
  linkUrl$: Observable<string>;

  constructor(
    private insuranceProviderService: InsuranceProviderService,
  ) {
  }

  ngOnInit(): void {
    const insuranceProvider$ = this.refSource$.pipe(
      switchMap(ref => ref == null ? of(null) : this.insuranceProviderService.getUnrestrictedInsuranceProvider$(ref)),
      publishReplay(1), refCount(),
    );
    const provider$: Observable<ProductProvider | null> = insuranceProvider$.pipe(
      switchMap(i => i == null ? of(null) : this.insuranceProviderService.getUnrestrictedProductProvider$(i.productProviderRef)),
      publishReplay(1), refCount(),
    );

    this.name$ = provider$.pipe(
      map(p => p == null ? null : p.publicLabel),
      publishReplay(1), refCount(),
    );
    this.logoUrl$ = provider$.pipe(
      map(p => p == null ? null : this.insuranceProviderService.getUnrestrictedProviderLogoUrl(p, this.useFallbackLogo)),
      publishReplay(1), refCount(),
    );
    this.linkUrl$ = provider$.pipe(
      map(p => p == null ? null : p.publicUri),
      publishReplay(1), refCount(),
    );
  }

}
