import {Injectable} from '@angular/core';
import {combineLatest, forkJoin, Observable, of} from 'rxjs';
import {MenuItem} from 'primeng/api';
import {distinctUntilChanged, map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {AppConfigService, FrontendAppSwitch, Role, User, UserAuthService} from '@lifeislife/lifeislife-domain';
import {ApplicationMenuItemData} from './main/routing/application-menu-item-data';
import {ApplicationMenu} from './application-menu';
import {ApplicationMenuItemDef} from './main/routing/application-menu-item-def';
import {AppFeatureService} from './main/services/app-feature.service';
import {AppFeature} from './main/model/app-feature';

export interface MenuCreateOptions {
  parentLink?: any[];
  clearLinkOnParentWithChildren?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationMenuService {

  private applicationMenu: Observable<MenuItem[]>;

  constructor(
    private userAuthService: UserAuthService,
    private appFeatureService: AppFeatureService,
    private configService: AppConfigService,
  ) {
    this.applicationMenu = this.createMenu$(ApplicationMenu, {
      clearLinkOnParentWithChildren: true,
    });
  }

  getMenu(): Observable<MenuItem[]> {
    return this.applicationMenu;
  }

  createMenu$(menuDef: ApplicationMenuItemDef[], options: MenuCreateOptions = {}): Observable<MenuItem[]> {
    return combineLatest([
      this.userAuthService.user$.pipe(distinctUntilChanged()),
      this.userAuthService.roles$.pipe(distinctUntilChanged()),
      this.appFeatureService.appFeatures$,
    ]).pipe(
      switchMap(r => this.createApplicationMenu$(r[0], r[1], r[2], menuDef, options)),
      publishReplay(1), refCount(),
    );
  }

  private createApplicationMenu$(loggedUser: User, roles: Role[], features: AppFeature[], menuDef: ApplicationMenuItemDef[], options: MenuCreateOptions): Observable<MenuItem[]> {
    if (loggedUser == null) {
      return of([]);
    }
    const menuItemName = Object.getOwnPropertyNames(menuDef);
    const menuItem$List: Observable<MenuItem>[] = menuDef
      .map(itemDef => this.createMainRouteMenuItem$(loggedUser, features, roles, itemDef, options.parentLink));

    const menuItemList$ = menuItem$List.length === 0 ? of([]) : forkJoin(menuItem$List);
    return menuItemList$.pipe(
      map(items => items
        .filter(item => item != null)
        .map(item => this.clearParentLinksIfChildrenPresent(item, options.clearLinkOnParentWithChildren))
        .filter(item => item != null),
      ),
    );
  }

  private createMainRouteMenuItem$(loggedUser: User, appFeatures: AppFeature[], roles: Role[], itemDef: ApplicationMenuItemDef, parentLink: any[]): Observable<MenuItem> {
    if (itemDef.separator) {
      return of(<MenuItem>{
        separator: true,
      });
    }
    const menuData = itemDef.data;
    const mainLink = menuData.menuLink || this.createRelativeLink(parentLink, menuData.relativeMenuLink);
    const mainItem: MenuItem = {
      routerLink: menuData.url == null ? mainLink : undefined,
      id: menuData.id,
      label: menuData.title,
      title: menuData.title,
      icon: menuData.menuIcon,
      url: menuData.url,
      target: menuData.url == null ? undefined : '_blank',
    };
    return this.checkItemAccess$(loggedUser, appFeatures, roles, menuData, mainItem).pipe(
      switchMap(item => this.checkCreateItemChildren$(loggedUser, appFeatures, roles, itemDef, item)),
    );
  }

  private checkCreateItemChildren$(loggedUser: User, appFeatures: AppFeature[], roles: Role[],
                                   itemDef: ApplicationMenuItemDef,
                                   parentItem: MenuItem): Observable<MenuItem> {
    if (parentItem == null) {
      return of(null);
    }
    const childMenuDef: ApplicationMenuItemDef[] = itemDef.children;
    if (childMenuDef == null) {
      return of(parentItem);
    }

    const safeParentItem = parentItem || {};
    const safeParentLink = safeParentItem.routerLink || [];
    const childItem$List: Observable<MenuItem>[] = childMenuDef
      .map(childDef => this.createChildRouteMenuItem$(loggedUser, appFeatures, roles, safeParentLink, childDef));

    const childItemList$ = childItem$List.length === 0 ? of([]) : forkJoin(childItem$List);
    return childItemList$.pipe(
      map(childItems => Object.assign(safeParentItem, {}, <Partial<MenuItem>>{
        items: childItems.filter(item => item != null),
      })),
    );
  }


  private createChildRouteMenuItem$(loggedUser: User, appFeatures: AppFeature[], roles: Role[], parentLink: any[], itemDef: ApplicationMenuItemDef)
    : Observable<MenuItem> {
    const menuData = itemDef.data;
    const childMenuItem = this.createMenuItem(menuData);
    return this.checkItemAccess$(loggedUser, appFeatures, roles, menuData, childMenuItem).pipe(
      switchMap(menuItem => this.checkCreateItemChildren$(loggedUser, appFeatures, roles, itemDef, menuItem)),
    );
  }


  private checkItemAccess$(loggedUser: User | null, appFeatures: AppFeature[], roles: Role[],
                           menuData: ApplicationMenuItemData,
                           item: MenuItem): Observable<MenuItem | null> {
    const debug = this.configService.isSwitchCurrentlyEnabled(FrontendAppSwitch.front_dev_debug);
    if (menuData.anyAppFeature != null || menuData.allAppFeatures != null) {
      if (!this.checkAppFeatures(menuData, appFeatures)) {
        if (debug) {
          console.warn('no feature for ' + menuData.id);
        }
        return of(null);
      }
    }
    if (menuData.userRoleConstraint != null) {
      if (!this.checkUserRole(loggedUser, roles, menuData.userRoleConstraint)) {
        if (debug) {
          console.warn('no role for ' + menuData.id);
        }
        return of(null);
      }
    }

    return of(item);
  }

  private checkAppFeatures(menuData: ApplicationMenuItemData,
                           loggedAppFeatures: AppFeature[]): boolean {
    if (menuData.anyAppFeature && menuData.anyAppFeature.length > 0) {
      const included = menuData.anyAppFeature.find(f => {
        return loggedAppFeatures.indexOf(f) >= 0;
      });
      if (included == null) {
        return false;
      }
    }
    if (menuData.allAppFeatures && menuData.allAppFeatures.length > 0) {
      const excluded = menuData.allAppFeatures.find(f => {
        return loggedAppFeatures.indexOf(f) < 0;
      });
      if (excluded != null) {
        return false;
      }
    }
    return true;
  }

  private checkUserRole(loggedUser: User, roles: Role[], constraint: Role[]) {
    if (loggedUser == null) {
      return false;
    }
    const granted = constraint.find(
      role => roles.includes(role),
    ) != null;
    return granted;
  }

  private createMenuItem(menuData: ApplicationMenuItemData, parentLink?: any[]): MenuItem {
    const menuLink = parentLink == null ? [] : [...parentLink];
    if (menuData.menuLink != null) {
      menuLink.push(...menuData.menuLink);
    }
    return {
      label: menuData.title,
      icon: menuData.menuIcon,
      id: menuData.id || menuLink.toString(),
      routerLink: menuData.url == null ? menuLink : undefined,
      title: menuData.title,
      url: menuData.url,
      target: menuData.url == null ? undefined : '_blank',
    };
  }

  private clearParentLinksIfChildrenPresent(item: MenuItem, clear: boolean) {
    if (!clear) {
      return item;
    }
    if (item.routerLink && item.items) {
      if (item.items.length > 0) {
        item.routerLink = null;
      }
    }
    return item;
  }

  private createRelativeLink(parentLink: any[], relativeMenuLink: any[]) {
    if (parentLink == null || relativeMenuLink == null) {
      return undefined;
    }
    return [...parentLink, ...relativeMenuLink];
  }
}
