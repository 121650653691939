import {Injectable} from '@angular/core';
import {WsIdResourceCache} from '../../../private_util/wsIdResourceCache';
import {WsSmtpAccount} from '@lifeislife/lifeislife-ws-api';
import {WsRequestCache} from '../../../private_util/wsRequestCache';
import {RequestService} from '../../../service/request.service';
import {Observable, of, throwError} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {SimplePaginationUtils} from '../../../private_util/simple-pagination-utils';
import {SearchResultFactory} from '../../../private_util/searchResultFactory';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {SearchResult} from '../../../domain/search/search-result';
import {SimplePagination} from '../../../../util/pagination/simple-pagination';
import {FrontendAppConfigKey} from '../../../../domain/config/frontend-app-config-key';
import {AppConfigService} from '../../../../service/config/app-config.service';


@Injectable({
  providedIn: 'root',
})
export class SmtpAccountWsClient {

  private resourceCache: WsIdResourceCache<WsSmtpAccount>;
  private requestCache: WsRequestCache<WsSmtpAccount>;

  constructor(private requestService: RequestService,
              private authProvider: AuthProvider,
              private appConfigService: AppConfigService,
  ) {
    this.resourceCache = new WsIdResourceCache<WsSmtpAccount>();
    this.requestCache = new WsRequestCache<WsSmtpAccount>();
  }

  getAccount$(id: number, forceFetch?: boolean): Observable<WsSmtpAccount> {
    if (forceFetch) {
      return this.fetchAccount$(id);
    }
    const entity = this.resourceCache.getFromCache(id);
    if (entity !== undefined) {
      return of(entity);
    }
    const request = this.requestCache.getFromCache(id);
    if (request !== undefined) {
      return request;
    }
    return this.fetchAccount$(id);
  }

  fetchAccount$(id: number): Observable<WsSmtpAccount> {
    const fetchTask = this.doGet(id)
      .pipe(tap(e => this.resourceCache.putInCache(e)));
    const cachedTask = this.requestCache.shareInCache(id, fetchTask);
    return cachedTask;
  }


  createAccount$(account: WsSmtpAccount): Observable<WsSmtpAccount> {
    const auth = this.authProvider.getAuth();
    const id = account.id;
    if (id != null) {
      return throwError('Unexpected id');
    }

    const url = `${this.getResourceUrl()}`;
    return this.requestService.sendRequest<WsSmtpAccount>({
      url: url,
      method: 'POST',
      body: account,
    }, auth);
  }

  updateAccount$(account: WsSmtpAccount): Observable<WsSmtpAccount> {
    const auth = this.authProvider.getAuth();
    const id = account.id;
    if (id == null || isNaN(id)) {
      return throwError('Invalid id');
    }
    this.clearCache(id);
    const url = `${this.getResourceUrl()}/${id}`;
    return this.requestService.sendRequest<WsSmtpAccount>({
      url: url,
      method: 'PUT',
      body: account,
    }, auth);
  }


  deleteAccount$(id: number): Observable<WsSmtpAccount> {
    const auth = this.authProvider.getAuth();
    if (id == null || isNaN(id)) {
      return throwError('Invalid id');
    }
    this.clearCache(id);
    const url = `${this.getResourceUrl()}/${id}`;
    return this.requestService.sendRequest<WsSmtpAccount>({
      url: url,
      method: 'DELETE',
    }, auth);
  }

  searchAccounts$(wsSearch: any, pagination: SimplePagination): Observable<SearchResult<WsSmtpAccount>> {
    const auth = this.authProvider.getAuth();
    const url = `${this.getResourceUrl()}/search`;
    const queryParams = SimplePaginationUtils.toQueryParams(pagination);
    return this.requestService.sendRequestFullResponse<WsSmtpAccount[]>({
      url: url,
      method: 'POST',
      body: wsSearch,
      params: queryParams,
    }, auth).pipe(
      map(response => SearchResultFactory.createSearchResult<WsSmtpAccount>(response)),
    );
  }

  clearCache(id: number) {
    this.resourceCache.removeFromCache(id);
    this.requestCache.clear(id);
  }

  private doGet(id: number): Observable<WsSmtpAccount> {
    if (id == null || isNaN(id)) {
      return throwError(new Error('Invalid input'));
    }
    const url = `${this.getResourceUrl()}/${id}`;
    return this.requestService.sendRequest<WsSmtpAccount>({
      method: 'GET', url: url,
    }, this.authProvider.getAuth());
  }


  private getResourceUrl(): string {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    return `${wsUri}/internal/mail/smtp-account`;
  }

}
