import {ApplicationMenuItemDef} from '../main/routing/application-menu-item-def';
import {TrusteeMenuData} from './trustee-menu-data';

export const TrusteeMenu: ApplicationMenuItemDef[] = [
  {data: TrusteeMenuData.list},
  {data: TrusteeMenuData.new},
];

export const TrusteeDetailsTabMenu: ApplicationMenuItemDef[] = [
  {data: TrusteeMenuData.details.children.info},
  {data: TrusteeMenuData.details.children.contact},
  {data: TrusteeMenuData.details.children.customers},
  {data: TrusteeMenuData.details.children.insuranceSubscriptions},
  {data: TrusteeMenuData.details.children.creditSubscriptions},
  {data: TrusteeMenuData.details.children.investmentSubscriptions},
];
