export enum FrontendAppSwitch {
  front_error_report = 'front.error.report',
  front_dev_banner = 'front.dev.banner',
  front_dev_debug = 'front.dev.debug',
  // Resubscribe to web push notification
  webpush_resubscribe = 'webpush.resubscribe',
  // Is it a mobile device, checked if an userAgent string is present and contains 'Mobi'
  useragent_mobile = 'useragent.mobile',
  // Is it a web application that has been installed on the homescreen/start menu, as checked by
  // window.navigator.standalone
  navigator_stanalone = 'navigator.standalone'
}
