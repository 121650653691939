<div class="application-header">
  <div class="menu-bar">
    <div *ngIf="isCustomer$ | async" class="customer-menu">
      <p-menubar [model]="customerMenuModel$ | async"
                 class="full-width-menu"
                 [autoZIndex]="false">
        <!--                 [autoDisplay]="false"-->
        <div class="info-panel">
          <div class="user-info ui-menuitem-link ui-corner-all" *ngIf="authenticated | async"
               (click)="userMenu.toggle($event)">
            <label>Utilisateur</label>
            <app-user [ref]="loggedUserRef$ | async"></app-user>
          </div>

          <div class="busy-icon">
            <div class="spinner" [class.hidden]="loadingIndicatorHidden | async">
              <i class="pi pi-spin pi-spinner"></i>
            </div>
          </div>
        </div>

      </p-menubar>
    </div>
    <div *ngIf="!(isCustomer$ | async)" class="user-menu">
      <p-menubar [model]="menuModel | async"
                 class="full-width-menu"
                 [autoZIndex]="false">
        <!--                 [autoDisplay]="false"-->
        <div class="info-panel">
          <div class="user-info ui-menuitem-link ui-corner-all" *ngIf="authenticated | async"
               (click)="userMenu.toggle($event)">
            <label>Utilisateur</label>
            <app-user [ref]="loggedUserRef$ | async"></app-user>
          </div>
          <div class="version">
            <label>Version</label>
            <span>{{ appVersion }}</span>
          </div>
          <div class="busy-icon">
            <div class="spinner" [class.hidden]="loadingIndicatorHidden | async">
              <i class="pi pi-spin pi-spinner"></i>
            </div>
          </div>
        </div>
      </p-menubar>
    </div>


    <p-menubar [model]="singleItemMenuModel | async"
               class="small-width-menu"
               [autoZIndex]="false">
      <!--               [autoDisplay]="false"-->
      <div class="menubar-breadcrumb">
        <app-breadcrumb></app-breadcrumb>
      </div>

      <div class="info-panel">
        <div class="user-info ui-menuitem-link ui-corner-all" *ngIf="authenticated | async"
             (click)="userMenu.toggle($event)">
          <label>Utilisateur</label>
          <app-user [ref]="loggedUserRef$ | async"></app-user>
        </div>
        <div class="busy-icon">
          <div class="spinner" [class.hidden]="loadingIndicatorHidden | async">
            <i class="fa fa-spinner fa-pulse fa-fw"></i>
          </div>
        </div>
      </div>
    </p-menubar>

    <p-slideMenu #userMenu
                 class="user-menu"
                 [popup]="true"
                 [model]="userMenuModel"
    ></p-slideMenu>


    <div class="dev-badge host-colored" *ngIf="(devBanner$ | async) === true"
         [ngClass]="hostClass$ | async">
      <div class="dev-label">
        dev
      </div>
      <div class="version-label">
        {{ appVersion }}
      </div>
      <div class="dev-host" [ngClass]="hostClass$ | async">
        {{ host$ | async }}
      </div>
    </div>
  </div>

  <div class="breadcrumb" *ngIf="!(isCustomer$ | async)">
    <app-breadcrumb></app-breadcrumb>
  </div>
</div>
