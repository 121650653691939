import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {InsuranceBranch} from '../../domain/insurance/insurance-branch';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {WsInsuranceSubscriptionSnapshot} from '@lifeislife/lifeislife-ws-api';
import {InsuranceSubscriptionSnapshot} from '../../domain/insurance/insurance-subscription-snapshot';
import {Recurrence} from '../../domain/corebusiness/recurrence';
import {InsuranceSubscriptionSnapshotType} from '../../domain/insurance/insurance-subscription-snapshot-type';

export class InsuranceSubscriptionSnapshotConverter {

  static convertIn(value: WsInsuranceSubscriptionSnapshot): InsuranceSubscriptionSnapshot {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      version: value.version,
      contractEndDate: DateConverterUtils.parseIsoDate(value.contractEndDate),
      contractStartDate: DateConverterUtils.parseIsoDate(value.contractStartDate),
      deathCoverReserve: value.deathCoverReserve,
      estimatedRetirementAmount: value.estimatedRetirementAmount,
      fund1: value.fund1,
      fund2: value.fund2,
      fund3: value.fund3,
      fund4: value.fund4,
      insuranceBranch: EnumConverterUtils.convertValue(value.insuranceBranch, InsuranceBranch),
      insuredInfo: value.insuredInfo,
      incomeGuaranteeAmount: value.incomeGuaranteeAmount,
      mandated: value.mandated,
      maxBackservice: value.maxBackservice,
      maxDeducibleAmount: value.maxDeducibleAmount,
      premiumTax: value.premiumTax,
      recuringPremiumTaxInclusive: value.recuringPremiumTaxInclusive,
      recurrence: EnumConverterUtils.convertValue(value.recurrence, Recurrence),
      reserveUpdateDate: DateConverterUtils.parseIsoDate(value.reserveUpdateDate),
      snapshotDateTime: DateConverterUtils.parseIsoDateTime(value.snapshotDateTime),
      snapshotDescription: value.snapshotDescription,
      snapshotType: EnumConverterUtils.convertValue(value.snapshotType, InsuranceSubscriptionSnapshotType),
      vestedReserveAmount: value.vestedReserveAmount,
      wsInsuranceSubscriptionWsRef: value.wsInsuranceSubscriptionWsRef,
      yearlyPremiumTaxExclusive: value.yearlyPremiumTaxExclusive,
      yearlyPremiumTaxInclusive: value.yearlyPremiumTaxInclusive,
    };
  }

}
