import {WsCustomerSearch, WsPaymentType} from '@lifeislife/lifeislife-ws-api';
import {CustomerSearch} from '../../domain/customer/customer-search';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {CustomerTypeSearchConverter} from './customer-type-search.converter';
import {CustomerStatusSearchConverter} from './customer-status-search.converter';
import {TrusteeSearchConverter} from '../trustee/trustee-search.converter';
import {CustomerContactSearchConverter} from '../customer-contact/customer-contact-search.converter';
import {PaymentType} from '../../domain/customer/payment-type';

export class CustomerSearchConverter {

  static toCustomerSearch(value: WsCustomerSearch) {
    if (value == null) {
      return undefined;
    }
    return {
      exactCustomerRef: value.exactCustomerRef,
      anyCustomerRefList: value.anyCustomerRefList,
      nameContains: value.nameContains,
      customerTypeSearch: CustomerTypeSearchConverter.toCustomerTypeSearch(value.wsCustomerTypeSearch),
      customerStatusSearch: CustomerStatusSearchConverter.toCustomerStatusSearch(value.wsCustomerStatusSearch),
      trusteeSearch: TrusteeSearchConverter.toTrusteeSearch(value.wsTrusteeSearch),
      preferredPaymentTypeList: value.preferredPaymentTypeList == null ? [] : value.preferredPaymentTypeList
        .map(wsType => EnumConverterUtils.convertValue(wsType, PaymentType)),
      mainCustomerContactSearch: CustomerContactSearchConverter.toCustomerContactSearch(value.mainCustomerContactSearch),
      previousBrokerContains: value.previousBrokerContains,
      vatNumberContains: value.vatNumberContains,
      notesContains: value.notesContains,
      minCreationTime: DateConverterUtils.parseIsoDateTime(value.minCreationTime),
      maxCreationTime: DateConverterUtils.parseIsoDateTime(value.maxCreationTime),
      minUpdateTime: DateConverterUtils.parseIsoDateTime(value.minUpdateTime),
      maxUpdateTime: DateConverterUtils.parseIsoDateTime(value.maxUpdateTime),
      template: value.template,
      withActiveSubscriptions: value.withActiveSubscriptions,
      citizenCustomer: value.citizenCustomer,
    };
  }

  static toWsCustomerSearch(value: CustomerSearch): WsCustomerSearch {
    if (value == null) {
      return undefined;
    }
    return {
      exactCustomerRef: value.exactCustomerRef,
      anyCustomerRefList: value.anyCustomerRefList,
      nameContains: value.nameContains,
      wsCustomerTypeSearch: CustomerTypeSearchConverter.toWsCustomerTypeSearch(value.customerTypeSearch),
      wsCustomerStatusSearch: CustomerStatusSearchConverter.toWsCustomerStatusSearch(value.customerStatusSearch),
      wsTrusteeSearch: TrusteeSearchConverter.toWsTrusteeSearch(value.trusteeSearch),
      preferredPaymentTypeList: value.preferredPaymentTypeList == null ? [] : value.preferredPaymentTypeList
        .map(wsType => EnumConverterUtils.convertValue(wsType, WsPaymentType)),
      mainCustomerContactSearch: CustomerContactSearchConverter.toWsCustomerContactSearch(value.mainCustomerContactSearch),
      previousBrokerContains: value.previousBrokerContains,
      vatNumberContains: value.vatNumberContains,
      notesContains: value.notesContains,
      minCreationTime: DateConverterUtils.formatDateToIsoDateTime(value.minCreationTime),
      maxCreationTime: DateConverterUtils.formatDateToIsoDateTime(value.maxCreationTime),
      minUpdateTime: DateConverterUtils.formatDateToIsoDateTime(value.minUpdateTime),
      maxUpdateTime: DateConverterUtils.formatDateToIsoDateTime(value.maxUpdateTime),
      template: value.template,
      withActiveSubscriptions: value.withActiveSubscriptions,
      citizenCustomer: value.citizenCustomer,
    };
  }

}
