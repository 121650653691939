import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsRef, WsSms, WsSmsSearch} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {SmsConverter} from './sms.converter';
import {Sms} from '../../domain/sms/sms';
import {SmsFilter} from '../../domain/sms/sms-filter';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {SmsWsClient} from '../../client/resources/front/sms-ws-client';
import {Trustee} from '../../domain/trustee/trustee';

@Injectable({
  providedIn: 'root',
})
export class SmsService {


  constructor(private client: SmsWsClient,
  ) {
  }

  getSms$(ref?: Ref<Sms>): Observable<Sms> {
    return !isValidRef(ref) ? of(null) : this.client.doGet(ref.id).pipe(
      map(wsSms => SmsConverter.convertIn(wsSms)),
    );
  }

  searchSmss$(smsFilter: SmsFilter, pagination: SimplePagination): Observable<SearchResult<Sms>> {
    const wsSearch = SmsConverter.convertFilterOut(smsFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateSms$(sms: Sms): Observable<ValidationResult<Sms>> {
    const wsSms = SmsConverter.convertOut(sms);
    return this.client.doValidate(wsSms).pipe(
      map((results: WsSms | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveSms$(sms: Sms): Observable<any> {
    const wsSms = SmsConverter.convertOut(sms);
    return this.client.doSave(wsSms);
  }

  deleteSms$(id: number): Observable<any> {
    return this.client.doRemove(id);
  }

  serializeFilter(smsFilter: SmsFilter): string {
    ObjectConverterUtil.cleanUnsetFilterValues(smsFilter);
    const wsSmsSearch = SmsConverter.convertFilterOut(smsFilter);
    return JSON.stringify(wsSmsSearch);
  }


  deserializeFilter(valueString: string | null): SmsFilter {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: any = JSON.parse(valueString);
      const filter = SmsConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }


  createFilter(trusteeRef?: Ref<Trustee>): SmsFilter {
    return SmsConverter.convertFilterIn({
      trusteeRef: !isValidRef(trusteeRef) ? null : trusteeRef,
    } as WsSmsSearch);
  }

  private createValidationResult(results: WsSms | WsConstraintViolation[]): ValidationResult<Sms> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsSms => SmsConverter.convertIn(wsSms),
      propertyNameMappings: SmsConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsSms>>): Observable<SearchResult<Sms>> {
    const rowTasks = results.list.map(ref => this.getSms$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<Sms>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<Sms>>>{
          list: rows,
        }),
      ));
  }
}
