import {WsSubscriptionFile, WsSubscriptionFileSearch} from '@lifeislife/lifeislife-ws-api';
import {SubscriptionFile} from '../../domain/corebusiness/subscription-file';
import {SubscriptionFileSearch} from '../../domain/corebusiness/subscription-file-search';
import {SubscriptionConverter} from './subscription-converter';
import {StoredFileConverter} from '../file/stored-file.converter';

export class SubscriptionFileConverter {

  static convertIn(value: WsSubscriptionFile): SubscriptionFile {
    return {
      id: value.id,
      storedFileRef: value.storedFileWsRef,
      subscriptionRef: value.subscriptionWsRef,
      visibleToCustomer: value.visibleToCustomer,
      storedFile: undefined,
      storedFileDownloadUri: undefined,
    };
  }

  static convertOut(value: SubscriptionFile): WsSubscriptionFile {
    return {
      id: value.id,
      storedFileWsRef: value.storedFileRef,
      subscriptionWsRef: value.subscriptionRef,
      visibleToCustomer: value.visibleToCustomer,
    };
  }


  static convertFilterIn(value?: WsSubscriptionFileSearch): SubscriptionFileSearch {
    if (value == null) {
      return undefined;
    }
    return {
      storedFileSearch: StoredFileConverter.convertFilterIn(value.wsFileSearch),
      subscriptionSearch: SubscriptionConverter.convertFilterIn(value.wsSubscriptionSearch),
      visibleToCustomer: value.visibleToCustomer,
    };
  }

  static convertFilterOut(value: SubscriptionFileSearch): WsSubscriptionFileSearch {
    if (value == null) {
      return undefined;
    }
    return {
      wsFileSearch: StoredFileConverter.convertFilterOut(value.storedFileSearch),
      wsSubscriptionSearch: SubscriptionConverter.convertFilterOut(value.subscriptionSearch),
      visibleToCustomer: value.visibleToCustomer,
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsSubscriptionFile]: keyof SubscriptionFile } {
    return {
      id: 'id',
      subscriptionWsRef: 'subscriptionRef',
      storedFileWsRef: 'storedFileRef',
      visibleToCustomer: 'visibleToCustomer',
    };
  }

}
