import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrusteeContactTypeSelectComponent} from './trustee-contact-type-select/trustee-contact-type-select.component';
import {FormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {TrusteeContactSelectComponent} from './trustee-contact-select/trustee-contact-select.component';
import {TrusteeContactMultiViewComponent} from './trustee-contact-multi-view/trustee-contact-multi-view.component';
import {MainContactComponentsModule} from '../contact/main-contact-components.module';
import {TrusteeContactColumnComponent} from './trustee-contact-table/trustee-contact-column/trustee-contact-column.component';
import {MainCountryModule} from '../country/main-country.module';
import {PersonTitleModule} from '../person-title/person-title.module';
import {MainModule} from '../../main.module';
import {TrusteeContactTypeComponent} from './trustee-contact-type/trustee-contact-type.component';
import {TrusteeMainContactViewComponent} from './trustee-main-contact-view/trustee-main-contact-view.component';
import {MainTrusteeComponentsModule} from '../trustee/main-trustee-components.module';
import {TrusteeContactTypeMultiSelectComponent} from './trustee-contact-type-multi-select/trustee-contact-type-multi-select.component';
import {TrusteeContactTypeModule} from './trustee-contact-type/trustee-contact-type.module';
import {TrusteeContactTypeBadgeModule} from './trustee-contact-type-badge/trustee-contact-type-badge.module';

const PUBLIC_DECLARATIONS = [
  TrusteeContactTypeSelectComponent,
  TrusteeContactSelectComponent,
  TrusteeContactMultiViewComponent,
  TrusteeMainContactViewComponent,
  TrusteeContactColumnComponent,
  TrusteeContactTypeMultiSelectComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    MainContactComponentsModule,

    DropdownModule,
    MainCountryModule,
    PersonTitleModule,
    MainModule,
    MainTrusteeComponentsModule,
    MultiSelectModule,
    TrusteeContactTypeModule,
    TrusteeContactTypeBadgeModule,
  ],
  declarations: [...PUBLIC_DECLARATIONS],
  exports: [...PUBLIC_DECLARATIONS],
})
export class MainTrusteeContactComponentsModule {
}
