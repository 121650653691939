import {SimpleSort} from './simple-sort';
import {SimplePagination} from './simple-pagination';
import {LoadEvent} from './load-event';

export class SimplePaginationUtils {

  static newPagination<F = any | string>(pageSize: number, sort?: SimpleSort<F>): SimplePagination<F> {
    return {
      offset: 0,
      length: pageSize,
      sorts: sort == null ? [] : [sort],
    };
  }

  static reset(pagination: SimplePagination): SimplePagination {
    if (pagination == null) {
      return null;
    }
    if (pagination.offset === 0) {
      return pagination;
    }
    return Object.assign({}, pagination, {
      offset: 0,
    });
  }

  static toLastPage(pagination: SimplePagination, totalCount: number): SimplePagination {
    const pageCount = Math.ceil(totalCount / pagination.length);
    const lastPageIndex = pageCount - 1;
    const offset = lastPageIndex * pagination.length;

    return Object.assign({}, pagination, {
      offset: offset,
    });
  }

  static getPageIndex(pagination: SimplePagination): number {
    return Math.floor(pagination.offset / pagination.length);
  }

  static applyLazyLoadEvent(pagination: SimplePagination, event: LoadEvent): SimplePagination {
    const sorts = [];
    if (event.sortField != null) {
      sorts.push({
        field: event.sortField,
        order: event.sortOrder,
      });
    } else if (event.multiSortMeta != null) {
      event.multiSortMeta
        .map(meta => <SimpleSort>{
          field: meta.field,
          order: meta.order,
        }).forEach(s => sorts.push(s));
    } else if (pagination) {
      sorts.push(...pagination.sorts);
    }
    const update = <Partial<SimplePagination>>{
      offset: event.first,
      length: event.rows,
      sorts: sorts,
    };
    return Object.assign({}, pagination || {} as SimplePagination, update);
  }

  static applyColumnSelection(pagination: SimplePagination, columnsNames: string[]): SimplePagination {
    return Object.assign({}, pagination, {
      displayColumns: columnsNames,
    });
  }

  static applyGroupColumnSelection(pagination: SimplePagination, columnsNames: string[]): SimplePagination {
    return Object.assign({}, pagination, {
      groups: columnsNames,
    });
  }

  static rotateSortOrder(curOrder: number): number {
    switch (curOrder) {
      case 0:
        return 1;
      case 1:
        return -1;
      case -1:
        return 0;
      default:
        return 0;
    }
  }


  static isSameSearchPagination(pA: SimplePagination, pB: SimplePagination) {
    if (pA == null && pB == null) {
      return true;
    }
    if ((pA == null) !== (pB == null)) {
      return false;
    }
    if (pA.offset !== pB.offset
      || pA.length !== pB.length
      || !this.isSameSorts(pA.sorts, pB.sorts)
      || !this.isSameGroups(pA.groups, pB.groups)) {
      return false;
    }
    return true;
  }

  static isSameSorts(sortsA: SimpleSort<any>[], sortsB: SimpleSort<any>[]) {
    if (sortsA == null && sortsB == null) {
      return true;
    }
    if ((sortsA == null) !== (sortsB == null)) {
      return false;
    }

    if (sortsA.length !== sortsB.length) {
      return false;
    }
    for (let i = 0; i < sortsA.length; i++) {
      const sortA = sortsA[i];
      const sortB = sortsB[i];
      if (sortA.field !== sortB.field
        || sortA.order !== sortB.order) {
        return false;
      }
    }
    return true;
  }

  static isSameGroups(groupsA: any[], groupsB: any[]) {
    if (groupsA == null && groupsB == null) {
      return true;
    }
    if ((groupsA == null) !== (groupsB == null)) {
      return false;
    }

    if (groupsA.length !== groupsB.length) {
      return false;
    }
    for (let i = 0; i < groupsA.length; i++) {
      const groupA = groupsA[i];
      const groupB = groupsB[i];
      if (groupA !== groupB) {
        return false;
      }
    }
    return true;
  }

}
