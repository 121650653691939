<div class="picture-edit">

  <app-file-drop-area [acceptFiles]="true" [active]="true"
                      [class.has-error]="hasError"
                      (click)="fileUPload.openUploadFileDialog(true)"
                      (filesDropped)="fileUPload.uploadFiles($event,fileUPoadPath,'dragndrop')"
  >
    <div class="preview" *ngIf="previewUri != null; else noPreview" >
      <img [src]="previewUri">
    </div>
    <ng-template #noPreview>
      <p>
        Aucune image
      </p>
    </ng-template>
  </app-file-drop-area>

  <div class="actions">
    <a (click)="fileUPload.openUploadFileDialog(true)">
      Ajouter une image
    </a>
  </div>

</div>
<app-file-upload #fileUPload
                 [showDialog]="false"
                 (uploadSuccess$)="onUploadSuccess($event)"
                 (uploadError)="onUploadError($event)"
                 [uploadPath]="fileUPoadPath"
                 [disabled]="disabled"
></app-file-upload>
