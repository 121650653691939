import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs';
import {Contact, Customer, CustomerContactService, CustomerService, Ref} from '@lifeislife/lifeislife-domain';
import {defaultIfEmpty, filter, map, publishReplay, refCount, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-customer-main-contact-view',
  templateUrl: './customer-main-contact-view.component.html',
  styleUrls: ['./customer-main-contact-view.component.scss'],
})
export class CustomerMainContactViewComponent implements OnInit {

  @Input()
  set customerRef(value: Ref<Customer>) {
    this.customerRefSource$.next({id: value.id});
  }

  private customerRefSource$ = new BehaviorSubject<Ref<Customer>>(null);
  contactRef$: Observable<Ref<Contact>>;

  constructor(private customerService: CustomerService,
              private customerContactService: CustomerContactService,
  ) {
  }

  ngOnInit() {
    this.contactRef$ = this.customerRefSource$.pipe(
      switchMap(ref => this.fetchContactRef(ref)),
      publishReplay(1), refCount(),
    );
  }

  private fetchContactRef(customeRef: Ref<Customer>): Observable<Ref<Contact>> {
    if (customeRef == null || customeRef.id == null) {
      return of(null);
    }
    return this.customerService.getCustomer$({id: customeRef.id}).pipe(
      filter(customer => customer != null),
      map(customer => customer.mainCustomerContactRef),
      filter(customerContactRef => customerContactRef != null),
      switchMap(customerContactRef => this.customerContactService.getCustomerContact$(customerContactRef)),
      filter(customerContact => customerContact != null),
      map(customerContact => customerContact.contactRef),
      defaultIfEmpty(null),
    );
  }
}
