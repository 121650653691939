import {WsDaoEventEntityType, WsMail, WsRef} from '@lifeislife/lifeislife-ws-api';
import {RequestService} from '../../service/request.service';
import {Injectable} from '@angular/core';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {AppConfigService} from '../../../service/config/app-config.service';
import {CachedWsResourceClient} from '../../private_util/client/cached-http-ws-Resource-client';
import {ResourceCacheService} from '../../../util/cache/resource-cache.service';

@Injectable({
  providedIn: 'root',
})
export class MailWsClient extends CachedWsResourceClient<WsMail, WsRef<WsMail>> {

  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private injectedAppConfigService: AppConfigService) {
    super('/front/mail', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.MAIL, injectectedCacheService);
  }


}
