import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {WebPushRegistrationService} from './web-push-registration.service';
import {Contact, Ref} from '@lifeislife/lifeislife-domain';


@Injectable()
export class WebPushRegistrationServiceNoop implements WebPushRegistrationService {

  constructor() {
  }

  subscribeToNotifications$(contactRef: Ref<Contact>) {
    return of(null);
  }

  canSubscribe$(): Observable<boolean> {
    return of(false);
  }

  hasSubscribe$(): Observable<boolean> {
    return of(false);
  }


  canSubscribe(): boolean {
    return false;
  }

  discard(): void {
  }


}
