import {AUTH_TYPE_BASIC, LifeisLifeWsAuth, SupportedAuthType} from './lifeis-life-ws-auth';
import {Base64Encoder} from '../../../util/base64/base64-encoder';

export class WsBasicAuth implements LifeisLifeWsAuth {
  userName: string;
  password: string;

  authorizationHeader: string;
  authorizationQueryString: string;
  type: SupportedAuthType;

  constructor(login: string, password: string, encoder: Base64Encoder) {
    this.userName = login;
    this.password = password;
    this.authorizationHeader = this.provideAuthorizationHeader(encoder);
    this.authorizationQueryString = this.provideAuthorizationQueryString(encoder);
    this.type = AUTH_TYPE_BASIC;
  }

  private provideAuthorizationHeader(encoder: Base64Encoder): string {
    if (this.userName == null) {
      throw new Error('No credentials provided');
    }
    let header = 'Basic ';
    header += this.getEncodedCredentials(encoder);
    return header;
  }

  private provideAuthorizationQueryString(encoder: Base64Encoder): string {
    const base64credentials = this.getEncodedCredentials(encoder);
    const encodedValue = encodeURIComponent(base64credentials);
    const encodedKey = encodeURIComponent('basic_auth');
    const queryString = `${encodedKey}=${encodedValue}`;
    return queryString;
  }

  private getEncodedCredentials(encoder: Base64Encoder) {
    const credentials = this.userName + ':' + this.password;
    const b64Credentials = encoder.encodeToString(credentials);
    return b64Credentials;
  }
}
