import {Injectable} from '@angular/core';
import {UnrestictedRetireClient} from '../../client/resources/unrestricted/unresticted-retire-client';
import {MypensionContextConverter} from './mypension-context-converter';
import {Observable, of, timer} from 'rxjs';
import {MypensionInitContext} from '../../domain/retire/mypension-init-context';
import {map, switchMap} from 'rxjs/operators';
import {MypensionContext} from '../../domain/retire/mypension-context';
import {MypensionContactContext} from '../../domain/retire/mypension-contact-context';

@Injectable({
  providedIn: 'root',
})
export class UnrestrictedRetireService {

  constructor(
    private retireClient: UnrestictedRetireClient,
  ) {
  }

  getInitContext$(trusteeKeyOrName: string, phoneCountry: string, phoneNumber: string): Observable<MypensionInitContext> {
    return this.retireClient.getInitContext$(trusteeKeyOrName, phoneCountry, phoneNumber).pipe(
      map(wsContext => MypensionContextConverter.convertInitContextIn(wsContext)),
    );
  }

  getItsMeStatus$(initContext: MypensionInitContext): Observable<boolean> {
    const wsInitContext = MypensionContextConverter.convertInitContextOut(initContext);
    return this.retireClient.getItsMeStatus$(wsInitContext);
  }

  pollItsMeStatusUntilDone$(initContext: MypensionInitContext): Observable<boolean> {
    return this.getItsMeStatus$(initContext).pipe(
      switchMap(s => this.sleepAndRecheckStatusIfNotDone$(s, initContext)),
    );
  }

  authenticate$(initContext: MypensionInitContext): Observable<MypensionContext> {
    const wsInitContext = MypensionContextConverter.convertInitContextOut(initContext);
    return this.retireClient.authenticate$(wsInitContext).pipe(
      map(wsContext => MypensionContextConverter.convertContextIn(wsContext)),
    );
  }

  /**
   * returns a frontend url containing an authcode to exchange
   */
  sendMostRecentStatusPdfByMail$(contactContext: MypensionContactContext): Observable<string> {
    const wsContext = MypensionContextConverter.convertContactContextOut(contactContext);
    return this.retireClient.sendMostRecentStatusPdfByMail$(wsContext);
  }


  /**
   * returns a frontend url containing an authcode to exchange
   */
  downloadPensionStatus$(contactContext: MypensionContactContext): Observable<string> {
    const wsContext = MypensionContextConverter.convertContactContextOut(contactContext);
    return this.retireClient.downloadPensionStatus$(wsContext);
  }


  private sleepAndRecheckStatusIfNotDone$(done: boolean, initContext: MypensionInitContext) {
    if (done) {
      return of(true);
    }
    return timer(1000).pipe(
      switchMap(() => this.pollItsMeStatusUntilDone$(initContext)),
    );
  }
}
