export abstract class WindowRef {
  abstract getWindow(): LifeislifeWindow;
}

export interface LifeislifeWindow {
  onGoogleMapsCallback?: Function;
  google?: {
    maps?: any
  };
  localStorage?: any;
  navigator?: any;
  document?: Document;
  location?: Location;

  close(): void;

  matchMedia(query: string): MediaQueryList;
}
