import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {Mail} from '../../domain/mail/mail';
import {map, switchMap} from 'rxjs/operators';
import {MailSearch} from '../../domain/mail/mail-search';
import {WsMail, WsRef} from '@lifeislife/lifeislife-ws-api';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {MailConverter} from './mail.converter';
import {MailWsClient} from '../../client/resources/front/mail-ws-client';

@Injectable({
  providedIn: 'root',
})
export class MailService {


  constructor(private client: MailWsClient,
  ) {
  }

  getMail$(id: number): Observable<Mail> {
    return this.client.doGet(id).pipe(
      map(wsMail => MailConverter.convertIn(wsMail)),
    );
  }

  fetchMail$(id: number): Observable<Mail> {
    return this.client.doFetch(id).pipe(
      map(wsMail => MailConverter.convertIn(wsMail)),
    );
  }

  searchMails$(mailFilter: MailSearch, pagination: SimplePagination): Observable<SearchResult<Mail>> {
    const wsSearch = MailConverter.convertFilterOut(mailFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsMail>>): Observable<SearchResult<Mail>> {
    const rowTasks = results.list.map(ref => this.getMail$(ref.id));
    return rowTasks.length === 0 ? of(new SearchResult<Mail>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<Mail>>>{
          list: rows,
        }),
      ));
  }

}
