import {ErrorHandler, Injectable} from '@angular/core';
import {concat, EMPTY, Observable} from 'rxjs';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {ResourceCache} from './resource-cache';
import {WithId} from '../../client/domain/with-id';
import {CacheResourceType} from './cache-resource-type';


@Injectable({
  providedIn: 'root',
})
export class ResourceCacheService {

  private cacheRequests = true;
  private cacheResponses = true;

  constructor(
    private resourceCache: ResourceCache,
    private errorHandler: ErrorHandler,
  ) {
  }

  /**
   * Get the resource from the cache. If it is not cached, fetch it then cache it.
   * @param type
   * @param id
   * @param fetch$
   */
  getFromCacheOrNetwork$<T extends WithId>(type: CacheResourceType, id: number, fetch$: Observable<T>): Observable<T> {
    if (this.resourceCache.isSkipped(type)) {
      return fetch$;
    }
    this.checkValidId(id);
    return this.resourceCache.hasCachedValue$(type, id).pipe(
      switchMap(hasValue => hasValue ? this.getCachedValue$(type, id, fetch$) : this.fetchAndCache$<T>(type, id, fetch$)),
    );
  }

  /**
   * If the resource is cached, emit the cached value. Fetch it also from the network and emit the network value.
   * The network value will be cached.
   * WARNING: the returned observable will emit 2 values.
   * @param type
   * @param id
   * @param fetch$
   */
  getFromCacheThenNetwork$<T extends WithId>(type: CacheResourceType, id: number, fetch$: Observable<T>): Observable<T> {
    if (this.resourceCache.isSkipped(type)) {
      return fetch$;
    }
    this.checkValidId(id);
    const cachedValue$ = this.resourceCache.hasCachedValue$(type, id).pipe(
      switchMap(hasValue => hasValue ? this.getCachedValue$<T>(type, id, fetch$) : EMPTY),
    );
    const networkValue$ = this.fetchAndCache$<T>(type, id, fetch$);
    return concat(cachedValue$, networkValue$);
  }

  /**
   * Get from the network, and cache the value.
   * @param type
   * @param id
   * @param fetch$
   */
  getFromNetwork$<T extends WithId>(type: CacheResourceType, id: number, fetch$: Observable<T>): Observable<T> {
    if (this.resourceCache.isSkipped(type)) {
      return fetch$;
    }
    this.checkValidId(id);
    return this.fetchAndCache$<T>(type, id, fetch$);
  }

  /**
   * If the resource is cached, fetch from network and save a fresh value in the cache.
   * @param type
   * @param id
   * @param fetch$
   */
  refreshFromNetworkIfCached<T extends WithId>(type: CacheResourceType, id: number, fetch$: Observable<T>): void {
    if (this.resourceCache.isSkipped(type)) {
      return;
    }
    this.checkValidId(id);
    this.resourceCache.hasCachedValue$(type, id).pipe(
      filter(cached => cached),
      switchMap(() => this.fetchAndCache$(type, id, fetch$)),
    ).subscribe(() => {
        // Cache updated
      },
      e => {
        this.errorHandler.handleError(`Failed to refetch ${type} ${id} on cache update event: ${e}`);
      });
  }

  clear(type: CacheResourceType, id: number) {
    if (this.resourceCache.isSkipped(type)) {
      return;
    }
    this.checkValidId(id);
    this.resourceCache.clear(type, id);
  }

  clearWholeCache(type: CacheResourceType): void {
    this.resourceCache.clearAll(type);
  }

  cacheValue<T extends WithId>(type: CacheResourceType, id: number, value: T) {
    if (this.resourceCache.isSkipped(type)) {
      return;
    }
    if (!this.cacheResponses) {
      return;
    }
    this.resourceCache.cacheValue(type, id, value);
  }

  getCachedValue$<T extends WithId>(type: CacheResourceType, id: number, fetch$: Observable<T>) {
    if (this.resourceCache.isSkipped(type)) {
      return fetch$;
    }
    return this.resourceCache.getCachedValue$<T>(type, id).pipe(
      catchError(e => this.getFromNetwork$(type, id, fetch$)),
    );
  }

  private fetchAndCache$<T extends WithId>(type: CacheResourceType, id: number, fetch$: Observable<T>) {
    if (this.resourceCache.isSkipped(type)) {
      return fetch$;
    }
    const request$ = this.cacheRequests ? this.resourceCache.cacheRequest$(type, id, fetch$) : fetch$;
    return request$.pipe(
      tap(value => this.cacheValue(type, id, value)),
    );
  }

  private checkValidId(id: number) {
    if (isNaN(id)) {
      throw new Error(`Invalid id`);
    }
  }

}
