import {Injectable} from '@angular/core';
import {RequestService} from '../../../service/request.service';
import {WsDaoEventEntityType, WsRef, WsSubscription, WsSubscriptionGroupSummary} from '@lifeislife/lifeislife-ws-api';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {Ref} from '../../../../domain/shared/ref';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {WsCustomer} from '@lifeislife/lifeislife-ws-api';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionWsClient extends CachedWsResourceClient<WsSubscription, WsRef<WsSubscription>, WsSubscriptionGroupSummary> {


  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private injectedAppConfigService: AppConfigService) {
    super('/front/subscription', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.SUBSCRIPTION, injectectedCacheService);
  }

  getNewSubscriptionFilePath(ref: WsRef<WsSubscription>): string {
    return `/front/subscription/${ref.id}/file`;
  }

  updateSubscriptionCustomer$(ref: WsRef<WsSubscription>, customerRef: Ref<WsCustomer>) {
    const uri = `${this.getResourceUrl()}/${ref.id}/customer`;
    return this.requestService.sendRequest<WsRef<WsSubscription>>({
      method: 'PUT', url: uri, body: customerRef,
    }, this.authService.getAuth());
  }
}
