<div class="marital-status-select">
  <p-dropdown name="status"
              [(ngModel)]="value"
              (ngModelChange)="fireChanges()"
              [options]="allItems$ | async"
              [class.p-state-error]="hasError"
              appendTo="body"
              optionLabel="label"
              optionValue="value"
  >
    <ng-template let-item pTemplate="item">
      <span i18n *ngIf="item.maritalStatus == null; else withValue">{{item.label}}</span>
      <ng-template #withValue>
        <span style="display: inline-block; margin-right: 1em;">{{item.maritalStatus.code}}</span>
        <span style="display: inline-block">{{item.maritalStatus.label}}</span>
      </ng-template>
    </ng-template>
    <ng-template let-item pTemplate="selectedItem">
      {{ item.label }}
    </ng-template>

  </p-dropdown>
</div>
