import {MypensionUser} from '../../domain/retire/mypension-user';
import {WsMyPensionUser} from '@lifeislife/lifeislife-ws-api';

export class MypensionUserConverter {


  static convertUserIn(value: WsMyPensionUser): MypensionUser {
    return {
      firstName: value.firstName,
      lastName: value.lastName,
      ssin: value.ssin,
    };
  }


  static convertUserOut(value: MypensionUser): WsMyPensionUser {
    return {
      firstName: value.firstName,
      lastName: value.lastName,
      ssin: value.ssin,
    };
  }


}
