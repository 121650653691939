import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {Observable, of, ReplaySubject, Subscription, throwError} from 'rxjs';
import {last, map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {FileSubresourcePath, FilesUploadProgress, FileUploadMethod, Ref, WithId} from '@lifeislife/lifeislife-domain';
import {FileUploadProgressService} from './file-upload-progress.service';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.html',
  styleUrls: ['./file-upload.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploadComponent implements OnInit, OnDestroy {

  fileSelection = ''; // ngModel binds the 'value' property, which accepts a filename in the setter

  @Input() showDialog = false;
  @Input() multiple = true;
  @Input() disabled = false;
  @Input() uploadPath: FileSubresourcePath<WithId>;

  //
  @Output() uploadError = new EventEmitter<any>();
  @Output() uploadAborted = new EventEmitter<any>();
  @Output() uploadSuccess$ = new EventEmitter<Ref<any>[]>();

  uploadProgress$ = new ReplaySubject<FilesUploadProgress | null>(1);
  uploadRunning$: Observable<boolean>;

  @ViewChild('fileInput', {static: false}) fileInputElement: ElementRef;
  @ViewChild('imageInput', {static: false}) imageInputElement: ElementRef;

  private aborted: boolean;
  private subscription: Subscription;

  constructor(
    private fileUploadService: FileUploadProgressService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.uploadRunning$ = this.uploadProgress$.pipe(
      map(progress => progress != null),
      publishReplay(1), refCount(),
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  openUploadFileDialog(acceptImages ?: boolean) {
    this.clearFileSelection();
    if (acceptImages === true) {
      this.imageInputElement.nativeElement.click();
    } else {
      this.fileInputElement.nativeElement.click();
    }
  }

  uploadFiles(files: File[] | FileList | File, uploadPath: FileSubresourcePath<WithId>, method: FileUploadMethod) {
    if (files instanceof File) {
      files = [files];
    }
    if (this.disabled) {
      return;
    }
    const uploadProgress$ = this.fileUploadService.uploadFiles$(method, uploadPath, files);
    this.subscription = new Subscription();

    const progressSubscription = uploadProgress$.subscribe(n => {
        this.uploadProgress$.next(n);
        this.changeDetectorRef.detectChanges();
      }, e => this.uploadError.next(e),
    );
    this.subscription.add(progressSubscription);

    const lastSubscription = uploadProgress$.pipe(
      last(),
      switchMap(lastSummary => this.checkLastUploadSummaryErrored$(lastSummary)),
    ).subscribe({
      next: p => this.onUploadCompleted(p.createdFiles),
      error: e => this.onUploadError(e),
    });
    this.subscription.add(lastSubscription);
  }

  cancelUpload() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.fileUploadService.cancelUpload();
    this.clearFileSelection();
    this.uploadProgress$.next(null);
  }

  onCancelClicked() {
    this.aborted = true;
    this.uploadAborted.next(true);
    this.cancelUpload();
  }

  onFilesSelected(event?: any) {
    if (this.disabled) {
      return;
    }
    if (event.target.file) {
      this.uploadFiles([event.target.file], this.uploadPath, 'input-type-file');
    } else if (event.target.files) {
      this.uploadFiles(event.target.files, this.uploadPath, 'input-type-file');
    }
  }

  private onUploadCompleted(createdFiles: Ref<any>[]) {
    // this.fileInputElement.nativeElement.value = '';
    // this.imageInputElement.nativeElement.value = '';
    this.clearFileSelection();
    if (!this.aborted) {
      this.uploadSuccess$.next(createdFiles);
    }
    this.uploadProgress$.next(null);
    this.aborted = false;
  }

  private onUploadError(error: any) {
    // this.fileInputElement.nativeElement.value = '';
    // this.imageInputElement.nativeElement.value = '';
    this.uploadError.next(error);
    this.cancelUpload();
  }

  private checkLastUploadSummaryErrored$(lastProgress: FilesUploadProgress | null): Observable<FilesUploadProgress> {
    if (lastProgress == null) {
      return throwError(new Error(`Aucun fichier n'a été envoyé`));
    } else if (lastProgress.errored) {
      const erroredFileList = lastProgress.erroredFiles.map((f: File) => `${f.name}\n`);
      return throwError(new Error(`L'envoi de certains fichiers a échoué:\n${erroredFileList}`));
    } else {
      return of(lastProgress);
    }
  }

  private clearFileSelection() {
    this.fileSelection = '';
    if (this.imageInputElement && this.imageInputElement.nativeElement) {
      this.imageInputElement.nativeElement.value = '';
    }
    if (this.fileInputElement && this.fileInputElement.nativeElement) {
      this.fileInputElement.nativeElement.value = '';
    }
  }

}
