<span class="customer-contact-type-badge">
  <div [class]="(contactTypeModel$ | async)?.contactType"
       [class.customer-access]="(contactTypeModel$ | async)?.customerDataAccess"
       [class.internal]="(contactTypeModel$ | async)?.customerInternal"
       class="lifeislife-badge contact-type-colored customer-contact-type"
       [title]="showCustomerLabelTitle ? (customerLabel$ | async) : ''"
  >
    <app-customer-contact-type [contactType]="(contactTypeModel$ | async)?.contactType"
    ></app-customer-contact-type>

  </div>
</span>
