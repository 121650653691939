export enum EventRecordSortField {

  DATE = 'DATE',
  TYPE = 'TYPE',
  CUSTOMER = 'CUSTOMER',
  UPDATER = 'UPDATER',
  MESSAGE = 'MESSAGE',
  OLD_VALUE = 'OLD_VALUE',
  NEW_VALUE = 'NEW_VALUE',
}
