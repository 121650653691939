import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UnrestictedHealthClient} from '../../client/resources/unrestricted/unresticted-health-client';
import {HealthcheckResponse} from '../../domain/health/healthcheck-response';

@Injectable({
  providedIn: 'root',
})
export class HealthcheckService {

  constructor(private client: UnrestictedHealthClient,
  ) {
  }

  checkHealth$(thirdPartyService = true): Observable<HealthcheckResponse> {
    return this.client.checkHealth$(thirdPartyService).pipe(
      map(r => r as HealthcheckResponse),
    );
  }

}
