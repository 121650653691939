import {StoredFile} from '../../domain/file/stored-file';
import {Observable, Observer, Subscription} from 'rxjs';

export type ContentDisposition = 'attachment' | 'inline';

export const EXCEL_MIME_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const ZIP_MIME_TYPE = 'application/zip';

export class FileUtils {

  static isPdfDocument(file: StoredFile): boolean {
    if (file == null) {
      return false;
    }
    const mimeType = file.mimeType;
    const fileName = file.fileName;
    const dotSplitted = fileName.split('.');
    let extension = '';
    if (dotSplitted.length > 1) {
      extension = dotSplitted[dotSplitted.length - 1];
    }
    return mimeType === 'application/pdf' || extension === 'pdf';
  }


  static readFileDataToURI$(file: File): Observable<string> {
    const reader = new FileReader();
    const subscription = new Subscription(() => {
      try {
        if (reader != null && reader.readyState === reader.LOADING) {
          reader.abort();
        }
      } catch (e) {
        console.warn('Failed to unsubscribe from filereader');
        console.warn(e);
      }
    });

    const result$ = Observable.create((observer: Observer<string>) => {
      reader.onload = () => {
        if (reader.result == null) {
          observer.error(`No file content`);
        } else if (typeof reader.result === 'string') {
          const stringValue = reader.result as string;
          observer.next(stringValue);
          observer.complete();
        } else {
          observer.error(`Unhandled file content type: ${typeof reader.result}`);
        }
      };
      reader.readAsDataURL(file);
      return subscription;
    });
    return result$;
  }

}
