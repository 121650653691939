import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, forkJoin, Observable, of, timer} from 'rxjs';
import {
  CustomerService,
  FileSubresourcePath,
  Ref,
  SearchResult,
  SimplePagination,
  SimplePaginationUtils,
  SortOrder,
  StoredFile,
  StoredFileSearch,
  Subscription,
  SubscriptionFile,
  SubscriptionFileSearch,
  SubscriptionFileService,
  SubscriptionFileSortField,
  SubscriptionService,
  Trustee,
} from '@lifeislife/lifeislife-domain';
import {map, publishReplay, refCount, switchMap, tap} from 'rxjs/operators';
import {SubscriptionFileWithFile} from './subscription-file-with-file';

@Injectable()
export class SubscriptionFilesHelperService {

  private reloadSource$ = new BehaviorSubject<any>(null);
  private filter$ = new BehaviorSubject<SubscriptionFileSearch>(null);

  pagination$ = new BehaviorSubject<SimplePagination>(SimplePaginationUtils.newPagination(10, {
    field: SubscriptionFileSortField.FILE_UPDATE_TIME,
    order: SortOrder.DESCENDING,
  }));
  loading$ = new BehaviorSubject<boolean>(false);
  newFileUploadPath$ = new BehaviorSubject<FileSubresourcePath<Subscription>>(null);

  results$: Observable<SearchResult<SubscriptionFile>>;
  subscriptionFiles$: Observable<SubscriptionFile[]>;
  subscriptionFilesWithFiles$: Observable<SubscriptionFileWithFile[]>;
  fileRefs$: Observable<Ref<StoredFile>[]>;
  totalCount$: Observable<number>;

  constructor(
    private subscriptionFilesService: SubscriptionFileService,
    private subscriptionService: SubscriptionService,
    private customerService: CustomerService,
  ) {
    this.results$ = combineLatest([this.filter$, this.pagination$, this.reloadSource$]).pipe(
      switchMap(r => this.search$(r[0], r[1])),
      publishReplay(1), refCount(),
    );
    this.fileRefs$ = this.results$.pipe(
      map(r => r == null ? [] : r.list.map(s => s.storedFileRef)),
      publishReplay(1), refCount(),
    );
    this.totalCount$ = this.results$.pipe(
      map(r => r == null ? 0 : r.count),
      publishReplay(1), refCount(),
    );
    this.subscriptionFiles$ = this.results$.pipe(
      map(r => r == null ? [] : r.list),
      publishReplay(1), refCount(),
    );
    this.subscriptionFilesWithFiles$ = this.subscriptionFiles$.pipe(
      switchMap(r => this.fetchSubscriptionFileFiles$(r)),
      publishReplay(1), refCount(),
    );
  }

  clear() {
    this.filter$.next(null);
  }

  initForSubscriptionRef(subscriptionRef: Ref<Subscription>, visibleToCustomers: boolean | null) {
    if (subscriptionRef == null) {
      this.newFileUploadPath$.next(null);
      return;
    }
    const trusteeRef$ = this.subscriptionService.getSubscription$(subscriptionRef).pipe(
      switchMap(subscription => this.customerService.getCustomer$(subscription.customerRef)),
      map(custoer => custoer.trusteeRef),
    );
    trusteeRef$.pipe(
      map(trusteeRef => this.createFilter(subscriptionRef, trusteeRef, visibleToCustomers)),
    ).subscribe(f => this.filter$.next(f));
    this.newFileUploadPath$.next(this.subscriptionService.getNewSubscriptionFilePath(subscriptionRef));
  }

  updateWithFileNameQuery(query: string) {
    const curFilter = this.filter$.getValue();
    if (curFilter == null) {
      return;
    }
    const curFileFilter = curFilter.storedFileSearch || {} as StoredFileSearch;
    const nameContainsValue = (query == null || query.length === 0) ? null : query;
    const newFileSearch: StoredFileSearch = Object.assign({}, curFileFilter, <Partial<StoredFileSearch>>{
      nameContains: nameContainsValue,
    });
    const newFilter = Object.assign({}, curFilter, <Partial<SubscriptionFileSearch>>{
      storedFileSearch: newFileSearch,
    });
    this.filter$.next(newFilter);
  }

  reload() {
    this.reloadSource$.next(true);
  }

  private search$(searcHFilter: SubscriptionFileSearch, pagination: SimplePagination): Observable<SearchResult<SubscriptionFile>> {
    if (searcHFilter == null || pagination == null) {
      return of(null);
    }
    return timer(100).pipe(
      tap(() => this.loading$.next(true)),
      switchMap(() => this.subscriptionFilesService.searchSubscriptionFiles$(searcHFilter, pagination)),
      tap(() => this.loading$.next(false)),
    );
  }

  private createFilter(subscriptionRef: Ref<Subscription>, trusteeRef: Ref<Trustee>, visibleToCustomers: boolean) {
    const searchFilter: SubscriptionFileSearch = {
      subscriptionSearch: {
        subscriptionRef: subscriptionRef,
        customerSearch: {
          trusteeSearch: {
            exactTrusteeRef: trusteeRef,
          },
        },
      },
      storedFileSearch: {
        trusteeRef: trusteeRef,
        deleted: false,
        storedFileRef: null,
        name: null,
        customerSearch: {
          trusteeSearch: {
            exactTrusteeRef: trusteeRef,
          },
        },
        nameContains: null,
      },
      visibleToCustomer: visibleToCustomers,
    };
    return searchFilter;
  }

  private fetchSubscriptionFileFiles$(files: SubscriptionFile[]): Observable<SubscriptionFileWithFile[]> {
    if (files == null || files.length === 0) {
      return of([]);
    }
    const storedFiles$List = files.map(f => this.subscriptionFilesService.getStoredFile$(f).pipe(
      map(storefFile => Object.assign({}, f, {
        storedFile: storefFile,
      })),
    ));
    return storedFiles$List.length === 0 ? of([]) : forkJoin(storedFiles$List);
  }
}
