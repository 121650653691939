import {WsCountry, WsCountrySearch} from '@lifeislife/lifeislife-ws-api';
import {RequestService} from '../../service/request.service';
import {Injectable} from '@angular/core';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {Observable, of, throwError} from 'rxjs';
import {map, publishReplay, refCount, tap} from 'rxjs/operators';
import {WsIdResourceCache} from '../../private_util/wsIdResourceCache';
import {WsRequestCache} from '../../private_util/wsRequestCache';
import {SearchResult} from '../../domain/search/search-result';
import {SimplePaginationUtils} from '../../private_util/simple-pagination-utils';
import {SimplePagination} from '../../../util/pagination/simple-pagination';
import {SearchResultFactory} from '../../private_util/searchResultFactory';
import {AppConfigService} from '../../../service/config/app-config.service';
import {FrontendAppConfigKey} from '../../../domain/config/frontend-app-config-key';

@Injectable({
  providedIn: 'root',
})
export class CountryWsClient {

  private resourceCache: WsIdResourceCache<WsCountry>;
  private requestCache: WsRequestCache<WsCountry>;
  private allCountries: SearchResult<WsCountry>;
  private allCountriesTask$: Observable<SearchResult<WsCountry>>;

  constructor(private requestService: RequestService,
              private authProvider: AuthProvider,
              private appConfigService: AppConfigService) {
    this.resourceCache = new WsIdResourceCache<WsCountry>();
    this.requestCache = new WsRequestCache<WsCountry>();
    this.allCountries = {
      count: 1,
      list: [{
        code: 'be',
        defaultVatRate: 0.21,
        nameFr: 'Belgique',
        id: null,
      }],
    };
  }

  getCountry$(id: number): Observable<WsCountry> {
    const entity = this.resourceCache.getFromCache(id);
    if (entity !== undefined) {
      return of(entity);
    }
    const request = this.requestCache.getFromCache(id);
    if (request !== undefined) {
      return request;
    }
    return this.fetchCountry$(id);
  }

  fetchCountry$(id: number): Observable<WsCountry> {
    const fetchTask = this.doGet(id)
      .pipe(tap(e => this.resourceCache.putInCache(e)));
    const cachedTask = this.requestCache.shareInCache(id, fetchTask);
    return cachedTask;
  }

  getAllCountries$(): Observable<SearchResult<WsCountry>> {
    if (this.allCountries != null) {
      return of(this.allCountries);
    }
    if (this.allCountriesTask$ != null) {
      return this.allCountriesTask$;
    }
    this.allCountriesTask$ = this.searchCountrys$({} as WsCountrySearch, null).pipe(
      tap(list => this.allCountries = list),
      publishReplay(1), refCount(),
    );
    return this.allCountriesTask$;
  }

  searchCountrys$(wsSearch: WsCountrySearch, pagination: SimplePagination): Observable<SearchResult<WsCountry>> {
    const auth = this.authProvider.getAuth();
    const url = `${this.getResourceUrl()}/search`;
    const queryParams = SimplePaginationUtils.toQueryParams(pagination);
    return this.requestService.sendRequestFullResponse<WsCountry[]>({
      url: url,
      method: 'POST',
      body: wsSearch,
      params: queryParams,
    }, auth).pipe(
      map(response => SearchResultFactory.createSearchResult<WsCountry>(response)),
    );
  }

  clearCache(id: number) {
    this.resourceCache.removeFromCache(id);
    this.requestCache.clear(id);
  }

  private doGet(id: number): Observable<WsCountry> {
    if (id == null || isNaN(id)) {
      return throwError(new Error('Invalid input'));
    }
    const url = `${this.getResourceUrl()}/${id}`;
    return this.requestService.sendRequest<WsCountry>({
      method: 'GET', url: url,
    }, this.authProvider.getAuth());
  }


  private getResourceUrl(): string {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    return `${wsUri}/front/country`;
  }

}
