import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {WsInsuranceSubscriptionSnapshotSearch, WsInsuranceSubscriptionSnapshotType} from '@lifeislife/lifeislife-ws-api';
import {InsuranceSubscriptionSnapshotSearch} from '../../domain/insurance/insurance-subscription-snapshot-search';
import {InsuranceSubscriptionSearchConverter} from './insurance-subscription-search-converter';
import {InsuranceSubscriptionSnapshotType} from '../../domain/insurance/insurance-subscription-snapshot-type';
import {DateTimeSearchConverter} from '../shared/date-time-search-converter';

export class InsuranceSubscriptionSnapshotSearchConverter {

  static convertFilterIn(value?: WsInsuranceSubscriptionSnapshotSearch): InsuranceSubscriptionSnapshotSearch {
    if (value == null) {
      return undefined;
    }
    return {
      insuranceSubscriptionSearch: InsuranceSubscriptionSearchConverter.convertFilterIn(value.wsInsuranceSubscriptionSearch),
      snapshotDateTimeSearch: DateTimeSearchConverter.convertIn(value.snapshotDateTimeSearch),
      snapshotType: EnumConverterUtils.convertValue(value.snapshotType, WsInsuranceSubscriptionSnapshotType),
      descriptionContains: value.descriptionContains,
    };
  }

  static convertFilterOut(value: InsuranceSubscriptionSnapshotSearch): WsInsuranceSubscriptionSnapshotSearch {
    if (value == null) {
      return undefined;
    }
    return {
      wsInsuranceSubscriptionSearch: InsuranceSubscriptionSearchConverter.convertFilterOut(value.insuranceSubscriptionSearch),
      snapshotDateTimeSearch: DateTimeSearchConverter.convertOut(value.snapshotDateTimeSearch),
      snapshotType: EnumConverterUtils.convertValue(value.snapshotType, InsuranceSubscriptionSnapshotType),
      descriptionContains: value.descriptionContains,
    };
  }


}
