import {WsLifeisLifeLogEntrySearch} from '@lifeislife/lifeislife-ws-api';
import {LifeislifeLogEntrySearch} from '../../domain/log/lifeislife-log-entry-search';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {ContactSearchConverter} from '../contact/contact-search.converter';
import {LifeislifeLogSearchConverter} from './lifeislife-log-search.converter';

export class LifeislifeLogEntrySearchConverter {

  static convertIn(value: WsLifeisLifeLogEntrySearch): LifeislifeLogEntrySearch {
    if (value == null) {
      return undefined;
    }
    return {
      contentContains: value.contentContains,
      exactEntryRef: value.exactEntryRef,
      lifeisLifeLogSearch: LifeislifeLogSearchConverter.convertIn(value.lifeisLifeLogSearch),
      maxDateTime: DateConverterUtils.parseIsoDateTime(value.maxDateTime),
      minDateTime: DateConverterUtils.parseIsoDateTime(value.minDateTime),
      udpdaterContactSearch: ContactSearchConverter.toContactSearch(value.udpdaterContactSearch),
    };
  }

  static convertOut(value: LifeislifeLogEntrySearch): WsLifeisLifeLogEntrySearch {
    if (value == null) {
      return undefined;
    }
    return {
      contentContains: value.contentContains,
      exactEntryRef: value.exactEntryRef,
      lifeisLifeLogSearch: LifeislifeLogSearchConverter.convertOut(value.lifeisLifeLogSearch),
      maxDateTime: DateConverterUtils.formatDateToIsoDateTime(value.maxDateTime),
      minDateTime: DateConverterUtils.formatDateToIsoDateTime(value.minDateTime),
      udpdaterContactSearch: ContactSearchConverter.toWsContactSearch(value.udpdaterContactSearch),
    };
  }

}
