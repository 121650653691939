import {Injectable} from '@angular/core';
import {AuthState} from './auth-state';
import {AuthInitListener} from './auth-init-listener';
import {User} from '../../domain/user/user';
import {
  catchError,
  defaultIfEmpty,
  delay,
  exhaustMap,
  filter,
  first,
  map,
  mapTo,
  mergeMap,
  publishReplay,
  refCount,
  switchMap,
  take,
  tap,
  throwIfEmpty,
} from 'rxjs/operators';
import {Ref} from '../../domain/shared/ref';
import {WsUserToken} from '@lifeislife/lifeislife-ws-api';
import {HttpErrorResponse} from '@angular/common/http';
import {UserConverter} from '../../service/user/user.converter';
import {AuthFactory} from './auth-factory';
import {BehaviorSubject, EMPTY, forkJoin, Observable, of, Subscription, throwError, timer} from 'rxjs';
import {AuthProvider} from '../../client/domain/auth/auth-provider';
import {UserAuthWsClient} from '../../client/resources/front/user-auth-ws-client';
import {UserInviteAuthWsClient} from '../../client/resources/front/user-invite-auth-ws-client';
import {RequestService} from '../../client/service/request.service';
import {isOidcAuth, isUserTokenAuth, LifeisLifeWsAuth} from '../../client/domain/auth/lifeis-life-ws-auth';
import {WsUserTokenAuth} from '../../client/domain/auth/ws-user-token-auth';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {WsInviteTokenAuth} from '../../client/domain/auth/ws-invite-token-auth';
import {Contact} from '../../domain/contact/contact';
import {TrusteeContact} from '../../domain/trustee/trustee-contact';
import {CustomerContact} from '../../domain/customer-contact/customer-contact';
import {Role} from '../../domain/role/role';
import {RoleConverter} from '../../service/permission/role-converter';
import {ErrorUtils} from '../error/error-utils';
import {KeyValueStore} from '../peristence/key-value-store';
import {Base64Encoder} from '../base64/base64-encoder';
import {DateUtils} from '../date/date-utils';
import {ApplicationCompileTimeSettings} from '../../domain/config/application-config-settings';
import {FrontendAppConfigKey} from '../../domain/config/frontend-app-config-key';

export interface AuthenticateOptions {
  showError?: boolean;
  initHandler?: AuthInitListener<any>;
}

@Injectable()
export class AuthService implements AuthProvider {

  private state$ = new BehaviorSubject<AuthState>(new AuthState());
  private tokenExpireSubscription = new Subscription();
  private subscription = new Subscription();

  private INVALID_CREDENTIAL_ERROR_REASON = 'Login / mot de passe invalides';
  private INVALID_INVITE_CODE_REASON = `Le code de validation n'a pas pu être vérifié`;
  private EXPIRED_SESSION_REASON = `Votre session a expiré`;
  private ERROR_401_WHILE_AUTHENTICATED_REASON = `L'accès à une resource vous a été refusé. Veuillez vous identifier à nouveau`;
  // When getting a 401, try to refresh token once
  private REFRESH_ON_401_MIN_DELAY_SECONDS = 2 * 60;

  // Cannot depend on any service depending on auth provider (this).
  constructor(
    private compileTimeSettings: ApplicationCompileTimeSettings,
    private userAuthClient: UserAuthWsClient,
    private userInviteAuthWsClient: UserInviteAuthWsClient,
    private requestService: RequestService,
    private keyValueStore: KeyValueStore,
    private base64Encoder: Base64Encoder,
  ) {
    this.restoreLastUsedLoginFromStorage();
    this.restoreLastUsedEmailFromStorage();
    this.observeUnauthorizedErrors();
  }

  getAuth(): LifeisLifeWsAuth {
    const state = this.state$.getValue();
    return state.wsAuth;
  }

  getAuthState$(): Observable<AuthState> {
    return this.state$;
  }

  authenticate$<T extends AuthState>(auth: LifeisLifeWsAuth,
                                     options?: AuthenticateOptions): Observable<T> {
    const curState = this.state$.getValue() as T;
    if (curState.status === 'authenticated') {
      console.warn('re-authenticating');
    } else if (curState.status === 'authenticating') {
      console.warn('re-authenticating');
      // return throwError(new Error('Already authenticating'));
    }

    this.tokenExpireSubscription.unsubscribe();
    this.updateState({
      wsAuth: auth,
      status: 'authenticating',
      // Preserve auth state while authenticating, so that refreshing a token does not force a full
      // reinitialization of the contact context. This can still be achieved by calling deauthenticate.
      // user: null,
      // roles: [],
      // contactRef: null,
      // trusteeContactRefs: [],
      // customerContactRefs: [],
      errorMessage: null,
    });
    const safeAuthTask$ = this.authenticateUser$<T>(options).pipe(
      mergeMap(state => this.applyHandler$<T>(state, options)),
      throwIfEmpty(() => new Error('Le serveur ne répond pas')),
      catchError(error => this.handleLoginError$<T>(curState, options, error)),
      publishReplay(1), refCount(),
    );
    safeAuthTask$.subscribe(state => {
        this.updateState(state);
        this.observerExpiringUserToken(state.wsAuth, options);
      },
    );
    return safeAuthTask$.pipe(
      mergeMap(state => this.rethrowIfNotAuthenticated$(state)),
      publishReplay(1), refCount(),
    );
  }


  /**
   * Deauthenticate, without redirection. Guarded page controllers should trigger the navigation
   * after calling this method.
   *
   * @param reason a message that can be displayed on the login prompt
   * @param options
   */
  deauthtenticate<T extends AuthState = AuthState>(reason: string, options: AuthenticateOptions = {}) {
    const curState = this.state$.getValue();
    const updatedState = Object.assign({}, curState, <Partial<AuthState>>{
      wsAuth: null,
      status: 'deauthenticated',
      user: null,
      roles: [],
      permissions: [],
      contactRef: null,
      trusteeContactRefs: [],
      customerContactRefs: [],
      errorMessage: reason,
    });
    this.removeAuthFromStorage();
    this.applyHandler$(updatedState, options).pipe(
      tap(newState => this.updateState(newState)),
      // Force async, so new state is propagated to guards when navigation occurs
      delay(0),
    ).subscribe(newState => {
      // // renavigate to trigger route guards
      // const curUrl = this.router.routerState.snapshot.url;
      // this.router.navigateByUrl(curUrl);
    });
  }

  refreshAuthenticationToken<T extends AuthState>(token: LifeisLifeWsAuth,
                                                  options: AuthenticateOptions = {}): Observable<AuthState> {
    const curState = this.state$.getValue();
    if (curState.status === 'authenticated') {
      console.warn('re-authenticating');
    } else if (curState.status === 'authenticating') {
      return throwError(new Error('Already authenticating'));
    }
    if (!(isUserTokenAuth(token))) {
      return throwError(new Error(`Not a refreshable user token`));
    }

    this.tokenExpireSubscription.unsubscribe();
    const safeAuthTask$ = this.refreshToken$(token.wsUserToken, options).pipe(
      mergeMap(state => this.applyHandler$(state, options)),
      throwIfEmpty(() => new Error('Le serveur ne répond pas')),
      catchError(error => this.handleLoginError$(curState, options, error)),
      publishReplay(1), refCount(),
    );
    safeAuthTask$.subscribe(state => {
        this.updateState(state);
        this.observerExpiringUserToken(state.wsAuth, options);
      },
    );
    return safeAuthTask$.pipe(
      mergeMap(state => this.rethrowIfNotAuthenticated$(state)),
      publishReplay(1), refCount(),
    );
  }


  private applyHandler$<T extends AuthState>(state: T, options: AuthenticateOptions = {}): Observable<T> {
    try {
      return this.applyInitHandler$(state, options);
    } catch (e) {
      return this.handleLoginError$(state, options, e);
    }
  }

  private applyInitHandler$(state: any, options: AuthenticateOptions = {}) {
    if (options.initHandler) {
      return options.initHandler(state);
    } else {
      return of(state);
    }
  }

  getState$(): Observable<AuthState> {
    return this.state$;
  }

  getState(): AuthState {
    return this.state$.getValue();
  }

  updateAuthState<T extends AuthState>(state: Partial<T>) {
    this.updateState(state);
  }

  clearState<T extends AuthState>(state: Partial<T>) {
    this.updateState(new AuthState());
  }

  isAuthenticated(): boolean {
    const curState = this.state$.getValue();
    return curState.status === 'authenticated';
  }

  getAuthenticatedObservable(): Observable<boolean> {
    return this.state$.pipe(
      map(s => s.status === 'authenticated'),
      publishReplay(1), refCount(),
    );
  }

  getLoggedUser(): User | null {
    const curState = this.state$.getValue();
    return curState.user;
  }

  getLoggedUser$(): Observable<User | null> {
    return this.state$.pipe(
      map(s => s.user),
      publishReplay(1), refCount(),
    );
  }

  getLastUsedLogin(): string | null {
    const curState = this.state$.getValue();
    return curState.lastLogin;
  }

  saveAuthToStorage(auth: LifeisLifeWsAuth) {
    if (auth == null || auth.type !== 'user-token') {
      return;
    }
    const storageKey = this.getAuthLocalStorageKey();
    const authJson = JSON.stringify(auth);
    const authB64 = this.base64Encoder.encodeToString(authJson);
    this.keyValueStore.putValue(storageKey, authB64);
  }

  restoreAuthFromStorage(): LifeisLifeWsAuth | null {
    const storageKey = this.getAuthLocalStorageKey();
    const authB64 = this.keyValueStore.getValue(storageKey);
    if (authB64 == null) {
      return null;
    }
    const authJSON = this.base64Encoder.decodeToString(authB64);
    let authJs: any;

    try {
      authJs = JSON.parse(authJSON);
    } catch (error) {
      console.warn('Failed to restore auth from storage due to json parse error: ' + error);
      this.keyValueStore.clearValue(storageKey);
      return null;
    }
    const auth: LifeisLifeWsAuth = AuthFactory.revive(authJs, this.base64Encoder);
    return auth;
  }

  removeAuthFromStorage() {
    const storageKey = this.getAuthLocalStorageKey();
    this.keyValueStore.clearValue(storageKey);
  }

  saveLastUsedLoginToStorage(login: string | null) {
    const key = this.getLastUsedLoginLocalStorageKey();
    const previousValue = this.keyValueStore.getValue(key);

    if (login == null) {
      this.keyValueStore.clearValue(key);
    } else if (previousValue !== login) {
      this.keyValueStore.putValue(key, login);
    }

    const curStateLogin = this.state$.getValue().lastLogin;
    if (curStateLogin !== login) {
      this.updateState({
        lastLogin: login,
      });
    }
  }

  saveLastUsedEmailToStorage(email: string | null) {
    const key = this.getLastUsedEmailLocalStorageKey();
    if (email == null) {
      this.keyValueStore.clearValue(key);
    } else {
      this.keyValueStore.putValue(key, email);
    }
    this.updateState({
      lastEmail: email,
    });
  }

  private authenticateUser$<T extends AuthState>(options: AuthenticateOptions = {}): Observable<T | null> {
    const curState = this.state$.getValue() as T;
    const curAuth: LifeisLifeWsAuth = curState.wsAuth;

    const user$ = this.getLoggedUserFromClient$(curAuth);
    const roles$ = this.getLoggedUserRoles$(curAuth);
    const newAuth$ = this.getFreshAuth$(curAuth);

    return forkJoin([user$, roles$, newAuth$]).pipe(
      switchMap(results => this.onAuthenticationResponses$<T>(curState,
        results[0], results[1], results[2], options)),
    );
  }

  private onAuthenticationResponses$<T extends AuthState>(curState: T,
                                                          user: User | null,
                                                          roles: Role[],
                                                          newAuth: LifeisLifeWsAuth | null,
                                                          options: AuthenticateOptions = {})
    : Observable<T> {
    // failed state without message.
    if (newAuth == null) {
      const failedStae = this.createFailedAuthState<T>(curState, null);
      return of(failedStae);
    } else {
      const loggedContactRef$ = this.getLoggedContactRef$(newAuth);
      const loggedTrustees$ = this.getLoggedUserTrusteeRefList$(newAuth);
      const loggedCustomers$ = this.getLoggedUserCustomerRefList(newAuth);

      return forkJoin([loggedContactRef$, loggedTrustees$, loggedCustomers$]).pipe(
        map(r => this.createSuccessAuthState<T>(curState, user, roles, newAuth, r[0], r[1], r[2], options)),
      );
    }
  }

  createFailedAuthState<T extends AuthState>(curState, errorMessage: string): T {
    return Object.assign({}, curState, {
      user: null,
      roles: [],
      permissions: [],
      contactRef: null,
      trusteeContactRefs: [],
      customerContactRefs: [],
      wsAuth: null,
      status: 'deauthenticated',
      errorMessage: errorMessage,
    }) as T;
  }

  private restoreLastUsedLoginFromStorage() {
    const key = this.getLastUsedLoginLocalStorageKey();
    const login = this.keyValueStore.getValue(key);
    this.updateState({
      lastLogin: login,
    });
  }

  private restoreLastUsedEmailFromStorage() {
    const key = this.getLastUsedEmailLocalStorageKey();
    const email = this.keyValueStore.getValue(key);
    this.updateState({
      lastEmail: email,
    });
  }

  private getAppConfigKey() {
    return this.compileTimeSettings.config[FrontendAppConfigKey.app_configKey];
  }

  private getAuthLocalStorageKey() {
    return this.getAppConfigKey() + '.auth';
  }


  private getLastUsedLoginLocalStorageKey() {
    return this.getAppConfigKey() + '.login';
  }

  private getLastUsedEmailLocalStorageKey() {
    return this.getAppConfigKey() + '.email';
  }

  private updateState(update: Partial<AuthState>) {
    const curState = this.state$.getValue();
    const nextState = Object.assign({}, curState, update);
    this.state$.next(nextState);
  }

  private observeUnauthorizedErrors() {
    const observationSubscription = this.requestService.getErrorsObservable().pipe(
      filter(error => this.isUnauthenticatedError(error)),
      // We might try to restore auth at the same time than exchanging an expired token. Give it some time
      delay(500),
      // We only want to deauthenticate when we get a 401 while authenticated, so ignore other cases
      switchMap(a => this.checkAuthStateAuthenticatedOrEmpty$(a)),
      // If we get a 401, recheck we are correctly logged in, otherwise redirect to login page with a message.
      // This should not happen as we have a timer to refresh the token, but there are errors reports for this issue
      // and if not handled, the app seems broken.
      // Exhaust map is crucial here: it will ignore any upstream value while the mapped observable (the one returned by
      // checkUserCorrectlyLoggedIn) did not complete - effectively discarding further 401 errors that might occur during the
      // relogin attempt.
      exhaustMap(error => this.validateLoggedUserOn401$()),
      filter(loggedIn => !loggedIn),
      // We were authenticated, got a 401/403, attempted to refresh our token, but that failed as well.
      // Ensure we are deauthenticated, and redirect to login with a message.
    ).subscribe(() => this.deauthtenticate(this.ERROR_401_WHILE_AUTHENTICATED_REASON, {}));
    this.subscription.add(observationSubscription);
  }

  private checkAuthStateAuthenticatedOrEmpty$(error: any) {
    const curState = this.state$.getValue();
    if (curState.status === 'authenticated') {
      return of(error);
    } else {
      return EMPTY;
    }
  }

  private observerExpiringUserToken<T extends AuthState>(tokenAuth: LifeisLifeWsAuth, options: AuthenticateOptions = {}) {
    if (isUserTokenAuth(tokenAuth)) {
      const wsTokenAuth = <WsUserTokenAuth>tokenAuth;
      this.tokenExpireSubscription = this.waitTokenExpirationRenewalTimes(wsTokenAuth.wsUserToken).pipe(
        switchMap(token => this.refreshToken$(token, options)),
        first(),
      ).subscribe();
    }
  }

  private waitTokenExpirationRenewalTimes(userToken: WsUserToken): Observable<WsUserToken> {
    const expireTimeAsString = userToken.expireTime;
    const expireDate = DateConverterUtils.parseIsoDateTime(expireTimeAsString);
    if (expireDate == null) {
      console.warn('Could not find out token expiration time: ' + expireTimeAsString);
      return;
    }
    const twoMinnutesPriorExpiration = DateUtils.addSecond(expireDate, -2 * 60);
    const twoSecondsFromNow = DateUtils.addSecond(new Date(), 2);
    const expiresLater = DateUtils.isDistinctSortedSeconds(twoSecondsFromNow, twoMinnutesPriorExpiration);
    const refreshTime = expiresLater ? twoMinnutesPriorExpiration : twoSecondsFromNow;
    const refreshTimeStirng = DateUtils.formatDateTimeToHumanFormat(refreshTime, true);
    const expireTimeString = DateUtils.formatDateTimeToHumanFormat(expireDate, true);
    console.log(`Refreshing token at ${refreshTimeStirng} (expires at ${expireTimeString})`);

    // Emits every 30 s starting 2 min before auth expires
    return timer(refreshTime, 30000).pipe(
      take(5),
      mapTo(userToken),
    );
  }

  private isUnauthenticatedError(error: HttpErrorResponse) {
    if (error == null || error.status == null) {
      return false;
    }
    // Do not handle 403: permission errors are most likely a bug in the frontend request
    // than an expired token.
    // return error.status === 401 || error.status === 403;
    return error.status === 401;
  }

  getLoggedUserFromClient$(auth: LifeisLifeWsAuth): Observable<User> {
    if (auth == null || auth.type === 'invite-token') {
      return of(null);
    }
    return this.userAuthClient.getLoggedUser$(auth).pipe(
      map(wsUser => UserConverter.convertIn(wsUser)),
      catchError(e => of(null)),
    );
  }

  getLoggedUserRoles$(auth: LifeisLifeWsAuth): Observable<Role[]> {
    if (auth == null) {
      return of([]);
    }
    return this.userAuthClient.getLoggedUserRoles$(auth).pipe(
      map(wsRoles => wsRoles == null ? null : wsRoles.map(
        wsRole => RoleConverter.fromWsRole(wsRole),
      )),
      catchError(e => of([])),
    );
  }

  // Do not catch error on this one, but let them propagate to get a meaningful message
  // depending on the status code.
  getFreshAuth$(auth: LifeisLifeWsAuth): Observable<LifeisLifeWsAuth> {
    if (auth == null) {
      return of(null);
    }
    if (auth.type === 'invite-token') {
      if (auth.role === Role.TRUSTEE) {
        return this.userInviteAuthWsClient.getTrusteeInviteCode$(auth as WsInviteTokenAuth).pipe(
          map(wsCode => auth as WsInviteTokenAuth),
        );
      } else {
        return this.userInviteAuthWsClient.getCustomerInviteCode$(auth as WsInviteTokenAuth).pipe(
          map(wsCode => auth as WsInviteTokenAuth),
        );
      }
    } else if (auth.type === 'oidc-token') {
      return of(auth);
    } else {
      return this.userAuthClient.getLoggedUserToken$(auth).pipe(
        map(wsToken => WsUserTokenAuth.fromUserToken(wsToken)),
      );
    }
  }

  setAuthStateError$<T extends AuthState>(error: any) {
    of(this.getState()).pipe(
      switchMap(cur => this.handleLoginError$(cur, {
        showError: true,
        initHandler: (s) => of(s),
      }, error)),
    ).subscribe(s => this.state$.next(s));
  }

  private getLoggedContactRef$(auth: LifeisLifeWsAuth): Observable<Ref<Contact>> {
    if (auth == null) {
      return of(null);
    }
    return this.userAuthClient.getLoggedUserContactRef$(auth).pipe(
      catchError(e => of(null)),
    );
  }


  private getLoggedUserTrusteeRefList$(auth: LifeisLifeWsAuth): Observable<Ref<TrusteeContact>[]> {
    if (auth == null) {
      return of(null);
    }
    return this.userAuthClient.getLoggedUserTrustees$(auth);
  }


  private getLoggedUserCustomerRefList(auth: LifeisLifeWsAuth): Observable<Ref<CustomerContact>[]> {
    if (auth == null) {
      return of(null);
    }
    return this.userAuthClient.getLoggedUserCustomers$(auth);
  }


  private refreshToken$<T extends AuthState>(token: WsUserToken, options: AuthenticateOptions = {}): Observable<T> {
    if (token.refreshToken) {
      const auth: LifeisLifeWsAuth = WsUserTokenAuth.forOidcToken(token.token, token.refreshToken);

      return this.userAuthClient.refreshUserToken$(auth, token.refreshToken).pipe(
        map(refreshedToken => WsUserTokenAuth.fromUserToken(refreshedToken)),
        switchMap(refreshedAuth => this.authenticateWithRefreshedaAuth$<T>(refreshedAuth, options)),
      );
    } else {
      return throwError(`No refresh token`);
    }
  }

  private authenticateWithRefreshedaAuth$<T extends AuthState>(refreshedAuth: WsUserTokenAuth, options: AuthenticateOptions = {}): Observable<T> {
    return this.authenticate$<T>(refreshedAuth, options);
  }

  private handleLoginError$<T extends AuthState>(curState: T, options: AuthenticateOptions, error: any): Observable<T> {
    const httpErrorStatus = ErrorUtils.getHttpErrorStatus(error);
    const curStateAuth = this.state$.getValue().wsAuth;
    let errorReason = `Veuillez vous identifier`;

    if (httpErrorStatus) {
      const backendError = ErrorUtils.getHttpBackendError(error);
      switch (httpErrorStatus) {
        case 401: {
          if (curStateAuth) {
            // Try to refresh token in case of 401
            if (isUserTokenAuth(curStateAuth)) {
              const refreshToken = curStateAuth.refreshToken || curStateAuth.wsUserToken?.refreshToken;
              // try refresh
              if (refreshToken != null && curStateAuth.wsUserToken.token !== refreshToken) {
                curStateAuth.wsUserToken.token = refreshToken;
                return this.refreshToken$<T>(curStateAuth.wsUserToken, options).pipe(
                  catchError(e => this.handleLoginError$<T>(curState, options, e)),
                );
              }
            } else if (isOidcAuth(curStateAuth)) {
              const refreshToken = curStateAuth.refreshToken;
              // try refresh
              if (refreshToken != null && curStateAuth.wsUserToken.token !== refreshToken) {
                curStateAuth.wsUserToken.token = refreshToken;
                return this.refreshToken$<T>(curStateAuth.wsUserToken, options).pipe(
                  catchError(e => this.handleLoginError$<T>(curState, options, e)),
                );
              }
            }
            errorReason = this.getInvalidCredentialErrorReason(curStateAuth);
          }
          break;
        }
        case 403: {
          if (backendError && backendError.message) {
            // Body is an error message to display
            errorReason = backendError.message;
          } else {
            errorReason = 'Accès refusé';
          }
          break;
        }
        default: {
          if (error.message != null) {
            errorReason = `${error.message}`;
          } else {
            errorReason = 'Erreur de communication avec le server. Si le probleme persiste, veuillez contacter le support.';
          }
          break;
        }
      }
    } else {
      if (error.message != null) {
        errorReason = `${error.message}`;
      } else {
        errorReason = 'Erreur de communication avec le server. Si le probleme persiste, veuillez contacter le support.';
      }
    }
    const showError = (options && options.showError) || true;

    const erroredState: T = <T>Object.assign({}, curState, <Partial<T>>{
      wsAuth: null,
      user: null,
      roles: [],
      contactRef: null,
      trusteeContactRefs: [],
      customerContactRefs: [],
      status: 'deauthenticated',
      errorMessage: showError ? errorReason : null,
      error: error,
    });
    return of(erroredState);
  }

  private rethrowIfNotAuthenticated$<T extends AuthState>(state: T): Observable<T> {
    const authenticated = state.status === 'authenticated';
    if (authenticated) {
      return of(state);
    }
    const stateErrorMessage = state.errorMessage;
    if (stateErrorMessage != null) {
      return throwError(new Error(state.errorMessage));
    } else if (state.error != null) {
      return throwError(state.error);
    } else {
      return throwError(new Error(`Unexpected error while authenticating`));
    }
  }

  private validateLoggedUserOn401$(): Observable<boolean> {
    const auth = this.getAuth();
    if (auth == null) {
      return of(false);
    } else {
      const state = this.state$.getValue();
      const lastRefreshAttemptDate = state.lastRefreshOn401AttemptedDate;

      if (lastRefreshAttemptDate) {
        const lastRefreshedMinutesAgo = DateUtils.isDistinctSortedSeconds(lastRefreshAttemptDate,
          new Date(), this.REFRESH_ON_401_MIN_DELAY_SECONDS);
        if (!lastRefreshedMinutesAgo) {
          return of(false);
        }
      }
      this.updateState({
        lastRefreshOn401AttemptedDate: new Date(),
      });
      return this.getFreshAuth$(auth).pipe(
        mergeMap(token => this.checkUserCorrectlyLoggedIn$(token)),
        catchError(e => of(false)),
        defaultIfEmpty(false),
      );
    }
  }


  private checkUserCorrectlyLoggedIn$(wsUserToken: LifeisLifeWsAuth): Observable<boolean> {
    if (wsUserToken && isUserTokenAuth(wsUserToken)) {
      if (wsUserToken.wsUserToken) {
        return this.refreshToken$(wsUserToken.wsUserToken, {}).pipe(
          map(state => state.status === 'authenticated'),
          catchError(e => of(false)),
        );
      }
    }
    return of(wsUserToken != null);
  }

  private createSuccessAuthState<T extends AuthState>(prevState: T, user: User, roles: Role[],
                                                      newAuth: LifeisLifeWsAuth,
                                                      contactRef: Ref<Contact>, trusteeRefList: Ref<TrusteeContact>[], customerRefList: Ref<CustomerContact>[],
                                                      options: AuthenticateOptions): T {
    const newState = Object.assign({}, prevState, {
      status: 'authenticated',
      errorMessage: null,
      user: user,
      roles: roles,
      wsAuth: newAuth,
      contactRef: contactRef,
      trusteeContactRefs: trusteeRefList,
      customerContactRefs: customerRefList,
    }) as T;
    return newState;
  }

  private getInvalidCredentialErrorReason(curStateAuth: LifeisLifeWsAuth) {
    switch (curStateAuth.type) {
      case 'basic':
        return this.INVALID_CREDENTIAL_ERROR_REASON;
      case 'oauth':
        return this.INVALID_CREDENTIAL_ERROR_REASON;
      case 'user-token':
        return this.EXPIRED_SESSION_REASON;
      case 'invite-token':
        return this.INVALID_INVITE_CODE_REASON;
      default:
        return null;
    }
  }
}
