export enum ApiEntityType {
  CONTACT = 'CONTACT',
  CREDIT = 'CREDIT',
  CREDIT_PROVIDER = 'CREDIT_PROVIDER',
  CREDIT_SUBSCRIPTION = 'CREDIT_SUBSCRIPTION',
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_BANK_ACCOUNT = 'CUSTOMER_BANK_ACCOUNT',
  CUSTOMER_CONTACT = 'CUSTOMER_CONTACT',
  CUSTOMER_EVENT_SUBSCRIPTION = 'CUSTOMER_EVENT_SUBSCRIPTION',
  CUSTOMER_NOTE = 'CUSTOMER_NOTE',
  CUSTOMER_STATUS = 'CUSTOMER_STATUS',
  CUSTOMER_TYPE = 'CUSTOMER_TYPE',
  INSURANCE = 'INSURANCE',
  INSURANCE_CATEGORY = 'INSURANCE_CATEGORY',
  INSURANCE_PROVIDER = 'INSURANCE_PROVIDER',
  INSURANCE_SUBSCRIPTION = 'INSURANCE_SUBSCRIPTION',
  INVESTMENT = 'INVESTMENT',
  INVESTMENT_PROVIDER = 'INVESTMENT_PROVIDER',
  INVESTMENT_SUBSCRIPTION = 'INVESTMENT_SUBSCRIPTION',
  LIFEISLIFE_ADMIN = 'LIFEISLIFE_ADMIN',
  LIFEISLIFE_USER = 'LIFEISLIFE_USER',
  LIFEISLIFE_LOG = 'LIFEISLIFE_LOG',
  LIFEISLIFE_LOG_ENTRY = 'LIFEISLIFE_LOG_ENTRY',
  LIFEISLIFE_EVENT_RECORD = 'LIFEISLIFE_EVENT_RECORD',
  MAIL = 'MAIL',
  MESSAGE_TEMPLATE = 'MESSAGE_TEMPLATE',
  NOTIFICATION = 'NOTIFICATION',
  PRICING = 'PRICING',
  PERSON_TITLE = 'PERSON_TITLE',
  PRODUCT = 'PRODUCT',
  PRODUCT_PROVIDER = 'PRODUCT_PROVIDER',
  SMS = 'SMS',
  SIGNING_PACKAGE = 'SIGNING_PACKAGE',
  SIGNING_PACKAGE_FILE = 'SIGNING_PACKAGE_FILE',
  STORED_FILE = 'STORED_FILE',
  SUBSCRIPTION = 'SUBSCRIPTION',
  SUBSCRIPTION_FILE = 'SUBSCRIPTION_FILE',
  SUBSCRIPTION_SIGNING_PACKAGE = 'SUBSCRIPTION_SIGNING_PACKAGE',
  THIRDPARTY = 'THIRDPARTY',
  TICKET = 'TICKET',
  TICKET_COMMENT = 'TICKET_COMMENT',
  TICKET_FILE = 'TICKET_FILE',
  TICKET_SIGNING_PACKAGE = 'TICKET_SIGNING_PACKAGE',
  TICKET_INSURANCE_MANDATE_REQUEST = 'TICKET_INSURANCE_MANDATE_REQUEST',
  TICKET_CUSTOMER = 'TICKET_CUSTOMER',
  TICKET_TURSTEE = 'TICKET_TURSTEE',
  TICKET_CONTACT = 'TICKET_CONTACT',
  TRUSTEE = 'TRUSTEE',
  TRUSTEE_CONTACT = 'TRUSTEE_CONTACT',
}
