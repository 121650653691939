import {Role} from '@lifeislife/lifeislife-domain';
import {ApplicationMenuItemData} from './main/routing/application-menu-item-data';
import {AppFeature} from './main/model/app-feature';

// These are menu data, defining labels/icons/links/permissions for menu items that can be used
// in the main menu bar as well as the breadcrumb.
// Menu items displayed in the tabs are defined under each modules, following a similar pattern.
export const ApplicationMenuData: any & Record<string, ApplicationMenuItemData> = {

  login: {
    title: 'Connexion',
    skipMenu: true,
  },

  register: {
    title: 'Inscription',
    skipMenu: true,
    userRoleConstraint: [Role.INVITEE],
  },

  welcome: {
    title: 'LifeisLife',
    menuIcon: 'fa fa-home',
    menuLink: ['/welcome'],
  },


  trustee: {
    title: 'Fiduciaires',
    userRoleConstraint: [Role.ADMIN],
    allAppFeatures: [AppFeature.trustee_list],
    menuIcon: 'fa fa-building-o',
    menuLink: ['/trustee'],
  },

  contact: {
    title: 'Contacts',
    allAppFeatures: [AppFeature.contact_list],
    menuIcon: 'fa fa-user',
    menuLink: ['/contacts'],
  },

  customer: {
    title: 'Clients',
    userRoleConstraint: [Role.ADMIN, Role.TRUSTEE],
    allAppFeatures: [AppFeature.customer_list],
    menuIcon: 'fa fa-users',
    menuLink: ['/customer'],
  },

  signingPackage: {
    title: 'Liasses à signer',
    userRoleConstraint: [Role.ADMIN, Role.TRUSTEE],
    allAppFeatures: [AppFeature.signing_packages_list],
    menuIcon: 'fa fa-certificate',
    menuLink: ['/signingPackage'],
  },

  insuranceMenu: {
    title: `Assurances`,
    id: 'insurance-menu',
    menuIcon: 'fa fa-life-ring',
    anyAppFeature: [AppFeature.insurance_list, AppFeature.insurance_provider_list, AppFeature.insurance_subscription_list],
  },

  // The 'insurance product' concept does not exist. Merged with subscription
  insurance: {
    title: `Assurances`,
    id: 'insurance',
    menuIcon: 'fa fa-life-ring',
    menuLink: ['/insurance'],
    anyAppFeature: [AppFeature.insurance_list],
  },

  insuranceCategory: {
    title: `Polices LIL`,
    id: 'insurance-category',
    menuIcon: 'fa fa-bookmark-o',
    menuLink: ['/insuranceCategory'],
    anyAppFeature: [AppFeature.insurance_category_list],
  },

  insuranceProvider: {
    title: `Compagnies d'assurance`,
    id: 'insurance-provider',
    menuIcon: 'fa fa-building',
    menuLink: ['/insuranceProvider'],
    anyAppFeature: [AppFeature.insurance_provider_list],
  },

  insuranceSubscription: {
    title: `Polices d'assurance`,
    id: 'insurance-subscription',
    menuIcon: 'fa fa-life-ring',
    menuLink: ['/insuranceSubscription'],
    anyAppFeature: [AppFeature.insurance_subscription_list],
  },


  newInsuranceSubscription: {
    title: `Nouvelle police`,
    id: 'new-insurance',
    menuIcon: 'fa fa-plus',
    menuLink: ['/insuranceSubscription/new'],
    allAppFeatures: [AppFeature.insurance_subscription_create],
  },

  creditMenu: {
    title: `Crédits`,
    id: 'credit-menu',
    menuIcon: 'fa fa-credit-card',
    anyAppFeature: [AppFeature.credit_list, AppFeature.credit_provider_list, AppFeature.credit_subscription_list],
  },

  credit: {
    title: `Crédits`,
    id: 'credit',
    menuIcon: 'fa fa-credit-card-alt',
    menuLink: ['/credit'],
    allAppFeatures: [AppFeature.credit_list],
  },

  creditProvider: {
    title: `Compagnies de crédit`,
    id: 'credit-provider',
    menuIcon: 'fa fa-building',
    menuLink: ['/creditProvider'],
    allAppFeatures: [AppFeature.credit_provider_list],
  },

  creditSubscription: {
    title: `Contrats de crédit`,
    id: 'credit-subscription',
    menuIcon: 'fa fa-credit-card',
    menuLink: ['/creditSubscription'],
    allAppFeatures: [AppFeature.credit_subscription_list],
  },

  newCreditSubscription: {
    title: `Nouveau Contrat`,
    id: 'new-credit',
    menuIcon: 'fa fa-plus',
    menuLink: ['/creditSubscription/new'],
    allAppFeatures: [AppFeature.credit_subscription_create],
  },

  investmentMenu: {
    title: `Placements`,
    id: 'investment-menu',
    menuIcon: 'fa fa-bank',
    anyAppFeature: [AppFeature.investment_list, AppFeature.investment_provider_list, AppFeature.investment_subscription_list],
  },

  investment: {
    title: `Placements`,
    id: 'investment',
    menuIcon: 'fa fa-bank',
    menuLink: ['/investment'],
    allAppFeatures: [AppFeature.investment_list],
  },

  investmentProvider: {
    title: `Compagnies d'investissements`,
    id: 'investment-provider',
    menuIcon: 'fa fa-building',
    menuLink: ['/investmentProvider'],
    allAppFeatures: [AppFeature.investment_provider_list],
  },

  investmentSubscription: {
    title: `Contrats d'investissement`,
    id: 'investment-subscription',
    menuIcon: 'fa fa-bank',
    menuLink: ['/investmentSubscription'],
    allAppFeatures: [AppFeature.investment_subscription_list],
  },

  newInvestmentSubscription: {
    title: `Nouveau Contrat`,
    id: 'new-investment',
    menuIcon: 'fa fa-plus',
    menuLink: ['/investmentSubscription/new'],
    allAppFeatures: [AppFeature.investment_subscription_create],
  },

  subscription: {
    title: `Contrats`,
    id: 'subscription',
    menuIcon: 'fa fa-money',
    menuLink: ['/subscription'],
    allAppFeatures: [AppFeature.subscription_list],
  },

  user: {
    title: `Utilisateurs`,
    menuIcon: 'fa fa-users',
    id: 'user',
    menuLink: ['/user/list'],
    allAppFeatures: [AppFeature.user_list],
  },
  messageTemplate: {
    title: `Modèles de message`,
    menuIcon: 'fa fa-file-text',
    id: 'message-template',
    menuLink: ['/messageTemplate/list'],
    allAppFeatures: [AppFeature.message_template_list],
  },
  mail: {
    title: `Mails`,
    menuIcon: 'fa fa-envelope',
    id: 'mail',
    menuLink: ['/mail/list'],
    allAppFeatures: [AppFeature.mail_list],
  },
  webmailDev: {
    title: `Webmail dev`,
    menuIcon: 'fa fa-envelope-o',
    id: 'Webmail dev',
    url: 'https://mail.valuya.be',
    allAppFeatures: [AppFeature.mail_list],
  },
  preference: {
    title: `Configuration`,
    menuIcon: 'fa fa-cog',
    id: 'preference',
    menuLink: ['/preference/list'],
    allAppFeatures: [AppFeature.app_preference_list],
  },
  personTitle: {
    title: `Civilités`,
    menuIcon: 'fa fa-user',
    id: 'person-title',
    menuLink: ['/personTitle/list'],
    allAppFeatures: [AppFeature.person_title_list],
  },
  emailAccount: {
    title: 'Comptes mail',
    menuIcon: 'fa  fa-envelope-o',
    id: 'email-account',
    menuLink: ['/emailAccount'],
    allAppFeatures: [AppFeature.email_account_list],
  },
  smtpACcount: {
    title: 'Comptes SMTP',
    menuIcon: 'fa  fa-envelope-o',
    id: 'smtp-account',
    menuLink: ['/smtpAccount'],
    allAppFeatures: [AppFeature.smtp_account_list],
  },
  eventRecord: {
    title: 'Évènements',
    menuIcon: 'fa  fa-clock-o',
    id: 'event-record',
    menuLink: ['/eventRecord'],
    allAppFeatures: [AppFeature.event_record_list],
  },

  batches: {
    userRoleConstraint: [Role.ADMIN],
    title: 'Batches',
    menuIcon: 'fa fa-stopwatch',
    menuLink: ['/batch'],
    allAppFeatures: [AppFeature.batch_list],
  },

  administration: {
    userRoleConstraint: [Role.ADMIN],
    title: 'Administration',
    menuIcon: 'fa fa-cog',
    // anyAppFeature: [AppFeature.mail_list],
  },

  configuration: {
    userRoleConstraint: [Role.MASTER],
    title: 'Configuration',
    menuIcon: 'fa fa-wrench',
    // anyAppFeature: [AppFeature.mail_list],
  },

};
