import {WsInvestment, WsInvestmentSearch} from '@lifeislife/lifeislife-ws-api';
import {Investment} from '../../../domain/corebusiness/investment/investment';
import {InvestmentSearch} from '../../../domain/corebusiness/investment/investment-search';
import {ProductConverter} from '../product-converter';

export class InvestmentConverter {

  static convertIn(value: WsInvestment): Investment {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      investmentProviderRef: value.investmentProviderWsRef,
      productRef: value.productWsRef,
    };
  }

  static convertOut(value: Investment): WsInvestment {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      investmentProviderWsRef: value.investmentProviderRef,
      productWsRef: value.productRef,
    };
  }


  static convertFilterIn(value?: WsInvestmentSearch): InvestmentSearch {
    if (value == null) {
      return undefined;
    }
    return {
      investmentProviderRef: value.investmentProviderWsRef,
      investmentRef: value.investmentWsRef,
      productSearch: ProductConverter.convertFilterIn(value.wsProductSearch),
    };
  }

  static convertFilterOut(value: InvestmentSearch): WsInvestmentSearch {
    if (value == null) {
      return undefined;
    }
    return {
      investmentProviderWsRef: value.investmentProviderRef,
      investmentWsRef: value.investmentRef,
      wsProductSearch: ProductConverter.convertFilterOut(value.productSearch),
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsInvestment]: keyof Investment } {
    return {
      id: 'id',
      investmentProviderWsRef: 'investmentProviderRef',
      productWsRef: 'productRef',
    };
  }

}
