import {Injectable} from '@angular/core';
import {RequestService} from '../../service/request.service';
import {ResourceCacheService} from '../../../util/cache/resource-cache.service';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {AppConfigService} from '../../../service/config/app-config.service';
import {FrontendAppConfigKey} from '../../../domain/config/frontend-app-config-key';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UploadProgressUtils} from '../../../util/upload/upload-progress.utils';
import {FileUploadProgress} from '../../../util/upload/file-upload-progress';
import {FileUploadUtils} from '../../private_util/file-upload-utils';

@Injectable({
  providedIn: 'root',
})
export class DataImportWsClient {


  constructor(private requestService: RequestService,
              private cacheService: ResourceCacheService,
              private authService: AuthProvider,
              private appConfigService: AppConfigService) {
  }

  startAgBatch(file: File): Observable<FileUploadProgress> {
    const url = this.getResourceUri('ag-lifeislife-import-batch') + '?ngsw-bypass=true';
    const auth = this.authService.getAuth();
    return UploadProgressUtils.createFileUploadProgress$(file,
      FileUploadUtils.singleFileUploadWithFetchApi$(file, url, auth,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),
    ).pipe(
      catchError(e => this.handleFileUploadError$(e, file)),
    );
  }

  startGenericInsuranceBatch(file: File): Observable<FileUploadProgress> {
    const url = this.getResourceUri('insurance-import-batch') + '?ngsw-bypass=true';
    const auth = this.authService.getAuth();
    return UploadProgressUtils.createFileUploadProgress$(file,
      FileUploadUtils.singleFileUploadWithFetchApi$(file, url, auth,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),
    ).pipe(
      catchError(e => this.handleFileUploadError$(e, file)),
    );
  }

  private handleFileUploadError$(error: Error | any, file: File): Observable<FileUploadProgress> {
    const erroredFileUploadProgress = UploadProgressUtils.createErroredFileUploadProgress(error, file);
    return of(erroredFileUploadProgress);
  }

  private getResourceUri(subpath = '') {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    return `${wsUri}/internal/dataImport/${subpath}`;
  }

}
