<span class="calendar-input" [class.readonly]="readonly">
  <p-calendar [ngModel]="value | async"
              (ngModelChange)="onValueChange($event)"
              (onClearClick)="onValueChange(null)"
              name="date"
              [showIcon]="true"
              [monthNavigator]="showYear"
              [yearNavigator]="showYear"
              [yearRange]="yearRange"
              [disabled]="disabled"
              [showOnFocus]="!readonly && !disabled"
              [readonlyInput]="readonly"
              [inputStyleClass]="hasError ? 'ng-dirty ng-invalid' : '' "
              panelStyleClass="calendar-input-panel"
              [showButtonBar]="showClearAction"
              [minDate]="minDate"
              [maxDate]="maxDate"
              [appendTo]="appendTo"
              [firstDayOfWeek]="1"
              [dateFormat]="showYear ? 'dd/mm/yy' : 'dd/mm'">
              dateFormat="dd/mm/yy">
      <p-header>
          <ng-content select="[header]"></ng-content>
      </p-header>
      <p-footer>
          <ng-content select="[footer]"></ng-content>
      </p-footer>
  </p-calendar>
</span>
