import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {InsuranceService, InsuranceType} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, publishReplay, refCount} from 'rxjs/operators';

@Component({
  selector: 'llc-insurance-type',
  templateUrl: './insurance-type.component.html',
  styleUrls: ['./insurance-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceTypeComponent implements OnInit {


  @Input()
  set type(value: InsuranceType) {
    this.typeSource$.next(value);
  }

  @Input()
  badge: boolean;

  label$: Observable<string>;
  typeSource$ = new BehaviorSubject<InsuranceType | null>(null);

  constructor(
    private insuranceService: InsuranceService,
  ) {
  }

  ngOnInit() {
    this.label$ = this.typeSource$.pipe(
      map(type => this.insuranceService.getInsuranceTypeName(type)),
      publishReplay(1), refCount(),
    );
  }

}
