import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrusteeStatusComponent} from './trustee-status/trustee-status.component';
import {TrusteeStatusMultiSelectComponent} from './trustee-status-multi-select/trustee-status-multi-select.component';
import {CheckboxModule} from 'primeng/checkbox';
import {FormsModule} from '@angular/forms';
import {TrusteeStatusSelectComponent} from './trustee-status-select/trustee-status-select.component';
import {DropdownModule} from 'primeng/dropdown';
import {TrusteeFormComponent} from './trustee-form/trustee-form.component';
import {InputTextModule} from 'primeng/inputtext';
import {MainModule} from '../../main.module';
import {TrusteeSelectComponent} from './trustee-select/trustee-select.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {PercentageInputModule} from '../../../commons/components/percentage/percentage-input/percentage-input.module';
import {TrusteeComponent} from './trustee/trustee.component';
import {MainCountryModule} from '../country/main-country.module';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [TrusteeStatusComponent,
    TrusteeComponent,
    TrusteeStatusMultiSelectComponent, TrusteeStatusSelectComponent, TrusteeFormComponent, TrusteeSelectComponent],
    imports: [
        CommonModule,
        CheckboxModule,
        FormsModule,
        DropdownModule,
        InputTextModule,
        MainModule,
        PercentageInputModule,
        AutoCompleteModule,
        MainCountryModule,
        RouterModule,
    ],
  exports: [TrusteeStatusComponent, TrusteeComponent, TrusteeStatusMultiSelectComponent, TrusteeStatusSelectComponent, TrusteeFormComponent, TrusteeSelectComponent,
  ],
})
export class MainTrusteeComponentsModule {
}
