<div class="identity-switcher-body">

  <form class="identity-switcher-panel p-card" #identitySwitcherForm="ngForm"
        (ngSubmit)="onSubmit(identitySwitcherForm.valid)">

    <div class="header">
      <img src="{{baseHref}}assets/logo-lifeislife-128.png" class="logo">
      <h2 i18n>Vous avez plusieurs casquettes</h2>
      <p>
        Votre compte est lié à plusieurs rôles. Veuillez choisir pour lequel vous souhaitez
        utiliser l'application. Vous pouvez changer à tout moment via le menu utilisateur.
      </p>
    </div>

    <div class="identity-list">
      <div class="role-choose-list">
        <p-listbox name="role-list"
                   [options]="rolesOptions$ | async"
                   [(ngModel)]="selectedRoleValue"
                   [multiple]="false"
                   [required]="true"
        >
          <ng-template let-item let-i="index" pTemplate="item">
            <div class="ui-helper-clearfix role-row">
              <i class="icon" [ngClass]="item.roleIcon"></i>
              <div class="role-label" *ngIf="item.trusteeContact != null">
                <app-trustee-contact-type
                  [contactType]="item.trusteeContact.trusteeContactType"></app-trustee-contact-type>
                <span>chez</span>
                <app-trustee [ref]="item.trusteeContact.trusteeRef"></app-trustee>
              </div>
              <div class="role-label" *ngIf="item.customerContact != null">
                <app-customer-contact-type
                  [contactType]="item.customerContact.customerContactType"></app-customer-contact-type>
                <span>pour</span>
                <app-customer [ref]="item.customerContact.customerRef"></app-customer>
              </div>
              <div class="admin-role-label" *ngIf="item.role === 'ADMIN'">
                <span i18n>Administrateur LifeisLife</span>
              </div>
            </div>
          </ng-template>
        </p-listbox>
      </div>
    </div>

    <div class="button-container">
      <button type="submit" hidden></button>
      <button type="button" pButton
              icon="fa fa-check"
              label="Valider" i18n-label
              (click)="onSubmit(identitySwitcherForm.valid)"
      ></button>
    </div>
  </form>
</div>
