import {WsDaoEventEntityType} from '@lifeislife/lifeislife-ws-api';

export type CacheResourceType = WsDaoEventEntityType | FrontendResourceType;
// Re-export WsDaoEventEntityType so clients are still abstracted from the api (they dont need to import it)
export {WsDaoEventEntityType} from '@lifeislife/lifeislife-ws-api';

// Resources not listed in WsDaoEventEntityType - yet
export enum FrontendResourceType {

  // Those are cached locally but no cache event expected from the server
  CAREER_PERIOD_DETAILS,
  LEGAL_PENSION_CAREER_DETAILS,
  LEGAL_PENSION_STATUS,
  PENSION_CAREER_STATUS,
  PENSION_PLAN_STATUS,
  SUPPLEMENTARY_PENSION_STATUS,
  MESSAGE_TYPE,
  INSURANCE_OPTIONAL_FEATURES,
  INSURANCE_SUBSCRIPTION_SNAPSHOT_LAST_IMPORT,
  PREFERENCE,
}
