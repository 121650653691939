import {WsProduct, WsProductSearch, WsProductType} from '@lifeislife/lifeislife-ws-api';
import {Product} from '../../domain/corebusiness/product';
import {ProductType} from '../../domain/corebusiness/product-type';
import {ProductSearch} from '../../domain/corebusiness/product-search';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {ProductProviderConverter} from './product-provider-converter';

export class ProductConverter {

  static convertIn(value: WsProduct): Product {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      productType: value.wsProductType as string as ProductType,
      name: value.name,
      providerRef: value.providerWsRef,
      pros: value.pros,
      cons: value.cons,
      comments: value.comments,
      archived: value.archived,
      imported: value.imported,
      lastImportDateTime: DateConverterUtils.parseIsoDateTime(value.lastImportDateTime),
      creationDateTime: DateConverterUtils.parseIsoDateTime(value.creationTime),
      updateDateTime: DateConverterUtils.parseIsoDateTime(value.updateTime),
    };
  }

  static convertOut(value: Product): WsProduct {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      wsProductType: value.productType as string as WsProductType,
      name: value.name,
      providerWsRef: value.providerRef,
      pros: value.pros,
      cons: value.cons,
      comments: value.comments,
      archived: value.archived,
      imported: value.imported,
      lastImportDateTime: DateConverterUtils.formatDateToIsoDateTime(value.lastImportDateTime),
      creationTime: DateConverterUtils.formatDateToIsoDateTime(value.creationDateTime),
      updateTime: DateConverterUtils.formatDateToIsoDateTime(value.updateDateTime),
    };
  }

  static convertFilterIn(value: WsProductSearch): ProductSearch {
    if (value == null) {
      return undefined;
    }
    return {
      nameContains: value.nameContains,
      multiSearch: value.multiSearch,
      archived: value.archived,
      productProviderSearch: ProductProviderConverter.convertFilterIn(value.productProviderSearch),
      productRef: value.productWsRef,
      productType: EnumConverterUtils.convertValue(value.wsProductType, ProductType),
    };
  }

  static convertFilterOut(value: ProductSearch): WsProductSearch {
    if (value == null) {
      return undefined;
    }
    return {
      nameContains: value.nameContains,
      multiSearch: value.multiSearch,
      archived: value.archived,
      productProviderSearch: ProductProviderConverter.convertFilterOut(value.productProviderSearch),
      productWsRef: value.productRef,
      wsProductType: EnumConverterUtils.convertValue(value.productType, WsProductType),
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsProduct]: keyof Product } {
    return {
      id: 'id',
      wsProductType: 'productType',
      name: 'name',
      providerWsRef: 'providerRef',
      pros: 'pros',
      cons: 'cons',
      comments: 'comments',
      archived: 'archived',
      imported: 'imported',
      lastImportDateTime: 'lastImportDateTime',
      creationTime: 'creationDateTime',
      updateTime: 'updateDateTime',
    };
  }

}
