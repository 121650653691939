import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {Trustee} from '../../domain/trustee/trustee';
import {TrusteePreference} from '../../domain/trustee/trustee-preference';
import {catchError, map} from 'rxjs/operators';
import {TrusteePreferenceWsClient} from '../../client/resources/front/trustee-preference-ws-client';

@Injectable({
  providedIn: 'root',
})
export class TrusteePreferenceService {

  constructor(private client: TrusteePreferenceWsClient) {
  }

  getCustomerContactAddress$(trusteeRef: Ref<Trustee>): Observable<string> {
    if (!isValidRef(trusteeRef)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.getPreferenceValue$<string>(trusteeRef, TrusteePreference.CUSTOMER_CONTACT_EMAIL_ADDRESS).pipe(
      catchError(e => of(`info@lifeislife.be`)),
    );
  }

  getHiddenFolderRegexps$(trusteeRef: Ref<Trustee>): Observable<string[]> {
    if (!isValidRef(trusteeRef)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.getPreferenceValue$<string>(trusteeRef, TrusteePreference.HIDDEN_FOLDER_REGEXP).pipe(
      map(value => this.splitRegexp(value)),
    );
  }

  setHiddenFolderRegexps(trusteeRef: Ref<Trustee>, value: string[]): Observable<any> {
    if (!isValidRef(trusteeRef)) {
      return throwError(new Error('Invalid ref'));
    }
    const stringValue = value.reduce((c, n) => c == null ? n : `${c}|${n}`);
    return this.client.setPreferenceValue$<string>(trusteeRef, TrusteePreference.HIDDEN_FOLDER_REGEXP, stringValue);
  }

  private splitRegexp(value: string): string[] {
    if (value == null) {
      return [];
    }
    return value.split('\|')
      .filter(part => part.length > 0);
  }
}
