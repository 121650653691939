import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {NotificationMessageService} from '../../commons/services/notificationMessage.service';
import {RouteUtils} from '@lifeislife/lifeislife-components';
import {ActivatedRoute, Router} from '@angular/router';
import {APP_BASE_HREF} from '@angular/common';
import {
  AppConfigService,
  AuthCodeResolver,
  AuthService,
  FrontendAppSwitch,
  Role,
  User,
  UserAuthService,
  UserService,
} from '@lifeislife/lifeislife-domain';
import {debounceTime, distinctUntilChanged, filter, map, publishReplay, refCount, switchMap, take} from 'rxjs/operators';
import {LoginMethod} from './login-method';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  loginMethods: LoginMethod[] = ['email', 'login-password'];
  loginMethodTabIndex = 0;

  login: string;
  password: string;

  email: string;
  emailSent: boolean;

  authenticating$: Observable<boolean>;
  message$: Observable<string>;

  debug$: Observable<boolean>;
  error$: Observable<any | null>;

  private redirectUrl: string;
  private subscription: Subscription = new Subscription();

  constructor(private userAuthService: UserAuthService,
              private authService: AuthService,
              private userService: UserService,
              private authCodeResolver: AuthCodeResolver,
              private notificationService: NotificationMessageService,
              private changeDetectorRef: ChangeDetectorRef,
              @Inject(APP_BASE_HREF)
              public baseHref: string,
              private configService: AppConfigService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.debug$ = this.configService.getFrontSwitchEnabled$(FrontendAppSwitch.front_dev_debug);

    const errorsSubscription = this.authService.getState$().pipe(
      debounceTime(200),
      take(1), // Only display first error if redirected to login
      filter(s => s.status === 'deauthenticated'),
      map(s => s.errorMessage),
      filter(s => s != null),
    ).subscribe(error => this.notificationService.addError(error, null, 10000));
    this.subscription.add(errorsSubscription);

    const authCodeSubscription = this.authCodeResolver.exchangeToken$(this.route.snapshot.queryParamMap).pipe(
      switchMap(auth => this.userAuthService.authenticate$(auth, true)),
    ).subscribe(u => this.onAuthCodeExchangeSuccess(u),
      e => this.onAuthCodeExchangeError(e));
    this.subscription.add(authCodeSubscription);

    const routeSubscription = RouteUtils.getRouteParam('redirectUrl', this.route)
      .subscribe(param => this.redirectUrl = param);
    this.subscription.add(routeSubscription);

    const lastLoginSubscription = this.userAuthService.state$.pipe(
      map(state => state.lastLogin),
      distinctUntilChanged(),
      filter(s => s != null),
    ).subscribe(lastLogin => this.login = lastLogin);
    this.subscription.add(lastLoginSubscription);

    const lastEmailSubscription = this.userAuthService.state$.pipe(
      map(state => state.lastEmail),
      distinctUntilChanged(),
      filter(s => s != null),
    ).subscribe(lastEmail => this.email = lastEmail);
    this.subscription.add(lastEmailSubscription);

    const loggedInSubscription = this.authService.getAuthenticatedObservable().pipe(
      map(a => a),
    ).subscribe(() => this.onLoginSuccess());
    this.subscription.add(loggedInSubscription);
    //
    // // 1 time attempts, no need to unsubscribe
    // this.userAuthService.attemptRestoreFromStorage$().pipe(
    //   filter(a => a != null),
    // ).subscribe(a => this.onLoginSuccess());

    this.authenticating$ = this.userAuthService.state$.pipe(
      map(state => state.status === 'authenticating'),
      publishReplay(1), refCount(),
    );
    this.message$ = this.userAuthService.state$.pipe(
      map(state => state.errorMessage),
      publishReplay(1), refCount(),
    );
    this.error$ = this.userAuthService.state$.pipe(
      map(state => state.error),
      publishReplay(1), refCount(),
    );

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onEmailLogin(formValid: boolean) {
    if (!formValid) {
      return;
    }

    this.authService.updateAuthState({
      errorMessage: null,
    });
    const url = location.href;
    this.userService.requestEmailValidationCode$(this.email, url, false,  Role.ADMIN)
      .subscribe((uri) => this.onEmailValidationSent(uri),
        error => this.onEmailValidationError(error));
  }

  onLogin(formValid: boolean) {
    if (!formValid) {
      return;
    }
    this.userAuthService.authenticateBasic$(this.login, this.password, true)
      .subscribe(() => this.onLoginSuccess(),
        error => this.notificationService.addError(error));
  }


  private onAuthCodeExchangeSuccess(user: User) {
    this.onLoginSuccess();
  }

  private onLoginSuccess() {
    const gestempsWsAuth = this.authService.getAuth();
    if (gestempsWsAuth != null && gestempsWsAuth.type === 'user-token') {
      this.authService.saveAuthToStorage(gestempsWsAuth);
      this.authService.saveLastUsedLoginToStorage(this.login);
    }
    if (this.redirectUrl != null) {
      this.router.navigateByUrl(this.redirectUrl)
        .catch(e => this.handleRedirectError(e));
    } else {
      this.router.navigate(['/welcome'], {
        queryParams: {
          authCode: undefined,
          authError: undefined,
        },
      })
        .catch(e => console.warn(e));
    }
  }

  private handleRedirectError(error: any) {
    console.warn(error);
    this.router.navigate(['/welcome'])
      .catch(e => console.warn(e));
  }

  private onEmailValidationSent(uri: string) {
    const debug = this.configService.isSwitchCurrentlyEnabled(FrontendAppSwitch.front_dev_debug);
    if (debug) {
      console.log('Email link: ' + uri);
    }
    this.authService.saveLastUsedEmailToStorage(this.email);
    this.emailSent = true;
  }

  private onEmailValidationError(error) {
    if (error && error.status && error.status === 404) {
      this.userAuthService.deauthenticate(`Adresse email inconnue`);
      return;
    }
    this.notificationService.addError(error);
  }

  private onAuthCodeExchangeError(error: any) {
    // this.notificationService.addError(`Unable toerror);
    this.router.navigate(['/login'], {
      queryParams: {
        authCode: undefined,
        authError: undefined,
      },
    });
  }
}
