import {enableProdMode, LOCALE_ID, StaticProvider} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {forkJoin} from 'rxjs';
import {
  ApplicationCompileTimeSettings,
  ApplicationVersion,
  INVITE_AUTH_TOKEN,
  USER_AUTH_TOKEN,
  VERSION,
} from '@lifeislife/lifeislife-domain';
import {APP_BASE_HREF, PlatformLocation, registerLocaleData} from '@angular/common';
import {fetchAuthTokenFromQueryString, fetchInviteCodeFromQueryString} from './app/commons/resources/clientConfigProvider';
import localeFr from '@angular/common/locales/fr';
import {Settings} from 'luxon';
import {getLifeisLifeRoleBaseSwitchGrants} from './app/main/model/app-feature-roles-grants';
import {getLifeisLifeTrusteeContactTypeBasedSwitchGrants} from './app/main/model/app-feature-trustee-role-grants';

registerLocaleData(localeFr);
Settings.defaultLocale = 'fr';

const appVersion: ApplicationVersion = {
  version: VERSION,
};
const frontCompileTimeAppConfig: ApplicationCompileTimeSettings = {
  config: environment.defaultConfig,
  switches: environment.defaultSwitches,
  roleGrantedSwitches: getLifeisLifeRoleBaseSwitchGrants(),
  trusteeContactGrantedSwitches: getLifeisLifeTrusteeContactTypeBasedSwitchGrants(),
};
if (environment.production) {
  enableProdMode();
}

// TODO: use APP_INITIALIZER for those config. Use singletons to fetch & store configs rather than injection tokens
forkJoin(
  fetchAuthTokenFromQueryString(),
  fetchInviteCodeFromQueryString(),
).subscribe(results => {
  const authtoken: string | null = results[0];
  const inviteCode: string | null = results[1];

  const providers: StaticProvider[] = [
    {provide: ApplicationVersion, useValue: appVersion},
    {provide: ApplicationCompileTimeSettings, useValue: frontCompileTimeAppConfig},
    {provide: LOCALE_ID, useValue: 'fr'},
    {
      provide: APP_BASE_HREF,
      useFactory: (platformLocation: PlatformLocation) => platformLocation.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
  ];
  if (authtoken != null) {
    providers.push({
      provide: USER_AUTH_TOKEN,
      useValue: authtoken,
    });
  }
  if (inviteCode != null) {
    providers.push({
      provide: INVITE_AUTH_TOKEN,
      useValue: inviteCode,
    });
  }
  platformBrowserDynamic(providers).bootstrapModule(AppModule, {
    providers: [
      // https://github.com/angular/angular-cli/issues/6683
      {provide: LOCALE_ID, useValue: 'fr'},
    ],
  })
    .catch(err => console.error(err));

});
