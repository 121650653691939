import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {WsContactClaim, WsContactClaimType} from '@lifeislife/lifeislife-ws-api';
import {ContactClaim} from '../../domain/contact/contact-claim';
import {ContactClaimType} from '../../domain/contact/contact-claim-type';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';

export class ContactClaimConverter {

  static toContactClaim(value: WsContactClaim): ContactClaim {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      contactWsRef: value.contactWsRef,
      contactClaimType: ContactClaimConverter.toContactClaimType(value.wsContactClaimType),
      claimDateTime: DateConverterUtils.parseIsoDateTime(value.claimDateTime),
      value: value.value,
      description: value.description,
    };
  }

  static toContactClaimType(value: WsContactClaimType): ContactClaimType {
    if (value == null) {
      return undefined;
    }
    return EnumConverterUtils.convertValue(value, ContactClaimType);
  }


}
