import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ApplicationRoutes} from './application-routes';
import {ApplicationRoute} from './main/routing/application-route';
import {AppShellComponent} from './shell/app-shell/app-shell.component';
import {UserLoggedInRouterGuard} from './main/services/user-logged-in.guard';
import {Role} from '@lifeislife/lifeislife-domain';

const appShellRoutes: ApplicationRoute[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'welcome',
  },

  ApplicationRoutes.welcome,
  ApplicationRoutes.customer,
  ApplicationRoutes.contact,
  ApplicationRoutes.insurance,
  ApplicationRoutes.insuranceCategory,
  ApplicationRoutes.insuranceProvider,
  ApplicationRoutes.insuranceSubscription,
  ApplicationRoutes.credit,
  ApplicationRoutes.creditProvider,
  ApplicationRoutes.creditSubscription,
  ApplicationRoutes.investment,
  ApplicationRoutes.investmentProvider,
  ApplicationRoutes.investmentSubscription,
  ApplicationRoutes.messageTemplate,
  ApplicationRoutes.mail,
  ApplicationRoutes.personTitle,
  ApplicationRoutes.preference,
  ApplicationRoutes.emailAccount,
  ApplicationRoutes.signingPackage,
  ApplicationRoutes.subscription,
  ApplicationRoutes.smtpAccount,
  ApplicationRoutes.trustee,
  ApplicationRoutes.user,
  ApplicationRoutes.eventRecord,
  ApplicationRoutes.batch,

  {
    path: '**',
    redirectTo: '/welcome',
  },
];

const routes: ApplicationRoute[] = [
  ApplicationRoutes.login,
  ApplicationRoutes.register,
  {
    path: '',
    component: AppShellComponent,
    canActivate: [UserLoggedInRouterGuard],
    children: appShellRoutes,
    data: {
      menuData: {
        userRoleConstraint: [Role.BASIC_USER],
      },
    },
  },
  {
    path: '**',
    redirectTo: '/welcome',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'disabled',
  }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
