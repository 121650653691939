<div class="customer-contact-multi-view">
  <span class="contact">
    <app-contact-view [ref]="contactRefSource$ | async"
    ></app-contact-view>
  </span>
  <span class="contact-types">
    <span *ngFor="let customerContact of customerContacts$ | async">
      <app-customer-contact-type-badge [customerContact]="customerContact"
      ></app-customer-contact-type-badge>
    </span>
  </span>
</div>
