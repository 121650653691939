import {WsContactSearch, WsCustomerContactType, WsTrusteeContactType} from '@lifeislife/lifeislife-ws-api';
import {ContactSearch} from '../../domain/contact/contact-search';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {TrusteeContactType} from '../../domain/trustee/trustee-contact-type';
import {CustomerContactType} from '../../domain/customer-contact/customer-contact-type';
import {CustomerSearchConverter} from '../customer/customer-search.converter';
import {TrusteeSearchConverter} from '../trustee/trustee-search.converter';

export class ContactSearchConverter {

  static toContactSearch(value: WsContactSearch): ContactSearch {
    if (value == null) {
      return undefined;
    }
    return {
      exactContactRef: value.exactContactRef,
      trusteeRef: value.trusteeRef,
      customerTrusteeRef: value.customersTrusteeRef,
      contactIdBlackList: value.contactIdBlackList,
      internalOptional: value.internal,
      companyContains: value.companyContains,
      lastNameContains: value.lastNameContains,
      firstNameContains: value.firstNameContains,
      anyNameContains: value.anyNameContains,
      domain: value.domain,
      customerRef: value.customerRef,
      idExpiryFromDate: DateConverterUtils.parseIsoDate(value.idExpiryFromDate),
      idExpiryToDate: DateConverterUtils.parseIsoDate(value.idExpiryToDate),
      customerContactTypes: value.wsCustomerContactTypeList == null ? [] : value.wsCustomerContactTypeList.map(
        wsType => wsType as string as CustomerContactType,
      ),
      trusteeContactTypes: value.wsTrusteeContactTypeList == null ? [] : value.wsTrusteeContactTypeList.map(
        wsType => wsType as string as TrusteeContactType,
      ),
      withCustomerContact: value.withCustomerContact,
      withTrusteeContact: value.withTrusteeContact,
      withLifeIsLifeAdmin: value.withLifeIsLifeAdmin,
      exactEmail: value.exactEmail,
      exactPhone: value.exactPhone,
      exactNationalRegister: value.exactNationalRegister,

      customerContactCustomerSearch: CustomerSearchConverter.toCustomerSearch(value.wsCustomerContactCustomerSearch),
      trusteeContactTrusteeSearch: TrusteeSearchConverter.toTrusteeSearch(value.wsTrusteeContactTrusteeSearch),
    };
  }

  static toWsContactSearch(value: ContactSearch): WsContactSearch {
    if (value == null) {
      return undefined;
    }
    return {
      exactContactRef: value.exactContactRef,
      trusteeRef: value.trusteeRef,
      contactIdBlackList: value.contactIdBlackList,
      cityContains: value.cityContains,
      zipContains: value.zipContains,
      customersTrusteeRef: value.customerTrusteeRef,
      internal: value.internalOptional,
      companyContains: value.companyContains,
      lastNameContains: value.lastNameContains,
      firstNameContains: value.firstNameContains,
      anyNameContains: value.anyNameContains,
      domain: value.domain,
      customerRef: value.customerRef,
      idExpiryFromDate: DateConverterUtils.formatDateToIsoDate(value.idExpiryFromDate),
      idExpiryToDate: DateConverterUtils.formatDateToIsoDate(value.idExpiryToDate),
      wsCustomerContactTypeList: value.customerContactTypes == null ? [] : value.customerContactTypes.map(
        wsType => wsType as string as WsCustomerContactType,
      ),
      wsTrusteeContactTypeList: value.trusteeContactTypes == null ? [] : value.trusteeContactTypes.map(
        wsType => wsType as string as WsTrusteeContactType,
      ),
      withCustomerContact: value.withCustomerContact,
      withTrusteeContact: value.withTrusteeContact,
      withLifeIsLifeAdmin: value.withLifeIsLifeAdmin,
      exactEmail: value.exactEmail,
      exactPhone: value.exactPhone,
      exactNationalRegister: value.exactNationalRegister,


      wsCustomerContactCustomerSearch: CustomerSearchConverter.toWsCustomerSearch(value.customerContactCustomerSearch),
      wsTrusteeContactTrusteeSearch: TrusteeSearchConverter.toWsTrusteeSearch(value.trusteeContactTrusteeSearch),
    };
  }

}
