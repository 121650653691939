import {Injectable} from '@angular/core';
import {RequestService} from '../../service/request.service';
import {Observable} from 'rxjs';
import {WsMyPensionContactContext, WsMyPensionContext, WsMyPensionInitContext} from '@lifeislife/lifeislife-ws-api';
import {HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {FrontendAppConfigKey} from '../../../domain/config/frontend-app-config-key';
import {AppConfigService} from '../../../service/config/app-config.service';


@Injectable({
  providedIn: 'root',
})
export class UnrestictedRetireClient {

  constructor(private requestService: RequestService,
              private appConfigService: AppConfigService,

  ) {
  }

  getInitContext$(trusteeKeyOrName: string, phoneCountry: string, phoneNumber: string): Observable<WsMyPensionInitContext> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/unrestricted/retire/phone/${phoneCountry}/${phoneNumber}`;
    return this.requestService.sendRequest<WsMyPensionInitContext>({
      method: 'GET',
      url: url,
      params: new HttpParams({
        fromObject: {
          trusteeKeyOrName: trusteeKeyOrName,
        },
      }),
    });
  }

  getItsMeStatus$(initContext: WsMyPensionInitContext): Observable<boolean> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/unrestricted/retire/itsme/status`;
    return this.requestService.sendRequest({
      method: 'POST',
      url: url,
      body: initContext,
      responseType: 'text',
    }).pipe(
      map(r => r === 'true'),
    );
  }


  authenticate$(initContext: WsMyPensionInitContext): Observable<WsMyPensionContext> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/unrestricted/retire/auth`;
    return this.requestService.sendRequest<WsMyPensionContext>({
      method: 'POST',
      url: url,
      body: initContext,
    });
  }

  /**
   * returns a frontend url containing an authcode to exchange
   */
  sendMostRecentStatusPdfByMail$(contactContext: WsMyPensionContactContext): Observable<string> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/unrestricted/retire/pdf/mail`;
    return this.requestService.sendRequest({
      method: 'POST',
      url: url,
      body: contactContext,
      responseType: 'text',
    });
  }


  /**
   * returns a frontend url containing an authcode to exchange
   */
  downloadPensionStatus$(contactContext: WsMyPensionContactContext): Observable<string> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/unrestricted/retire/pensionStatus`;
    return this.requestService.sendRequest({
      method: 'POST',
      url: url,
      body: contactContext,
      responseType: 'text',
    });
  }
}
