import {Injectable} from '@angular/core';
import {WsRef, WsSigningPackageFile} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {ObjectConverterUtil} from '../object-converter-util';
import {SearchResult} from '../../client/domain/search/search-result';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SigningPackageFileWsClient} from '../../client/resources/front/file/signing-package-file-ws-client';
import {SigningPackageFile} from '../../domain/file/signing-package-file';
import {SigningPackageFileSearch} from '../../domain/file/signing-package-file-search';
import {StoredFileWsClient} from '../../client/resources/front/file/stored-file-ws-client';
import {StoredFile} from '../../domain/file/stored-file';
import {StoredFileConverter} from './stored-file.converter';
import {SigningPackageFileConverter} from './signing-package-file-converter';

@Injectable({
  providedIn: 'root',
})
export class SigningPackageFileService {


  constructor(private client: SigningPackageFileWsClient,
              private fileClient: StoredFileWsClient,
  ) {
  }

  clearSigningPackageFileCache(ref: Ref<SigningPackageFile>) {
    this.client.doClearCache(ref.id);
  }

  getSigningPackageFile$(ref: Ref<SigningPackageFile>): Observable<SigningPackageFile> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    // No cache: always fetch fresh file to get fresh signed status.
    // for packages, cache is cleared manually
    return this.client.doFetch(ref.id).pipe(
      map(value => SigningPackageFileConverter.convertIn(value)),
    );
  }

  searchSigningPackageFiles$(signingPackageFileFilter: SigningPackageFileSearch, pagination: SimplePagination)
    : Observable<SearchResult<SigningPackageFile>> {
    const wsSearch = SigningPackageFileConverter.convertFilterOut(signingPackageFileFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  saveSigningPackageFile$(signingPackageFile: SigningPackageFile): Observable<any> {
    const wsSigningPackageFile = SigningPackageFileConverter.convertOut(signingPackageFile);
    return this.client.doSave(wsSigningPackageFile);
  }

  deleteSigningPackageFile$(ref: Ref<SigningPackageFile>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }


  checkSigningPackageFileSigner$(ref: Ref<SigningPackageFile>): Observable<SigningPackageFile> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.getSigningPackageFile$(ref).pipe(
      tap(p => {
        if (p.signedFileRef) {
          this.fileClient.doClearCache(p.signedFileRef.id);
        }
      }),
      switchMap(p => this.client.docCheckSigner$(p.id)),
      switchMap(r => this.getSigningPackageFile$(r)),
    );
  }


  getStoredFile$(ref: Ref<SigningPackageFile>): Observable<StoredFile> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }

    return this.client.getFile(ref.id).pipe(
      map(wsFile => StoredFileConverter.convertIn(wsFile)),
    );
  }

  getStoredFileDownloadUrl$(ref: Ref<SigningPackageFile>, contentDisposition?: 'inline' | 'attachment'): Observable<string> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }

    return this.client.getFileDownloadUrl$(ref.id, contentDisposition);
  }


  serializeFilter(signingPackageFileFilter: SigningPackageFileSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(signingPackageFileFilter);
    const WsSigningPackageFileSearch = SigningPackageFileConverter.convertFilterOut(signingPackageFileFilter);
    return JSON.stringify(WsSigningPackageFileSearch);
  }


  deserializeFilter(valueString: string | null): SigningPackageFileSearch | null {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: any = JSON.parse(valueString);
      const filter = SigningPackageFileConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsSigningPackageFile>>): Observable<SearchResult<SigningPackageFile>> {
    const rowTasks = results.list.map(ref => this.getSigningPackageFile$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<SigningPackageFile>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<SigningPackageFile>>>{
          list: rows,
        }),
      ));
  }

}
