import {CustomerEventSubscription} from '../../domain/event/customer-event-subscription';
import {WsCustomerEventSubscription, WsCustomerEventSubscriptionSearch, WsCustomerEventType} from '@lifeislife/lifeislife-ws-api';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {CustomerEventType} from '../../domain/event/customer-event-type';
import {CustomerEventSubscriptionSearch} from '../../domain/event/customer-event-subscription-search';

export class CustomerEventSubscriptionConverter {

  static convertIn(value: WsCustomerEventSubscription): CustomerEventSubscription {
    return {
      id: value.id,
      customerRef: value.customerWsRef,
      eventType: EnumConverterUtils.convertValue(value.eventType, CustomerEventType),
      subscriberContactRef: value.subscriberContactWsRef,
      version: value.version,
    };
  }

  static convertOut(value: CustomerEventSubscription): WsCustomerEventSubscription {
    return {
      id: value.id,
      customerWsRef: value.customerRef,
      eventType: EnumConverterUtils.convertValue(value.eventType, WsCustomerEventType),
      subscriberContactWsRef: value.subscriberContactRef,
      version: value.version,
    };
  }


  static convertFilterIn(value?: WsCustomerEventSubscriptionSearch): CustomerEventSubscriptionSearch {
    if (value == null) {
      return undefined;
    }
    return {
      customerWsRef: value.customerWsRef,
      eventType: EnumConverterUtils.convertValue(value.eventType, CustomerEventType),
      subscriberContactWsRef: value.subscriberContactWsRef,
    };
  }

  static convertFilterOut(value: CustomerEventSubscriptionSearch): WsCustomerEventSubscriptionSearch {
    if (value == null) {
      return undefined;
    }
    return {
      customerWsRef: value.customerWsRef,
      eventType: EnumConverterUtils.convertValue(value.eventType, WsCustomerEventType),
      subscriberContactWsRef: value.subscriberContactWsRef,
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsCustomerEventSubscription]: keyof CustomerEventSubscription } {
    return {
      id: 'id',
      version: 'version',
      subscriberContactWsRef: 'subscriberContactRef',
      eventType: 'eventType',
      customerWsRef: 'customerRef',
    };
  }

}
