<div class="country-select">
  <p-autoComplete [ngModel]="selectedCountryItem$ | async"
                  (ngModelChange)="onChange($event)"
                  (onClear)="onClear()"
                  dataKey="value"
                  field="label"
                  [suggestions]="suggestions"
                  [dropdown]="showButton"
                  [readonly]="disabled"
                  [size]="25"
                  [minLength]="1"
                  placeholder="Faites votre choix"
                  (completeMethod)="onQuery($event)"
                  (onDropdownClick)="onQueryAll()"
                  [inputStyleClass]="hasError ? 'ng-dirty ng-invalid' : '' "
                  #countryDropDown
  >
  </p-autoComplete>
</div>
