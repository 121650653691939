import {WsCustomerType} from '@lifeislife/lifeislife-ws-api';
import {CustomerType} from '../../domain/customer/customer-type';

export class CustomerTypeConverter {

  static convertIn(value: WsCustomerType): CustomerType {
    return {
      id: value.id,
      name: value.name,
      templateCustomerRef: value.templateCustomerRef,
    };
  }

  static convertOut(value: CustomerType): WsCustomerType {
    return {
      id: value.id,
      name: value.name,
      templateCustomerRef: value.templateCustomerRef,
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsCustomerType]: keyof CustomerType } {
    return {
      id: 'id',
      name: 'name',
      templateCustomerRef: 'templateCustomerRef',
    };
  }

}
