<span class="customer lifeislife-inline-label"
      [class.wrap]="wrap"
      [class.wrap-text]="wrapText"
      *ngIf="(customerName$ | async) != null"
>
  <div class="label" *ngIf="showLabel"
       libUpperCase
       title="Nom du client" i18n-title>
    <span>
      {{ customerName$ | async }}
    </span>
  </div>

  <a class="lifeislife-jump-link" *ngIf="showJumpLink && (jumpCustomerLinkVisible$ | async)"
     [routerLink]="jumpCustomerRouterLink$ | async"
     (click)="onClick($event)"
     title="Vers la fiche client" i18n-title
  >
    <i class="fa fa-external-link-square"></i>
  </a>

  <div class="trustee-label" *ngIf="showTrusteeName"
       title="Fiduciaire du client" i18n-title>
    <app-trustee [ref]="trusteeRef$ | async"
                 [showJumpLink]="showJumpLink"
                 [wrap]="wrap"
                 [wrapText]="wrapText"
    ></app-trustee>
  </div>

  <div class="contact-name" *ngIf="showContactNames"
       title="Contact principal du client" i18n-title>
    <app-contact-view [ref]="contactRef$ | async"
                      [showJumpLink]="showJumpLink"
                      [wrap]="wrap"
                      [wrapText]="wrapText"
    ></app-contact-view>
  </div>

</span>
