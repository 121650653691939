<div class="trustee-contact-select">
  <app-contact-dropdown-select
    [showCompanyName]="false"
    [ngModel]="selectedValue"
    (ngModelChange)="onChange($event)"
    [filter]="contactFilter$ | async"
    [includeNoSelectionOption]="includeNoSelectionOption"
    [noSelectionLabel]="noSelectionLabel"
    [hasError]="hasError"
    [disabled]="disabled"
  ></app-contact-dropdown-select>
  <!-- TODO: use ng-content & to be able to use <app-trustee-contact-multi-view> in the dropdown -->
</div>
