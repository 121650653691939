import {Injectable} from '@angular/core';
import {WsRef, WsTrustee} from '@lifeislife/lifeislife-ws-api';
import {RequestService} from '../../service/request.service';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {TrusteeWsClient} from './trustee-ws-client';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {SearchResult} from '../../domain/search/search-result';
import {AppConfigService} from '../../../service/config/app-config.service';
import {FrontendAppConfigKey} from '../../../domain/config/frontend-app-config-key';

@Injectable({
  providedIn: 'root',
})
export class TrusteePartnersWsClient {
  constructor(
    private requestService: RequestService,
    private authService: AuthProvider,
    private appConfigService: AppConfigService,
    private trusteeClient: TrusteeWsClient,
  ) {
  }

  searchPartnersRefs(trusteeRef: WsRef<WsTrustee>): Observable<WsRef<WsTrustee>[]> {
    if (trusteeRef == null) {
      return of([]);
    }
    const url = this.getResourceUrl(trusteeRef) + '/search';

    return this.requestService.sendRequest<WsRef<WsTrustee>[]>({
      method: 'POST', url: url,
    }, this.authService.getAuth());
  }

  searchPartners(trusteeRef: WsRef<WsTrustee>): Observable<SearchResult<WsTrustee>> {
    return this.searchPartnersRefs(trusteeRef)
      .pipe(
        map(refs => refs.map(ref => this.trusteeClient.doGet(ref.id))),
        mergeMap(refTasks => refTasks == null ? of([]) : forkJoin(refTasks)),
        map(list => {
          const result = new SearchResult<WsTrustee>();
          result.list = list;
          result.count = list.length;
          return result;
        }),
      );
  }

  private getResourceUrl(trusteeRef: WsRef<WsTrustee>): string {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    return wsUri + '/front/trustee/' + trusteeRef.id + '/partners';
  }

}
