import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LifeislifeContactComponent } from './lifeislife-contact.component';



@NgModule({
  declarations: [LifeislifeContactComponent],
  imports: [
    CommonModule
  ],
  exports: [LifeislifeContactComponent]
})
export class LifeislifeContactModule { }
