import {WsMail, WsMailSearch} from '@lifeislife/lifeislife-ws-api';
import {Mail} from '../../domain/mail/mail';
import {FlavouredMessageConverter} from './flavoured-message-converter';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {CustomerSearchConverter} from '../customer/customer-search.converter';
import {MailSearch} from '../../domain/mail/mail-search';

export class MailConverter {


  static convertIn(value: WsMail): Mail {
    return {
      id: value.id,
      customerRef: value.customerRef,
      from: value.from,
      to: value.to,
      cc: value.cc,
      bcc: value.bcc,
      subject: value.subject,
      messages: FlavouredMessageConverter.convertMessagesIn(value.textMessagesList),
      sendTime: DateConverterUtils.parseIsoDateTime(value.sentTime),
      trusteeRef: value.trusteeRef,
      messageTypeName: value.messageTypeName,
      messageId: value.messageId,
      mailBatchRef: value.mailBatchRef,
      error: value.error,
      simulation: value.simulation,
    };
  }

  static convertFilterIn(value?: WsMailSearch): MailSearch {
    if (value == null) {
      return undefined;
    }
    return {
      customerRef: value.customerRef,
      customerSearch: CustomerSearchConverter.toCustomerSearch(value.customerSearch),
      delivered: value.delivered,
      fromDate: DateConverterUtils.parseIsoDateTime(value.fromDate),
      mailBatchRef: value.mailBatchWsRef,
      messageContains: value.messageContains,
      messageId: value.messageId,
      messageIdContains: value.messageIdContains,
      messageTypeNamess: value.messageTypesNames == null ? null : value.messageTypesNames,
      subjectContains: value.subjectContains,
      toDate: DateConverterUtils.parseIsoDateTime(value.toDate),
    };
  }

  static convertFilterOut(value: MailSearch): WsMailSearch {
    if (value == null) {
      return undefined;
    }
    return {
      trusteeRef: null,
      customerRef: value.customerRef,
      customerSearch: CustomerSearchConverter.toWsCustomerSearch(value.customerSearch),
      delivered: value.delivered,
      fromDate: DateConverterUtils.formatDateToIsoDateTime(value.fromDate),
      mailBatchWsRef: value.mailBatchRef,
      messageContains: value.messageContains,
      messageId: value.messageId,
      messageIdContains: value.messageIdContains,
      messageTypesNames: value.messageTypeNamess,
      subjectContains: value.subjectContains,
      toDate: DateConverterUtils.formatDateToIsoDateTime(value.toDate),
    };
  }

}
