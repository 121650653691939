import {Component, Input, OnInit} from '@angular/core';
import {LifeislifeControlListener} from '../../lifeislife-control-listener';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'llc-iban-input',
  templateUrl: './iban-input.component.html',
  styleUrls: ['./iban-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: IbanInputComponent,
    multi: true,
  }],
})
export class IbanInputComponent extends LifeislifeControlListener<string> implements OnInit {

  @Input()
  disabled: boolean;
  @Input()
  hasError: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
