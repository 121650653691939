import {Injectable} from '@angular/core';
import {WsLegalPensionCareerDetails, WsRef} from '@lifeislife/lifeislife-ws-api';
import {RequestService} from '../../../service/request.service';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {FrontendResourceType} from '../../../../util/cache/cache-resource-type';

@Injectable({
  providedIn: 'root',
})
export class LegalPensionCareerDetailsWsClient extends CachedWsResourceClient<WsLegalPensionCareerDetails, WsRef<WsLegalPensionCareerDetails>> {

  constructor(
    private injectedRequestService: RequestService,
    private injectectedCacheService: ResourceCacheService,
    private injectedAuthService: AuthProvider,
    private injectedAppConfigService: AppConfigService,
  ) {
    super('/front/legalPensionCareerDetails', injectedAppConfigService, injectedRequestService, injectedAuthService,
      FrontendResourceType.LEGAL_PENSION_CAREER_DETAILS, injectectedCacheService);
  }

}
