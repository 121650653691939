import {Injectable} from '@angular/core';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {RequestService} from '../../../service/request.service';
import {WsDaoEventEntityType, WsRef, WsTicket, WsTicketCustomer, WsTicketStatus} from '@lifeislife/lifeislife-ws-api';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TicketWsClient extends CachedWsResourceClient<WsTicket, WsRef<WsTicket>> {

  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private injectedAppConfigService: AppConfigService) {
    super('/front/ticket', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.TICKET, injectectedCacheService);
  }

  updateTicketStatus$(ticketId: number, wsStatus: WsTicketStatus): Observable<WsRef<WsTicket>> {
    const wsUrl = `${this.getResourceUrl()}/${ticketId}/status/${wsStatus}`;
    return this.injectedRequestService.sendRequest<WsRef<WsTicket>>({
      throughServiceWorker: false,
      method: 'POST',
      url: wsUrl,
    }, this.injectedAuthService.getAuth());
  }

  addTicketCustomer$(ticketId: number, customerId: number): Observable<WsRef<WsTicketCustomer>> {
    const wsUrl = `${this.getResourceUrl()}/${ticketId}/customer/${customerId}`;
    return this.injectedRequestService.sendRequest<WsRef<WsTicket>>({
      throughServiceWorker: false,
      method: 'POST',
      url: wsUrl,
    }, this.injectedAuthService.getAuth());
  }

  getNewTicketFilePath(id: number): string {
    return `${this.resourcePath}/${id}/file`;
  }
}
