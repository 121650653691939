import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {MainModule} from '../../main.module';
import {TreeModule} from 'primeng/tree';
import {MultiSelectModule} from 'primeng/multiselect';
import {MessageModule} from 'primeng/message';
import {InputSwitchModule} from 'primeng/inputswitch';
import {DropdownModule} from 'primeng/dropdown';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {DialogModule} from 'primeng/dialog';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {MainTrusteeComponentsModule} from '../trustee/main-trustee-components.module';
import {MainCountryModule} from '../country/main-country.module';
import {CustomerSelectComponent} from './customer-select/customer-select.component';
import {CustomerMultiSelectComponent} from './customer-multi-select/customer-multi-select.component';
import {CustomerCopyFormComponent} from './customer-copy-form/customer-copy-form.component';
import {CustomerCopyDialogComponent} from './customer-copy-dialog/customer-copy-dialog.component';
import {CustomerComponent} from './customer/customer.component';
import {CustomerTreeComponent} from './customer-tree/customer-tree/customer-tree.component';
import {CustomerStatusComponent} from './customer-status/customer-status.component';
import {CustomerStatusMultiSelectComponent} from './customer-status-multi-select/customer-status-multi-select.component';
import {CustomerTypeComponent} from './customer-type/customer-type.component';
import {CustomerTypeMultiSelectComponent} from './customer-type-multi-select/customer-type-multi-select.component';
import {CustomerTypeSelectComponent} from './customer-type-select/customer-type-select.component';
import {CustomerStatusSelectComponent} from './customer-status-select/customer-status-select.component';
import {PaymentTypeSelectComponent} from './payment-type-select/payment-type-select.component';
import {PaymentTypeMultiSelectComponent} from './payment-type-multi-select/payment-type-multi-select.component';
import {RouterModule} from '@angular/router';
import {DateModule} from '@lifeislife/lifeislife-components';
import {MainContactComponentsModule} from '../contact/main-contact-components.module';
import {CustomerInviteFormComponent} from './customer-invite-form/customer-invite-form.component';


@NgModule({
  declarations: [
    CustomerComponent,
    CustomerCopyDialogComponent,
    CustomerCopyFormComponent,
    CustomerMultiSelectComponent,
    CustomerSelectComponent,
    CustomerTreeComponent,

    CustomerStatusComponent,
    CustomerStatusSelectComponent,
    CustomerStatusMultiSelectComponent,

    CustomerTypeComponent,
    CustomerTypeSelectComponent,
    CustomerTypeMultiSelectComponent,
    PaymentTypeSelectComponent,
    PaymentTypeMultiSelectComponent,
    CustomerInviteFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    MainModule,
    DateModule,
    TriStateCheckboxModule,
    MainTrusteeComponentsModule,
    MainCountryModule,
    DialogModule,
    InputSwitchModule,
    AutoCompleteModule,
    MessageModule,
    TreeModule,
    DropdownModule,
    MultiSelectModule,
    RouterModule,
    MainContactComponentsModule,
  ],
  exports: [
    CustomerComponent,
    CustomerCopyDialogComponent,
    CustomerCopyFormComponent,
    CustomerMultiSelectComponent,
    CustomerSelectComponent,
    CustomerTreeComponent,

    CustomerStatusComponent,
    CustomerStatusSelectComponent,
    CustomerStatusMultiSelectComponent,

    CustomerTypeComponent,
    CustomerTypeSelectComponent,
    CustomerTypeMultiSelectComponent,
    PaymentTypeSelectComponent,
    PaymentTypeMultiSelectComponent,
    CustomerInviteFormComponent,
  ],
})
export class MainCustomerModule {
}
