import {WsPensionCareerStatus} from '@lifeislife/lifeislife-ws-api';
import {PensionCareerStatus} from '../../domain/retire/pension-career-status';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';

export class PensionCareerStatusConverter {

  static convertIn(wsStatus: WsPensionCareerStatus): PensionCareerStatus {
    if (wsStatus == null) {
      return null;
    }
    return {
      childrenCareKnown: wsStatus.childrenCareKnown,
      contactRef: wsStatus.contactWsRef,
      creationDate: DateConverterUtils.parseIsoDate(wsStatus.creationDate),
      displomaRequired: wsStatus.displomaRequired,
      endYear: wsStatus.endYear,
      id: wsStatus.id,
      legalPensionAge: wsStatus.legalPensionAge,
      legalPensionYear: wsStatus.legalPensionYear,
      militaryService: wsStatus.militaryService,
      missingHistoryCapello: wsStatus.missingHistoryCapello,
      pensionee: wsStatus.pensionee,
      startYear: wsStatus.startYear,
    };
  }


  static convertOut(careerStatus: PensionCareerStatus): WsPensionCareerStatus {
    if (careerStatus == null) {
      return null;
    }
    return {
      childrenCareKnown: careerStatus.childrenCareKnown,
      contactWsRef: careerStatus.contactRef,
      creationDate: DateConverterUtils.formatDateToIsoDate(careerStatus.creationDate),
      displomaRequired: careerStatus.displomaRequired,
      endYear: careerStatus.endYear,
      id: careerStatus.id,
      legalPensionAge: careerStatus.legalPensionAge,
      legalPensionYear: careerStatus.legalPensionYear,
      militaryService: careerStatus.militaryService,
      missingHistoryCapello: careerStatus.missingHistoryCapello,
      pensionee: careerStatus.pensionee,
      startYear: careerStatus.startYear,
    };
  }
}
