import {HttpResponse} from '@angular/common/http';
import {SearchResult} from '../domain/search/search-result';

/**
 * Created by cghislai on 09/10/16.
 */

export class SearchResultFactory {

  static createSearchResult<T>(response: HttpResponse<T[]>): SearchResult<T> {
    const countHeader = response.headers.get('x-resultcount');
    const results: T[] = response.body == null ? [] : response.body;

    const result = new SearchResult<T>();
    const resultCount = countHeader == null ? results.length : parseInt(countHeader, 10);
    result.count = resultCount;
    result.list = results;
    return result;
  }
}
