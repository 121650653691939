import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, publishReplay, refCount} from 'rxjs/operators';
import {TrusteeContactType} from '@lifeislife/lifeislife-domain';
import {TrusteeContactTypeItemsService} from '../trustee-contact-type-items.service';

@Component({
  selector: 'app-trustee-contact-type',
  templateUrl: './trustee-contact-type.component.html',
  styleUrls: ['./trustee-contact-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrusteeContactTypeComponent implements OnInit {

  @Input()
  set contactType(value: TrusteeContactType) {
    this.contactTypeSource$.next(value);
  }

  private contactTypeSource$ = new BehaviorSubject<TrusteeContactType>(null);

  label$: Observable<string>;

  constructor(private itemService: TrusteeContactTypeItemsService) {
  }

  ngOnInit() {
    this.label$ = this.contactTypeSource$.pipe(
      map(type => this.itemService.getTrusteeContactTypeLabel(type)),
      publishReplay(1), refCount(),
    );
  }

}
