import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SigningPackageService, SigningPackageStatus} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, publishReplay, refCount} from 'rxjs/operators';

@Component({
  selector: 'llc-signing-package-status',
  templateUrl: './signing-package-status.component.html',
  styleUrls: ['./signing-package-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SigningPackageStatusComponent implements OnInit {

  @Input()
  set status(value: SigningPackageStatus) {
    this.value$.next(value);
  }


  SigningPackageStatus = SigningPackageStatus;
  value$ = new BehaviorSubject<SigningPackageStatus | null>(null);
  label$: Observable<string>;

  constructor(
    private signingPackageService: SigningPackageService,
  ) {
    this.label$ = this.value$.pipe(
      map(v => this.signingPackageService.getStatusLabel(v)),
      publishReplay(1), refCount(),
    );
  }

  ngOnInit(): void {
  }

}
