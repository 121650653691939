import {Injectable} from '@angular/core';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {WsConstraintViolation, WsRef, WsTicketComment} from '@lifeislife/lifeislife-ws-api';
import {ObjectConverterUtil} from '../object-converter-util';
import {TicketCommentSearch} from '../../domain/ticket/ticket-comment-search';
import {TicketComment} from '../../domain/ticket/ticket-comment';
import {TicketCommentConverter} from './ticket-comment.converter';
import {TicketCommentWsClient} from '../../client/resources/front/ticketing/ticket-comment-ws-client';

@Injectable({
  providedIn: 'root',
})
export class TicketCommentService {

  constructor(private client: TicketCommentWsClient,
  ) {
  }

  getTicketComment$(ref?: Ref<TicketComment>): Observable<TicketComment> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doGet(ref.id).pipe(
      map(wsTicketComment => TicketCommentConverter.convertIn(wsTicketComment)),
    );
  }

  searchTicketComments$(ticketCommentFilter: TicketCommentSearch, pagination: SimplePagination)
    : Observable<SearchResult<TicketComment>> {
    const wsSearch = TicketCommentConverter.convertFilterOut(ticketCommentFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateTicketComment$(ticketComment: TicketComment): Observable<ValidationResult<TicketComment>> {
    const wsTicketComment = TicketCommentConverter.convertOut(ticketComment);
    return this.client.doValidate(wsTicketComment).pipe(
      map((results: WsTicketComment | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveTicketComment$(ticketComment: TicketComment): Observable<Ref<TicketComment>> {
    const wsTicketComment = TicketCommentConverter.convertOut(ticketComment);
    return this.client.doSave(wsTicketComment);
  }

  deleteTicketComment$(id: number): Observable<any> {
    return this.client.doRemove(id);
  }

  private createValidationResult(results: WsTicketComment | WsConstraintViolation[]): ValidationResult<TicketComment> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsTicketComment => TicketCommentConverter.convertIn(wsTicketComment),
      propertyNameMappings: TicketCommentConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsTicketComment>>): Observable<SearchResult<TicketComment>> {
    const rowTasks = results.list.map(ref => this.getTicketComment$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<TicketComment>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<TicketComment>>>{
          list: rows,
        }),
      ));
  }

}
