import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {delay, map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {CustomerContactService, CustomerContactType, CustomerContactTypeModel} from '@lifeislife/lifeislife-domain';

@Component({
  selector: 'app-customer-contact-type',
  templateUrl: './customer-contact-type.component.html',
  styleUrls: ['./customer-contact-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerContactTypeComponent implements OnInit {

  @Input()
  set contactType(value: CustomerContactType) {
    this.contactTypeSource$.next(value);
  }

  @Input()
  showInfoBadges = false;

  contactTypeSource$ = new BehaviorSubject<CustomerContactType>(null);

  model$: Observable<CustomerContactTypeModel>;
  customerDataAccess$: Observable<boolean>;
  internal$: Observable<boolean>;
  label$: Observable<string>;

  constructor(private customerContactService: CustomerContactService) {
  }

  ngOnInit() {
    this.model$ = this.contactTypeSource$.pipe(
      switchMap(t => this.customerContactService.getContactTypeModel$(t)),
      publishReplay(1), refCount(),
    );
    this.customerDataAccess$ = this.model$.pipe(
      map(m => m == null ? false : m.customerDataAccess),
      publishReplay(1), refCount(),
    );
    this.internal$ = this.model$.pipe(
      map(m => m == null ? false : m.customerInternal),
      publishReplay(1), refCount(),
    );
    this.label$ = this.model$.pipe(
      map(m => m == null ? '' : m.label),
      publishReplay(1), refCount(),
    );

  }

}
