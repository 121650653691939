import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsCustomerNote, WsCustomerNoteSearch, WsRef} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {CustomerNoteConverter} from './customer-note.converter';
import {CustomerNote} from '../../domain/customer-note/customer-note';
import {CustomerNoteFilter} from '../../domain/customer-note/customer-note-filter';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {CustomerNoteWsClient} from '../../client/resources/front/customer-note-ws-client';
import {Trustee} from '../../domain/trustee/trustee';

@Injectable({
  providedIn: 'root',
})
export class CustomerNoteService {


  constructor(private client: CustomerNoteWsClient,
  ) {
  }

  getCustomerNote$(ref: Ref<CustomerNote>): Observable<CustomerNote> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doGet(ref.id).pipe(
      map(wsCustomerNote => CustomerNoteConverter.convertIn(wsCustomerNote)),
    );
  }

  searchCustomerNotes$(customerNoteFilter: CustomerNoteFilter, pagination: SimplePagination): Observable<SearchResult<CustomerNote>> {
    const wsSearch = CustomerNoteConverter.convertFilterOut(customerNoteFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateCustomerNote$(customerNote: CustomerNote): Observable<ValidationResult<CustomerNote>> {
    const wsCustomerNote = CustomerNoteConverter.convertOut(customerNote);
    return this.client.doValidate(wsCustomerNote).pipe(
      map((results: WsCustomerNote | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveCustomerNote$(customerNote: CustomerNote): Observable<any> {
    const wsCustomerNote = CustomerNoteConverter.convertOut(customerNote);
    return this.client.doSave(wsCustomerNote);
  }

  deleteCustomerNote$(ref: Ref<CustomerNote>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  serializeFilter(customerNoteFilter: CustomerNoteFilter): string {
    ObjectConverterUtil.cleanUnsetFilterValues(customerNoteFilter);
    const wsCustomerNoteSearch = CustomerNoteConverter.convertFilterOut(customerNoteFilter);
    return JSON.stringify(wsCustomerNoteSearch);
  }


  deserializeFilter(valueString: string | null): CustomerNoteFilter {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: any = JSON.parse(valueString);
      const filter = CustomerNoteConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  createFilter(trusteeRef?: Ref<Trustee>): CustomerNoteFilter {
    return CustomerNoteConverter.convertFilterIn({
      trusteeRef: trusteeRef == null ? null : trusteeRef,
    } as WsCustomerNoteSearch);
  }

  private createValidationResult(results: WsCustomerNote | WsConstraintViolation[]): ValidationResult<CustomerNote> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsCustomerNote => CustomerNoteConverter.convertIn(wsCustomerNote),
      propertyNameMappings: CustomerNoteConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsCustomerNote>>): Observable<SearchResult<CustomerNote>> {
    const rowTasks = results.list.map(ref => this.getCustomerNote$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<CustomerNote>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<CustomerNote>>>{
          list: rows,
        }),
      ));
  }

}
