import {Injectable} from '@angular/core';
import {WsCustomerFrontCustomerSourcing, WsRetirementSimulatorPotentialCustomer} from '@lifeislife/lifeislife-ws-api';
import {RequestService} from '../../../service/request.service';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {Observable} from 'rxjs';
import {FrontendAppConfigKey} from '../../../../domain/config/frontend-app-config-key';
import {AppConfigService} from '../../../../service/config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerSourcingWsClient {

  constructor(private requestService: RequestService,
              private authProvider: AuthProvider,
              private appConfigService: AppConfigService,
  ) {
  }

  notifyPotentialCustomerFromRetirementSimulator$(value: WsRetirementSimulatorPotentialCustomer): Observable<any> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/front/customer/sourcing/retirement-simulator`;
    return this.requestService.sendRequest<any>({
      method: 'POST',
      url: url,
      body: value,
    }, this.authProvider.getAuth());
  }

  notifyCustomerFrontSourcing$(value: WsCustomerFrontCustomerSourcing): Observable<any> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/front/customer/sourcing/customer-front`;
    return this.requestService.sendRequest<any>({
      method: 'POST',
      url: url,
      body: value,
    }, this.authProvider.getAuth());
  }
}
