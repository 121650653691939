import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CurrencyPipe} from './currency.pipe';
import {MonthPipe} from './monthPipe';
import {RelativeDatePipe} from './relativeDate.pipe';
import {YearPipe} from './yearPipe';
import {TimePipe} from './timePipe';
import {DateModule} from '@lifeislife/lifeislife-components';

@NgModule({
  imports: [
    CommonModule,
    DateModule,
  ],
  declarations: [

    CurrencyPipe,
    MonthPipe,
    RelativeDatePipe,
    TimePipe,
    YearPipe,
  ],
  exports: [
    DateModule,
    CurrencyPipe,
    MonthPipe,
    RelativeDatePipe,
    TimePipe,
    YearPipe,
  ],
})
export class CommonPipesModule {

}
