import {FrontendAppSwitch} from '../../domain/config/frontend-app-switch';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {FrontendAppConfigKey} from '../../domain/config/frontend-app-config-key';
import {ApplicationConfigSettings} from '../../domain/config/application-config-settings';
import {ParsedConfigSettings} from '../../domain/config/parsed-config-settings';
import {Role} from '../../domain/role/role';
import {TrusteeContact} from '../../domain/trustee/trustee-contact';
import {CustomerContact} from '../../domain/customer-contact/customer-contact';
import {TrusteeContactType} from '../../domain/trustee/trustee-contact-type';
import {CustomerContactType} from '../../domain/customer-contact/customer-contact-type';

export class AppConfigConverter {


  static augmentCustomerContactBasedSettingsForCustomerContact(settings: ApplicationConfigSettings, customerContact: CustomerContact) {
    const safeSettings = settings || {};
    if (customerContact == null || customerContact.customerContactType == null) {
      return safeSettings;
    }
    const roleSwitchesGrants = safeSettings.customerContactGrantedSwitches || {};
    const roleGrantedSwitches = [];
    AppConfigConverter.fillCustomerContactGrantedSwitches(roleSwitchesGrants, customerContact.customerContactType, roleGrantedSwitches);

    const augmentedSwitches = Object.assign({}, safeSettings.switches);
    roleGrantedSwitches.forEach(roleSwitch => augmentedSwitches[roleSwitch] = true);
    return AppConfigConverter.augmentSettings(safeSettings, {
      switches: augmentedSwitches,
    });
  }

  static augmentTrusteeContactBasedSettingsForTrusteeContact(settings: ApplicationConfigSettings, trusteeContact: TrusteeContact) {
    const safeSettings = settings || {};
    if (trusteeContact == null || trusteeContact.trusteeContactType == null) {
      return safeSettings;
    }
    const roleSwitchesGrants = safeSettings.trusteeContactGrantedSwitches || {};
    const roleGrantedSwitches = [];
    AppConfigConverter.fillTrusteeContactGrantedSwitches(roleSwitchesGrants, trusteeContact.trusteeContactType, roleGrantedSwitches);

    const augmentedSwitches = Object.assign({}, safeSettings.switches);
    roleGrantedSwitches.forEach(roleSwitch => augmentedSwitches[roleSwitch] = true);
    return AppConfigConverter.augmentSettings(safeSettings, {
      switches: augmentedSwitches,
    });
  }

  static augmentRoleBasedSettingsForRoles(settings: ApplicationConfigSettings, roles: Role[]) {
    const safeSettings = settings || {};
    if (roles == null || roles.length === 0) {
      return safeSettings;
    }
    const roleSwitchesGrants = safeSettings.roleGrantedSwitches || {};
    const roleGrantedSwitches = [];
    roles.forEach(role => AppConfigConverter.fillRoleGrantedSwitches(roleSwitchesGrants, role, roleGrantedSwitches));

    const augmentedSwitches = Object.assign({}, safeSettings.switches);
    roleGrantedSwitches.forEach(roleSwitch => augmentedSwitches[roleSwitch] = true);
    return AppConfigConverter.augmentSettings(safeSettings, {
      switches: augmentedSwitches,
    });
  }

  static augmentTrusteeBasedSettingsForTrustee(settings: ApplicationConfigSettings, trusteeSettings: { [key: string]: string }) {
    const safeSettings = settings || {};
    if (trusteeSettings == null) {
      return safeSettings;
    }
    const splittedSettings = AppConfigConverter.splitSwitchOrConfig(trusteeSettings);
    return AppConfigConverter.augmentSettings(safeSettings, {
      switches: splittedSettings[0],
      config: splittedSettings[1],
    });
  }

  static augmentSettings(base: ApplicationConfigSettings, update: Partial<ApplicationConfigSettings>) {
    const safeSettings = base || {};
    const safeUpdate = update || {};
    const newConfig = Object.assign({}, safeSettings);
    if (safeUpdate.switches) {
      newConfig.switches = AppConfigConverter.updateAppSwitches(safeUpdate.switches, safeSettings.switches);
    }
    if (safeUpdate.config) {
      newConfig.config = AppConfigConverter.updateAppConfigs(safeUpdate.config, safeSettings.config);
    }
    if (safeUpdate.authConfig) {
      const newAuthConfig = Object.assign({}, newConfig.authConfig, safeUpdate.authConfig);
      newConfig.authConfig = newAuthConfig;
    }

    if (safeUpdate.roleGrantedSwitches) {
      newConfig.roleGrantedSwitches = Object.assign({}, safeSettings.roleGrantedSwitches, safeUpdate.roleGrantedSwitches);
    }
    if (safeUpdate.trusteeContactGrantedSwitches) {
      newConfig.trusteeContactGrantedSwitches = Object.assign({},
        safeSettings.trusteeContactGrantedSwitches, safeUpdate.trusteeContactGrantedSwitches);
    }
    if (safeUpdate.customerContactGrantedSwitches) {
      newConfig.customerContactGrantedSwitches = Object.assign({},
        safeSettings.customerContactGrantedSwitches, safeUpdate.customerContactGrantedSwitches);
    }

    return newConfig;
  }

  static parseSettings(settings: ApplicationConfigSettings): ParsedConfigSettings {
    if (settings == null) {
      return null;
    }
    const frontAppSwitches = AppConfigConverter.parseSwitches<FrontendAppSwitch>(settings.switches, FrontendAppSwitch);
    const frontConfig = AppConfigConverter.parseConfigKeyValues<FrontendAppConfigKey>(settings.config, FrontendAppConfigKey);

    const parsedConfig: ParsedConfigSettings = {
      settings: settings,
      frontConfig: frontConfig,
      frontSwitches: frontAppSwitches,
      authConfig: settings.authConfig,
    };
    return parsedConfig;
  }


  static parseEnabledSwicthesArray<T = FrontendAppSwitch>(dictionnary: { [p: string]: boolean }, switchType: any): T[] {
    const swicthesMap = AppConfigConverter.parseSwitches<T>(dictionnary, switchType);
    const arrayValue: T[] = [];
    for (const key of swicthesMap.keys()) {
      if (swicthesMap.get(key) === true) {
        arrayValue.push(key);
      }
    }
    return arrayValue;
  }


  static parseSwitches<T = FrontendAppSwitch>(dictionnary: { [p: string]: boolean }, switchType: any): Map<T, boolean> {
    const switches: Map<T, boolean> = new Map<T, boolean>();
    if (dictionnary == null) {
      return switches;
    }
    for (const key in dictionnary) {
      if (typeof key === 'string' && dictionnary.hasOwnProperty(key)) {
        const enabled = dictionnary[key];
        const appSwitch = EnumConverterUtils.convertValue(key, switchType, true);
        if (appSwitch) {
          switches.set(appSwitch, enabled);
          // } else {
          //   console.warn(`Invalid app switch: ${key}`);
        }
      }
    }
    return switches;
  }

  static parseConfigKeyValues<T = FrontendAppConfigKey>(dictionnary: { [p: string]: string }, keyType: any): Map<T, string> {
    const config = new Map<T, string>();
    if (dictionnary == null) {
      return config;
    }
    for (const key in dictionnary) {
      if (typeof key === 'string' && dictionnary.hasOwnProperty(key)) {
        const configKey = EnumConverterUtils.convertValue(key, keyType, true);
        if (configKey) {
          const value = dictionnary[key];
          config.set(configKey, `${value}`);
          // } else {
          //   console.warn(`Invalid app config key: ${key}`);
        }
      }
    }
    return config;
  }

  private static splitSwitchOrConfig(dictionnary: { [p: string]: string }): [
    { [key: string]: boolean }, { [key: string]: string }] {
    const switchmap = {};
    const configMap = {};

    for (const key in dictionnary) {
      if (typeof key === 'string' && dictionnary.hasOwnProperty(key)) {
        const value = dictionnary[key];
        if (value === 'true' || value === 'false') {
          const booleanValue = value === 'true';
          switchmap[key] = booleanValue;
        } else {
          configMap[key] = value;
        }
      }
    }
    return [switchmap, configMap];
  }


  private static updateAppSwitches(update: { [key: string]: boolean }, base: { [key: string]: boolean }) {
    const newSwitches = Object.assign({}, base, update);
    return newSwitches;
  }

  private static updateAppConfigs(configUpdate: { [key: string]: string }, base: { [key: string]: string }) {
    const newConfig = Object.assign({}, base, configUpdate);
    return newConfig;
  }

  private static fillRoleGrantedSwitches(grants: { [p: string]: string[] }, role: Role, roleGrantedSwitches: string[]) {
    const roleGrants = AppConfigConverter.getSwitchesForRole(role, grants);
    AppConfigConverter.appendSwitch(roleGrantedSwitches, ...roleGrants);
  }

  private static fillTrusteeContactGrantedSwitches(grants: { [p: string]: string[] },
                                                   trusteeContactType: TrusteeContactType,
                                                   roleGrantedSwitches: string[]) {
    const roleGrants = AppConfigConverter.getSwitchesForTrusteeContactType(trusteeContactType, grants);
    AppConfigConverter.appendSwitch(roleGrantedSwitches, ...roleGrants);
  }

  private static fillCustomerContactGrantedSwitches(grants: { [p: string]: string[] },
                                                    customerContactType: CustomerContactType,
                                                    roleGrantedSwitches: string[]) {
    const roleGrants = AppConfigConverter.getSwitchesForCustomerContactType(customerContactType, grants);
    AppConfigConverter.appendSwitch(roleGrantedSwitches, ...roleGrants);
  }

  private static appendSwitch(features: string[], ...featuresToAdd: string[]) {
    featuresToAdd.forEach(f => {
      if (features.indexOf(f) < 0) {
        features.push(f);
      }
    });
  }

  private static getSwitchesForRole(role: Role, grants: { [p: string]: string[] }): string[] {
    const allFeatures = Object.keys(grants);
    return allFeatures.filter(f => {
      const grantedRoles = grants[f] || [];
      return grantedRoles.indexOf(`${role}`) >= 0;
    });
  }

  private static getSwitchesForTrusteeContactType(trusteeContactType: TrusteeContactType, grants: { [p: string]: string[] }): string[] {
    const allFeatures = Object.keys(grants);
    return allFeatures.filter(f => {
      const grantedRoles = grants[f] || [];
      return grantedRoles.indexOf(`${trusteeContactType}`) >= 0;
    });
  }

  private static getSwitchesForCustomerContactType(customerContactType: CustomerContactType, grants: { [p: string]: string[] }): string[] {
    const allFeatures = Object.keys(grants);
    return allFeatures.filter(f => {
      const grantedRoles = grants[f] || [];
      return grantedRoles.indexOf(`${customerContactType}`) >= 0;
    });
  }

}
