import {Injectable} from '@angular/core';
import {TrusteeContactType} from '@lifeislife/lifeislife-domain';
import {SelectItem} from 'primeng/api';
import {EnumUtils} from '../../util/enum-utils';

@Injectable({
  providedIn: 'root',
})
export class TrusteeContactTypeItemsService {

  constructor() {
  }

  public createAllTrusteeContactTypeItems(options?: TrusteeContactType[]): SelectItem[] {
    const baseOptions = options || EnumUtils.listEnumMembers(TrusteeContactType);
    return baseOptions.map(type => this.createTrusteeContactTypeItem(type));
  }

  public createTrusteeContactTypeItem(contactType: TrusteeContactType): SelectItem {
    return {
      value: contactType,
      label: this.getTrusteeContactTypeLabel(contactType),
    };
  }

  public getTrusteeContactTypeLabel(contactType: TrusteeContactType): string {
    if (contactType == null) {
      return '';
    }
    switch (contactType) {
      case TrusteeContactType.ADMINISTRATIVE:
        return 'Administratif';
      case TrusteeContactType.OTHER:
        return 'Autre';
      case TrusteeContactType.ACCOUNTANT:
        return 'Comptable';
      case TrusteeContactType.LEGAL_REPRESENTATIVE:
        return 'Représenant légal';
      case TrusteeContactType.CUSTOMER_PROSPECT:
        return 'Client prospect';
      default:
        return `${contactType}`;
    }
  }
}
