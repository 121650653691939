import {WsDomainObject, WsRef} from '@lifeislife/lifeislife-ws-api';
import {HttpWsResourceClient} from './http-ws-resource-client';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {RequestService} from '../../service/request.service';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {HttpParams} from '@angular/common/http';
import {CacheResourceType} from '../../../util/cache/cache-resource-type';
import {ResourceCacheService} from '../../../util/cache/resource-cache.service';
import {WithId} from '../../domain/with-id';
import {AppConfigService} from '../../../service/config/app-config.service';

export class CachedWsResourceClient<ENTITY extends WsDomainObject & WithId, SEARCH_RESULT, REPORT_GROUP_SUMMARIES = any>
  extends HttpWsResourceClient<ENTITY, SEARCH_RESULT, REPORT_GROUP_SUMMARIES> {

  protected cacheService: ResourceCacheService;
  private resourceType: CacheResourceType;

  constructor(resourcePath: string,
              appConfigService: AppConfigService,
              requestService: RequestService,
              authProvider: AuthProvider,
              resourceType: CacheResourceType,
              cacheService: ResourceCacheService,
  ) {
    super(resourcePath, appConfigService, requestService, authProvider);
    if (this.authService == null || this.requestService == null) {
      throw new Error('Failed to initialize cached ws resource client');
    }
    this.resourceType = resourceType;
    this.cacheService = cacheService;
  }

  doClearWholeCache() {
    this.cacheService.clearWholeCache(this.resourceType);
  }

  doClearCache(id: number) {
    this.cacheService.clear(this.resourceType, id);
  }

  doPutInCache(id: number, entity: ENTITY) {
    this.cacheService.cacheValue(this.resourceType, id, entity);
  }

  doFetch(id: number): Observable<ENTITY> {
    return this.cacheService.getFromNetwork$<ENTITY>(this.resourceType, id, super.doGet(id, true));
  }

  doGet(id: number, forceFetch?: boolean): Observable<ENTITY> {
    if (forceFetch !== true) {
      return this.cacheService.getFromCacheOrNetwork$<ENTITY>(this.resourceType, id, super.doGet(id) as Observable<ENTITY>);
    }
    return this.doFetch(id);
  }


  doRemove(id: number, queryParams?: HttpParams): Observable<any> {
    return super.doRemove(id, queryParams)
      .pipe(tap(e => this.doClearCache(id)));
  }

  doUpdate(entity: ENTITY, params?: HttpParams): Observable<WsRef<ENTITY>> {
    return super.doUpdate(entity, params)
      .pipe(tap(ref => this.doClearCache(ref.id)));
  }

  protected clearAllCaches() {
    this.cacheService.clearWholeCache(this.resourceType);
  }

}
