import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  AuthCodeResolver,
  AuthProvider,
  AuthService,
  Base64Encoder,
  BrowserWindowRef,
  InMemoryResourceCache,
  KeyValueStore,
  LifeislifeDomainModule,
  LocalStorageKeyValueStoreService,
  ResourceCache,
  UserAuthService,
  UserAuthServiceBrowser,
  WebBase64EncoderService,
  WindowRef,
} from '@lifeislife/lifeislife-domain';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {UserThemeService} from './main/services/user-theme-service';
import {of} from 'rxjs';
import {ShellModule} from './shell/shell.module';
import {UserTheme} from './main/model/user-theme';
import {LifeislifeFrontErrorHandler} from './commons/services/error.handler';
import {AppConfigService} from '@lifeislife/lifeislife-domain';

export function UserThemeInitFactory(service: UserThemeService) {
  const factory = () => {
    const theme: UserTheme = service.loadStorageTheme() || service.getActiveTheme() || 'lifeislife' as UserTheme;
    service.setTheme(theme);
    return of(null).toPromise();
  };
  return factory;
}

export function AppConfigInitFactory(service: AppConfigService) {
  const factory = () => {
    return service.getInitialized$().toPromise();
  };
  return factory;
}


@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,

    LifeislifeDomainModule.forRoot(),
    ShellModule,

    AppRoutingModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
      scope: './',
    }),

    ToastModule,
  ],
  providers: [
    {provide: APP_INITIALIZER, useFactory: UserThemeInitFactory, deps: [UserThemeService], multi: true},
    {provide: APP_INITIALIZER, useFactory: AppConfigInitFactory, deps: [AppConfigService], multi: true},
    {provide: ErrorHandler, useClass: LifeislifeFrontErrorHandler},
    {provide: KeyValueStore, useClass: LocalStorageKeyValueStoreService},
    {provide: Base64Encoder, useClass: WebBase64EncoderService},
    {provide: ResourceCache, useClass: InMemoryResourceCache},
    {provide: UserAuthService, useClass: UserAuthServiceBrowser},
    {provide: WindowRef, useClass: BrowserWindowRef},
    AuthCodeResolver,
    MessageService,
    ConfirmationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
