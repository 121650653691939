/**
 * Created by cghislai on 02/07/16.
 */
import {ErrorHandler, Injectable, Injector, Optional} from '@angular/core';
import {WsFrontendError} from '@lifeislife/lifeislife-ws-api';
import {
  AppConfigService,
  AuthProvider,
  ErrorUtils,
  FrontendAppConfigKey,
  FrontendAppSwitch,
  RequestService, WindowRef,
} from '@lifeislife/lifeislife-domain';
import {AppHomeScreenInstallerService} from '@lifeislife/lifeislife-components';
import {Subject} from 'rxjs';
import {bufferWhen, debounceTime} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LifeislifeFrontErrorHandler implements ErrorHandler {

  private errorToSendQueue$ = new Subject<WsFrontendError>();

  constructor(@Optional()
              private appInstallService: AppHomeScreenInstallerService,
              private injector: Injector,
              private windowRef: WindowRef,
              private requestService: RequestService) {

    // Buffer errors to send to backend in case of bursts
    const bufferCloseTimes$ = this.errorToSendQueue$.pipe(
      debounceTime(1000),
    );
    this.errorToSendQueue$.pipe(
      bufferWhen(() => bufferCloseTimes$),
    ).subscribe(errors => this.sendErrors(errors));
  }

  handleError(error: any) {
    console.warn(error);
    const userUrl = window.location.toString();
    // FIXME: remove this hack once figured out the bug cause
    const uploadErrorDetails = error['uploadErrorDetails'];
    const isUploadError = uploadErrorDetails != null;

    const stackTraceTxt = uploadErrorDetails || ErrorUtils.createStackTrace(error);
    const message = error.message || '';
    const navigatorName = this.parseBrowser();


    const standalone = this.appInstallService == null ? null : this.appInstallService.isLaunchedFromHomeScreen();
    const configService = this.getConfigService();
    const appVersion = configService.getApplicationVersion();
    let appName = configService.getCurrentConfigValue(FrontendAppConfigKey.app_name) || 'UNKNOWN';
    if (standalone) {
      appName += ` - Standalone`;
    }

    if (ErrorUtils.isErrorToDiscard(error)) {
      return;
    }
    const sendReport = configService.isSwitchCurrentlyEnabled(FrontendAppSwitch.front_error_report);
    if (!sendReport) {
      return;
    }

    const frontendError: WsFrontendError = {
      errorStack: stackTraceTxt,
      message: message,
      url: userUrl,
      applicationName: appName,
      version: appVersion,
      navigator: navigatorName,
    };

    if (isUploadError) {
      this.sendErrors([frontendError]);
    } else {
      this.errorToSendQueue$.next(frontendError);
    }
  }

  private parseBrowser() {
    const window = this.windowRef.getWindow();
    if (window.navigator) {
      return `user agent: ${window.navigator.userAgent}, vendor: ${window.navigator.vendor}`;
    } else {
      return 'inconnu';
    }
  }


  private sendErrors(errors: WsFrontendError[]) {
    if (errors.length === 0) {
      return;
    }

    const configService = this.getConfigService();
    const authProvider = this.getAuthProvider();
    ErrorUtils.sendErrors(errors, authProvider, configService, this.requestService);
  }

  private getConfigService(): AppConfigService | null {
    return this.injector.get(AppConfigService, null);
  }

  private getAuthProvider(): AuthProvider | null {
    return this.injector.get(AuthProvider, null);
  }

}
