import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  Contact,
  ContactService,
  Ref,
  SigningPackageFile,
  SigningPackageFileSearch,
  SigningPackageFileService,
  SimplePaginationUtils,
  StoredFile,
} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {delay, map, publishReplay, refCount, switchMap, take, tap} from 'rxjs/operators';

@Component({
  selector: 'llc-file-list-item',
  templateUrl: './file-list-item.component.html',
  styleUrls: ['./file-list-item.component.scss'],
})
export class FileListItemComponent implements OnInit {

  @Input()
  set file(file: StoredFile) {
    this.file$.next(file);
  }

  @Input()
  showSize = true;
  @Input()
  disabled = true;
  @Input()
  showUpdate = true;
  @Input()
  showCreated = false;
  @Input()
  showDelete = false;
  @Input()
  showRename = false;
  @Input()
  showBadgeSigned = false;
  @Input()
  overlayAppendTo: any;
  @Input()
  showVisibleToCustomer = true;

  // Should the rename input field be displayed. This is delegated to the parent component so a single list-item
  // can be in 'rename' mode in the list
  @Input()
  renaming = false;
  @Output()
  deleteClick = new EventEmitter();
  @Output()
  fileRename = new EventEmitter<string>();
  @Output()
  renamingChange = new EventEmitter<boolean>();

  @Input()
  visibleToCustomer: boolean;
  @Output()
  visibleToCustomerChange = new EventEmitter<boolean>();

  file$ = new BehaviorSubject<StoredFile>(null);
  fileIcon$: Observable<string>;
  fileSignerName$: Observable<string>;
  fileSigningPackage$: Observable<SigningPackageFile | null>;
  fileExpectedSignerName$: Observable<string>;
  fileExpectedSignerMatches$: Observable<boolean>;

  newFileName: string;
  checkingSigner$ = new BehaviorSubject<boolean>(false);

  @ViewChild('renameInputCompnent', {static: false})
  private renameInputCompnent: ElementRef;

  constructor(
    private contactService: ContactService,
    private signginPackageFileService: SigningPackageFileService,
  ) {
  }

  ngOnInit() {
    this.fileIcon$ = this.file$.pipe(
      map(f => this.getFileIcon(f)),
      publishReplay(1), refCount(),
    );
    const fileSignerRef$: Observable<Ref<Contact> | null> = this.file$.pipe(
      map(f => f == null ? null : f.signer),
      publishReplay(1), refCount(),
    );
    this.fileSignerName$ = fileSignerRef$.pipe(
      switchMap(f => this.getContactName$(f)),
      publishReplay(1), refCount(),
    );
    this.fileSigningPackage$ = this.file$.pipe(
      switchMap(f => this.getSingleSigningPackage$(f)),
      publishReplay(1), refCount(),
    );
    const packageSignerRef$: Observable<Ref<Contact> | null> = this.fileSigningPackage$.pipe(
      map(f => f == null ? null : f.signerContactRef),
      publishReplay(1), refCount(),
    );
    this.fileExpectedSignerName$ = packageSignerRef$.pipe(
      switchMap(f => this.getContactName$(f)),
      publishReplay(1), refCount(),
    );
    this.fileExpectedSignerMatches$ = combineLatest([
      packageSignerRef$, fileSignerRef$,
    ]).pipe(
      map(r => this.checkSignersMatch(r[0], r[1])),
      publishReplay(1), refCount(),
    );

  }

  onDeleteClick(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.deleteClick.next(true);
  }

  onRenameClick(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.renamingChange.next(true);
    this.file$.pipe(
      take(1),
      delay(100), // Allow time for the form to be rendered
    ).subscribe(f => {
      this.newFileName = f.fileName;
      // Select file name without extension
      const extensionStartIndex = this.newFileName.lastIndexOf('.');
      if (this.renameInputCompnent) {
        const inputField: HTMLInputElement = this.renameInputCompnent.nativeElement;
        inputField.value = this.newFileName;
        inputField.focus();
        inputField.setSelectionRange(0, extensionStartIndex);
      }
    });
  }

  onFileRenameConfirm(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
    this.renamingChange.next(false);
    this.fileRename.next(this.newFileName);
    this.newFileName = null;
  }

  onFileRenameCancel(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
    this.renamingChange.next(false);
    this.newFileName = null;
  }

  discardClick(event: MouseEvent) {
    // Keep default: implicit form submit triggers a click event. But do not bubble to parent component,
    // so download is not triggered
    // event.preventDefault();
    event.stopImmediatePropagation();
  }

  onCheckSignatureClick(event: Event) {
    event.stopImmediatePropagation();

    this.checkingSigner$.next(true);
    this.fileSigningPackage$.pipe(
      take(1),
      switchMap(p => p == null ? of(null) : this.signginPackageFileService.checkSigningPackageFileSigner$(p)),
      switchMap(p => this.signginPackageFileService.getStoredFile$(p)),
    ).subscribe(storedFile => {
      this.file$.next(storedFile);
      this.checkingSigner$.next(false);
    }, e => {
      this.checkingSigner$.next(false);
    });
  }

  private getFileIcon(file: StoredFile) {
    if (file == null) {
      return 'fa fa-times';
    }
    if (file.mimeType == null) {
      return 'fa fa-file';
    }
    switch (file.mimeType) {
      case 'application/pdf':
        return 'fa fa-file-pdf-o';
      default:
        return 'fa fa-file-o';
    }
  }

  private getContactName$(contactRef: Ref<Contact>) {
    if (contactRef == null) {
      return of(null);
    }
    return this.contactService.getContact$(contactRef).pipe(
      map(c => this.contactService.getContactName(c, false)),
    );
  }

  private getSingleSigningPackage$(file: StoredFile) {
    if (file == null) {
      return of(null);
    }
    const fileSearch: SigningPackageFileSearch = {
      storedFileRef: {id: file.id},
    };
    return this.signginPackageFileService.searchSigningPackageFiles$(fileSearch, SimplePaginationUtils.newPagination(5)).pipe(
      map(r => r.count === 1 ? r.list[0] : null),
    );
  }

  private checkSignersMatch(expectedSignerRef: Ref<Contact> | null, actualSignerRef: Ref<Contact> | null): boolean {
    if (expectedSignerRef) {
      return actualSignerRef != null && expectedSignerRef.id === actualSignerRef.id;
    } else {
      return true;
    }
  }

  onVisibleToCustomerClick(event: MouseEvent) {
    event.stopImmediatePropagation();
    this.visibleToCustomerChange.next(!this.visibleToCustomer);
  }
}
