import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {SwPush} from '@angular/service-worker';
import {
  AppConfigService,
  ApplicationCompileTimeSettings,
  AuthService,
  Contact,
  ContactService,
  DateUtils,
  FrontendAppConfigKey,
  FrontendAppSwitch,
  isSameRef,
  KeyValueStore,
  Ref,
  WindowRef,
} from '@lifeislife/lifeislife-domain';
import {fromPromise} from 'rxjs/internal-compatibility';
import {catchError, debounceTime, defaultIfEmpty, filter, map, publishReplay, refCount, switchMap, take, tap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, EMPTY, Observable, of, throwError} from 'rxjs';
import {WebPushRegistrationService} from './web-push-registration.service';


@Injectable()
export class WebPushRegistrationServiceBrowser implements WebPushRegistrationService {

  discarded$ = new BehaviorSubject<boolean>(false);
  subscription: Observable<PushSubscription | null>;
  messages$: Observable<any>;
  notificationClicks$: Observable<any>;
  swPush: SwPush | null;

  constructor(
    private injector: Injector,
    private configService: AppConfigService,
    private contactService: ContactService,
    private authService: AuthService,
    private errorHandler: ErrorHandler,
    private compileTimeSettings: ApplicationCompileTimeSettings,
    private keyValueStore: KeyValueStore,
    private windowRef: WindowRef,
  ) {
    this.swPush = this.getSwPushOrNull();
    if (this.swPush == null) {
      this.subscription = of(null);
      this.notificationClicks$ = EMPTY;
      this.messages$ = EMPTY;
    } else {
      this.subscription = this.swPush.subscription.pipe(
        publishReplay(1), refCount(),
      );
      this.notificationClicks$ = this.swPush.notificationClicks;
      this.messages$ = this.swPush.messages;

      this.subscription
        .subscribe(pushSubscription => {
          if (pushSubscription) {
            // Ensure we mark discarded if subscription exists
            this.discarded$.next(true);
          }
        }, e => {
          // Handle errors (https://github.com/angular/angular/issues/27889)
          this.errorHandler.handleError(e);
          this.discarded$.next(true);
        });
      this.swPush.messages.subscribe(m => this.onMessage(m));
    }

    this.discarded$.next(this.checkHasDiscardedAppInstallFromStorage());
    this.notificationClicks$.subscribe(n => this.onNotificationClick(n));
  }

  subscribeToNotifications$(contactRef: Ref<Contact>) {
    const vapidKey = this.configService.getCurrentConfigValue(FrontendAppConfigKey.webpush_vapid_publicKey);

    return this.subscription.pipe(
      take(1),
      switchMap(s => s == null ? this.subscribeNew$(contactRef, vapidKey) : this.renotifySubscription$(contactRef, vapidKey)),
    );
  }

  canSubscribe(): boolean {
    if (this.swPush == null) {
      return false;
    }
    if (!this.swPush.isEnabled) {
      return false;
    }
    if (this.discarded$.getValue()) {
      return false;
    }

    const vapidKey = this.configService.getCurrentConfigValue(FrontendAppConfigKey.webpush_vapid_publicKey);
    if (vapidKey == null) {
      return false;
    }
    const authState = this.authService.getState();
    if (authState.contactRef == null) {
      return false;
    }
    return true;
  }

  hasSubscribe$(): Observable<boolean> {
    return this.subscription.pipe(
      map(s => s != null),
      defaultIfEmpty(false),
    );
  }

  canSubscribe$(): Observable<boolean> {
    if (this.swPush == null) {
      return of(false);
    }

    return combineLatest([
      this.discarded$,
      this.authService.getAuthenticatedObservable(),
      this.subscription,
    ]).pipe(
      debounceTime(1000),
      // CHeck if not already subscribed
      map((r) => r[2] == null && !this.discarded$.getValue()),
      map(r => r && this.canSubscribe()),
    );
  }

  discard(): void {
    const storageKey = this.getwebpushStorageKey();
    const dateString = new Date().toISOString();
    this.keyValueStore.putValue(storageKey, dateString);
    this.discarded$.next(true);
    this.unsubscribeIfSubscribed$().subscribe();
  }

  private unsubscribeIfSubscribed$(): Observable<boolean> {
    if (this.swPush == null) {
      return of(false);
    }

    return this.subscription.pipe(
      take(1),
      filter(i => i != null),
      switchMap(i => {
        const debug = this.configService.isSwitchCurrentlyEnabled(FrontendAppSwitch.front_dev_debug);
        if (debug) {
          console.log(`Unsubscribing from webpush notifications`);
        }
        return fromPromise(this.swPush.unsubscribe());
      }),
      map(r => true),
      defaultIfEmpty(false),
    );
  }

  private getwebpushStorageKey() {
    const configKey = this.compileTimeSettings.config[FrontendAppConfigKey.app_configKey];
    return `${configKey}.weubpushDiscardDate`;
  }

  private registerToBackend(contactRef: Ref<Contact>, sub: PushSubscription, publicKey: string): Observable<any> {
    const authState = this.authService.getState();
    const authContactRef = authState.contactRef;
    if (authContactRef == null || !isSameRef(authContactRef, contactRef)) {
      return throwError(new Error(`Not authenticated for ${contactRef}`));
    }

    return this.contactService.registerWebPush$(authContactRef, sub, publicKey);
  }

  private handleSubscribeError$(error: any): Observable<any> {
    this.errorHandler.handleError(error);
    this.discard();
    return of(null);
  }

  private checkHasDiscardedAppInstallFromStorage() {
    const storageKey = this.getwebpushStorageKey();
    const discardedTimeString = this.keyValueStore.getValue(storageKey);
    if (discardedTimeString == null) {
      return false;
    }

    const discardedMoment = DateUtils.parseDateAndTime(discardedTimeString);
    if (discardedMoment == null) {
      return false;
    }

    const discardDuration = this.configService.getCurrentConfigIntValue(FrontendAppConfigKey.webpush_discardDuration_days)
      || 30;
    const isDiscardedSomeDaysAgo = DateUtils.isDistinctSortedDays(discardedMoment, new Date(), discardDuration);
    if (isDiscardedSomeDaysAgo) {
      this.keyValueStore.clearValue(storageKey);
      return false;
    } else {
      return true;
    }
  }


  private onMessage(m: any) {
    if (m && m.notification && m.notification.sound && m.notification.sound.length > 0) {
      this.playSoundOnNonMobile(m);
    }
  }


  private subscribeNew$(contactRef: Ref<Contact>, vapidKey: string): Observable<any> {
    if (vapidKey == null || contactRef == null) {
      return of(null);
    }
    if (this.swPush == null) {
      return of(null);
    }
    const debug = this.configService.isSwitchCurrentlyEnabled(FrontendAppSwitch.front_dev_debug);
    return fromPromise(this.swPush.requestSubscription({
      serverPublicKey: vapidKey,
    })).pipe(
      switchMap(sub => this.registerToBackend(contactRef, sub, vapidKey)),
      tap(a => this.discarded$.next(true)),
      tap(a => {
        if (debug) {
          console.log(`Registered sw push: ${a}`);
        }
      }),
      catchError(e => this.handleSubscribeError$(e)),
    );
  }

  private renotifySubscription$(contactRef: Ref<Contact>, vapidKey: string) {
    // Ensure we resubscribe when the config flag is set.
    // This will ensure correct keys are used in case it changed.
    const mustResubscribe = this.configService.isSwitchCurrentlyEnabled(FrontendAppSwitch.webpush_resubscribe);
    const debug = this.configService.isSwitchCurrentlyEnabled(FrontendAppSwitch.front_dev_debug);
    if (this.swPush == null) {
      return;
    }
    if (mustResubscribe) {
      if (debug) {
        console.log('Resubscribing for webpush notifications');
      }
      fromPromise(this.swPush.unsubscribe()).pipe(
        switchMap(v => this.subscribeNew$(contactRef, vapidKey)),
      ).subscribe();
      return;
    }

    return this.subscription.pipe(
      filter(s => s != null),
      switchMap(s => this.registerToBackend(contactRef, s, vapidKey)),
    );
  }

  private onNotificationClick(event: {
    action: string;
    notification: NotificationOptions & {
      title: string;
    };
  }) {
    if (event.action === 'main' && event.notification.data.mainActionRoutePath && this.windowRef.getWindow()) {
      // const window = this.winbowRef.getWindow();
      // const externalUrl = this.location.prepareExternalUrl(event.notification.data.mainActionRoutePath);
      // const absoluteUrl = new URL(externalUrl, window.location.origin).href;
      // window.document.open(absoluteUrl);
    }
  }

  private playSoundOnNonMobile(m: any) {
    const mobileUserAgent = this.configService.isSwitchCurrentlyEnabled(FrontendAppSwitch.useragent_mobile);
    if (!mobileUserAgent && m.notification && m.notification.sound) {
      // TODO: play sound on desktop?
      // TODO: check audio feature?
      const soundUri = m.notification.sound;
      this.playAudio(soundUri);
    }
  }

  private playAudio(soundUri: string) {
    // TODO if required
    // const myAudio = new Audio(soundUri);
    // fromPromise(myAudio.play())
    //   .subscribe(() => {
    //     },
    //     e => console.warn(e));
  }

  private getSwPushOrNull(): SwPush | null {
    // https://github.com/angular/angular/issues/27889: handle safari lack of support
    if (this.windowRef && ServiceWorkerRegistration) {
      const window = this.windowRef.getWindow();
      if (!('PushManager' in window) && !('pushManager' in ServiceWorkerRegistration.prototype)) {
        return null;
      }
    }
    return this.injector.get(SwPush, undefined);
  }
}
