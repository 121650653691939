import {Injectable} from '@angular/core';
import {RequestService} from '../../service/request.service';
import {Observable} from 'rxjs';
import {WsContactEmailCodeRequest, WsRole, WsUserToken} from '@lifeislife/lifeislife-ws-api';
import {HttpHeaders} from '@angular/common/http';
import {FrontendAppConfigKey} from '../../../domain/config/frontend-app-config-key';
import {AppConfigService} from '../../../service/config/app-config.service';


@Injectable({
  providedIn: 'root',
})
export class UnrestictedTokenClient {

  constructor(private requestService: RequestService,
              private appConfigService: AppConfigService,
  ) {
  }

  exchangeCodeForToken$(authCode: string): Observable<WsUserToken> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/unrestricted/token/code`;
    return this.requestService.sendRequest<WsUserToken>({
      method: 'POST',
      url: url,
      body: authCode,
      headers: new HttpHeaders({
        'content-type': 'text/plain',
      }),
    });
  }


  requestVerificationCodeByMail$(email: string, redirect: string, resetPassword: boolean, roles: WsRole[]): Observable<string> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/unrestricted/token/verify/email`;
    const redirectUrl = new URL(redirect);
    if (redirectUrl.search) {
      console.warn(`Silencing search param for auth code redirect: ${redirectUrl.search}`);
      redirectUrl.search = '';
    }
    const codeRequest: WsContactEmailCodeRequest = {
      email: email,
      redirectUri: redirectUrl.toJSON(),
      anyWsRole: roles,
      wsRole: undefined,
      resetPassword: resetPassword,
    };
    return this.requestService.sendRequest<string>({
      method: 'POST',
      url: url,
      body: codeRequest,
      responseType: 'text',
      headers: new HttpHeaders({
        'content-type': 'application/json',
        'accept': 'text/plain',
      }),
    });
  }

}
