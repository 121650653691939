import {WithId} from '../../client/domain/with-id';
import {WsIdResourceCache} from '../../client/private_util/wsIdResourceCache';
import {Observable, of, throwError} from 'rxjs';
import {WsRequestCache} from '../../client/private_util/wsRequestCache';

export class InMemoryResourceTypeCache<T extends WithId> {

  private resourceCache: WsIdResourceCache<T>;
  private requestCache: WsRequestCache<T>;


  constructor() {
    this.resourceCache = new WsIdResourceCache();
    this.requestCache = new WsRequestCache();
  }

  cacheRequest$(id: number, request$: Observable<T>): Observable<T> {
    return this.requestCache.shareInCache(id, request$);
  }

  cacheValue(id: number, value: T) {
    this.resourceCache.putInCache(value, id);
  }

  getCachedValue$(id: number): Observable<T> {
    const cachedValue = this.resourceCache.getFromCache(id);
    if (cachedValue) {
      return of(cachedValue);
    }
    const cachedRequest$ = this.requestCache.getFromCache(id);
    if (cachedRequest$) {
      return cachedRequest$;
    }
    return throwError(`No cached value`);
  }

  hasCachedValue(id: number) {
    return this.resourceCache.getFromCache(id) != null
      || this.requestCache.getFromCache(id) != null;
  }

  clear(id: number) {
    this.resourceCache.removeFromCache(id);
    this.requestCache.clear(id);
  }

  clearAll() {
    this.resourceCache.clear();
    this.requestCache.clearAll();
  }
}
