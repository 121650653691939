import {WsResourceClient} from './ws-resource-client';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {WsApplicationError, WsConstraintViolation, WsRef} from '@lifeislife/lifeislife-ws-api';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {RequestService} from '../../service/request.service';
import {SearchResultFactory} from '../searchResultFactory';
import {SimplePaginationUtils} from '../simple-pagination-utils';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {SimplePagination} from '../../../util/pagination/simple-pagination';
import {SearchResult} from '../../domain/search/search-result';
import {AppConfigService} from '../../../service/config/app-config.service';
import {FrontendAppConfigKey} from '../../../domain/config/frontend-app-config-key';


export abstract class HttpWsResourceClient<T, U, S> implements WsResourceClient<T, U, S> {

  constructor(protected resourcePath: string,
              protected appConfigService: AppConfigService,
              protected requestService: RequestService,
              protected authService: AuthProvider) {
  }

  doGet(id: number, bypassSw?: boolean): Observable<T> {
    if (id == null || isNaN(id)) {
      return throwError(new Error('Invalid input'));
    }
    const url = this.getResourceUrl() + '/' + id;
    return this.requestService.sendRequest<T>({
      method: 'GET', url: url,
      throughServiceWorker: bypassSw !== true,
    }, this.authService.getAuth());
  }

  doRemove(id: number, queryParams?: HttpParams): Observable<any> {
    if (id == null || isNaN(id)) {
      return throwError(new Error('Invalid input'));
    }
    const url = this.getResourceUrl() + '/' + id;

    return this.requestService.sendRequest({
      method: 'DELETE', url: url, params: queryParams,
    }, this.authService.getAuth());
  }

  doCreate(entity: T, params?: HttpParams): Observable<WsRef<T>> {
    const url = this.getResourceUrl();

    return this.requestService.sendRequest<WsRef<T>>({
      method: 'POST', url: url, body: entity,
      params: params,
    }, this.authService.getAuth());
  }

  doUpdate(entity: T, params?: HttpParams): Observable<WsRef<T>> {
    const id = entity['id'];
    if (id == null || isNaN(id)) {
      return throwError(new Error('Invalid input'));
    }
    const url = this.getResourceUrl() + '/' + id;

    return this.requestService.sendRequest<WsRef<T>>({
      method: 'PUT', url: url, body: entity,
      params: params,
    }, this.authService.getAuth());
  }

  doSave(entity: T, params?: HttpParams): Observable<WsRef<T>> {
    if (!entity.hasOwnProperty('id')) {
      return this.doCreate(entity, params);
    }
    const id = entity['id'];
    if (id == null) {
      return this.doCreate(entity, params);
    } else {
      return this.doUpdate(entity, params);
    }
  }

  doValidate(entity: T): Observable<T | WsConstraintViolation[]> {
    if (entity == null) {
      return throwError(new Error('Invalid input'));
    }
    const url = this.getResourceUrl() + '/validate';

    return this.requestService.sendRequest<T>({
      method: 'PUT', url: url, body: entity,
    }, this.authService.getAuth())
      .pipe(
        catchError((error, caught) => this.handleValidationError(error, caught)),
      ) as Observable<T | WsConstraintViolation[]>;
  }


  doSearch(filter: any, pagination: SimplePagination): Observable<SearchResult<U>> {
    const url = this.getResourceUrl() + '/search';
    const queryParams = this.createQueryParams(pagination);

    return this.requestService.sendRequestFullResponse<U[]>({
      method: 'POST', url: url,
      params: queryParams,
      body: filter,
    }, this.authService.getAuth())
      .pipe(
        map((response) => SearchResultFactory.createSearchResult<U>(response)),
      );
  }

  doSearchReport(filter: any, pagination: SimplePagination, contentType: string): Observable<Blob> {
    const url = this.getResourceUrl() + '/report';
    const queryParams = this.createQueryParams(pagination);
    return this.requestService.downloadBlob({
      method: 'POST', url: url,
      params: queryParams,
      body: filter,
    }, this.authService.getAuth(), contentType);
  }

  doSearchGroupSummaries(filter: any, pagination: SimplePagination): Observable<SearchResult<S>> {
    const url = this.getResourceUrl() + '/report/groupSummaries';
    const queryParams = this.createQueryParams(pagination);

    return this.requestService.sendRequestFullResponse<S[]>({
      method: 'POST', url: url,
      params: queryParams,
      body: filter,
    }, this.authService.getAuth())
      .pipe(
        map((response) => SearchResultFactory.createSearchResult<S>(response)),
      );
  }

  doClearCache(id: number) {
    console.warn('No cache implemented in the client for resource path ' + this.resourcePath);
  }

  doFetch(id: number): Observable<T> {
    console.warn('No cache implemented in the client for resource path ' + this.resourcePath);
    return this.doGet(id);
  }


  getResourceUrl(): string {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    if (wsUri) {
      return `${wsUri}${this.resourcePath}`;
    }
    throw new Error(`No ws uri in config`);
  }

  protected handleValidationError(error: HttpErrorResponse, caught: Observable<any>): Observable<WsConstraintViolation[]> {
    const responseStatus = error.status;
    if (responseStatus !== 406) {
      return throwError(error);
    }
    const errorResponse: WsApplicationError = error.error;
    if (errorResponse == null) {
      return throwError(error);
    }
    return of(errorResponse.constraintViolations);
  }

  protected createQueryParams(pagination: SimplePagination): HttpParams {
    if (pagination == null) {
      return new HttpParams();
    }
    return SimplePaginationUtils.toQueryParams(pagination);
  }
}
