import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectItem} from 'primeng/api';
import {TrusteeContactType} from '@lifeislife/lifeislife-domain';
import {TrusteeContactTypeItemsService} from '../trustee-contact-type-items.service';

/**
 * ngModel-bound value: TrusteeContactType[]
 */
@Component({
  selector: 'app-trustee-contact-type-multi-select',
  templateUrl: './trustee-contact-type-multi-select.component.html',
  styleUrls: ['./trustee-contact-type-multi-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TrusteeContactTypeMultiSelectComponent),
    multi: true,
  }],
})
export class TrusteeContactTypeMultiSelectComponent implements OnInit, ControlValueAccessor {


  @Input()
  disabled = false;
  @Input()
  hasError = false;

  allItems: SelectItem[];
  selectedValue: TrusteeContactType[];

  private onChangeFunction: Function;
  private onTouchedFunction: Function;

  constructor(private itemService: TrusteeContactTypeItemsService) {
  }

  ngOnInit() {
    this.allItems = this.itemService.createAllTrusteeContactTypeItems();
  }

  writeValue(obj: TrusteeContactType[]): void {
    this.selectedValue = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFunction = fn;
  }

  onChange(event: TrusteeContactType[]) {
    this.selectedValue = event;
    this.fireChange();
  }


  fireChange() {
    this.onTouchedFunction();
    this.onChangeFunction(this.selectedValue);
  }

}
