import {WsCustomerStatus} from '@lifeislife/lifeislife-ws-api';
import {CustomerStatus} from '../../domain/customer/customer-status';

export class CustomerStatusConverter {

  static toCustomerStatus(value: WsCustomerStatus) {
    if (value == null) {
      return undefined;
    }
    switch (value) {
      case WsCustomerStatus.ACTIVE:
        return CustomerStatus.ACTIVE;
      case WsCustomerStatus.INACTIVE:
        return CustomerStatus.INACTIVE;
      case WsCustomerStatus.ARCHIVED:
        return CustomerStatus.ARCHIVED;
      case WsCustomerStatus.PROSPECT:
        return CustomerStatus.PROSPECT;
    }
  }

  static toWsCustomerStatus(value: CustomerStatus): WsCustomerStatus {
    if (value == null) {
      return undefined;
    }
    switch (value) {
      case CustomerStatus.ACTIVE:
        return WsCustomerStatus.ACTIVE;
      case CustomerStatus.INACTIVE:
        return WsCustomerStatus.INACTIVE;
      case CustomerStatus.ARCHIVED:
        return WsCustomerStatus.ARCHIVED;
      case CustomerStatus.PROSPECT:
        return WsCustomerStatus.PROSPECT;
    }
  }

}
