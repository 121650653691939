import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {Contact, Ref, Trustee, TrusteeContact, TrusteeContactSearch, TrusteeContactService} from '@lifeislife/lifeislife-domain';
import {distinctUntilChanged, map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {ObjectUtils} from '../../../../commons/util/ObjectUtils';

/**
 * Displays a contact linked to a trustee, with all its trusteeContactType relations
 */
@Component({
  selector: 'app-trustee-contact-multi-view',
  templateUrl: './trustee-contact-multi-view.component.html',
  styleUrls: ['./trustee-contact-multi-view.component.scss'],
})
export class TrusteeContactMultiViewComponent implements OnInit {

  @Input()
  set contactRef(ref: Ref<Contact>) {
    this.contactRefSource$.next(ref);
  }

  @Input()
  set trusteeRef(ref: Ref<Trustee>) {
    this.trusteeRefSource$.next(ref);
  }

  contactRefSource$ = new BehaviorSubject<Ref<Contact>>(null);
  private trusteeRefSource$ = new BehaviorSubject<Ref<Trustee>>(null);

  trusteeContacts$: Observable<TrusteeContact[]>;

  constructor(private trusteeContactService: TrusteeContactService) {
  }

  ngOnInit() {
    this.trusteeContacts$ = combineLatest(
      this.trusteeRefSource$.pipe(distinctUntilChanged(ObjectUtils.isSameRef)),
      this.contactRefSource$.pipe(distinctUntilChanged(ObjectUtils.isSameRef)),
    ).pipe(
      switchMap(results => this.searchTrusteeContacts$(results[0], results[1])),
      publishReplay(1), refCount(),
    );
  }

  private searchTrusteeContacts$(trusteeRef: Ref<Trustee> | null, contactRef: Ref<Contact> | null): Observable<TrusteeContact[]> {
    if (trusteeRef == null || contactRef == null) {
      return of([]);
    }
    const trusteeContactSearch: TrusteeContactSearch = {
      contactRef: contactRef,
      trusteeRef: trusteeRef,
    };

    return this.trusteeContactService.searchTrusteeContacts$(trusteeContactSearch, null)
      .pipe(map(results => results.list));
  }
}
