import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {concat, interval, Observable, Subscription} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {fromPromise} from 'rxjs/internal-compatibility';
import {Location} from '@angular/common';

@Injectable()
export class CacheService {

  private pollSubscription: Subscription;

  constructor(private swUpdate: SwUpdate,
              private location: Location,
              private appRef: ApplicationRef) {
    this.swUpdate.unrecoverable.subscribe(
      () => {
        console.error(`Application crashed`);
        location.go(location.path(true));
      },
    );
  }

  getUpdateAvailableObservable(): Observable<any> {
    return this.swUpdate.available;
  }

  getUpdateActivatedObservable(): Observable<{
    hash: string;
    appData?: Object;
  }> {
    return this.swUpdate.activated
      .pipe(map(event => event.current));
  }

  startPollingUpdates(millisecondInterval: number = 30000) {
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everyInterval$ = interval(millisecondInterval);
    this.pollSubscription = concat(appIsStable$, everyInterval$)
      .subscribe(() => this.checkUpdates());
  }

  activateUpdate(): Observable<any> {
    return fromPromise(this.swUpdate.activateUpdate());
  }

  checkUpdates(): Observable<any> {
    const updateCheckedPromise = this.swUpdate.checkForUpdate()
      .catch(error => {
        if (this.pollSubscription != null) {
          console.warn(`Stopping polling for sw updates following error: ${error}`);
          this.pollSubscription.unsubscribe();
        }
      });
    return fromPromise(updateCheckedPromise);
  }

  isPotentialCacheMismatchError(err: any): boolean {
    if (err.message != null && typeof err.message === 'string') {
      const messageString: string = err.message;
      return messageString.indexOf('Loading chunk') >= 0
        || messageString.indexOf('Cannot find module ') >= 0;
    } else {
      return false;
    }
  }
}
