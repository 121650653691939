import {Injectable, isDevMode} from '@angular/core';
import {Message, MessageService} from 'primeng/api';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {MessageSeverity} from '../domain/message-severity';

@Injectable({
  providedIn: 'root',
})
export class NotificationMessageService {

  static DEFAULT_INFO_MESSAGE_TIMEOUT_MS = 1000;
  static DEFAULT_WARN_MESSAGE_TIMEOUT_MS = 3000;
  static DEFAULT_ERROR_MESSAGE_TIMEOUT_MS = 15000;

  constructor(private messageService: MessageService) {
  }

  dismissAllMessages() {
    this.messageService.clear();
  }

  addInfo(title: string, details?: string, timeoutMs?: number) {
    const message = <Message>{
      summary: title,
      detail: details,
      severity: MessageSeverity.INFO,
      life: this.getTimeout('info', timeoutMs),
    };
    this.addMessage(message);
  }

  addWarning(title: string, details?: string, timeoutMs?: number) {
    const message = <Message>{
      summary: title,
      detail: details,
      severity: MessageSeverity.WARNING,
      life: this.getTimeout('warn', timeoutMs),
    };
    this.addMessage(message);
  }

  addError(title: string, detailsOrError?: string | any, timeoutMs?: number) {
    const messageDetails = this.getMessageDetails(detailsOrError);
    const lifeValue = this.getTimeout('error', timeoutMs);
    const message = <Message>{
      summary: title,
      detail: messageDetails,
      severity: 'error',
      life: lifeValue,
    };
    this.addMessage(message);
    if (isDevMode()) {
      console.warn(detailsOrError);
    }
  }

  addMessage(message: Message) {
    this.messageService.add(message);
  }


  private getMessageDetails(detailsOrError: string | any | Error): string | null {
    if (detailsOrError == null) {
      return null;
    }
    if (typeof detailsOrError === 'string') {
      return detailsOrError;
    } else if (detailsOrError instanceof HttpErrorResponse) {
      return this.getErrorMessageFromHttpErrorResponse(detailsOrError);
    } else if (detailsOrError instanceof HttpResponse) {
      return this.getErrorMessageFromHttpResponse(detailsOrError);
    } else if (detailsOrError instanceof Error) {
      return detailsOrError.message;
    } else {
      console.warn('Could not figure out error message:');
      console.log(detailsOrError);
      return 'Erreur inconnue';
    }
  }


  private getErrorMessageFromHttpResponse(response: HttpResponse<any>): string {
    const statusCode = response.status;
    if (statusCode === 0) {
      return 'Le server ne répond pas';
    } else if (statusCode === 404) {
      return 'Resource introuvable (404)';
    }
    let responseText = response.body;
    if (responseText === 'InvalidStateError') {
      // IE
      return 'Le server ne répond pas';
    }
    if (responseText == null) {
      responseText = 'Erreur inconnue';
    }
    try {
      const jsonResponse = JSON.parse(responseText);
      const text = jsonResponse.message;
      return text;
    } catch (error) {
      const truncatedText = responseText.substr(0, 20);
      return truncatedText + ' (' + statusCode + ')';
    }
  }

  private getErrorMessageFromHttpErrorResponse(response: HttpErrorResponse): string {
    const statusCode = response.status;
    if (statusCode === 0) {
      return 'Le server ne répond pas';
    } else if (statusCode === 404) {
      return 'Resource introuvable (404)';
    }

    try {
      const jsonResponse = response.error;
      const text = jsonResponse.messageDetails || jsonResponse.message;
      return text;
    } catch (error) {
    }
    let responseText = response.message;
    if (responseText === 'InvalidStateError') {
      // IE
      return 'Le server ne répond pas';
    }
    if (responseText == null) {
      responseText = 'Erreur inconnue';
    }
    const truncatedText = responseText.substr(0, 20);
    return truncatedText + ' (' + statusCode + ')';
  }

  private getTimeout(level: string, timeoutMs: number) {
    if (timeoutMs != null) {
      return timeoutMs;
    }
    switch (level) {
      case 'error':
        return NotificationMessageService.DEFAULT_ERROR_MESSAGE_TIMEOUT_MS;
      case 'warn':
        return NotificationMessageService.DEFAULT_WARN_MESSAGE_TIMEOUT_MS;
      default:
        return NotificationMessageService.DEFAULT_WARN_MESSAGE_TIMEOUT_MS;
    }
  }
}
