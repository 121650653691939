import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsContact, WsWebPushSubscriptionRequest} from '@lifeislife/lifeislife-ws-api';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {Contact} from '../../domain/contact/contact';
import {ContactSearch} from '../../domain/contact/contact-search';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {Trustee} from '../../domain/trustee/trustee';
import {Country} from '../../domain/country/country';
import {ContactWsClient} from '../../client/resources/front/contact/contact-ws-client';
import {WithId} from '../../client/domain/with-id';
import {ContactConverter} from './contact.converter';
import {ContactSearchConverter} from './contact-search.converter';
import {ContactClaimType} from '../../domain/contact/contact-claim-type';
import {ContactClaim} from '../../domain/contact/contact-claim';
import {ContactClaimConverter} from './contact-claim.converter';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {WsContactClaimType} from '@lifeislife/lifeislife-ws-api';
import {MaritalStatusWsClient} from '../../client/resources/front/contact/marital-status-ws-client';
import {MaritalStatus} from '../../domain/contact/marital-status';
import {MaritalStatusConverter} from './marital-status.converter';
import {WsMaritalStatus} from '@lifeislife/lifeislife-ws-api';

@Injectable({
  providedIn: 'root',
})
export class MaritalStatusService {


  constructor(private client: MaritalStatusWsClient,
  ) {
  }

  getMaritalStatus$(ref: Ref<MaritalStatus>, forceFetch?: boolean): Observable<MaritalStatus> {
    if (ref == null) {
      return of(null);
    }

    return this.client.doGet(ref.id, forceFetch).pipe(
      map(wsStatus => MaritalStatusConverter.toMaritalStatus(wsStatus)),
    );
  }

  searchMaritalStatuses$(): Observable<MaritalStatus[]> {
    return this.client.listAllStatuses$().pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  private convertSearchResultsIn$(results: WsMaritalStatus[]): Observable<MaritalStatus[]> {
    return of(results.map(r => MaritalStatusConverter.toMaritalStatus(r)));
  }
}
