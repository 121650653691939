import {WsSubscriptionGroupSummary} from '@lifeislife/lifeislife-ws-api';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {SubscriptionGroupSummary} from '../../domain/corebusiness/subscription-group-summary';
import {SubscriptionGroupField} from '../../domain/corebusiness/subscription-group-field';

export class SubscriptionGroupSummaryConverter {


  static convertIn(value: WsSubscriptionGroupSummary): SubscriptionGroupSummary {
    return {
      groupCount: value.groupCount,
      groupField: EnumConverterUtils.convertValue(value.groupField, SubscriptionGroupField),
      groupId: value.groupId,
      groupLabel: value.groupLabel,
      totalPricingSum: value.totalPricingSum,
    };
  }

}
