import {WsCustomerGroupSummary} from '@lifeislife/lifeislife-ws-api';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {CustomerGroupSummary} from '../../domain/customer/customer-group-summary';
import {CustomerGroupField} from '../../domain/customer/customer-group-field';

export class CustomerGroupSummaryConverter {

  static convertIn(value: WsCustomerGroupSummary): CustomerGroupSummary {
    return {
      cashBackPricingSum: value.cashBackPricingSum,
      groupCount: value.groupCount,
      groupField: EnumConverterUtils.convertValue(value.groupField, CustomerGroupField),
      groupId: value.groupId,
      groupLabel: value.groupLabel,
      totalPricingVSum: value.totalPricingVSum,
    };
  }

}
