import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {MailAccountConverter} from './mail-account-converter';
import {SmtpAccount} from '../../domain/mail/smtp-account';
import {Ref} from '../../domain/shared/ref';
import {map} from 'rxjs/operators';
import {WsDomainObject} from '@lifeislife/lifeislife-ws-api';
import {EmailAccount} from '../../domain/mail/email-account';
import {SmtpAccountWsClient} from '../../client/resources/internal/mail/smtp-account-ws-client';
import {EmailAccountWsClient} from '../../client/resources/internal/mail/email-account-ws-client';
import {WithId} from '../../client/domain/with-id';

@Injectable({
  providedIn: 'root',
})
export class MailAccountService {


  constructor(private smtpAccountClient: SmtpAccountWsClient,
              private emailAccountClient: EmailAccountWsClient,
  ) {
  }


  getAccountLabel(account: SmtpAccount): string {
    return `${account.userName}@${account.host}:${account.port}`;
  }

  getSmtpAccount$(ref: Ref<SmtpAccount>, forceFetch?: boolean): Observable<SmtpAccount> {
    return this.smtpAccountClient.getAccount$(ref.id, forceFetch).pipe(
      map(wsAccount => MailAccountConverter.convertSmtpAccountIn(wsAccount)),
    );
  }

  saveSmtpAccount$(account: SmtpAccount): Observable<SmtpAccount> {
    const wsAccount = MailAccountConverter.convertSmtpAccountOut(account);
    if (account.id == null) {
      return this.smtpAccountClient.createAccount$(wsAccount).pipe(
        map(wsAcc => MailAccountConverter.convertSmtpAccountIn(wsAcc)),
      );
    } else {
      return this.smtpAccountClient.updateAccount$(wsAccount).pipe(
        map(wsAcc => MailAccountConverter.convertSmtpAccountIn(wsAcc)),
      );
    }
  }

  deleteSmtpAccount$(ref: Ref<SmtpAccount>): Observable<any> {
    return this.smtpAccountClient.deleteAccount$(ref.id);
  }

  searchSmtpAccounts$(pagination?: SimplePagination): Observable<SearchResult<SmtpAccount>> {
    return this.smtpAccountClient.searchAccounts$({}, pagination).pipe(
      map(results => this.convertSearchResults(results, wsAccount => MailAccountConverter.convertSmtpAccountIn(wsAccount))),
    );
  }

  getEmailAccount$(ref: Ref<EmailAccount>, forceFetch?: boolean): Observable<EmailAccount> {
    return this.emailAccountClient.getAccount$(ref.id, forceFetch).pipe(
      map(wsAccount => MailAccountConverter.convertEmailAccountIn(wsAccount)),
    );
  }

  saveEmailAccount$(account: EmailAccount): Observable<EmailAccount> {
    const wsAccount = MailAccountConverter.convertEmailAccountOut(account);
    if (account.id == null) {
      return this.emailAccountClient.createAccount$(wsAccount).pipe(
        map(wsAcc => MailAccountConverter.convertEmailAccountIn(wsAcc)),
      );
    } else {
      return this.emailAccountClient.updateAccount$(wsAccount).pipe(
        map(wsAcc => MailAccountConverter.convertEmailAccountIn(wsAcc)),
      );
    }
  }

  deleteEmailAccount$(ref: Ref<EmailAccount>): Observable<any> {
    return this.emailAccountClient.deleteAccount$(ref.id);
  }

  searchEmailAccounts$(pagination?: SimplePagination): Observable<SearchResult<EmailAccount>> {
    return this.emailAccountClient.searchAccounts$({}, pagination).pipe(
      map(results => this.convertSearchResults(results, wsAccount => MailAccountConverter.convertEmailAccountIn(wsAccount))),
    );
  }


  private convertSearchResults<W extends WsDomainObject, T extends WithId>(
    results: SearchResult<W>, mapper: (wsAccount: W) => T): SearchResult<T> {
    const mappedList: T[] = results.list.map(wsAccount => mapper(wsAccount));
    return {
      count: results.count,
      list: mappedList,
    };
  }
}
