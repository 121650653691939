export enum InsuranceSubscriptionStatus {

  TRUSTEE_OK = 'TRUSTEE_OK',
  TRUSTEE_FORM_SENT = 'TRUSTEE_FORM_SENT',
  FORM_OK = 'FORM_OK',
  DRAFT_SENT_TO_TRUSTEE = 'DRAFT_SENT_TO_TRUSTEE',
  DRAFT_SENT_TO_CUSTOMER = 'DRAFT_SENT_TO_CUSTOMER',
  DRAFT_ACCEPTED = 'DRAFT_ACCEPTED',
  OFFER_SENT_TO_CUSTOMER = 'OFFER_SENT_TO_CUSTOMER',
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  ACTIVE = 'ACTIVE',
  NEGOTIATION = 'NEGOTIATION',
  MANDATE_SIGNATURE_PENDING = 'MANDATE_SIGNATURE_PENDING',
  MANDATE_VALIDATION = 'MANDATE_VALIDATION',
  CONTACT_REQUIRED = 'CONTACT_REQUIRED',
  LOST = 'LOST',
  CANCELLED = 'CANCELLED',
  ON_HOLD = 'ON_HOLD',
  DISCOUNTING = 'DISCOUNTING',
}
