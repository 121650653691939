import {WsCustomerNote, WsCustomerNoteSearch} from '@lifeislife/lifeislife-ws-api';
import {CustomerNote} from '../../domain/customer-note/customer-note';
import {CustomerNoteFilter} from '../../domain/customer-note/customer-note-filter';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';

export class CustomerNoteConverter {

  static convertIn(value: WsCustomerNote): CustomerNote {
    return {
      customerRef: value.customerRef,
      dateTime: DateConverterUtils.parseIsoDate(value.date),
      note: value.note,
      alert: value.alert,
      forCustomer: value.forCustomer,
      id: value.id,
    };
  }

  static convertOut(value: CustomerNote): WsCustomerNote {
    return {
      customerRef: value.customerRef,
      date: DateConverterUtils.formatDateToIsoDate(value.dateTime),
      note: value.note,
      alert: value.alert,
      forCustomer: value.forCustomer,
      id: value.id,
    };
  }


  static convertFilterIn(value?: WsCustomerNoteSearch): CustomerNoteFilter {
    if (value == null) {
      return undefined;
    }
    return {
      alert: value.alert,
      forCustomer: value.forCustomer,
      customerRef: value.customerRef,
      trusteeRef: value.trusteeRef,
    };
  }

  static convertFilterOut(value: CustomerNoteFilter): WsCustomerNoteSearch {
    if (value == null) {
      return undefined;
    }
    return {
      alert: value.alert,
      forCustomer: value.forCustomer,
      customerRef: value.customerRef,
      trusteeRef: value.trusteeRef,
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsCustomerNote]: keyof CustomerNote } {
    return {
      id: 'id',
      customerRef: 'customerRef',
      forCustomer: 'forCustomer',
      alert: 'alert',
      note: 'note',
      date: 'dateTime',
    };
  }

}
