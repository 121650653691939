import {ObjectConverterUtil, PropertyDiff, Ref, ValidationResult, WithId} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, combineLatest, forkJoin, merge, Observable, of, throwError} from 'rxjs';
import {debounceTime, delay, filter, map, publishReplay, refCount, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';

export class FormHelper<T extends WithId, R = Ref<any>> {

  initialValue$ = new BehaviorSubject<T | null>(null);
  editingValue$ = new BehaviorSubject<T | null>(null);

  validationResults$: Observable<ValidationResult<T>>;
  validating$ = new BehaviorSubject<boolean>(false);
  fetching$ = new BehaviorSubject<boolean>(false);
  saving$ = new BehaviorSubject<boolean>(false);
  busy$: Observable<boolean>;
  persisted$: Observable<boolean>;
  nonPersisted$: Observable<boolean>;
  hasChanges$: Observable<boolean>;
  valid$: Observable<boolean>;
  invalid$: Observable<boolean>;
  propertyDiffCheckers: { [k in keyof T | string]?: (a: any, b: any) => boolean } = {};
  propertiesDiff$: Observable<PropertyDiff<T>>;

  private fetch$: (ref: R) => Observable<T>;
  private save$: (value: T) => Observable<R>;
  private validate$: (value: T) => Observable<ValidationResult<T>>;

  // Sometimes, frontend maintain an object graph with each its FormHelper and validated them in parallel.
  // Only on form submit, a service will make POST requests in order and update parent entities with children refs once
  // persisted on the backend side. Those properties should be considered valid and not prevent form submit.
  // We allow any string, so that we can filter out backend managed value that return a constraint violation from a property
  // of a lifeislife-ejb domain entity which would be created by the rest service.
  // Ex: Contact.user is @NotNull in lifeislife-ejb. WsContact.userRef is nullable, as this is a backend-managed value: the frontend
  // does not have to create the user itself. Validation of a new (non-peristed) contact will throw a violation error for an 'user' property.
  // This error can safely be ignored here, so we need to filter out the 'user' property, which is not a property name of any contact entity here.
  private validationIgnoredProperties: (keyof T | string)[];

  constructor(
    fetch$: (ref: R) => Observable<T>,
    save$: (value: T) => Observable<R>,
    validate$: (value: T) => Observable<ValidationResult<T>> = value => this.mockValidation$(value),
    initialValue?: T,
    // Property names in the validation results that should be ignored to check whether the object is invalid.
    // Those must match the names of the properties in the ws api objects, AND those in the backend domain entities.
    validationIgnoredPoperties?: (keyof T | string)[],
  ) {
    this.fetch$ = fetch$;
    this.save$ = save$;
    this.validate$ = validate$;
    this.validationIgnoredProperties = validationIgnoredPoperties;

    this.init();

    if (initialValue != null) {
      this.initFromValue(initialValue);
    }
  }

  initFromRef(ref: R) {
    this.fetchValue$(ref)
      .subscribe(value => this.setInitialValue(value));
  }

  replaceInitialValue(value: T | null) {
    this.setInitialValue(value, true);
  }

  initFromValue(value: T | null) {
    this.setInitialValue(value);
  }

  clearValue() {
    this.setInitialValue(null);
  }

  reinit() {
    this.setInitialValue(this.initialValue$.value);
  }

  setEditingValue(value: T) {
    this.editingValue$.next(value);
  }

  updateEditingValue(partialValue: Partial<T>) {
    const curValue = this.editingValue$.getValue();
    const updatedValue = Object.assign({}, curValue, partialValue);
    this.editingValue$.next(updatedValue);
  }

  persist$(): Observable<T> {
    const value = this.editingValue$.getValue();
    return this.persistValue$(value);
  }

  hasValue$(): Observable<boolean> {
    return this.editingValue$.pipe(
      map(v => v != null),
      publishReplay(1), refCount(),
    );
  }

  reset() {
    const initialValue = this.initialValue$.getValue();
    const newEditingValue = Object.assign({}, initialValue);
    this.setEditingValue(newEditingValue);
  }

  refetchAndInitValueIfNoChanges() {
    // If no pending changes...
    this.hasChanges$.pipe(
      take(1),
      filter(c => !c),
      // ..and if persisted
      map(() => this.initialValue$.getValue()),
      filter(v => v.id != null),
      // ..then refetch
      switchMap(v => this.fetchValue$({id: v.id} as unknown as R)),
    ).subscribe(v => this.initFromValue(v));
  }

  refetchAndInitValue() {
    // If no pending changes...
    const initialValue = this.initialValue$.getValue();
    if (initialValue && initialValue.id) {
      this.fetchValue$({id: initialValue.id} as unknown as R)
        .subscribe(v => this.initFromValue(v));
    }
  }

  refetchAndReapplyChanges() {
    // If no pending changes...
    const initialValue = this.initialValue$.getValue();
    const editingValue = this.editingValue$.getValue();
    const propertyDiffs$ = this.propertiesDiff$.pipe(
      take(1),
    );
    if (initialValue && initialValue.id) {
      forkJoin([
        this.fetchValue$({id: initialValue.id} as unknown as R),
        propertyDiffs$,
      ]).subscribe(r => this.initAndReapplyChanges(r[0], r[1], editingValue));
    }
  }

  refetchAndKeepEditingValue$() {
    const initialValue = this.initialValue$.getValue();
    if (initialValue && initialValue.id) {
      return this.fetchValue$({id: initialValue.id} as unknown as R).pipe(
        tap(v => this.initialValue$.next(v)),
        tap(v => this.updateEditingValue({
          version: v['version'],
        } as unknown as Partial<T>)),
      );
    } else {
      return throwError(`No value`);
    }
  }

  private init() {
    this.validationResults$ = merge(of({
      errors: [], propertiesErrors: {}, updatedValue: null, valid: true,
    } as ValidationResult<T>), this.editingValue$.pipe(
      filter(c => c != null),
      debounceTime(300),
      switchMap(value => this.validateValue$(value)),
      publishReplay(1), refCount(),
    ));
    this.busy$ = combineLatest(this.fetching$, this.saving$, this.validating$).pipe(
      map(r => r[0] || r[1] || r[2]),
      publishReplay(1), refCount(),
    );
    this.persisted$ = this.editingValue$.pipe(
      map(value => value != null && value.id != null),
      publishReplay(1), refCount(),
    );
    this.nonPersisted$ = this.editingValue$.pipe(
      map(value => value == null || value.id == null),
      publishReplay(1), refCount(),
    );
    this.valid$ = this.validationResults$.pipe(
      map(results => results && results.valid),
      publishReplay(1), refCount(),
    );
    this.invalid$ = this.validationResults$.pipe(
      map(results => results == null || !results.valid),
      publishReplay(1), refCount(),
    );
    this.propertiesDiff$ = this.editingValue$.pipe(
      withLatestFrom(this.initialValue$),
      map(r => this.createPropertyDiff(r[0], r[1])),
      publishReplay(1), refCount(),
    );
    this.hasChanges$ = this.propertiesDiff$.pipe(
      map(diff => !this.isEmptyDiff(diff)),
      publishReplay(1), refCount(),
    );
  }

  private validateValue$(value: T): Observable<ValidationResult<T>> {
    this.validating$.next(true);
    const safeIgnoredProperties = this.validationIgnoredProperties || [];
    return this.validate$(value).pipe(
      map(r => ObjectConverterUtil.filterValidationResult(r, ...safeIgnoredProperties)),
      delay(0),
      tap({complete: () => this.validating$.next(false)}),
    );
  }

  private fetchValue$(ref: R) {
    this.fetching$.next(true);
    return this.fetch$(ref).pipe(
      delay(0),
      tap({complete: () => this.fetching$.next(false)}),
    );
  }

  private persistValue$(value: T) {
    this.saving$.next(true);
    return this.save$(value).pipe(
      switchMap(ref => this.fetch$(ref)),
      delay(0),
      tap({
        next: val => this.initFromValue(val),
        complete: () => this.saving$.next(false),
        error: () => this.saving$.next(false),
      }),
    );
  }

  private mockValidation$(value: T) {
    return of(<ValidationResult<T>>{
      valid: true,
      errors: [],
      propertiesErrors: {},
      updatedValue: null,
    });
  }

  private createPropertyDiff<O>(curValue: O, initialValue: O): PropertyDiff<O> {
    const diff: PropertyDiff<O> = {} as PropertyDiff<O>;
    if ((curValue == null) || (initialValue == null)) {
      return diff;
    }
    // Ignore properties diff when the initial instance has not been persisted
    if (initialValue['id'] == null) {
      return diff;
    }
    const processedKeys: (keyof O)[] = [];
    for (const curKey in curValue) {
      if (typeof curKey !== 'string') {
        continue;
      }
      if (!curValue.hasOwnProperty(curKey)) {
        continue;
      }
      processedKeys.push(curKey);
      if (!initialValue.hasOwnProperty(curKey)) {
        diff[curKey] = true;
        continue;
      }
      const curPropertyValue = curValue[curKey];
      const initialPropertyValue = initialValue[curKey];
      if (this.propertyValuesDiffer<O>(curKey, curPropertyValue, initialPropertyValue)) {
        diff[curKey] = true;
      }
    }

    for (const initialKey in initialValue) {
      if (typeof initialKey !== 'string') {
        continue;
      }
      if (!initialValue.hasOwnProperty(initialKey)) {
        continue;
      }
      // If not processed, absent from cur value, thus changed
      if (processedKeys.find(p => p === initialKey) != null) {
        continue;
      }
      diff[initialKey] = true;
    }

    return diff;
  }

  private propertyValuesDiffer<O>(key: keyof O | string, valueA: any, valueB: any) {
    if ((valueA == null) !== (valueB == null)) {
      return true;
    }
    if (valueA == null && valueB == null) {
      return false;
    }
    if (typeof valueA !== typeof valueB) {
      return true;
    }
    const difChecker = this.propertyDiffCheckers[key as string];
    if (difChecker) {
      return difChecker(valueA, valueB);
    }
    if (typeof valueA === 'object') {
      if (valueA instanceof Date && valueB instanceof Date) {
        return valueA.toISOString() !== valueB.toISOString();
      }
      if (valueA instanceof Array && valueB instanceof Array) {
        return this.arrayDiffers(valueA, valueB);
      }
      const valueDiff = this.createPropertyDiff(valueA, valueB);
      return !this.isEmptyDiff(valueDiff);
    } else {
      return valueA !== valueB;
    }
  }

  private isEmptyDiff(diff: PropertyDiff<any>): boolean {
    if (diff == null) {
      return true;
    }
    const diffProperties = Object.getOwnPropertyNames(diff);
    return diffProperties.length === 0;
  }

  private setInitialValue(value, ignoreEditingValueUdates?: boolean) {
    this.initialValue$.next(value);
    if (ignoreEditingValueUdates === true) {
      return;
    }
    if (value == null) {
      this.editingValue$.next(null);
    } else {
      const clone = Object.assign({}, value);
      this.editingValue$.next(clone);
    }
  }

  private initAndReapplyChanges(upstreamValue: T, propertyDiffs: PropertyDiff<T>, editingValue: T) {
    this.initFromValue(upstreamValue);

    const updates: Partial<T> = {};
    for (const propKey in propertyDiffs) {
      if (typeof propKey === 'string' && editingValue.hasOwnProperty(propKey)) {
        const editedValue = editingValue[propKey];
        updates[propKey] = editedValue;
      }
    }
    this.updateEditingValue(updates);
  }

  private arrayDiffers<WT, WU>(valueA: Array<WT>, valueB: Array<WU>) {
    if (valueA.length !== valueB.length) {
      return true;
    }
    for (let i = 0; i < valueA.length; i++) {
      const itemA = valueA[i];
      const itemB = valueB[i];
      const differ = this.propertyValuesDiffer(`${i}`, itemA, itemB);
      if (differ) {
        return true;
      }
    }
    return false;
  }
}
