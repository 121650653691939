import {NgModule} from '@angular/core';
import {PhoneInputComponent} from './phone-input.component';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {CommonModule} from '@angular/common';
import {InputMaskModule} from 'primeng/inputmask';

@NgModule({
  declarations: [
    PhoneInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    InputMaskModule,
  ],
  exports: [
    PhoneInputComponent,
  ],
})
export class PhoneInputModule {

}
