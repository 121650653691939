import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsCustomerEventSubscription, WsRef} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {CustomerEventSubscription} from '../../domain/event/customer-event-subscription';
import {CustomerEventSubscriptionWsClient} from '../../client/resources/front/customer-event-subscription-ws-client';
import {CustomerEventSubscriptionConverter} from './customer-event-subscription-converter';
import {CustomerEventSubscriptionSearch} from '../../domain/event/customer-event-subscription-search';
import {CustomerEventType} from '../../domain/event/customer-event-type';

@Injectable({
  providedIn: 'root',
})
export class CustomerEventSubscriptionService {


  constructor(private client: CustomerEventSubscriptionWsClient,
  ) {
  }

  getCustomerEventSubscription$(ref: Ref<CustomerEventSubscription>): Observable<CustomerEventSubscription> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doGet(ref.id).pipe(
      map(wsCustomerEventSubscription => CustomerEventSubscriptionConverter.convertIn(wsCustomerEventSubscription)),
    );
  }

  searchCustomerEventSubscriptions$(customerEventSubscriptionFilter: CustomerEventSubscriptionSearch, pagination: SimplePagination)
    : Observable<SearchResult<CustomerEventSubscription>> {
    const wsSearch = CustomerEventSubscriptionConverter.convertFilterOut(customerEventSubscriptionFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateCustomerEventSubscription$(customerEventSubscription: CustomerEventSubscription)
    : Observable<ValidationResult<CustomerEventSubscription>> {
    const wsCustomerEventSubscription = CustomerEventSubscriptionConverter.convertOut(customerEventSubscription);
    return this.client.doValidate(wsCustomerEventSubscription).pipe(
      map((results: WsCustomerEventSubscription | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveCustomerEventSubscription$(customerEventSubscription: CustomerEventSubscription): Observable<any> {
    const wsCustomerEventSubscription = CustomerEventSubscriptionConverter.convertOut(customerEventSubscription);
    return this.client.doSave(wsCustomerEventSubscription);
  }

  deleteCustomerEventSubscription$(ref: Ref<CustomerEventSubscription>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  serializeFilter(customerEventSubscriptionFilter: CustomerEventSubscriptionSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(customerEventSubscriptionFilter);
    const wsCustomerEventSubscriptionSearch = CustomerEventSubscriptionConverter.convertFilterOut(customerEventSubscriptionFilter);
    return JSON.stringify(wsCustomerEventSubscriptionSearch);
  }


  deserializeFilter(valueString: string | null): CustomerEventSubscriptionSearch {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: any = JSON.parse(valueString);
      const filter = CustomerEventSubscriptionConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  getEventTypeLabel(eventType: CustomerEventType): string {
    if (eventType == null) {
      return '';
    }
    // TODO i18n
    switch (eventType) {
      case CustomerEventType.CUSTOMER_CREATED:
        return `Client créé`;
      case CustomerEventType.CUSTOMER_DELETED:
        return `Client supprimé`;
      case CustomerEventType.CUSTOMER_UPDATED:
        return `Client mis à jour`;
      case CustomerEventType.CUSTOMER_STATUS_UPDATED:
        return `État du client mis à jour`;
      case CustomerEventType.CREDIT_SUBSCRIPTION_CREATED:
        return `Contrat de crédit créé`;
      case CustomerEventType.CREDIT_SUBSCRIPTION_DELETED:
        return `Contrat de crédit supprimé`;
      case CustomerEventType.CREDIT_SUBSCRIPTION_UPDATED:
        return `Contrat de crédit mis à jour`;
      case CustomerEventType.CREDIT_SUBSCRIPTION_STATUS_UPDATED:
        return `État du contrat de crédit mis à jour`;
      case CustomerEventType.INSURANCE_SUBSCRIPTION_CREATED:
        return `Contrat d'assurance créé`;
      case CustomerEventType.INSURANCE_SUBSCRIPTION_DELETED:
        return `Contrat d'assurance supprimé`;
      case CustomerEventType.INSURANCE_SUBSCRIPTION_UPDATED:
        return `Contrat d'assurance mis à jour`;
      case CustomerEventType.INSURANCE_SUBSCRIPTION_STATUS_UPDATED:
        return `État du contrat d'assurance mis à jour`;
      case CustomerEventType.INVESTMENT_SUBSCRIPTION_CREATED:
        return `Contrat d'investissement créé`;
      case CustomerEventType.INVESTMENT_SUBSCRIPTION_DELETED:
        return `Contrat d'investissement supprimé`;
      case CustomerEventType.INVESTMENT_SUBSCRIPTION_UPDATED:
        return `Contrat d'investissement mis à jour`;
      case CustomerEventType.INVESTMENT_SUBSCRIPTION_STATUS_UPDATED:
        return `État du contrat d'investissement mis à jour`;
    }
  }

  private createValidationResult(results: WsCustomerEventSubscription | WsConstraintViolation[])
    : ValidationResult<CustomerEventSubscription> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsCustomerEventSubscription => CustomerEventSubscriptionConverter.convertIn(wsCustomerEventSubscription),
      propertyNameMappings: CustomerEventSubscriptionConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsCustomerEventSubscription>>): Observable<SearchResult<CustomerEventSubscription>> {
    const rowTasks = results.list.map(ref => this.getCustomerEventSubscription$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<CustomerEventSubscription>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<CustomerEventSubscription>>>{
          list: rows,
        }),
      ));
  }

}
