export enum CreditStatus {
  REQUESTED_BY_CUSTOMER = 'REQUESTED_BY_CUSTOMER',
  REQUESTED_TO_PROVIDER = 'REQUESTED_TO_PROVIDER',
  ACCEPTED_BY_PROVIDER = 'ACCEPTED_BY_PROVIDER',
  REFUSED_BY_PROVIDER = 'REFUSED_BY_PROVIDER',
  DOCUMENTS_PENDING = 'DOCUMENTS_PENDING',
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  ARCHIVED = 'ARCHIVED',
}
