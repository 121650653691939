import {Injectable} from '@angular/core';
import {InsuranceCategory} from '@lifeislife/lifeislife-domain';
import {forkJoin, Observable, of} from 'rxjs';
import {InsuranceCategorySelectItem} from './insurance-category-select-item';

@Injectable({
  providedIn: 'root',
})
export class InsuranceCategoryItemService {

  OTHER_OPTION_VALUE = 'other';

  constructor() {
  }

  createInsuranceCategoryItems$(insuranceCategorys: InsuranceCategory[], options: {
    noSelectionLabel: string,
    includeNoSelectionOption: boolean,
    includeOtherOption: boolean,
    otherOptionLabel: string,
    usePublicLabel: boolean,
  }): Observable<InsuranceCategorySelectItem[]> {
    const allInsuranceCategorys = [];

    if (options.includeNoSelectionOption) {
      allInsuranceCategorys.push(null);
    }
    if (options.includeOtherOption) {
      allInsuranceCategorys.push(this.OTHER_OPTION_VALUE);
    }
    allInsuranceCategorys.push(...insuranceCategorys);

    const item$List: Observable<InsuranceCategorySelectItem>[]
      = allInsuranceCategorys.map(type => this.createInsuranceCategoryItem$(type, options));
    const itemList$ = item$List.length === 0 ? of([]) : forkJoin(item$List);

    return itemList$;
  }

  createInsuranceCategoryItem$(insuranceCategoryValue: InsuranceCategory | null | 'other', options: {
    noSelectionLabel: string,
    otherOptionLabel: string,
    usePublicLabel: boolean,
  }): Observable<InsuranceCategorySelectItem> {
    if (insuranceCategoryValue == null) {
      return of({
        value: null,
        label: options.noSelectionLabel,
        insuranceCategory: null,
      });
    } else if (insuranceCategoryValue === this.OTHER_OPTION_VALUE) {
      return of({
        value: this.OTHER_OPTION_VALUE,
        label: options.otherOptionLabel,
        insuranceCategory: null,
      });
    } else {
      const safeInsuranceCategory = insuranceCategoryValue as InsuranceCategory;
      return of({
        value: {id: safeInsuranceCategory.id},
        label: options.usePublicLabel ? safeInsuranceCategory.publicLabel : safeInsuranceCategory.internalLabel,
        insuranceCategory: insuranceCategoryValue,
      });
    }
  }

}
