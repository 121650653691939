import {Component, Input, OnInit} from '@angular/core';
import {PersonTitle, PersonTitleService, Ref} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-person-title',
  templateUrl: './person-title.component.html',
  styleUrls: ['./person-title.component.scss'],
})
export class PersonTitleComponent implements OnInit {

  @Input()
  set ref(value: Ref<PersonTitle>) {
    this.refSource$.next(value);
  }

  private refSource$ = new BehaviorSubject<Ref<PersonTitle>>(null);
  label$: Observable<string>;

  constructor(
    private personTitleService: PersonTitleService,
  ) {
  }

  ngOnInit() {
    const title$ = this.refSource$.pipe(
      switchMap(ref => this.fetchRef$(ref)),
      publishReplay(1), refCount(),
    );
    this.label$ = title$.pipe(
      map(title => title == null ? null : title.title),
      publishReplay(1), refCount(),
    );
  }

  private fetchRef$(ref: Ref<PersonTitle>) {
    if (ref == null) {
      return of(null);
    }
    return this.personTitleService.getPersonTitle$(ref);
  }
}
