import {Component, Input, OnInit} from '@angular/core';
import {MaritalStatusItemService} from './marital-status-item.service';
import {publishReplay, refCount} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MaritalStatusSelectItem} from './marital-status-select-item';
import {MaritalStatus, Ref} from '@lifeislife/lifeislife-domain';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-marital-status-select',
  templateUrl: './marital-status-select.component.html',
  styleUrls: ['./marital-status-select.component.scss'],
  providers: [MaritalStatusItemService, {
    provide: NG_VALUE_ACCESSOR,
    useExisting: MaritalStatusSelectComponent,
    multi: true,
  }],
})
export class MaritalStatusSelectComponent implements OnInit, ControlValueAccessor {

  @Input()
  private noSelectionlabel = `Aucun`;
  @Input()
  private showNoSelectionOption = true;
  @Input()
  disabled = false;
  @Input()
  hasError = false;

  allItems$: Observable<MaritalStatusSelectItem[]>;
  value: Ref<MaritalStatus>;

  private onChange: Function;
  private onTouched: Function;


  constructor(
    private itemService: MaritalStatusItemService,
  ) {
  }

  ngOnInit(): void {
    this.allItems$ = this.itemService.createAllMaritalStatusItems$({
      noSelectionLabel: this.noSelectionlabel,
      includeNoSelectionOption: this.showNoSelectionOption,
    }).pipe(
      publishReplay(1), refCount(),
    );
  }

  fireChanges() {
    if (this.onTouched) {
      this.onTouched();
    }
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
}
