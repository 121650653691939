import {Injectable} from '@angular/core';
import {WsDaoEventEntityType, WsRef, WsStoredFile, WsSubscriptionFile} from '@lifeislife/lifeislife-ws-api';
import {RequestService} from '../../../service/request.service';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {Observable} from 'rxjs';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionFileWsClient extends CachedWsResourceClient<WsSubscriptionFile, WsRef<WsSubscriptionFile>> {

  constructor(
    private injectedRequestService: RequestService,
    private injectedAuthService: AuthProvider,
    private injectectedCacheService: ResourceCacheService,
    private injectedAppConfigService: AppConfigService,
  ) {
    super('/front/subscriptionFile', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.SUBSCRIPTION_FILE, injectectedCacheService);
  }

  getFile(subscriptionFileId: number): Observable<WsStoredFile> {
    const url = this.getFileResourceUrl(subscriptionFileId);
    return this.requestService.sendRequest<WsStoredFile>({
      method: 'GET', url: url,
    }, this.authService.getAuth());
  }

  renameFile(subscriptionFileId: number, newFileName: string): Observable<WsRef<WsStoredFile>> {
    const url = `${this.getFileResourceUrl(subscriptionFileId)}/fileName`;
    const headers = new HttpHeaders()
      .append('Content-Type', 'text/plain')
      .append('Accept', 'application/json');

    return this.requestService.sendRequest<WsStoredFile>({
      method: 'PUT', url: url, body: newFileName, headers: headers,
    }, this.authService.getAuth()).pipe(
      tap(f => this.injectectedCacheService.clear(WsDaoEventEntityType.SUBSCRIPTION_FILE, f.id)),
    );
  }

  getFileThumbnailUrl$(subscriptionFileId: number): Observable<string> {
    const url = `${this.getFileResourceUrl(subscriptionFileId)}/thumbnailUrl`;
    const headers = new HttpHeaders()
      .append('Accept', 'text/plain');

    return this.requestService.sendRequest<string>({
      method: 'GET', url: url, headers: headers, responseType: 'text',
    }, this.authService.getAuth());
  }

  getFileDownloadUrl$(subscriptionFileId: number, contentDisposition?: 'inline' | 'attachment'): Observable<string> {
    const url = `${this.getFileResourceUrl(subscriptionFileId)}/contentUrl`;
    const headers = new HttpHeaders()
      .append('Accept', 'text/plain');
    let queryParams = new HttpParams();
    if (contentDisposition != null) {
      queryParams = queryParams.append('content-disposition', contentDisposition);
    }

    return this.requestService.sendRequest<string>({
      method: 'GET', url: url, headers: headers, responseType: 'text',
      params: queryParams,
    }, this.authService.getAuth());
  }


  private getFileResourceUrl(subscriptionFileId: number) {
    return `${this.getResourceUrl()}/${subscriptionFileId}/file`;
  }

}
