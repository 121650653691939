/**
 * Created by cghislai on 12/04/16.
 */


import {Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PhoneNumberService} from '../phone-number.service';
import {Country, Ref} from '@lifeislife/lifeislife-domain';


@Component({
  selector: 'phone-number-input',
  templateUrl: './phoneNumberInput.html',
  styleUrls: ['./phoneNumberInput.scss'],
  providers: [
    PhoneNumberService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberInputComponent),
      multi: true,
    }],
})
export class PhoneNumberInputComponent implements OnInit, ControlValueAccessor {

  @Input()
  disabled = false;
  @Input()
  hasError = false;
  // Holds the whole number as a ngModel-bound value
  phoneNumberPrefix = '';
  phoneNumberNumber = '';
  prefixTouched: boolean;
  private onChangeFunction: Function;
  private onTouchedFunction: Function;
  @ViewChild('prefixInput', {static: false})
  private prefixInput: ElementRef;
  @ViewChild('numberInput', {static: false})
  private numberInput: ElementRef;

  constructor(private phoneNumberService: PhoneNumberService) {
  }

  @Input()
  set countryRef(value: Ref<Country>) {
    this.setPhonePrefixFromCountry(value);
  }

  ngOnInit() {
  }

  writeValue(value: string): void {
    const parsed = this.phoneNumberService.parsePhoneNumberWithprefix(value);
    this.phoneNumberPrefix = parsed.prefix == null ? '+32' : parsed.prefix;
    this.phoneNumberNumber = parsed.number;
    if (parsed.guessed) {
      this.fireChange();
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFunction = fn;
  }

  onPrefixChanged(prefix: string) {
    const validPrefix = this.phoneNumberService.digitsOnly(prefix, 3);
    this.prefixTouched = true;
    this.phoneNumberPrefix = validPrefix;
    if (validPrefix.length >= 3) {
      this.numberInput.nativeElement.focus();
    }
    this.fireChange();
  }

  onNumberChanged(number: string) {
    const validNumber = this.phoneNumberService.digitsOnly(number, 10);
    this.phoneNumberNumber = validNumber;
    this.fireChange();
  }

  private setPhonePrefixFromCountry(ref: Ref<Country>) {
    if (ref != null && !this.prefixTouched) {
      // TODO: country-prefix table
      // this.phoneNumber.prefix = this.country.phonePrefix;
      // this.fireChange();
    }
  }

  private fireChange() {
    if (this.onTouchedFunction) {
      this.onTouchedFunction();
    }
    if (this.onChangeFunction) {
      const wholeNumber = this.getWholeNumber();
      this.onChangeFunction(wholeNumber);
    }
  }

  private getWholeNumber(): string {
    return `${this.phoneNumberPrefix}${this.phoneNumberNumber}`;
  }

}
