import {Injectable} from '@angular/core';
import {ALL_USER_THEMES, UserTheme} from '../model/user-theme';
import {KeyValueStore, WindowRef} from '@lifeislife/lifeislife-domain';

@Injectable({
  providedIn: 'root',
})
export class UserThemeService {

  private userThemeStorageKey = 'lifeislife.theme';

  constructor(
    private keyValueStore: KeyValueStore,
    private windowRef: WindowRef,
  ) {
  }

  loadStorageTheme(): UserTheme | null {
    const storageValue = this.keyValueStore.getValue(this.userThemeStorageKey);
    if (storageValue == null) {
      return null;
    }
    const isSupportedTheme = this.isSupportedTheme(storageValue);
    if (isSupportedTheme) {
      return storageValue as UserTheme;
    }
  }

  isSupportedTheme(stringValue: string | UserTheme) {
    return ALL_USER_THEMES.find(t => t === stringValue) != null;
  }

  setTheme(theme: UserTheme) {
    if (!this.isSupportedTheme(theme)) {
      return;
    }
    this.saveThemeToStorage(theme);

    const window = this.windowRef.getWindow();
    const document = window.document;
    const headLinksElements = document.getElementsByTagName('head')
      .item(0)
      .getElementsByTagName('link');
    for (let i = 0; i < headLinksElements.length; i++) {
      const linkElement = headLinksElements.item(i);
      const linkRelAttribute = linkElement.getAttribute('rel');
      if (linkRelAttribute !== 'stylesheet') {
        continue;
      }
      const isGestempsLink = linkElement.hasAttribute('lifeislife-link');
      if (!isGestempsLink) {
        continue;
      }
      this.setLinkStylesheetHref(linkElement, theme);
    }
  }

  getActiveTheme(): UserTheme | null {
    const window = this.windowRef.getWindow();
    const document = window.document;

    const headLinksElements = document.getElementsByTagName('head')
      .item(0)
      .getElementsByTagName('link');
    for (let i = 0; i < headLinksElements.length; i++) {
      const linkElement = headLinksElements.item(i);
      const linkRelAttribute = linkElement.getAttribute('rel');
      if (linkRelAttribute !== 'stylesheet') {
        continue;
      }
      const isGestempsLink = linkElement.hasAttribute('lifeislife-link');
      if (!isGestempsLink) {
        continue;
      }
      const linkTitleAttribute = linkElement.getAttribute('title');
      const linkEnabled = linkElement.disabled !== true;
      if (linkEnabled && this.isSupportedTheme(linkTitleAttribute)) {
        return linkTitleAttribute as UserTheme;
      }
    }
    return null;
  }

  private setLinkStylesheetHref(linkElement: HTMLLinkElement, theme: UserTheme) {
    const themeBundle = `theme-${theme}.css`;
    linkElement.href = themeBundle;
  }

  private saveThemeToStorage(theme: UserTheme) {
    this.keyValueStore.putValue(this.userThemeStorageKey, theme);
  }
}
