import {DateUtils} from '../../util/date/date-utils';
import {DateTime} from 'luxon';

export class DateConverterUtils {

  static parseIsoDate(dateString: string): Date {
    if (dateString == null) {
      return undefined;
    }
    const dateTime = DateTime.fromISO(dateString).toJSDate();
    return DateUtils.toDayOnlyDate(dateTime);
  }

  static parseIsoDateTime(dateString: string): Date {
    if (dateString == null) {
      return undefined;
    }
    const dateTime = DateTime.fromISO(dateString).toJSDate();
    return dateTime;
  }

  static formatDateToIsoDateTime(date: Date): string {
    if (date == null) {
      return undefined;
    }
    const dateTimeString = DateTime.fromJSDate(date)
      .toUTC(0, {keepLocalTime: true})
      .toISO({format: 'extended'});
    return dateTimeString;
  }


  static formatDateToIsoDate(date: Date): string {
    if (date == null) {
      return undefined;
    }
    const dateString = DateTime.fromJSDate(date)
      .toUTC(0, {keepLocalTime: true})
      .toISODate({format: 'extended'});
    return dateString;
  }


}
