import {Injectable} from '@angular/core';
import {BehaviorSubject, ConnectableObservable, Observable, of, Subscription} from 'rxjs';
import {delay, publishReplay, tap} from 'rxjs/operators';
import {FilesUploadProgress, FileUploadMethod, StoredFileService, WithId} from '@lifeislife/lifeislife-domain';
import {FileUploadRequest} from './file-upload-request';
import {FileSubresourcePath} from '@lifeislife/lifeislife-domain';

@Injectable({providedIn: 'root'})
export class FileUploadProgressService {

  private runningUploadSubscription: Subscription;
  private uplaoding$ = new BehaviorSubject<boolean>(false);

  constructor(private storedFileService: StoredFileService) {
  }

  uploadFiles$(method: FileUploadMethod, uploadPath: FileSubresourcePath<WithId>, files: File[] | FileList)
    : Observable<FilesUploadProgress | null> {
    const filesArray: File[] = [];
    if (files instanceof FileList) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        filesArray.push(file);
      }
    } else {
      filesArray.push(...files);
    }
    const uploadRequest = {
      files: filesArray,
      method: method,
      uploadPath: uploadPath,
    };
    return this.doUploadFiles$(uploadRequest);
  }

  getUploading$() {
    return this.uplaoding$;
  }

  cancelUpload() {
    if (this.runningUploadSubscription) {
      this.runningUploadSubscription.unsubscribe();
    }
  }

  private doUploadFiles$(request: FileUploadRequest): Observable<FilesUploadProgress | null> {
    if (request.files == null || request.files.length === 0) {
      return of(null);
    }
    this.uplaoding$.next(true);
    const upload$: ConnectableObservable<FilesUploadProgress | null> = this.storedFileService.uploadFiles$(
      request.method, request.uploadPath, ...request.files,
    ).pipe(
      delay(0),
      tap({
        error: () => this.uplaoding$.next(false),
        complete: () => this.uplaoding$.next(false),
      }),
      publishReplay(1),
    ) as ConnectableObservable<FilesUploadProgress | null>;
    this.runningUploadSubscription = upload$.connect();
    this.runningUploadSubscription.add(() => {
      this.uplaoding$.next(false);
    });


    return upload$;
  }
}
