import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectItem} from 'primeng/api';
import {CustomerContactType} from '@lifeislife/lifeislife-domain';
import {CustomerContactTypeItemsService} from '../customer-contact-type-items.service';

@Component({
  selector: 'app-customer-contact-type-select',
  templateUrl: './customer-contact-type-select.component.html',
  styleUrls: ['./customer-contact-type-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomerContactTypeSelectComponent),
    multi: true,
  }],
})
export class CustomerContactTypeSelectComponent implements OnInit, ControlValueAccessor {


  @Input()
  disabled = false;
  @Input()
  hasError = false;

  allItems: SelectItem[];
  selectedValue: CustomerContactType;

  private onChangeFunction: Function;
  private onTouchedFunction: Function;

  constructor(private itemService: CustomerContactTypeItemsService) {
  }

  ngOnInit() {
    this.itemService.createAllCustomerContactTypeItems$().subscribe(list => this.allItems = list);
  }

  writeValue(obj: CustomerContactType): void {
    this.selectedValue = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFunction = fn;
  }

  onChange(event: CustomerContactType) {
    this.selectedValue = event;
    this.fireChange();
  }


  fireChange() {
    this.onTouchedFunction();
    this.onChangeFunction(this.selectedValue);
  }

}
