import {Component, Input, OnInit} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {Contact, Ref, Trustee, TrusteeContactService, TrusteeService} from '@lifeislife/lifeislife-domain';
import {defaultIfEmpty, filter, map, publishReplay, refCount, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-trustee-main-contact-view',
  templateUrl: './trustee-main-contact-view.component.html',
  styleUrls: ['./trustee-main-contact-view.component.scss'],
})
export class TrusteeMainContactViewComponent implements OnInit {

  @Input()
  set trusteeRef(value: Ref<Trustee>) {
    this.trusteeRefSource$.next(value);
  }

  private trusteeRefSource$ = new ReplaySubject<Ref<Trustee>>(1);
  contactRef$: Observable<Ref<Contact>>;

  constructor(private trusteeService: TrusteeService,
              private trusteeContactService: TrusteeContactService,
  ) {
  }

  ngOnInit() {
    this.contactRef$ = this.trusteeRefSource$.pipe(
      switchMap(ref => this.fetchContactRef(ref)),
      publishReplay(1), refCount(),
    );
  }

  private fetchContactRef(customeRef: Ref<Trustee>): Observable<Ref<Contact>> {
    if (customeRef == null) {
      return of(null);
    }
    return this.trusteeService.getTrustee$(customeRef).pipe(
      filter(trustee => trustee != null),
      map(trustee => trustee.mainTrusteeContactRef),
      filter(trusteeContactRef => trusteeContactRef != null),
      switchMap(trusteeContactRef => this.trusteeContactService.getTrusteeContact$(trusteeContactRef)),
      filter(trusteeContact => trusteeContact != null),
      map(trusteeContact => trusteeContact.contactRef),
      defaultIfEmpty(null),
    );
  }
}
