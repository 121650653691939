import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {DateSearch} from '../../domain/shared/date-search';
import {WsDateSearch} from '@lifeislife/lifeislife-ws-api';

export class DateSearchConverter {

  static convertOut(dateSearch: DateSearch): WsDateSearch {
    if (dateSearch == null) {
      return null;
    }
    return {
      minDate: DateConverterUtils.formatDateToIsoDate(dateSearch.minDate),
      maxDateExclusive: DateConverterUtils.formatDateToIsoDate(dateSearch.maxDateExclusive),
    };
  }

  static convertIn(wsDateSearch: WsDateSearch): DateSearch {
    if (wsDateSearch == null) {
      return null;
    }
    return {
      minDate: DateConverterUtils.parseIsoDate(wsDateSearch.minDate),
      maxDateExclusive: DateConverterUtils.parseIsoDate(wsDateSearch.maxDateExclusive),
    };
  }

}
