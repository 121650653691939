import {WsCustomer, WsPaymentType} from '@lifeislife/lifeislife-ws-api';
import {Customer} from '../../domain/customer/customer';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {PaymentType} from '../../domain/customer/payment-type';
import {CustomerStatusConverter} from './customer-status.converter';

export class CustomerConverter {

  static convertIn(wsCustomer: WsCustomer): Customer {
    return {
      id: wsCustomer.id,
      version: wsCustomer.version,

      name: wsCustomer.name,
      customerTypeRef: wsCustomer.customerTypeWsRef,
      customerStatus: CustomerStatusConverter.toCustomerStatus(wsCustomer.customerStatus),
      trusteeRef: wsCustomer.trusteeWsRef,
      preferredPaymentType: wsCustomer.preferredPaymentType as string as PaymentType,
      mainCustomerContactRef: wsCustomer.mainCustomerContact,
      previousBroker: wsCustomer.previousBroker,
      vatNumber: wsCustomer.vatNumber,
      notes: wsCustomer.notes,
      template: wsCustomer.template,
      creationTime: DateConverterUtils.parseIsoDateTime(wsCustomer.creationTime),
      updateTime: DateConverterUtils.parseIsoDateTime(wsCustomer.updateTime),
      address: wsCustomer.address,
      zip: wsCustomer.zip,
      city: wsCustomer.city,
      countryRef: wsCustomer.countryWsRef,

      totalPricing: wsCustomer.totalPricing,
      cashBackPricing: wsCustomer.cashbackPricing,

      thirdPartyWsRef: wsCustomer.thirdPartyWsRef,
      vatNumberNotRequired: wsCustomer.vatNumberNotRequired,
      citizenCustomer: wsCustomer.citizenCustomer,

    };
  }

  static convertOut(customer: Customer): WsCustomer {
    return {
      id: customer.id,
      version: customer.version,

      name: customer.name,
      customerTypeWsRef: customer.customerTypeRef,
      customerStatus: CustomerStatusConverter.toWsCustomerStatus(customer.customerStatus),
      trusteeWsRef: customer.trusteeRef,
      preferredPaymentType: customer.preferredPaymentType as string as WsPaymentType,
      mainCustomerContact: customer.mainCustomerContactRef,
      previousBroker: customer.previousBroker,
      vatNumber: customer.vatNumber,
      notes: customer.notes,
      template: customer.template,
      creationTime: DateConverterUtils.formatDateToIsoDateTime(customer.creationTime),
      updateTime: DateConverterUtils.formatDateToIsoDateTime(customer.updateTime),
      address: customer.address,
      zip: customer.zip,
      city: customer.city,
      countryWsRef: customer.countryRef,
      totalPricing: customer.totalPricing,
      cashbackPricing: customer.cashBackPricing,
      thirdPartyWsRef: customer.thirdPartyWsRef,
      vatNumberNotRequired: customer.vatNumberNotRequired,
      citizenCustomer: customer.citizenCustomer,
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsCustomer]: keyof Customer } {
    return {
      id: 'id',
      version: 'version',
      name: 'name',
      customerTypeWsRef: 'customerTypeRef',
      customerStatus: 'customerStatus',
      trusteeWsRef: 'trusteeRef',
      preferredPaymentType: 'preferredPaymentType',
      mainCustomerContact: 'mainCustomerContactRef',
      previousBroker: 'previousBroker',
      vatNumber: 'vatNumber',
      notes: 'notes',
      template: 'template',
      creationTime: 'creationTime',
      updateTime: 'updateTime',
      address: 'address',
      zip: 'zip',
      city: 'city',
      countryWsRef: 'countryRef',
      totalPricing: 'totalPricing',
      cashbackPricing: 'cashBackPricing',
      thirdPartyWsRef: 'thirdPartyWsRef',
      vatNumberNotRequired: 'vatNumberNotRequired',
      citizenCustomer: 'citizenCustomer',
    };
  }


}
