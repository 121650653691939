import {Injectable} from '@angular/core';
import {AppConfigService, DateUtils, FrontendAppConfigKey, FrontendAppSwitch, KeyValueStore} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject} from 'rxjs';
import {AppHomeScreenInstallerService} from './app-home-screen-installer.service';


/**
 * This services requires events to be passed in from the components.
 * In your app compnent, listen for beforeinstallprompt and forward the event to this service:
 *
 * ```
 @HostListener('window:beforeinstallprompt', ['$event'])
 onBeforeInstallPrompt(event: Event) {
    this.appInstallService.onBeforeInstallPromt(event);
  }

 @HostListener('window:appInstalled', ['$event'])
 onAppInstalled(event: Event) {
    this.appInstallService.onAppInstalled(event);
  }
 ```
 */
@Injectable()
export class AppHomeScreenInstallerBrowserService extends AppHomeScreenInstallerService {

  private deferredPrompt: any;
  private asking = false;
  private launchedFromHomeScreen: boolean;

  canInstall$ = new BehaviorSubject<boolean>(false);

  constructor(
    private keyValueStore: KeyValueStore,
    private configService: AppConfigService,
  ) {
    super();
    this.launchedFromHomeScreen = this.checkLaunchedFromHomeScreen();
  }

  canInstallOnHomeScreen(): boolean {
    const fromHomeScreen = this.isLaunchedFromHomeScreen();
    const discardedInstall = this.hadDiscardedAppInstall();
    const hasPromptAvailable = this.deferredPrompt != null;
    const isCurrentlyAsking = this.asking === true;

    const mobileUserAgent = this.configService.isSwitchCurrentlyEnabled(FrontendAppSwitch.useragent_mobile);
    return !fromHomeScreen && !discardedInstall && hasPromptAvailable && !isCurrentlyAsking && mobileUserAgent;
  }

  isLaunchedFromHomeScreen(): boolean {
    return this.launchedFromHomeScreen;
  }

  discardAppInstall() {
    const storageKey = this.getInstallDiscardDateStorageKey();
    const dateString = new Date().toISOString();
    this.keyValueStore.putValue(storageKey, dateString);
    this.updateCanInstall();
  }

  hadDiscardedAppInstall() {
    const storageKey = this.getInstallDiscardDateStorageKey();
    const discardedTimeString = this.keyValueStore.getValue(storageKey);
    if (discardedTimeString == null) {
      return false;
    }

    const discardedMoment = DateUtils.parseDateAndTime(discardedTimeString);
    if (discardedMoment == null) {
      return false;
    }

    const discardDuration = this.configService.getCurrentConfigIntValue(FrontendAppConfigKey.appInstall_discardDuration_days)
      || 30;

    const isDiscardedSomeDaysAgo = DateUtils.isDistinctSortedDays(discardedMoment, new Date(), discardDuration);
    if (isDiscardedSomeDaysAgo) {
      this.keyValueStore.clearValue(storageKey);
      return false;
    } else {
      return true;
    }
  }

  askInstallToHomeScreen() {
    this.asking = true;
    this.deferredPrompt.prompt();
    this.updateCanInstall();
    const userChoice: Promise<any> = this.deferredPrompt.userChoice;
    userChoice.then(results => this.onInstallToHomeScreenAsked(results));
  }

  onBeforeInstallPromt(event: Event) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    event.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = event;

    this.updateCanInstall();
  }

  onAppInstalled(e: Event) {
    // TODO: notify backend
    // this.appInstallationClient.notifyAppInstalled()
    //   .subscribe(() => console.log('App installation notified'),
    //     error => console.warn(`App installation notification error: ${error}`));
  }

  private onInstallToHomeScreenAsked(result: any) {
    if (result.outcome !== 'accepted') {
      this.discardAppInstall();
    }
    this.deferredPrompt = null;
    this.asking = false;
    this.updateCanInstall();
  }


  private checkLaunchedFromHomeScreen(): boolean {
    const standaloneNavigator = this.configService.isSwitchCurrentlyEnabled(FrontendAppSwitch.navigator_stanalone);
    return standaloneNavigator;
  }

  private getInstallDiscardDateStorageKey() {
    const configKey = this.configService.getCurrentConfigValue(FrontendAppConfigKey.app_configKey);
    return `${configKey}.installDiscardDate`;
  }

  private updateCanInstall() {
    const canInstall = this.canInstallOnHomeScreen();
    this.canInstall$.next(canInstall);
  }

}
