import {WsCustomerContactSearch, WsCustomerContactType, WsCustomerContactTypeSearch} from '@lifeislife/lifeislife-ws-api';
import {CustomerContactSearch} from '../../domain/customer-contact/customer-contact-search';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {CustomerContactTypeSearch} from '../../domain/customer-contact/customer-contact-type-search';
import {CustomerContactType} from '../../domain/customer-contact/customer-contact-type';
import {ContactSearchConverter} from '../contact/contact-search.converter';

export class CustomerContactSearchConverter {

  static toCustomerContactSearch(value: WsCustomerContactSearch): CustomerContactSearch {
    if (value == null) {
      return undefined;
    }
    return {
      trusteeRef: value.trusteeRef,
      customerRef: value.customerRef,
      contactRef: value.contactRef,
      customerContactTypeSearch: CustomerContactSearchConverter.toCustomerContactTypeSearch(value.customerContactTypeSearch),
      contactFilter: ContactSearchConverter.toContactSearch(value.contactSearch),
      exactCustomerContactRef: value.exactCustomerContactRef,
      empty: value.empty,
    };
  }

  static toCustomerContactTypeSearch(value: WsCustomerContactTypeSearch) {
    if (value == null) {
      return undefined;
    }
    return {
      customerContactTypes: value.mailContactTypes == null ? [] : value.mailContactTypes.map(
        wsType => EnumConverterUtils.convertValue(wsType, CustomerContactType),
      ),
      internal: value.internal,
    };
  }

  static toWsCustomerContactSearch(value: CustomerContactSearch): WsCustomerContactSearch {
    if (value == null) {
      return undefined;
    }
    return {
      trusteeRef: value.trusteeRef,
      customerRef: value.customerRef,
      contactRef: value.contactRef,
      customerContactTypeSearch: CustomerContactSearchConverter.toWsCustomerContactTypeSearch(value.customerContactTypeSearch),
      contactSearch: ContactSearchConverter.toWsContactSearch(value.contactFilter),
      exactCustomerContactRef: value.exactCustomerContactRef,
      empty: value.empty,
    };
  }

  static toWsCustomerContactTypeSearch(value: CustomerContactTypeSearch): WsCustomerContactTypeSearch {
    if (value == null) {
      return undefined;
    }
    return {
      mailContactTypes: value.customerContactTypes == null ? [] : value.customerContactTypes.map(
        type => EnumConverterUtils.convertValue(type, WsCustomerContactType),
      ),
      internal: value.internal,
    };
  }


}
