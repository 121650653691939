import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {InsuranceCategory, InsuranceCategoryFamily, InsuranceCategoryService, InsuranceType, Ref} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';

@Component({
  selector: 'llc-insurance-category',
  templateUrl: './insurance-category.component.html',
  styleUrls: ['./insurance-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceCategoryComponent implements OnInit {

  @Input()
  set ref(value: Ref<InsuranceCategory>) {
    this.refSource$.next(value);
  }

  @Input()
  showFamilyBadge: boolean;
  @Input()
  showTypeBadge: boolean;
  @Input()
  wrap = true;
  @Input()
  wrapText: boolean;
  @Input()
  labelType: 'public' | 'internal' = 'public';

  label$: Observable<string>;
  publicLabel$: Observable<string>;
  family$: Observable<InsuranceCategoryFamily>;
  type$: Observable<InsuranceType>;
  private refSource$ = new BehaviorSubject<Ref<InsuranceCategory> | null>(null);

  constructor(
    private insuranceCategoryService: InsuranceCategoryService,
  ) {
  }

  ngOnInit() {
    const category$ = this.refSource$.pipe(
      switchMap(ref => this.searchRef$(ref)),
      publishReplay(1), refCount(),
    );

    this.label$ = category$.pipe(
      map(category => category == null ? '' : category.internalLabel),
      publishReplay(1), refCount(),
    );
    this.publicLabel$ = category$.pipe(
      map(category => category == null ? '' : category.publicLabel),
      publishReplay(1), refCount(),
    );

    this.family$ = category$.pipe(
      map(category => category == null ? null : category.insuranceCategoryFamily),
      publishReplay(1), refCount(),
    );

    this.type$ = category$.pipe(
      map(category => category == null ? null : category.insuranceCategoryFamilyType),
      publishReplay(1), refCount(),
    );

  }

  private searchRef$(ref: Ref<InsuranceCategory>): Observable<InsuranceCategory> {
    if (ref == null) {
      return of(null);
    }
    return this.insuranceCategoryService.getInsuranceCategory$(ref);
  }
}
