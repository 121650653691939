/**
 * Created by cghislai on 07/09/16.
 */
import {WithId} from '@lifeislife/lifeislife-domain';

export class ObjectUtils {

  static listDifferentProperties<T = any>(objectA: T, objectB: T): string[] {
    if (objectA == null || objectB == null) {
      return [];
    }
    const keys1 = Object.getOwnPropertyNames(objectA);
    const keys2 = Object.getOwnPropertyNames(objectB);
    const mismatchKeys: string[] = [];
    for (const key of keys1) {
      const val1 = objectA[key];
      const val2 = objectB[key];
      if (
        val1 != val2 // Compare with type conversion intended (wants null == undefined)
        && (!this.isRef(val1) || !ObjectUtils.isSameRef(val1, val2))
        && (!this.isRefArray(val1) || !ObjectUtils.isSameRefArray(val1, val2))
      ) {
        mismatchKeys.push(<string>key);
      }
    }
    for (const key of keys2) {
      // undefined
      if (objectB[key] == null && objectA[key] == null) {
        continue;
      }
      if (keys1.find(k => k === key) != null) {
        continue;
      }
      mismatchKeys.push(<string>key);
    }

    return mismatchKeys;
  }

  static isSameRef(val1: WithId, val2: WithId): boolean {
    if (val1 == null && val2 == null) {
      return true;
    }
    if (val1 == null || val2 == null) {
      return false;
    }
    const id1 = val1['id'];
    const id2 = val2['id'];
    return id1 != null && id2 != null && id1 === id2;
  }

  static reduceUniqueRefs<T extends WithId = WithId>(cur: T[], next: T): T[] {
    const safeCur = cur || [];
    const included = safeCur.findIndex(c => this.isSameRef(c, next)) >= 0;
    if (!included) {
      safeCur.push(next);
    }
    return safeCur;
  }

  static isSameArray(val1: any[], val2: any[], valueComparator?: (val1, val2) => boolean): boolean {
    if (val1 == null && val2 == null) {
      return true;
    }
    if (val1 == null || val2 == null) {
      return false;
    }
    if (val1.length !== val2.length) {
      return false;
    }
    let comparator: (val1, val2) => boolean;
    if (valueComparator == null) {
      comparator = (v1, v2) => v1 === v2;
    } else {
      comparator = valueComparator;
    }
    for (let i = 0; i < val1.length; i++) {
      if (!comparator(val1[i], val2[i])) {
        return false;
      }
    }
    return true;
  }

  static arrayIncludes<T = any>(array: T[], value: T): boolean {
    if (array == null) {
      return false;
    }
    const index = array.indexOf(value);
    return index >= 0;
  }

  private static isSameRefArray(val1: any, val2: any) {
    if (typeof val1 !== 'object') {
      return false;
    }
    if (typeof val2 !== 'object') {
      return false;
    }
    return ObjectUtils.isSameArray(val1, val2,
      (ref1, ref2) => ObjectUtils.isSameRef(ref1, ref2));
  }

  private static isRef(val) {
    if (val == null) {
      return false;
    }
    const keys = Object.getOwnPropertyNames(val);
    return keys.length === 1 && keys[0] === 'id' && val['id'] != null;
  }


  private static isRefArray(val: any) {
    if (val == null) {
      return false;
    }
    if (typeof val !== 'object') {
      return false;
    }
    if (val.length == null) {
      return false;
    }
    if (val.length < 1) {
      return true;
    }
    return this.isRef(val[0]);
  }


}
