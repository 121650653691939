import {WsCountry, WsCountrySearch} from '@lifeislife/lifeislife-ws-api';
import {Country} from '../../domain/country/country';
import {Injectable} from '@angular/core';
import {SearchResult} from '../../client/domain/search/search-result';
import {CountryFilter} from '../../domain/country/country-filter';

export class CountryConverter {

  static convertIn(wsCountry: WsCountry): Country {
    return {
      id: wsCountry.id,
      code: wsCountry.code,
      nameFr: wsCountry.nameFr,
      defaultVatRate: wsCountry.defaultVatRate,
    };
  }

  static counvertOut(country: Country): WsCountry {
    return {
      id: country.id,
      code: country.code,
      nameFr: country.nameFr,
      defaultVatRate: country.defaultVatRate,
    };
  }


  static convertFilterIn(value?: WsCountrySearch): CountryFilter {
    if (value == null) {
      return undefined;
    }
    return {
      code: value.code,
      nameFr: value.nameFr,
      nameFrContains: value.nameFrContains,
    };
  }

  static convertFilterOut(value: CountryFilter): WsCountrySearch {
    if (value == null) {
      return undefined;
    }
    return {
      code: value.code,
      nameFr: value.nameFr,
      nameFrContains: value.nameFrContains,
    };
  }


  static convertSearchResultsIn(results: SearchResult<WsCountry>): SearchResult<Country> {
    return Object.assign({}, results, <Partial<SearchResult<Country>>>{
      list: results.list.map(result => this.convertIn(result)),
    });
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsCountry]: keyof Country } {
    return {
      defaultVatRate: 'defaultVatRate',
      nameFr: 'nameFr',
      code: 'code',
      id: 'id',
    };
  }
}
