import {Injectable} from '@angular/core';
import {
  WsDaoEventEntityType,
  WsRef,
  WsSubscriptionSigningPackage,
  WsSubscriptionSigningPackageRequest,
} from '@lifeislife/lifeislife-ws-api';
import {RequestService} from '../../../service/request.service';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {Observable} from 'rxjs';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionSigningPackageWsClient extends CachedWsResourceClient<WsSubscriptionSigningPackage, WsRef<WsSubscriptionSigningPackage>> {

  constructor(
    private injectedRequestService: RequestService,
    private injectedAuthService: AuthProvider,
    private injectectedCacheService: ResourceCacheService,
    private injectedAppConfigService: AppConfigService,
  ) {
    super('/front/subscriptionSigningPackage', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.SUBSCRIPTION_SIGNING_PACKAGE, injectectedCacheService);
  }

  createPackage(req: WsSubscriptionSigningPackageRequest): Observable<WsRef<WsSubscriptionSigningPackage>> {
    const url = this.getResourceUrl();
    return this.requestService.sendRequest<WsRef<WsSubscriptionSigningPackage>>({
      method: 'POST', url: url, body: req,
    }, this.authService.getAuth());
  }

}
