import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, forkJoin, Observable, of, timer} from 'rxjs';
import {
  CustomerService,
  Ref,
  SearchResult,
  SigningPackage,
  SigningPackageSearch,
  SigningPackageService,
  SigningPackageStatus,
  SimplePagination,
  SimplePaginationUtils,
  SortOrder,
  Subscription,
  SubscriptionService,
  SubscriptionSigningPackage,
  SubscriptionSigningPackageSearch,
  SubscriptionSigningPackageService,
  Trustee,
} from '@lifeislife/lifeislife-domain';
import {map, publishReplay, refCount, switchMap, tap} from 'rxjs/operators';
import {SubscriptionSigningPackageWithSigningPackage} from './subscription-signing-package-with-signing-package';

@Injectable()
export class SubscriptionSigningPackagesHelperService {

  private reloadSource$ = new BehaviorSubject<any>(null);
  private filter$ = new BehaviorSubject<SubscriptionSigningPackageSearch>(null);

  pagination$ = new BehaviorSubject<SimplePagination>(SimplePaginationUtils.newPagination(10, {
    field: 'PACKAGE_DESCRIPTION',
    order: SortOrder.ASCENDING,
  }));
  loading$ = new BehaviorSubject<boolean>(false);

  results$: Observable<SearchResult<SubscriptionSigningPackage>>;
  subscriptionSigningPackages$: Observable<SubscriptionSigningPackage[]>;
  subscriptionSigningPackagesWithSigningPackages$: Observable<SubscriptionSigningPackageWithSigningPackage[]>;
  signingPackageRefs$: Observable<Ref<SigningPackage>[]>;
  totalCount$: Observable<number>;

  constructor(
    private subscriptionSigningPackagesService: SubscriptionSigningPackageService,
    private subscriptionService: SubscriptionService,
    private signingPackageService: SigningPackageService,
    private customerService: CustomerService,
  ) {
    this.results$ = combineLatest([this.filter$, this.pagination$, this.reloadSource$]).pipe(
      switchMap(r => this.search$(r[0], r[1])),
      publishReplay(1), refCount(),
    );
    this.signingPackageRefs$ = this.results$.pipe(
      map(r => r == null ? [] : r.list.map(s => s.signingPackageRef)),
      publishReplay(1), refCount(),
    );
    this.totalCount$ = this.results$.pipe(
      map(r => r == null ? 0 : r.count),
      publishReplay(1), refCount(),
    );
    this.subscriptionSigningPackages$ = this.results$.pipe(
      map(r => r == null ? [] : r.list),
      publishReplay(1), refCount(),
    );
    this.subscriptionSigningPackagesWithSigningPackages$ = this.subscriptionSigningPackages$.pipe(
      switchMap(r => this.fetchSubscriptionSigningPackageSigningPackages$(r)),
      publishReplay(1), refCount(),
    );
  }

  clear() {
    this.filter$.next(null);
  }

  initForSubscriptionRef(subscriptionRef: Ref<Subscription>) {
    if (subscriptionRef == null) {
      return;
    }
    const trusteeRef$ = this.subscriptionService.getSubscription$(subscriptionRef).pipe(
      switchMap(subscription => this.customerService.getCustomer$(subscription.customerRef)),
      map(custoer => custoer.trusteeRef),
    );
    trusteeRef$.pipe(
      map(trusteeRef => this.createFilter(subscriptionRef, trusteeRef)),
    ).subscribe(f => this.filter$.next(f));
  }

  updateSigningPackageSearch(query?: string, includeAllStatus?: boolean) {
    const curFilter = this.filter$.getValue();
    if (curFilter == null) {
      return;
    }
    const curSigningPackageFilter = curFilter.signingPackageSearch || {} as SigningPackageSearch;
    const nameContainsValue = (query == null || query.length === 0) ? null : query;
    const anyStatusListValue = includeAllStatus ? null : [SigningPackageStatus.WAIITING_SIGNATURE, SigningPackageStatus.DRAFT, SigningPackageStatus.REJECTED];
    const newSigningPackageSearch: SigningPackageSearch = Object.assign({}, curSigningPackageFilter, <Partial<SigningPackageSearch>>{
      nameContains: nameContainsValue,
      anyStatusList: anyStatusListValue,
    });
    const newFilter = Object.assign({}, curFilter, <Partial<SubscriptionSigningPackageSearch>>{
      signingPackageSearch: newSigningPackageSearch,
    });
    this.filter$.next(newFilter);
  }

  reload() {
    this.reloadSource$.next(true);
  }

  private search$(searcHFilter: SubscriptionSigningPackageSearch, pagination: SimplePagination): Observable<SearchResult<SubscriptionSigningPackage>> {
    if (searcHFilter == null || pagination == null) {
      return of(null);
    }
    return timer(100).pipe(
      tap(() => this.loading$.next(true)),
      switchMap(() => this.subscriptionSigningPackagesService.searchSubscriptionSigningPackages$(searcHFilter, pagination)),
      tap(() => this.loading$.next(false)),
    );
  }

  private createFilter(subscriptionRef: Ref<Subscription>, trusteeRef: Ref<Trustee>) {
    const searchFilter: SubscriptionSigningPackageSearch = {
      subscriptionSearch: {
        subscriptionRef: subscriptionRef,
        customerSearch: {
          trusteeSearch: {
            exactTrusteeRef: trusteeRef,
          },
        },
      },
      signingPackageSearch: {
        customerSearch: {
          trusteeSearch: {
            exactTrusteeRef: trusteeRef,
          },
        },
      },
    };
    return searchFilter;
  }

  private fetchSubscriptionSigningPackageSigningPackages$(signingPackages: SubscriptionSigningPackage[]): Observable<SubscriptionSigningPackageWithSigningPackage[]> {
    if (signingPackages == null || signingPackages.length === 0) {
      return of([]);
    }
    const signingPackages$List = signingPackages.map(f => this.signingPackageService.getSigningPackage$(f.signingPackageRef).pipe(
      map(storefSigningPackage => Object.assign({}, f, {
        signingPackage: storefSigningPackage,
      })),
    ));
    return signingPackages$List.length === 0 ? of([]) : forkJoin(signingPackages$List);
  }
}
