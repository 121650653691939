import {DateRangeSearch} from '../../domain/shared/date-range-search';
import {WsDateRangeSearch} from '@lifeislife/lifeislife-ws-api';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';

export class DateRangeSearchConverter {

  static convertIn(value: WsDateRangeSearch): DateRangeSearch {
    if (value == null) {
      return undefined;
    }
    return {
      maxEndDate: DateConverterUtils.parseIsoDate(value.maxEndDate),
      maxStartDate: DateConverterUtils.parseIsoDate(value.maxStartDate),
      minEndDate: DateConverterUtils.parseIsoDate(value.minEndDate),
      minStartDate: DateConverterUtils.parseIsoDate(value.minStartDate),
    };
  }

  static convertOut(value: DateRangeSearch): WsDateRangeSearch {
    if (value == null) {
      return undefined;
    }
    return {
      maxEndDate: DateConverterUtils.formatDateToIsoDate(value.maxEndDate),
      maxStartDate: DateConverterUtils.formatDateToIsoDate(value.maxStartDate),
      minEndDate: DateConverterUtils.formatDateToIsoDate(value.minEndDate),
      minStartDate: DateConverterUtils.formatDateToIsoDate(value.minStartDate),
    };
  }

}
