import {Injectable} from '@angular/core';
import {RequestService} from '../../../service/request.service';
import {
  WsDaoEventEntityType,
  WsInsuranceCategory,
  WsInsuranceSubscriptionStatus,
  WsInsuranceSubscriptionStatusSearch,
  WsRef,
} from '@lifeislife/lifeislife-ws-api';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {Observable, of} from 'rxjs';
import {
  WsInsuranceCategoryOptionalFeature,
  WsLabelledField,
} from '@lifeislife/lifeislife-ws-api';
import {FrontendAppConfigKey} from '../../../../domain/config/frontend-app-config-key';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InsuranceCategoryWsClient extends CachedWsResourceClient<WsInsuranceCategory, WsRef<WsInsuranceCategory>> {

  private allFeaturesCache: WsLabelledField<WsInsuranceCategoryOptionalFeature>[] = null;

  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private injectedAppConfigService: AppConfigService) {
    super('/front/insuranceCategory', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.INSURANCE_CATEGORY, injectectedCacheService);
  }

  getFamilyEnabledOptionalFeatures$(ref: WsRef<WsInsuranceCategory>): Observable<WsLabelledField<WsInsuranceCategoryOptionalFeature>[]> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/front/insuranceCategory/${ref.id}/optionalFeatures/fromFamily`;
    return this.injectedRequestService.sendRequest<WsLabelledField<WsInsuranceCategoryOptionalFeature>[]>({
      method: 'GET', url: url,
    }, this.injectedAuthService.getAuth());
  }

  getAllOptionalFeatures$(skipCache?: boolean): Observable<WsLabelledField<WsInsuranceCategoryOptionalFeature>[]> {
    if (!skipCache && this.allFeaturesCache) {
      return of(this.allFeaturesCache);
    }
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/front/insuranceCategory/optionalFeatures/all`;
    return this.injectedRequestService.sendRequest<WsLabelledField<WsInsuranceCategoryOptionalFeature>[]>({
      method: 'GET', url: url,
    }, this.injectedAuthService.getAuth()).pipe(
      tap(r => this.allFeaturesCache = r),
    );
  }

}
