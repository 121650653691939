import {Injectable} from '@angular/core';
import {RequestService} from '../../../service/request.service';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {FrontendAppConfigKey} from '../../../../domain/config/frontend-app-config-key';

@Injectable({
  providedIn: 'root',
})
export class StoredFilesWsClient {

  constructor(
    private requestService: RequestService,
    private authService: AuthProvider,
    private appConfigService: AppConfigService,

  ) {
  }

  getFileArchiveDownloadUrl(idList: number[]) {
    let url = `${this.getFilesResourceUrl()}/contentArchive`;
    const auth = this.authService.getAuth();

    if (auth != null) {
      const authQueryString = auth.authorizationQueryString;
      url += `?${authQueryString}`;
    } else {
      url += '?auth=unknown';
    }

    for (const id of idList) {
      url += `&idList=${id}`;
    }
    return url;
  }

  private getFilesResourceUrl() {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    return wsUri + '/front/files';
  }

}
