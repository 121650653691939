import {Customer} from './customer';
import {Ref} from '../shared/ref';
import {PaymentType} from './payment-type';
import {CustomerTypeSearch} from './customer-type-search';
import {CustomerStatusSearch} from './customer-status-search';
import {TrusteeSearch} from '../trustee/trustee-search';
import {CustomerContactSearch} from '../customer-contact/customer-contact-search';
import {ObjectConverterUtil} from '../../service/object-converter-util';
import {CustomerSearchConverter} from '../../service/customer/customer-search.converter';

export class CustomerSearch {
  exactCustomerRef?: Ref<Customer>;
  anyCustomerRefList?: Ref<Customer>[];
  nameContains?: string;
  customerTypeSearch?: CustomerTypeSearch;
  customerStatusSearch?: CustomerStatusSearch;
  trusteeSearch?: TrusteeSearch;
  preferredPaymentTypeList?: PaymentType[];
  mainCustomerContactSearch?: CustomerContactSearch;
  previousBrokerContains?: string;
  vatNumberContains?: string;
  notesContains?: string;
  minCreationTime?: Date;
  maxCreationTime?: Date;
  minUpdateTime?: Date;
  maxUpdateTime?: Date;
  template?: boolean;
  withActiveSubscriptions?: boolean;
  citizenCustomer?: boolean;


  static serializeFilter(customerSearch: CustomerSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(customerSearch);
    const wsCustomerSearch = CustomerSearchConverter.toWsCustomerSearch(customerSearch);
    return JSON.stringify(wsCustomerSearch);
  }

  static deserializeFilter(valueString: string | null): CustomerSearch | null {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: any = JSON.parse(valueString);
      const filter = CustomerSearchConverter.toCustomerSearch(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }
}

