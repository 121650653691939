import {Injectable} from '@angular/core';
import {RequestService} from '../../../service/request.service';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {WsCustomerContactType, WsCustomerContactTypeModel} from '@lifeislife/lifeislife-ws-api';
import {tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {FrontendAppConfigKey} from '../../../../domain/config/frontend-app-config-key';

@Injectable({
  providedIn: 'root',
})
export class CustomerContactTypeWsClient {

  cachedList: WsCustomerContactTypeModel[];

  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private injectedAppConfigService: AppConfigService) {
  }

  clearModelCache() {
    this.cachedList = null;
  }

  searchAll$(): Observable<WsCustomerContactTypeModel[]> {
    if (this.cachedList) {
      return of(this.cachedList);
    }
    const wsUri = this.injectedAppConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/front/customerContactType/list`;
    return this.injectedRequestService.sendRequest<WsCustomerContactTypeModel[]>({
      url: url,
      method: 'GET',
      throughServiceWorker: false,
    }, this.injectedAuthService.getAuth()).pipe(
      tap(list => this.cachedList = list),
    );
  }
}
