import {Injectable} from '@angular/core';
import {
  WsConstraintViolation,
  WsInvestmentSubscription,
  WsInvestmentSubscriptionSearch,
  WsRef,
  WsSubscriptionGroupSummary,
} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../../util/pagination/simple-pagination';
import {SearchResult} from '../../../client/domain/search/search-result';
import {ValidationResult} from '../../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../../object-converter-util';
import {isValidRef, Ref} from '../../../domain/shared/ref';
import {WithId} from '../../../client/domain/with-id';
import {InvestmentStatus} from '../../../domain/corebusiness/investment/investment-status';
import {InvestmentSubscriptionWsClient} from '../../../client/resources/front/corebusiness/investment-subscription-ws-client';
import {InvestmentSubscriptionConverter} from './investment-subscription-converter';
import {InvestmentSubscription} from '../../../domain/corebusiness/investment/investment-subscription';
import {InvestmentSubscriptionFilter} from '../../../domain/corebusiness/investment/investment-subscription-filter';
import {SubscriptionGroupSummary} from '../../../domain/corebusiness/subscription-group-summary';
import {SubscriptionGroupSummaryConverter} from '../subscription-group-summary.converter';
import {SubscriptionService} from '../subscription.service';
import {SimplePaginationUtils} from '../../../util/pagination/simple-pagination-utils';
import {Subscription} from '../../../domain/corebusiness/subscription';
import {Customer} from '../../../domain/customer/customer';

@Injectable({
  providedIn: 'root',
})
export class InvestmentSubscriptionService {


  constructor(private client: InvestmentSubscriptionWsClient,
              private subscriptionService: SubscriptionService,
  ) {
  }

  getInvestmentSubscription$(ref: Ref<InvestmentSubscription>, forceFetch?: boolean): Observable<InvestmentSubscription> {
    return ref == null ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsInvestmentSubscription => InvestmentSubscriptionConverter.convertIn(wsInvestmentSubscription)),
    );
  }

  getInvestmentForProductSubscription$(subscriptionRef: Ref<Subscription>, customerRef: Ref<Customer>): Observable<InvestmentSubscription> {
    const investmentSearch: InvestmentSubscriptionFilter = {
      subscriptionSearch: {
        subscriptionRef: subscriptionRef,
        customerSearch: {
          exactCustomerRef: customerRef,
        },
      },
    };
    const pagination = SimplePaginationUtils.newPagination(1);
    return this.searchInvestmentSubscriptions$(investmentSearch, pagination).pipe(
      map(r => r.count === 0 ? null : r.list[0]),
    );
  }

  getInvestmentSubscriptionName$(investmentSubscription: InvestmentSubscription): Observable<string> {
    if (investmentSubscription == null || investmentSubscription.id == null || investmentSubscription.subscriptionRef == null) {
      return of(`Nouveau contrat d'investissement`);
    }
    return this.subscriptionService.getSubscription$(investmentSubscription.subscriptionRef).pipe(
      switchMap(s => this.subscriptionService.getSubscriptionName$(s)),
    );
  }

  getInvestmentSubscriptionStatusName(investmentSubscriptionStatus: InvestmentStatus): string {
    if (investmentSubscriptionStatus == null) {
      return null;
    }
    switch (investmentSubscriptionStatus) {
      case InvestmentStatus.ARCHIVED:
        return `Archivé`;
      case InvestmentStatus.PAYED:
        return `Payé`;
      case InvestmentStatus.ACTIVE:
        return `Actif`;
      case InvestmentStatus.CANCELED:
        return `Annulé`;
      case InvestmentStatus.REQUESTED_BY_CUSTOMER:
        return `Demandé par le client`;
      case InvestmentStatus.REQUESTED_TO_PROVIDER:
        return `Demandé à la compagnie`;
      default:
        throw new Error(`Unahdnled InvestmentStatus: ${investmentSubscriptionStatus}`);
    }
  }

  searchInvestmentSubscriptions$(investmentSubscriptionFilter: InvestmentSubscriptionFilter, pagination: SimplePagination)
    : Observable<SearchResult<InvestmentSubscription>> {
    const wsSearch = InvestmentSubscriptionConverter.convertFilterOut(investmentSubscriptionFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  searchSubscriptionsGroupSummaries$(subscriptionSearch: InvestmentSubscriptionFilter, pagination: SimplePagination)
    : Observable<SearchResult<SubscriptionGroupSummary>> {
    const wsSearch = InvestmentSubscriptionConverter.convertFilterOut(subscriptionSearch);
    return this.client.doSearchGroupSummaries(wsSearch, pagination).pipe(
      switchMap(results => this.convertGroupSummariesResultsIn$(results)),
    );
  }

  validateInvestmentSubscription$(investmentSubscription: InvestmentSubscription): Observable<ValidationResult<InvestmentSubscription>> {
    const wsInvestmentSubscription = InvestmentSubscriptionConverter.convertOut(investmentSubscription);
    return this.client.doValidate(wsInvestmentSubscription).pipe(
      map((results: WsInvestmentSubscription | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveInvestmentSubscription$(investmentSubscription: InvestmentSubscription): Observable<WithId> {
    const wsInvestmentSubscription = InvestmentSubscriptionConverter.convertOut(investmentSubscription);
    return this.client.doSave(wsInvestmentSubscription);
  }

  deleteInvestmentSubscription$(ref: Ref<InvestmentSubscription>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  serializeFilter(investmentSubscriptionFilter: InvestmentSubscriptionFilter): string {
    ObjectConverterUtil.cleanUnsetFilterValues(investmentSubscriptionFilter);
    const wsInvestmentSearch = InvestmentSubscriptionConverter.convertFilterOut(investmentSubscriptionFilter);
    return JSON.stringify(wsInvestmentSearch);
  }

  deserializeFilter(valueString: string | null): InvestmentSubscriptionFilter {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsInvestmentSubscriptionSearch = JSON.parse(valueString);
      const filter = InvestmentSubscriptionConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsInvestmentSubscription | WsConstraintViolation[]): ValidationResult<InvestmentSubscription> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsInvestmentSubscription => InvestmentSubscriptionConverter.convertIn(wsInvestmentSubscription),
      propertyNameMappings: InvestmentSubscriptionConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsInvestmentSubscription>>): Observable<SearchResult<InvestmentSubscription>> {
    const rowTasks = results.list.map(ref => this.getInvestmentSubscription$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<InvestmentSubscription>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<InvestmentSubscription>>>{
          list: rows,
        }),
      ));
  }


  private convertGroupSummariesResultsIn$(results: SearchResult<WsSubscriptionGroupSummary>) {
    const groupSummaries = results.list.map(wsSummary => SubscriptionGroupSummaryConverter.convertIn(wsSummary));
    const convertedResult = Object.assign({}, results, {
      list: groupSummaries,
    });
    return of(convertedResult);
  }

}
