import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {debounceTime, filter, map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {ContactService, Customer, CustomerService, Ref, Role, Trustee, TrusteeService} from '@lifeislife/lifeislife-domain';
import {AppShellContextProviderService} from '../../../../shell/app-shell-context-provider.service';
import {ApplicationNavigationService} from '../../../../application-navigation.service';
import {Contact} from '@lifeislife/lifeislife-domain';
import {CustomerContactService} from '@lifeislife/lifeislife-domain';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerComponent implements OnInit {

  @Input()
  set ref(ref: Ref<Customer>) {
    this.refSource$.next(ref);
  }

  @Input()
  set jumLinkContextTrusteeRef(ref: Ref<Trustee>) {
    this.jumpLinkContextTrusteeRefSource$.next(ref);
  }

  @Input()
  showLabel = true;
  @Input()
  showTrusteeName: boolean;
  @Input()
  showContactNames: boolean;
  @Input()
  showJumpLink = true;
  @Input()
  wrap: boolean;
  @Input()
  wrapText = true;

  private refSource$ = new BehaviorSubject<Ref<Customer>>(null);

  customerName$: Observable<string>;
  trusteeRef$: Observable<Ref<Trustee>>;
  contactRef$: Observable<Ref<Contact>>;

  jumpLinkContextTrusteeRefSource$ = new BehaviorSubject<Ref<Trustee> | null>(null);
  jumpCustomerLinkVisible$: Observable<boolean>;
  jumpCustomerRouterLink$: Observable<any[]>;

  constructor(
    private customerService: CustomerService,
    private appContextservice: AppShellContextProviderService,
    private navigationService: ApplicationNavigationService,
    private trusteeService: TrusteeService,
    private cusotmerContactService: CustomerContactService,
    private router: Router) {
  }

  ngOnInit() {
    const customer$ = this.refSource$.pipe(
      switchMap(ref => ref == null ? of(null) : this.customerService.getCustomer$(ref)),
      publishReplay(1), refCount(),
    );
    this.trusteeRef$ = customer$.pipe(
      map(c => c == null ? null : c.trusteeRef),
      publishReplay(1), refCount(),
    );
    this.contactRef$ = customer$.pipe(
      map(c => c == null ? null : c.mainCustomerContactRef),
      switchMap(c => c == null ? of(null) : this.cusotmerContactService.getCustomerContact$(c)),
      map(c => c == null ? null : c.contactRef),
      publishReplay(1), refCount(),
    );
    this.customerName$ = customer$.pipe(
      map(c => this.customerService.getCustomerName(c)),
      publishReplay(1), refCount(),
    );

    this.jumpCustomerLinkVisible$ = this.appContextservice.getActiveContext$().pipe(
      map(c => c != null && c.activeRole != null && c.activeRole !== Role.CUSTOMER),
      publishReplay(1), refCount(),
    );
    this.jumpCustomerRouterLink$ = combineLatest([this.refSource$, this.jumpLinkContextTrusteeRefSource$]).pipe(
      map(r => this.navigationService.createCustomerDetailsRouterLink(...r)),
      publishReplay(1), refCount(),
    );
  }

  onClick(event: MouseEvent) {
    event.stopImmediatePropagation();
    //event.preventDefault();
  }

}
