import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SimplePagination, SimplePaginationUtils, SimpleSort, WithId} from '@lifeislife/lifeislife-domain';
import {LazyLoadEvent} from 'primeng/api';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {debounceTime, publishReplay, refCount} from 'rxjs/operators';
import {RefWithSigningPackageRef} from './ref-with-signing-package-ref';
import {SigningPackageRow} from './signing-package-row';

@Component({
  selector: 'app-signing-package-box',
  templateUrl: './signing-package-box.component.html',
  styleUrls: ['./signing-package-box.component.scss'],
})
export class SigningPackageBoxComponent implements OnInit {

  @Input()
  rows: SigningPackageRow[];
  @Input()
  pagination: SimplePagination;
  @Input()
  sort: SimpleSort;
  @Input()
  totalCount: number;
  @Input()
  loading: boolean;

  @Input()
  showDelete = false;
  @Input()
  showItemCreated = false;
  @Input()
  showItemUpdated = false;
  @Input()
  showItemStatus = false;
  /**
   * Footer content must then be provided as content of this component
   */
  @Input()
  showFooter = false;
  @Input()
  showHeader = true;

  @Output()
  paginationChange = new EventEmitter<SimplePagination>();
  @Output()
  signingPackageClick = new EventEmitter<RefWithSigningPackageRef>();
  @Output()
  signingPackageDeleteClick = new EventEmitter<RefWithSigningPackageRef>();
  @Output()
  searchQueryChange: Observable<string>;
  @Output()
  includeAllChange: Observable<boolean>;

  searchQueryValue$ = new BehaviorSubject<string>('');
  includeAllStatus$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.searchQueryChange = this.searchQueryValue$.pipe(
      debounceTime(200),
      publishReplay(1), refCount(),
    );
    this.includeAllChange = this.includeAllStatus$.pipe(
      publishReplay(1), refCount(),
    );
  }

  ngOnInit() {
  }

  onLazyLoad(event: LazyLoadEvent) {
    const newPagination = SimplePaginationUtils.applyLazyLoadEvent(this.pagination, event);
    // Force async for change detection
    timer(100)
      .subscribe(a => this.paginationChange.next(newPagination));
  }

  onRowClicked(row: SigningPackageRow, event: MouseEvent) {
    this.signingPackageClick.next(row);
    event.stopPropagation();
    event.preventDefault();
  }


  onDeleteClick(event: MouseEvent, row: SigningPackageRow) {
    this.signingPackageDeleteClick.next(row);
  }

  onQueyChanged(value: string) {
    this.searchQueryValue$.next(value);
  }

}
