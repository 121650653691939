import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileListItemComponent} from './file-list-item/file-list-item.component';
import {FileDownloadComponent} from './file-download/file-download.component';
import {FileUploadComponent} from './file-upload/file-upload';
import {FileDropAreaComponent} from './file-drop-area/file-drop-area.component';
import {DataViewModule} from 'primeng/dataview';
import {InputTextModule} from 'primeng/inputtext';
import {FormsModule} from '@angular/forms';
import {FileBoxComponent} from './file-box/file-box.component';
import {FileSizePipe} from './file-size.pipe';
import {DateModule} from '../date/date.module';
import {DialogModule} from 'primeng/dialog';
import {ProgressBarModule} from 'primeng/progressbar';
import {ButtonModule} from 'primeng/button';
import {SigningPackageListItemComponent} from './signing-package-list-item/signing-package-list-item.component';
import {SigningPackageStatusComponent} from './signing-package-status/signing-package-status.component';
import {SigningPackageBoxComponent} from './signing-package-box/signing-package-box.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PictureEditComponent} from './picture-edit/picture-edit.component';


const COMPONENTS = [
  FileListItemComponent,
  FileDownloadComponent,
  FileUploadComponent,
  FileDropAreaComponent,
  FileBoxComponent,
  FileSizePipe,
  SigningPackageBoxComponent,
  SigningPackageListItemComponent,
  SigningPackageStatusComponent,
  PictureEditComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,

    DataViewModule,
    InputTextModule,
    FormsModule,
    DateModule,
    ProgressBarModule,
    DialogModule,
    ButtonModule,
    InputSwitchModule,
    OverlayPanelModule,
  ],
  exports: [...COMPONENTS],
})
export class StoredFileModule {
}
