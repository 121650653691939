import {AppFeature} from './app-feature';
import {TrusteeContactType} from '@lifeislife/lifeislife-domain';

/**
 * App features granted in the context of a trustee to the specified roles of trustee contacts
 */
const AllKnownContactTypes: TrusteeContactType[] = [
  TrusteeContactType.LEGAL_REPRESENTATIVE,
  TrusteeContactType.ACCOUNTANT,
  TrusteeContactType.ADMINISTRATIVE];

export const TRUSTEE_CONTACT_FEATURE_GRANTS: { [K in AppFeature]?: TrusteeContactType[] } = {
  [AppFeature.contact_list]: [...AllKnownContactTypes],
  [AppFeature.customer_list]: [...AllKnownContactTypes],
  [AppFeature.customer_create]: [...AllKnownContactTypes],
  [AppFeature.customer_edit]: [...AllKnownContactTypes],
  [AppFeature.customer_bank_account_list]: [...AllKnownContactTypes],
  [AppFeature.customer_bank_account_edit]: [...AllKnownContactTypes],
  [AppFeature.customer_bank_account_create]: [...AllKnownContactTypes],
  [AppFeature.customer_bank_account_remove]: [...AllKnownContactTypes],
  [AppFeature.customer_contact_list]: [...AllKnownContactTypes],
  [AppFeature.customer_contact_invite]: [...AllKnownContactTypes],
  [AppFeature.customer_contact_create]: [...AllKnownContactTypes],
  [AppFeature.customer_contact_remove]: [...AllKnownContactTypes],
  [AppFeature.customer_contact_edit]: [...AllKnownContactTypes],
  [AppFeature.customer_event_subscribe]: [...AllKnownContactTypes],
  // [AppFeature.credit_subscription_list]: [...AllKnownContactTypes],
  // [AppFeature.credit_subscription_create]: [...AllKnownContactTypes],
  // [AppFeature.credit_subscription_edit]: [...AllKnownContactTypes],
  [AppFeature.insurance_subscription_list]: [...AllKnownContactTypes],
  [AppFeature.insurance_subscription_create]: [],
  [AppFeature.insurance_subscription_edit]: [],
  // [AppFeature.investment_subscription_list]: [...AllKnownContactTypes],
  // [AppFeature.investment_subscription_create]: [...AllKnownContactTypes],
  // [AppFeature.investment_subscription_edit]: [...AllKnownContactTypes],
  // [AppFeature.subscription_list]: [...AllKnownContactTypes],
  [AppFeature.mail_list]: [...AllKnownContactTypes],
  [AppFeature.trustee_contact_list]: [...AllKnownContactTypes],
  [AppFeature.trustee_contact_edit]: [...AllKnownContactTypes],
  [AppFeature.trustee_contact_remove]: [...AllKnownContactTypes],
  [AppFeature.trustee_contact_create]: [...AllKnownContactTypes],
  [AppFeature.trustee_contact_invite]: [...AllKnownContactTypes],
};

export function getLifeisLifeTrusteeContactTypeBasedSwitchGrants(): { [key: string]: string[] } {
  return toAppConfigSettings(TRUSTEE_CONTACT_FEATURE_GRANTS);
}

function toAppConfigSettings(roleGrants: { [K in AppFeature]?: TrusteeContactType[] }): { [K in string]: string[] } {
  const settings = {};
  for (const key in roleGrants) {
    if (typeof key === 'string' && roleGrants.hasOwnProperty(key)) {
      const stringValues = roleGrants[key].map(r => `${r}`);
      settings[key] = stringValues;
    }
  }
  return settings;
}
