import {Trustee} from '../trustee/trustee';
import {Customer} from '../customer/customer';
import {Ref} from '../shared/ref';
import {CustomerContactType} from '../customer-contact/customer-contact-type';
import {TrusteeContactType} from '../trustee/trustee-contact-type';
import {Contact} from './contact';
import {ObjectConverterUtil} from '../../service/object-converter-util';
import {ContactConverter} from '../../service/contact/contact.converter';
import {WsContactSearch} from '@lifeislife/lifeislife-ws-api';
import {CustomerSearch} from '../customer/customer-search';
import {TrusteeSearch} from '../trustee/trustee-search';
import {ContactSearchConverter} from '../../service/contact/contact-search.converter';

export class ContactSearch {
  exactContactRef?: Ref<Contact>;
  trusteeRef?: Ref<Trustee>;
  customerTrusteeRef?: Ref<Trustee>;
  contactIdBlackList?: number[];
  domain?: string;
  lastNameContains?: string;
  firstNameContains?: string;
  anyNameContains?: string;
  companyContains?: string;
  cityContains?: string;
  zipContains?: string;
  internalOptional?: boolean;
  customerRef?: Ref<Customer>;
  idExpiryFromDate?: Date;
  idExpiryToDate?: Date;
  trusteeContactTypes?: TrusteeContactType[];
  customerContactTypes?: CustomerContactType[];

  withLifeIsLifeAdmin?: boolean;
  withCustomerContact?: boolean;
  withTrusteeContact?: boolean;

  exactEmail?: string;
  exactPhone?: string;
  exactNationalRegister?: string;

  customerContactCustomerSearch?: CustomerSearch;
  trusteeContactTrusteeSearch?: TrusteeSearch;


  static serializeFilter(contactFilter: ContactSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(contactFilter);
    const wsContactSearch = ContactSearchConverter.toWsContactSearch(contactFilter);
    return JSON.stringify(wsContactSearch);
  }

  static deserializeFilter(valueString: string | null): ContactSearch | null {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsContactSearch = JSON.parse(valueString);
      const filter = ContactSearchConverter.toContactSearch(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }
}
