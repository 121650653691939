import {Injectable} from '@angular/core';
import {RequestService} from '../../service/request.service';
import {WsCustomerInviteCode, WsTrusteeInviteCode} from '@lifeislife/lifeislife-ws-api';
import {Observable} from 'rxjs';
import {WsInviteTokenAuth} from '../../domain/auth/ws-invite-token-auth';
import {FrontendAppConfigKey} from '../../../domain/config/frontend-app-config-key';
import {AppConfigService} from '../../../service/config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class UserInviteAuthWsClient {


  constructor(private requestService: RequestService,
              private appConfigService: AppConfigService,
  ) {
  }


  getTrusteeInviteCode$(auth: WsInviteTokenAuth): Observable<WsTrusteeInviteCode> {
    const url = `${this.getResourceUri()}/trustee/${auth.token}`;

    return this.requestService.sendRequest<WsTrusteeInviteCode>({
      method: 'GET', url: url,
    }, auth);
  }

  getCustomerInviteCode$(auth: WsInviteTokenAuth): Observable<WsCustomerInviteCode> {
    const url = `${this.getResourceUri()}/customer/${auth.token}`;

    return this.requestService.sendRequest<WsCustomerInviteCode>({
      method: 'GET', url: url,
    }, auth);
  }


  private getResourceUri() {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const resourceUrl = `${wsUri}/front/invite`;
    return resourceUrl;
  }
}
