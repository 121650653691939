import {ControlValueAccessor} from '@angular/forms';

export class LifeislifeControlListener<T> implements ControlValueAccessor {

  value: T;

  private onChange: Function;
  private onTouched: Function;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  fireChanges() {
    if (this.onTouched) {
      this.onTouched();
    }
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

}
