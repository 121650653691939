import {Injectable} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ActivationEnd,
  ActivationStart,
  ChildActivationEnd,
  ChildActivationStart,
  NavigationEnd,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterEvent,
  Scroll,
} from '@angular/router';
import {combineLatest, EMPTY as IWantToUseEmptyObserbale, merge, Observable, of} from 'rxjs';
import {MenuItem} from 'primeng/api';
import {defaultIfEmpty, filter, map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {ApplicationRouteData} from '../../main/routing/application-route-data';
import {ApplicationMenuItemData} from '../../main/routing/application-menu-item-data';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbMenuService {

  menu$: Observable<MenuItem[]>;

  constructor(
    private router: Router,
  ) {
    const curState = this.router.routerState;
    const curMenu$ = this.buildMenuFromRoot$(curState.root);
    const nextMenu$ = this.router.events.pipe(
      switchMap(event => this.buildMenuOnEvent$(event)),
      map(menu => menu.filter(item => item != null)),
    );
    this.menu$ = merge(curMenu$, nextMenu$).pipe(
      publishReplay(1), refCount(),
    );
  }


  private buildMenuOnEvent$(event: RouterEvent | RouteConfigLoadStart | RouteConfigLoadEnd | ChildActivationStart | ChildActivationEnd | ActivationStart | ActivationEnd | Scroll) {
    if (event instanceof NavigationEnd) {
      const root = this.router.routerState.root;
      return this.buildMenuFromRoot$(root);
      // } else if (event instanceof ActivationEnd) {
      //   return this.buildMenuFromSnapshot$(event.snapshot);
    } else {
      return IWantToUseEmptyObserbale;
    }
  }

  private buildMenuFromSnapshot$(snapshot: ActivatedRouteSnapshot) {
    const menu = snapshot.pathFromRoot
      .map(item => this.buildMenuItemOrNull(item))
      .filter(item => item != null);
    return of(menu);
  }

  private buildMenuItemOrNull(item: ActivatedRouteSnapshot) {
    const itemData: ApplicationRouteData = item.data;
    if (itemData.menuData) {
      return this.buildMenuItemFromData(itemData.menuData);
    } else {
      return null;
    }
  }

  private buildMenuItemFromData(menuData: ApplicationMenuItemData, parentLink?: any[]): MenuItem {
    if (menuData == null || menuData.skipBreadcrumb) {
      return null;
    }
    const routerLink = menuData.menuLink || this.createRelativeLink(parentLink, menuData.relativeMenuLink);
    return {
      label: menuData.title,
      icon: menuData.menuIcon,
      routerLink: routerLink,
    };
  }

  private buildMenuFromRoot$(root: ActivatedRoute): Observable<MenuItem[]> {
    const childs = root.children;
    const menuItem$List = this.appendChilds([], childs)
      .map(routeData$ => routeData$.pipe(
        filter(data => data != null),
        map(routeData => routeData.menuData),
        filter(data => data != null),
        defaultIfEmpty(null),
      ));
    const menu$ = menuItem$List.length === 0 ? of([]) : combineLatest(menuItem$List);
    return menu$.pipe(
      map(dataList => this.buildMenuFromDataList(dataList)),
    );
  }

  private createRelativeLink(parentLink: any[], relativeMenuLink: any[]) {
    if (parentLink == null || relativeMenuLink == null) {
      return undefined;
    }
    return [...parentLink, ...relativeMenuLink];
  }

  private appendChilds(parents: Observable<ApplicationRouteData>[], childs: ActivatedRoute[]): Observable<ApplicationRouteData>[] {
    if (childs == null || childs.length === 0) {
      return parents;
    }
    if (childs.length > 1) {
      console.warn('Ignoring forking route path');
    }
    const child = childs[0];
    const routeData$ = child.data as Observable<ApplicationRouteData>;
    if (routeData$ != null) {
      const newParents = [...parents, routeData$];
      return this.appendChilds(newParents, child.children);
    } else {
      return this.appendChilds(parents, child.children);
    }
  }

  private buildMenuFromDataList(dataList: ApplicationMenuItemData[]) {
    // Update links
    let curUrl = [];
    const menu: MenuItem[] = [];
    for (const menuData of dataList) {
      const item = this.buildMenuItemFromData(menuData, curUrl);
      if (item != null && item.routerLink != null) {
        curUrl = [...item.routerLink];
        menu.push(item);
      }
    }
    return menu;
  }
}
