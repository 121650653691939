<div class="customer-contact-select">

  <p-dropdown name="customer-contact-select"
              [options]="selectItems$  | async"
              [(ngModel)]="selectedValue"
              (ngModelChange)="onChange($event)"
              [readonly]="disabled"
              [styleClass]="hasError ? 'p-state-error' : ''"
              [appendTo]="appendTo"
              optionLabel="label"
              optionValue="value"
              [filter]="showFilter"
              [resetFilterOnHide]="true"
  ></p-dropdown>

</div>
