import {WsSigningPackageFile, WsSigningPackageFileSearch} from '@lifeislife/lifeislife-ws-api';
import {SigningPackageFile} from '../../domain/file/signing-package-file';
import {SigningPackageFileSearch} from '../../domain/file/signing-package-file-search';
import {SigningPackageConverter} from './signing-package.converter';
import {ContactSearchConverter} from '../contact/contact-search.converter';

export class SigningPackageFileConverter {

  static convertIn(value: WsSigningPackageFile): SigningPackageFile {
    return {
      id: value.id,
      version: value.version,
      signed: value.signed,
      signedFileRef: value.signedFileRef,
      signerContactRef: value.signerContactWsRef,
      signingPackageRef: value.signingPackageWsRef,
      unsignedFileRef: value.unsignedFileRef,
      providerReference: value.providerReference,
    };
  }


  static convertOut(value: SigningPackageFile): WsSigningPackageFile {
    return {
      id: value.id,
      version: value.version,
      signed: value.signed,
      signedFileRef: value.signedFileRef,
      signerContactWsRef: value.signerContactRef,
      signingPackageWsRef: value.signingPackageRef,
      unsignedFileRef: value.unsignedFileRef,
      providerReference: value.providerReference,
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsSigningPackageFile]: keyof SigningPackageFile } {
    return {
      id: 'id',
      version: 'version',
      signed: 'signed',
      signedFileRef: 'signedFileRef',
      signerContactWsRef: 'signerContactRef',
      signingPackageWsRef: 'signingPackageRef',
      unsignedFileRef: 'unsignedFileRef',
      providerReference: 'providerReference',
    };
  }

  static convertFilterIn(value: WsSigningPackageFileSearch): SigningPackageFileSearch {
    if (value == null) {
      return undefined;
    }
    return {
      exactFileWsRef: value.exactFileWsRef,
      providerReference: value.providerReference,
      signerContactSearch: ContactSearchConverter.toContactSearch(value.signerContactSearch),
      signingPackageSearch: SigningPackageConverter.convertFilterIn(value.signingPackageSearch),
      storedFileRef: value.storedFileWsRef,
    };
  }


  static convertFilterOut(value: SigningPackageFileSearch): WsSigningPackageFileSearch {
    if (value == null) {
      return undefined;
    }
    return {
      exactFileWsRef: value.exactFileWsRef,
      providerReference: value.providerReference,
      signerContactSearch: ContactSearchConverter.toWsContactSearch(value.signerContactSearch),
      signingPackageSearch: SigningPackageConverter.convertFilterOut(value.signingPackageSearch),
      storedFileWsRef: value.storedFileRef,
    };
  }
}
