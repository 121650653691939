import {SimpleHttpCookie} from '../../domain/retire/simple-http-cookie';
import {WsSimpleHttpCookie} from '@lifeislife/lifeislife-ws-api';

export class SimpleHttpCookieConverter {


  static convertCookieIn(cookie: WsSimpleHttpCookie): SimpleHttpCookie {
    return {
      domain: cookie.domain,
      httpOnly: cookie.httpOnly,
      name: cookie.name,
      path: cookie.path,
      secure: cookie.secure,
      value: cookie.value,
      version: cookie.version,
    };
  }

  static convertCookieOut(cookie: SimpleHttpCookie): WsSimpleHttpCookie {
    return {
      domain: cookie.domain,
      httpOnly: cookie.httpOnly,
      name: cookie.name,
      path: cookie.path,
      secure: cookie.secure,
      value: cookie.value,
      version: cookie.version,
    };
  }

  static convertCookieMapIn(cookieMap: { [domain: string]: WsSimpleHttpCookie[] }): { [domain: string]: SimpleHttpCookie[] } {
    const convertedMap = {};
    const domains = Object.getOwnPropertyNames(cookieMap);
    for (const domain of domains) {
      if (typeof domain !== 'string' || !cookieMap.hasOwnProperty(domain)) {
        continue;
      }
      const wsCookies = cookieMap[domain];
      const cookie = wsCookies.map(c => this.convertCookieIn(c));
      convertedMap[domain] = cookie;
    }
    return convertedMap;
  }

  static convertCookieMapOut(cookieMap: { [domain: string]: SimpleHttpCookie[] }): { [domain: string]: WsSimpleHttpCookie[] } {
    const convertedMap = {};
    const domains = Object.getOwnPropertyNames(cookieMap);
    for (const domain of domains) {
      if (typeof domain !== 'string' || !cookieMap.hasOwnProperty(domain)) {
        continue;
      }
      const cookies = cookieMap[domain];
      const wsCookie = cookies.map(c => this.convertCookieOut(c));
      convertedMap[domain] = wsCookie;
    }
    return convertedMap;
  }


}
