import {ActivatedRouteSnapshot, ParamMap, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {WsUserTokenAuth} from '../../client/domain/auth/ws-user-token-auth';
import {UserService} from '../../service/user/user.service';
import {AuthService} from './auth.service';
import {catchError, defaultIfEmpty, filter, switchMap, take} from 'rxjs/operators';

@Injectable()
export class AuthCodeResolver implements Resolve<WsUserTokenAuth> {

  constructor(
    private userService: UserService,
    private authService: AuthService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WsUserTokenAuth> {
    return this.exchangeToken$(route.queryParamMap);
  }

  /**
   * Exchange auth code
   * @param routeParams route query params
   */
  exchangeToken$(routeParams: ParamMap): Observable<WsUserTokenAuth> {
    const authErrorParam = routeParams.get('authError');
    if (authErrorParam != null && authErrorParam.length > 0) {
      return throwError(new Error(authErrorParam));
    }

    const authCodeParam = routeParams.get('authCode');
    if (authCodeParam == null || authCodeParam.length === 0) {
      return of(null);
    }

    // We might be - although unusually - redirected by the backend to a frontend url containing another auth code.
    // This happen if you are for instance on a url with an authCode=code2 query param, then you request a new auth code by filling the mail,
    // then click on the email link. You get redirected to the backend, then to the frontend with ?authCode=code1&authCode=code2.
    if (this.authService.isAuthenticated()) {
      console.warn('Skipping exchanging auth code while authenticated');
      return of(null);
    }

    return of(authCodeParam).pipe(
      switchMap(c => this.userService.exchangeAuthCodeForToken$(c)),
      catchError(e => of(null)),
      filter(c => c != null),
      take(1),
      defaultIfEmpty(null),
    );
  }
}
