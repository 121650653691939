import {WsLifeIsLifeUser, WsUserSearch} from '@lifeislife/lifeislife-ws-api';
import {Observable, of} from 'rxjs';
import {SearchResult} from '../../client/domain/search/search-result';
import {User} from '../../domain/user/user';
import {UserFilter} from '../../domain/user/user-filter';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {ContactSearchConverter} from '../contact/contact-search.converter';

export class UserConverter {

  static convertIn(value: WsLifeIsLifeUser): User {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      archived: value.archived,
      login: value.login,
      lastConnectionTime: DateConverterUtils.parseIsoDateTime(value.lastConnectionTime),
    };
  }

  static convertOut(value: User): WsLifeIsLifeUser {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      archived: value.archived,
      login: value.login,
      lastConnectionTime: DateConverterUtils.formatDateToIsoDateTime(value.lastConnectionTime),
    };
  }


  static convertFilterIn(value?: WsUserSearch): UserFilter {
    if (value == null) {
      return undefined;
    }
    return {
      archived: value.archived,
      multiSearch: value.multiSearch,
      userRef: value.exactUser,
      contactSearch: ContactSearchConverter.toContactSearch(value.wsContactSearch),
    };
  }

  static convertFilterOut(value: UserFilter): WsUserSearch {
    if (value == null) {
      return undefined;
    }
    return {
      archived: value.archived,
      multiSearch: value.multiSearch,
      exactUser: value.userRef,
      wsContactSearch: ContactSearchConverter.toWsContactSearch(value.contactSearch),
    } as WsUserSearch;
  }

  static convertSearchResultsIn$(results: SearchResult<WsLifeIsLifeUser>): Observable<SearchResult<User>> {
    return of(Object.assign({}, results, <Partial<SearchResult<User>>>{
      list: results.list.map(wsType => this.convertIn(wsType)),
    }));
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsLifeIsLifeUser]: keyof User } {
    return {
      id: 'id',
      login: 'login',
      archived: 'archived',
      lastConnectionTime: 'lastConnectionTime',
    };
  }

}
