import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {MessageFlavor} from '../../domain/mail/message-flavor';
import {MessageChannel} from '../../domain/mail/message-channel';
import {WsLifeisLifeMessage} from '@lifeislife/lifeislife-ws-api';
import {LifeislifeMessageType} from '../../domain/mail/lifeislife-message-type';

export class MessageTypeConverter {


  static convertIn(value: WsLifeisLifeMessage): LifeislifeMessageType {
    return {
      name: value.messageTypeName,
      label: value.messageTypeLabel,
      channels: value.messageChannels == null ? [] : value.messageChannels
        .map(c => EnumConverterUtils.convertValue(c, MessageChannel)),
      flavours: value.messageFlavors == null ? [] : value.messageFlavors
        .map(f => EnumConverterUtils.convertValue(f, MessageFlavor)),
      templateRef: value.wsMessageTemplateWsRef,
    };
  }

}
