import {MypensionInitContext} from '../../domain/retire/mypension-init-context';
import {WsMyPensionContactContext, WsMyPensionContext, WsMyPensionInitContext} from '@lifeislife/lifeislife-ws-api';
import {MypensionContext} from '../../domain/retire/mypension-context';
import {MypensionContactContext} from '../../domain/retire/mypension-contact-context';
import {ContactConverter} from '../contact/contact.converter';
import {SimpleHttpCookieConverter} from './simple-http-cookie-converter';
import {MypensionUserConverter} from './mypension-user-converter';

export class MypensionContextConverter {

  static convertInitContextIn(wsInitContext: WsMyPensionInitContext): MypensionInitContext {
    return {
      cookieMap: SimpleHttpCookieConverter.convertCookieMapIn(wsInitContext.cookieMap),
      pokaYokeImageUri: wsInitContext.pokaYokeImageUri,
      encryptedLifeisLifeContext: wsInitContext.encryptedLifeisLifeContext,
    };
  }

  static convertInitContextOut(initContext: MypensionInitContext): WsMyPensionInitContext {
    return {
      cookieMap: SimpleHttpCookieConverter.convertCookieMapOut(initContext.cookieMap),
      pokaYokeImageUri: initContext.pokaYokeImageUri,
      encryptedLifeisLifeContext: initContext.encryptedLifeisLifeContext,
    };
  }

  static convertContextIn(wsContext: WsMyPensionContext): MypensionContext {
    return {
      fgovWResult: wsContext.fgovWResult,
      loginSocialSecurityCookies: wsContext.loginSocialSecurityCookies.map(c => SimpleHttpCookieConverter.convertCookieIn(c)),
      wwwMyPensionBeCookies: wsContext.wwwMyPensionBeCookies.map(c => SimpleHttpCookieConverter.convertCookieIn(c)),
      myPensionUser: MypensionUserConverter.convertUserIn(wsContext.myPensionUser),
      encryptedLifeisLifeContext: wsContext.encryptedLifeisLifeContext,
    };
  }

  static convertContactContextOut(context: MypensionContactContext): WsMyPensionContactContext {
    return {
      fgovWResult: context.fgovWResult,
      loginSocialSecurityCookies: context.loginSocialSecurityCookies.map(c => SimpleHttpCookieConverter.convertCookieOut(c)),
      wwwMyPensionBeCookies: context.wwwMyPensionBeCookies.map(c => SimpleHttpCookieConverter.convertCookieOut(c)),
      wsContact: ContactConverter.toWsContact(context.contact),
      encryptedLifeisLifeContext: context.encryptedLifeisLifeContext,
    };
  }
}
