import {Injectable} from '@angular/core';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {WsConstraintViolation, WsRef, WsTicketInsuranceMandateRequest} from '@lifeislife/lifeislife-ws-api';
import {ObjectConverterUtil} from '../object-converter-util';
import {TicketInsuranceMandateRequestWsClient} from '../../client/resources/front/ticketing/ticket-insurance-mandate-request-ws-client';
import {TicketInsuranceMandateRequetsConverter} from './ticket-insurance-mandate-requets.converter';
import {TicketInsuranceMandateRequetsSearchConverter} from './ticket-insurance-mandate-requets-search.converter';
import {TicketInsuranceMandateRequest} from '../../domain/ticket/ticket-insurance-mandate-request';
import {TicketInsuranceMandateRequestSearch} from '../../domain/ticket/ticket-insurance-mandate-request-search';

@Injectable({
  providedIn: 'root',
})
export class TicketInsuranceMandateRequestService {

  constructor(private client: TicketInsuranceMandateRequestWsClient,
  ) {
  }

  getTicketInsuranceMandateRequest$(ref?: Ref<TicketInsuranceMandateRequest>): Observable<TicketInsuranceMandateRequest> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doGet(ref.id).pipe(
      map(wsTicketInsuranceMandateRequest => TicketInsuranceMandateRequetsConverter.convertIn(wsTicketInsuranceMandateRequest)),
    );
  }

  searchTicketInsuranceMandateRequests$(ticketInsuranceMandateRequestFilter: TicketInsuranceMandateRequestSearch,
                                        pagination: SimplePagination): Observable<SearchResult<TicketInsuranceMandateRequest>> {
    const wsSearch = TicketInsuranceMandateRequetsSearchConverter.convertOut(ticketInsuranceMandateRequestFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateTicketInsuranceMandateRequest$(ticketInsuranceMandateRequest: TicketInsuranceMandateRequest)
    : Observable<ValidationResult<TicketInsuranceMandateRequest>> {
    const wsTicketInsuranceMandateRequest = TicketInsuranceMandateRequetsConverter.convertOut(ticketInsuranceMandateRequest);
    return this.client.doValidate(wsTicketInsuranceMandateRequest).pipe(
      map((results: WsTicketInsuranceMandateRequest | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveTicketInsuranceMandateRequest$(ticketInsuranceMandateRequest: TicketInsuranceMandateRequest)
    : Observable<Ref<TicketInsuranceMandateRequest>> {
    const wsTicketInsuranceMandateRequest = TicketInsuranceMandateRequetsConverter.convertOut(ticketInsuranceMandateRequest);
    return this.client.doSave(wsTicketInsuranceMandateRequest);
  }

  deleteTicketInsuranceMandateRequest$(id: number): Observable<any> {
    return this.client.doRemove(id);
  }

  private createValidationResult(results: WsTicketInsuranceMandateRequest | WsConstraintViolation[])
    : ValidationResult<TicketInsuranceMandateRequest> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsTicketInsuranceMandateRequest => TicketInsuranceMandateRequetsConverter.convertIn(wsTicketInsuranceMandateRequest),
      propertyNameMappings: TicketInsuranceMandateRequetsConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsTicketInsuranceMandateRequest>>): Observable<SearchResult<TicketInsuranceMandateRequest>> {
    const rowTasks = results.list.map(ref => this.getTicketInsuranceMandateRequest$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<TicketInsuranceMandateRequest>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<TicketInsuranceMandateRequest>>>{
          list: rows,
        }),
      ));
  }

}
