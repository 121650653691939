import {Injectable} from '@angular/core';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {WsConstraintViolation, WsRef, WsTicketContact} from '@lifeislife/lifeislife-ws-api';
import {ObjectConverterUtil} from '../object-converter-util';
import {TicketContactWsClient} from '../../client/resources/front/ticketing/ticket-contact-ws-client';
import {TicketContact} from '../../domain/ticket/ticket-contact';
import {TicketContactConverter} from './ticket-contact.converter';
import {TicketContactSearch} from '../../domain/ticket/ticket-contact-search';

@Injectable({
  providedIn: 'root',
})
export class TicketContactService {

  constructor(private client: TicketContactWsClient,
  ) {
  }

  getTicketContact$(ref?: Ref<TicketContact>): Observable<TicketContact> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doGet(ref.id).pipe(
      map(wsTicketContact => TicketContactConverter.convertIn(wsTicketContact)),
    );
  }

  searchTicketContacts$(ticketContactFilter: TicketContactSearch, pagination: SimplePagination)
    : Observable<SearchResult<TicketContact>> {
    const wsSearch = TicketContactConverter.convertFilterOut(ticketContactFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateTicketContact$(ticketContact: TicketContact): Observable<ValidationResult<TicketContact>> {
    const wsTicketContact = TicketContactConverter.convertOut(ticketContact);
    return this.client.doValidate(wsTicketContact).pipe(
      map((results: WsTicketContact | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveTicketContact$(ticketContact: TicketContact): Observable<Ref<TicketContact>> {
    const wsTicketContact = TicketContactConverter.convertOut(ticketContact);
    return this.client.doSave(wsTicketContact);
  }

  deleteTicketContact$(id: number): Observable<any> {
    return this.client.doRemove(id);
  }

  private createValidationResult(results: WsTicketContact | WsConstraintViolation[]): ValidationResult<TicketContact> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsTicketContact => TicketContactConverter.convertIn(wsTicketContact),
      propertyNameMappings: TicketContactConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsTicketContact>>): Observable<SearchResult<TicketContact>> {
    const rowTasks = results.list.map(ref => this.getTicketContact$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<TicketContact>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<TicketContact>>>{
          list: rows,
        }),
      ));
  }

}
