<div class="phone-number-input">
  <input type="text" pInputText
         name="phone-number"
         class="number-input"
         size="12"
         [readonly]="disabled"
         [class.p-state-error]="hasError"
         [ngModel]="phoneNumber | async"
         (ngModelChange)="onNumberChanged($event)"
  >
</div>
