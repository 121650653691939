import {Injectable} from '@angular/core';
import {
  Contact,
  ContactService,
  CustomerContact, CustomerContactField,
  CustomerContactSearch,
  CustomerContactService,
  Ref,
  SimplePaginationUtils,
} from '@lifeislife/lifeislife-domain';
import {SelectItem} from 'primeng/api';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerContactItemsService {

  private defaultOptions: {
    includeNoValueOption: boolean,
    noSelectionLabel: string,
  } = {
    includeNoValueOption: true,
    noSelectionLabel: `<Aucun>`,
  };

  constructor(
    private customerContactService: CustomerContactService,
    private contactService: ContactService,
  ) {
  }

  createCustomerContactItemsForSearchFilter$(searchFilter: CustomerContactSearch, pageSize = 100,
                                             options = this.defaultOptions): Observable<SelectItem<Ref<CustomerContact>>[]> {
    if (searchFilter == null) {
      return of([]);
    }
    const pagination = SimplePaginationUtils.newPagination(pageSize, {
      field: CustomerContactField.LAST_NAME,
      order: 1,
    });
    return this.customerContactService.searchCustomerContacts$(searchFilter, pagination).pipe(
      switchMap(r => this.createCustomerContactItems$(r.list, options)),
    );
  }


  createCustomerContactItems$(refList: Ref<CustomerContact>[], options = this.defaultOptions): Observable<SelectItem<Ref<CustomerContact>>[]> {
    const alLRefList = options.includeNoValueOption ? [null, ...refList] : refList;
    const task$List = alLRefList.map(r => this.createCustomerContactItem$(r, options));
    const taskList$ = task$List.length === 0 ? of([]) : forkJoin(task$List);
    return taskList$;
  }

  createCustomerContactItem$(ref: Ref<CustomerContact>, options = this.defaultOptions): Observable<SelectItem<Ref<CustomerContact>>> {
    return this.getCustomerContactLabel$(ref).pipe(
      map(label => {
        if (label == null) {
          if (options.includeNoValueOption) {
            return {
              value: null,
              label: options.noSelectionLabel,
            } as SelectItem<Ref<CustomerContact>>;
          } else {
            return null;
          }
        } else {
          return {
            value: {id: ref.id},
            label: label,
          } as SelectItem<Ref<CustomerContact>>;
        }
      }),
    );
  }

  private getCustomerContactLabel$(ref: Ref<CustomerContact> | null): Observable<string | null> {
    if (ref == null) {
      return of(null);
    }

    return this.customerContactService.getCustomerContact$(ref).pipe(
      switchMap(cc => this.getContactAndRoleLabel$(cc)),
    );
  }

  private getContactAndRoleLabel$(cc: CustomerContact): Observable<string> {
    return this.contactService.getContact$(cc.contactRef).pipe(
      switchMap(c => this.getContactAndRoleLabelWithContact$(cc, c)),
    );
  }

  private getContactAndRoleLabelWithContact$(customerContact: CustomerContact, contact: Contact): Observable<string> {
    return this.customerContactService.getContactTypeModel$(customerContact.customerContactType).pipe(
      map(typeModel => {
        const contactName = this.contactService.getContactName(contact);
        const contactTypeLabel = typeModel.label;
        return `${contactName} (${contactTypeLabel})`;
      }),
    );
  }
}
