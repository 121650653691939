<div class="app-shell">

  <div class="header" *ngIf="(showMenu$ | async) && (allSet$ | async)">
    <app-header></app-header>
  </div>

  <div class="content" *ngIf="loggedIn$ | async">
    <app-identity-switcher *ngIf="!(activeContextSet$ | async)"
    ></app-identity-switcher>

    <router-outlet *ngIf="allSet$ | async ">
    </router-outlet>
  </div>

  <!-- absolute-positionned panels -->

  <div class="message-overlay">
    <p-toast position="top-center" [baseZIndex]="5000"
             (click)="onToastClick($event)"
    >
    </p-toast>

    <p-confirmDialog header="Confirmation"
                     icon="pi pi-exclamation-triangle"
    ></p-confirmDialog>
  </div>


</div>
