import {LifeisLifeWsAuth} from './lifeis-life-ws-auth';
import {Observable} from 'rxjs';
import {AuthState} from '../../../util/auth/auth-state';

export abstract class AuthProvider {
  abstract getAuth(): LifeisLifeWsAuth | null;

  abstract getAuthState$(): Observable<AuthState>;
}

