import {WsTicketComment, WsTicketCommentSearch} from '@lifeislife/lifeislife-ws-api';
import {TicketComment} from '../../domain/ticket/ticket-comment';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {TicketCommentSearch} from '../../domain/ticket/ticket-comment-search';
import {TicketContactConverter} from './ticket-contact.converter';
import {DateTimeSearchConverter} from '../shared/date-time-search-converter';
import {TicketSearchConverter} from './ticket-search.converter';

export class TicketCommentConverter {

  static convertIn(value: WsTicketComment): TicketComment {
    return {
      id: value.id,
      authorContactRef: value.authorContactRef,
      content: value.content,
      dateTime: DateConverterUtils.parseIsoDateTime(value.dateTime),
      ticketRef: value.ticketRef,
    };
  }

  static convertOut(value: TicketComment): WsTicketComment {
    return {
      id: value.id,
      authorContactRef: value.authorContactRef,
      content: value.content,
      dateTime: DateConverterUtils.formatDateToIsoDateTime(value.dateTime),
      ticketRef: value.ticketRef,
    };
  }


  static convertFilterIn(value?: WsTicketCommentSearch): TicketCommentSearch {
    if (value == null) {
      return undefined;
    }
    return {
      authorTicketContactSearch: TicketContactConverter.convertFilterIn(value.authorTicketContactSearch),
      dateTimeSearch: DateTimeSearchConverter.convertIn(value.dateTimeSearch),
      ticketSearch: TicketSearchConverter.convertFilterIn(value.ticketSearch),
    };
  }

  static convertFilterOut(value: TicketCommentSearch): WsTicketCommentSearch {
    if (value == null) {
      return undefined;
    }
    return {
      authorTicketContactSearch: TicketContactConverter.convertFilterOut(value.authorTicketContactSearch),
      dateTimeSearch: DateTimeSearchConverter.convertOut(value.dateTimeSearch),
      ticketSearch: TicketSearchConverter.convertFilterOut(value.ticketSearch),
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsTicketComment]: keyof TicketComment } {
    return {
      id: 'id',
      ticketRef: 'ticketRef',
      dateTime: 'dateTime',
      content: 'content',
      authorContactRef: 'authorContactRef',
    };
  }

}
