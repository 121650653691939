import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsRef, WsSubscriptionFile} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {SubscriptionFileConverter} from './subscription-file.converter';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {StoredFile} from '../../domain/file/stored-file';
import {ContentDisposition} from '../../util/file/file-utils';
import {SubscriptionFileWsClient} from '../../client/resources/front/corebusiness/subscription-file-ws-client';
import {StoredFileConverter} from '../file/stored-file.converter';
import {SubscriptionFile} from '../../domain/corebusiness/subscription-file';
import {SubscriptionFileSearch} from '../../domain/corebusiness/subscription-file-search';
import {StoredFileWsClient} from '../../client/resources/front/file/stored-file-ws-client';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionFileService {


  constructor(private client: SubscriptionFileWsClient,
              private storedFileClient: StoredFileWsClient,
  ) {
  }

  getSubscriptionFile$(ref: Ref<SubscriptionFile>): Observable<SubscriptionFile> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doGet(ref.id).pipe(
      map(value => SubscriptionFileConverter.convertIn(value)),
    );
  }

  searchSubscriptionFiles$(subscriptionFileFilter: SubscriptionFileSearch, pagination: SimplePagination)
    : Observable<SearchResult<SubscriptionFile>> {
    const wsSearch = SubscriptionFileConverter.convertFilterOut(subscriptionFileFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateSubscriptionFile$(subscriptionFile: SubscriptionFile): Observable<ValidationResult<SubscriptionFile>> {
    const wsSubscriptionFile = SubscriptionFileConverter.convertOut(subscriptionFile);
    return this.client.doValidate(wsSubscriptionFile).pipe(
      map((results: WsSubscriptionFile | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveSubscriptionFile$(subscriptionFile: SubscriptionFile): Observable<any> {
    const wsSubscriptionFile = SubscriptionFileConverter.convertOut(subscriptionFile);
    return this.client.doUpdate(wsSubscriptionFile);
  }

  deleteSubscriptionFile$(ref: Ref<SubscriptionFile>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  renameFile(ref: Ref<SubscriptionFile>, newFileName: string): Observable<Ref<StoredFile>> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.renameFile(ref.id, newFileName);
  }

  getFileThumbnailUrl$(ref: Ref<SubscriptionFile>): Observable<string> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.getFileThumbnailUrl$(ref.id);
  }

  getFileDownloadUrl$(ref: Ref<SubscriptionFile>, contentDisposition: ContentDisposition = 'attachment'): Observable<string> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }

    return this.client.getFileDownloadUrl$(ref.id, contentDisposition);
  }

  getStoredFile$(ref: Ref<SubscriptionFile>): Observable<StoredFile> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }

    return this.client.getFile(ref.id).pipe(
      // Cache value
      tap(wsFile => this.storedFileClient.doPutInCache(wsFile.id, wsFile)),
      map(wsFile => StoredFileConverter.convertIn(wsFile)),
    );
  }

  serializeFilter(subscriptionFileFilter: SubscriptionFileSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(subscriptionFileFilter);
    const wsSubscriptionFileSearch = SubscriptionFileConverter.convertFilterOut(subscriptionFileFilter);
    return JSON.stringify(wsSubscriptionFileSearch);
  }


  deserializeFilter(valueString: string | null): SubscriptionFileSearch | null {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: any = JSON.parse(valueString);
      const filter = SubscriptionFileConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsSubscriptionFile | WsConstraintViolation[]): ValidationResult<SubscriptionFile> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsSubscriptionFile => SubscriptionFileConverter.convertIn(wsSubscriptionFile),
      propertyNameMappings: SubscriptionFileConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsSubscriptionFile>>): Observable<SearchResult<SubscriptionFile>> {
    const rowTasks = results.list.map(ref => this.getSubscriptionFile$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<SubscriptionFile>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, {
          list: rows,
        }),
      ));
  }

}
