import {
  WsInsuranceCategory,
  WsInsuranceCategoryFamily,
  WsInsuranceCategorySearch,
} from '@lifeislife/lifeislife-ws-api';
import {InsuranceCategory} from '../../domain/insurance/insurance-category';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {InsuranceCategoryFamily} from '../../domain/insurance/insurance-category-family';
import {InsuranceCategorySearch} from '../../domain/insurance/insurance-category-search';
import {InsuranceType} from '../../domain/insurance/insurance-type';
import {InsuranceCategoryOptionalFeature} from '../../domain/insurance/insurance-category-optional-feature';
import {WsInsuranceCategoryOptionalFeature} from '@lifeislife/lifeislife-ws-api';

export class InsuranceCategoryConverter {

  static convertIn(value: WsInsuranceCategory): InsuranceCategory {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      version: value.version,
      code: value.code,
      internalLabel: value.internalLabel,
      publicLabel: value.publicLabel,
      insuranceCategoryFamily: EnumConverterUtils.convertValue(value.wsInsuranceCategoryFamily, InsuranceCategoryFamily),
      insuranceCategoryFamilyType: EnumConverterUtils.convertValue(value.wsInsuranceCategoryFamilyType, InsuranceType),
      optionalFeatures: value.insuranceSubscriptionsFeatures == null ? [] : value.insuranceSubscriptionsFeatures.map(
        ws => EnumConverterUtils.convertWsLabelledField(ws, InsuranceCategoryOptionalFeature),
      ),
    };
  }

  static convertOut(value: InsuranceCategory): WsInsuranceCategory {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      version: value.version,
      code: value.code,
      internalLabel: value.internalLabel,
      publicLabel: value.publicLabel,
      wsInsuranceCategoryFamily: EnumConverterUtils.convertValue(value.insuranceCategoryFamily, WsInsuranceCategoryFamily),
      wsInsuranceCategoryFamilyType: undefined, // backend managed
      insuranceSubscriptionsFeatures: value.optionalFeatures == null ? [] : value.optionalFeatures.map(
        ws => EnumConverterUtils.convertLabelledField(ws, WsInsuranceCategoryOptionalFeature),
      ),
    };
  }


  static convertFilterIn(value?: WsInsuranceCategorySearch): InsuranceCategorySearch {
    if (value == null) {
      return undefined;
    }
    return {
      anyFamily: value.anyFamily == null ? null : value.anyFamily
        .map(wsFamily => EnumConverterUtils.convertValue(wsFamily, InsuranceCategoryFamily)),
      anyOptionalFeature: value.anyOptionalFeature == null ? null : value.anyOptionalFeature
        .map(wsFeature => EnumConverterUtils.convertValue(wsFeature, InsuranceCategoryOptionalFeature)),
      anyCategoryRef: value.anyCategoryRef,
      internalNameContains: value.internalNameContains,
    };
  }

  static convertFilterOut(value: InsuranceCategorySearch): WsInsuranceCategorySearch {
    if (value == null) {
      return undefined;
    }
    return {
      anyFamily: value.anyFamily == null ? null : value.anyFamily
        .map(family => EnumConverterUtils.convertValue(family, WsInsuranceCategoryFamily)),
      anyOptionalFeature: value.anyOptionalFeature == null ? null : value.anyOptionalFeature
        .map(feature => EnumConverterUtils.convertValue(feature, WsInsuranceCategoryOptionalFeature)),
      anyCategoryRef: value.anyCategoryRef,
      internalNameContains: value.internalNameContains,
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsInsuranceCategory]: keyof InsuranceCategory } {
    return {
      id: 'id',
      version: 'version',
      wsInsuranceCategoryFamily: 'insuranceCategoryFamily',
      publicLabel: 'publicLabel',
      internalLabel: 'internalLabel',
      code: 'code',
      wsInsuranceCategoryFamilyType: 'insuranceCategoryFamilyType',
      insuranceSubscriptionsFeatures: 'optionalFeatures',
    };
  }

}
