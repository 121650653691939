import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsTrusteeContact} from '@lifeislife/lifeislife-ws-api';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {ContactService} from '../contact/contact.service';
import {Ref} from '../../domain/shared/ref';
import {TrusteeContactConverter} from './trustee-contact.converter';
import {TrusteeContact} from '../../domain/trustee/trustee-contact';
import {TrusteeContactWsClient} from '../../client/resources/front/trustee-contact-ws-client';
import {HttpParams} from '@angular/common/http';
import {TrusteeContactSearchConverter} from './trustee-contact-search.converter';
import {TrusteeContactSearch} from '../../domain/trustee/trustee-contact-search';

@Injectable({
  providedIn: 'root',
})
export class TrusteeContactService {


  constructor(private client: TrusteeContactWsClient,
              private contactService: ContactService,
  ) {
  }

  getTrusteeContact$(ref: Ref<TrusteeContact>): Observable<TrusteeContact> {
    return ref == null ? of(null) : this.client.doGet(ref.id).pipe(
      map(wsTrusteeContact => TrusteeContactConverter.convertIn(wsTrusteeContact)),
    );
  }

  searchTrusteeContacts$(trusteeContactFilter: TrusteeContactSearch, pagination: SimplePagination): Observable<SearchResult<TrusteeContact>> {
    const wsSearch = TrusteeContactSearchConverter.convertOut(trusteeContactFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateTrusteeContact$(trusteeContact: TrusteeContact): Observable<ValidationResult<TrusteeContact>> {
    const wsTrusteeContact = TrusteeContactConverter.convertOut(trusteeContact);
    return this.client.doValidate(wsTrusteeContact).pipe(
      map((results: WsTrusteeContact | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveTrusteeContact$(trusteeContact: TrusteeContact): Observable<Ref<TrusteeContact>> {
    const wsTrusteeContact = TrusteeContactConverter.convertOut(trusteeContact);
    return this.client.doSave(wsTrusteeContact);
  }

  deleteTrusteeContact$(id: number, force?: boolean): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        force: force ? 'true' : 'false',
      },
    });

    return this.client.doRemove(id, params);
  }

  serializeFilter(trusteeContactFilter: TrusteeContactSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(trusteeContactFilter);
    const wsTrusteeContactSearch = TrusteeContactSearchConverter.convertOut(trusteeContactFilter);
    return JSON.stringify(wsTrusteeContactSearch);
  }

  deserializeFilter(valueString: string | null): TrusteeContactSearch | null {
    if (valueString == null) {
      return null;
    }
    const wsSearch: any = JSON.parse(valueString);
    return TrusteeContactSearchConverter.convertIn(wsSearch);
  }


  private createValidationResult(results: WsTrusteeContact | WsConstraintViolation[]): ValidationResult<TrusteeContact> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsTrusteeContact => TrusteeContactConverter.convertIn(wsTrusteeContact),
      propertyNameMappings: TrusteeContactConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsTrusteeContact>): Observable<SearchResult<TrusteeContact>> {
    const rows = results.list.map(wsTrusteeContact => TrusteeContactConverter.convertIn(wsTrusteeContact));
    return of(rows).pipe(
      map(newRows => Object.assign({}, results, <Partial<SearchResult<TrusteeContact>>>{
          list: newRows,
        }),
      ));
  }
}
