import {ApplicationMenuItemDef} from '../main/routing/application-menu-item-def';
import {CustomerMenuData} from './customer-menu-data';

export const CustomerMenu: ApplicationMenuItemDef[] = [
  {data: CustomerMenuData.allList},
  {data: CustomerMenuData.activesList},
  {data: CustomerMenuData.new},
];

export const CustomerDetailsTabMenu: ApplicationMenuItemDef[] = [
  {data: CustomerMenuData.details.children.info},
  {data: CustomerMenuData.details.children.contact},
  {data: CustomerMenuData.details.children.customerBankAccount},
  {data: CustomerMenuData.details.children.insuranceSubscriptions},
  {data: CustomerMenuData.details.children.creditSubscriptions},
  {data: CustomerMenuData.details.children.investmentSubscriptions},
  {data: CustomerMenuData.details.children.signingPackage},
];
