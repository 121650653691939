export class DragDropUtils {
  // private static CUSTOMER_FILE_DATA_TYPE = 'application/x-ws-lifeislife-file';
  // IE11 cannot set any mime type in the dragevent data
  // Not even the standard text/plain apparently..
  // private static IE_11_TEXT_DATA_TYPE = 'Text';
  // private static CUSTOMER_FILE_ID_LIST_DRAG_DATA_TPE = 'customer-file-id-list';


  static hasFileData(event: DragEvent): boolean {
    const data = event.dataTransfer;
    if (data == null) {
      return false;
    }

    if (DragDropUtils.hasDataType(data, 'Files')) {
      return true;
    }

    return false;
  }

  static getFileData(event: DragEvent): FileList | null {
    const data = event.dataTransfer;
    if (data == null) {
      return null;
    }
    const files = data.files;
    return files;
  }

  private static hasDataType(data: DataTransfer, typeName: string): boolean {
    if (data.types != null) {
      // This wont be a JS array on IE
      const typesList = data.types;
      for (let index = 0; index < typesList.length; index++) {
        const type = typesList[index];
        if (type.toLowerCase() === typeName.toLowerCase()) {
          return true;
        }
      }
    }
    return false;
  }

}
