import {WsTicket, WsTicketStatus, WsTicketType} from '@lifeislife/lifeislife-ws-api';
import {Ticket} from '../../domain/ticket/ticket';
import {TicketStatus} from '../../domain/ticket/ticket-status';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {TicketType} from '../../domain/ticket/ticket-type';

export class TicketConverter {

  static convertIn(value: WsTicket): Ticket {
    return {
      id: value.id,
      version: value.version,
      authorContactRef: value.authorContactRef,
      ticketType: EnumConverterUtils.convertValue(value.ticketType, TicketType),
      updateDateTime: DateConverterUtils.parseIsoDateTime(value.updateDateTime),
      subject: value.subject,
      description: value.description,
      ticketStatus: EnumConverterUtils.convertValue(value.ticketStatus, TicketStatus),
      creationDateTime: DateConverterUtils.parseIsoDateTime(value.creationDateTime),
    };
  }

  static convertOut(value: Ticket): WsTicket {
    return {
      id: value.id,
      version: value.version,
      authorContactRef: value.authorContactRef,
      ticketType: EnumConverterUtils.convertValue(value.ticketType, WsTicketType),
      updateDateTime: DateConverterUtils.formatDateToIsoDateTime(value.updateDateTime),
      subject: value.subject,
      description: value.description,
      ticketStatus: EnumConverterUtils.convertValue(value.ticketStatus, WsTicketStatus),
      creationDateTime: DateConverterUtils.formatDateToIsoDateTime(value.creationDateTime),
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsTicket]: keyof Ticket } {
    return {
      id: 'id',
      version: 'version',
      updateDateTime: 'updateDateTime',
      ticketType: 'ticketType',
      authorContactRef: 'authorContactRef',
      creationDateTime: 'creationDateTime',
      description: 'description',
      subject: 'subject',
      ticketStatus: 'ticketStatus',
    };
  }

}
