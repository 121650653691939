import {Injectable} from '@angular/core';
import {
  WsConstraintViolation,
  WsInsuranceProvider,
  WsInsuranceProviderSearch,
  WsProductProviderSearch,
  WsRef,
} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, publishReplay, refCount, switchMap, tap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {WithId} from '../../client/domain/with-id';
import {InsuranceProviderWsClient} from '../../client/resources/front/insurance/insurance-provider-ws-client';
import {InsuranceProviderConverter} from './insurance-provider-converter';
import {ProductProviderService} from '../corebusiness/product-provider.service';
import {ProductProvider} from '../../domain/corebusiness/product-provider';
import {SimplePaginationUtils} from '../../util/pagination/simple-pagination-utils';
import {InsuranceProvider} from '../../domain/insurance/insurance-provider';
import {InsuranceProviderSearch} from '../../domain/insurance/insurance-provider-search';
import {UnrestictedInsurancePartnersClient} from '../../client/resources/unrestricted/unresticted-insurance-partners-client';
import {ProductProviderConverter} from '../corebusiness/product-provider-converter';

@Injectable({
  providedIn: 'root',
})
export class InsuranceProviderService {

  private partnerProvidersPageSize = 12;
  private cachedPartnerProvidersPage: Ref<InsuranceProvider>[];

  constructor(private client: InsuranceProviderWsClient,
              private prouctProviderService: ProductProviderService,
              private unrestrictedClient: UnrestictedInsurancePartnersClient,
  ) {
  }

  getUnrestrictedInsuranceProvider$(ref: Ref<InsuranceProvider>): Observable<InsuranceProvider> {
    return ref == null ? of(null) : this.unrestrictedClient.getInsurancetPartner$(ref).pipe(
      map(wsInsuranceProvider => InsuranceProviderConverter.convertIn(wsInsuranceProvider)),
    );
  }


  getUnrestrictedProductProvider$(ref: Ref<ProductProvider>): Observable<ProductProvider> {
    return ref == null ? of(null) : this.unrestrictedClient.getPartner$(ref).pipe(
      map(wsProductProvider => ProductProviderConverter.convertIn(wsProductProvider)),
    );
  }

  getUnrestrictedProviderLogoUrl(ref: Ref<ProductProvider>, useFallback?: boolean): string {
    return ref == null ? null : this.unrestrictedClient.getProductProviderLogoUrl$(ref, useFallback);
  }

  searchPartnersProvidersPage(): Observable<Ref<InsuranceProvider>[]> {
    if (this.cachedPartnerProvidersPage) {
      return of(this.cachedPartnerProvidersPage);
    }
    return this.searchUnrestrictedProviders$(SimplePaginationUtils.newPagination(this.partnerProvidersPageSize)).pipe(
      map(r => r.list),
      tap(l => this.cachedPartnerProvidersPage = l),
      publishReplay(1), refCount(),
    );
  }

  searchUnrestrictedProviders$(pagination: SimplePagination): Observable<SearchResult<Ref<InsuranceProvider>>> {
    return this.unrestrictedClient.getInsurancePartnersList$({
      wsProductProviderSearch: {
        archived: false,
      } as WsProductProviderSearch,
    }, pagination);
  }


  getInsuranceProvider$(ref: Ref<InsuranceProvider>, forceFetch?: boolean): Observable<InsuranceProvider> {
    return ref == null ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsInsuranceProvider => InsuranceProviderConverter.convertIn(wsInsuranceProvider)),
    );
  }

  getInsuranceForProductProvider$(providerRef: Ref<ProductProvider>): Observable<InsuranceProvider> {
    const insuranceSearch: InsuranceProviderSearch = {
      productProviderSearch: {
        productProviderRef: providerRef,
      },
    };
    const pagination = SimplePaginationUtils.newPagination(1);
    return this.searchInsuranceProviders$(insuranceSearch, pagination).pipe(
      map(r => r.count === 0 ? null : r.list[0]),
    );
  }

  getInsuranceProviderName$(insuranceProvider: InsuranceProvider, publicLabel?: boolean): Observable<string> {
    if (insuranceProvider == null) {
      return of('');
    }
    if (insuranceProvider.id == null || insuranceProvider.productProviderRef == null) {
      return of(`Nouvelle compagnie d'assurance`);
    }
    return this.prouctProviderService.getProductProvider$(insuranceProvider.productProviderRef).pipe(
      map(p => publicLabel ? p.publicLabel : p.internalLabel),
    );
  }

  searchInsuranceProviders$(InsuranceSearch: InsuranceProviderSearch, pagination: SimplePagination)
    : Observable<SearchResult<InsuranceProvider>> {
    const wsSearch = InsuranceProviderConverter.convertFilterOut(InsuranceSearch);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateInsuranceProvider$(insurance: InsuranceProvider): Observable<ValidationResult<InsuranceProvider>> {
    const wsInsuranceProvider = InsuranceProviderConverter.convertOut(insurance);
    return this.client.doValidate(wsInsuranceProvider).pipe(
      map((results: WsInsuranceProvider | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveInsuranceProvider$(insurance: InsuranceProvider): Observable<WithId> {
    const wsInsuranceProvider = InsuranceProviderConverter.convertOut(insurance);
    return this.client.doSave(wsInsuranceProvider);
  }

  deleteInsuranceProvider$(ref: Ref<InsuranceProvider>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  serializeFilter(InsuranceSearch: InsuranceProviderSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(InsuranceSearch);
    const wsInsuranceProviderSearch = InsuranceProviderConverter.convertFilterOut(InsuranceSearch);
    return JSON.stringify(wsInsuranceProviderSearch);
  }

  deserializeFilter(valueString: string | null): InsuranceProviderSearch {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsInsuranceProviderSearch = JSON.parse(valueString);
      const filter = InsuranceProviderConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsInsuranceProvider | WsConstraintViolation[]): ValidationResult<InsuranceProvider> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsInsuranceProvider => InsuranceProviderConverter.convertIn(wsInsuranceProvider),
      propertyNameMappings: InsuranceProviderConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsInsuranceProvider>>): Observable<SearchResult<InsuranceProvider>> {
    const rowTasks = results.list.map(ref => this.getInsuranceProvider$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<InsuranceProvider>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<InsuranceProvider>>>{
          list: rows,
        }),
      ));
  }
}
