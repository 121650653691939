import {Injectable} from '@angular/core';
import {RequestService} from '../../../service/request.service';
import {WsContact, WsDaoEventEntityType, WsRef} from '@lifeislife/lifeislife-ws-api';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {Observable} from 'rxjs';
import {HttpHeaders} from '@angular/common/http';
import {WsNotificationMessage} from '@lifeislife/lifeislife-ws-api';
import {WsContactClaim, WsContactClaimType} from '@lifeislife/lifeislife-ws-api';

@Injectable({
  providedIn: 'root',
})
export class ContactWsClient extends CachedWsResourceClient<WsContact, WsContact> {

  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private injectedAppConfigService: AppConfigService) {
    super('/front/contact', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.CONTACT, injectectedCacheService);
  }

  mergeContact$(target: WsContact, sourceRef: WsRef<WsContact>): Observable<WsRef<WsContact>> {
    const url = `${this.getResourceUrl()}/${target.id}/reconcile/${sourceRef.id}`;
    return this.requestService.sendRequest<WsRef<WsContact>>({
      url: url,
      body: target,
      method: 'PUT',
    }, this.authService.getAuth());
  }

  getContactClaim$(ref: WsRef<WsContact>, claimType: WsContactClaimType): Observable<WsContactClaim> {
    const url = `${this.getResourceUrl()}/${ref.id}/claim/${claimType}`;
    return this.requestService.sendRequest<WsContactClaim>({
      url: url,
      method: 'GET',
    }, this.authService.getAuth());
  }

  deleteContactClaim$(ref: WsRef<WsContact>, claimType: WsContactClaimType): Observable<any> {
    const url = `${this.getResourceUrl()}/${ref.id}/claim/${claimType}`;
    return this.requestService.sendRequest<any>({
      url: url,
      method: 'DELETE',
    }, this.authService.getAuth());
  }

  registerWsPushRequest$(ref: WsRef<WsContact>, requestJson: any): Observable<any> {
    const url = `${this.getResourceUrl()}/${ref.id}/webpush`;
    const headersMap = new HttpHeaders({
      'content-type': 'application/json',
    });
    return this.requestService.sendRequest<any>({
      url: url,
      body: requestJson,
      headers: headersMap,
      method: 'POST',
    }, this.authService.getAuth());
  }

  getWebPushSubscriptionDeviceNames$(ref: WsRef<WsContact>): Observable<string[]> {
    const url = `${this.getResourceUrl()}/${ref.id}/webpush`;
    return this.requestService.sendRequest<string[]>({
      url: url,
      method: 'POST',
    }, this.authService.getAuth());
  }

  postNotification$(ref: WsRef<WsContact>, message: WsNotificationMessage): Observable<string[]> {
    const url = `${this.getResourceUrl()}/${ref.id}/webpush/message`;
    const headersMap = new HttpHeaders({
      'content-type': 'application/json',
    });
    return this.requestService.sendRequest<string[]>({
      url: url,
      method: 'POST',
      headers: headersMap,
      body: message,
    }, this.authService.getAuth());
  }


}
