import {WsCreditProvider, WsCreditProviderSearch} from '@lifeislife/lifeislife-ws-api';
import {CreditProvider} from '../../../domain/corebusiness/credit/credit-provider';
import {CreditProviderSearch} from '../../../domain/corebusiness/credit/credit-provider-search';
import {ProductProviderConverter} from '../product-provider-converter';

export class CreditProviderConverter {

  static convertIn(value: WsCreditProvider): CreditProvider {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      productProviderRef: value.productProviderWsRef,
    };
  }

  static convertOut(value: CreditProvider): WsCreditProvider {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      productProviderWsRef: value.productProviderRef,
    };
  }


  static convertFilterIn(value?: WsCreditProviderSearch): CreditProviderSearch {
    if (value == null) {
      return undefined;
    }
    return {
      productProviderSearch: ProductProviderConverter.convertFilterIn(value.wsProductProviderSearch),
    };
  }

  static convertFilterOut(value: CreditProviderSearch): WsCreditProviderSearch {
    if (value == null) {
      return undefined;
    }
    return {
      wsProductProviderSearch: ProductProviderConverter.convertFilterOut(value.productProviderSearch),
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsCreditProvider]: keyof CreditProvider } {
    return {
      id: 'id',
      productProviderWsRef: 'productProviderRef',
    };
  }

}
