import {AUTH_TYPE_BASIC, AUTH_TYPE_OAUTH, AUTH_TYPE_USER_TOKEN, LifeisLifeWsAuth} from '../../client/domain/auth/lifeis-life-ws-auth';
import {WsBasicAuth} from '../../client/domain/auth/ws-basic-auth';
import {WsUserTokenAuth} from '../../client/domain/auth/ws-user-token-auth';
import {Base64Encoder} from '../base64/base64-encoder';

export class AuthFactory {

  static revive(authJs: any, encoder: Base64Encoder): LifeisLifeWsAuth {
    if (authJs == null || authJs.type == null) {
      return null;
    }
    if (authJs.type === AUTH_TYPE_BASIC) {
      return new WsBasicAuth(authJs.userName, authJs.password, encoder);
    } else if (authJs.type === AUTH_TYPE_OAUTH) {
      return WsUserTokenAuth.fromOldToken(authJs);
    } else if (authJs.type === AUTH_TYPE_USER_TOKEN) {
      return WsUserTokenAuth.fromUserToken(authJs.wsUserToken);
    } else {
      throw new Error('Unknown auth type: ' + authJs.type);
    }
  }

  static reviveTokenString(tokenString: string): LifeisLifeWsAuth {
    return WsUserTokenAuth.fromOldToken({token: tokenString});
  }
}
