import {AppFeature} from './app-feature';
import {Role} from '@lifeislife/lifeislife-domain';

/**
 * App features that should be visible to specific roles, for all resources.
 * Trustee scoped features may be granted as well for specific trustee roles.
 */
export const APP_FEATURE_ROLE_GRANTS: { [K in AppFeature]: Role[] } = {

  // Batch
  [AppFeature.batch_list]: [Role.ADMIN],

  // Contact
  [AppFeature.contact_list]: [Role.ADMIN, Role.TRUSTEE],
  [AppFeature.contact_edit]: [Role.ADMIN],
  [AppFeature.contact_create]: [Role.ADMIN],

  // Customer
  [AppFeature.customer_list]: [Role.ADMIN, Role.TRUSTEE],
  [AppFeature.customer_create]: [Role.ADMIN],
  [AppFeature.customer_edit]: [Role.ADMIN],
  // Customer subcription snapshot report (etat des lieux EDL)
  [AppFeature.customer_subscription_snapshots_create]: [Role.ADMIN],
  // Customer mailing contacts report (excel with contact/customer info grouped by trustee/contact)
  [AppFeature.customer_mailing_contacts_create]: [Role.ADMIN],
  // Customer mandate request creation (changement d'intermédiaire)
  [AppFeature.customer_mandate_request_create]: [Role.ADMIN],
  // Customer bank accounts
  [AppFeature.customer_bank_account_list]: [Role.ADMIN],
  [AppFeature.customer_bank_account_create]: [Role.ADMIN],
  [AppFeature.customer_bank_account_edit]: [Role.ADMIN],
  [AppFeature.customer_bank_account_remove]: [Role.ADMIN],
  // Customer contacts
  [AppFeature.customer_contact_list]: [Role.ADMIN, Role.TRUSTEE],
  [AppFeature.customer_contact_invite]: [Role.ADMIN],
  [AppFeature.customer_contact_create]: [Role.ADMIN],
  [AppFeature.customer_contact_remove]: [Role.ADMIN],
  [AppFeature.customer_contact_edit]: [Role.ADMIN],
  // Customer events
  [AppFeature.customer_event_subscribe]: [],


  // Insurance
  [AppFeature.insurance_list]: [Role.ADMIN],
  // Insurance category
  [AppFeature.insurance_category_list]: [Role.ADMIN, Role.BASIC_USER],
  [AppFeature.insurance_category_create]: [Role.ADMIN],
  [AppFeature.insurance_category_edit]: [Role.ADMIN],
  // Edition of category feature (custom fields)
  [AppFeature.insurance_category_features_edit]: [Role.MASTER],
  // Insurance Provider
  [AppFeature.insurance_provider_list]: [Role.ADMIN, Role.BASIC_USER],
  [AppFeature.insurance_provider_create]: [Role.ADMIN],
  [AppFeature.insurance_provider_edit]: [Role.ADMIN],
  // Insurance subcription
  [AppFeature.insurance_subscription_list]: [Role.ADMIN, Role.TRUSTEE],
  [AppFeature.insurance_subscription_create]: [Role.ADMIN],
  [AppFeature.insurance_subscription_edit]: [Role.ADMIN],

  // LifeisLife logs
  [AppFeature.lifeislife_log_list]: [Role.ADMIN],
  [AppFeature.lifeislife_log_create]: [Role.ADMIN],

  // Mails
  [AppFeature.mail_list]: [Role.MASTER],

  // Message templates
  [AppFeature.message_template_list]: [Role.MASTER],
  [AppFeature.message_template_edit]: [Role.MASTER],


  // Events
  [AppFeature.event_record_list]: [Role.ADMIN],

  // Signing packages
  [AppFeature.signing_packages_list]: [Role.ADMIN, Role.BASIC_USER],
  [AppFeature.signing_packages_create]: [Role.ADMIN],
  [AppFeature.signing_packages_check_signature]: [],


  // Trustees
  [AppFeature.trustee_list]: [Role.ADMIN],
  [AppFeature.trustee_edit]: [Role.ADMIN],
  [AppFeature.trustee_create]: [Role.ADMIN],
  // Trustee contacts
  [AppFeature.trustee_contact_list]: [Role.ADMIN],
  [AppFeature.trustee_contact_invite]: [Role.ADMIN],
  [AppFeature.trustee_contact_create]: [Role.ADMIN],
  [AppFeature.trustee_contact_remove]: [Role.ADMIN],
  [AppFeature.trustee_contact_edit]: [Role.ADMIN],

  // Preferences
  [AppFeature.app_preference_list]: [Role.MASTER],
  [AppFeature.app_preference_edit]: [Role.MASTER],

  // Users
  [AppFeature.user_list]: [Role.ADMIN],
  [AppFeature.user_edit]: [Role.ADMIN],
  [AppFeature.user_edit_admin]: [Role.ADMIN],
  [AppFeature.user_edit_master]: [Role.MASTER],


  ///
  /// Deprecated // Unused

  [AppFeature.credit_list]: [],
  [AppFeature.credit_create]: [],
  [AppFeature.credit_edit]: [],
  [AppFeature.credit_provider_list]: [],
  [AppFeature.credit_provider_create]: [],
  [AppFeature.credit_provider_edit]: [],
  [AppFeature.credit_subscription_list]: [],
  [AppFeature.credit_subscription_create]: [],
  [AppFeature.credit_subscription_edit]: [],
  [AppFeature.insurance_create]: [],
  [AppFeature.insurance_edit]: [],
  [AppFeature.investment_list]: [],
  [AppFeature.investment_create]: [],
  [AppFeature.investment_edit]: [],
  [AppFeature.investment_provider_list]: [],
  [AppFeature.investment_provider_create]: [],
  [AppFeature.investment_provider_edit]: [],
  [AppFeature.investment_subscription_list]: [],
  [AppFeature.investment_subscription_create]: [],
  [AppFeature.investment_subscription_edit]: [],
  [AppFeature.person_title_list]: [],
  [AppFeature.person_title_edit]: [],
  [AppFeature.person_title_create]: [],
  [AppFeature.smtp_account_list]: [],
  [AppFeature.smtp_account_edit]: [],
  [AppFeature.smtp_account_create]: [],
  [AppFeature.subscription_list]: [],

  [AppFeature.email_account_list]: [],
  [AppFeature.email_account_edit]: [],
  [AppFeature.email_account_create]: [],

};

export function getLifeisLifeRoleBaseSwitchGrants(): { [key: string]: string[] } {
  return toAppConfigSettings(APP_FEATURE_ROLE_GRANTS);
}

function toAppConfigSettings(roleGrants: { [K in AppFeature]: Role[] }): { [K in string]: string[] } {
  const settings = {};
  for (const key in roleGrants) {
    if (typeof key === 'string' && roleGrants.hasOwnProperty(key)) {
      const stringValues = roleGrants[key].map(r => `${r}`);
      settings[key] = stringValues;
    }
  }
  return settings;
}
