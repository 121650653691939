<div class="upload">
  <p-dialog [visible]="uploadRunning$ | async" *ngIf="showDialog"
            [modal]="true" [closable]="false" [draggable]="false" [resizable]="false"
            class="uploadDialog">
    <header>
      <i class="fa fa-cloud-upload"></i>
      Envoi des fichiers
    </header>

    <div class="Card">
      <div class="message">
        Envoi des fichiers en cours, veuillez patienter...
      </div>
      <div *ngIf="(uploadProgress$ | async) != null">
        <div class="files count">
          {{ (uploadProgress$ | async).fileUploaded }} / {{ (uploadProgress$ | async).fileCount }} fichier(s)
        </div>
        <div class="progress-bar">
          <p-progressBar [value]="(uploadProgress$ | async).bytesPercent"></p-progressBar>
        </div>
        <div class="files count">
          {{ (uploadProgress$ | async).bytesUploaded }} / {{ (uploadProgress$ | async).bytesCount }} bytes
        </div>
      </div>
      <div class="footer">
        <button type="button" (click)="onCancelClicked()"
                pButton label="Annuler"></button>
      </div>
    </div>
  </p-dialog>

  <form>
    <input type="file" #fileInput accept="*/*"
           name="fileInput"  [attr.multiple]="multiple"
           [ngModel]="fileSelection"
           (change)="onFilesSelected($event)"
           class="hidden">
    <input type="file" #imageInput accept="image/*"
           name="imageInput" [attr.multiple]="multiple"
           [ngModel]="fileSelection"
           (change)="onFilesSelected($event)"
           class="hidden">
  </form>
</div>
