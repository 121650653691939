import {Injectable} from '@angular/core';
import {ParsedPhoneNumber} from './parsed-phone-number';

@Injectable()
export class PhoneNumberService {

  parseSimplePhoneNumber(value: string): string {
    return value;
  }

  parsePhoneNumberWithprefix(value: string): ParsedPhoneNumber {
    if (value == null || typeof value !== 'string' || value.length < 1) {
      return {
        prefix: null,
        number: null,
        guessed: false,
      };
    }
    // Starts with +xx
    if (value.match(/^\+[1-9]\d/)) {
      const prefix = value.substring(0, 3);
      const numberToMatch = value.substring(3);
      const number = this.digitsOnly(numberToMatch);
      return {
        prefix: prefix,
        number: number,
        guessed: true,
      };
    }
    // Starts with 00xx
    if (value.match(/^00[1-9]\d/)) {
      const prefix = `+${value.substring(2, 4)}`;
      const numberToMatch = value.substring(4);
      const number = this.digitsOnly(numberToMatch);
      return {
        prefix: prefix,
        number: number,
        guessed: true,
      };
    }
    // Starts with 0x
    if (value.match(/^0[1-9]/)) {
      const numberToMatch = value.substring(1);
      const number = this.digitsOnly(numberToMatch);
      return {
        prefix: null,
        number: number,
        guessed: true,
      };
    }
    // Unable to guess
    return {
      prefix: null,
      number: value,
      guessed: false,
    };
  }

  digitsOnly(number: string, trimLength?: number): string {
    if (number == null) {
      return '';
    }
    // iterate over each chars
    const notDigitRegexp = /[^0-9]/;
    let digitNumber = '';
    for (let index = 0; index < number.length; index++) {
      const char = number.charAt(index);
      if (index !== 0 || char !== '+') {
        if (char.match(notDigitRegexp)) {
          continue;
        }
      }
      digitNumber += char;
      if (trimLength > 0 && digitNumber.length >= trimLength) {
        break;
      }
    }
    return digitNumber;
  }
}
