import {Injectable} from '@angular/core';
import {RequestService} from '../../service/request.service';
import {CachedWsResourceClient} from '../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {AppConfigService} from '../../../service/config/app-config.service';
import {Observable, of} from 'rxjs';
import {ResourceCacheService} from '../../../util/cache/resource-cache.service';
import {FrontendResourceType} from '../../../util/cache/cache-resource-type';
import {switchMap, tap} from 'rxjs/operators';
import {WsLifeisLifeMessage} from '@lifeislife/lifeislife-ws-api';

@Injectable({
  providedIn: 'root',
})
export class MessageTypeWsClient extends CachedWsResourceClient<any, any> {

  private messageTypesListCache: WsLifeisLifeMessage[] = null;

  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private injectedAppConfigService: AppConfigService) {
    super('/front/messageType', injectedAppConfigService, injectedRequestService, injectedAuthService,
      FrontendResourceType.MESSAGE_TYPE, injectectedCacheService);
  }

  listMessageTypes$(skipcache?: boolean): Observable<WsLifeisLifeMessage[]> {
    if (this.messageTypesListCache != null && !skipcache) {
      return of(this.messageTypesListCache);
    }
    const url = `${this.getResourceUrl()}/list`;
    return this.requestService.sendRequest<WsLifeisLifeMessage[]>({
      method: 'GET', url: url,
    }, this.authService.getAuth()).pipe(
      tap(list => this.messageTypesListCache = list),
    );
  }

  getMessageType$(typeName: string): Observable<WsLifeisLifeMessage> {
    if (this.messageTypesListCache) {
      return of(this.messageTypesListCache.find(t => t.messageTypeName === typeName));
    }
    return this.listMessageTypes$(false).pipe(
      switchMap(r => this.getMessageType$(typeName)),
    );
  }

  clearCache() {
    this.messageTypesListCache = null;
  }


}
