import {Component, Input, OnInit} from '@angular/core';
import {Contact, ContactService, Ref} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';

@Component({
  selector: 'llc-lifeislife-contact',
  templateUrl: './lifeislife-contact.component.html',
  styleUrls: ['./lifeislife-contact.component.scss'],
})
export class LifeislifeContactComponent implements OnInit {

  @Input()
  set contactRef(value: Ref<Contact>) {
    this.contactRef$.next(value);
  }

  contactRef$ = new BehaviorSubject<Ref<Contact>>(null);
  label$: Observable<string>;

  constructor(
    private contactService: ContactService,
  ) {
  }

  ngOnInit(): void {
    this.label$ = this.contactRef$.pipe(
      switchMap(ref => ref == null ? of(null) : this.contactService.getContact$(ref)),
      map(c => this.contactService.getContactName(c)),
      publishReplay(1), refCount(),
    );
  }

}
