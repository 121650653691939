import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {LifeisLifeLogConverter} from './lifeislife-log.converter';
import {LifeisLifeLogWsClient} from '../../client/resources/front/log/lifeislife-log-ws-client';
import {WsLifeisLifeLog, WsRef} from '@lifeislife/lifeislife-ws-api';
import {LifeislifeLogSearch} from '../../domain/log/lifeislife-log-search';
import {LifeislifeLog} from '../../domain/log/lifeislife-log';
import {LifeislifeLogSearchConverter} from './lifeislife-log-search.converter';

@Injectable({
  providedIn: 'root',
})
export class LifeisLifeLogService {


  constructor(private client: LifeisLifeLogWsClient,
  ) {
  }

  getLifeisLifeLog$(id: number): Observable<LifeislifeLog> {
    return this.client.doGet(id).pipe(
      map(wsLifeisLifeLog => LifeisLifeLogConverter.convertIn(wsLifeisLifeLog)),
    );
  }

  fetchLifeisLifeLog$(id: number): Observable<LifeislifeLog> {
    return this.client.doFetch(id).pipe(
      map(wsLifeisLifeLog => LifeisLifeLogConverter.convertIn(wsLifeisLifeLog)),
    );
  }

  searchLifeisLifeLogs$(lifeisLifeLogSearch: LifeislifeLogSearch, pagination: SimplePagination): Observable<SearchResult<LifeislifeLog>> {
    const wsSearch = LifeislifeLogSearchConverter.convertOut(lifeisLifeLogSearch);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsLifeisLifeLog>>): Observable<SearchResult<LifeislifeLog>> {
    const rowTasks = results.list.map(ref => this.getLifeisLifeLog$(ref.id));
    return rowTasks.length === 0 ? of(new SearchResult<LifeislifeLog>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<LifeislifeLog>>>{
          list: rows,
        }),
      ));
  }

}
