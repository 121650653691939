import {WsCustomerContact, WsCustomerContactType} from '@lifeislife/lifeislife-ws-api';
import {Observable, of} from 'rxjs';
import {SearchResult} from '../../client/domain/search/search-result';
import {CustomerContact} from '../../domain/customer-contact/customer-contact';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {CustomerContactType} from '../../domain/customer-contact/customer-contact-type';

export class CustomerContactConverter {

  static convertIn(value: WsCustomerContact): CustomerContact {
    return {
      id: value.id,
      customerRef: value.customerRef,
      contactRef: value.contactRef,
      customerContactType: EnumConverterUtils.convertValue(value.mailContactType, CustomerContactType),
      position: value.position,
      newMandateRequestUri: value.newMandateRequestUri,
    };
  }

  static convertOut(value: CustomerContact): WsCustomerContact {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      customerRef: value.customerRef,
      contactRef: value.contactRef,
      mailContactType: EnumConverterUtils.convertValue(value.customerContactType, WsCustomerContactType),
      position: value.position,
      newMandateRequestUri: value.newMandateRequestUri,
    };
  }

  static convertSearchResultsIn$(results: SearchResult<WsCustomerContact>): Observable<SearchResult<CustomerContact>> {
    return of(Object.assign({}, results, <Partial<SearchResult<CustomerContact>>>{
      list: results.list.map(result => this.convertIn(result)),
    }));
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsCustomerContact]: keyof CustomerContact } {
    return {
      id: 'id',
      customerRef: 'customerRef',
      contactRef: 'contactRef',
      mailContactType: 'customerContactType',
      position: 'position',
      newMandateRequestUri: 'newMandateRequestUri',
    };
  }

}
