import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsCredit, WsCreditSearch, WsRef} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../../util/pagination/simple-pagination';
import {SearchResult} from '../../../client/domain/search/search-result';
import {ValidationResult} from '../../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../../object-converter-util';
import {isValidRef, Ref} from '../../../domain/shared/ref';
import {WithId} from '../../../client/domain/with-id';
import {CreditWsClient} from '../../../client/resources/front/corebusiness/credit-ws-client';
import {CreditConverter} from './credit-converter';
import {Credit} from '../../../domain/corebusiness/credit/credit';
import {CreditSearch} from '../../../domain/corebusiness/credit/credit-search';
import {ProductService} from '../product.service';
import {Product} from '../../../domain/corebusiness/product';
import {SimplePaginationUtils} from '../../../util/pagination/simple-pagination-utils';

@Injectable({
  providedIn: 'root',
})
export class CreditService {


  constructor(private client: CreditWsClient,
              private productService: ProductService,
  ) {
  }

  getCredit$(ref: Ref<Credit>, forceFetch?: boolean): Observable<Credit> {
    return ref == null ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsCredit => CreditConverter.convertIn(wsCredit)),
    );
  }

  getCreditForProduct$(productRef: Ref<Product>): Observable<Credit> {
    const creditSearch: CreditSearch = {
      productSearch: {
        productRef: productRef,
      },
    };
    const pagination = SimplePaginationUtils.newPagination(1);
    return this.searchCredits$(creditSearch, pagination).pipe(
      map(r => r.count === 0 ? null : r.list[0]),
    );
  }

  getCreditName$(credit: Credit): Observable<string> {
    if (credit == null || credit.id == null || credit.productRef == null) {
      return of('Nouveau credit');
    }
    return this.productService.getProduct$(credit.productRef).pipe(
      map(p => p.name),
    );
  }

  searchCredits$(creditFilter: CreditSearch, pagination: SimplePagination): Observable<SearchResult<Credit>> {
    const wsSearch = CreditConverter.convertFilterOut(creditFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateCredit$(credit: Credit): Observable<ValidationResult<Credit>> {
    const wsCredit = CreditConverter.convertOut(credit);
    return this.client.doValidate(wsCredit).pipe(
      map((results: WsCredit | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveCredit$(credit: Credit): Observable<WithId> {
    const wsCredit = CreditConverter.convertOut(credit);
    return this.client.doSave(wsCredit);
  }

  deleteCredit$(ref: Ref<Credit>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  serializeFilter(creditFilter: CreditSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(creditFilter);
    const wsCreditSearch = CreditConverter.convertFilterOut(creditFilter);
    return JSON.stringify(wsCreditSearch);
  }

  deserializeFilter(valueString: string | null): CreditSearch {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsCreditSearch = JSON.parse(valueString);
      const filter = CreditConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsCredit | WsConstraintViolation[]): ValidationResult<Credit> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsCredit => CreditConverter.convertIn(wsCredit),
      propertyNameMappings: CreditConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsCredit>>): Observable<SearchResult<Credit>> {
    const rowTasks = results.list.map(ref => this.getCredit$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<Credit>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<Credit>>>{
          list: rows,
        }),
      ));
  }
}
