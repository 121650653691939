import {WsPensionPlanStatus, WsPensionPlanStatusSearch} from '@lifeislife/lifeislife-ws-api';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {PensionPlanStatus} from '../../domain/retire/pension-plan-status';
import {PensionPlanStatusSearch} from '../../domain/retire/pension-plan-status-search';
import {PensionPlanTypeConverter} from './pension-plan-type-converter';

export class PensionPlanStatusConverter {

  static convertIn(wsPensionPlanStatus: WsPensionPlanStatus): PensionPlanStatus {
    return {
      id: wsPensionPlanStatus.id,
      supplementaryPensionStatusRef: wsPensionPlanStatus.supplementaryPensionStatusWsRef,
      statusProviderName: wsPensionPlanStatus.statusProviderName,
      pensionPlanType: PensionPlanTypeConverter.convertIn(wsPensionPlanStatus.pensionPlanType),
      organiserName: wsPensionPlanStatus.organiserName,
      organiserVatNumber: wsPensionPlanStatus.organiserVatNumber,
      pensionBodyName: wsPensionPlanStatus.pensionBodyName,
      pensionBodyVatNumber: wsPensionPlanStatus.pensionBodyVatNumber,
      pensionBodyUrl: wsPensionPlanStatus.pensionBodyUrl,
      name: wsPensionPlanStatus.name,
      reference: wsPensionPlanStatus.reference,
      beneficiaryStatus: wsPensionPlanStatus.beneficiaryStatus,
      beneficiaryStatusActive: wsPensionPlanStatus.beneficiaryStatusActive,
      computationDate: DateConverterUtils.parseIsoDate(wsPensionPlanStatus.computationDate),
      statusDate: DateConverterUtils.parseIsoDate(wsPensionPlanStatus.statusDate),
      accountIdentifier: wsPensionPlanStatus.accountIdentifier,
      pensionAccountComponentIdentifier: wsPensionPlanStatus.pensionAccountComponentIdentifier,
      pensionReserve: wsPensionPlanStatus.pensionReserve,
      pensionFundingRatio: wsPensionPlanStatus.pensionFundingRatio,
      pensionAcquiredBenefitAmount: wsPensionPlanStatus.pensionAcquiredBenefitAmount,
      pensionExpectedBenefitAmount: wsPensionPlanStatus.pensionExpectedBenefitAmount,
      deathCoverAccountComponentIdentifier: wsPensionPlanStatus.deathCoverAccountComponentIdentifier,
      deathCoverAmount: wsPensionPlanStatus.deathCoverAmount,
      deatchCoverBeneficiariesAdditionalAllowance: wsPensionPlanStatus.deatchCoverBeneficiariesAdditionalAllowance,
      deatchCoverOrphinsAdditionalAllowance: wsPensionPlanStatus.deatchCoverOrphinsAdditionalAllowance,
      contactName: wsPensionPlanStatus.contactName,
      contactAddress: wsPensionPlanStatus.contactAddress,
      contactMail: wsPensionPlanStatus.contactMail,
      contactPhone: wsPensionPlanStatus.contactPhone,
    };
  }

  static convertSearchFilterOut(search: PensionPlanStatusSearch): WsPensionPlanStatusSearch {
    if (search == null) {
      return null;
    }
    return {
      wsSupplementaryPensionStatusWsRef: search.supplementaryPensionStatusRef,
    };
  }
}
