<div class="partner-icons" *ngIf="searcHresults$ | async">
  <div *ngFor="let ref of (searcHresults$ | async).list"
       class="logo"
  >
    <llc-insurance-partner [ref]="ref"
                           [showLogo]="showIcons"
                           [showTitle]="showNames"
                           [asLink]="asLinks"
                           [useFallbackLogo]="false"
    ></llc-insurance-partner>
  </div>
</div>
