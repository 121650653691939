import {UrlParserUtils} from '../util/UrlParserUtils';
import {ClientConfig, ResourceUtils} from '@lifeislife/lifeislife-domain';
import {Observable, of} from 'rxjs';

/**
 * @deprecated- do not share urls with credentials
 */
export const fetchAuthTokenFromQueryString = function (): Observable<string> {
  const token = UrlParserUtils.getQueryStringValue('auth_token');

  if (token == null) {
    return of(null);
  }
  return of(token);
};

/**
 * @deprecated do not share urls with credentials
 */
export const fetchInviteCodeFromQueryString = function (): Observable<string> {
  const token = UrlParserUtils.getQueryStringValue('invite_token');

  if (token == null) {
    return of(null);
  }
  return of(token);
};
