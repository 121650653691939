import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsInsurance, WsInsuranceSearch, WsRef} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {WithId} from '../../client/domain/with-id';
import {InsuranceWsClient} from '../../client/resources/front/insurance/insurance-ws-client';
import {InsuranceConverter} from './insurance-converter';
import {ProductService} from '../corebusiness/product.service';
import {Product} from '../../domain/corebusiness/product';
import {SimplePaginationUtils} from '../../util/pagination/simple-pagination-utils';
import {Insurance} from '../../domain/insurance/insurance';
import {InsuranceSearch} from '../../domain/insurance/insurance-search';
import {InsuranceType} from '../../domain/insurance/insurance-type';
import {InsuranceCategoryFamily} from '../../domain/insurance/insurance-category-family';
import {InsuranceBranch} from '../../domain/insurance/insurance-branch';

@Injectable({
  providedIn: 'root',
})
export class InsuranceService {


  constructor(private client: InsuranceWsClient,
              private productService: ProductService,
  ) {
  }

  getInsurance$(ref: Ref<Insurance>, forceFetch?: boolean): Observable<Insurance> {
    return ref == null ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsInsurance => InsuranceConverter.convertIn(wsInsurance)),
      tap(i => {
        if (i) {
          // prefetch related data
          forkJoin([
            this.productService.getProduct$(i.productRef),
          ]).subscribe();
        }
      }),
    );
  }

  getInsuranceForProduct$(productRef: Ref<Product>): Observable<Insurance> {
    const insuranceSearch: InsuranceSearch = {
      productSearch: {
        productRef: productRef,
      },
    };
    const pagination = SimplePaginationUtils.newPagination(1);
    return this.searchInsurances$(insuranceSearch, pagination).pipe(
      map(r => r.count === 0 ? null : r.list[0]),
    );
  }

  getInsuranceName$(insurance: Insurance): Observable<string | null> {
    if (insurance == null || insurance.id == null || insurance.productRef == null) {
      return of(null);
    }
    return this.productService.getProduct$(insurance.productRef).pipe(
      map(p => p.name),
    );
  }

  getInsuranceFamilyLabel(family: InsuranceCategoryFamily): string {
    if (family == null) {
      return null;
    }
    switch (family) {
      case InsuranceCategoryFamily.MOBILITY:
        return 'MOBILITÉ / VÉHICULE';
      case InsuranceCategoryFamily.HOME:
        return 'BATIMENT / HABITATION';
      case InsuranceCategoryFamily.WORK:
        return 'PROF / RESPONSABILITÉ';
      case InsuranceCategoryFamily.INVESTMENTS:
        return 'PLACEMENTS';
      case InsuranceCategoryFamily.RETIREMENT:
        return 'PENSION';
      case InsuranceCategoryFamily.INCOME_GUARANTEE:
        return 'GARANTIE DE REVENUS';
      case InsuranceCategoryFamily.HEALTH:
        return 'SANTÉ';
      case InsuranceCategoryFamily.FAMILY:
        return 'FAMILLE / PRIVÉ';
      case InsuranceCategoryFamily.DEATH:
        return 'DÉCÈS';
      default:
        console.warn('No label for insurance family' + family);
        return `${family}`;
    }
  }

  getInsuranceTypeName(insuranceType: InsuranceType): string {
    if (insuranceType == null) {
      return null;
    }
    switch (insuranceType) {
      case InsuranceType.IARD:
        return `IARD`;
      case InsuranceType.LIFE:
        return `LIFE`;
      default:
        throw new Error(`Unahdnled insurance type: ${insuranceType}`);
    }
  }


  getInsuranceBranchLabel(insuranceBranch: InsuranceBranch) {
    if (insuranceBranch == null) {
      return '';
    }
    switch (insuranceBranch) {
      case InsuranceBranch.BRANCH_21:
        return '21';
      case InsuranceBranch.BRANCH_23:
        return '23';
      case InsuranceBranch.MIX:
        return 'MIX';
      case InsuranceBranch.OTHER:
        return 'Autre';
    }
  }

  searchInsurances$(insuranceSearch: InsuranceSearch, pagination: SimplePagination): Observable<SearchResult<Insurance>> {
    const wsSearch = InsuranceConverter.convertFilterOut(insuranceSearch);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateInsurance$(insurance: Insurance): Observable<ValidationResult<Insurance>> {
    const wsInsurance = InsuranceConverter.convertOut(insurance);
    return this.client.doValidate(wsInsurance).pipe(
      map((results: WsInsurance | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveInsurance$(insurance: Insurance): Observable<WithId> {
    const wsInsurance = InsuranceConverter.convertOut(insurance);
    return this.client.doSave(wsInsurance);
  }

  deleteInsurance$(ref: Ref<Insurance>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  serializeFilter(insuranceSearch: InsuranceSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(insuranceSearch);
    const wsInsuranceSearch = InsuranceConverter.convertFilterOut(insuranceSearch);
    return JSON.stringify(wsInsuranceSearch);
  }

  deserializeFilter(valueString: string | null): InsuranceSearch {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsInsuranceSearch = JSON.parse(valueString);
      const filter = InsuranceConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsInsurance | WsConstraintViolation[]): ValidationResult<Insurance> {
    const nameMappings = InsuranceConverter.getValidationPropertyNameMappings();
    // Ensure backend properties are mapped
    nameMappings['insuranceCategory'] = 'insuranceCategoryRef';
    nameMappings['insuranceProvider'] = 'insuranceProviderWsRef';
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsInsurance => InsuranceConverter.convertIn(wsInsurance),
      propertyNameMappings: nameMappings,
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsInsurance>>): Observable<SearchResult<Insurance>> {
    const rowTasks = results.list.map(ref => this.getInsurance$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<Insurance>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<Insurance>>>{
          list: rows,
        }),
      ));
  }
}
