import {ErrorHandler, Injectable} from '@angular/core';
import {WsRef, WsStoredFile} from '@lifeislife/lifeislife-ws-api';
import {concat, forkJoin, Observable, of, throwError} from 'rxjs';
import {catchError, map, scan, switchMap} from 'rxjs/operators';
import {StoredFile} from '../../domain/file/stored-file';
import {StoredFileSearch} from '../../domain/file/stored-file-search';
import {StoredFileConverter} from './stored-file.converter';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {FilesUploadProgress} from '../../util/upload/files-upload-progress';
import {FileUploadProgress} from '../../util/upload/file-upload-progress';
import {UploadProgressUtils} from '../../util/upload/upload-progress.utils';
import {ContentDisposition, FileUtils} from '../../util/file/file-utils';
import {FileUploadMethod} from '../../util/upload/file-upload-method';
import {UploadErrorUtils} from '../../util/upload/upload-error-utils';
import {ObjectConverterUtil} from '../object-converter-util';
import {StoredFileWsClient} from '../../client/resources/front/file/stored-file-ws-client';
import {StoredFilesWsClient} from '../../client/resources/front/file/stored-files-ws-client';
import {SearchResult} from '../../client/domain/search/search-result';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {FileSubresourcePath} from '../../domain/file/file-subresource-path';
import {WithId} from '../../client/domain/with-id';
import {Subscription} from '../../domain/corebusiness/subscription';

@Injectable({
  providedIn: 'root',
})
export class StoredFileService {


  constructor(private client: StoredFileWsClient,
              private filesClient: StoredFilesWsClient,
              private errorService: ErrorHandler,
  ) {
  }

  clearFileCache(ref: Ref<StoredFile>) {
    this.client.doClearCache(ref.id);
  }

  getFile$(ref: Ref<StoredFile>, forceFetch ?: boolean): Observable<StoredFile> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doGet(ref.id, forceFetch).pipe(
      map(value => StoredFileConverter.convertIn(value)),
    );
  }

  searchFiles$(fileFilter: StoredFileSearch, pagination: SimplePagination): Observable<SearchResult<StoredFile>> {
    const wsSearch = StoredFileConverter.convertFilterOut(fileFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  saveFile$(file: StoredFile): Observable<any> {
    const wsFile = StoredFileConverter.convertOut(file);
    return this.client.doSave(wsFile);
  }

  deleteFile$(ref: Ref<StoredFile>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  renameFile$(ref: Ref<StoredFile>, name: string): Observable<Ref<StoredFile>> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.renameFile(ref.id, name).pipe(
      map(() => ref),
    );
  }

  getNewFilePath(): FileSubresourcePath<Subscription> {
    const path = this.client.getNewFilePath();
    return {
      ref: null,
      fileWsPath: path,
    };
  }

  uploadFile$(uploadMethod: FileUploadMethod, subResourcePath: FileSubresourcePath<WithId>, file: File)
    : Observable<FileUploadProgress> {

    return UploadProgressUtils.createFileUploadProgress$(file,
      this.client.addFile(subResourcePath.fileWsPath, file, error => {
        this.handleFileUploadError$(error, file, uploadMethod).subscribe();
      }),
    ).pipe(
      catchError(e => this.handleFileUploadError$(e, file, uploadMethod)),
    );
  }

  uploadFiles$(uploadMethod: FileUploadMethod, subResourcePath: FileSubresourcePath<WithId>, ...files: File[])
    : Observable<FilesUploadProgress> {
    const filesTotalSize = files.map(file => file.size)
      .reduce((cur, next) => cur + next, 0);
    const fileCount = files.length;
    const fileProgress$List = files.map(file => this.uploadFile$(uploadMethod, subResourcePath, file));

    return concat(...fileProgress$List).pipe(
      scan((cur, next) => this.reduceUploadProgressPerFile(cur, next), null),
      map(fileProgressList => UploadProgressUtils.createFilesUploadProgess(fileProgressList, filesTotalSize, fileCount)),
    );
  }

  getFileThumbnailPreviewUrl(ref: Ref<StoredFile>): string {
    if (!isValidRef(ref)) {
      throw new Error('Invalid ref');
    }
    return this.client.getFilePreviewUrl(ref.id);
  }

  getFileArchiveDownloadUrl(refs: Ref<StoredFile>[]): string {
    if (refs.length < 1) {
      throw new Error('Invalid refs');
    }
    const idList = refs.map(ref => ref.id);
    return this.filesClient.getFileArchiveDownloadUrl(idList);
  }

  getFileDownloadUrl$(ref: Ref<StoredFile>, contentDisposition: ContentDisposition = 'attachment'): Observable<string> {
    if (!isValidRef(ref)) {
      throw new Error('Invalid ref');
    }
    return this.client.getFileDownloadUrl$(ref.id, contentDisposition);
  }

  serializeFilter(fileFilter: StoredFileSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(fileFilter);
    const WsFileSearch = StoredFileConverter.convertFilterOut(fileFilter);
    return JSON.stringify(WsFileSearch);
  }


  deserializeFilter(valueString: string | null): StoredFileSearch | null {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: any = JSON.parse(valueString);
      const filter = StoredFileConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsStoredFile>>): Observable<SearchResult<StoredFile>> {
    const rowTasks = results.list.map(ref => this.getFile$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<StoredFile>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<StoredFile>>>{
          list: rows,
        }),
      ));
  }

  private reduceUploadProgressPerFile(cur: FileUploadProgress[], next: FileUploadProgress): FileUploadProgress[] {
    if (cur == null) {
      return [next];
    } else {
      const existingIndex = cur.findIndex(progress => progress.file === next.file);
      if (existingIndex >= 0) {
        cur.splice(existingIndex, 1, next);
        return cur;
      } else {
        return [...cur, next];
      }
    }
  }

  private handleFileUploadError$(error: Error | any, file: File, uploadMethod: FileUploadMethod): Observable<FileUploadProgress> {
    return FileUtils.readFileDataToURI$(file).pipe(
      catchError(e => of(`Reading file errored: ${e}`)),
      map(fileDataUri => this.handleFileUploadError(error, file, uploadMethod, fileDataUri)),
    );
  }

  private handleFileUploadError(error: Error | any, file: File, uploadMethod: FileUploadMethod, fileDataUri: string): FileUploadProgress {
    const diagnosisError = UploadErrorUtils.createDiagnosisUploadError(error, file, uploadMethod, fileDataUri);
    this.errorService.handleError(diagnosisError);

    const erroredFileUploadProgress = UploadProgressUtils.createErroredFileUploadProgress(error, file);
    return erroredFileUploadProgress;
  }
}
