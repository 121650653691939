import {WsTicketContact, WsTicketContactSearch, WsTicketContactType} from '@lifeislife/lifeislife-ws-api';
import {TicketContact} from '../../domain/ticket/ticket-contact';
import {TicketContactSearch} from '../../domain/ticket/ticket-contact-search';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {TicketContactType} from '../../domain/ticket/ticket-contact-type';
import {TicketSearchConverter} from './ticket-search.converter';
import {ContactSearchConverter} from '../contact/contact-search.converter';

export class TicketContactConverter {

  static convertIn(value: WsTicketContact): TicketContact {
    return {
      id: value.id,
      contactRef: value.contactWsRef,
      ticketRef: value.ticketWsRef,
      contactType: EnumConverterUtils.convertValue(value.contactType, TicketContactType),
    };
  }

  static convertOut(value: TicketContact): WsTicketContact {
    return {
      id: value.id,
      ticketWsRef: value.ticketRef,
      contactWsRef: value.contactRef,
      contactType: EnumConverterUtils.convertValue(value.contactType, WsTicketContactType),
    };
  }


  static convertFilterIn(value?: WsTicketContactSearch): TicketContactSearch {
    if (value == null) {
      return undefined;
    }
    return {
      contactSearch: ContactSearchConverter.toContactSearch(value.wsContactSearch),
      ticketSearch: TicketSearchConverter.convertFilterIn(value.wsTicketSearch),
      withChangesSinceLastAcknowledged: value.withChangesSinceLastAcknowledged,
      ticketArchived: value.ticketArchived,
      ticketSilenced: value.ticketSilenced,
      ticketStarred: value.ticketStarred,
    };
  }

  static convertFilterOut(value: TicketContactSearch): WsTicketContactSearch {
    if (value == null) {
      return undefined;
    }
    return {
      wsContactSearch: ContactSearchConverter.toWsContactSearch(value.contactSearch),
      wsTicketSearch: TicketSearchConverter.convertFilterOut(value.ticketSearch),
      withChangesSinceLastAcknowledged: value.withChangesSinceLastAcknowledged,
      ticketArchived: value.ticketArchived,
      ticketSilenced: value.ticketSilenced,
      ticketStarred: value.ticketStarred,
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsTicketContact]: keyof TicketContact } {
    return {
      id: 'id',
      contactWsRef: 'contactRef',
      ticketWsRef: 'ticketRef',
      contactType: 'contactType',
    };
  }

}
