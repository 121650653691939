import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectItem} from 'primeng/api';
import {BehaviorSubject, EMPTY as IknowIUseEmptyObservables, Observable, of, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {
  AuthService,
  AuthState,
  ContactService,
  Country,
  CountryFilter,
  CountryService,
  Customer,
  CustomerService,
  Ref,
  Trustee,
  TrusteeService,
} from '@lifeislife/lifeislife-domain';
import {CountryItemsService} from '../customer-type-items.service';
import {CountrySelectItem} from '../country-select-item';
import {ObjectUtils} from '../../../../commons/util/ObjectUtils';

/**
 * Country selector. ngModel: the country id.
 */
@Component({
  selector: 'app-country-select',
  templateUrl: './countrySelect.html',
  styleUrls: ['./countrySelect.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CountrySelectComponent),
    multi: true,
  }],
})
export class CountrySelectComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @Input()
  disabled = false;
  @Input()
  hasError = false;
  @Input()
  showButton = true;
  @Input()
  noSelectionLabel = 'Aucun';

  @Input()
  set fromTrusteeRef(value: Ref<Trustee>) {
    this.trusteeRefSource$.next(value);
  }

  @Input()
  set fromCustomerRef(value: Ref<Customer>) {
    this.customerRefSource$.next(value);
  }

  @Input()
  allowUnmatches = false;

  private trusteeRefSource$ = new BehaviorSubject<Ref<Trustee>>(null);
  private customerRefSource$ = new BehaviorSubject<Ref<Customer>>(null);
  private countryRefSource$ = new BehaviorSubject<Ref<Country>>(null);

  selectedCountryItem$: Observable<CountrySelectItem>;
  suggestions: CountrySelectItem[];

  private onChangeFunction: any;
  private onTouchedFunction: any;
  private subscription: Subscription;

  constructor(private countryService: CountryService,
              private contactService: ContactService,
              private changeDetector: ChangeDetectorRef,
              private trusteeService: TrusteeService,
              private authService: AuthService,
              private customerService: CustomerService,
              private countryItemsService: CountryItemsService) {
  }

  ngOnInit() {
    this.subscription = new Subscription();

    this.selectedCountryItem$ = this.countryRefSource$.pipe(
        distinctUntilChanged(ObjectUtils.isSameRef),
        switchMap(ref => this.fetchCountry(ref)),
        map(country => this.countryItemsService.createCountryItem(country, {
          codeAsLabel: false,
          noSelectionLabel: this.noSelectionLabel,
        })),
    );

    const trusteeSubscription = this.trusteeRefSource$.pipe(
        switchMap(ref => this.fetchTrusteeCountryRefOrEmpty$(ref)),
    ).subscribe(countryRef => this.setCountryRefIfUnset(countryRef));
    this.subscription.add(trusteeSubscription);


    const customerSubscription = this.customerRefSource$.pipe(
        switchMap(ref => this.fetchCustomerCountryRefOrEmpty$(ref)),
    ).subscribe(countryRef => this.setCountryRefIfUnset(countryRef));
    this.subscription.add(customerSubscription);

    const singleResultsSubscription = this.searchAllCountriesItems().pipe(
        filter(list => list.length === 1),
        map(list => list[0]),
        map(item => item.value),
    ).subscribe(countryRef => this.setCountryRefIfUnset(countryRef));
    this.subscription.add(singleResultsSubscription);

    const authSubscription = this.authService.getState$().pipe(
        switchMap(state => this.fetchAuthCountryRefOrEmpty$(state)),
    ).subscribe(countryRef => this.setCountryRefIfUnset(countryRef));
    this.subscription.add(authSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(ref: Ref<Country>): void {
    this.countryRefSource$.next(ref);
  }

  registerOnChange(fn: any): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFunction = fn;
  }

  onChange(item: SelectItem) {
    const countryRef = item.value;
    // this.countryId$.next(countryId);
    this.fireChanges(countryRef);
  }

  onQuery(queryEvent: { query: string }) {
    const queryLowerCase = queryEvent.query.toLowerCase();
    this.searchAllCountriesItems(queryLowerCase)
      .subscribe(results => this.setSuggestions(results));
  }

  onQueryAll() {
    this.searchAllCountriesItems()
      .subscribe(results => this.setSuggestions(results));
  }

  onClear() {
    this.fireChanges(null);
  }

  private setSuggestions(results) {
    this.suggestions = results;
    this.changeDetector.markForCheck();
  }

  private fetchCountry(ref: Ref<Country>) {
    return ref == null ? of(null) : this.countryService.getCountry$(ref);
  }

  private fireChanges(ref: Ref<Country>) {
    if (this.onTouchedFunction) {
      this.onTouchedFunction();
    }
    if (this.onChangeFunction) {
      this.onChangeFunction(ref);
    }
  }

  private searchAllCountriesItems(query?: string): Observable<CountrySelectItem[]> {
    const countryFilter: CountryFilter = this.countryService.createFilter();
    countryFilter.nameFrContains = query;

    return this.countryService.searchCountries$(countryFilter, null).pipe(
        map(results => this.countryItemsService.createCountryItems(results.list, {
          codeAsLabel: false,
          noSelectionLabel: this.noSelectionLabel,
        })),
    );
  }

  private fetchTrusteeCountryRefOrEmpty$(ref: Ref<Trustee>) {
    if (ref == null) {
      return IknowIUseEmptyObservables;
    }
    return this.trusteeService.getTrustee$(ref).pipe(
        map(trustee => trustee.countryRef),
        filter(countryRef => countryRef != null),
    );
  }


  private fetchCustomerCountryRefOrEmpty$(ref: Ref<Customer>) {
    if (ref == null) {
      return IknowIUseEmptyObservables;
    }
    return this.customerService.getCustomer$(ref).pipe(
        map(customer => customer.countryRef),
        filter(countryRef => countryRef != null),
    );
  }


  private fetchAuthCountryRefOrEmpty$(state: AuthState) {
    if (state == null) {
      return IknowIUseEmptyObservables;
    }
    const contactRef = state.contactRef;
    if (contactRef == null) {
      return IknowIUseEmptyObservables;
    }
    return this.contactService.getContact$(contactRef).pipe(
        map(contact => contact.countryRef),
        filter(countryRef => countryRef != null),
    );
  }

  private setCountryRefIfUnset(countryRef: Ref<Country>) {
    const curCountryRef = this.countryRefSource$.getValue();
    if (curCountryRef == null && countryRef != null) {
      this.countryRefSource$.next(countryRef);
    }
  }
}
