import {Injectable} from '@angular/core';
import {RequestService} from '../../service/request.service';
import {Observable} from 'rxjs';
import {FrontendAppConfigKey} from '../../../domain/config/frontend-app-config-key';
import {AppConfigService} from '../../../service/config/app-config.service';
import {WsInsuranceProvider, WsInsuranceProviderSearch, WsRef} from '@lifeislife/lifeislife-ws-api';
import {map} from 'rxjs/operators';
import {SearchResultFactory} from '../../private_util/searchResultFactory';
import {SimplePaginationUtils} from '../../private_util/simple-pagination-utils';
import {SearchResult} from '../../domain/search/search-result';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {SimplePagination} from '../../../util/pagination/simple-pagination';
import {WsProductProvider} from '@lifeislife/lifeislife-ws-api';


@Injectable({
  providedIn: 'root',
})
export class UnrestictedInsurancePartnersClient {
  private resourcePath = '/unrestricted/partners/';

  constructor(private requestService: RequestService,
              private authProvider: AuthProvider,
              private appConfigService: AppConfigService,
  ) {
  }

  getInsurancePartnersList$(wsSearch: WsInsuranceProviderSearch, pagination: SimplePagination)
    : Observable<SearchResult<WsRef<WsInsuranceProvider>>> {
    const url = this.getResourceUrl() + 'insurance/search';
    const queryParams = SimplePaginationUtils.toQueryParams(pagination);

    return this.requestService.sendRequestFullResponse<WsRef<WsInsuranceProvider>[]>({
      method: 'POST', url: url,
      params: queryParams,
      body: wsSearch,
    }, this.authProvider.getAuth())
      .pipe(
        map((response) => SearchResultFactory.createSearchResult<WsRef<WsInsuranceProvider>>(response)),
      );
  }

  getInsurancetPartner$(ref: WsRef<WsInsuranceProvider>): Observable<WsInsuranceProvider> {
    const url = this.getResourceUrl() + `insurance/${ref.id}`;

    return this.requestService.sendRequest<WsInsuranceProvider>({
      method: 'GET', url: url, throughServiceWorker: true,
    }, this.authProvider.getAuth());
  }

  getPartner$(ref: WsRef<WsProductProvider>): Observable<WsProductProvider> {
    const url = this.getResourceUrl() + `${ref.id}`;

    return this.requestService.sendRequest<WsProductProvider>({
      method: 'GET', url: url, throughServiceWorker: true,
    }, this.authProvider.getAuth());
  }

  getProductProviderLogoUrl$(ref: WsRef<WsProductProvider>, usefallback?: boolean): string {
    const queryParam = usefallback ? '?fallback=true' : '';
    const url = `${this.getResourceUrl()}${ref.id}/logo${queryParam}`;
    return url;
  }

  private getResourceUrl() {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    if (wsUri) {
      return `${wsUri}${this.resourcePath}`;
    }
    throw new Error(`No ws uri in config`);
  }
}
