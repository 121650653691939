import {WsTrusteeContactSearch, WsTrusteeContactType, WsTrusteeContactTypeSearch} from '@lifeislife/lifeislife-ws-api';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {TrusteeContactSearch} from '../../domain/trustee/trustee-contact-search';
import {TrusteeContactTypeSearch} from '../../domain/trustee/trustee-contact-type-search';
import {TrusteeContactType} from '../../domain/trustee/trustee-contact-type';
import {ContactSearchConverter} from '../contact/contact-search.converter';

export class TrusteeContactSearchConverter {

  static convertIn(value?: WsTrusteeContactSearch): TrusteeContactSearch {
    if (value == null) {
      return undefined;
    }
    return {
      trusteeRef: value.trusteeRef,
      contactRef: value.contactRef,
      trusteeContactTypeSearch: this.convertContactTypeSearchIn(value.wsTrusteeContactTypeSearch),
      contactSearch: ContactSearchConverter.toContactSearch(value.wsContactSearch),
    };
  }

  static convertOut(value: TrusteeContactSearch): WsTrusteeContactSearch {
    if (value == null) {
      return undefined;
    }
    return {
      trusteeRef: value.trusteeRef,
      contactRef: value.contactRef,
      wsTrusteeContactTypeSearch: this.convertContactTypeSearchOut(value.trusteeContactTypeSearch),
      wsContactSearch: ContactSearchConverter.toWsContactSearch(value.contactSearch),
    };
  }

  static convertContactTypeSearchIn(value?: WsTrusteeContactTypeSearch): TrusteeContactTypeSearch {
    if (value == null) {
      return undefined;
    }
    return {
      trusteeContactTypes: value.mailContactTypes == null ? [] : value.mailContactTypes.map(
        wsType => EnumConverterUtils.convertValue(wsType, TrusteeContactType),
      ),
      internal: value.internal,
    };
  }

  static convertContactTypeSearchOut(value: TrusteeContactTypeSearch): WsTrusteeContactTypeSearch {
    if (value == null) {
      return undefined;
    }
    return {
      mailContactTypes: value.trusteeContactTypes == null ? [] : value.trusteeContactTypes.map(
        type => EnumConverterUtils.convertValue(type, WsTrusteeContactType),
      ),
      internal: value.internal,
    };
  }


}
