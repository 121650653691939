import {DateTime} from 'luxon';
import {DateTimeSearch} from '../../domain/shared/date-time-search';

export class DateRange {

  from?: Date;
  to?: Date;
  label?: string;
  customRange?: boolean;

  static fromDateRange(other: DateRange): DateRange {
    if (other == null) {
      return {
        from: new Date(),
        customRange: true,
      };
    }
    return {
      from: other.from,
      to: other.to,
      label: other.label,
      customRange: other.customRange,
    };
  }

  static forDateTimeSearch(other: DateTimeSearch): DateRange {
    if (other == null) {
      return null;
    }
    return this.fromDateRange({
      from: other.minDateTime,
      to: other.maxDateTime,
    });
  }

  static forYear(yearValue: number): DateRange {
    if (yearValue == null) {
      return null;
    }
    const from = DateTime.fromJSDate(new Date())
      .set({
        year: yearValue,
      })
      .startOf('year');
    const end = from.plus({year: 1});
    return DateRange.fromDateRange({
      from: from.toJSDate(),
      to: end.toJSDate(),
    });
  }

  static forYearMonth(yearValue: number, monthValue: number): DateRange {
    if (yearValue == null) {
      return null;
    }
    const from = DateTime.fromJSDate(new Date())
      .set({
        year: yearValue,
        month: monthValue,
      })
      .startOf('month');
    const end = from.plus({month: 1});
    return DateRange.fromDateRange({
      from: from.toJSDate(),
      to: end.toJSDate(),
    });
  }

  static forDay(date: Date): DateRange {
    if (date == null) {
      return null;
    }
    const from = DateTime.fromJSDate(date)
      .startOf('day');
    const end = from.plus({day: 1});
    return DateRange.fromDateRange({
      from: from.toJSDate(),
      to: end.toJSDate(),
    });
  }

}
