import {WsTicketInsuranceMandateRequest} from '@lifeislife/lifeislife-ws-api';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {TicketInsuranceMandateRequest} from '../../domain/ticket/ticket-insurance-mandate-request';


export class TicketInsuranceMandateRequetsConverter {

  static convertIn(value: WsTicketInsuranceMandateRequest): TicketInsuranceMandateRequest {
    return {
      id: value.id,
      version: value.version,
      creationDateTime: DateConverterUtils.parseIsoDateTime(value.creationDateTime),
      contactSubscriberRef: value.contactSubscriberWsRef,
      customerSubscriber: value.customerSubscriber,
      insuranceCategoryRef: value.insuranceCategoryWsRef,
      insuranceProviderRef: value.insuranceProviderWsRef,
      nextAnniversaryDate: DateConverterUtils.parseIsoDate(value.nextAnniversaryDate),
      providerCategoryName: value.providerCategoryName,
      providerReference: value.providerReference,
      ticketRef: value.ticketWsRef,
      subscriptionRef: value.subscriptionWsRef,
    };
  }

  static convertOut(value: TicketInsuranceMandateRequest): WsTicketInsuranceMandateRequest {
    return {
      id: value.id,
      version: value.version,
      creationDateTime: DateConverterUtils.formatDateToIsoDateTime(value.creationDateTime),
      contactSubscriberWsRef: value.contactSubscriberRef,
      customerSubscriber: value.customerSubscriber,
      insuranceCategoryWsRef: value.insuranceCategoryRef,
      insuranceProviderWsRef: value.insuranceProviderRef,
      nextAnniversaryDate: DateConverterUtils.formatDateToIsoDate(value.nextAnniversaryDate),
      providerCategoryName: value.providerCategoryName,
      providerReference: value.providerReference,
      ticketWsRef: value.ticketRef,
      subscriptionWsRef: value.subscriptionRef,
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsTicketInsuranceMandateRequest]: keyof TicketInsuranceMandateRequest } {
    return {
      id: 'id',
      version: 'version',
      creationDateTime: 'creationDateTime',
      ticketWsRef: 'ticketRef',
      insuranceCategoryWsRef: 'insuranceCategoryRef',
      insuranceProviderWsRef: 'insuranceProviderRef',
      contactSubscriberWsRef: 'contactSubscriberRef',
      providerReference: 'providerReference',
      providerCategoryName: 'providerCategoryName',
      nextAnniversaryDate: 'nextAnniversaryDate',
      customerSubscriber: 'customerSubscriber',
      subscriptionWsRef: 'subscriptionRef',
    };
  }

}
