import {Injectable} from '@angular/core';
import {RequestService} from '../../service/request.service';
import {LifeisLifeWsAuth} from '../../domain/auth/lifeis-life-ws-auth';
import {Observable} from 'rxjs';
import {WsContact, WsDaoEventEntityType, WsLifeIsLifeAdmin, WsLifeIsLifeUser, WsRef} from '@lifeislife/lifeislife-ws-api';
import {CachedWsResourceClient} from '../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {AppConfigService} from '../../../service/config/app-config.service';
import {WsInviteTokenAuth} from '../../domain/auth/ws-invite-token-auth';
import {ResourceCacheService} from '../../../util/cache/resource-cache.service';

@Injectable({
  providedIn: 'root',
})
export class LifeislifeAdminWsClient extends CachedWsResourceClient<WsLifeIsLifeAdmin, WsRef<WsLifeIsLifeAdmin>> {

  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private injectedAppConfigService: AppConfigService) {
    super('/front/lifeislife', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.LIFEISLIFE_ADMIN, injectectedCacheService);
  }

  create$(auth: WsInviteTokenAuth, user: WsLifeIsLifeUser): Observable<WsLifeIsLifeAdmin> {
    const url = `${this.getResourceUrl()}/invite/${auth.token}`;
    return this.requestService.sendRequest<WsLifeIsLifeAdmin>({
      method: 'POST', url: url, body: user,
    }, auth);
  }

  getLoggedLifeislifeAdmin$(auth: LifeisLifeWsAuth): Observable<WsLifeIsLifeAdmin> {
    const url = `${this.getResourceUrl()}/me`;

    return this.requestService.sendRequest<WsLifeIsLifeAdmin>({
      method: 'GET', url: url,
    }, auth);
  }

  getAdminRefForContactRef$(contactRef: WsRef<WsContact>): Observable<WsRef<WsLifeIsLifeAdmin>> {
    const url = `${this.getResourceUrl()}/contact/${contactRef.id}`;

    return this.requestService.sendRequest<WsRef<WsLifeIsLifeAdmin>>({
      method: 'GET', url: url,
    }, this.injectedAuthService.getAuth());
  }


}
