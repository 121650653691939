import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsCustomerBankAccount, WsRef} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {Trustee} from '../../domain/trustee/trustee';
import {Ref} from '../../domain/shared/ref';
import {HttpParams} from '@angular/common/http';
import {CustomerBankAccountConverter} from './customer-bank-account.converter';
import {CustomerBankAccount} from '../../domain/customer-bank-account/customer-bank-account';
import {CustomerBankAccountWsClient} from '../../client/resources/front/bankaccount/customer-bank-account-ws-client';
import {CustomerBankAccountSearch} from '../../domain/customer-bank-account/customer-bank-account-search';

@Injectable({
  providedIn: 'root',
})
export class CustomerBankAccountService {


  constructor(private client: CustomerBankAccountWsClient,
  ) {
  }

  getCustomerBankAccount$(ref: Ref<CustomerBankAccount>, forceFetch?: boolean): Observable<CustomerBankAccount> {
    return ref == null ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsCustomerBankAccount => CustomerBankAccountConverter.convertIn(wsCustomerBankAccount)),
    );
  }

  searchCustomerBankAccounts$(customerBankAccountSearch: CustomerBankAccountSearch, pagination: SimplePagination)
    : Observable<SearchResult<CustomerBankAccount>> {
    const wsSearch = CustomerBankAccountConverter.convertFilterOut(customerBankAccountSearch);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateCustomerBankAccount$(customerBankAccount: CustomerBankAccount): Observable<ValidationResult<CustomerBankAccount>> {
    const wsCustomerBankAccount = CustomerBankAccountConverter.convertOut(customerBankAccount);
    return this.client.doValidate(wsCustomerBankAccount).pipe(
      map((results: WsCustomerBankAccount | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveCustomerBankAccount$(customerBankAccount: CustomerBankAccount): Observable<Ref<CustomerBankAccount>> {
    const wsCustomerBankAccount = CustomerBankAccountConverter.convertOut(customerBankAccount);
    return this.client.doSave(wsCustomerBankAccount);
  }

  deleteCustomerBankAccount$(id: number, force?: boolean): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        force: force ? 'true' : 'false',
      },
    });
    return this.client.doRemove(id, params);
  }

  serializeFilter(customerBankAccountSearch: CustomerBankAccountSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(customerBankAccountSearch);
    const wsCustomerBankAccountSearch = CustomerBankAccountConverter.convertFilterOut(customerBankAccountSearch);
    return JSON.stringify(wsCustomerBankAccountSearch);
  }

  deserializeFilter(valueString: string | null): CustomerBankAccountSearch | null {
    if (valueString == null) {
      return null;
    }
    const wsSearch: any = JSON.parse(valueString);
    return CustomerBankAccountConverter.convertFilterIn(wsSearch);
  }

  createFilter(trusteeRef?: Ref<Trustee>): CustomerBankAccountSearch {
    return {
      customerSearch: {
        trusteeSearch: {
          exactTrusteeRef: trusteeRef,
        },
      },
    };
  }

  createNewCustomerBankAccount(customerId?: number): CustomerBankAccount {
    return {
      id: undefined,
      version: undefined,
      customerRef: customerId ? {id: customerId} : undefined,
      bic: undefined,
      iban: undefined,
      privateAccount: false,
    };
  }

  private createValidationResult(results: WsCustomerBankAccount | WsConstraintViolation[]): ValidationResult<CustomerBankAccount> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsCustomerBankAccount => CustomerBankAccountConverter.convertIn(wsCustomerBankAccount),
      propertyNameMappings: CustomerBankAccountConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsCustomerBankAccount>>): Observable<SearchResult<CustomerBankAccount>> {
    const rowTasks = results.list.map(ref => this.getCustomerBankAccount$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<CustomerBankAccount>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<CustomerBankAccount>>>{
          list: rows,
        }),
      ));
  }


}
