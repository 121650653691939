import {ErrorHandler, Injectable} from '@angular/core';
import {WsRef, WsSigningPackage} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {ObjectConverterUtil} from '../object-converter-util';
import {SearchResult} from '../../client/domain/search/search-result';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SigningPackageWsClient} from '../../client/resources/front/file/signing-package-ws-client';
import {SigningPackage} from '../../domain/file/signing-package';
import {SigningPackageSearch} from '../../domain/file/signing-package-search';
import {SigningPackageConverter} from './signing-package.converter';
import {Contact} from '../../domain/contact/contact';
import {SigningPackageFileWsClient} from '../../client/resources/front/file/signing-package-file-ws-client';
import {UnrestictedSigningClient} from '../../client/resources/unrestricted/unresticted-signing-client';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {SigningPackageStatus} from '../../domain/file/signing-package-status';
import {StoredFileWsClient} from '../../client/resources/front/file/stored-file-ws-client';

@Injectable({
  providedIn: 'root',
})
export class SigningPackageService {


  constructor(private client: SigningPackageWsClient,
              private fileClient: SigningPackageFileWsClient,
              private storedFileClient: StoredFileWsClient,
              private unrestrictedSigningClient: UnrestictedSigningClient,
              private errorService: ErrorHandler,
  ) {
  }

  anonymousRefreshPackageStatus(packageId: number): Observable<SigningPackageStatus | null> {
    return this.unrestrictedSigningClient.refreshSisgningPackage$(packageId).pipe(
      map(s => EnumConverterUtils.convertValue(s, SigningPackageStatus)),
      catchError(e => {
        this.errorService.handleError(e);
        return of(null);
      }),
    );
  }

  clearSigningPackageCache(ref: Ref<SigningPackage>) {
    this.client.doClearCache(ref.id);
    this.fileClient.doClearWholeCache();
    this.storedFileClient.doClearWholeCache();
  }

  getSigningPackage$(ref: Ref<SigningPackage>, skpicache?: boolean): Observable<SigningPackage> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doGet(ref.id, skpicache).pipe(
      map(value => SigningPackageConverter.convertIn(value)),
    );
  }

  searchSigningPackages$(signingPackageFilter: SigningPackageSearch, pagination: SimplePagination)
    : Observable<SearchResult<SigningPackage>> {
    const wsSearch = SigningPackageConverter.convertFilterOut(signingPackageFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  saveSigningPackage$(signingPackage: SigningPackage): Observable<any> {
    const wsSigningPackage = SigningPackageConverter.convertOut(signingPackage);
    return this.client.doSave(wsSigningPackage);
  }

  deleteSigningPackage$(ref: Ref<SigningPackage>, keepFiles?: boolean): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.deleteSigningPackage$(ref.id, keepFiles);
  }

  serializeFilter(signingPackageFilter: SigningPackageSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(signingPackageFilter);
    const WsSigningPackageSearch = SigningPackageConverter.convertFilterOut(signingPackageFilter);
    return JSON.stringify(WsSigningPackageSearch);
  }


  deserializeFilter(valueString: string | null): SigningPackageSearch {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: any = JSON.parse(valueString);
      const filter = SigningPackageConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  getSignerSignUrl(signingPackage: Ref<SigningPackage>, contact: Ref<Contact>) {
    if (signingPackage == null || contact == null) {
      return null;
    }
    return this.client.getSignerSignUrl(signingPackage.id, contact.id);
  }

  updateStatusFromProvider(packageRef: Ref<SigningPackage>): Observable<SigningPackage> {
    this.clearSigningPackageCache(packageRef);
    return this.client.updateStatusFromProvider$(packageRef.id).pipe(
      switchMap(() => this.getSigningPackage$(packageRef, true)),
    );
  }

  rejectPackage$(packageRef: Ref<SigningPackage>): Observable<SigningPackage> {
    return this.client.rejectSigningPackage$(packageRef.id).pipe(
      switchMap(() => this.getSigningPackage$(packageRef, true)),
    );
  }

  resendInvitationMails$(packageRef: Ref<SigningPackage>): Observable<SigningPackage> {
    return this.client.resendSigningPackageInvitationMail$(packageRef.id).pipe(
      switchMap(() => this.getSigningPackage$(packageRef, true)),
    );
  }

  getStatusLabel(status: SigningPackageStatus | null): string {
    if (status == null) {
      return null;
    }
    switch (status) {
      case SigningPackageStatus.DRAFT:
        return `Brouillon`;
      case SigningPackageStatus.WAIITING_SIGNATURE:
        return `En attente`;
      case SigningPackageStatus.COMPLETED:
        return `Completé`;
      case SigningPackageStatus.REJECTED:
        return `Rejeté`;
      case SigningPackageStatus.ERROR:
        return `Erreur`;
      default:
        return `${status}`;
    }
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsSigningPackage>>): Observable<SearchResult<SigningPackage>> {
    const rowTasks = results.list.map(ref => this.getSigningPackage$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<SigningPackage>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<SigningPackage>>>{
          list: rows,
        }),
      ));
  }

}
