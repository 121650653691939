import {WsInvestmentProvider, WsInvestmentProviderSearch} from '@lifeislife/lifeislife-ws-api';
import {InvestmentProvider} from '../../../domain/corebusiness/investment/investment-provider';
import {InvestmentProviderSearch} from '../../../domain/corebusiness/investment/investment-provider-search';
import {ProductProviderConverter} from '../product-provider-converter';

export class InvestmentProviderConverter {

  static convertIn(value: WsInvestmentProvider): InvestmentProvider {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      productProviderRef: value.productProviderWsRef,
    };
  }

  static convertOut(value: InvestmentProvider): WsInvestmentProvider {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      productProviderWsRef: value.productProviderRef,
    };
  }


  static convertFilterIn(value?: WsInvestmentProviderSearch): InvestmentProviderSearch {
    if (value == null) {
      return undefined;
    }
    return {
      productProviderSearch: ProductProviderConverter.convertFilterIn(value.wsProductProviderSearch),
    };
  }

  static convertFilterOut(value: InvestmentProviderSearch): WsInvestmentProviderSearch {
    if (value == null) {
      return undefined;
    }
    return {
      wsProductProviderSearch: ProductProviderConverter.convertFilterOut(value.productProviderSearch),
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsInvestmentProvider]: keyof InvestmentProvider } {
    return {
      id: 'id',
      productProviderWsRef: 'productProviderRef',
    };
  }

}
