import {Component, Input, OnInit} from '@angular/core';
import {Country, CountryService, Ref} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
})
export class CountryComponent implements OnInit {

  @Input()
  set ref(value: Ref<Country>) {
    this.refSource$.next(value);
  }

  private refSource$ = new BehaviorSubject<Ref<Country>>(null);
  label$: Observable<string>;

  constructor(
    private countryService: CountryService,
  ) {
  }

  ngOnInit() {
    const title$ = this.refSource$.pipe(
      switchMap(ref => this.fetchRef$(ref)),
      publishReplay(1), refCount(),
    );
    this.label$ = title$.pipe(
      map(title => title == null ? null : title.nameFr),
      publishReplay(1), refCount(),
    );
  }

  private fetchRef$(ref: Ref<Country>) {
    if (ref == null) {
      return of(null);
    }
    return this.countryService.getCountry$(ref);
  }

}
