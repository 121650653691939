import {Observable} from 'rxjs';


/**
 * This services requires events to be passed in from the components.
 * In your app compnent, listen for beforeinstallprompt and forward the event to this service:
 *
 * ```
 @HostListener('window:beforeinstallprompt', ['$event'])
 onBeforeInstallPrompt(event: Event) {
    this.appInstallService.onBeforeInstallPromt(event);
  }

 @HostListener('window:appInstalled', ['$event'])
 onAppInstalled(event: Event) {
    this.appInstallService.onAppInstalled(event);
  }
 ```
 */
export abstract class AppHomeScreenInstallerService {

  abstract canInstall$: Observable<boolean>;

  abstract canInstallOnHomeScreen(): boolean;

  abstract isLaunchedFromHomeScreen(): boolean ;

  abstract discardAppInstall() ;

  abstract hadDiscardedAppInstall();

  abstract askInstallToHomeScreen() ;

  abstract onBeforeInstallPromt(event: Event);

  abstract onAppInstalled(e: Event) ;

}
