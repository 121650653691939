import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PersonTitleComponent} from './person-title/person-title.component';
import {PersonTitleSelectComponent} from './person-title-select/person-title-select.component';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    PersonTitleComponent,
    PersonTitleSelectComponent,
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
  ],
  exports: [
    PersonTitleComponent,
    PersonTitleSelectComponent,
  ],
})
export class PersonTitleModule {
}
