import {CustomerFrontCustomerSourcing} from '../../domain/customer/customer-front-customer-sourcing';
import {WsCustomerFrontCustomerSourcing, WsInsuranceCategoryFamily} from '@lifeislife/lifeislife-ws-api';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';

export class CustomerFrontCustomerSourcingConverter {

  static convertOut(customerFrontCustomerSource: CustomerFrontCustomerSourcing): WsCustomerFrontCustomerSourcing {
    return {
      contactWsRef: customerFrontCustomerSource.contactRef,
      customerWsRef: customerFrontCustomerSource.customerRef,
      family: EnumConverterUtils.convertValue(customerFrontCustomerSource.family, WsInsuranceCategoryFamily),
    };
  }
}
