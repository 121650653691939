import {Injectable} from '@angular/core';
import {Contact, ContactService} from '@lifeislife/lifeislife-domain';
import {ContactSelectItem} from './contact-select-item';

@Injectable({
  providedIn: 'root',
})
export class ContactItemsService {

  constructor(private contactService: ContactService) {
  }

  createContactItems(contacts: Contact[], options: {
    includeNoSelectionItem?: boolean,
    noSelectionItemLabel?: string,
    showCompanyName?: boolean
  }): ContactSelectItem[] {
    let items = contacts.map(type => this.createContactItem(type, options));
    if (options.includeNoSelectionItem) {
      const noSelectionItem = this.createContactItem(null, options);
      items = [noSelectionItem, ...items];
    }
    return items;
  }

  createContactItem(contact: Contact | null, options: {
    noSelectionItemLabel?: string,
    showCompanyName?: boolean
  }): ContactSelectItem {
    return {
      value: contact == null ? null : {id: contact.id},
      label: contact == null ? options.noSelectionItemLabel : this.getContactLabel(contact, options.showCompanyName),
      contact: contact,
    };
  }

  private getContactLabel(contact: Contact, showCompanyName?: boolean) {
    if (showCompanyName) {
      return contact.company;
    } else {
      return this.contactService.getContactName(contact);
    }
  }
}
