import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormHelper} from '../../main/services/form-helper';
import {
  AuthState,
  Contact,
  ContactService,
  CustomerContactService,
  CustomerInviteCode, DateUtils,
  InviteAuthService,
  LifeIsLifeAdminService,
  Ref, Role,
  TrusteeContactService,
  TrusteeInviteCode,
  TrusteeService,
  User,
  UserService,
  WsInviteTokenAuth,
} from '@lifeislife/lifeislife-domain';
import {combineLatest, forkJoin, Observable, of, Subscription, throwError} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {map, publishReplay, refCount, switchMap, take} from 'rxjs/operators';
import {NotificationMessageService} from '../../commons/services/notificationMessage.service';

@Component({
  selector: 'app-account-creation-route',
  templateUrl: './account-creation-route.component.html',
  styleUrls: ['./account-creation-route.component.scss'],
})
export class AccountCreationRouteComponent implements OnInit, OnDestroy {

  userFormHelper: FormHelper<User>;
  contactFormHelper: FormHelper<Contact>;

  inviteCodeExpired: boolean;
  redirectUrlParam$: Observable<string>;

  inviteCode: CustomerInviteCode | TrusteeInviteCode;
  inviteToken$: Observable<string>;
  password: string;

  private subscrption: Subscription;

  constructor(
    private inviteAuthService: InviteAuthService,
    private userService: UserService,
    private contactService: ContactService,
    private trusteeContactService: TrusteeContactService,
    private customerContactService: CustomerContactService,
    private trusteeService: TrusteeService,
    private lifeislifeAdminService: LifeIsLifeAdminService,
    private notificationMessageService: NotificationMessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.userFormHelper = new FormHelper<User>(
      ref => this.userService.getUser$(ref, true),
      value => this.userService.saveUser$(value),
      value => this.userService.validateUser$(value),
    );
    this.contactFormHelper = new FormHelper<Contact>(
      ref => this.contactService.getContact$(ref, true),
      value => this.contactService.saveContact$(value),
      value => this.contactService.validateContact$(value),
    );
  }

  ngOnInit() {
    this.subscrption = new Subscription();

    this.inviteToken$ = this.inviteAuthService.state$.pipe(
      map(s => s.wsAuth as WsInviteTokenAuth),
      map(s => s == null ? null : s.token),
      publishReplay(1), refCount(),
    );

    this.redirectUrlParam$ = this.activatedRoute.params.pipe(
      map(params => params.redirect),
      publishReplay(1), refCount(),
    );

    const inviteContact$ = this.inviteAuthService.state$.pipe(
      map(s => s.contactRef),
      switchMap(ref => ref == null ? of(null) : this.contactService.getContact$(ref)),
      publishReplay(1), refCount(),
    );

    const inviteCode$ = this.inviteAuthService.state$.pipe(
      map(s => s.status === 'authenticated' ? this.inviteAuthService.getInviteAuth() : null),
      switchMap(wsAuth => this.inviteAuthService.findInviteCode$(wsAuth, Role.TRUSTEE)),
      publishReplay(1), refCount(),
    );

    const codeSubscription = combineLatest(
      inviteCode$,
      this.inviteAuthService.state$,
      inviteContact$,
    ).subscribe(r => this.initForms(r[0] as TrusteeInviteCode, r[1], r[2]));
    this.subscrption.add(codeSubscription);
  }

  ngOnDestroy(): void {
    this.subscrption.unsubscribe();
  }

  onSubmit() {
    if (this.inviteCode == null) {
      return;
    }
    forkJoin(
      this.userFormHelper.editingValue$.pipe(take(1)),
      this.contactFormHelper.editingValue$.pipe(take(1)),
    ).pipe(
      switchMap(r => this.createUser$(r[0], r[1], this.inviteCode)),
    ).subscribe(
      (contactRef) => this.onSaveSuccess(contactRef),
      error => this.onSaveError(error),
    );

  }

  private initForms(code: TrusteeInviteCode | null, state: AuthState, contact: Contact) {
    this.inviteCode = code;

    if (code != null && state != null) {
      this.inviteCodeExpired = DateUtils.isDistinctSortedDays(code.expirationTime, new Date());
      this.contactFormHelper.initFromRef(contact);
      this.userFormHelper.initFromRef(contact.userRef);
    } else {
      this.inviteCodeExpired = false;
      this.userFormHelper.clearValue();
      this.contactFormHelper.clearValue();
    }
  }

  private onSaveSuccess(contactRef: Ref<Contact>) {
    this.notificationMessageService.addInfo(`Enregistré`);

    this.redirectUrlParam$.pipe(
      take(1),
    ).subscribe(redirect => {
      if (redirect) {
        this.router.navigateByUrl(redirect);
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  private onSaveError(error: any) {
    this.notificationMessageService.addError(`Erreur lors de l'enregistrement`, error);
  }

  private fetchCodeContact$(code: TrusteeInviteCode | CustomerInviteCode): Observable<Contact> {
    if (code == null) {
      return of(null);
    }
    let contactRef$: Observable<Ref<Contact>>;
    if (this.isTrusteeInvite(code)) {
      contactRef$ = this.getInviteTrusteeContact$(code).pipe(
        map(trusteeContact => trusteeContact.contactRef),
      );
    } else if (this.isCustomerInvite(code)) {
      contactRef$ = this.customerContactService.getCustomerContact$(code['customerContactRef']).pipe(
        map(customerContact => customerContact.contactRef),
      );
    } else {
      return throwError(new Error(`Unhandled invite code type`));
    }
    return contactRef$.pipe(
      switchMap(ref => ref == null ? of(null) : this.contactService.getContact$(ref)),
    );
  }

  getInviteTrusteeContact$(code) {
    return this.trusteeContactService.getTrusteeContact$(code['trusteeContactRef']);
  }

  isCustomerInvite(code) {
    return code['customerContactRef'] != null;
  }

  isTrusteeInvite(code) {
    return code['trusteeContactRef'] != null;
  }

  private createUser$(user: User, contact: Contact, inviteCode: CustomerInviteCode | TrusteeInviteCode): Observable<Ref<Contact>> {
    const user$ = this.userService.saveUser$(user);
    const password$ = this.userService.setLoggedUserPassword$(this.password);
    const contact$ = forkJoin(user$, password$).pipe(
      map(r => Object.assign({}, contact, <Partial<Contact>>{
        userRef: r[0],
      })),
      switchMap(newContact => this.contactService.saveContact$(newContact)),
    );
    return contact$;
    // TODO create trustee/customer/accountant
  }
}
