import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InsuranceProviderSelectComponent} from './insurance-provider-select/insurance-provider-select.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FormsModule} from '@angular/forms';
import {InsuranceCategorySelectComponent} from './insurance-category-select/insurance-category-select.component';
import {InsuranceCategoryComponent} from './insurance-category/insurance-category.component';
import {InsuranceCategoryFamilyComponent} from './insurance-category-family/insurance-category-family.component';
import {InsuranceTypeComponent} from './insurance-type/insurance-type.component';


@NgModule({
  declarations: [InsuranceProviderSelectComponent, InsuranceCategorySelectComponent,
    InsuranceCategoryComponent, InsuranceCategoryFamilyComponent, InsuranceTypeComponent],
  imports: [
    CommonModule,
    AutoCompleteModule,
    FormsModule,
  ],
  exports: [InsuranceProviderSelectComponent, InsuranceCategorySelectComponent,
    InsuranceCategoryComponent, InsuranceCategoryFamilyComponent, InsuranceTypeComponent],
})
export class InsuranceComponentsModule {
}
