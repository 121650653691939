import {ApplicationMenuDef} from '../main/routing/application-menu-def';
import {AppFeature} from '../main/model/app-feature';
import {CustomerSearch} from '@lifeislife/lifeislife-domain';

export const AllCustomerFilterParam: string = CustomerSearch.serializeFilter({});
export const ActiveCustomerFilterParam: string = CustomerSearch.serializeFilter({
  customerStatusSearch: {
    customerActive: true,
  },
  withActiveSubscriptions: true,
});

export const CustomerMenuData: any & ApplicationMenuDef = {
  moduleShell: {
    title: '',
    skipMenu: true,
  },
  list: {
    title: `Liste des clients`,
    menuLink: ['/customer/list'],
    allAppFeatures: [AppFeature.customer_list],
  },
  allList: {
    title: `Tous les clients`,
    menuLink: ['/customer/list', {filter: AllCustomerFilterParam}],
    allAppFeatures: [AppFeature.customer_list],
  },
  activesList: {
    title: `Clients actifs`,
    menuLink: ['/customer/list', {filter: ActiveCustomerFilterParam}],
    allAppFeatures: [AppFeature.customer_list],
  },
  new: {
    title: 'Nouveau client',
    menuLink: ['/customer/new'],
    allAppFeatures: [AppFeature.customer_create],
  },
  details: {
    title: 'Détails',
    id: 'customer-details',
    skipMenu: true,

    children: {
      info: {
        title: 'Informations',
        id: 'customer-info',
        menuIcon: 'fa fa-info',
        relativeMenuLink: ['info'],
      },
      contact: {
        title: 'Contacts',
        id: 'customer-contacts',
        menuIcon: 'fa fa-user',
        relativeMenuLink: ['contact'],
        allAppFeatures: [AppFeature.customer_contact_list],
      },
      insuranceSubscriptions: {
        title: `Polices d'assurance`,
        id: 'customer-insurance-subscriptions',
        menuIcon: 'fa fa-life-ring',
        relativeMenuLink: ['insuranceSubscription'],
        allAppFeatures: [AppFeature.insurance_subscription_list],
      },
      creditSubscriptions: {
        title: `Contrats de crédit`,
        id: 'customer-credit-subscriptions',
        menuIcon: 'fa fa-credit-card',
        relativeMenuLink: ['creditSubscription'],
        allAppFeatures: [AppFeature.credit_subscription_list],
      },
      investmentSubscriptions: {
        title: `Contrats d'investissement`,
        id: 'customer-investments-subscriptions',
        menuIcon: 'fa fa-bank',
        relativeMenuLink: ['investmentSubscription'],
        allAppFeatures: [AppFeature.investment_subscription_list],
      },
      customerBankAccount: {
        title: `Comptes bancaires`,
        id: 'customer-bank-accounts',
        menuIcon: 'fa fa-credit-card',
        relativeMenuLink: ['customerBankAccount'],
        allAppFeatures: [AppFeature.customer_bank_account_list],
      },
      signingPackage: {
        title: `Liasses à signer`,
        id: 'customer-signing-package',
        menuIcon: 'fa fa-certificate',
        relativeMenuLink: ['signingPackage'],
        allAppFeatures: [AppFeature.signing_packages_list],
      },
    },
  },
};
