import {FileUploadMethod} from './file-upload-method';

export class UploadErrorUtils {

  static createDiagnosisUploadError(error: any, file: File, method: FileUploadMethod, fileDataURI: string): Error {
    // Extra diagnosis for the empty file bug
    const errorMessage = error.message;
    let errorJson;
    try {
      errorJson = JSON.stringify(error);
    } catch (e) {
      errorJson = '<Could not serialize error to JSON>';
    }
    const fileName = file.name;
    const fileSize = file.size;
    const fileType = file.type;

    let newErrorMessage = `FILE UPLOAD ERROR:`
      + `\n- Message: ${errorMessage}`
      + `\n- Json: ${errorJson}`
      + `\n- Upload method: ${method}`
      + `\n- File name: ${fileName}`
      + `\n- File size: ${fileSize}`
      + `\n- File type: ${fileType}`;
    if (fileSize && fileSize < 1024 * 4) {
      newErrorMessage += `\n- File data URL: ${fileDataURI}`;
    } else {
      newErrorMessage += '\n- File was read flawlessly, but too big to be sent in this message';
    }


    const newError = new Error('FILE UPLOAD ERROR');
    newError['uploadErrorDetails'] = newErrorMessage;
    return newError;
  }
}
