import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {User, PropertyDiff, ValidationResult} from '@lifeislife/lifeislife-domain';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: UserFormComponent,
    multi: true,
  }],
})
export class UserFormComponent implements ControlValueAccessor {

  @Input()
  userSelectVisible = true;
  @Input()
  disabled: boolean;
  @Input()
  validationResults: ValidationResult<User>;
  @Input()
  propertyDiff: PropertyDiff<User>;
  @Input()
  showPassword: boolean;
  @Input()
  showArchived: boolean;
  @Input()
  password: string;

  @Output()
  passwordChange = new EventEmitter<string>();

  user: User;

  password1: string;
  password2: string;
  passwordMismatch: boolean;

  private onChangeFunction: Function;
  private onTouchedFunction: Function;

  constructor() {
  }

  writeValue(val: User) {
    this.user = val == null ? <User>{} : val;
  }

  registerOnChange(fn: any) {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedFunction = fn;
  }

  fireChanges() {
    // Cleanup some values that may cause validation to fail
    if (this.user.login != null && this.user.login.length === 0) {
      this.user.login = null;
    }
    this.onTouchedFunction();
    this.onChangeFunction(this.user);
  }

  onPasswordsChange() {
    this.passwordMismatch = this.password1 !== this.password2;
    if (!this.passwordMismatch) {
      this.passwordChange.next(this.password1);
    }
  }
}
