import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Ref, SimplePaginationUtils, Trustee, TrusteeSearch, TrusteeService} from '@lifeislife/lifeislife-domain';
import {SelectItem} from 'primeng/api';
import {map} from 'rxjs/operators';

@Component({
  selector: 'llc-trustee-dropdown-select',
  templateUrl: './trustee-dropdown-select.component.html',
  styleUrls: ['./trustee-dropdown-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TrusteeDropdownSelectComponent,
    multi: true,
  }],
})
export class TrusteeDropdownSelectComponent implements OnInit, ControlValueAccessor {

  @Input()
  disabled: boolean;
  @Input()
  required: boolean;
  @Input()
  forceSelection: boolean;
  @Input()
  placeHolder = 'Votre fiduciaire...';

  @Input()
  set trusteeRef(value: Ref<Trustee>) {
    this.loadTrustee(value);
  }

  @Output()
  trusteeRefChange = new EventEmitter<Ref<Trustee> | null>();

  nameValue: string;

  suggestions: SelectItem[];
  suggestionsPagination = SimplePaginationUtils.newPagination(20);

  private onChange: any;
  private onTouched: any;

  constructor(
    private trusteeService: TrusteeService,
  ) {
  }

  ngOnInit(): void {
  }

  writeValue(obj: any): void {
    this.nameValue = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  onNameValueChange(value: string) {
    this.nameValue = value;
    this.fireChanges();
  }

  onDropDownClick() {
    this.searchSuggestions(null);
  }

  onComplete(query: string) {
    this.searchSuggestions(query);
  }

  onItemSelect(value: SelectItem) {
    const trustee = value.value;
    this.nameValue = trustee.name;
    this.fireChanges(trustee);
  }

  private fireChanges(trustee?: Trustee) {
    if (this.onTouched) {
      this.onTouched();
    }
    if (this.onChange) {
      this.onChange(this.nameValue);
    }
    if (trustee) {
      this.trusteeRefChange.next({id: trustee.id});
    } else {
      this.trusteeRefChange.next(null);
    }
  }

  private searchSuggestions(query) {
    const trusteeSearch: TrusteeSearch = {
      nameContains: query,
    };
    this.trusteeService.publicTrusteeSearch$(trusteeSearch, this.suggestionsPagination).pipe(
      map(r => r.list),
      map(list => this.createItems(list)),
    ).subscribe(items => this.suggestions = items);
  }

  private createItems(list: Partial<Trustee>[]) {
    return list.map(trustee => {
      return {
        label: `${trustee.name}`,
        value: trustee,
      } as SelectItem;
    });
  }

  private loadTrustee(value: Ref<Trustee>) {
    if (value == null) {
      this.writeValue(null);
      return;
    }
    this.trusteeService.getPublicTrusteeView$(value)
      .subscribe(t => this.writeValue(t.name));
  }

}
