import {Injectable} from '@angular/core';
import {RequestService} from '../../../service/request.service';
import {
  WsInsuranceSubscriptionStatus,
  WsInsuranceSubscriptionStatusLabel,
  WsInsuranceSubscriptionStatusSearch,
} from '@lifeislife/lifeislife-ws-api';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {WsStringKeyResourceCache} from '../../../private_util/wsStringKeyResourceCache';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {FrontendAppConfigKey} from '../../../../domain/config/frontend-app-config-key';

@Injectable({
  providedIn: 'root',
})
export class InsuranceSubscriptionStatusWsClient {

  private labelCache: WsStringKeyResourceCache<WsInsuranceSubscriptionStatusLabel>;

  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private appConfigService: AppConfigService) {
    this.labelCache = new WsStringKeyResourceCache<WsInsuranceSubscriptionStatusLabel>(
      statusLabel => statusLabel.subscriptionStatus,
    );
  }

  getPublicLabel$(status: WsInsuranceSubscriptionStatus): Observable<string> {
    return this.getLabel$(status).pipe(
      map(l => l.publicLabel),
    );
  }

  getInternalLabel$(status: WsInsuranceSubscriptionStatus): Observable<string> {
    return this.getLabel$(status).pipe(
      map(l => l.internalLabel),
    );
  }

  searchStatusList$(wsStatusSearch: WsInsuranceSubscriptionStatusSearch): Observable<WsInsuranceSubscriptionStatus[]> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/front/insuranceSubscriptionStatus/search`;
    return this.injectedRequestService.sendRequest<WsInsuranceSubscriptionStatus[]>({
      method: 'POST', url: url, body: wsStatusSearch,
    }, this.injectedAuthService.getAuth());
  }

  private getLabel$(status: WsInsuranceSubscriptionStatus, bypassSw?: boolean): Observable<WsInsuranceSubscriptionStatusLabel> {
    const cachedLabels = this.labelCache.getFromCache(status as string);
    if (cachedLabels !== undefined) {
      return of(cachedLabels);
    } else {
      return this.fetchtLabel$(status, true).pipe(
        tap(l => this.labelCache.putInCache(l, status as string)),
      );
    }
  }

  private fetchtLabel$(status: WsInsuranceSubscriptionStatus, bypassSw?: boolean): Observable<WsInsuranceSubscriptionStatusLabel> {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const url = `${wsUri}/front/insuranceSubscriptionStatus/${status}/labels`;
    return this.injectedRequestService.sendRequest<WsInsuranceSubscriptionStatusLabel>({
      method: 'GET', url: url,
      throughServiceWorker: bypassSw !== true,
    }, this.injectedAuthService.getAuth());
  }

}
