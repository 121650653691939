import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {combineLatest, concat, Observable, of} from 'rxjs';
import {
  AppConfigService,
  AuthService,
  FrontendAppConfigKey,
  FrontendAppSwitch,
  Ref,
  RequestService,
  User,
  UserAuthService,
} from '@lifeislife/lifeislife-domain';
import {
  ChildActivationEnd,
  ChildActivationStart,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import {debounceTime, delay, distinctUntilChanged, filter, map, publishReplay, refCount, tap} from 'rxjs/operators';
import {ApplicationMenuService} from '../../application-menu.service';
import {UserThemeService} from '../../main/services/user-theme-service';
import {CustomerApplicationMenu} from '../../dashboard/customer-application-menu';
import {AppShellContextProviderService} from '../app-shell-context-provider.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent implements OnInit {

  authenticated: Observable<boolean>;
  loggedUserRef$: Observable<Ref<User>>;
  requestRunning: Observable<boolean>;
  routeLoading: Observable<boolean>;
  loadingIndicatorHidden: Observable<boolean>;
  appVersion: string;

  devBanner$: Observable<boolean>;
  host$: Observable<string>;
  hostClass$: Observable<string>;

  menuModel: Observable<MenuItem[]>;
  singleItemMenuModel: Observable<MenuItem[]>;
  userMenuModel: MenuItem[];

  isCustomer$: Observable<boolean>;
  customerMenuModel$: Observable<MenuItem[]>;

  constructor(private userAuthService: UserAuthService,
              private appContextService: AppShellContextProviderService,
              private authService: AuthService,
              private appConfigService: AppConfigService,
              private requestService: RequestService,
              private changeDetectorRef: ChangeDetectorRef,
              private router: Router,
              private menuService: ApplicationMenuService,
              private themeService: UserThemeService,
  ) {
  }

  ngOnInit() {
    this.authenticated = this.authService.getAuthenticatedObservable();
    this.loggedUserRef$ = this.userAuthService.user$.pipe(map(u => u == null ? null : {id: u.id}));

    this.appVersion = this.appConfigService.getApplicationVersion();
    this.devBanner$ = this.appConfigService.getFrontSwitchEnabled$(FrontendAppSwitch.front_dev_banner);
    this.host$ = this.appConfigService.getConfigValue$(FrontendAppConfigKey.lifeislife_ws_uri).pipe(
      map(wsUri => new URL(wsUri)),
      map(url => url.host),
      publishReplay(1), refCount(),
    );
    this.hostClass$ = this.host$.pipe(
      map(host => {
        return this.isProdBackend(host) ? 'prod' :
          this.isDevBackend(host) ? 'dev' : 'local';
      }),
      publishReplay(1), refCount(),
    );

    this.requestRunning = this.requestService.getRequestRunningObservable().pipe(
      publishReplay(1), refCount(),
    );
    this.routeLoading = this.router.events.pipe(
      map(event => this.isRouteLoading(event)),
      filter(loading => loading != null),
      publishReplay(1), refCount(),
    );
    this.loadingIndicatorHidden = combineLatest([
      concat(of(false), this.requestRunning),
      concat(of(false), this.routeLoading),
    ]).pipe(
      map(results => !results[0] && !results[1]), // hidden if none loading
      // delay(0), // required
      publishReplay(1), refCount(),
    );

    this.menuModel = this.menuService.getMenu();
    this.customerMenuModel$ = this.menuService.createMenu$(CustomerApplicationMenu);

    this.singleItemMenuModel = this.menuModel.pipe(
      map(model => [<MenuItem>{
        label: 'Menu',
        icon: 'fa fa-bars',
        items: model,
      }]),
    );

    const customerRef$ = this.appContextService.getActiveContext$().pipe(
      map(c => c == null ? null : c.activeCustomerContact),
      map(cc => cc == null ? null : cc.customerRef),
      publishReplay(1), refCount(),
    );

    this.isCustomer$ = customerRef$.pipe(
      map(c => c != null),
      publishReplay(1), refCount(),
    );

    this.userMenuModel = [
      // {
      //   label: 'Statistiques',
      //   id: 'value-stats',
      //   icon: 'fa fa-bar-chart',
      // },
      // {
      //   label: 'Carte d\'identité',
      //   id: 'beid-parametes',
      //   icon: 'fa fa-id-card',
      // },
      {
        label: 'Déconnexion',
        id: 'logout',
        icon: 'fa fa-sign-out',
        command: () => this.logout(),
      },
      {
        label: 'Thème visuel',
        id: 'theme',
        icon: 'fa fa-paint-brush',
        items: [
          {
            label: 'LifeisLife',
            id: 'lifeislife',
            command: () => this.themeService.setTheme('lifeislife'),
          },
          {
            label: 'Luna blue',
            id: 'luna_blue',
            command: () => this.themeService.setTheme('luna_blue'),
          },
        ],
      },
    ];
  }

  private logout() {
    this.router.navigate(['/login'])
      .then(() => {
        // Waiting for navigation prevents current view to get 401 and the error handler
        // to trigger the redirect with an error messge
        this.userAuthService.deauthenticate();
      });
  }

  private isRouteLoading(event: Event) {
    if (event instanceof NavigationStart) {
      return true;
    }
    if (event instanceof NavigationEnd) {
      return false;
    }
    if (event instanceof ChildActivationStart) {
      return true;
    }
    if (event instanceof ChildActivationEnd) {
      return false;
    }
    if (event instanceof RouteConfigLoadStart) {
      return true;
    }
    if (event instanceof RouteConfigLoadEnd) {
      return false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      return false;
    }
    if (event instanceof NavigationError) {
      return false;
    }
    return null;
  }

  private isDevBackend(host: string) {
    return host.indexOf('lifeislife.local') < 0
      && host.indexOf('localhost') < 0
      && host.indexOf('dev') >= 0;
  }

  private isProdBackend(host: string) {
    return host.indexOf('lifeislife.local') < 0
      && host.indexOf('localhost') < 0
      && host.indexOf('lifeislifedev') < 0
      && host.indexOf('.lifeislife.be') >= 0;
  }

}
