import {KeyValueStore} from './key-value-store';
import {Injectable} from '@angular/core';

@Injectable()
export class NoopKeyValueStoreService implements KeyValueStore {

  constructor() {
  }

  clearValue(key: string) {
  }

  getValue(key: string): string | null {
    return null;
  }

  putValue(key: string, value: string) {
  }

}
