import {ApplicationMenuDef} from '../main/routing/application-menu-def';
import {ContactSearch, CustomerContactType} from '@lifeislife/lifeislife-domain';
import {AppFeature} from '../main/model/app-feature';


export const AllContactsFilterParam: string = ContactSearch.serializeFilter({});
export const ActiveCustomersContactsFilterParam: string = ContactSearch.serializeFilter({
  customerContactTypes: [
    CustomerContactType.LEGAL_REPRESENTATIVE,
    CustomerContactType.MANDATEE,
  ],
  customerContactCustomerSearch: {
    customerStatusSearch: {
      customerActive: true,
    },
    withActiveSubscriptions: true,
  },
});

export const ContactMenuData: any & ApplicationMenuDef = {
  moduleShell: {
    title: '',
    skipMenu: true,
  },
  list: {
    title: 'Liste des contacts',
    menuLink: ['/contact/list'],
    allAppFeatures: [AppFeature.contact_list],
  },
  allLlist: {
    title: `Tous les contacts`,
    menuLink: ['/contact/list', {filter: AllContactsFilterParam}],
    allAppFeatures: [AppFeature.contact_list],
  },
  customerList: {
    title: `Clients (Représentants légaux & mandatés)`,
    menuLink: ['/contact/list', {filter: ActiveCustomersContactsFilterParam}],
    allAppFeatures: [AppFeature.contact_list, AppFeature.customer_list],
  },
  new: {
    title: `Nouveau contact`,
    menuLink: ['/contact/new'],
  },
  details: {
    title: 'Détails',
    skipMenu: true,

    children: {
      details: {
        title: 'Détails',
        id: 'contact-details',
        menuIcon: 'fa fa-info',
        relativeMenuLink: ['details'],
      },
    },
  },
};
