<div class="trustee lifeislife-inline-label" *ngIf="trustee != null"
     [class.wrap]="wrap"
     [class.wrap-text]="wrapText"
>
  <span class="label">{{ trustee.name }}</span>

  <a class="lifeislife-jump-link" *ngIf="jumpLinkVisible$ | async"
     [routerLink]="jumpLinkRouteLink$ | async"
     (click)="onClick($event)"
     title="Vers la fiche fiduciaire" i18n-title
  >
    <i class="fa fa-external-link-square"></i>
  </a>

</div>
