import {Recurrence} from '../../domain/corebusiness/recurrence';
import {DateTime, Duration, DurationObjectUnits} from 'luxon';

export class DateUtils {

  static isSameDay(date1: Date, date2: Date): boolean {
    return !this.isDistinctSortedDays(date1, date2)
      && !this.isDistinctSortedDays(date2, date1);
  }

  static isDistinctSortedDays(date1: Date, date2: Date, minDayDiff = 0): boolean {
    const d1 = DateTime.fromJSDate(date1);
    const d2 = DateTime.fromJSDate(date2);
    return d2.diff(d1, 'day').days > minDayDiff;
  }

  static isDistinctSortedSeconds(date1: Date, date2: Date, minSecondDiff = 0): boolean {
    const d1 = DateTime.fromJSDate(date1);
    const d2 = DateTime.fromJSDate(date2);
    return d2.diff(d1, 'second').seconds > minSecondDiff;
  }

  static getYearDiff(date1: Date, date2: Date, includeIncompletes = true): number {
    const d1 = DateTime.fromJSDate(date1)
      .startOf('year');
    const d2 = DateTime.fromJSDate(date2);
    const duration = d2.diff(d1, 'month').shiftTo('year', 'month');
    const yearDuration = duration.years;
    if (includeIncompletes) {
      const monthDuration = duration.months / 12;
      return yearDuration + monthDuration;
    }
    return yearDuration;
  }

  static getYear(date: Date): number {
    if (date == null) {
      return 0;
    }
    const dt = DateTime.fromJSDate(date);
    return dt.year;
  }

  static getDayOfMonth(date: Date): number {
    if (date == null) {
      return 0;
    }
    const dt = DateTime.fromJSDate(date);
    return dt.daysInMonth;
  }

  static getMonth(date: Date): number {
    if (date == null) {
      return 0;
    }
    const dt = DateTime.fromJSDate(date);
    return dt.month;
  }


  static toDayOnlyDate(date: Date): Date | null {
    if (date == null) {
      return null;
    }
    const d1 = DateTime.fromJSDate(date);
    if (d1.isValid) {
      return d1.startOf('day').toJSDate();
    } else {
      return null;
    }
  }

  static parseDateAndTime(dateStirng: string | Date): Date | null {
    if (dateStirng == null) {
      return null;
    }
    const dt = this.parseDateTime(dateStirng);
    return dt.isValid ? dt.toJSDate() : null;
  }

  static addSecond(date: Date, amount: number): Date {
    if (date == null) {
      return null;
    }
    const d1 = DateTime.fromJSDate(date);
    if (d1.isValid) {
      return d1.plus({
        second: amount,
      }).toJSDate();
    } else {
      return null;
    }
  }

  static addMonth(date: Date, amount: number): Date {
    if (date == null) {
      return null;
    }
    const d1 = DateTime.fromJSDate(date);
    if (d1.isValid) {
      return d1.plus({
        month: amount,
      }).toJSDate();
    } else {
      return null;
    }
  }


  static formatTimeToHumanFormat(value: Date, withSecond = false): string {
    if (value == null) {
      return '';
    }

    let dateTime;
    if (typeof value === 'string') {
      dateTime = DateTime.fromISO(value);
    } else {
      dateTime = DateTime.fromJSDate(value);
    }

    if (dateTime.isValid) {
      return withSecond ? dateTime.toLocaleString(DateTime.TIME_24_WITH_SECONDS)
        : dateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
    } else {
      return '';
    }
  }

  static formatDateTimeToHumanFormat(value: Date | string, withSecond?: boolean) {
    if (value == null) {
      return '';
    }
    if (value == null) {
      return '';
    }

    let dateTime;
    if (typeof value === 'string') {
      dateTime = DateTime.fromISO(value);
    } else {
      dateTime = DateTime.fromJSDate(value);
    }

    if (dateTime.isValid) {
      return withSecond ? dateTime.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
        : dateTime.toLocaleString(DateTime.DATETIME_SHORT);
    } else {
      return '';
    }

  }

  static formatDateToHumanFormat(value: Date | string) {
    if (value == null) {
      return '';
    }
    const dateTime = this.parseDateTime(value);

    if (dateTime.isValid) {
      return dateTime.toLocaleString(DateTime.DATE_SHORT);
    } else {
      return '';
    }
  }

  static formatMonthToHumanFormat(value: Date | string) {
    if (value == null) {
      return '';
    }
    const dateTime = this.parseDateTime(value);

    if (dateTime.isValid) {
      return dateTime.toFormat('MMMM yyyy');
    } else {
      return '';
    }
  }

  static formatToRelativeDay(value: Date | string) {
    if (value == null) {
      return '';
    }
    const dateTime = this.parseDateTime(value);

    if (dateTime.isValid) {
      return dateTime.toRelative();
    } else {
      return '';
    }
  }

  static isValidTimeInputValue(value: string): boolean {
    const regexp = new RegExp(/[0-9]+:[0-9]+/);
    return regexp.test(value);
  }

  static isValidDate(value: Date): boolean {
    return value != null && !isNaN(value.getDate());
  }

  static nextInFutureByRecurrenceSteps(referenceDate: Date, recurrence: Recurrence): Date | null {
    if (referenceDate == null || recurrence == null) {
      return null;
    }
    const dateTime = this.parseDateTime(referenceDate);

    if (!dateTime.isValid) {
      return null;
    }

    switch (recurrence) {
      case Recurrence.MONTH:
        return this.addDurationUntilFuture(dateTime, {
          months: 1,
        })
          .toJSDate();
      case Recurrence.QUARTER:
        return this.addDurationUntilFuture(dateTime, {
          quarters: 1,
        })
          .toJSDate();
      case Recurrence.SEMESTER:
        return this.addDurationUntilFuture(dateTime, {
          months: 6,
        })
          .toJSDate();
      case Recurrence.YEAR:
        return this.addDurationUntilFuture(dateTime, {
          years: 1,
        })
          .toJSDate();
      default:
        throw new Error(`Unhandled recurrence: ${recurrence}`);
    }
  }

  static getMaxDate(...dates: Date[]): Date {
    return dates.reduce((c, n) => {
      if (c == null) {
        return n;
      }
      const dateTimeA = DateTime.fromJSDate(c);
      const dateTimeB = DateTime.fromJSDate(n);
      return dateTimeA.diff(dateTimeB, 'day').days >= 0 ? dateTimeA.toJSDate() : dateTimeB.toJSDate();
    }, null);
  }

  private static addDurationUntilFuture(refDateTime: DateTime, durationUnits: DurationObjectUnits): DateTime {
    const now = DateTime.fromJSDate(new Date());
    const duration = Duration.fromObject(durationUnits);

    let testDateTime = refDateTime;
    while (testDateTime.diff(now, 'day').days < 0) {
      testDateTime = testDateTime.plus(duration);
    }
    return testDateTime;
  }

  private static parseDateTime(value: Date | string): DateTime {
    if (typeof value === 'string') {
      return DateTime.fromISO(value);
    } else {
      return DateTime.fromJSDate(value);
    }
  }

}

