import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {filter, switchMap, take} from 'rxjs/operators';
import {DragDropUtils} from '../drag-drop-utils';

@Component({
  selector: 'app-file-drop-area',
  templateUrl: './file-drop-area.template.html',
  styleUrls: ['./file-drop-area.style.scss'],
})
export class FileDropAreaComponent implements OnInit {

  @Input() active: boolean;
  @Input() acceptFiles = true;

  @Output() filesDropped = new EventEmitter<FileList>();
  @Output() draggingChanged = new EventEmitter<boolean>();
  @Output() dragOverTick = new EventEmitter<any>();

  draggingOver: boolean;
  private subscription: Subscription;

  constructor() {
  }

  ngOnInit() {
    // Here we emit an event after some delay when holding a draggin item over this component.
    // This allow to trigger a navigation when holding files over a folder for some time for instance.
    this.subscription = this.draggingChanged.asObservable().pipe(
      filter(d => d),
      switchMap(() => this.delayDragOverTick()),
    ).subscribe(tick => this.dragOverTick.emit(tick));
  }

  private delayDragOverTick() {
    // FIXME: some timers seem to remain active (ie not catching the dragleave event) so we take at most 3
    // FIXME: may be due to the fact that setInterval in angular zone causes issue
    return timer(1500).pipe(take(3));
  }

  onDragEnter(event: DragEvent) {
    if (!this.active) {
      return;
    }
    this.draggingOver = true;
    this.draggingChanged.emit(true);
  }

  onDragLeave(event) {
    if (!this.active) {
      return;
    }
    this.draggingOver = false;
    this.draggingChanged.emit(false);
  }

  onDragOver(event: DragEvent) {
    if (!this.active) {
      return false;
    }
    if (DragDropUtils.hasFileData(event) && !this.acceptFiles) {
      return false;
    }
    // Accept the drag by preventign default
    event.preventDefault();
    return false;
  }

  onDrop(event) {
    if (!this.active) {
      return;
    }
    if (DragDropUtils.hasFileData(event) && this.acceptFiles) {
      const files = DragDropUtils.getFileData(event);
      this.filesDropped.emit(files);
    }

    this.draggingOver = false;
    this.draggingChanged.emit(false);
    return false;
  }

}
