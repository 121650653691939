import {SupplementaryPensionStatus} from '../../domain/retire/supplementary-pension-status';
import {WsSupplementaryPensionStatus, WsSupplementaryPensionStatusSearch} from '@lifeislife/lifeislife-ws-api';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {SupplementaryPensionStatusSearch} from '../../domain/retire/supplementary-pension-status-search';

export class SupplementaryPensionStatusConverter {

  static convertIn(wsSupplementaryPensionStatus: WsSupplementaryPensionStatus): SupplementaryPensionStatus {
    return {
      id: wsSupplementaryPensionStatus.id,
      contactRef: wsSupplementaryPensionStatus.wsContactWsRef,
      creationDate: DateConverterUtils.parseIsoDate(wsSupplementaryPensionStatus.creationDate),
      referenceDate: DateConverterUtils.parseIsoDate(wsSupplementaryPensionStatus.referenceDate),
      referenceYear: wsSupplementaryPensionStatus.referenceYear,
      reserveAmount: wsSupplementaryPensionStatus.reserveAmount,
      reserveEstimatedMonthlyAmount: wsSupplementaryPensionStatus.reserveEstimatedMonthlyAmount,
      deathCoverAmount: wsSupplementaryPensionStatus.deathCoverAmount,
      deathCoverBeneficiariesAdditionalAllowance: wsSupplementaryPensionStatus.deathCoverBeneficiariesAdditionalAllowance,
      deathCoverOrphinsAdditionalAllowance: wsSupplementaryPensionStatus.deathCoverOrphinsAdditionalAllowance,
    };
  }

  static convertSearchFilterOut(search: SupplementaryPensionStatusSearch): WsSupplementaryPensionStatusSearch {
    if (search == null) {
      return null;
    }
    return {
      contactWsRef: search.contactRef,
      referenceYear: search.referenceYear,
    };
  }
}
