<span [ngSwitch]="column.type"
      [class]="column.type"
      [title]="getTitle(column, row)"
>

    <span *ngSwitchCase="'MAIL_CONTACT_TYPE'">
      <app-customer-contact-type [contactType]="row?.customerContact.customerContactType"
      ></app-customer-contact-type>
    </span>

    <span *ngSwitchCase="'CUSTOMER'">
      <app-customer [ref]="row?.customerContact.customerRef"
      ></app-customer>
    </span>

    <span *ngSwitchCase="'TRUSTEE'">
<!--      <app-trustee [ref]="row?.contact.trusteeRef"></app-trustee>-->
    </span>

    <span *ngSwitchCase="'INTERNAL'">
      <i class="pi pi-check-circle"
         *ngIf="row.contact?.internal"></i>
    </span>

     <span *ngSwitchCase="'PERSON_TITLE'">
       <app-person-title [ref]="row?.contact.personTitleRef">
       </app-person-title>
    </span>

    <span *ngSwitchCase="'SEND_EMAIL'">
      <i class="pi pi-check-circle"
         *ngIf="row.contact?.sendEmail"></i>
    </span>

    <span *ngSwitchCase="'SEND_SMS'">
      <i class="pi pi-check-circle"
         *ngIf="row.contact?.sendSms"></i>
    </span>

    <span *ngSwitchCase="'BIRTH_DATE'">
      {{ row.contact.birthDate | cDate }}
    </span>

    <span *ngSwitchCase="'ID_EXPIRY_DATE'">
      {{ row.contact.idExpiryDate | cDate }}
    </span>

    <span *ngSwitchCase="'COUNTRY'">
      <app-country [ref]="row?.contact.countryRef">
      </app-country>
    </span>


    <span *ngSwitchCase="'ACTIONS'">
        <div class="actions">
            <a class="action delete"
               *ngIf="showDeleteAction"
               (click)="onDeleteClicked(row, $event)"
            >
                <i class="fa fa-times"
                   title="Supprimer"></i>
            </a>
        </div>
    </span>

    <span *ngSwitchDefault>
      {{ getTitle(column, row) }}
    </span>

</span>
