import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsInvestment, WsInvestmentSearch, WsRef} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../../util/pagination/simple-pagination';
import {SearchResult} from '../../../client/domain/search/search-result';
import {ValidationResult} from '../../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../../object-converter-util';
import {isValidRef, Ref} from '../../../domain/shared/ref';
import {WithId} from '../../../client/domain/with-id';
import {InvestmentWsClient} from '../../../client/resources/front/corebusiness/investment-ws-client';
import {InvestmentConverter} from './investment-converter';
import {Investment} from '../../../domain/corebusiness/investment/investment';
import {InvestmentSearch} from '../../../domain/corebusiness/investment/investment-search';
import {ProductService} from '../product.service';
import {Product} from '../../../domain/corebusiness/product';
import {SimplePaginationUtils} from '../../../util/pagination/simple-pagination-utils';

@Injectable({
  providedIn: 'root',
})
export class InvestmentService {


  constructor(private client: InvestmentWsClient,
              private productService: ProductService,
  ) {
  }

  getInvestment$(ref: Ref<Investment>, forceFetch?: boolean): Observable<Investment> {
    return ref == null ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsInvestment => InvestmentConverter.convertIn(wsInvestment)),
    );
  }

  getInvestmentForProduct$(productRef: Ref<Product>): Observable<Investment> {
    const investmentSearch: InvestmentSearch = {
      productSearch: {
        productRef: productRef,
      },
    };
    const pagination = SimplePaginationUtils.newPagination(1);
    return this.searchInvestments$(investmentSearch, pagination).pipe(
      map(r => r.count === 0 ? null : r.list[0]),
    );
  }

  getInvestmentName$(investment: Investment): Observable<string> {
    if (investment == null || investment.id == null) {
      return of('Nouveau placement');
    }
    return this.productService.getProduct$(investment.productRef).pipe(
      map(i => i.name),
    );
  }

  searchInvestments$(investmentFilter: InvestmentSearch, pagination: SimplePagination): Observable<SearchResult<Investment>> {
    const wsSearch = InvestmentConverter.convertFilterOut(investmentFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateInvestment$(investment: Investment): Observable<ValidationResult<Investment>> {
    const wsInvestment = InvestmentConverter.convertOut(investment);
    return this.client.doValidate(wsInvestment).pipe(
      map((results: WsInvestment | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveInvestment$(investment: Investment): Observable<WithId> {
    const wsInvestment = InvestmentConverter.convertOut(investment);
    return this.client.doSave(wsInvestment);
  }

  deleteInvestment$(ref: Ref<Investment>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  serializeFilter(investmentFilter: InvestmentSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(investmentFilter);
    const wsInvestmentSearch = InvestmentConverter.convertFilterOut(investmentFilter);
    return JSON.stringify(wsInvestmentSearch);
  }

  deserializeFilter(valueString: string | null): InvestmentSearch {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsInvestmentSearch = JSON.parse(valueString);
      const filter = InvestmentConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsInvestment | WsConstraintViolation[]): ValidationResult<Investment> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsInvestment => InvestmentConverter.convertIn(wsInvestment),
      propertyNameMappings: InvestmentConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsInvestment>>): Observable<SearchResult<Investment>> {
    const rowTasks = results.list.map(ref => this.getInvestment$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<Investment>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<Investment>>>{
          list: rows,
        }),
      ));
  }
}
