import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Ref, Role, Trustee, TrusteeService} from '@lifeislife/lifeislife-domain';
import {Observable, of} from 'rxjs';
import {map, publishReplay, refCount} from 'rxjs/operators';
import {AppShellContextProviderService} from '../../../../shell/app-shell-context-provider.service';
import {ApplicationNavigationService} from '../../../../application-navigation.service';

@Component({
  selector: 'app-trustee',
  templateUrl: 'trustee.template.html',
  styleUrls: ['./trustee.style.scss'],
  // FIXME
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrusteeComponent implements OnInit, OnChanges {

  @Input()
  trustee: Trustee;
  @Input()
  ref: Ref<Trustee>;
  @Input()
  showJumpLink;
  @Input()
  wrap: boolean;
  @Input()
  wrapText = true;

  hasFetchError: boolean;

  jumpLinkVisible$: Observable<boolean>;
  jumpLinkRouteLink$: Observable<any[]>;

  constructor(
    private trusteeService: TrusteeService,
    private appContextservice: AppShellContextProviderService,
    private navigationService: ApplicationNavigationService,
  ) {
  }

  ngOnInit(): void {
    this.jumpLinkVisible$ = this.appContextservice.getActiveContext$().pipe(
      map(c => c != null && c.activeRole != null && c.activeRole !== Role.CUSTOMER
        && c.activeTrusteeContact == null && this.showJumpLink),
      publishReplay(1), refCount(),
    );
    this.jumpLinkRouteLink$ = of(null).pipe(
      map(r => this.navigationService.createTrusteeDetailsRouterLink(this.ref)),
      publishReplay(1), refCount(),
    );
  }


  ngOnChanges(changes: SimpleChanges): void {
    const refChange = changes['ref'];
    if (refChange != null) {
      this.loadRef(refChange.currentValue);
    }
  }

  private loadRef(ref: Ref<Trustee>) {
    if (ref == null) {
      return;
    } else {
      this.trusteeService.getTrustee$(ref)
        .subscribe(type => this.onFetchSuccess(type),
          error => this.onFetchError(error),
        );
    }
  }

  private onFetchSuccess(trustee) {
    this.trustee = trustee;
    this.hasFetchError = false;
  }

  private onFetchError(error: any) {
    this.trustee = null;
    this.hasFetchError = true;
  }

  onClick(event: MouseEvent) {
    event.stopImmediatePropagation();
  }
}
