/**
 * Created by cghislai on 18/04/16.
 */


export class WsStringKeyResourceCache<T> {
  private readonly cache: { [id: string]: T };
  private readonly getEntityKeyFunction: (entity: T) => string;

  constructor(getEntityKeyFunction: (entity: T) => string) {
    this.cache = {};
    this.getEntityKeyFunction = getEntityKeyFunction;
  }

  public putInCache(entity: T, id?: string) {
    if (id == null) {
      if (!this.isCachable(entity)) {
        return;
      }
      id = this.getId(entity);
    }
    this.cache[id] = entity;
  }

  public removeFromCache(id: string) {
    delete this.cache[id];
  }

  public getFromCache(id: string): T | undefined {
    if (!this.isInCache(id)) {
      return undefined;
    }
    return this.cache[id];
  }

  private isInCache(id: string): boolean {
    return this.cache[id] !== undefined;
  }

  private isCachable(entity: T) {
    const id = this.getId(entity);
    return id != null;
  }

  private getId(entity: T): string {
    if (entity == null) {
      return undefined;
    }
    return this.getEntityKeyFunction(entity);
  }
}
