import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {CountryConverter} from './country.converter';
import {Country} from '../../domain/country/country';
import {CountryFilter} from '../../domain/country/country-filter';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {CountryWsClient} from '../../client/resources/front/country-ws-client';
import {WsCountrySearch} from '@lifeislife/lifeislife-ws-api';

@Injectable({
  providedIn: 'root',
})
export class CountryService {

  constructor(private client: CountryWsClient,
  ) {
  }

  getCountry$(ref: Ref<Country>): Observable<Country> {
    return !isValidRef(ref) ? of(null) : this.client.getCountry$(ref.id).pipe(
      map(wsCountry => CountryConverter.convertIn(wsCountry)),
    );
  }

  getCountryName$(ref: Ref<Country>): Observable<string | null> {
    if (!isValidRef(ref)) {
      return of(null);
    }
    return this.getCountry$(ref).pipe(
      map(c => c.nameFr),
    );
  }

  getAllCountries$(): Observable<Country[]> {
    return this.client.getAllCountries$().pipe(
      map(results => CountryConverter.convertSearchResultsIn(results)),
      map(result => result.list),
    );
  }

  searchCountryByNameFr$(nameFr: string): Observable<Country[]> {
    return this.getAllCountries$().pipe(
      map(list => list.filter(country => this.countryNameMatches(country, nameFr))),
    );
  }

  searchCountryByCode$(code: string): Observable<Country[]> {
    return this.getAllCountries$().pipe(
      map(list => list.filter(country => this.countryCodeMatches(country, code))),
    );
  }

  searchCountries$(countryFilter: CountryFilter, pagination: SimplePagination): Observable<SearchResult<Country>> {
    const wsCountrySearch = CountryConverter.convertFilterOut(countryFilter);
    return this.client.searchCountrys$(wsCountrySearch, pagination).pipe(
      map(results => CountryConverter.convertSearchResultsIn(results)),
    );
  }

  createFilter(): CountryFilter {
    return CountryConverter.convertFilterIn({} as WsCountrySearch);
  }

  private countryNameMatches(country: Country, testString: string) {
    return country != null
      && country.nameFr != null
      && country.nameFr.toLowerCase().indexOf(testString.toLowerCase()) >= 0;
  }


  private countryCodeMatches(country: Country, testString: string) {
    return country != null
      && country.code != null
      && country.code.toLowerCase().indexOf(testString.toLowerCase()) >= 0;
  }
}
