/**
 * Created by cghislai on 03/09/16.
 */


export class UrlParserUtils {

  static getQueryStringValue(key: string) {
    const queryString = window.location.search;
    const regexp = new RegExp(key + '=([^&]+)');
    const result = regexp.exec(queryString);
    if (result == null || result.length < 1) {
      return null;
    }
    const match = result[1];
    const decodedValue = decodeURIComponent(match);
    return decodedValue;
  }
}
