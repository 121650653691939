import {WsCustomerStatusSearch} from '@lifeislife/lifeislife-ws-api';
import {CustomerStatusSearch} from '../../domain/customer/customer-status-search';
import {CustomerStatusConverter} from './customer-status.converter';

export class CustomerStatusSearchConverter {

  static toCustomerStatusSearch(value: WsCustomerStatusSearch) {
    if (value == null) {
      return undefined;
    }
    return {
      anyCustomerStatusList: value.anyCustomerStatusRefList == null ? [] : value.anyCustomerStatusRefList.map(
        s => CustomerStatusConverter.toCustomerStatus(s),
      ),
      archived: value.archived,
      customerActive: value.customerActive,
    };
  }

  static toWsCustomerStatusSearch(value: CustomerStatusSearch) {
    if (value == null) {
      return undefined;
    }
    return {
      anyCustomerStatusRefList: value.anyCustomerStatusList == null ? [] : value.anyCustomerStatusList.map(
        s => CustomerStatusConverter.toWsCustomerStatus(s),
      ),
      archived: value.archived,
      customerActive: value.customerActive,
    };
  }

}
