<div class="phone-number-input">
  <div class="prefix">
    <input type="text" name="prefix" pInputText
           pattern="\+[0-9]{2,3}"
           [(ngModel)]="prefixValue"
           (ngModelChange)="onPrefixChange($event)"
           [disabled]="!prefixEdiitable"
           size="3"
    >
  </div>
  <div class="number">
    <p-inputMask type="text" name="number"
                 [(ngModel)]="value"
                 (ngModelChange)="onNumberChange($event)"
                 [disabled]="!numberEditable"
                 mask="999 999999"
                 [size]="12"
                 [autoFocus]="autoFocus"
                 [required]="required"
                 #numberInput
    ></p-inputMask>
  </div>
</div>
