import {LifeislifeBackendError} from './lifeislife-backend-error';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {WsFrontendError} from '@lifeislife/lifeislife-ws-api';
import {AuthProvider} from '../../client/domain/auth/auth-provider';
import {RequestService} from '../../client/service/request.service';
import {FrontendAppConfigKey} from '../../domain/config/frontend-app-config-key';
import {AppConfigService} from '../../service/config/app-config.service';
import {FrontendAppSwitch} from '../../domain/config/frontend-app-switch';

export class ErrorUtils {
  // imported from '@angular/service-worker/src/low_level';
  private static ERR_SW_NOT_SUPPORTED = 'Service workers are disabled or not supported by this browser';
  public static BACKEND_ERROR_ID_OPTIMISTIC_LOCK = 'OPTIMISTIC_LOCK_ERROR';


  static getHttpErrorStatus(error: any): number | null {
    if (error instanceof HttpResponse) {
      return error.status;
    } else if (error instanceof HttpErrorResponse) {
      return error.status;
    } else if (error.status != null && typeof error.status === 'number') {
      return error.status;
    } else {
      return null;
    }
  }

  static getErrorMessage(error: any): string {
    const backendError = this.getHttpBackendError(error);
    if (backendError) {
      return backendError.message;
    }
    if (typeof error === 'string') {
      return `${error}`;
    } else if (error.message) {
      return error.message;
    } else {
      return `Erreur inconnue`;
    }
  }

  static getHttpBackendError(error: any): LifeislifeBackendError | null {
    if (error instanceof HttpErrorResponse) {
      if (error.error) {
        let contentType = '';
        if (error.headers && error.headers.get && typeof error.headers.get === 'function') {
          contentType = error.headers.get('content-type') || '';
        }
        let errorJsonObj;
        // json should be parsed. But when we request something else, error response might not be parsed
        if (contentType.indexOf('application/json') !== 0 || typeof error.error === 'string') {
          try {
            errorJsonObj = JSON.parse(error.error);
          } catch (e) {
            errorJsonObj = {
              message: e.message + ' while parsing ' + error.error,
            };
          }
        } else {
          errorJsonObj = error.error;
        }
        const identifier = errorJsonObj.errorIdentifier;
        const message = errorJsonObj.message;
        let details = errorJsonObj.messageDetails;
        // Display constraint violations as details when provided
        const constraints = errorJsonObj.constraintViolations;
        if (constraints && constraints.length > 0) {
          const startMessage = `Des contraintes d'intégrité ne sont pas respectées`;
          details = constraints
            .map(c => {
              return c.propertyName == null ? c.localizedMessage : `${c.propertyName}: ${c.localizedMessage}`;
            })
            .reduce((a, b) => `${a}. ${b}`, startMessage);
        }
        if (identifier != null && message != null) {
          return {
            identifier: identifier,
            message: message,
            details: details,
          };
        }
      }
    }
    return null;
  }


  static createStackTrace(error: any) {
    let message;
    let stack;
    if (error instanceof HttpErrorResponse) {
      let body = error.error;
      if (typeof body === 'object') {
        body = JSON.stringify(body);
      }
      message = `HTTP error ${error.status}: ${error.message}\nresponse body:\n${body}`;
    } else if (error.message) {
      message = error.message;
    } else {
      message = `(No message)`;
    }
    if (error.stack) {
      const errorStack = error.stack;
      stack = errorStack.split('\n')
        .filter(l => l.indexOf('vendor') < 0)
        .reduce((c, n) => `${c}\n${n}`, '');
    } else {
      stack = `(No stack)`;
    }

    return `${message}\n${stack}`;
  }

  // Check whether an error should be reported to backend or discarded
  static isErrorToDiscard(error: any): boolean {
    if (error['rejection'] != null) {
      const promiseRejection = error['rejection'];
      // Ignore service worker unavailable errors
      if (promiseRejection.message === this.ERR_SW_NOT_SUPPORTED) {
        console.warn('Not submitting service worker error:');
        return true;
      }
    } else if (error['status'] != null) {
      const errorStatus: number = error.status;
      // Ignore validation errors and authorization errors
      if (errorStatus === 406 || errorStatus === 401) {
        return true;
      }
      if (errorStatus === 404) {
        // const errorUrl: string = error.url;
        // if (errorUrl) {
        //   if (errorUrl.indexOf('lifeislife-ws/unrestricted/token/verify/email') >= 0) {
        //     return true;
        //   }
        // }
        return true;
      }
    }

    return false;
  }


  static sendErrors(errors: WsFrontendError[],
                    authProvider: AuthProvider,
                    configService: AppConfigService,
                    requestService: RequestService) {
    if (errors.length === 0) {
      return;
    }

    const curConfig = configService.curSettings.frontConfig;
    const curSwitches = configService.curSettings.frontSwitches;
    if (curConfig == null || curSwitches == null) {
      console.warn('Could not send error: no client config');
      return;
    }
    const sendReportSwitch = curSwitches.get(FrontendAppSwitch.front_error_report) === true;
    if (sendReportSwitch === false) {
      return;
    }
    const wsUri = curConfig.get(FrontendAppConfigKey.lifeislife_ws_uri);
    const auth = authProvider == null ? null : authProvider.getAuth();
    const errorUrl = `${wsUri}/unrestricted/error`;

    const firstError = errors[0];
    if (errors.length > 1) {
      const amountSilenced = errors.length - 1;
      firstError.message = `${firstError.message}\n${amountSilenced} following error silenced`;
    }

    requestService.sendRequest({
      method: 'POST',
      url: errorUrl,
      body: firstError,
    }, auth)
      .subscribe((problemId: number) => {
        console.log(`Error has been submitted with id ${problemId}`);
      }, requestError => {
        console.warn('Could not send error to backend');
        console.warn(requestError);
      });
  }

  static isServiceUnavailableHttpError(error: any): error is HttpErrorResponse {
    if (error instanceof HttpErrorResponse) {
      const status = error.status;
      // include 0 - cors error
      return status === 503 || status === 504 || status === 0;
    }
    return false;
  }

}
