export enum AppFeature {

  batch_list = 'lifeislife_front.batch.list',
  contact_list = 'lifeislife_front.contact.list',
  contact_edit = 'lifeislife_front.contact.edit',
  contact_create = 'lifeislife_front.contact.create',
  customer_list = 'lifeislife_front.customer.list',
  customer_create = 'lifeislife_front.customer.create',
  customer_edit = 'lifeislife_front.customer.edit',
  customer_subscription_snapshots_create = 'lifeislife_front.customer.subscriptionSnapshots.create',
  customer_mailing_contacts_create = 'lifeislife_front.customer.mailingContacts.create',
  customer_mandate_request_create = 'lifeislife_front.customer.mandateRequest.create',
  customer_bank_account_list = 'lifeislife_front.customer.bank_account.list',
  customer_bank_account_create = 'lifeislife_front.customer.bank_account.create',
  customer_bank_account_remove = 'lifeislife_front.customer.bank_account.remove',
  customer_bank_account_edit = 'lifeislife_front.customer.bank_account.edit',
  customer_contact_list = 'lifeislife_front.customer.contact.list',
  customer_contact_create = 'lifeislife_front.customer.contact.create',
  customer_contact_remove = 'lifeislife_front.customer.contact.remove',
  customer_contact_edit = 'lifeislife_front.customer.contact.edit',
  customer_contact_invite = 'lifeislife_front.customer.contact.invite',
  customer_event_subscribe = 'lifeislife_front.customer.event.subscribe',
  credit_list = 'lifeislife_front.credit.list',
  credit_create = 'lifeislife_front.credit.create',
  credit_edit = 'lifeislife_front.credit.edit',
  credit_provider_list = 'lifeislife_front.credit_provider.list',
  credit_provider_create = 'lifeislife_front.credit_provider.create',
  credit_provider_edit = 'lifeislife_front.credit_provider.edit',
  credit_subscription_list = 'lifeislife_front.credit_subscription.list',
  credit_subscription_create = 'lifeislife_front.credit_subscription.create',
  credit_subscription_edit = 'lifeislife_front.credit_subscription.edit',
  email_account_list = 'lifeislife_front.email_account.list',
  email_account_edit = 'lifeislife_front.email_account.edit',
  email_account_create = 'lifeislife_front.email_account.create',
  insurance_list = 'lifeislife_front.insurance.list',
  insurance_create = 'lifeislife_front.insurance.create',
  insurance_edit = 'lifeislife_front.insurance.edit',
  insurance_category_list = 'lifeislife_front.insurance_category.list',
  insurance_category_create = 'lifeislife_front.insurance_category.create',
  insurance_category_edit = 'lifeislife_front.insurance_category.edit',
  insurance_category_features_edit = 'lifeislife_front.insurance_category.features.edit',
  insurance_provider_list = 'lifeislife_front.insurance_provider.list',
  insurance_provider_create = 'lifeislife_front.insurance_provider.create',
  insurance_provider_edit = 'lifeislife_front.insurance_provider.edit',
  insurance_subscription_list = 'lifeislife_front.insurance_subscription.list',
  insurance_subscription_create = 'lifeislife_front.insurance_subscription.create',
  insurance_subscription_edit = 'lifeislife_front.insurance_subscription.edit',
  investment_list = 'lifeislife_front.investment.list',
  investment_create = 'lifeislife_front.investment.create',
  investment_edit = 'lifeislife_front.investment.edit',
  investment_provider_list = 'lifeislife_front.investment_provider.list',
  investment_provider_create = 'lifeislife_front.investment_provider.create',
  investment_provider_edit = 'lifeislife_front.investment_provider.edit',
  investment_subscription_list = 'lifeislife_front.investment_subscription.list',
  investment_subscription_create = 'lifeislife_front.investment_subscription.create',
  investment_subscription_edit = 'lifeislife_front.investment_subscription.edit',
  lifeislife_log_list = 'lifeislife_fornt.lifislife_log.view',
  lifeislife_log_create = 'lifeislife_fornt.lifislife_log.edit',
  mail_list = 'lifeislife_front.mail.list',
  message_template_list = 'lifeislife_front.message_template.list',
  message_template_edit = 'lifeislife_front.message_template.edit',
  person_title_list = 'lifeislife_front.person_title.list',
  person_title_edit = 'lifeislife_front.person_title.edit',
  person_title_create = 'lifeislife_front.person_title.create',
  signing_packages_list = 'lifeislife_front.signing_packages.list',
  signing_packages_create = 'lifeislife_front.signing_packages.create',
  signing_packages_check_signature = 'lifeislife_front.signing_packages.check_signature',
  smtp_account_list = 'lifeislife_front.smtp_account.list',
  smtp_account_edit = 'lifeislife_front.smtp_account.edit',
  smtp_account_create = 'lifeislife_front.smtp_account.create',
  app_preference_list = 'lifeislife_front.app_preference.list',
  app_preference_edit = 'lifeislife_front.app_preference.edit',
  event_record_list = 'lifeislife_front.event_record.list',
  subscription_list = 'lifeislife_front.subscription.list',
  trustee_list = 'lifeislife_front.trustee.list',
  trustee_edit = 'lifeislife_front.trustee.edit',
  trustee_create = 'lifeislife_front.trustee.create',
  trustee_contact_list = 'lifeislife_front.trustee.contact.list',
  trustee_contact_invite = 'lifeislife_front.trustee.contact.invite',
  trustee_contact_create = 'lifeislife_front.trustee.contact.create',
  trustee_contact_remove = 'lifeislife_front.trustee.contact.remove',
  trustee_contact_edit = 'lifeislife_front.trustee.contact.edit',
  user_list = 'lifeislife_front.user.list',
  user_edit = 'lifeislife_front.user.edit',
  user_edit_admin = 'lifeislife_front.user.edit.admin',
  user_edit_master = 'lifeislife_front.user.edit.master',
}
