import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {AppConfigService, ApplicationVersion, FrontendAppConfigKey} from '@lifeislife/lifeislife-domain';
import {Observable} from 'rxjs';

@Component({
  selector: 'llc-lifeislife-footer',
  templateUrl: './lifeislife-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifeislifeFooterComponent implements OnInit {

  versionString: string;


  companyAddress$: Observable<string>;
  companyPhone$: Observable<string>;
  mailSupportAddress$: Observable<string>;
  companyVat$: Observable<string>;
  privacyPolicyUri$: Observable<string>;

  constructor(
    private injector: Injector,
    private configService: AppConfigService,
  ) {
  }

  ngOnInit(): void {
    const appVersion = this.getAppVersion();
    if (appVersion) {
      this.versionString = this.getAppVersion().version;
    } else {
      this.versionString = `unspecified`;
    }

    this.companyAddress$ = this.configService.getConfigValue$(FrontendAppConfigKey.company_address);
    this.companyPhone$ = this.configService.getConfigValue$(FrontendAppConfigKey.company_phone);
    this.mailSupportAddress$ = this.configService.getConfigValue$(FrontendAppConfigKey.mail_support_address);
    this.companyVat$ = this.configService.getConfigValue$(FrontendAppConfigKey.company_vat);
    this.privacyPolicyUri$ = this.configService.getConfigValue$(FrontendAppConfigKey.privacy_policy_uri);
  }

  // Resolve theses at runtime because this is dependency hell.
  private getAppVersion(): ApplicationVersion | null {
    return this.injector.get(ApplicationVersion, null);
  }
}
