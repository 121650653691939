import {Component, Input, OnInit} from '@angular/core';
import {TrusteeService} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {TrusteeKeysWithLogo} from './trustee-with-logo';

/**
 * Make sure to include @lifeislife/lifeislife-resources/trustee assets in the build output dir /assets/trustee.
 * See angular.json.
 */
@Component({
  selector: 'llc-trustee-logo',
  templateUrl: './trustee-logo.component.html',
  styleUrls: ['./trustee-logo.component.scss'],
})
export class TrusteeLogoComponent implements OnInit {

  @Input()
  set trusteeKey(value: string) {
    this.key$.next(value);
  }

  @Input()
  showFallbackLabel: boolean;

  key$ = new BehaviorSubject<string>(null);
  label$: Observable<string>;
  hasLogo$: Observable<boolean>;


  constructor(
    private trusteeService: TrusteeService,
  ) {
  }

  ngOnInit(): void {
    this.label$ = this.key$.pipe(
      switchMap(k => k == null ? of(null) : this.trusteeService.getTrusteeRefByKey$(k)),
      switchMap(ref => ref == null ? of(null) : this.trusteeService.getTrusteeName$(ref)),
      publishReplay(1), refCount(),
    );
    this.hasLogo$ = this.key$.pipe(
      map(k => this.hasTrusteeLogo(k)),
      publishReplay(1), refCount(),
    );
  }

  private hasTrusteeLogo(key: string) {
    if (key == null) {
      return false;
    }
    return TrusteeKeysWithLogo.findIndex(k => k === key) >= 0;
  }
}
