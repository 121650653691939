import {WsCreditStatus, WsCreditSubscription, WsCreditSubscriptionSearch} from '@lifeislife/lifeislife-ws-api';
import {CreditSubscription} from '../../../domain/corebusiness/credit/credit-subscription';
import {EnumConverterUtils} from '../../../client/private_util/enum-converter-utils';
import {CreditSubscriptionSearch} from '../../../domain/corebusiness/credit/credit-subscription-search';
import {CreditConverter} from './credit-converter';
import {CreditStatus} from '../../../domain/corebusiness/credit/credit-status';
import {SubscriptionConverter} from '../subscription-converter';

export class CreditSubscriptionConverter {

  static convertIn(value: WsCreditSubscription): CreditSubscription {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      creditRef: value.creditWsRef,
      creditStatus: EnumConverterUtils.convertValue(value.wsCreditStatus, CreditStatus),
      subscriptionRef: value.subscriptionWsRef,
    };
  }

  static convertOut(value: CreditSubscription): WsCreditSubscription {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      creditWsRef: value.creditRef,
      wsCreditStatus: EnumConverterUtils.convertValue(value.creditStatus, WsCreditStatus),
      subscriptionWsRef: value.subscriptionRef,
    };
  }


  static convertFilterIn(value?: WsCreditSubscriptionSearch): CreditSubscriptionSearch {
    if (value == null) {
      return undefined;
    }
    return {
      creditStatusList: value.creditStatusList == null ? null : value.creditStatusList
        .map(status => EnumConverterUtils.convertValue(status, CreditStatus)),
      subscriptionSearch: SubscriptionConverter.convertFilterIn(value.subscriptionSearch),
      creditSubscriptionRef: value.creditSubscriptionWsRef,
      creditSearch: CreditConverter.convertFilterIn(value.creditSearch),
    };
  }

  static convertFilterOut(value: CreditSubscriptionSearch): WsCreditSubscriptionSearch {
    if (value == null) {
      return undefined;
    }
    return {
      creditStatusList: value.creditStatusList == null ? null : value.creditStatusList
        .map(status => EnumConverterUtils.convertValue(status, WsCreditStatus)),
      subscriptionSearch: SubscriptionConverter.convertFilterOut(value.subscriptionSearch),
      creditSubscriptionWsRef: value.creditSubscriptionRef,
      creditSearch: CreditConverter.convertFilterOut(value.creditSearch),
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsCreditSubscription]: keyof CreditSubscription } {
    return {
      id: 'id',
      creditWsRef: 'creditRef',
      wsCreditStatus: 'creditStatus',
      subscriptionWsRef: 'subscriptionRef',
    };
  }

}
