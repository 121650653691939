import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, forkJoin, Observable, of, timer} from 'rxjs';
import {
  Contact,
  CustomerService,
  Ref,
  SearchResult,
  SigningPackage,
  SigningPackageService,
  SimplePagination,
  SimplePaginationUtils,
  SortOrder,
  Ticket,
  TicketInsuranceMandateRequest,
  TicketInsuranceMandateRequestSearch,
  TicketInsuranceMandateRequestService,
  TicketSearch,
  TicketService,
  TicketSigningPackage,
  TicketSigningPackageSearch,
  TicketSigningPackageService,
  TicketStatus,
  UserAuthService,
} from '@lifeislife/lifeislife-domain';
import {filter, map, publishReplay, refCount, switchMap, take, tap, throwIfEmpty} from 'rxjs/operators';

@Injectable()
export class TicketInsuranceMandateRequestsHelperService {

  private reloadSource$ = new BehaviorSubject<any>(null);
  private filter$ = new BehaviorSubject<TicketInsuranceMandateRequestSearch>(null);

  pagination$ = new BehaviorSubject<SimplePagination>(SimplePaginationUtils.newPagination(10, {
    field: 'ID',
    order: SortOrder.ASCENDING,
  }));
  loading$ = new BehaviorSubject<boolean>(false);

  results$: Observable<SearchResult<TicketInsuranceMandateRequest>>;
  ticketInsuranceMandateRequests$: Observable<TicketInsuranceMandateRequest[]>;
  totalCount$: Observable<number>;

  constructor(
    private ticketInsuranceMandateRequestsService: TicketInsuranceMandateRequestService,
    private ticketService: TicketService,
    private ticketSigningPackageService: TicketSigningPackageService,
    private signingPackageService: SigningPackageService,
    private customerService: CustomerService,
    private userAuthService: UserAuthService,
  ) {
    this.results$ = combineLatest([this.filter$, this.pagination$, this.reloadSource$]).pipe(
      switchMap(r => this.search$(r[0], r[1])),
      publishReplay(1), refCount(),
    );
    this.totalCount$ = this.results$.pipe(
      map(r => r == null ? 0 : r.count),
      publishReplay(1), refCount(),
    );
    this.ticketInsuranceMandateRequests$ = this.results$.pipe(
      map(r => r == null ? [] : r.list),
      publishReplay(1), refCount(),
    );
  }

  clear() {
    this.filter$.next(null);
  }

  initForTicketRef(ticketRef: Ref<Ticket>) {
    if (ticketRef == null) {
      return;
    }

    this.createFilter$(ticketRef)
      .subscribe(search => this.filter$.next(search));
  }

  reload() {
    this.reloadSource$.next(true);
  }

  setPagination(pagination: SimplePagination) {
    this.pagination$.next(pagination);
  }

  getPagination$() {
    return this.pagination$;
  }

  pollForTicketSigningPackage$(ticket: Ticket): Observable<SigningPackage> {
    if (ticket == null) {
      return of(null);
    }
    return timer(500, 1000).pipe(
      take(30),
      switchMap(() => this.userAuthService.state$.pipe(take(1))),
      filter(s => s != null && s.contactRef != null),
      map(s => s.contactRef),
      switchMap((s) => this.fetchSigningPackageAndTicket$(s, ticket)),
      switchMap(r => this.getSigningPackageCreated$(r[0], r[1])),
      filter(s => s != null),
      take(1),
      throwIfEmpty(() => new Error(`Aucune liasse de document à signer`)),
    );
  }

  private getSigningPackageCreated$(signingPackage: SigningPackage, ticket: Ticket) {
    if (signingPackage != null) {
      return of(signingPackage);
    }
    const ticketStatys = ticket.ticketStatus;
    if (ticketStatys !== TicketStatus.WAITING) {
      throw new Error(`La création des documents a signer à échoué. Veuillez contacter LifeisLife à info@lifeislife.be`);
    }
    return of(null);
  }

  fetchSigningPackageAndTicket$(contactRef: Ref<Contact>, ticket: Ticket): Observable<[SigningPackage, Ticket]> {
    const package$ = this.fetchSigningPackage$(contactRef, ticket);
    const ticket$ = this.ticketService.getTicket$(ticket, true);
    return forkJoin([package$, ticket$]);
  }

  fetchSigningPackage$(contactRef: Ref<Contact>, ticket: Ticket): Observable<SigningPackage> {
    const ticketSigningPackagesSearch: TicketSigningPackageSearch = {
      ticketSearch: {
        exactTicketRef: {id: ticket.id},
        ticketContactSearch: {
          contactSearch: {
            exactContactRef: contactRef,
          },
        },
      } as TicketSearch,
    } as TicketSigningPackageSearch;
    const pagination = SimplePaginationUtils.newPagination(30);
    return this.ticketSigningPackageService.searchTicketSigningPackages$(ticketSigningPackagesSearch, pagination).pipe(
      switchMap(r => r.count === 0 ? of(null) : of(r.list[0])),
      map((t: TicketSigningPackage | null) => t == null ? null : t.signingPackageRef),
      switchMap(ref => ref == null ? of(null) : this.signingPackageService.getSigningPackage$(ref)),
    );
  }

  private search$(searcHFilter: TicketInsuranceMandateRequestSearch, pagination: SimplePagination)
    : Observable<SearchResult<TicketInsuranceMandateRequest>> {
    if (searcHFilter == null || pagination == null) {
      return of(null);
    }
    return timer(100).pipe(
      tap(() => this.loading$.next(true)),
      switchMap(() => this.ticketInsuranceMandateRequestsService.searchTicketInsuranceMandateRequests$(searcHFilter, pagination)),
      tap(() => this.loading$.next(false)),
    );
  }


  private createFilter$(ticketRef: Ref<Ticket>): Observable<TicketInsuranceMandateRequestSearch> {
    const loggedContactRef$ = this.userAuthService.state$.pipe(
      map(s => s.contactRef),
      take(1),
    );
    return forkJoin([loggedContactRef$]).pipe(
      map(r => this.createFilter(r[0], ticketRef)),
    );
  }

  private createFilter(loggedContactRef: Ref<Contact>, ticketRef: Ref<Ticket>): TicketInsuranceMandateRequestSearch {
    const searchFilter: TicketInsuranceMandateRequestSearch = {
      ticketSearch: {
        exactTicketRef: ticketRef,
        ticketContactSearch: {
          contactSearch: {
            exactContactRef: loggedContactRef,
          },
        },
      } as TicketSearch,
    };
    return searchFilter;
  }

}
