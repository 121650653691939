import {Injectable} from '@angular/core';
import {
  WsConstraintViolation,
  WsInsuranceCategoryOptionalFeature,
  WsInsuranceSubscription,
  WsInsuranceSubscriptionGroupSummary,
  WsInsuranceSubscriptionSearch,
  WsInsuranceSubscriptionStatus,
  WsRef,
} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {InsuranceSubscriptionWsClient} from '../../client/resources/front/insurance/insurance-subscription-ws-client';
import {InsuranceSubscriptionConverter} from './insurance-subscription-converter';
import {SubscriptionService} from '../corebusiness/subscription.service';
import {SimplePaginationUtils} from '../../util/pagination/simple-pagination-utils';
import {Subscription} from '../../domain/corebusiness/subscription';
import {Customer} from '../../domain/customer/customer';
import {InsuranceSubscription} from '../../domain/insurance/insurance-subscription';
import {InsuranceSubscriptionSearch} from '../../domain/insurance/insurance-subscription-search';
import {InsuranceSubscriptionStatus} from '../../domain/insurance/insurance-subscription-status';
import {InsuranceSubscriptionStatusWsClient} from '../../client/resources/front/insurance/insurance-status-ws-client';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {EXCEL_MIME_TYPE} from '../../util/file/file-utils';
import {InsuranceSubscriptionGroupSummary} from '../../domain/insurance/insurance-subscription-group-summary';
import {InsuranceSubscriptionGroupSummaryConverter} from './insurance-subscription-group-summary.converter';
import {InsuranceSubscriptionSearchConverter} from './insurance-subscription-search-converter';
import {LabelledField} from '../../domain/labelled-field';
import {InsuranceCategoryOptionalFeature} from '../../domain/insurance/insurance-category-optional-feature';
import {HttpParams} from '@angular/common/http';
import {InsuranceSubscriptionSnapshot} from '../../domain/insurance/insurance-subscription-snapshot';
import {InsuranceSubscriptionSnapshotService} from './insurance-subscription-snapshot.service';
import {InsuranceService} from './insurance.service';
import {SubscriptionConverter} from '../corebusiness/subscription-converter';
import {InsuranceConverter} from './insurance-converter';
import {ProductConverter} from '../corebusiness/product-converter';
import {WsNewInsuranceSubscription} from '@lifeislife/lifeislife-ws-api';
import {Insurance} from '../../domain/insurance/insurance';
import {Product} from '../../domain/corebusiness/product';

@Injectable({
  providedIn: 'root',
})
export class InsuranceSubscriptionService {


  constructor(private client: InsuranceSubscriptionWsClient,
              private statusClient: InsuranceSubscriptionStatusWsClient,
              private subscriptionService: SubscriptionService,
              private insuranceService: InsuranceService,
              private subscriptionSnapshotService: InsuranceSubscriptionSnapshotService,
  ) {
  }

  getInsuranceSubscription$(ref: Ref<InsuranceSubscription>, forceFetch = false): Observable<InsuranceSubscription> {
    return !isValidRef(ref) ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsInsuranceSubscription => InsuranceSubscriptionConverter.convertIn(wsInsuranceSubscription)),
      tap((i) => {
        // Precache related data
        if (i) {
          forkJoin([
            this.subscriptionService.getSubscription$(i.subscriptionRef),
            this.insuranceService.getInsurance$(i.insuranceRef),
          ]).subscribe();
        }
      }),
    );
  }

  getInsuranceSubscriptionOptionalFeatueres$(ref: Ref<InsuranceSubscription>): Observable<LabelledField<InsuranceCategoryOptionalFeature>[]> {
    return !isValidRef(ref) ? of(null) : this.client.getOptionalFeaturesEnabled$(ref).pipe(
      map(list => list.map(ws => EnumConverterUtils.convertWsLabelledField(ws, InsuranceCategoryOptionalFeature))),
    );
  }

  getInsuranceSubscriptionLastImportSnapshot$(ref: Ref<InsuranceSubscription>)
    : Observable<InsuranceSubscriptionSnapshot | null> {
    return !isValidRef(ref) ? of(null) : this.client.getLastImportSnapshotRef$(ref).pipe(
      switchMap(wsSnapshotRef => wsSnapshotRef == null ? of(null) : this.subscriptionSnapshotService.getInsuranceSubscriptionSnapshot$(wsSnapshotRef)),
    );
  }

  isInsuranceSubscriptionFeatureEnabled$(ref: Ref<InsuranceSubscription>, feature: InsuranceCategoryOptionalFeature): Observable<boolean> {
    const testWsvalue = EnumConverterUtils.convertValue(feature, WsInsuranceCategoryOptionalFeature);
    return !isValidRef(ref) ? of(false) : this.client.getOptionalFeaturesEnabled$(ref).pipe(
      map(list => list.find(f => f.field === testWsvalue) != null),
    );
  }

  getInsuranceForProductSubscription$(subscriptionRef: Ref<Subscription>, customerRef: Ref<Customer>): Observable<InsuranceSubscription> {
    const insuranceSearch: InsuranceSubscriptionSearch = {
      subscriptionSearch: {
        subscriptionRef: subscriptionRef,
        customerSearch: {
          exactCustomerRef: customerRef,
        },
      },
    };
    const pagination = SimplePaginationUtils.newPagination(1);
    return this.searchInsuranceSubscriptions$(insuranceSearch, pagination).pipe(
      map(r => r.count === 0 ? null : r.list[0]),
    );
  }

  getInsuranceSubscriptionName$(insuranceSubscription: InsuranceSubscription): Observable<string> {
    if (insuranceSubscription == null || insuranceSubscription.id == null || insuranceSubscription.subscriptionRef == null) {
      return of(`Nouveau contrat d'assurance`);
    }
    return this.subscriptionService.getSubscription$(insuranceSubscription.subscriptionRef).pipe(
      switchMap(s => this.subscriptionService.getSubscriptionName$(s)),
    );
  }

  getInsuranceSubscriptionStatusInternalLabel$(insuranceSubscriptionStatus: InsuranceSubscriptionStatus): Observable<string> {
    if (insuranceSubscriptionStatus == null) {
      return of(null);
    }
    const wsStatus = EnumConverterUtils.convertValue(insuranceSubscriptionStatus, WsInsuranceSubscriptionStatus);
    return this.statusClient.getInternalLabel$(wsStatus);
  }

  getInsuranceSubscriptionStatusPublicLabel$(insuranceSubscriptionStatus: InsuranceSubscriptionStatus): Observable<string> {
    if (insuranceSubscriptionStatus == null) {
      return of(null);
    }
    const wsStatus = EnumConverterUtils.convertValue(insuranceSubscriptionStatus, WsInsuranceSubscriptionStatus);
    return this.statusClient.getPublicLabel$(wsStatus);

  }

  searchInsuranceSubscriptions$(insuranceSubscriptionFilter: InsuranceSubscriptionSearch, pagination: SimplePagination)
    : Observable<SearchResult<InsuranceSubscription>> {
    const wsSearch = InsuranceSubscriptionSearchConverter.convertFilterOut(insuranceSubscriptionFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  searchInsuranceSubscriptionRefs$(insuranceSubscriptionFilter: InsuranceSubscriptionSearch, pagination: SimplePagination)
    : Observable<SearchResult<Ref<InsuranceSubscription>>> {
    const wsSearch = InsuranceSubscriptionSearchConverter.convertFilterOut(insuranceSubscriptionFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      map(results => this.convertRefSearchResultsIn(results)),
    );
  }

  searchSubscriptionsExcelReport$(baseSearch: InsuranceSubscriptionSearch, pagination: SimplePagination): Observable<Blob> {
    const wsSearch = InsuranceSubscriptionSearchConverter.convertFilterOut(baseSearch);
    return this.client.doSearchReport(wsSearch, pagination, EXCEL_MIME_TYPE);
  }

  searchSubscriptionsGroupSummaries$(subscriptionSearch: InsuranceSubscriptionSearch, pagination: SimplePagination)
    : Observable<SearchResult<InsuranceSubscriptionGroupSummary>> {
    const wsSearch = InsuranceSubscriptionSearchConverter.convertFilterOut(subscriptionSearch);
    return this.client.doSearchGroupSummaries(wsSearch, pagination).pipe(
      switchMap(results => this.convertGroupSummariesResultsIn$(results)),
    );
  }

  validateInsuranceSubscription$(insuranceSubscription: InsuranceSubscription): Observable<ValidationResult<InsuranceSubscription>> {
    const wsInsuranceSubscription = InsuranceSubscriptionConverter.convertOut(insuranceSubscription);
    return this.client.doValidate(wsInsuranceSubscription).pipe(
      map((results: WsInsuranceSubscription | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  createInsuranceSubscription$(insuranceSubscription: InsuranceSubscription,
                               subscription: Subscription,
                               insurance: Insurance,
                               product: Product,
                               skipStatusChangeNotifications?: boolean): Observable<Ref<InsuranceSubscription>> {
    const wsInsuranceSubscription = InsuranceSubscriptionConverter.convertOut(insuranceSubscription);
    const wsSubscription = SubscriptionConverter.convertOut(subscription);
    const wsInsurance = InsuranceConverter.convertOut(insurance);
    const wsProdut = ProductConverter.convertOut(product);
    const newInsuranceSubscription: WsNewInsuranceSubscription = {
      insuranceSubscription: wsInsuranceSubscription,
      wsInsurance: wsInsurance,
      wsSubscription: wsSubscription,
      wsProduct: wsProdut,
    };
    return this.client.createInsuranceSubscription$(newInsuranceSubscription, skipStatusChangeNotifications);
  }

  saveInsuranceSubscription$(insuranceSubscription: InsuranceSubscription,
                             skipStatusChangeNotifications?: boolean): Observable<Ref<InsuranceSubscription>> {
    const subscriptioNRef = insuranceSubscription.subscriptionRef;
    const wsInsuranceSubscription = InsuranceSubscriptionConverter.convertOut(insuranceSubscription);
    return this.client.saveInsuranceSubscription$(wsInsuranceSubscription, skipStatusChangeNotifications).pipe(
      // Persisting the insurance subscription modifies the subscription in the backend
      switchMap(ref => this.subscriptionService.refetchSubscription$(subscriptioNRef).pipe(map(() => ref))),
    );
  }

  deleteInsuranceSubscription$(ref: Ref<InsuranceSubscription>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    const queryparams = new HttpParams()
      .append('force', 'true');
    return this.client.doRemove(ref.id, queryparams);
  }

  serializeFilter(insuranceSubscriptionFilter: InsuranceSubscriptionSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(insuranceSubscriptionFilter);
    const wsInsuranceSearch = InsuranceSubscriptionSearchConverter.convertFilterOut(insuranceSubscriptionFilter);
    return JSON.stringify(wsInsuranceSearch);
  }

  deserializeFilter(valueString: string | null): InsuranceSubscriptionSearch {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsInsuranceSubscriptionSearch = JSON.parse(valueString);
      const filter = InsuranceSubscriptionSearchConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsInsuranceSubscription | WsConstraintViolation[]): ValidationResult<InsuranceSubscription> {
    const nameMappings = InsuranceSubscriptionConverter.getValidationPropertyNameMappings();
    // Append extra mappings for constraint on the backend entity class that uses backend property names
    nameMappings['insuredCustomerContact'] = ['insuredCustomerContactRef'];

    const backendResults = ObjectConverterUtil.createValidationResult<WsInsuranceSubscription, InsuranceSubscription>(results, {
      converter: wsInsuranceSubscription => InsuranceSubscriptionConverter.convertIn(wsInsuranceSubscription),
      propertyNameMappings: nameMappings,
    });

    return backendResults;
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsInsuranceSubscription>>): Observable<SearchResult<InsuranceSubscription>> {
    const rowTasks = results.list.map(ref => this.getInsuranceSubscription$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<InsuranceSubscription>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<InsuranceSubscription>>>{
          list: rows,
        }),
      ));
  }

  private convertRefSearchResultsIn(results: SearchResult<WsRef<WsInsuranceSubscription>>): SearchResult<Ref<InsuranceSubscription>> {
    const newList = results.list.map(r => r as Ref<InsuranceSubscription>);
    const newResults = Object.assign({}, results, <Partial<SearchResult<InsuranceSubscription>>>{
      list: newList,
    });
    return newResults;
  }


  private convertGroupSummariesResultsIn$(results: SearchResult<WsInsuranceSubscriptionGroupSummary>) {
    const groupSummaries = results.list.map(wsSummary => InsuranceSubscriptionGroupSummaryConverter.convertIn(wsSummary));
    const convertedResult = Object.assign({}, results, {
      list: groupSummaries,
    });
    return of(convertedResult);
  }
}
