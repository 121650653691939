import {WsTicketSigningPackage, WsTicketSigningPackageSearch} from '@lifeislife/lifeislife-ws-api';
import {TicketSearchConverter} from './ticket-search.converter';
import {TicketSigningPackage} from '../../domain/ticket/ticket-signing-package';
import {SigningPackageConverter} from '../file/signing-package.converter';
import {TicketSigningPackageSearch} from '../../domain/ticket/ticket-signing-package-search';

export class TicketSigningPackageConverter {

  static convertIn(value: WsTicketSigningPackage): TicketSigningPackage {
    return {
      id: value.id,
      signingPackageRef: value.signingPackageWsRef,
      ticketRef: value.ticketWsRef,
    };
  }

  static convertOut(value: TicketSigningPackage): WsTicketSigningPackage {
    return {
      id: value.id,
      ticketWsRef: value.ticketRef,
      signingPackageWsRef: value.signingPackageRef,
    };
  }


  static convertFilterIn(value?: WsTicketSigningPackageSearch): TicketSigningPackageSearch {
    if (value == null) {
      return undefined;
    }
    return {
      signingPackageSearch: SigningPackageConverter.convertFilterIn(value.wsSigningPackageSearch),
      ticketSearch: TicketSearchConverter.convertFilterIn(value.wsTicketSearch),
    };
  }

  static convertFilterOut(value: TicketSigningPackageSearch): WsTicketSigningPackageSearch {
    if (value == null) {
      return undefined;
    }
    return {
      wsSigningPackageSearch: SigningPackageConverter.convertFilterOut(value.signingPackageSearch),
      wsTicketSearch: TicketSearchConverter.convertFilterOut(value.ticketSearch),
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsTicketSigningPackage]: keyof TicketSigningPackage } {
    return {
      id: 'id',
      signingPackageWsRef: 'signingPackageRef',
      ticketWsRef: 'ticketRef',
    };
  }

}
