<div class="insurance-category-select">
  <p-autoComplete [ngModel]="selectedItem$ | async"
                  (ngModelChange)="onModelChange($event)"
                  field="label"
                  dataKey="value"
                  [placeholder]="placeholder || noSelectionLabel"
                  [suggestions]="suggestions$ | async"
                  [dropdown]="!(readonly || disabled)"
                  [readonly]="readonly || disabled"
                  [autoHighlight]="true"
                  [forceSelection]="!allowStrings"
                  [completeOnFocus]="!disabled && !readonly"
                  [minLength]="1"
                  [appendTo]="appendTo"
                  scrollHeight="400px"
                  (onSelect)="onItemSelect($event)"
                  (onClear)="onItemClear()"
                  (onFocus)="searchAllSuggestions()"
                  (completeMethod)="searchSuggestions($event.query)"
                  (onDropdownClick)="searchAllSuggestions()"
                  [inputStyleClass]="hasError ? 'ng-dirty ng-invalid' : '' "
  >
    <ng-template let-item pTemplate="item">
      <div class="ui-helper-clearfix">
        <llc-insurance-category [ref]="item.insuranceCategory"
                                [showTypeBadge]="showTypeBadge"
                                *ngIf="item.insuranceCategory != null; else noSelectionValue"
        ></llc-insurance-category>
        <ng-template #noSelectionValue>
          {{ item.label }}
        </ng-template>
      </div>
    </ng-template>
  </p-autoComplete>
</div>
