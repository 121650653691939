import {Injectable} from '@angular/core';
import {RequestService} from '../../service/request.service';
import {
  WsDaoEventEntityType,
  WsMessageFlavor,
  WsMessageTemplate,
  WsMessageTemplateSearch,
} from '@lifeislife/lifeislife-ws-api';
import {CachedWsResourceClient} from '../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {AppConfigService} from '../../../service/config/app-config.service';
import {Observable} from 'rxjs';
import {ResourceCacheService} from '../../../util/cache/resource-cache.service';

@Injectable({
  providedIn: 'root',
})
export class MessageTemplateWsClient extends CachedWsResourceClient<WsMessageTemplate, WsMessageTemplate> {

  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private injectedAppConfigService: AppConfigService) {
    super('/front/messageTemplate', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.MESSAGE_TEMPLATE, injectectedCacheService);
  }

  searchMessageTemplate$(search: WsMessageTemplateSearch): Observable<WsMessageTemplate> {
    const url = `${this.getResourceUrl()}/search`;
    return this.requestService.sendRequest<WsMessageTemplate>({
      method: 'POST', url: url, body: search,
    }, this.authService.getAuth());
  }


  searchDefaultMessageTemplate$(search: WsMessageTemplateSearch): Observable<WsMessageTemplate> {
    const url = `${this.getResourceUrl()}/search/default`;
    return this.requestService.sendRequest<WsMessageTemplate>({
      method: 'POST', url: url, body: search,
    }, this.authService.getAuth());
  }

}
