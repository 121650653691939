import {WsTrusteeSearch, WsTrusteeStatus} from '@lifeislife/lifeislife-ws-api';
import {TrusteeSearch} from '../../domain/trustee/trustee-search';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {TrusteeStatus} from '../../domain/trustee/trustee-status';
import {ContactSearchConverter} from '../contact/contact-search.converter';

export class TrusteeSearchConverter {


  static toTrusteeSearch(value: WsTrusteeSearch) {
    if (value == null) {
      return undefined;
    }
    return {
      exactTrusteeRef: value.exactTrusteeRef,
      name: value.name,
      configKey: value.configKey,

      nameContains: value.nameContains,
      relatedContactRef: value.relatedContactRef,
      contactSearch: ContactSearchConverter.toContactSearch(value.contactSearch),
      vatNumberContains: value.vatNumberContains,
      exactVatNumber: value.exactVatNumber,
      exactLegalName: value.exactLegalName,
      webSiteContains: value.webSiteContains,
      minFeeRatio: value.minFeeRatio,
      maxFeeRatio: value.maxFeeRatio,
      statusList: value.statusList == null ? [] : value.statusList.map(
        wsStatus => EnumConverterUtils.convertValue(wsStatus, TrusteeStatus),
      ),
      minCreationDateTime: DateConverterUtils.parseIsoDateTime(value.minCreationDateTime),
      maxCreationDateTime: DateConverterUtils.parseIsoDateTime(value.maxCreationDateTime),
      minUpdateDateTime: DateConverterUtils.parseIsoDateTime(value.minUpdateDateTime),
      maxUpdateDateTime: DateConverterUtils.parseIsoDateTime(value.maxUpdateDateTime),
      template: value.template,
    };
  }

  static toWsTrusteeSearch(value: TrusteeSearch): WsTrusteeSearch {
    if (value == null) {
      return undefined;
    }
    return {
      exactTrusteeRef: value.exactTrusteeRef,
      name: value.name,
      configKey: value.configKey,

      nameContains: value.nameContains,
      relatedContactRef: value.relatedContactRef,
      contactSearch: ContactSearchConverter.toWsContactSearch(value.contactSearch),
      vatNumberContains: value.vatNumberContains,
      exactVatNumber: value.exactVatNumber,
      exactLegalName: value.exactLegalName,
      webSiteContains: value.webSiteContains,
      minFeeRatio: value.minFeeRatio,
      maxFeeRatio: value.maxFeeRatio,
      statusList: value.statusList == null ? [] : value.statusList.map(
        wsStatus => EnumConverterUtils.convertValue(wsStatus, WsTrusteeStatus),
      ),
      minCreationDateTime: DateConverterUtils.formatDateToIsoDateTime(value.minCreationDateTime),
      maxCreationDateTime: DateConverterUtils.formatDateToIsoDateTime(value.maxCreationDateTime),
      minUpdateDateTime: DateConverterUtils.formatDateToIsoDateTime(value.minUpdateDateTime),
      maxUpdateDateTime: DateConverterUtils.formatDateToIsoDateTime(value.maxUpdateDateTime),
      template: value.template,
    };
  }


}
