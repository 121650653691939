import {
  WsCareerDetailsType,
  WsCareerPeriodDetails,
  WsEmployeeCareerPeriodDetails,
  WsSelfEmployedCareerPeriodDetails,
} from '@lifeislife/lifeislife-ws-api';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {CareerPeriodDetails} from '../../domain/retire/career-period-details';
import {CareerDetailsTypeConverter} from './career-details-type-converter';
import {SelfEmployedCareerPeriodDetails} from '../../domain/retire/self-employed-career-period-details';
import {EmployeeCareerPeriodDetails} from '../../domain/retire/employee-career-period-details';

export class CareerPeriodDetailsConverter {

  static convertAnyIn<T extends WsCareerPeriodDetails>(wsCareerPeriodDetails: T): CareerPeriodDetails
    | EmployeeCareerPeriodDetails | SelfEmployedCareerPeriodDetails {
    if (wsCareerPeriodDetails == null) {
      return null;
    }
    if (wsCareerPeriodDetails.careerType === WsCareerDetailsType.EMPLOYEE) {
      return this.convertEmployeeIn(wsCareerPeriodDetails as unknown as WsEmployeeCareerPeriodDetails);
    } else if (wsCareerPeriodDetails.careerType === WsCareerDetailsType.SELF_EMPLOYED) {
      return this.convertSelfEmployedIn(wsCareerPeriodDetails as unknown as WsSelfEmployedCareerPeriodDetails);
    } else {
      return this.convertIn(wsCareerPeriodDetails);
    }
  }

  static convertIn(wsCareerPeriodDetails: WsCareerPeriodDetails): CareerPeriodDetails {
    if (wsCareerPeriodDetails == null) {
      return null;
    }
    return {
      asStated: wsCareerPeriodDetails.asStated,
      careerType: CareerDetailsTypeConverter.convertIn(wsCareerPeriodDetails.careerType),
      creationDate: DateConverterUtils.parseIsoDate(wsCareerPeriodDetails.creationDate),
      duration: wsCareerPeriodDetails.duration,
      id: wsCareerPeriodDetails.id,
      lastUpdateDate: DateConverterUtils.parseIsoDate(wsCareerPeriodDetails.lastUpdateDate),
      pensionCareerStatusRef: wsCareerPeriodDetails.pensionCareerStatusWsRef,
      valid: wsCareerPeriodDetails.valid,
      year: wsCareerPeriodDetails.year,
      yearStart: wsCareerPeriodDetails.yearStart,
    };
  }

  static convertSelfEmployedIn(wsCareerPeriodDetails: WsSelfEmployedCareerPeriodDetails): SelfEmployedCareerPeriodDetails {
    return Object.assign(this.convertIn(wsCareerPeriodDetails), {
      descriptionLabel: wsCareerPeriodDetails.descriptionLabel,
      descriptionProvisionalLabel: wsCareerPeriodDetails.descriptionProvisionalLabel,
      income: wsCareerPeriodDetails.income,
      pensionFund: wsCareerPeriodDetails.pensionFund,
      provisionalIncome: wsCareerPeriodDetails.provisionalIncome,
      quarterPeriod: wsCareerPeriodDetails.quarterPeriod,
    } as SelfEmployedCareerPeriodDetails);
  }

  static convertEmployeeIn(wsCareerPeriodDetails: WsEmployeeCareerPeriodDetails): EmployeeCareerPeriodDetails {
    return Object.assign(this.convertIn(wsCareerPeriodDetails), {
      employerName: wsCareerPeriodDetails.employerName,
      endDate: DateConverterUtils.parseIsoDate(wsCareerPeriodDetails.endDate),
      salary: wsCareerPeriodDetails.salary,
      specialPeriod: wsCareerPeriodDetails.specialPeriod,
      startDate: DateConverterUtils.parseIsoDate(wsCareerPeriodDetails.startDate),
      statusLabel: wsCareerPeriodDetails.statusLabel,
      workDays: wsCareerPeriodDetails.workDays,
    } as EmployeeCareerPeriodDetails);
  }


}
