import {Component, Input, OnInit} from '@angular/core';
import {InsuranceProvider, InsuranceProviderService, Ref, SearchResult} from '@lifeislife/lifeislife-domain';
import {Observable, of} from 'rxjs';
import {publishReplay, refCount, switchMap} from 'rxjs/operators';

@Component({
  selector: 'llc-insurance-partner-icons',
  templateUrl: './insurance-partner-icons.component.html',
  styleUrls: ['./insurance-partner-icons.component.scss'],
})
export class InsurancePartnerIconsComponent implements OnInit {

  @Input()
  showNames = false;
  @Input()
  showIcons = true;
  @Input()
  asLinks = false;

  searcHresults$: Observable<SearchResult<Ref<InsuranceProvider>>>;

  constructor(
    private insuranceProviderService: InsuranceProviderService,
  ) {
  }

  ngOnInit(): void {
    this.searcHresults$ = of(null).pipe(
      switchMap(p => this.insuranceProviderService.searchUnrestrictedProviders$(p)),
      publishReplay(1), refCount(),
    );
  }

}
