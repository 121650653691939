import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {LifeisLifeLogEntryWsClient} from '../../client/resources/front/log/lifeislife-log-entry-ws-client';
import {LifeisLifeLogEntryConverter} from './lifeislife-log-entry.converter';
import {LifeislifeLogEntrySearchConverter} from './lifeislife-log-entry-search.converter';
import {LifeislifeLogEntry} from '../../domain/log/lifeislife-log-entry';
import {LifeislifeLogEntrySearch} from '../../domain/log/lifeislife-log-entry-search';
import {WsConstraintViolation, WsLifeisLifeLogEntry, WsRef} from '@lifeislife/lifeislife-ws-api';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {Ref} from '../../domain/shared/ref';

@Injectable({
  providedIn: 'root',
})
export class LifeisLifeLogEntryService {


  constructor(private client: LifeisLifeLogEntryWsClient,
  ) {
  }

  getLifeisLifeLogEntry$(id: number, forceFetch?: boolean): Observable<LifeislifeLogEntry> {
    return this.client.doGet(id, forceFetch).pipe(
      map(wsLifeisLifeLogEntry => LifeisLifeLogEntryConverter.convertIn(wsLifeisLifeLogEntry)),
    );
  }

  saveLifeislifeLogEntry$(lifeislifeLogEntry: LifeislifeLogEntry): Observable<Ref<LifeislifeLogEntry>> {
    const wsLifeislifeLogEntry = LifeisLifeLogEntryConverter.convertOut(lifeislifeLogEntry);
    return this.client.doSave(wsLifeislifeLogEntry);
  }

  deleteLifeislifeLogEntry$(ref: Ref<LifeislifeLogEntry>): Observable<any> {
    return this.client.doRemove(ref.id);
  }

  searchLifeisLifeLogEntrys$(lifeisLifeLogEntryFilter: LifeislifeLogEntrySearch, pagination: SimplePagination)
    : Observable<SearchResult<LifeislifeLogEntry>> {
    const wsSearch = LifeislifeLogEntrySearchConverter.convertOut(lifeisLifeLogEntryFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateLifeislifeLogEntry$(lifeislifeLogEntry: LifeislifeLogEntry): Observable<ValidationResult<LifeislifeLogEntry>> {
    const wsLifeislifeLogEntry = LifeisLifeLogEntryConverter.convertOut(lifeislifeLogEntry);
    return this.client.doValidate(wsLifeislifeLogEntry).pipe(
      map((results: WsLifeisLifeLogEntry | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  serializeFilter(notificationFilter: LifeislifeLogEntrySearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(notificationFilter);
    const wsNotificationSearch = LifeislifeLogEntrySearchConverter.convertOut(notificationFilter);
    return JSON.stringify(wsNotificationSearch);
  }


  deserializeFilter(valueString: string | null): LifeislifeLogEntrySearch {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: any = JSON.parse(valueString);
      const filter = LifeislifeLogEntrySearchConverter.convertIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsLifeisLifeLogEntry>>): Observable<SearchResult<LifeislifeLogEntry>> {
    const rowTasks = results.list.map(ref => this.getLifeisLifeLogEntry$(ref.id));
    return rowTasks.length === 0 ? of(new SearchResult<LifeislifeLogEntry>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<LifeislifeLogEntry>>>{
          list: rows,
        }),
      ));
  }

  private createValidationResult(results: WsLifeisLifeLogEntry | WsConstraintViolation[]): ValidationResult<LifeislifeLogEntry> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsNotification => LifeisLifeLogEntryConverter.convertIn(wsNotification),
      propertyNameMappings: LifeisLifeLogEntryConverter.getValidationPropertyNameMappings(),
    });
  }

}
