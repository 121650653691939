export enum CustomerContactField {

  ID = 'ID',
  MAIL_CONTACT_TYPE = 'MAIL_CONTACT_TYPE',
  POSITION = 'POSITION',
  CUSTOMER = 'CUSTOMER',

  COMPANY = 'COMPANY',
  LAST_NAME = 'LAST_NAME',
  FIRST_NAME = 'FIRST_NAME',
  PHONE1 = 'PHONE1',
  PHONE2 = 'PHONE2',
  MOBILE = 'MOBILE',
  FAX = 'FAX',
  EMAIL = 'EMAIL',
  ZIP = 'ZIP',
  CITY = 'CITY',
  ADDRESS1 = 'ADDRESS1',
  ADDRESS2 = 'ADDRESS2',
  DOMAIN = 'DOMAIN',
  PERSON_TITLE = 'PERSON_TITLE',
  SEND_EMAIL = 'SEND_EMAIL',
  SEND_SMS = 'SEND_SMS_TITLE',
  BIRTH_PLACE = 'BIRTH_PLACE',
  BIRTH_DATE = 'BIRTH_DATE',
  ID_EXPIRY_DATE = 'ID_EXPIRY_DATE',
  ID_CARD_NUMBER = 'ID_CARD_NUMBER',
  COUNTRY = 'COUNTRY',
  TRUSTEE = 'TRUSTEE'
}
