import {WsTicketInsuranceMandateRequestSearch} from '@lifeislife/lifeislife-ws-api';
import {TicketInsuranceMandateRequestSearch} from '../../domain/ticket/ticket-insurance-mandate-request-search';
import {SubscriptionConverter} from '../corebusiness/subscription-converter';
import {InsuranceProviderConverter} from '../insurance/insurance-provider-converter';
import {InsuranceCategoryConverter} from '../insurance/insurance-category-converter';
import {TicketSearchConverter} from './ticket-search.converter';


export class TicketInsuranceMandateRequetsSearchConverter {

  static convertIn(value: WsTicketInsuranceMandateRequestSearch): TicketInsuranceMandateRequestSearch {
    if (value == null) {
      return undefined;
    }
    return {
      exactProviderReference: value.exactProviderReference,
      insuranceCategorySearch: InsuranceCategoryConverter.convertFilterIn(value.insuranceCategorySearch),
      insuranceProviderSearch: InsuranceProviderConverter.convertFilterIn(value.insuranceProviderSearch),
      ticketSearch: TicketSearchConverter.convertFilterIn(value.ticketSearch),
      subscriptionSearch: SubscriptionConverter.convertFilterIn(value.wsSubscriptionSearch),
    };
  }

  static convertOut(value: TicketInsuranceMandateRequestSearch): WsTicketInsuranceMandateRequestSearch {
    if (value == null) {
      return undefined;
    }
    return {
      exactProviderReference: value.exactProviderReference,
      insuranceCategorySearch: InsuranceCategoryConverter.convertFilterOut(value.insuranceCategorySearch),
      insuranceProviderSearch: InsuranceProviderConverter.convertFilterOut(value.insuranceProviderSearch),
      ticketSearch: TicketSearchConverter.convertFilterOut(value.ticketSearch),
      wsSubscriptionSearch: SubscriptionConverter.convertFilterOut(value.subscriptionSearch),
    };
  }

}
