import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrusteeDropdownSelectComponent} from './trustee-dropdown-select.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [TrusteeDropdownSelectComponent],
  exports: [TrusteeDropdownSelectComponent],
  imports: [
    CommonModule,
    AutoCompleteModule,
    FormsModule,
  ],
})
export class TrusteeDropdownSelectModule {
}
