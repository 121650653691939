import {WsFileSearch, WsStoredFile} from '@lifeislife/lifeislife-ws-api';
import {StoredFile} from '../../domain/file/stored-file';
import {StoredFileSearch} from '../../domain/file/stored-file-search';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {CustomerSearchConverter} from '../customer/customer-search.converter';

export class StoredFileConverter {

  static convertIn(value: WsStoredFile): StoredFile {
    return {
      id: value.id,
      fileName: value.fileName,
      mimeType: value.mimeType,
      size: value.size,
      deleted: value.deleted,
      creationDateTime: DateConverterUtils.parseIsoDateTime(value.creationDateTime),
      updateDateTime: DateConverterUtils.parseIsoDateTime(value.updateDateTime),
      signer: value.signer,
    };
  }


  static convertOut(value: StoredFile): WsStoredFile {
    return {
      id: value.id,
      fileName: value.fileName,
      mimeType: value.mimeType,
      size: value.size,
      deleted: value.deleted,
      creationDateTime: DateConverterUtils.formatDateToIsoDateTime(value.creationDateTime),
      updateDateTime: DateConverterUtils.formatDateToIsoDateTime(value.updateDateTime),
      signer: value.signer,
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsStoredFile]: keyof StoredFile } {
    return {
      id: 'id',
      fileName: 'fileName',
      mimeType: 'mimeType',
      size: 'size',
      deleted: 'deleted',
      creationDateTime: 'creationDateTime',
      updateDateTime: 'updateDateTime',
      signer: 'signer',
    };
  }

  static convertFilterIn(value: WsFileSearch): StoredFileSearch {
    if (value == null) {
      return undefined;
    }
    return {
      trusteeRef: value.trusteeWsRef,
      storedFileRef: value.storedFileWsRef,
      deleted: value.deleted,
      nameContains: value.nameContains,
      name: value.name,
      customerSearch: CustomerSearchConverter.toCustomerSearch(value.wsCustomerSearch),
    };
  }


  static convertFilterOut(value: StoredFileSearch): WsFileSearch {
    if (value == null) {
      return undefined;
    }
    return {
      trusteeWsRef: value.trusteeRef,
      storedFileWsRef: value.storedFileRef,
      deleted: value.deleted,
      nameContains: value.nameContains,
      name: value.name,
      wsCustomerSearch: CustomerSearchConverter.toWsCustomerSearch(value.customerSearch),
    };
  }
}
