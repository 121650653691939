<div class="insurance-category"
     [class.wrap]="wrap"
     [class.nowraptext]="!wrapText">
  <span class="label" *ngIf="labelType === 'public'">{{ publicLabel$ | async }}</span>
  <span class="label" *ngIf="labelType === 'internal'">{{ label$ | async }}</span>

  <span class="family-badge badge" *ngIf="showFamilyBadge">
    <llc-insurance-category-family [family]="family$ | async"
                                   [badge]="true"
    ></llc-insurance-category-family>
  </span>

  <span class="type-badge badge" *ngIf="showTypeBadge">
    <llc-insurance-type [type]="type$ | async"
                        [badge]="true"
    ></llc-insurance-type>
  </span>

</div>
