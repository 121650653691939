import {Component, Input, OnInit} from '@angular/core';
import {User, UserService, Ref} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {

  private refSource$ = new BehaviorSubject<Ref<User>>(null);

  @Input()
  set ref(value: Ref<User>) {
    this.refSource$.next(value);
  }

  userLogin$: Observable<string>;

  constructor(
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.userLogin$ = this.refSource$.pipe(
      switchMap(ref => this.fetchUserLogin$(ref)),
      publishReplay(1), refCount(),
    );
  }

  private fetchUserLogin$(ref: Ref<User>): Observable<string> {
    if (ref == null) {
      return of(`Invité`);
    }
    return this.userService.getUser$(ref).pipe(
      map(user => user.login),
    );
  }
}
