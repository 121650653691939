import {WsCustomerTypeSearch} from '@lifeislife/lifeislife-ws-api';
import {CustomerTypeSearch} from '../../domain/customer/customer-type-search';

export class CustomerTypeSearchConverter {

  static toCustomerTypeSearch(value: WsCustomerTypeSearch) {
    if (value == null) {
      return undefined;
    }
    return {
      anyCustomerTypeRefList: value.anyCustomerTypeRefList,
      nameContains: value.nameContains,
    };
  }

  static toWsCustomerTypeSearch(value: CustomerTypeSearch) {
    if (value == null) {
      return undefined;
    }
    return {
      anyCustomerTypeRefList: value.anyCustomerTypeRefList,
      nameContains: value.nameContains,
    };
  }


}
