import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrusteeLogoComponent} from './trustee-logo.component';

/**
 * Needs assets put in the right location in the app build process!
 */

@NgModule({
  declarations: [TrusteeLogoComponent],
  imports: [
    CommonModule,
  ],
  exports: [TrusteeLogoComponent],
})
export class TrusteeLogoModule {
}
