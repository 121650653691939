import {ResourceCache} from './resource-cache';
import {CacheResourceType} from './cache-resource-type';
import {Observable, of, throwError} from 'rxjs';
import {WithId} from '../../client/domain/with-id';
import {Injectable} from '@angular/core';

@Injectable()
export class NoopResourceCache implements ResourceCache {

  cacheValue<T extends WithId>(type: CacheResourceType, id: number, value: T): void {
  }

  cacheRequest$<T extends WithId>(type: CacheResourceType, id: number, request$: Observable<T>): Observable<T> {
    return request$;
  }

  getCachedValue$<T extends WithId>(type: CacheResourceType, id: number): Observable<T> {
    return throwError(`No cached value`);
  }

  hasCachedValue$(type: CacheResourceType, id: number): Observable<boolean> {
    return of(false);
  }

  clear(type: CacheResourceType, id: number): void {
  }

  clearAll(type: CacheResourceType): void {
  }

  isSkipped(type: CacheResourceType): boolean {
    return true;
  }

  skip(type: CacheResourceType): void {
  }

  unskip(type: CacheResourceType): void {
  }

}
