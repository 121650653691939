import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PhoneNumberService} from '../phone-number.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-simple-phone-number-input',
  templateUrl: './simple-phone-number-input.component.html',
  styleUrls: ['./simple-phone-number-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SimplePhoneNumberInputComponent),
    multi: true,
  }],
})
export class SimplePhoneNumberInputComponent implements OnInit, ControlValueAccessor {

  @Input()
  disabled = false;
  @Input()
  hasError = false;

  phoneNumber = new BehaviorSubject<string>(null);

  private onChangeFunction: Function;
  private onTouchedFunction: Function;

  constructor(private phoneNumberService: PhoneNumberService) {
  }

  ngOnInit() {
  }

  writeValue(value: string): void {
    const parsedNumber = this.phoneNumberService.parseSimplePhoneNumber(value);
    this.phoneNumber.next(parsedNumber);
  }

  registerOnChange(fn: any): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFunction = fn;
  }

  setDisabledState(value: boolean) {
    this.disabled = value;
  }

  onNumberChanged(value: string) {
    this.phoneNumber.next(value);
    this.fireChange(value);
  }

  private fireChange(value: string) {
    if (this.onTouchedFunction) {
      this.onTouchedFunction();
    }
    if (this.onChangeFunction) {
      this.onChangeFunction(value);
    }
  }
}
