<div class="contact-form" *ngIf="contact != null" #formDiv>

  <div class="col form-table">

    <!--    <div class="row" [class.changed]="propertyDiff?.personTitleRef" hidden>-->
    <!--      <label >-->
    <!--        Civilité-->
    <!--      </label>-->
    <!--      <div>-->
    <!--        <app-person-title-select name="person-title"-->
    <!--                                 [(ngModel)]="contact.personTitleRef"-->
    <!--                                 [trusteeRef]="trusteeRef"-->
    <!--                                 [disabled]="disabled"-->
    <!--                                 [hasError]="validationResults?.propertiesErrors.personTitleRef != null"-->
    <!--        ></app-person-title-select>-->
    <!--        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.personTitleRef"-->
    <!--        ></validation-error-message>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="row" [class.changed]="propertyDiff?.firstName">
      <label>
        Prénom
      </label>
      <div>
        <div class="with-badge">
          <input type="text" pInputText
                 name="firstName"
                 class="fill"
                 autocomplete="nope"
                 [readonly]="disabled"
                 [(ngModel)]="contact.firstName"
                 (ngModelChange)="fireChanges()"
                 [class.p-state-error]="validationResults?.propertiesErrors.firstName != null"
          >
          <span class="badge severity-colored  firstname-claim"
                *ngIf="showClaimIcons && namesClaimNullable && namesClaimNullable.value">
            <i class="fas fa-user-check" title="Les noms ont été vérifiés"
               (click)="firstNamePanel.toggle($event)"
            ></i>
            <p-overlayPanel #firstNamePanel appendTo="body">
              <p *ngIf="namesClaimNullable.description">
                Raison: {{ namesClaimNullable.description }}
              </p>
              <p>
                Valeur: {{ namesClaimNullable.value }}
              </p>
              <p>
                Date: {{ namesClaimNullable.claimDateTime | cDateTime }}
              </p>
            </p-overlayPanel>
          </span>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.firstName"
        ></validation-error-message>
      </div>
    </div>

    <div class="row" [class.changed]="propertyDiff?.lastName">
      <label>
        Nom
      </label>
      <div>
        <div class="with-badge">
          <input type="text" pInputText
                 name="lastName"
                 class="fill"
                 autocomplete="nope"
                 [readonly]="disabled"
                 [(ngModel)]="contact.lastName"
                 (ngModelChange)="fireChanges()"
                 [class.p-state-error]="validationResults?.propertiesErrors.lastName != null"
          >
          <span class="badge severity-colored  lastname-claim"
                *ngIf="showClaimIcons && namesClaimNullable && namesClaimNullable.value">
            <i class="fas fa-user-check" title="Les noms ont été vérifiés"
               (click)="lastNamePanel.toggle($event)"
            ></i>
            <p-overlayPanel #lastNamePanel appendTo="body">
              <p *ngIf="namesClaimNullable.description">
                Raison: {{ namesClaimNullable.description }}
              </p>
              <p>
                Valeur: {{ namesClaimNullable.value }}
              </p>
              <p>
                Date: {{ namesClaimNullable.claimDateTime | cDateTime }}
              </p>
            </p-overlayPanel>
          </span>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.lastName"
        ></validation-error-message>
      </div>
    </div>

    <div class="row" [class.changed]="propertyDiff?.email">
      <label>
        Email
      </label>
      <div>
        <div class="with-badge">
          <input type="text" pInputText
                 name="email"
                 class="fill"
                 autocomplete="nope"
                 [readonly]="disabled"
                 [(ngModel)]="contact.email"
                 (ngModelChange)="fireChanges()"
                 [class.ui-state-erro]="validationResults?.propertiesErrors.email != null"
          >
          <span class="badge severity-colored  email-claim"
                *ngIf="showClaimIcons && emailClaimNullable && emailClaimNullable.value">
            <i class="fas fa-user-check" title="L'addresse email a été vérifiée"
               [class.error]="emailClaimNullable.value !== contact.email"
               (click)="emailClaimNullablePanel.toggle($event)"
            ></i>
            <p-overlayPanel #emailClaimNullablePanel appendTo="body">
              <p *ngIf="emailClaimNullable.description">
                Raison: {{ emailClaimNullable.description }}
              </p>
              <p>
                Valeur: {{ emailClaimNullable.value }}
              </p>
              <p>
                Date: {{ emailClaimNullable.claimDateTime | cDateTime }}
              </p>
            </p-overlayPanel>
          </span>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.email"
        ></validation-error-message>
      </div>
    </div>

    <div class="row" [class.changed]="propertyDiff?.mobile">
      <label>
        GSM
      </label>
      <div>
        <div class="with-badge">
          <phone-number-input
            name="mobile"
            autocomplete="nope"
            [disabled]="disabled"
            [(ngModel)]="contact.mobile"
            (ngModelChange)="fireChanges()"
            [hasError]="validationResults?.propertiesErrors.mobile != null"
          ></phone-number-input>
          <span class="badge severity-colored  mobile-claim"
                *ngIf="showClaimIcons && mobileClaimNullable && mobileClaimNullable.value">
            <i class="fas fa-user-check" title="Le numéro de GSM a été vérifié"
               [class.error]="mobileClaimNullable.value !== contact.mobile"
               (click)="mobileClaimNullablePanel.toggle($event)"
            ></i>
            <p-overlayPanel #mobileClaimNullablePanel appendTo="body">
              <p *ngIf="mobileClaimNullable.description">
                Raison: {{ mobileClaimNullable.description }}
              </p>
              <p>
                Valeur: {{ mobileClaimNullable.value }}
              </p>
              <p>
                Date: {{ mobileClaimNullable.claimDateTime | cDateTime }}
              </p>
            </p-overlayPanel>
          </span>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.mobile"
        ></validation-error-message>
      </div>
    </div>

    <div class="row" [class.changed]="propertyDiff?.phone1" *ngIf="showTelBlock">
      <label>
        Tél. 1
      </label>
      <div>
        <div>
          <phone-number-input
            name="phone1"
            [disabled]="disabled"
            [(ngModel)]="contact.phone1"
            (ngModelChange)="fireChanges()"
            [hasError]="validationResults?.propertiesErrors.phone1 != null"
          ></phone-number-input>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.phone1"
        ></validation-error-message>
      </div>
    </div>

    <div class="row" [class.changed]="propertyDiff?.phone2" *ngIf="showTelBlock">
      <label>
        Tél. 2
      </label>
      <div>
        <div>
          <phone-number-input
            name="phone2"
            [disabled]="disabled"
            [(ngModel)]="contact.phone2"
            (ngModelChange)="fireChanges()"
            [hasError]="validationResults?.propertiesErrors.phone2 != null"
          ></phone-number-input>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.phone2"
        ></validation-error-message>
      </div>
    </div>

    <div class="row" [class.changed]="propertyDiff?.fax" *ngIf="showFax">
      <label>
        Fax
      </label>
      <div>
        <div>
          <phone-number-input
            name="fax"
            [disabled]="disabled"
            [(ngModel)]="contact.fax"
            (ngModelChange)="fireChanges()"
            [hasError]="validationResults?.propertiesErrors.fax != null"
          ></phone-number-input>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.fax"
        ></validation-error-message>
      </div>
    </div>

    <div class="row" [class.changed]="propertyDiff?.address1" *ngIf="showAddressBlock">
      <label>
        Adresse 1
      </label>
      <div>
        <div>
          <input type="text" pInputText
                 name="address1"
                 class="fill"
                 autocomplete="nope"
                 [readonly]="disabled"
                 [(ngModel)]="contact.address1"
                 (ngModelChange)="fireChanges()"
                 [class.ui-state-erro]="validationResults?.propertiesErrors.address1 != null"
          >
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.address1"
        ></validation-error-message>
      </div>
    </div>


    <div class="row" [class.changed]="propertyDiff?.address2" *ngIf="showAddressBlock">
      <label>
        Adresse 2
      </label>
      <div>
        <div>
          <input type="text" pInputText
                 name="address2"
                 class="fill"
                 autocomplete="nope"
                 [readonly]="disabled"
                 [(ngModel)]="contact.address2"
                 (ngModelChange)="fireChanges()"
                 [class.ui-state-erro]="validationResults?.propertiesErrors.address2 != null"
          >
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.address2"
        ></validation-error-message>
      </div>
    </div>


    <div class="row" [class.changed]="propertyDiff?.zip" *ngIf="showAddressBlock">
      <label>
        Code postal
      </label>
      <div>
        <div>
          <input type="text" pInputText
                 name="zip"
                 class="fill"
                 autocomplete="nope"
                 [readonly]="disabled"
                 [(ngModel)]="contact.zip"
                 (ngModelChange)="fireChanges()"
                 [class.ui-state-erro]="validationResults?.propertiesErrors.zip != null"
                 [size]="8"
          >
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.zip"
        ></validation-error-message>
      </div>
    </div>

    <div class="row" [class.changed]="propertyDiff?.city" *ngIf="showAddressBlock">
      <label>
        Ville
      </label>
      <div>
        <div>
          <input type="text" pInputText
                 name="city"
                 class="fill"
                 autocomplete="nope"
                 [readonly]="disabled"
                 [(ngModel)]="contact.city"
                 (ngModelChange)="fireChanges()"
                 [class.ui-state-erro]="validationResults?.propertiesErrors.city != null"
          >
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.city"
        ></validation-error-message>
      </div>
    </div>


    <div class="row" [class.changed]="propertyDiff?.countryRef" *ngIf="showAddressBlock">
      <label>
        Pays
      </label>
      <div>
        <div>
          <app-country-select name="country"
                              [(ngModel)]="contact.countryRef"
                              (ngModelChange)="fireChanges()"
                              [disabled]="disabled"
                              [hasError]="validationResults?.propertiesErrors.countryRef != null"
          ></app-country-select>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.countryRef"
        ></validation-error-message>
      </div>
    </div>

  </div>


  <div class="col form-table">

    <div class="row" [class.changed]="propertyDiff?.domain" *ngIf="showDomain">
      <label>
        Domaine
      </label>
      <div>
        <div>
          <input type="text" pInputText
                 name="domain"
                 class="fill"
                 autocomplete="nope"
                 [readonly]="disabled"
                 [(ngModel)]="contact.domain"
                 (ngModelChange)="fireChanges()"
                 [class.ui-state-erro]="validationResults?.propertiesErrors.domain != null"
          >
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.domain"
        ></validation-error-message>
      </div>
    </div>

    <div class="row communication-row" [class.changed]="propertyDiff?.sendEmail || propertyDiff?.sendSms"
         *ngIf="showSettingsBlock">
      <label>
        Préférences de communication
      </label>
      <div>
        <div>
          <label>Email</label>
          <p-inputSwitch
            name="sendEmail"
            [readonly]="disabled"
            [(ngModel)]="contact.sendEmail"
            (ngModelChange)="fireChanges()"
            [class.ui-state-erro]="validationResults?.propertiesErrors.sendEmail != null"
          ></p-inputSwitch>
        </div>
        <div>
          <label>SMS</label>
          <p-inputSwitch
            name="sendSms"
            [readonly]="disabled"
            [(ngModel)]="contact.sendSms"
            (ngModelChange)="fireChanges()"
            [class.ui-state-erro]="validationResults?.propertiesErrors.sendSms != null"
          ></p-inputSwitch>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.sendEmail"
        ></validation-error-message>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.sendSms"
        ></validation-error-message>
      </div>
    </div>

    <div class="row" [class.changed]="propertyDiff?.internal" *ngIf="showInternal">
      <label>
        Interne
      </label>
      <div>
        <div>
          <p-inputSwitch name="internal"
                         [(ngModel)]="contact.internal"
                         (ngModelChange)="fireChanges()"
                         [class.p-state-error]="validationResults?.propertiesErrors.internal != null"
                         [readonly]="disabled"
          ></p-inputSwitch>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.internal"
        ></validation-error-message>
      </div>
    </div>


    <div class="row" [class.changed]="propertyDiff?.birthPlace" *ngIf="showIdBlock">
      <label>
        Lieu de naissance
      </label>
      <div>
        <div>
          <input type="text" pInputText
                 name="birthPlace"
                 class="fill"
                 autocomplete="nope"
                 [readonly]="disabled"
                 [(ngModel)]="contact.birthPlace"
                 (ngModelChange)="fireChanges()"
                 [class.ui-state-erro]="validationResults?.propertiesErrors.birthPlace != null"
          >
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.birthPlace"
        ></validation-error-message>
      </div>
    </div>


    <div class="row" [class.changed]="propertyDiff?.birthDate" *ngIf="showIdBlock">
      <label>
        Date de naissance
      </label>
      <div>
        <div>
          <calendar-input name="birthDate"
                          [(ngModel)]="contact.birthDate"
                          (ngModelChange)="fireChanges()"
                          [hasError]="validationResults?.propertiesErrors.birthDate != null"
                          [minYearDiff]="-100"
                          [maxYearDiff]="1"
                          [disabled]="disabled"
          ></calendar-input>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.birthPlace"
        ></validation-error-message>
      </div>
    </div>


    <div class="row" [class.changed]="propertyDiff?.idCardNumber" *ngIf="showIdBlock">
      <label>
        Numéro de carte d'identité
      </label>
      <div>
        <div>
          <input type="text" pInputText
                 name="idCardNumber"
                 class="fill"
                 autocomplete="nope"
                 [readonly]="disabled"
                 [(ngModel)]="contact.idCardNumber"
                 (ngModelChange)="fireChanges()"
                 [class.ui-state-erro]="validationResults?.propertiesErrors.idCardNumber != null"
          >
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.idCardNumber"
        ></validation-error-message>
      </div>
    </div>


    <div class="row" [class.changed]="propertyDiff?.idExpiryDate" *ngIf="showIdBlock">
      <label>
        Date d'expiration ID
      </label>
      <div>
        <div>
          <calendar-input name="idExpiryDate"
                          [(ngModel)]="contact.idExpiryDate"
                          (ngModelChange)="fireChanges()"
                          [hasError]="validationResults?.propertiesErrors.idExpiryDate != null"
                          [minYearDiff]="-1"
                          [maxYearDiff]="50"
                          [disabled]="disabled"
          ></calendar-input>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.idExpiryDate"
        ></validation-error-message>
      </div>
    </div>

    <div class="row" [class.changed]="propertyDiff?.idDeliveryDate" *ngIf="showIdBlock">
      <label>
        Date de délivrance ID
      </label>
      <div>
        <div>
          <calendar-input name="idDeliveryDate"
                          [(ngModel)]="contact.idDeliveryDate"
                          (ngModelChange)="fireChanges()"
                          [hasError]="validationResults?.propertiesErrors.idDeliveryDate != null"
                          [minYearDiff]="-100"
                          [maxYearDiff]="1"
                          [disabled]="disabled"
          ></calendar-input>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.idDeliveryDate"
        ></validation-error-message>
      </div>
    </div>


    <div class="row" [class.changed]="propertyDiff?.nationalRegisterNumber" *ngIf="showIdBlock">
      <label>
        Numéro de registre national
      </label>
      <div>
        <div class="with-badge">
          <input type="text" pInputText
                 name="idExpiryDate"
                 autocomplete="nope"
                 [(ngModel)]="contact.nationalRegisterNumber"
                 (ngModelChange)="fireChanges()"
                 [class.p-state-error]="validationResults?.propertiesErrors.nationalRegisterNumber != null"
                 [readonly]="disabled"
          >
          <span class="badge  severity-colored ssin-claim"
                *ngIf="showClaimIcons && ssinClaimNullable && ssinClaimNullable && ssinClaimNullable.value">
            <i class="fas fa-user-check" title="Le numéro national a été vérifié"
               [class.error]="ssinClaimNullable.value !== contact.nationalRegisterNumber"
               (click)="ssinClaimNullablePanel.toggle($event)"
            ></i>
            <p-overlayPanel #ssinClaimNullablePanel appendTo="body">
              <p *ngIf="ssinClaimNullable.description">
                Raison: {{ ssinClaimNullable.description }}
              </p>
              <p>
                Valeur: {{ ssinClaimNullable.value }}
              </p>
              <p>
                Date: {{ ssinClaimNullable.claimDateTime | cDateTime }}
              </p>
            </p-overlayPanel>
          </span>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.nationalRegisterNumber"
        ></validation-error-message>
      </div>
    </div>

    <div class="row" [class.changed]="propertyDiff?.maritalStatusRef" *ngIf="showMaritalStatus">
      <label>
        État civil
      </label>
      <div>
        <div>
          <app-marital-status-select name="maritalStatusRef"
                                     [(ngModel)]="contact.maritalStatusRef"
                                     (ngModelChange)="fireChanges()"
                                     [hasError]="validationResults?.propertiesErrors.maritalStatusRef != null"
                                     [disabled]="disabled"
          ></app-marital-status-select>
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.maritalStatusRef"
        ></validation-error-message>
      </div>
    </div>


    <div class="row" [class.changed]="propertyDiff?.company" *ngIf="showCompany">
      <label>
        Compagnie
      </label>
      <div>
        <div>
          <input type="text" pInputText
                 name="company"
                 class="fill"
                 autocomplete="nope"
                 [disabled]="disabled"
                 [(ngModel)]="contact.company"
                 (ngModelChange)="fireChanges()"
                 [class.p-state-error]="validationResults?.propertiesErrors.company != null"
          >
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.company"
        ></validation-error-message>
      </div>
    </div>


    <div class="row" [class.changed]="propertyDiff?.dubiousTrusteeName"
         *ngIf="showDubiousTrusteeName && contact.dubiousTrusteeName"
         title="Le nom de la fiduciaire tel que mentionné par le contact. Elle pourrait ne pas avoir été trouvée dans la base de donnée."
         i18n-title>
      <label>
        Fiduciaire (non vérifiable)
      </label>
      <div>
        <div class="as-label">
          {{ contact.dubiousTrusteeName }}
        </div>
        <validation-error-message [errorMessages]="validationResults?.propertiesErrors.dubiousTrusteeName"
        ></validation-error-message>
      </div>
    </div>

    <div class="row" [class.changed]="propertyDiff?.dubiousMail" *ngIf="showDubiousMail && contact.dubiousMail"
         title="Cet email n'a pas pu être enregistré" i18n-title>
      <label>
        Email (non vérifiable)
      </label>
      <div>
        <div>
          <div class="as-label">
            {{ contact.dubiousMail }}
          </div>
          <validation-error-message [errorMessages]="validationResults?.propertiesErrors.dubiousMail"
          ></validation-error-message>
        </div>
      </div>

      <ng-container *ngIf="showImportDetails && contact.imported">
        <div class="row">
          <label i18n>
            Importé
          </label>
          <div class="field-hint as-label">
            Ce contact a été créé ou mis à jour lors d'une importation en lot
          </div>
        </div>

        <div class="row">
          <label i18n>
            Dernière importation
          </label>
          <div class="field-hint as-label">
            {{ contact.lastImportDateTime | cDateTime }}
          </div>
        </div>
      </ng-container>


    </div>

  </div>
