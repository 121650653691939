import {ActivatedRoute, ActivatedRouteSnapshot, Data} from '@angular/router';
import {distinctUntilChanged, filter, map, publishReplay, refCount} from 'rxjs/operators';
import {combineLatest, concat, merge, Observable, of} from 'rxjs';
import {Ref, SimplePagination, Trustee, WithTrusteeRef, WithId} from '@lifeislife/lifeislife-domain';
import {MenuItem} from 'primeng/api';

export class RouteUtils {

  static getRouteParam(param: string, route: ActivatedRoute): Observable<any> {
    const fragmentParams = route.fragment
      .pipe(
        map(f => RouteUtils.parseFragmentQueryParams(f)),
        filter(params => params != null),
        map(params => params[param]),
      );

    const queryParams = route.queryParams
      .pipe(
        filter(params => params != null),
        map(params => params[param]),
      );

    const routeParams = route.params
      .pipe(
        filter(params => params != null),
        map(params => params[param]),
      );

    return merge(
      queryParams,
      routeParams,
      fragmentParams,
    ).pipe(filter(val => val != null));
  }

  private static parseFragmentQueryParams(fragment: string): any {
    if (fragment == null) {
      return null;
    }
    const splitted = fragment.replace(/^\?/, '')
      .split('&');
    const params = splitted.map(keyval => keyval.split('=', 2))
      .reduce((cur, next) => {
        cur[next[0]] = next[1];
        return cur;
      }, {});
    return params;
  }


  static parseRouteParam<T>(value: string | null): T | null {
    if (value == null) {
      return undefined;
    }
    try {
      const decoded = this.decodeRouteParam(value);
      const parsed: T = JSON.parse(decoded);
      return parsed;
    } catch (e) {
      throw new Error(`Failed to parse route param: ${e}`);
    }
  }


  /**
   * Route param encoding flow:
   * value :any ---> serializedValue: string ---> encodedValue: string
   * @param value
   */
  static encodeRouteParam(value: string): string {
    return value;
  }

  /**
   * Route param decoding flow:
   * url-encodedValue: string ---> decoded value: string ----> parsed/deserialized value : any
   * @param value
   */
  static decodeRouteParam(value: string): string {
    return value;
  }

  /**
   * Override current state with defined values in route state.
   * Override unset values with those if the initial state.
   * Return the resulting state.
   * @param currentState
   * @param routeState
   * @param initialState
   */
  static mergeStates<T extends object>(currentState: T, routeState: T, initialState: T): T {
    // current state overridden by route state (undefined route values should not override current state)
    const routeStateKeys = Object.getOwnPropertyNames(routeState)
      .filter(key => typeof key === 'string');
    const definedRouteStateValues: Partial<T> = {};
    routeStateKeys.filter(key => routeState[key] !== undefined)
      .forEach(key => definedRouteStateValues[key] = routeState[key]);
    const state: T = Object.assign({}, currentState, definedRouteStateValues);

    // unset (undefined and null) values overridden by initial state
    const unsetStateKeys = Object.getOwnPropertyNames(initialState)
      .filter(key => typeof key === 'string')
      .filter(key => state[key] == null);
    unsetStateKeys.forEach(key => state[key] = initialState[key]);

    return state;
  }

  static encodeState<T extends { [key: string]: any }>(state: T): { [key: string]: string } {
    const stateKeys = Object.getOwnPropertyNames(state)
      .filter(key => typeof key === 'string')
      .filter(key => state[key] !== undefined);
    const encodedState = {};
    stateKeys.forEach(key => encodedState[key] = JSON.stringify(state[key]));
    return encodedState;
  }

  static findRouterLinkFromRoot(snapshot: ActivatedRouteSnapshot): any[] {
    const parentLink = snapshot.pathFromRoot
      .map(route => route.url.reduce((c, n) => [...c, n.path], []))
      .reduce((c, n) => [...c, ...n], []);
    if (parentLink.length > 0) {
      parentLink[0] = `/${parentLink[0]}`;
    }
    return parentLink;
  }


  /**
   * Emits the effective search filter depending on the vlaue of the filter serialized in the route url, the one active
   * in the table helper, and an initial one.
   * The source of truth is the url, so any table component that want to triggers a new search should first navigate to the updated url,
   * then listen to route param changes, combine it with the other filter change sources, map the results using this function, then
   * subscribe to the emitted filter changes to set it in the tableHelper.
   *
   * If no route filter is active, the table helper filter is used. If no table filter is active, the initial filter is used.
   *
   * eg:
   *
   * ```
   *   const currentFilter = tableHelper.getFilter();

   const routeFilterSubscription = RouteUtils.watchRouteFilter(routeFilter, null,currentFilter, this.createInitialFilter$())
   .subscribe(searchFilter => {
        tableHelper.setFilter(searchFilter);
      });

   ```
   Do not use the trusteeRef param. Instead, combine the filter returned by this method with the context of the component
   to patch the filter accordingly. eg:
   * ```
   * const routeFilterSubscription = RouteUtils.watchRouteFilter(
   routeFilter, null, currentFilter, this.createInitialFilter$())
   .pipe(
   withLatestFrom(this.contextTrusteeRef$, this.contextCustomerRef$),
   map(r => this.patchFilter(r[0], r[1], r[2])),
   ).subscribe(searchFilter => {
        tableHelper.setFilter(searchFilter);
      });
   ```
   *
   * @param routeFilter$
   * @param trusteeRefOptional$ ignored
   * @param activeFilter$
   * @param initialFilterSingle$
   */
  static watchRouteFilter<T extends WithTrusteeRef | any>(routeFilter$: Observable<T>,
                                                          trusteeRefOptional$: Observable<Ref<Trustee>> | null,
                                                          activeFilter$: Observable<T>,
                                                          initialFilterSingle$: Observable<T>): Observable<T> {
    const routeFilterOrNull$: Observable<T | null> = concat(of(null), routeFilter$);
    const currentFilterOrNull$: Observable<T | null> = concat(of(null), activeFilter$);
    const effectiveFilter$: Observable<T> = combineLatest(
      routeFilterOrNull$, currentFilterOrNull$, initialFilterSingle$,
    ).pipe(
      map(results => this.getEffectiveFilterValue(results[0], results[1], results[2])),
      distinctUntilChanged(),
      publishReplay(1), refCount(),
    );

    return effectiveFilter$;
  }

  static watchRoutePagination(paginationParam: Observable<string>,
                              initialPagination: Observable<SimplePagination<any>>,
                              currentPagination: Observable<SimplePagination<any>>): Observable<SimplePagination<any>> {
    const parsedPaginationParam: Observable<SimplePagination | null> = paginationParam.pipe(
      map(param => this.parsePaginationJson(param)),
      distinctUntilChanged(),
    );
    const currentPaginationOrNull: Observable<SimplePagination | null> = concat(of(null), currentPagination);
    const effectivePagination: Observable<SimplePagination> = combineLatest([
      parsedPaginationParam, currentPaginationOrNull, initialPagination,
    ]).pipe(
      map(results => this.getEffectiveFilterValue(results[0], results[1], results[2])),
      publishReplay(1), refCount(),
    );
    return effectivePagination;
  }

  static getSnapshotRouteRef<T extends WithId>(snapshot: ActivatedRouteSnapshot, paramName: string): Ref<T> | 'new' | null {
    const idParam = snapshot.paramMap.get(paramName);
    if (idParam == null) {
      return null;
    } else if (idParam === 'new') {
      return 'new';
    }
    const idNumber = parseInt(idParam, 10);
    if (isNaN(idNumber)) {
      return null;
    }
    return <Ref<T>>{
      id: idNumber,
    };
  }

  static isActiveRouteTooDeepInMenu(activatedRoute: ActivatedRouteSnapshot, menu: MenuItem[], maxDepth = 2) {
    const pathFromLeaf = activatedRoute.pathFromRoot.reverse();
    for (let index = 0; index < pathFromLeaf.length; index++) {
      const route = pathFromLeaf[index];
      if (route.data != null && route.data.menuData != null) {
        const menuData = route.data.menuData;
        const idData = menuData.id;
        const isMenuRoute = menu.find(menuItem => menuItem.id === idData);
        if (isMenuRoute) {
          return index > maxDepth;
        }
      }
      if (index > maxDepth) {
        return true;
      }
    }
    return false;
  }

  static isActiveRouteSubmodule(activatedRoute: ActivatedRouteSnapshot, minDepth = 2) {
    const pathFromLeaf = activatedRoute.pathFromRoot.reverse();
    return pathFromLeaf.length > minDepth;
  }

  static resolveAncestorData$(route: ActivatedRoute): Observable<Data> {
    const ancoestorsData$List = route.pathFromRoot
      .map(path => path.data);
    const ancestorDataList$ = ancoestorsData$List.length === 0 ? of([]) : combineLatest(ancoestorsData$List);
    return ancestorDataList$.pipe(
      map(ancestorDataList => this.reduceAncestorData(ancestorDataList)),
    );
  }


  private static getEffectiveFilterValue<T>(routeValue: T | null, currentValue: T | null, initialValue: T): T {
    // if not filter provided in route, continue with the current filter if available,
    // otherwise create an initial filter
    if (routeValue == null) {
      if (currentValue != null) {
        return currentValue;
      } else {
        return initialValue;
      }
    } else {
      return routeValue;
    }
  }

  private static ensureFilterTrusteeRef<T extends WithTrusteeRef>(searchFilter: T, trusteeRef: Ref<Trustee> | null) {
    if (trusteeRef == null) {
      return searchFilter;
    }
    return Object.assign({}, searchFilter, {
      trusteeRef: trusteeRef,
    });
  }

  private static parsePaginationJson(param: string | null): SimplePagination | null {
    if (param == null) {
      return null;
    }
    try {
      const pagination = JSON.parse(param) as SimplePagination;
      return pagination;
    } catch (e) {
      return null;
    }
  }


  private static reduceAncestorData(ancestorDataList: Data[]): Data {
    return ancestorDataList
      .reduce((cur, next) => Object.assign({}, cur, next), {});
  }

}
