import {Injectable} from '@angular/core';
import {WsRef, WsTrustee} from '@lifeislife/lifeislife-ws-api';
import {RequestService} from '../../service/request.service';
import {Observable, of} from 'rxjs';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {FrontendAppConfigKey} from '../../../domain/config/frontend-app-config-key';
import {AppConfigService} from '../../../service/config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class TrusteePreferenceWsClient {

  constructor(private requestService: RequestService,
              private authService: AuthProvider,
              private appConfigService: AppConfigService,
  ) {
  }

  getPreferenceValue$<T>(trusteeRef: WsRef<WsTrustee>, preferenceKey: string): Observable<T> {
    if (trusteeRef == null) {
      return of(null);
    }
    const url = this.getResourceUrl(trusteeRef) + '/key/' + preferenceKey + '/value';

    return this.requestService.sendRequest<T>({
      method: 'GET', url: url, responseType: 'text',
    }, this.authService.getAuth());
  }

  setPreferenceValue$<T>(trusteeRef: WsRef<WsTrustee>, preferenceKey: string, value: T): Observable<any> {
    if (trusteeRef == null) {
      return of(null);
    }
    const url = this.getResourceUrl(trusteeRef) + '/key/' + preferenceKey + '/value';

    return this.requestService.sendRequest<any>({
      method: 'POST', url: url,
      body: value,
    }, this.authService.getAuth());
  }

  listPreferenceKeys$(trusteeRef: WsRef<WsTrustee>): Observable<string[]> {
    if (trusteeRef == null) {
      return of([]);
    }
    const url = this.getResourceUrl(trusteeRef) + '/key/list';

    return this.requestService.sendRequest<string[]>({
      method: 'GET', url: url,
    }, this.authService.getAuth());
  }

  private getResourceUrl(trusteeRef: WsRef<WsTrustee>): string {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    return wsUri + '/front/trustee/' + trusteeRef.id + '/preference';
  }

}
