/**
 * Created by cghislai on 26/04/16.
 */

import {Component, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'validation-error-message',
  templateUrl: 'errorMessageComponent.html',
  styleUrls: ['errorMessageComponent.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({
        opacity: '1.0',
      })),
      transition('void => *', [
        style({opacity: '0.0'}),
        animate('100ms ease-out'),
      ]),
      transition('* => void', [
        animate('100ms ease-in', style({opacity: '0.0'})),
      ]),
    ]),
  ],
})
export class ValidationErrorMessageComponent {
  @Input()
  errorMessages: string[];
  @Input()
  errorMessage: string;
  @Input()
  helpMessage: string;

  constructor() {
  }


  trackMsg(index, item) {
    return item;
  }
}
