<div class="file-box">

  <div class="content">

    <app-file-drop-area [active]="!disabled"
                        [acceptFiles]="!disabled"
                        (filesDropped)="onFileDrop($event)">

      <p-dataView [loading]="loading"
                  [totalRecords]="totalCount"
                  [first]="pagination?.offset"
                  [rows]="pagination?.length"
                  [sortOrder]="sort?.order"
                  [sortField]="sort?.field"
                  [lazy]="true"
                  [value]="rows"
                  [alwaysShowPaginator]="true"
                  (onLazyLoad)="onLazyLoad($event)"
                  layout="list"
                  paginatorPosition="bottom"
                  emptyMessage="Aucun fichier" i18n-emptyMessage
                  #dataView
      >
        <p-header *ngIf="showHeader">
          <input pInputText name="searchQuery"
                 class="file-search-query-input"
                 placeholder="Recherher un fichier..." i18n-placeholder
                 [ngModel]="searchQueryValue$ | async"
                 [disabled]="disabled"
                 (ngModelChange)="onQueyChanged($event)">
        </p-header>
        <ng-template let-row let-rowIndex="rowIndex" pTemplate="listItem">
          <div class="list-row" #rowComponent
               (click)="onRowClicked(row, $event)">
            <llc-file-list-item [file]="row.storedFile"
                                [disabled]="disabled"
                                [showCreated]="showFileCreated"
                                [showUpdate]="showFileUpdated"
                                [showDelete]="showDelete"
                                [showRename]="showRename"
                                [showBadgeSigned]="showBadgeSigned"
                                [showVisibleToCustomer]="showVisibleToCustomer"
                                [visibleToCustomer]="row.visibleToCustomer"
                                (visibleToCustomerChange)="onVisibleToCustomerChange(row,$event)"
                                [renaming]="renamingWithFile === row"
                                (renamingChange)="onRenamingFileChange(row, $event)"
                                (deleteClick)="onDeleteClick(row)"
                                (fileRename)="onFileRenamed(row, $event)"
                                [overlayAppendTo]="overlayAppendTo || rowComponent"
            ></llc-file-list-item>
          </div>
        </ng-template>
        <p-footer *ngIf="showFooter">
          <div *ngIf="showUploadInFooter && showUpload; else footerContent">
            <div class="actions lifeislife-link-toolbar">
              <a class="upload" *ngIf="showUpload"
                 [class.disabled]="disabled"
                 (click)="onUploadFileClick($event)">
                <i class="fa fa-upload"></i>
                <span i18n>Ajouter un fichier</span>
              </a>
            </div>
          </div>
          <ng-template #footerContent>
            <ng-content></ng-content>
          </ng-template>
        </p-footer>

      </p-dataView>

      <div class="actions lifeislife-link-toolbar" *ngIf="(showUpload && !showUploadInFooter)">
        <a class="upload" *ngIf="showUpload"
           [class.disabled]="disabled"
           (click)="onUploadFileClick($event)">
          <i class="fa fa-upload"></i>
          <span i18n>Ajouter un fichier</span>
        </a>
      </div>
    </app-file-drop-area>
  </div>

</div>
<app-file-upload (uploadAborted)="onUploadAborted($event)"
                 (uploadError)="onUploadError($event)"
                 (uploadSuccess$)="onUploadSuccess($event)"
                 [showDialog]="true"
                 [uploadPath]="newFileUploadPath"
                 #uploadComponent
></app-file-upload>
<file-download></file-download>
