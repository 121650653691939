import {Injectable} from '@angular/core';
import {WsDaoEventEntityType, WsRef, WsStoredFile} from '@lifeislife/lifeislife-ws-api';
import {RequestService} from '../../../service/request.service';
import {Observable} from 'rxjs';
import {HttpHeaders} from '@angular/common/http';
import {map, mapTo, tap} from 'rxjs/operators';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {FileUploadUtils} from '../../../private_util/file-upload-utils';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {FrontendAppConfigKey} from '../../../../domain/config/frontend-app-config-key';
import {SingleFileUploadProgress} from '../../../../util/upload/upload-progress';

@Injectable({
  providedIn: 'root',
})
export class StoredFileWsClient extends CachedWsResourceClient<WsStoredFile, WsRef<WsStoredFile>> {

  constructor(
    private injectedRequestService: RequestService,
    private injectedAuthService: AuthProvider,
    private injectectedCacheService: ResourceCacheService,
    private injectedAppConfigService: AppConfigService,
  ) {
    super('/front/file', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.STORED_FILE, injectectedCacheService);
  }

  getNewFilePath(): string {
    return `/front/file/`;
  }

  renameFile(id: number, newFileName: string): Observable<WsRef<WsStoredFile>> {
    const url = this.getResourceUrl() + '/' + id + '/fileName';
    const headers = new HttpHeaders()
      .append('Content-Type', 'text/plain')
      .append('Accept', 'application/json');

    return this.requestService.sendRequest<WsStoredFile>({
      method: 'PUT', url: url, body: newFileName, headers: headers,
    }, this.authService.getAuth()).pipe(
      tap(path => this.doClearCache(id)),
    );
  }

  getFilePreviewUrl(id: number): string {
    let url = `${this.getResourceUrl()}/${id}/thumbnail`;

    const auth = this.authService.getAuth();
    if (auth != null) {
      const authQueryString = auth.authorizationQueryString;
      url += `?${authQueryString}`;
    }
    return url;
  }

  getFileDownloadUrl$(id: number, contentDisposition?: 'inline' | 'attachment'): Observable<string> {
    const url = `${this.getUnrestrictedResourceUrl()}/${id}/contentUrl`;
    return this.requestService.sendRequestFullResponse<string>({
      method: 'GET', url: url, responseType: 'text',
    }, this.authService.getAuth())
      .pipe(
        map(r => r.body),
      );
  }

  reset(): Observable<any> {
    const url = this.getFileResourceUrl() + '/reset';
    return this.requestService.sendRequest({
      method: 'POST', url: url,
    }, this.authService.getAuth())
      .pipe(mapTo(true));
  }

  addFile(subResourcePath: string, file: File, errorHandler?: (error) => void): Observable<SingleFileUploadProgress> {
    const fileName = FileUploadUtils.getSanitizedFilenameForUpload(file);
    const urlEncodedFileName = encodeURIComponent(fileName);
    const url = this.getFileSubResourceUrl(subResourcePath) + '?fileName=' + urlEncodedFileName + '&ngsw-bypass=true';
    const auth = this.authService.getAuth();

    return FileUploadUtils.sendUploadRequestWithInMemoryFallback$(file, url, auth, errorHandler);
  }

  private getFileSubResourceUrl(path: string) {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    return wsUri + path;
  }

  private getFileResourceUrl() {
    return `${this.getResourceUrl()}`;
  }

  private getUnrestrictedResourceUrl() {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    return wsUri + '/unrestricted/file';
  }

}
