import {KeyValueStore} from './key-value-store';
import {Injectable} from '@angular/core';
import {WindowRef} from '../window/window-ref';

@Injectable()
export class LocalStorageKeyValueStoreService implements KeyValueStore {

  storage: Storage;

  constructor(
    private windowRef: WindowRef,
  ) {
    const window = windowRef.getWindow();
    if (window.localStorage == null) {
      throw new Error(`No local storage`);
    }
    this.storage = window.localStorage;
  }

  clearValue(key: string) {
    this.storage.removeItem(key);
  }

  getValue(key: string): string | null {
    return this.storage.getItem(key);
  }

  putValue(key: string, value: string) {
    this.storage.setItem(key, value);
  }

}
