import {WithId} from '../../client/domain/with-id';
import {Observable, of, throwError} from 'rxjs';

export interface Ref<T extends WithId> extends WithId {
  id: number;
}


export function isTypedRef<T extends WithId>(ref: Ref<T> | any): ref is Ref<T> {
  return ref != null && ref.id != null;
}

export function isValidRef(ref: Ref<any>): boolean {
  return ref != null && ref.id != null;
}

export function toValidRef$<T extends WithId = WithId>(ref: Ref<T>): Observable<Ref<T>> {
  if (isValidRef(ref)) {
    return of({id: ref.id});
  } else {
    return throwError(`Invalid ref: ${ref}`);
  }
}

export function isSameRef<T extends WithId>(val1: T, val2: T): boolean {
  if (val1 == null && val2 == null) {
    return true;
  }
  if (val1 == null || val2 == null) {
    return false;
  }
  const id1 = val1['id'];
  const id2 = val2['id'];
  return id1 != null && id2 != null && id1 === id2;
}
