import {TrusteeMenu} from './trustee/trustee-menu';
import {CustomerMenu} from './customer/customer-menu';
import {ApplicationMenuItemDef} from './main/routing/application-menu-item-def';
import {ApplicationMenuData} from './application-menu-data';
import {ContactMenu} from './contact/contact-menu';

// This is the main application menu, as displayed in the top menu bar.

export const ApplicationMenu: ApplicationMenuItemDef[] = [
  {data: ApplicationMenuData.welcome},
  {
    data: ApplicationMenuData.trustee,
    children: TrusteeMenu,
  },
  {
    data: ApplicationMenuData.customer,
    children: CustomerMenu,
  },
  {
    data: ApplicationMenuData.contact,
    children: ContactMenu,
  },
  {
    data: ApplicationMenuData.insuranceMenu,
    children: [
      {data: ApplicationMenuData.insuranceSubscription},
      {data: ApplicationMenuData.newInsuranceSubscription},
    ],
  },
  {
    data: ApplicationMenuData.creditMenu,
    children: [
      {data: ApplicationMenuData.newCreditSubscription},
      {data: ApplicationMenuData.creditSubscription},
      {data: ApplicationMenuData.credit},
      {data: ApplicationMenuData.creditProvider},
    ],
  },
  {
    data: ApplicationMenuData.investmentMenu,
    children: [
      {data: ApplicationMenuData.newInvestmentSubscription},
      {data: ApplicationMenuData.investmentSubscription},
      {data: ApplicationMenuData.investment},
      {data: ApplicationMenuData.investmentProvider},
    ],
  },
  {
    data: ApplicationMenuData.administration,
    children: [
      {
        data: ApplicationMenuData.insurance,
        children: [
          {data: ApplicationMenuData.insuranceCategory},
          {data: ApplicationMenuData.insuranceProvider},
        ],
      },
      {data: ApplicationMenuData.user},
      {data: ApplicationMenuData.signingPackage},
      {data: ApplicationMenuData.eventRecord},
      {data: ApplicationMenuData.batches},
    ],
  },

  {
    data: ApplicationMenuData.configuration,
    children: [
      {data: ApplicationMenuData.messageTemplate},
      {data: ApplicationMenuData.preference},
      {data: ApplicationMenuData.mail},
      {data: ApplicationMenuData.webmailDev},
    ],
  },
];
