<div class="customer-contact-type-multi-select">
  <p-multiSelect
    [options]="allItems || []"
    [ngModel]="selectedValue"
    (ngModelChange)="onChange($event)"
    [readonly]="disabled"
    [styleClass]="hasError ? 'p-state-error' : ''"
    appendTo="body"
    placeholder="Tous types"
  >
    <ng-template let-selectedItems pTemplate="selectedItems">
      <div *ngIf="selectedItems?.length < allItems.length && selectedItems?.length > 0; else anyItem">
        <span *ngFor="let item of selectedItems" class="enum-item">
          <app-customer-contact-type [contactType]="item"></app-customer-contact-type>
        </span>
      </div>
      <ng-template #anyItem>
        <span i18n>Tous types</span>
      </ng-template>
    </ng-template>

    <ng-template let-value pTemplate="item">
      <app-customer-contact-type [contactType]="value.value"
                                 [showInfoBadges]="true"
      ></app-customer-contact-type>
    </ng-template>

  </p-multiSelect>
</div>
