import {WsPensionCareerType} from '@lifeislife/lifeislife-ws-api';
import {PensionCareerType} from '../../domain/retire/pension-career-type';

export class PensionCareerTypeConverter {

  static convertIn(wsPensionCareerType: WsPensionCareerType): PensionCareerType {
    if (wsPensionCareerType == null) {
      return null;
    }
    return wsPensionCareerType as string as PensionCareerType;
  }


  static convertOut(pensionCareerType: PensionCareerType): WsPensionCareerType {
    if (pensionCareerType == null) {
      return null;
    }
    return pensionCareerType as string as WsPensionCareerType;
  }
}
