<div class="signing-package-box">

  <div class="content">
    <p-dataView [loading]="loading"
                [totalRecords]="totalCount"
                [first]="pagination?.offset"
                [rows]="pagination?.length"
                [sortOrder]="sort?.order"
                [sortField]="sort?.field"
                [lazy]="true"
                [value]="rows"
                [alwaysShowPaginator]="true"
                (onLazyLoad)="onLazyLoad($event)"
                layout="list"
                paginatorPosition="bottom"
                emptyMessage="Aucune liasse" i18n-emptyMessage
                #dataView
    >
      <p-header *ngIf="showHeader">
        <div class="header-content">
          <input pInputText name="searchQuery"
                 class="signing-package-search-query-input"
                 placeholder="Recherher une liasse..." i18n-placeholder
                 [ngModel]="searchQueryValue$ | async"
                 (ngModelChange)="onQueyChanged($event)">
          <p-inputSwitch name="includeAll" id="includeAll"
                         [ngModel]="includeAllStatus$ | async"
                         (ngModelChange)="includeAllStatus$.next($event)"
          >
          </p-inputSwitch>
          <label for="includeAll" i18n (click)="includeAllStatus$.next(!includeAllStatus$.getValue())">
            Tous status
          </label>
        </div>
      </p-header>
      <ng-template let-row let-rowIndex="rowIndex" pTemplate="listItem">
        <div class="list-row"
             [class.busy]="row.busy"
             (click)="onRowClicked(row, $event)">
          <llc-signing-package-list-item [package]="row.signingPackage"
                                         [showDelete]="showDelete"
                                         (deleteClick)="onDeleteClick($event,row)"
                                         [showStatus]="showItemStatus"
                                         [showCreated]="showItemCreated"
                                         [showUpdate]="showItemUpdated"
          ></llc-signing-package-list-item>
          <div class="row-overlay">
          </div>
        </div>
      </ng-template>
      <p-footer *ngIf="showFooter">
        <ng-content></ng-content>
      </p-footer>

    </p-dataView>

  </div>

</div>
