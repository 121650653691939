import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerContactTypeSelectComponent} from './customer-contact-type-select/customer-contact-type-select.component';
import {FormsModule} from '@angular/forms';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {CustomerContactSelectComponent} from './customer-contact-select/customer-contact-select.component';
import {CustomerContactMultiViewComponent} from './customer-contact-multi-view/customer-contact-multi-view.component';
import {CustomerMainContactViewComponent} from './customer-main-contact-view/customer-main-contact-view.component';
import {MainContactComponentsModule} from '../contact/main-contact-components.module';
import {CustomerContactColumnComponent} from './customer-contact-table/customer-contact-column/customer-contact-column.component';
import {MainCustomerModule} from '../customer/main-customer.module';
import {MainCountryModule} from '../country/main-country.module';
import {PersonTitleModule} from '../person-title/person-title.module';
import {MainModule} from '../../main.module';
import {CustomerContactTypeMultiSelectComponent} from './customer-contact-type-multi-select/customer-contact-type-multi-select.component';
import {CustomerContactTypeModule} from './customer-contact-type/customer-contact-type.module';
import {CustomerContactTypeBadgeModule} from './customer-contact-type-badge/customer-contact-type-badge.module';
import {AutoCompleteModule} from 'primeng/autocomplete';

const PUBLIC_DECLARATIONS = [
  CustomerContactTypeSelectComponent,
  CustomerContactSelectComponent,
  CustomerContactMultiViewComponent,
  CustomerMainContactViewComponent,
  CustomerContactColumnComponent,
  CustomerContactTypeMultiSelectComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        MainContactComponentsModule,

        DropdownModule,
        MainCountryModule,
        PersonTitleModule,
        MainModule,
        MainCustomerModule,
        MultiSelectModule,
        CustomerContactTypeModule,
        CustomerContactTypeBadgeModule,
        AutoCompleteModule,
    ],
  declarations: [...PUBLIC_DECLARATIONS],
  exports: [...PUBLIC_DECLARATIONS],
})
export class MainCustomerContactComponentsModule {
}
