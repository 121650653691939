<div class="customer-contact-type-select">
  <p-dropdown
    [options]="allItems"
    [ngModel]="selectedValue"
    (ngModelChange)="onChange($event)"
    [readonly]="disabled"
    [styleClass]="hasError ? 'p-state-error' : ''"
    appendTo="body"
    optionLabel="label"
    optionValue="value"
  >
    <ng-template pTemplate="selectedItem">
      <app-customer-contact-type [contactType]="selectedValue"
                                 *ngIf="selectedValue != null; else noValue"
      ></app-customer-contact-type>
      <ng-template #noValue>
        <span>Aucun</span>
      </ng-template>
    </ng-template>
    <ng-template let-value pTemplate="item">
      <app-customer-contact-type [contactType]="value.value"
                                 [showInfoBadges]="true"
      ></app-customer-contact-type>
    </ng-template>
  </p-dropdown>
</div>
