import {ChangeDetectionStrategy, Component, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {TrusteeContactRowModel} from '../model/trustee-contact-row-model';
import {TrusteeContactColumns} from '../model/trustee-contact-columns';

@Component({
  selector: 'app-trustee-contact-column',
  templateUrl: './trustee-contact-column.component.html',
  styleUrls: ['./trustee-contact-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrusteeContactColumnComponent implements OnInit {


  @Input()
  row: TrusteeContactRowModel;
  @Input()
  column: TrusteeContactColumns.Column;

  @Input()
  showDeleteAction: boolean;
  @Output()
  private deleteClicked = new Subject<TrusteeContactRowModel>();


  constructor() {
  }

  ngOnInit() {
  }

  getTitle(col: TrusteeContactColumns.Column, row: TrusteeContactRowModel) {
    const fields = col.field.split('.');
    const title = fields.reduce((cur, next) => {
      if (cur == null || cur[next] == null) {
        return null;
      }
      return cur[next];
    }, row);
    return title;
  }


  onDeleteClicked(row: TrusteeContactRowModel, event: Event) {
    this.deleteClicked.next(row);
    event.preventDefault();
    event.stopImmediatePropagation();
  }


  discardEvent(event: Event) {
    event.stopImmediatePropagation();
  }
}
