import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {LifeislifeControlListener} from '../lifeislife-control-listener';
import {InputMask} from 'primeng/inputmask';

@Component({
  selector: 'llc-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PhoneInputComponent,
    multi: true,
  }],
})
export class PhoneInputComponent extends LifeislifeControlListener<string> implements OnInit {

  @Input()
  prefixEdiitable;
  @Input()
  numberEditable = true;
  @Input()
  autoFocus = false;
  @Input()
  required = false;

  @Input()
  set prefix(value: string) {
    this.prefixValue = value != null && value.startsWith('+') ? value : `+32`;
  }

  @Output()
  private prefixChange = new EventEmitter<string>();

  prefixValue: string;

  @ViewChild('numberInput', {
    static: false,
  })
  private numberInputMask: InputMask;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  onPrefixChange(prefixValue: string) {
    const prefix = prefixValue.replace(/^\+/, '');
    this.prefixValue = prefix;
    this.prefixChange.next(prefix);
    this.fireChanges();
  }

  focus() {
    if (this.numberInputMask) {
      this.numberInputMask.focus();
    }
  }

  onNumberChange(numberValue: string) {
    const number = numberValue.replace(/^0/, '');
    this.value = number;
    this.fireChanges();
  }
}
