import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {CustomerContactType} from '../../domain/customer-contact/customer-contact-type';
import {WsCustomerContactTypeModel} from '@lifeislife/lifeislife-ws-api';
import {CustomerContactTypeModel} from '../../domain/customer-contact/customer-contact-type-model';

export class CustomerContactTypeModelConverter {

  static convertIn(value: WsCustomerContactTypeModel): CustomerContactTypeModel {
    return {
      contactType: EnumConverterUtils.convertValue(value.wsCustomerContactType, CustomerContactType),
      customerDataAccess: value.customerDataAccess,
      customerInternal: value.customerInternal,
      label: value.label,
    };
  }


}
