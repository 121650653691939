import {Injectable} from '@angular/core';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {WsConstraintViolation, WsRef, WsTicketFile} from '@lifeislife/lifeislife-ws-api';
import {ObjectConverterUtil} from '../object-converter-util';
import {TicketFileSearch} from '../../domain/ticket/ticket-file-search';
import {TicketFile} from '../../domain/ticket/ticket-file';
import {TicketFileWsClient} from '../../client/resources/front/ticketing/ticket-file-ws-client';
import {TicketFileConverter} from './ticket-file.converter';
import {ContentDisposition} from '../../util/file/file-utils';

@Injectable({
  providedIn: 'root',
})
export class TicketFileService {

  constructor(private client: TicketFileWsClient,
  ) {
  }

  getTicketFile$(ref?: Ref<TicketFile>): Observable<TicketFile> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doGet(ref.id).pipe(
      map(wsTicketFile => TicketFileConverter.convertIn(wsTicketFile)),
    );
  }

  searchTicketFiles$(ticketFileFilter: TicketFileSearch, pagination: SimplePagination)
    : Observable<SearchResult<TicketFile>> {
    const wsSearch = TicketFileConverter.convertFilterOut(ticketFileFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateTicketFile$(ticketFile: TicketFile): Observable<ValidationResult<TicketFile>> {
    const wsTicketFile = TicketFileConverter.convertOut(ticketFile);
    return this.client.doValidate(wsTicketFile).pipe(
      map((results: WsTicketFile | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveTicketFile$(ticketFile: TicketFile): Observable<Ref<TicketFile>> {
    const wsTicketFile = TicketFileConverter.convertOut(ticketFile);
    return this.client.doSave(wsTicketFile);
  }

  deleteTicketFile$(id: number): Observable<any> {
    return this.client.doRemove(id);
  }

  //
  // getFileThumbnailUrl$(ref: Ref<TicketFile>): Observable<string> {
  //   if (!isValidRef(ref)) {
  //     return throwError(new Error('Invalid ref'));
  //   }
  //   return this.client.getFileThumbnailUrl$(ref.id);
  // }

  getFileDownloadUrl$(ref: Ref<TicketFile>, contentDisposition: ContentDisposition = 'attachment'): Observable<string> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }

    return this.client.getFileDownloadUrl$(ref.id, contentDisposition);
  }


  private createValidationResult(results: WsTicketFile | WsConstraintViolation[]): ValidationResult<TicketFile> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsTicketFile => TicketFileConverter.convertIn(wsTicketFile),
      propertyNameMappings: TicketFileConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsTicketFile>>): Observable<SearchResult<TicketFile>> {
    const rowTasks = results.list.map(ref => this.getTicketFile$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<TicketFile>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<TicketFile>>>{
          list: rows,
        }),
      ));
  }

}
