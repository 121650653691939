import {Injectable} from '@angular/core';
import {RequestService} from '../../service/request.service';
import {Observable} from 'rxjs';
import {WsRef, WsTrustee, WsTrusteePublicView, WsTrusteeSearch} from '@lifeislife/lifeislife-ws-api';
import {map} from 'rxjs/operators';
import {SimplePagination} from '../../../util/pagination/simple-pagination';
import {SearchResult} from '../../domain/search/search-result';
import {SimplePaginationUtils} from '../../private_util/simple-pagination-utils';
import {SearchResultFactory} from '../../private_util/searchResultFactory';
import {FrontendAppConfigKey} from '../../../domain/config/frontend-app-config-key';
import {ApplicationCompileTimeSettings} from '../../../domain/config/application-config-settings';


@Injectable({
  providedIn: 'root',
})
export class UnrestrictedTrusteeClient {

  constructor(private requestService: RequestService,
              private compileTimeSettings: ApplicationCompileTimeSettings,
  ) {
  }

  searchTrustees$(search: WsTrusteeSearch, pagination: SimplePagination): Observable<SearchResult<WsRef<WsTrustee>>> {
    const wsUri = this.getWsUri();
    const url = `${wsUri}/unrestricted/trustee/search`;
    const queryParams = SimplePaginationUtils.toQueryParams(pagination);

    return this.requestService.sendRequestFullResponse<WsRef<WsTrustee>[]>({
      method: 'POST',
      url: url,
      params: queryParams,
      body: search,
    }).pipe(
      map((response) => SearchResultFactory.createSearchResult<WsRef<WsTrustee>>(response)),
    );
  }


  getTrustee$(ref: WsRef<WsTrustee>): Observable<WsTrusteePublicView> {
    const wsUri = this.getWsUri();
    const url = `${wsUri}/unrestricted/trustee/${ref.id}`;

    return this.requestService.sendRequest<WsTrusteePublicView>({
      method: 'GET',
      url: url,
    });
  }

  findTrusteeForKey$(key: string): Observable<WsRef<WsTrustee> | null> {
    const wsUri = this.getWsUri();
    const url = `${wsUri}/unrestricted/trustee/key/${key}`;

    return this.requestService.sendRequest<WsTrusteePublicView>({
      method: 'GET',
      url: url,
    });
  }

  getTrusteePreferencesMap$(trusteeId: number, apiUri?: string): Observable<{ [key: string]: string }> {
    const wsUri = apiUri || this.getWsUri();
    const url = `${wsUri}/unrestricted/trustee/${trusteeId}/settings`;
    return this.requestService.sendRequest<any>({
      method: 'GET', url: url,
    });
  }


  private getWsUri() {
    return this.compileTimeSettings.config[FrontendAppConfigKey.lifeislife_ws_uri];
  }
}
