import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsLifeIsLifeUser, WsRole} from '@lifeislife/lifeislife-ws-api';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {UserConverter} from './user.converter';
import {User} from '../../domain/user/user';
import {UserFilter} from '../../domain/user/user-filter';
import {Ref} from '../../domain/shared/ref';
import {AuthProvider} from '../../client/domain/auth/auth-provider';
import {UserWsClient} from '../../client/resources/front/user-ws-client';
import {UserAuthWsClient} from '../../client/resources/front/user-auth-ws-client';
import {Contact} from '../../domain/contact/contact';
import {ContactService} from '../contact/contact.service';
import {UnrestictedTokenClient} from '../../client/resources/unrestricted/unresticted-token-client';
import {WsUserTokenAuth} from '../../client/domain/auth/ws-user-token-auth';
import {Role} from '../../domain/role/role';
import {RoleConverter} from '../permission/role-converter';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(private authProvider: AuthProvider,
              private contactService: ContactService,
              private client: UserWsClient,
              private authCient: UserAuthWsClient,
              private userTokenClient: UnrestictedTokenClient,
  ) {
  }

  exchangeAuthCodeForToken$(code: string): Observable<WsUserTokenAuth> {
    return this.userTokenClient.exchangeCodeForToken$(code).pipe(
      map(wsToken => WsUserTokenAuth.fromUserToken(wsToken)),
    );
  }

  requestEmailValidationCode$(email: string, url: string, resetPassword: boolean, ...roles: Role[]): Observable<string> {
    const safeRoles = roles || [];
    const wsRoles: WsRole[] = safeRoles.map(
      role => RoleConverter.toWsRole(role),
    );
    // const searchparamsIndex = url.indexOf('?');
    // if (searchparamsIndex >= 0) {
    //   url = url.substring(0, searchparamsIndex);
    // }
    return this.userTokenClient.requestVerificationCodeByMail$(email, url, resetPassword, wsRoles);
  }

  getLoggedUser$(): Observable<User> {
    const auth = this.authProvider.getAuth();
    return this.authCient.getLoggedUser$(auth).pipe(
      map(wsUser => UserConverter.convertIn(wsUser)),
    );
  }

  setLoggedUserPassword$(newPassword: string): Observable<Ref<User>> {
    const auth = this.authProvider.getAuth();
    return this.authCient.setLoggedUserPassword$(auth, newPassword);
  }

  isLoggedUserPasswordSet$(): Observable<boolean> {
    const auth = this.authProvider.getAuth();
    return this.authCient.isLoggedUserPasswordSet$(auth);
  }

  getUser$(ref: Ref<User>, forceFetch?: boolean): Observable<User> {
    return ref == null ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsUser => UserConverter.convertIn(wsUser)),
    );
  }

  getUserContact$(ref: Ref<User>): Observable<Contact> {
    return ref == null ? of(null) : this.client.findUserContact$(ref.id).pipe(
      switchMap(wsContactRef => this.contactService.getContact$(wsContactRef)),
      catchError(notFound => of(null)),
    );
  }

  getUserRoles$(ref: Ref<User>): Observable<Role[]> {
    return ref == null ? of(null) : this.client.findUserRoles$(ref.id).pipe(
      map(l => l.map(wsRole => EnumConverterUtils.convertValue(wsRole, Role))),
      catchError(notFound => of([])),
    );
  }

  searchUsers$(userFilter: UserFilter, pagination: SimplePagination): Observable<SearchResult<User>> {
    const wsSearch = UserConverter.convertFilterOut(userFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => UserConverter.convertSearchResultsIn$(results)),
    );
  }

  validateUser$(user: User): Observable<ValidationResult<User>> {
    const wsUser = UserConverter.convertOut(user);
    return this.client.doValidate(wsUser).pipe(
      map((results: WsLifeIsLifeUser | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveUser$(user: User): Observable<any> {
    const wsUser = UserConverter.convertOut(user);
    return this.client.doSave(wsUser);
  }


  serializeFilter(userFilter: UserFilter): string {
    ObjectConverterUtil.cleanUnsetFilterValues(userFilter);
    const wsUserSearch = UserConverter.convertFilterOut(userFilter);
    return JSON.stringify(wsUserSearch);
  }

  deserializeFilter(valueString: string | null): UserFilter | null {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: any = JSON.parse(valueString);
      const filter = UserConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }


  private createValidationResult(results: WsLifeIsLifeUser | WsConstraintViolation[]): ValidationResult<User> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsUser => UserConverter.convertIn(wsUser),
      propertyNameMappings: UserConverter.getValidationPropertyNameMappings(),
    });
  }

}
