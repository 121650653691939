import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatePipe} from './datePipe';
import {DateTimePipe} from './dateTimePipe';
import {CalendarInputComponent} from './calendar-input/calendar-input.component';
import {CalendarModule} from 'primeng/calendar';
import {SharedModule} from 'primeng/api';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [DatePipe, DateTimePipe, CalendarInputComponent],
  exports: [DatePipe, DateTimePipe, CalendarInputComponent],
  imports: [
    CommonModule,
    FormsModule,

    CalendarModule,
    SharedModule,
  ],
})
export class DateModule {
}
