import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, map, publishReplay, refCount} from 'rxjs/operators';
import {Contact, ContactSearch, ContactService, Ref, Trustee} from '@lifeislife/lifeislife-domain';
import {ObjectUtils} from '../../../../commons/util/ObjectUtils';

/**
 * Selects a contact id from all contacts linked to a trustee
 */
@Component({
  selector: 'app-trustee-contact-select',
  templateUrl: './trustee-contact-select.component.html',
  styleUrls: ['./trustee-contact-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TrusteeContactSelectComponent),
    multi: true,
  }],
})
export class TrusteeContactSelectComponent implements OnInit, ControlValueAccessor {

  @Input()
  disabled = false;
  @Input()
  hasError = false;
  @Input()
  includeNoSelectionOption = false;
  @Input()
  noSelectionLabel = '<Aucun>';

  @Input()
  set trusteeRef(ref: Ref<Trustee>) {
    this.trusteeRefSource$.next(ref);
  }

  contactFilter$: Observable<ContactSearch>;
  selectedValue: Ref<Contact>;

  trusteeRefSource$ = new BehaviorSubject<Ref<Trustee>>(null);

  private onChangeFunction: Function;
  private onTouchedFunction: Function;


  constructor(private contactService: ContactService) {
  }

  ngOnInit() {
    this.contactFilter$ = combineLatest(
      this.trusteeRefSource$.pipe(distinctUntilChanged(ObjectUtils.isSameRef)),
    ).pipe(
      map(results => this.createContactFilter(results[0])),
      publishReplay(1), refCount(),
    );
  }

  writeValue(contactRef: Ref<Contact>): void {
    this.selectedValue = contactRef;
  }

  registerOnChange(fn: any): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFunction = fn;
  }

  onChange(value: Ref<Contact>) {
    this.selectedValue = value;
    this.fireChange(value);
  }

  fireChange(value: Ref<Contact>) {
    this.onTouchedFunction();
    this.onChangeFunction(value);
  }

  private createContactFilter(trusteeRef: Ref<Trustee> | null): ContactSearch {
    if (trusteeRef == null) {
      return null;
    }
    const contactFilter: ContactSearch = this.contactService.createFilter(trusteeRef);
    contactFilter.trusteeRef = trusteeRef;
    return contactFilter;
  }

}
