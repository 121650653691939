import {WsProductType, WsRecurrence, WsSubscription, WsSubscriptionSearch} from '@lifeislife/lifeislife-ws-api';
import {SubscriptionSearch} from '../../domain/corebusiness/subscription-search';
import {CustomerSearchConverter} from '../customer/customer-search.converter';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {Subscription} from '../../domain/corebusiness/subscription';
import {ProductType} from '../../domain/corebusiness/product-type';
import {ProductConverter} from './product-converter';
import {DateRangeSearchConverter} from '../shared/date-range-search-converter';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {Recurrence} from '../../domain/corebusiness/recurrence';
import {DateSearchConverter} from '../shared/date-search-converter';

export class SubscriptionConverter {


  static convertIn(wsSubscription: WsSubscription): Subscription {
    if (wsSubscription == null) {
      return null;
    }
    return {
      id: wsSubscription.id,
      description: wsSubscription.description,
      productType: wsSubscription.wsProductType as string as ProductType,
      productRef: wsSubscription.productWsRef,
      customerRef: wsSubscription.customerWsRef,
      active: wsSubscription.active,
      archived: wsSubscription.archived,
      imported: wsSubscription.imported,
      lastImportDateTime: DateConverterUtils.parseIsoDateTime(wsSubscription.lastImportDateTime),
      creationDateTime: DateConverterUtils.parseIsoDateTime(wsSubscription.creationDateTime),
      updateDateTime: DateConverterUtils.parseIsoDateTime(wsSubscription.updateDateTime),
      startDate: DateConverterUtils.parseIsoDate(wsSubscription.startDate),
      endDate: DateConverterUtils.parseIsoDate(wsSubscription.endDate),
      recurrence: EnumConverterUtils.convertValue(wsSubscription.recurrence, Recurrence),
      previousBrokerDescription: wsSubscription.previousBrokerDescription,
      totalPricing: wsSubscription.totalPricing,
      notes: wsSubscription.notes,
      providerReference: wsSubscription.providerReference,
      internalReference: wsSubscription.internalReference,
      parentSubscriptionRef: wsSubscription.parentSubscriptionRef,
      productionYear: wsSubscription.productionYear,
      mandated: wsSubscription.mandated,
      sourcingContactInfo: wsSubscription.sourcingContactInfo,
      contactMail: wsSubscription.contactMail,
      contactRequestedDate: DateConverterUtils.parseIsoDate(wsSubscription.contactRequestedDate),
      contactRequestDescription: wsSubscription.contactRequestDescription,
      logRef: wsSubscription.logRef,
      yearlyPricing: wsSubscription.yearlyPricing,
    }
      ;
  }

  static convertOut(subscription: Subscription): WsSubscription {
    if (subscription == null) {
      return null;
    }
    return {
      id: subscription.id,
      description: subscription.description,
      wsProductType: subscription.productType as string as WsProductType,
      productWsRef: subscription.productRef,
      customerWsRef: subscription.customerRef,
      active: subscription.active,
      archived: subscription.archived,
      imported: subscription.imported,
      lastImportDateTime: DateConverterUtils.formatDateToIsoDateTime(subscription.lastImportDateTime),
      creationDateTime: DateConverterUtils.formatDateToIsoDateTime(subscription.creationDateTime),
      updateDateTime: DateConverterUtils.formatDateToIsoDateTime(subscription.updateDateTime),
      startDate: DateConverterUtils.formatDateToIsoDate(subscription.startDate),
      endDate: DateConverterUtils.formatDateToIsoDate(subscription.endDate),
      recurrence: EnumConverterUtils.convertValue(subscription.recurrence, WsRecurrence),
      previousBrokerDescription: subscription.previousBrokerDescription,
      totalPricing: subscription.totalPricing,
      notes: subscription.notes,
      providerReference: subscription.providerReference,
      internalReference: subscription.internalReference,
      parentSubscriptionRef: subscription.parentSubscriptionRef,
      productionYear: subscription.productionYear,
      mandated: subscription.mandated,
      sourcingContactInfo: subscription.sourcingContactInfo,
      contactMail: subscription.contactMail,
      contactRequestedDate: DateConverterUtils.formatDateToIsoDate(subscription.contactRequestedDate),
      contactRequestDescription: subscription.contactRequestDescription,
      logRef: subscription.logRef,
      yearlyPricing: subscription.yearlyPricing,
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsSubscription]: keyof Subscription } {
    return {
      id: 'id',
      description: 'description',
      wsProductType: 'productType',
      productWsRef: 'productRef',
      customerWsRef: 'customerRef',
      active: 'active',
      archived: 'archived',
      imported: 'imported',
      lastImportDateTime: 'lastImportDateTime',
      creationDateTime: 'creationDateTime',
      updateDateTime: 'updateDateTime',
      startDate: 'startDate',
      endDate: 'endDate',
      recurrence: 'recurrence',
      previousBrokerDescription: 'previousBrokerDescription',
      totalPricing: 'totalPricing',
      providerReference: 'providerReference',
      internalReference: 'internalReference',
      notes: 'notes',
      parentSubscriptionRef: 'parentSubscriptionRef',
      productionYear: 'productionYear',
      mandated: 'mandated',
      sourcingContactInfo: 'sourcingContactInfo',
      contactMail: 'contactMail',
      contactRequestedDate: 'contactRequestedDate',
      'contactRequestDescription': 'contactRequestDescription',
      logRef: 'logRef',
      yearlyPricing: 'yearlyPricing',
    };
  }

  static convertFilterIn(value?: WsSubscriptionSearch): SubscriptionSearch {
    if (value == null) {
      return undefined;
    }
    return {
      multiSearch: value.multiSearch,
      descriptionContains: value.descriptionContains,
      providerReference: value.providerReference,
      providerReferenceContains: value.providerReferenceContains,
      internalReference: value.internalReference,
      internalReferenceContains: value.internalReferenceContains,
      notesContains: value.notesContains,
      active: value.active,
      archived: value.archived,
      customerSearch: CustomerSearchConverter.toCustomerSearch(value.customerSearch),
      productSearch: ProductConverter.convertFilterIn(value.productSearch),
      dateRangeSearch: DateRangeSearchConverter.convertIn(value.dateRangeSearch),
      recurring: value.recurring,
      recurrence: EnumConverterUtils.convertValue(value.recurrence, Recurrence),
      subscriptionRef: value.subscriptionWsRef,
      parentSubscriptionRef: value.parentSubscriptionWsRef,
      withParentSubscription: value.withParentSubscription,
      productionYear: value.productionYear,
      mandated: value.mandated,
      contactRequestDateSearch: DateSearchConverter.convertIn(value.contactRequestedDateSearch),
    };
  }

  static convertFilterOut(value: SubscriptionSearch): WsSubscriptionSearch {
    if (value == null) {
      return undefined;
    }
    return {
      multiSearch: value.multiSearch,
      descriptionContains: value.descriptionContains,
      providerReference: value.providerReference,
      providerReferenceContains: value.providerReferenceContains,
      internalReference: value.internalReference,
      internalReferenceContains: value.internalReferenceContains,
      notesContains: value.notesContains,
      active: value.active,
      archived: value.archived,
      customerSearch: CustomerSearchConverter.toWsCustomerSearch(value.customerSearch),
      productSearch: ProductConverter.convertFilterOut(value.productSearch),
      dateRangeSearch: DateRangeSearchConverter.convertOut(value.dateRangeSearch),
      recurring: value.recurring,
      recurrence: EnumConverterUtils.convertValue(value.recurrence, WsRecurrence),
      subscriptionWsRef: value.subscriptionRef,
      parentSubscriptionWsRef: value.parentSubscriptionRef,
      withParentSubscription: value.withParentSubscription,
      productionYear: value.productionYear,
      mandated: value.mandated,
      contactRequestedDateSearch: DateSearchConverter.convertOut(value.contactRequestDateSearch),
    };
  }

}
