<span class="contact-type"
      [class.customer-access]="customerDataAccess$ | async"
      [class.internal]="internal$ | async"
      [class]="contactTypeSource$ | async"
>
  <span class="label">{{ (label$ | async) }}</span>
  <span *ngIf="showInfoBadges && (customerDataAccess$ | async)"
        class="lifeislife-badge contact-type-colored customer-contact-type customer-access"
        title="Donne accès à l'application client"
  >
    app client
  </span>
  <span *ngIf="showInfoBadges && (internal$ | async)"
        class="lifeislife-badge contact-type-colored customer-contact-type internal"
        title="Contact interne à la société"
  >
    interne
  </span>

</span>
