import {FileUploadProgress} from './file-upload-progress';
import {concat, Observable, of} from 'rxjs';
import {defaultIfEmpty, last, map, publishReplay, refCount} from 'rxjs/operators';
import {FilesUploadProgress} from './files-upload-progress';
import {StoredFile} from '../../domain/file/stored-file';
import {Ref} from '../../domain/shared/ref';
import {SingleFileUploadProgress} from './upload-progress';

export class UploadProgressUtils {

  static createFileUploadProgress$(file: File, uploadProgress$: Observable<SingleFileUploadProgress>): Observable<FileUploadProgress> {
    const inProgress$ = uploadProgress$.pipe(
      map(uploaded => this.createFileUploadProgress(uploaded, file)),
      publishReplay(1), refCount(),
    );
    const lasProgress$ = inProgress$.pipe(
      last(),
      map(p => Object.assign({}, p, <Partial<FileUploadProgress>>{
        completed: true,
      })),
      defaultIfEmpty(this.createCompletedFileUploadProgress(file)),
    );
    return concat(
      of(this.createInitialFileUploadProgress(file)),
      inProgress$,
      lasProgress$,
    );
  }

  static createFilesUploadProgess(fileProgressList: FileUploadProgress[], bytesCount: number, fileCount: number): FilesUploadProgress {
    const byteUploaded = fileProgressList.reduce((c, n) => c + n.bytesUploaded, 0);
    const fileUploaded = fileProgressList.filter(f => f.completed).length;
    const files = fileProgressList.map(f => f.file);
    const byteProgressPercent = parseFloat(((byteUploaded / bytesCount) * 100).toFixed(1));
    const erroredFiles = fileProgressList.filter(f => f.errored).map(f => f.file);
    const errored = erroredFiles.length > 0;
    const createdFiles = fileProgressList.reduce((c, n) => [...c, n.createdFile], [])
      .filter(f => f != null);

    return {
      files: files,
      fileCount: fileCount,
      fileUploaded: fileUploaded,

      bytesCount: bytesCount,
      bytesUploaded: byteUploaded,
      bytesPercent: byteProgressPercent,

      errored: errored,
      erroredFiles: erroredFiles,
      createdFiles: createdFiles,
    };
  }

  static createErroredFileUploadProgress(error: Error | any, file: File) {
    return {
      file: file,
      bytesUploaded: file.size,
      bytesPercent: 100,
      bytesCount: file.size,
      errored: true,
      error: error,
      completed: true,
    };
  }

  private static createFileUploadProgress(uploadProgress: SingleFileUploadProgress, file: File): FileUploadProgress {
    const total = file.size;
    let percent = 0;
    if (typeof uploadProgress === 'number') {
      if (!isNaN(total)) {
        percent = parseFloat((100 * uploadProgress / total).toFixed(1));
      }
      return {
        file: file,
        bytesCount: total,
        bytesUploaded: uploadProgress,
        bytesPercent: percent,
        completed: uploadProgress >= total,
      };
    } else if (uploadProgress != null) {
      return {
        file: file,
        bytesCount: total,
        bytesUploaded: total,
        bytesPercent: 100,
        completed: true,
        createdFile: uploadProgress,
      };
    } else {
      throw new Error(`Unexpected empty result`);
    }
  }


  private static createInitialFileUploadProgress(file: File): FileUploadProgress {
    const total = file.size;
    return {
      file: file,
      bytesCount: total,
      bytesUploaded: 0,
      bytesPercent: 0,
      completed: false,
    };
  }


  private static createCompletedFileUploadProgress(file: File): FileUploadProgress {
    const total = file.size;
    return {
      file: file,
      bytesCount: total,
      bytesUploaded: total,
      bytesPercent: 100,
      completed: true,
    };
  }

}
