import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppHeaderComponent} from './app-header/app-header.component';
import {AppMenuComponent} from './app-menu/app-menu.component';
import {FormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';

import {ButtonModule} from 'primeng/button';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import {ListboxModule} from 'primeng/listbox';
import {MenubarModule} from 'primeng/menubar';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {OverlayPanelModule} from 'primeng/overlaypanel';


import {MainUserModule} from '../main/components/user/main-user.module';
import {AccountCreationRouteComponent} from './account-creation-route/account-creation-route.component';
import {MainContactComponentsModule} from '../main/components/contact/main-contact-components.module';
import {MainModule} from '../main/main.module';
import {MainTrusteeComponentsModule} from '../main/components/trustee/main-trustee-components.module';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {AppShellComponent} from './app-shell/app-shell.component';
import {ToastModule} from 'primeng/toast';
import {AppShellPageComponent} from './app-shell-page/app-shell-page.component';
import {ShellToolbarLeftDirective} from './shell-toolbar-left.directive';
import {ShellToolbarRightDirective} from './shell-toolbar-right.directive';
import {ShellContentHeaderDirective} from './shell-content-header.directive';
import {ShellTableComponent} from './shell-table/shell-table.component';
import {TableModule} from 'primeng/table';
import {ShellCountLabelDirective} from './shell-count-label.directive';
import {ShellSelectionActionsDirective} from './shell-selection-actions.directive';
import {ShellTableColumnDirective} from './shell-table-column.directive';
import {ShellFormToolbarComponent} from './shell-form-toolbar/shell-form-toolbar.component';
import {ShellModuleDirective} from './shell-module.directive';
import {ShellContactsSideFormComponent} from './shell-contacts-side-form/shell-contacts-side-form.component';
import {WithContactSelectListItemDirective} from './shell-contacts-side-form/with-contact-select-list-item.directive';
import {WithContactInfoHeaderDirective} from './shell-contacts-side-form/with-contact-info-header.directive';
import {WithContactFormHeaderDirective} from './shell-contacts-side-form/with-contact-form-header.directive';
import {IdentitySwitcherComponent} from './identity-switcher/identity-switcher.component';
import {MainTrusteeContactComponentsModule} from '../main/components/trustee-contact/main-trustee-contact-components.module';
import {MainCustomerContactComponentsModule} from '../main/components/customer-contact/main-customer-contact-components.module';
import {MainCustomerModule} from '../main/components/customer/main-customer.module';
import {ShellTableSearchFilterComponent} from './shell-table-search-filter/shell-table-search-filter.component';
import {CustomerFormModule} from '../main/components/customer/customer-form/customer-form.module';
import {ShellTableRowGroupHeaderDirective} from './shell-table-row-group-header.directive';
import {TabViewModule} from 'primeng/tabview';
import {WithContactAddExistingFormDirective} from './shell-contacts-side-form/with-contact-add-existing-form.directive';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {PasswordModule} from 'primeng/password';
import {CustomerContactTypeModule} from '../main/components/customer-contact/customer-contact-type/customer-contact-type.module';
import {TrusteeContactTypeModule} from '../main/components/trustee-contact/trustee-contact-type/trustee-contact-type.module';

@NgModule({
  declarations: [
    AppHeaderComponent,
    AppMenuComponent,
    LoginComponent,
    AccountCreationRouteComponent,
    BreadcrumbComponent,
    AppShellComponent,
    AppShellPageComponent,
    ShellToolbarLeftDirective,
    ShellToolbarRightDirective,
    ShellContentHeaderDirective,
    ShellTableComponent,
    ShellCountLabelDirective,
    ShellSelectionActionsDirective,
    ShellTableColumnDirective,
    ShellFormToolbarComponent,
    ShellModuleDirective,
    ShellContactsSideFormComponent,
    WithContactSelectListItemDirective,
    WithContactInfoHeaderDirective,
    WithContactFormHeaderDirective,
    WithContactAddExistingFormDirective,
    IdentitySwitcherComponent,
    ShellTableSearchFilterComponent,
    ShellTableRowGroupHeaderDirective,
  ],
  imports: [
    MainModule,
    CommonModule,
    FormsModule,

    TieredMenuModule,
    MenubarModule,
    MainUserModule,
    SlideMenuModule,
    ButtonModule,
    MainContactComponentsModule,
    InputTextModule,
    MainTrusteeComponentsModule,
    BreadcrumbModule,
    ToastModule,
    TableModule,
    MultiSelectModule,
    MessageModule,
    ListboxModule,
    MainTrusteeContactComponentsModule,
    MainCustomerContactComponentsModule,
    MainCustomerModule,
    SelectButtonModule,
    OverlayPanelModule,
    CustomerFormModule,
    DropdownModule,
    SplitButtonModule,
    TabViewModule,
    ConfirmDialogModule,
    PasswordModule,
    CustomerContactTypeModule,
    TrusteeContactTypeModule,
  ],
  exports: [
    AppHeaderComponent,
    LoginComponent,
    AccountCreationRouteComponent,
    AppShellComponent,
    AppShellPageComponent,
    ShellToolbarLeftDirective,
    ShellToolbarRightDirective,
    ShellContentHeaderDirective,
    ShellTableComponent,
    ShellCountLabelDirective,
    ShellSelectionActionsDirective,
    ShellTableColumnDirective,
    ShellFormToolbarComponent,
    ShellModuleDirective,
    ShellContactsSideFormComponent,
    WithContactSelectListItemDirective,
    WithContactInfoHeaderDirective,
    WithContactFormHeaderDirective,
    WithContactAddExistingFormDirective,
    ShellTableSearchFilterComponent,
    ShellTableRowGroupHeaderDirective,
  ],
})
export class ShellModule {
}
