import {Injectable} from '@angular/core';
import {UserAuthService} from '../../util/auth/user-auth.service';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {Contact} from '../../domain/contact/contact';
import {Ref} from '../../domain/shared/ref';
import {forkJoin, Observable, of} from 'rxjs';
import {PensionCareerStatus} from '../../domain/retire/pension-career-status';
import {PensionCareerStatusWsClient} from '../../client/resources/front/retire/pension-career-status-ws-client';
import {
  WsCareerPeriodDetails,
  WsCareerPeriodDetailsSearch,
  WsPensionCareerStatus,
  WsPensionCareerStatusSearch,
  WsRef,
} from '@lifeislife/lifeislife-ws-api';
import {SearchResult} from '../../client/domain/search/search-result';
import {SimplePaginationUtils} from '../../util/pagination/simple-pagination-utils';
import {CareerPeriodDetails} from '../../domain/retire/career-period-details';
import {CareerPeriodDetailsWsClient} from '../../client/resources/front/retire/career-period-details-ws-client';
import {CareerDetailsType} from '../../domain/retire/career-details-type';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {PensionCareerStatusConverter} from './pension-career-status-converter';
import {CareerPeriodDetailsConverter} from './career-period-details-converter';

@Injectable({
  providedIn: 'root',
})
export class LegalPensionCareerDetailsService {


  constructor(
    private authService: UserAuthService,
    private pensionCareerStatusWsClient: PensionCareerStatusWsClient,
    private careerPeriodDetailsWsClient: CareerPeriodDetailsWsClient,
  ) {
  }

  getMyLegalPensionCareerStatus$_(): Observable<PensionCareerStatus> {
    return this.authService.state$.pipe(
      map(s => s == null ? null : s.contactRef),
      switchMap(ref => this.searchLegalPensionStatus$(ref)),
      map(r => this.findSingleStatus(r)),
      publishReplay(1), refCount(),
    );
  }

  getPensionCareerStatus$(ref: WsRef<WsPensionCareerStatus>): Observable<PensionCareerStatus> {
    return this.pensionCareerStatusWsClient.doGet(ref.id).pipe(
      map(ws => PensionCareerStatusConverter.convertIn(ws)),
    );
  }

  searchCareerPeriodDetails$(careerStatusWsRef: WsRef<WsPensionCareerStatus>, pagination: SimplePagination): Observable<SearchResult<CareerPeriodDetails>> {
    const wsSearch: WsCareerPeriodDetailsSearch = {
      pensionCareerStatusWsRef: careerStatusWsRef,
      valid: undefined,
      careerType: undefined,
    };
    return this.careerPeriodDetailsWsClient.doSearch(wsSearch, pagination).pipe(
      switchMap(r => this.convertCareerDetailsResults$(r)),
    );
  }

  getCareerPeriodDetails$(ref: WsRef<WsCareerPeriodDetails>): Observable<CareerPeriodDetails> {
    return this.careerPeriodDetailsWsClient.doGet(ref.id).pipe(
      map(ws => CareerPeriodDetailsConverter.convertAnyIn(ws)),
    );
  }

  getCareerTypeLabel(type: CareerDetailsType): string {
    if (type == null) {
      return '';
    }
    switch (type) {
      case CareerDetailsType.EMPLOYEE:
        return `Employé`;
      case CareerDetailsType.SELF_EMPLOYED:
        return `Indépendant`;
      case CareerDetailsType.CIVIL_SERVANT:
        return `Fonctionnaire`;
      case CareerDetailsType.MILITARY:
        return `Militaire`;
      case CareerDetailsType.MILITARY_AS_EMPLOYEE:
        return `Employé militaire`;
      case CareerDetailsType.MILITARY_AS_CIVIL_SERVANT:
        return `Fonctionnaire militaire`;
      case CareerDetailsType.DIPLOMA:
        return `Diplôme`;
      case CareerDetailsType.FOREIGN:
        return `Étrangé`;
      case CareerDetailsType.SUPRANATIONAL:
        return `Supranational`;
      case CareerDetailsType.POLITIC:
        return `Politique`;
      case CareerDetailsType.OVERSEA:
        return `Expat`;
      default:
        return type as string;
    }
  }

  private searchLegalPensionStatus$(contactRef: Ref<Contact>): Observable<SearchResult<PensionCareerStatus>> {
    if (contactRef == null) {
      return of(new SearchResult<PensionCareerStatus>());
    }
    const wsSearch: WsPensionCareerStatusSearch = {
      contactWsRef: contactRef,
    };
    const pagination = SimplePaginationUtils.newPagination(3);
    return this.pensionCareerStatusWsClient.doSearch(wsSearch, pagination).pipe(
      switchMap(r => this.convertPensionCareerStatusSearchResults$(r)),
    );
  }

  private convertPensionCareerStatusSearchResults$(results: SearchResult<WsRef<WsPensionCareerStatus>>) {
    const status$List = results.list.map(ref => this.getPensionCareerStatus$(ref));
    const statusList$ = status$List.length === 0 ? of([]) : forkJoin(status$List);
    return statusList$.pipe(
      map(r => Object.assign({}, results, {
        list: r,
      }) as SearchResult<PensionCareerStatus>),
    );
  }


  private findSingleStatus(results: SearchResult<PensionCareerStatus>) {
    if (results.list.length === 0) {
      return null;
    }
    if (results.list.length > 1) {
      console.warn('Multiple career status');
      console.warn(results.list);
    }
    return results.list[0];
  }

  private convertCareerDetailsResults$(results: SearchResult<WsRef<WsCareerPeriodDetails>>): Observable<SearchResult<CareerPeriodDetails>> {
    const status$List = results.list.map(ref => this.getCareerPeriodDetails$(ref));
    const statusList$ = status$List.length === 0 ? of([]) : forkJoin(status$List);
    return statusList$.pipe(
      map(r => Object.assign({}, results, {
        list: r,
      }) as SearchResult<CareerPeriodDetails>),
    );
  }
}
