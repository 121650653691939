import {Component, Input, OnInit} from '@angular/core';
import {
  ContactService,
  TrusteeContact,
  TrusteeContactService,
  TrusteeService,
  TrusteeContactType,
} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {TrusteeContactTypeItemsService} from '../trustee-contact-type-items.service';

@Component({
  selector: 'app-trustee-contact-type-badge',
  templateUrl: './trustee-contact-type-badge.component.html',
  styleUrls: ['./trustee-contact-type-badge.component.scss'],
})
export class TrusteeContactTypeBadgeComponent implements OnInit {

  @Input()
  set trusteeContact(value: TrusteeContact) {
    this.value$.next(value);
  }

  @Input()
  showTrusteeLabelTitle = true;

  value$ = new BehaviorSubject<TrusteeContact>(null);
  contactType$: Observable<TrusteeContactType>;
  trusteeLabel$: Observable<string>;

  constructor(
    private trusteeService: TrusteeService,
    private contactService: ContactService,
    private trusteeContactService: TrusteeContactService,
    private trusteeContactItemService: TrusteeContactTypeItemsService,
  ) {
  }

  ngOnInit() {
    this.trusteeLabel$ = this.value$.pipe(
      switchMap(c => c == null ? of(null) : this.getTrusteeLabel$(c)),
      publishReplay(1), refCount(),
    );
    this.contactType$ = this.value$.pipe(
      map(v => v == null ? null : v.trusteeContactType),
      publishReplay(1), refCount(),
    );
  }

  private getTrusteeLabel$(trusteeContact: TrusteeContact) {
    if (trusteeContact == null) {
      return of(null);
    }

    const trusteeName$ = this.trusteeService.getTrusteeName$(trusteeContact.trusteeRef);
    const typeName = this.trusteeContactItemService.getTrusteeContactTypeLabel(trusteeContact.trusteeContactType);
    return trusteeName$.pipe(
      map(trusteeName => `${typeName} chez ${trusteeName}`),
    );
  }

}
