import {Injectable} from '@angular/core';
import {RequestService} from '../../service/request.service';
import {WsDaoEventEntityType, WsTrusteeContact} from '@lifeislife/lifeislife-ws-api';
import {CachedWsResourceClient} from '../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {AppConfigService} from '../../../service/config/app-config.service';
import {ResourceCacheService} from '../../../util/cache/resource-cache.service';

@Injectable({
  providedIn: 'root',
})
export class TrusteeContactWsClient extends CachedWsResourceClient<WsTrusteeContact, WsTrusteeContact> {

  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private injectedAppConfigService: AppConfigService) {
    super('/front/trusteeContact', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.TRUSTEE_CONTACT, injectectedCacheService);
  }

}
