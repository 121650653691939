import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsPersonTitle, WsPersonTitleFilter} from '@lifeislife/lifeislife-ws-api';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {PersonTitleConverter} from './person-title.converter';
import {PersonTitle} from '../../domain/person-title/person-title';
import {PersonTitleFilter} from '../../domain/person-title/person-title-filter';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {Trustee} from '../../domain/trustee/trustee';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {PersonTitleWsClient} from '../../client/resources/front/contact/person-title-ws-client';

@Injectable({
  providedIn: 'root',
})
export class PersonTitleService {


  constructor(private client: PersonTitleWsClient,
  ) {
  }

  getPersonTitle$(ref: Ref<PersonTitle>, forceFetch?: boolean): Observable<PersonTitle> {
    return !isValidRef(ref) ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsPersonTitle => PersonTitleConverter.convertIn(wsPersonTitle)),
    );
  }

  getPersonTitleLabel$(ref: Ref<PersonTitle>): Observable<string> {
    if (ref == null) {
      return of(null);
    }
    return this.getPersonTitle$(ref).pipe(
      map(personTitle => personTitle.title),
    );
  }

  searchPersonTitles$(personTitleFilter: PersonTitleFilter, pagination: SimplePagination): Observable<SearchResult<PersonTitle>> {
    const wsSearch = PersonTitleConverter.convertFilterOut(personTitleFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validatePersonTitle$(personTitle: PersonTitle): Observable<ValidationResult<PersonTitle>> {
    const wsPersonTitle = PersonTitleConverter.convertOut(personTitle);
    return this.client.doValidate(wsPersonTitle).pipe(
      map((results: WsPersonTitle | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  savePersonTitle$(personTitle: PersonTitle): Observable<any> {
    const wsPersonTitle = PersonTitleConverter.convertOut(personTitle);
    return this.client.doSave(wsPersonTitle);
  }

  deletePersonTitle$(id: number): Observable<any> {
    return this.client.doRemove(id);
  }

  createFilter(trusteeRef?: Ref<Trustee>): PersonTitleFilter {
    return PersonTitleConverter.convertFilterIn(<WsPersonTitleFilter>{});
  }

  serializeFilter(personTitleFilter: PersonTitleFilter): string {
    ObjectConverterUtil.cleanUnsetFilterValues(personTitleFilter);
    const wsPersonTitleSearch = PersonTitleConverter.convertFilterOut(personTitleFilter);
    return JSON.stringify(wsPersonTitleSearch);
  }

  deserializeFilter(valueString: string | null): PersonTitleFilter {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: any = JSON.parse(valueString);
      const filter = PersonTitleConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }


  private createValidationResult(results: WsPersonTitle | WsConstraintViolation[]): ValidationResult<PersonTitle> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsPersonTitle => PersonTitleConverter.convertIn(wsPersonTitle),
      propertyNameMappings: PersonTitleConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsPersonTitle>): Observable<SearchResult<PersonTitle>> {
    return of(Object.assign({}, results, <Partial<SearchResult<PersonTitle>>>{
      list: results.list.map(result => PersonTitleConverter.convertIn(result)),
    }));
  }
}
