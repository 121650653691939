import {WsCustomerBankAccount, WsCustomerBankAccountSearch} from '@lifeislife/lifeislife-ws-api';
import {CustomerBankAccount} from '../../domain/customer-bank-account/customer-bank-account';
import {CustomerBankAccountSearch} from '../../domain/customer-bank-account/customer-bank-account-search';
import {CustomerSearchConverter} from '../customer/customer-search.converter';

export class CustomerBankAccountConverter {


  static convertIn(value: WsCustomerBankAccount): CustomerBankAccount {
    return {
      id: value.id,
      version: value.version,
      iban: value.iban,
      bic: value.bic,
      privateAccount: value.privateAccount,
      customerRef: value.customerWsRef,
    };
  }

  static convertOut(value: CustomerBankAccount): WsCustomerBankAccount {
    return {
      id: value.id,
      version: value.version,
      iban: value.iban,
      bic: value.bic,
      privateAccount: value.privateAccount,
      customerWsRef: value.customerRef,
    };
  }


  static convertFilterIn(value?: WsCustomerBankAccountSearch): CustomerBankAccountSearch {
    if (value == null) {
      return undefined;
    }
    return {
      bicContains: value.bicContains,
      customerSearch: CustomerSearchConverter.toCustomerSearch(value.customerSearch),
      exactBic: value.exactBic,
      exactIban: value.exactIban,
      ibanContains: value.ibanContains,
      privateAccount: value.privateAccount,
    };
  }

  static convertFilterOut(value: CustomerBankAccountSearch): WsCustomerBankAccountSearch {
    if (value == null) {
      return undefined;
    }
    return {
      bicContains: value.bicContains,
      customerSearch: CustomerSearchConverter.toWsCustomerSearch(value.customerSearch),
      exactBic: value.exactBic,
      exactIban: value.exactIban,
      ibanContains: value.ibanContains,
      privateAccount: value.privateAccount,
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsCustomerBankAccount]: keyof CustomerBankAccount } {
    return {
      id: 'id',
      version: 'version',
      iban: 'iban',
      bic: 'bic',
      privateAccount: 'privateAccount',
      customerWsRef: 'customerRef',
    };
  }

}
