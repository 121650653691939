import {ErrorHandler, Inject, Injectable, Optional} from '@angular/core';
import {AuthService} from './auth.service';
import {concat, Observable, of, throwError} from 'rxjs';
import {AuthState} from './auth-state';
import {catchError, debounceTime, defaultIfEmpty, filter, map, publishReplay, refCount, switchMap, take, tap} from 'rxjs/operators';
import {AuthFactory} from './auth-factory';
import {USER_AUTH_TOKEN} from './user-auth-token';
import {User} from '../../domain/user/user';
import {WsUserTokenAuth} from '../../client/domain/auth/ws-user-token-auth';
import {isOidcAuth, isUserTokenAuth, LifeisLifeWsAuth} from '../../client/domain/auth/lifeis-life-ws-auth';
import {WsBasicAuth} from '../../client/domain/auth/ws-basic-auth';
import {Role} from '../../domain/role/role';
import {Base64Encoder} from '../base64/base64-encoder';
import {ContactService} from '../../service/contact/contact.service';
import {UserAuthService} from './user-auth.service';
import {ParamMap, Router} from '@angular/router';
import {AuthCodeResolver} from './auth-code-resolver';
import {InviteAuthService} from './invite-auth.service';
import {OAuthEvent, OAuthService} from 'angular-oauth2-oidc';
import {AppConfigService} from '../../service/config/app-config.service';
import {Location} from '@angular/common';
import {WindowRef} from '../window/window-ref';
import {fromPromise} from 'rxjs/internal-compatibility';

/**
 * This is the main service that handles the user authentication state.
 *
 * The application auth state is managed by the authService.
 * The keycloak auth state is managed by the oAuthService.
 *
 * This service maintains coherence between the two, and checks every allowed authentication method
 * to set up the authenticated state correctly.
 */
@Injectable()
export class UserAuthServiceBrowser implements UserAuthService {

  state$: Observable<AuthState>;
  user$: Observable<User | null>;
  roles$: Observable<Role[]>;

  constructor(private authService: AuthService,
              private authCodeResolver: AuthCodeResolver,
              private appConfigService: AppConfigService,
              private errorService: ErrorHandler,
              private userInviteAuthService: InviteAuthService,
              private contactService: ContactService,
              @Inject(USER_AUTH_TOKEN) @Optional()
              private queryStringToken: string,
              private base64Encoder: Base64Encoder,
              @Inject(OAuthService) @Optional()
              private oauthService: OAuthService,
              private location: Location,
              private windowRef: WindowRef,
              private router: Router,
  ) {
    this.state$ = this.authService.getState$();
    this.user$ = this.authService.getState$().pipe(
      map(state => state.user),
      publishReplay(1), refCount(),
    );
    this.roles$ = this.authService.getState$().pipe(
      map(state => state.roles),
      publishReplay(1), refCount(),
    );
    if (this.oauthService) {
      this.oauthService.events
        .subscribe(a => this.handleOauthEvent(a));
    }

    // State is provided to config service in the 'initHandler' method 'completeAuth'.
    // this.state$.pipe(
    //   filter(a => a.status === 'authenticated' || a.status === 'deauthenticated'),
    //   distinctUntilChanged((a, b) => isSameRef(a.contactRef, b.contactRef)),
    // ).subscribe(s => this.appConfigService.setAuthStateContext(s));
  }

  restoreAuthIfRequired$(routeParams: ParamMap, inviteeRole?: Role.TRUSTEE | Role.CUSTOMER): Observable<AuthState> {
    // We use the 'of(null).pipe(switchMap))' trick to create cold observables, that wont perform any action unless
    // subscribed upon. This allows the concat() operator below to trigger them in the desired order.


    // Authenticated state already present - no need to restore
    const authState$ = of(null).pipe(
      switchMap(() => this.getNextSettledAuthState$()),
      filter(s => s.status === 'authenticated'),
    );

    // Invite code query param, used as an authentication token granting the INVITEE role
    // eg: an admin want to impersonate an user
    const inviteCode$ = of(null).pipe(
      switchMap(() => inviteeRole == null ? of(null) : this.userInviteAuthService.attemptRestoreFromQueryString$(inviteeRole)),
      catchError(e => {
        this.authService.setAuthStateError$(e);
        return throwError(e);
      }),
    );


    // Auth code param to exchange to lifeislife-ws for a WsUserToken
    // eg, the user clicked the open session link
    const authCode$ = of(null).pipe(
      switchMap(() => this.authCodeResolver.exchangeToken$(routeParams)),
      switchMap(auth => auth == null ? of(null) : this.authenticate$(auth, false, true)),
      catchError(e => {
        this.authService.setAuthStateError$(e);
        return throwError(e);
      }),
    );

    // Openid authentication, delegated to angular-auth-oidc-client.
    const oidcAuth$ = of(null).pipe(
      switchMap(() => this.checkOidcAuth$()),
      catchError(e => {
        this.authService.setAuthStateError$(e);
        return throwError(e);
      }),
    );

    // Auth already present in the storage, and refreshed
    // eg: user opens a new tab
    const restoredFromStorage$ = of(null).pipe(
      switchMap(() => this.attemptRestoreFromStorage$()),
    );

    // Try all method in order, return first positive result
    return concat(authState$, inviteCode$, authCode$, oidcAuth$, restoredFromStorage$).pipe(
      filter(s => s != null), // user or auth state
      take(1),
      switchMap(() => this.getNextSettledAuthState$()),
      defaultIfEmpty(null),
    );
  }

  authenticateBasic$(login: string, password: string, remember?: boolean): Observable<User> {
    const auth = new WsBasicAuth(login, password, this.base64Encoder);
    return this.authenticate$(auth, true, remember);
  }

  attemptRestoreFromStorage$(): Observable<User | null> {
    const curState = this.getCurAuthState();
    if (curState.restoreFromStorageAttempted) {
      return of(null);
    }
    if (this.isAuthenticated()) {
      return of(null);
    }
    this.authService.updateAuthState({
      restoreFromStorageAttempted: true,
    });
    const auth = this.authService.restoreAuthFromStorage();
    if (auth == null || auth.type !== 'user-token') {
      return of(null);
    }
    // Ensure we clear an expired auth from storage when refreshing it fails.
    // This prevents future attempts
    return this.refreshAuth$(<WsUserTokenAuth>auth).pipe();
  }

  attemptRestoreFromQueryString$(): Observable<User> {
    const curState = this.getCurAuthState();
    if (curState.restoreFromQueryTokenAttempted) {
      return of(null);
    }
    if (this.isAuthenticated()) {
      return of(null);
    }
    this.authService.updateAuthState({
      restoreFromQueryTokenAttempted: true,
    });
    if (this.queryStringToken == null) {
      return of(null);
    }
    const auth = AuthFactory.reviveTokenString(this.queryStringToken);
    if (auth == null || auth.type !== 'oauth') {
      return of(null);
    }
    return this.authService.getFreshAuth$(auth).pipe(
      switchMap(newAuth => this.refreshAuth$(newAuth)),
    );
  }

  refreshState() {
    const curState = this.getCurAuthState();
    if (!this.isAuthenticated()) {
      return;
    }

    this.authService.getFreshAuth$(curState.wsAuth)
      .subscribe(tokenAuth => {
        if (tokenAuth.type === 'user-token') {
          this.authService.refreshAuthenticationToken(tokenAuth, {});
        }
      });
  }

  deauthenticate(reason?: string, localLoggoff?: boolean) {
    const url = this.router.url;
    const urlTree = this.router.parseUrl(url);

    // Remove all probable auth params
    delete urlTree.queryParams['authCode'];
    delete urlTree.queryParams['customerId'];
    delete urlTree.queryParams['customerContactId'];
    delete urlTree.queryParams['inviteCode'];
    const saneRedirectUrl = urlTree.toString();

    this.authService.deauthtenticate(reason, {
      initHandler: s => this.completeAuth$(s),
    });
    if (this.oauthService && !localLoggoff) {
      const oidcLoggedIn = this.oauthService.getAccessToken() != null;
      const window = this.windowRef.getWindow();
      const origin = window == null ? null : window.document.location.origin;
      const sanePath = this.location.prepareExternalUrl(`/${saneRedirectUrl}`);
      const redirectUri = `${origin}${sanePath}`;

      const idToken = this.oauthService.getIdToken();
      if (idToken) {
        // Only initiate logout redirect if we are logged in with a token.
        // We want to use postLogoutUrl param, so we need to pass id_token_hint as well.
        this.oauthService.postLogoutRedirectUri = `${redirectUri}`;
        this.oauthService.logOut({
          'id_token_hint': idToken,
        });
      } else {
        // otherwise redirects directly
        this.location.replaceState(saneRedirectUrl);
      }
    } else {
      this.location.replaceState(saneRedirectUrl);
    }
  }

  isAuthenticated() {
    const curState = this.getCurAuthState();
    return curState.status === 'authenticated'
      && curState.user != null;
  }

  getLoggedUserOrThrow() {
    const curState = this.getCurAuthState();
    if (curState.user == null) {
      throw new Error('Not logged in');
    }
    return curState.user;
  }

  getNextUserOrNull$(): Observable<User | null> {
    return this.state$.pipe(
      map(s => s.user),
      take(1),
    );
  }

  authenticate$(auth: LifeisLifeWsAuth, showError: boolean,
                remember?: boolean): Observable<User> {
    const authTask$ = this.authService.authenticate$(auth, {
        showError: showError,
        initHandler: state => this.completeAuth$(state, remember),
      },
    ).pipe(
      catchError(e => {
        this.clearOidcAuthStorage();
        if (this.oauthService) {
          this.oauthService.logOut(false);
        }
        return throwError(e);
      }),
      publishReplay(1), refCount(),
    );
    return authTask$.pipe(
      map((state: AuthState) => state.user),
    );
  }

  private refreshAuth$(tokenAuth: LifeisLifeWsAuth, remember?: boolean): Observable<User> {
    // Always remove auth from storage - will be set back later if refresh succeeds.
    // For some reason, there is still a flux where an expired token is kept is storage, and auth
    // reattempted everytime, with a message 'Expired session' displayed to the user
    this.authService.removeAuthFromStorage();
    if (isUserTokenAuth(tokenAuth)) {
      const authTask$ = this.authService.refreshAuthenticationToken(tokenAuth, {
          initHandler: state => this.completeAuth$(state, remember),
          showError: true,
        },
      ).pipe(
        publishReplay(1), refCount(),
      );
      return authTask$.pipe(
        map((state: AuthState) => state.user),
      );
    } else {
      return of(null);
    }
  }

  private completeAuth$(state: AuthState, remember?: boolean): Observable<AuthState> {
    let remberLogin = remember === true;
    const wsAuth = state.wsAuth;

    // Clear our auth storage, and we store it later according to the flag parameter
    this.clearLifeisLifeAuthStorage();

    if (wsAuth == null) {
      return of(state);
    }

    if (remember == null) {
      remember = wsAuth.type === 'user-token';
      remberLogin = state.user != null;
    }
    if (isOidcAuth(wsAuth)) {
      const refreshToken = this.oauthService.getRefreshToken();
      wsAuth.refreshToken = refreshToken;
    }

    // Ensure config is set
    this.appConfigService.setAuthStateContext(state);

    // Only act on the remember flag for user token auth.
    // Oidc tokens are stored by lib through oauthstorage service
    const userTokenAuth = isUserTokenAuth(state.wsAuth);
    if (userTokenAuth) {
      if (remember) {
        this.authService.saveAuthToStorage(wsAuth);
      }

      if (remberLogin) {
        const contactRef = state.contactRef;
        return this.contactService.getContact$(contactRef).pipe(
          tap(contact => {
            this.authService.saveLastUsedLoginToStorage(state.user.login);
            this.authService.saveLastUsedEmailToStorage(contact.email);
          }),
          map(() => state),
        );
      } else {
        this.authService.saveLastUsedLoginToStorage(null);
        this.authService.saveLastUsedEmailToStorage(null);
        return of(state);
      }
    } else {
      return of(state);
    }
  }

  private getCurAuthState(): AuthState {
    const curState = this.authService.getState();
    return curState;
  }


  private getNextSettledAuthState$(): Observable<AuthState> {
    return this.state$.pipe(
      debounceTime(50),
      filter(s => s.status !== 'authenticating'),
      take(1),
    );
  }

  private authenticateWithOidToken$(token: string, refreshToken?: string) {
    if (token == null) {
      return of(null);
    }

    // Ignore if already authenticated/authenticating with this oidc token.
    const curState = this.getCurAuthState();
    if (this.isAuthenticated() || curState.status === 'authenticating') {
      const curWsAuth = curState.wsAuth;
      if (curWsAuth && isOidcAuth(curWsAuth) && curWsAuth.token === token) {
        return of(curState);
      }
    }

    // console.log('reauthenticating with new oidc token ' + token);
    // this.deauthenticateIfRequired();

    const wsAuthToken = WsUserTokenAuth.forOidcToken(token, refreshToken);
    return this.authenticate$(wsAuthToken, false, false);
  }

  private checkOidcAuth$() {
    if (!this.oauthService) {
      return of(null);
    }
    // this.oauthService.checkSession();
    // Only checks the silent refresh
    const refreshToken = this.oauthService.getRefreshToken();
    if (refreshToken != null) {
      return fromPromise(this.oauthService.refreshToken()).pipe(
        switchMap(t => this.authenticateWithOidToken$(t.access_token, t.refresh_token)),
        catchError(e => fromPromise(this.oauthService.revokeTokenAndLogout(false, false))),
        catchError(e => of(null)),
        defaultIfEmpty(() => null),
      );
    } else {
      return of(null);
    }
  }

  private handleOauthEvent(e: OAuthEvent) {
    // console.log(e);

    if (e.type === 'code_error') {
      const codeError: any = e['params'];
      if (codeError.error && codeError.error === 'login_required') {
        console.warn(`Not logged in at keycloak`);
        // Just ensure we have no keycloak token stored here.
        // we have to avoid a redirect if not needed, as other query params may be parsed already to grant an auth token,
        // and a redirect to the same url seems to break that.
        if (this.oauthService) {
          this.oauthService.logOut(true);
        }
      } else {
        this.deauthenticateDirect(false, true, `Erreur lors de la restauration de la session`);
      }

    } else if (e.type === 'token_refreshed') {
      // Clear lifeislife auth storage, so that only one is active at any time
      this.clearLifeisLifeAuthStorage();
      const token = this.oauthService.getAccessToken();
      const refreshToken = this.oauthService.getRefreshToken();

      this.state$.pipe(
        take(1),
        // Only re-authenticate if deauthenticated or authenenticated with anonther auth type.
        // If already authenticated or authenticating with the same oidc token, ignore
        filter(s => s.status === 'deauthenticated' || !isOidcAuth(s.wsAuth) || !this.isSameTokenAsInState(s.wsAuth, token)),
        switchMap(() => this.authenticateWithOidToken$(token, refreshToken)),
      ).subscribe(() => this.onReauthenticatedWithNewOidcToken(),
        error => this.onOauthTokenReauthenticationerror(error));
    } else if (e.type === 'logout') {
      // Only deauthenticate if the active lil auth is a keycloak token. We may have parsed another token we need to keep.
      // Do not deauthenticate from keycloak, as this will trigger this event again
      const curState = this.getCurAuthState();
      if (isOidcAuth(curState.wsAuth)) {
        this.deauthenticateDirect(true, false);
      }
    }
  }

  private onOauthTokenReauthenticationerror(error: any) {
    console.warn(`Unable to reauthenticate with new oauth token`);
    console.warn(error);
    this.deauthenticateDirect(true, true, `Erreur lors de l'ouverture de la session`);
    this.errorService.handleError(error);
  }

  private onReauthenticatedWithNewOidcToken() {
    // console.log(`Reauthenticated with new token`);
  }


  /**
   * Deauthenticate directly from one or both providers (lifeislife auth sessions handled here, or keycloak sesssions).
   * This is required to maintain consistency between providers. For instance, if misconfiguration cause a keycloak token
   * to be granted but rejected by the lifeislife api, we must ensure we deauthenticate from both side.
   * @param lifeislifeAUth
   * @param keycloakAuth
   * @param reason
   * @private
   */
  private deauthenticateDirect(lifeislifeAUth: boolean, keycloakAuth: boolean, reason?: string) {
    if (lifeislifeAUth) {
      const curState = this.getCurAuthState();
      if (curState.status !== 'deauthenticated') {
        this.authService.deauthtenticate(reason, {
          initHandler: s => this.completeAuth$(s),
        });
      }
    }
    if (keycloakAuth && this.oauthService) {
      this.oauthService.logOut(false);
    }
  }

  private clearLifeisLifeAuthStorage() {
    this.authService.removeAuthFromStorage();
  }

  private clearOidcAuthStorage() {

  }

  private isSameTokenAsInState(wsAuth: WsUserTokenAuth, token: string) {
    const curToken = wsAuth.token;
    const newToken = token;
    return curToken === token;
  }
}
