import {
  WsSigningPackage,
  WsSigningPackageProvider,
  WsSigningPackageSearch,
  WsSigningPackageStatus,
  WsSigningPackageType,
} from '@lifeislife/lifeislife-ws-api';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {CustomerSearchConverter} from '../customer/customer-search.converter';
import {SigningPackage} from '../../domain/file/signing-package';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {SigningPackageType} from '../../domain/file/signing-package-type';
import {SigningPackageProvider} from '../../domain/file/signing-package-provider';
import {SigningPackageStatus} from '../../domain/file/signing-package-status';
import {SigningPackageSearch} from '../../domain/file/signing-package-search';
import {SubscriptionConverter} from '../corebusiness/subscription-converter';

export class SigningPackageConverter {

  static convertIn(value: WsSigningPackage): SigningPackage {
    return {
      id: value.id,
      version: value.version,
      creationDateTime: DateConverterUtils.parseIsoDateTime(value.creationDateTime),
      updateDateTime: DateConverterUtils.parseIsoDateTime(value.updateDateTime),
      customerRef: value.customerWsRef,
      name: value.name,
      packageType: EnumConverterUtils.convertValue(value.packageType, SigningPackageType),
      providerReference: value.providerReference,
      publicDescription: value.publicDescription,
      signersRedirectUri: value.signersRedirectUri,
      provider: EnumConverterUtils.convertValue(value.signingPackageProvider, SigningPackageProvider),
      status: EnumConverterUtils.convertValue(value.status, SigningPackageStatus),
      statusMessage: value.statusMessage,
    };
  }


  static convertOut(value: SigningPackage): WsSigningPackage {
    return {
      id: value.id,
      version: value.version,
      creationDateTime: DateConverterUtils.formatDateToIsoDateTime(value.creationDateTime),
      updateDateTime: DateConverterUtils.formatDateToIsoDateTime(value.updateDateTime),
      customerWsRef: value.customerRef,
      name: value.name,
      packageType: EnumConverterUtils.convertValue(value.packageType, WsSigningPackageType),
      providerReference: value.providerReference,
      publicDescription: value.publicDescription,
      signersRedirectUri: value.signersRedirectUri,
      signingPackageProvider: EnumConverterUtils.convertValue(value.provider, WsSigningPackageProvider),
      status: EnumConverterUtils.convertValue(value.status, WsSigningPackageStatus),
      statusMessage: value.statusMessage,
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsSigningPackage]: keyof SigningPackage } {
    return {
      id: 'id',
      version: 'version',
      creationDateTime: 'creationDateTime',
      updateDateTime: 'updateDateTime',
      customerWsRef: 'customerRef',
      name: 'name',
      packageType: 'packageType',
      providerReference: 'providerReference',
      publicDescription: 'publicDescription',
      signersRedirectUri: 'signersRedirectUri',
      signingPackageProvider: 'provider',
      status: 'status',
      statusMessage: 'statusMessage',
    };
  }

  static convertFilterIn(value: WsSigningPackageSearch): SigningPackageSearch {
    if (value == null) {
      return undefined;
    }
    return {
      exactPackageWsRef: value.exactPackageWsRef,
      providerReference: value.providerReference,
      packageProvider: EnumConverterUtils.convertValue(value.packageProvider, SigningPackageProvider),
      customerSearch: CustomerSearchConverter.toCustomerSearch(value.wsCustomerSearch),
      anyStatusList: value.anyStatusList == null ? null : value.anyStatusList
        .map(v => EnumConverterUtils.convertValue(v, WsSigningPackageStatus)),
      subscriptionSearch: SubscriptionConverter.convertFilterIn(value.wsSubscriptionSearch),
    };
  }


  static convertFilterOut(value: SigningPackageSearch): WsSigningPackageSearch {
    if (value == null) {
      return undefined;
    }
    return {
      exactPackageWsRef: value.exactPackageWsRef,
      providerReference: value.providerReference,
      packageProvider: EnumConverterUtils.convertValue(value.packageProvider, SigningPackageProvider),
      wsCustomerSearch: CustomerSearchConverter.toWsCustomerSearch(value.customerSearch),
      anyStatusList: value.anyStatusList == null ? null : value.anyStatusList
        .map(v => EnumConverterUtils.convertValue(v, SigningPackageStatus)),
      wsSubscriptionSearch: SubscriptionConverter.convertFilterOut(value.subscriptionSearch),
    };
  }
}
