<div class="customer-select">
  <p-autoComplete [ngModel]="selectedItem$ | async"
                  field="label"
                  dataKey="value?.id"
                  [suggestions]="suggestions$ | async"
                  [dropdown]="!disabled"
                  [readonly]="disabled"
                  [autoHighlight]="true"
                  [forceSelection]="true"
                  [placeholder]="noSelectionLabel"
                  [size]="25"
                  [minLength]="1"
                  [appendTo]="appendTo"
                  scrollHeight="auto"
                  (onSelect)="onItemSelect($event)"
                  (onClear)="onItemClear()"
                  (completeMethod)="searchSuggestions($event.query)"
                  (onDropdownClick)="searchAllSuggestions()"
                  [inputStyleClass]="hasError ? 'ng-dirty ng-invalid' : '' "
  >
    <ng-template let-item pTemplate="item">
      <div *ngIf="item.value == null">
        {{ item.label }}
      </div>
      <div class="contact" *ngIf="item.value != null">
        <app-contact-view [ref]="item.value"
          [showCompanyName]="showCompanyName"
          [hideContactName]="showCompanyName"
        ></app-contact-view>
      </div>

    </ng-template>

  </p-autoComplete>
</div>
