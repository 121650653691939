import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CountrySelectComponent} from './countrySelect/countrySelect';
import {CountryCodeSelectComponent} from './country-code-select/country-code-select.component';
import {CountryComponent} from './country/country.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    CountryComponent,
    CountrySelectComponent,
    CountryCodeSelectComponent,
    CountryComponent,
  ],
  imports: [
    CommonModule,
    AutoCompleteModule,
    FormsModule,
  ],
  exports: [
    CountryComponent,
    CountrySelectComponent,
    CountryCodeSelectComponent,
    CountryComponent,
  ],
})
export class MainCountryModule {
}
