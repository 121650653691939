import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsProductProvider} from '@lifeislife/lifeislife-ws-api';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Ref} from '../../domain/shared/ref';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {WithId} from '../../client/domain/with-id';
import {ProductProviderWsClient} from '../../client/resources/front/corebusiness/product-provider-ws-client';
import {ProductProviderConverter} from './product-provider-converter';
import {ProductProvider} from '../../domain/corebusiness/product-provider';
import {FileSubresourcePath} from '../../domain/file/file-subresource-path';
import {ContentDisposition} from '../../util/file/file-utils';

@Injectable({
  providedIn: 'root',
})
export class ProductProviderService {


  constructor(private client: ProductProviderWsClient,
  ) {
  }

  getProductProvider$(ref: Ref<ProductProvider>, forceFetch?: boolean): Observable<ProductProvider> {
    return ref == null ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsProductProvider => ProductProviderConverter.convertIn(wsProductProvider)),
    );
  }

  getNewLogoFilePath(ref: Ref<ProductProvider>): FileSubresourcePath<ProductProvider> {
    const path = this.client.getNewLogoFilePath(ref);
    return {
      ref: ref,
      fileWsPath: path,
    };
  }

  getLogoContentPath(ref: Ref<ProductProvider>, contentDisposition: ContentDisposition): Observable<string> {
    return this.client.getLogoFileDownloadUrl$(ref.id, contentDisposition);
  }

  validateProductProvider$(productProvider: ProductProvider): Observable<ValidationResult<ProductProvider>> {
    const wsProductProvider = ProductProviderConverter.convertOut(productProvider);
    return this.client.doValidate(wsProductProvider).pipe(
      map((results: WsProductProvider | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveProductProvider$(productProvider: ProductProvider): Observable<WithId> {
    const wsProductProvider = ProductProviderConverter.convertOut(productProvider);
    return this.client.doSave(wsProductProvider);
  }

  private createValidationResult(results: WsProductProvider | WsConstraintViolation[]): ValidationResult<ProductProvider> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsProductProvider => ProductProviderConverter.convertIn(wsProductProvider),
      propertyNameMappings: ProductProviderConverter.getValidationPropertyNameMappings(),
    });
  }

}
