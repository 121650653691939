import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {RoleOptionSelectItem} from './role-option-select-item';
import {AppShellContextProviderService} from '../app-shell-context-provider.service';
import {Observable} from 'rxjs';
import {delay, map, publishReplay, refCount, take, tap} from 'rxjs/operators';
import {AppShellAvailableContexts} from '../app-shell-available-contexts';
import {CustomerContact, Role, TrusteeContact} from '@lifeislife/lifeislife-domain';
import {APP_BASE_HREF} from '@angular/common';

@Component({
  selector: 'app-identity-switcher',
  templateUrl: './identity-switcher.component.html',
  styleUrls: ['./identity-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentitySwitcherComponent implements OnInit {

  rolesOptions$: Observable<RoleOptionSelectItem[]>;
  selectedRoleValue: string;

  constructor(
    private shellContextProvider: AppShellContextProviderService,
    @Inject(APP_BASE_HREF)
    public baseHref: string,
  ) {
  }

  ngOnInit() {
    this.rolesOptions$ = this.shellContextProvider.getAvailableContext$().pipe(
      map(contexts => this.createContextOptions(contexts)),
      delay(0),
      tap(options => this.selectFirstOption(options)),
      publishReplay(1), refCount(),
    );
  }

  onSubmit(valid: boolean) {
    if (!valid || this.selectedRoleValue == null) {
      return;
    }
    this.rolesOptions$.pipe(
      take(1),
      map(options => options.find(o => o.value === this.selectedRoleValue)),
    ).subscribe(value => this.setRolecontext(value));

  }

  private createContextOptions(contexts: AppShellAvailableContexts): RoleOptionSelectItem[] {
    // if (!contexts.loggedIn) {
    //   return [];
    // }
    const options = [];
    const roles = contexts.roles;
    if (roles.includes(Role.ADMIN)) {
      options.push(this.createAdminOption(contexts));
    }
    if (roles.includes(Role.TRUSTEE)) {
      options.push(...this.createAccountantOptions(contexts));
    }
    // if (roles.includes(Role.CUSTOMER)) {
    //   options.push(...this.createCustomerOptions(contexts));
    // }
    if (roles.includes(Role.INVITEE)) {
      options.push(...this.createInviteeOptions(contexts));
    }
    return options;
  }

  private createAdminOption(contexts: AppShellAvailableContexts): RoleOptionSelectItem {
    return {
      value: Role.ADMIN,
      label: '',
      role: Role.ADMIN,
      roleIcon: 'fa fa-user',
      trusteeContact: null,
      customerContact: null,
    };
  }

  private createAccountantOptions(contexts: AppShellAvailableContexts): RoleOptionSelectItem[] {
    return contexts.trusteeContact.map(
      trusteeContact => this.createTrusteeContatOption(Role.TRUSTEE, trusteeContact),
    );
  }

  private createTrusteeContatOption(role: Role, trusteeContact: TrusteeContact): RoleOptionSelectItem {
    return {
      value: `${role}-${trusteeContact.id}`,
      label: '',
      role: role,
      roleIcon: 'fa fa-building',
      customerContact: null,
      trusteeContact: trusteeContact,
    };
  }

  private createCustomerOptions(contexts: AppShellAvailableContexts): RoleOptionSelectItem[] {
    return contexts.customerContact.map(
      customerContact => this.createCustomerContatOption(Role.CUSTOMER, customerContact),
    );
  }

  private createCustomerContatOption(role: Role, customerContact: CustomerContact): RoleOptionSelectItem {
    return {
      value: `${role}-${customerContact.id}`,
      label: '',
      role: role,
      roleIcon: 'fa fa-user',
      trusteeContact: null,
      customerContact: customerContact,
    };
  }


  private createInviteeOptions(contexts: AppShellAvailableContexts): RoleOptionSelectItem[] {
    // const customerInviteeOptions = contexts.customerContact.map(
    //   customerContact => this.createCustomerContatOption(Role.INVITEE, customerContact),
    // );
    const trusteeInviteeOptions = contexts.trusteeContact.map(
      trusteeContact => this.createTrusteeContatOption(Role.INVITEE, trusteeContact),
    );
    return [...trusteeInviteeOptions];
  }

  private selectFirstOption(options: RoleOptionSelectItem[]) {
    if (options.length === 0) {
      this.selectedRoleValue = null;
    } else {
      this.selectedRoleValue = options[0].value;
    }
  }

  private setRolecontext(selectedRoleOption: RoleOptionSelectItem) {
    const role = selectedRoleOption.role;
    const trusteeContact = selectedRoleOption.trusteeContact;
    const customerContact = selectedRoleOption.customerContact;

    switch (role) {
      case Role.ADMIN: {
        this.shellContextProvider.activateAdminContext();
        break;
      }
      case Role.INVITEE: {
        if (trusteeContact != null) {
          this.shellContextProvider.activateInviteeTrusteeContext(trusteeContact);
        } else if (customerContact != null) {
          this.shellContextProvider.activateInviteeCustomerContext(customerContact);
        }
        break;
      }
      case Role.CUSTOMER: {
        if (customerContact != null) {
          this.shellContextProvider.activateCustomerContext(customerContact);
        }
        break;
      }
      case Role.TRUSTEE: {
        if (trusteeContact != null) {
          this.shellContextProvider.activateTrusteeContext(trusteeContact);
        }
      }
    }
  }
}
