import {Observable} from 'rxjs';
import {User} from '../../domain/user/user';
import {LifeisLifeWsAuth} from '../../client/domain/auth/lifeis-life-ws-auth';
import {AuthState} from './auth-state';
import {Role} from '../../domain/role/role';
import {ParamMap} from '@angular/router';

export abstract class UserAuthService {

  state$: Observable<AuthState>;
  user$: Observable<User | null>;
  roles$: Observable<Role[]>;

  /**
   * Attempt to authenticate if required:
   * - check authenticated, otherwise continue:
   * - check authCode in query. If authError present, throw it, otherwise
   *  -- exchange authCode and authenticate, or throw if that failed
   * - check invite code in query. If present
   *  -- authenticate using invite code
   * - attempt to restore auth from storage.
   *
   * The returned observable will emit a single value or throw an error.
   * The returned value will be null if authentication is not possible, or an User if it succeeded.
   * An error is throw if authentication failed.
   *
   * This method may updates the AuthService state, and set there messages to be displayed to the user.
   */
  abstract restoreAuthIfRequired$(routeParams: ParamMap, inviteeRole?: Role.TRUSTEE | Role.CUSTOMER): Observable<AuthState>;

  abstract authenticateBasic$(login: string, password: string, remember?: boolean): Observable<User>;

  abstract attemptRestoreFromQueryString$(): Observable<User>;

  abstract attemptRestoreFromStorage$(): Observable<User>;

  abstract refreshState();

  abstract deauthenticate(reason?: string, localLoggoff?: boolean);

  abstract isAuthenticated(): boolean;

  abstract getLoggedUserOrThrow(): User;

  abstract getNextUserOrNull$(): Observable<User | null>;

  abstract authenticate$(auth: LifeisLifeWsAuth, showError: boolean,
                         remember?: boolean): Observable<User>;

}
