import {WsSms, WsSmsSearch} from '@lifeislife/lifeislife-ws-api';
import {Sms} from '../../domain/sms/sms';
import {SmsFilter} from '../../domain/sms/sms-filter';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';

export class SmsConverter {

  static convertIn(value: WsSms): Sms {
    return {
      id: value.id,
      customerRef: value.customerRef,
      from: value.from,
      to: value.to,
      message: value.message,
      messageTypeName: value.messageTypeName,
      validity: value.validity,
      providerId: value.providerId,
      sentTime: DateConverterUtils.parseIsoDateTime(value.sentTime),
      trusteeRef: value.trusteeRef,
      mailBatchRef: value.mailBatchRef,
    };
  }

  static convertOut(value: Sms): WsSms {
    return {
      id: value.id,
      customerRef: value.customerRef,
      from: value.from,
      to: value.to,
      message: value.message,
      messageTypeName: value.messageTypeName,
      validity: value.validity,
      providerId: value.providerId,
      sentTime: DateConverterUtils.formatDateToIsoDateTime(value.sentTime),
      trusteeRef: value.trusteeRef,
      mailBatchRef: value.mailBatchRef,
    };
  }


  static convertFilterIn(value?: WsSmsSearch): SmsFilter {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      archived: value.archived,
      customerRef: value.customerRef,
      trusteeRef: value.trusteeRef,
      textContains: value.textContains,
      from: value.from,
      to: value.to,
      startDate: DateConverterUtils.parseIsoDateTime(value.startDate),
      endDate: DateConverterUtils.parseIsoDateTime(value.startDate),
    };
  }

  static convertFilterOut(value: SmsFilter): WsSmsSearch {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      archived: value.archived,
      customerRef: value.customerRef,
      trusteeRef: value.trusteeRef,
      textContains: value.textContains,
      from: value.from,
      to: value.to,
      startDate: DateConverterUtils.formatDateToIsoDateTime(value.startDate),
      endDate: DateConverterUtils.formatDateToIsoDateTime(value.startDate),
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsSms]: keyof Sms } {
    return {
      id: 'id',
      customerRef: 'customerRef',
      from: 'from',
      to: 'to',
      message: 'message',
      messageTypeName: 'messageTypeName',
      validity: 'validity',
      providerId: 'providerId',
      sentTime: 'sentTime',
      trusteeRef: 'trusteeRef',
      mailBatchRef: 'mailBatchRef',
    };
  }

}
