/**
 * Created by cghislai on 02/07/16.
 */
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {AppConfigService, FrontendAppConfigKey, WindowRef} from '@lifeislife/lifeislife-domain';


@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {

  private googleMapsInitialized: BehaviorSubject<boolean>;

  constructor(
    private configService: AppConfigService,
    private windowRef: WindowRef,
  ) {
    this.googleMapsInitialized = new BehaviorSubject<boolean>(false);
  }

  public loadMap(placeHolder: HTMLElement, coordinates: { lat: number, lng: number }, zoom: number = 10): Observable<any> {
    const window = this.windowRef.getWindow();
    return this.initGoogleMaps()
      .pipe(
        map(() => {
          return new window.google.maps.Map(placeHolder, {
            center: coordinates,
            zoom: zoom,
          });
        }),
      );
  }

  private initGoogleMaps(): Observable<boolean> {
    if (this.googleMapsInitialized.getValue()) {
      return of(true);
    }
    const publicKey = this.configService.getCurrentConfigValue(FrontendAppConfigKey.google_api_publicKey);
    if (publicKey == null) {
      console.warn(`No oogle public key for map services`);
      return of(false);
    }
    const self = this;
    const window = this.windowRef.getWindow();
    window.onGoogleMapsCallback = function () {
      self.googleMapsInitialized.next(true);
    };
    const srcUrl = `https://maps.googleapis.com/maps/api/js?key=${publicKey}&callback=onGoogleMapsCallback`;
    const script: HTMLScriptElement = document.createElement('script');
    script.src = srcUrl;
    script.type = 'text/javascript';
    script.defer = true;
    const head: HTMLHeadElement = <HTMLHeadElement>document.getElementsByTagName('head').item(0);
    head.appendChild(script);
    return this.googleMapsInitialized.pipe(
      filter(init => init),
      take(1),
    );
  }

}
