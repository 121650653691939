import {Injectable} from '@angular/core';
import {RequestService} from '../../service/request.service';
import {ResourceCacheService} from '../../../util/cache/resource-cache.service';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {AppConfigService} from '../../../service/config/app-config.service';
import {WsBatchExecution, WsJobName} from '@lifeislife/lifeislife-ws-api';
import {FrontendAppConfigKey} from '../../../domain/config/frontend-app-config-key';
import {Observable, of} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BatchWsClient {

  private jobNamesCache: WsJobName[] = null;

  constructor(private requestService: RequestService,
              private cacheService: ResourceCacheService,
              private authService: AuthProvider,
              private appConfigService: AppConfigService) {
  }

  getBatchExecution$(executionId: number): Observable<WsBatchExecution> {
    const uri = this.getResourceUri(`execution/${executionId}`);
    return this.requestService.sendRequest<WsBatchExecution>({
      method: 'GET',
      url: uri,
      throughServiceWorker: false,
    }, this.authService.getAuth());
  }


  getJobExecutionIdList$(jobName: string, offset?: number, size?: number): Observable<number[]> {
    const uri = this.getResourceUri(`job/${jobName}/execution/list`);
    const paramDict = {};
    if (offset && !isNaN(offset)) {
      paramDict['offset'] = offset;
    }
    if (size && !isNaN(size)) {
      paramDict['count'] = size;
    }
    const params = new HttpParams({
      fromObject: paramDict,
    });
    return this.requestService.sendRequest<number[]>({
      method: 'GET',
      url: uri,
      throughServiceWorker: false,
      params: params,
    }, this.authService.getAuth());
  }

  getJobNames$(): Observable<WsJobName[]> {
    if (this.jobNamesCache) {
      return of(this.jobNamesCache);
    }
    const uri = this.getResourceUri(`job/list`);
    return this.requestService.sendRequest<WsJobName[]>({
      method: 'GET',
      url: uri,
    }, this.authService.getAuth()).pipe(
      tap(a => this.jobNamesCache = a),
    );
  }


  private getResourceUri(subpath?: string) {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    return `${wsUri}/front/batch/${subpath || ''}`;
  }
}
