import {WsCredit, WsCreditSearch} from '@lifeislife/lifeislife-ws-api';
import {Credit} from '../../../domain/corebusiness/credit/credit';
import {CreditSearch} from '../../../domain/corebusiness/credit/credit-search';
import {ProductConverter} from '../product-converter';

export class CreditConverter {

  static convertIn(value: WsCredit): Credit {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      creditProviderRef: value.creditProviderWsRef,
      productRef: value.productWsRef,
    };
  }

  static convertOut(value: Credit): WsCredit {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      creditProviderWsRef: value.creditProviderRef,
      productWsRef: value.productRef,
    };
  }


  static convertFilterIn(value?: WsCreditSearch): CreditSearch {
    if (value == null) {
      return undefined;
    }
    return {
      creditProviderRef: value.creditProviderWsRef,
      creditRef: value.creditWsRef,
      productSearch: ProductConverter.convertFilterIn(value.wsProductSearch),
    };
  }

  static convertFilterOut(value: CreditSearch): WsCreditSearch {
    if (value == null) {
      return undefined;
    }
    return {
      creditProviderWsRef: value.creditProviderRef,
      creditWsRef: value.creditRef,
      wsProductSearch: ProductConverter.convertFilterOut(value.productSearch),
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsCredit]: keyof Credit } {
    return {
      id: 'id',
      creditProviderWsRef: 'creditProviderRef',
      productWsRef: 'productRef',
    };
  }

}
