import {WsInsuranceSubscriptionGroupSummary} from '@lifeislife/lifeislife-ws-api';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {InsuranceSubscriptionGroupSummary} from '../../domain/insurance/insurance-subscription-group-summary';
import {InsuranceSubscriptionGroupField} from '../../domain/insurance/insurance-subscription-group-field';

export class InsuranceSubscriptionGroupSummaryConverter {

  static convertIn(value: WsInsuranceSubscriptionGroupSummary): InsuranceSubscriptionGroupSummary {
    return {
      groupCount: value.groupCount,
      groupField: EnumConverterUtils.convertValue(value.groupField, InsuranceSubscriptionGroupField),
      groupId: value.groupId,
      groupLabel: value.groupLabel,
      totalPricingSum: value.totalPricingSum,
    };
  }

}
