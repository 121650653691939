export enum InsuranceCategoryFamily {
  MOBILITY = 'MOBILITY',
  HOME = 'HOME',
  WORK = 'WORK',
  INVESTMENTS = 'INVESTMENTS',
  RETIREMENT = 'RETIREMENT',
  INCOME_GUARANTEE = 'INCOME_GUARANTEE',
  HEALTH = 'HEALTH',
  FAMILY = 'FAMILY',
  DEATH = 'DEATH',
}
