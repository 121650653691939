import {SimplePagination} from '../../util/pagination/simple-pagination';
import {HttpParams} from '@angular/common/http';


export class SimplePaginationUtils {

  static toQueryParams(pagination: SimplePagination): HttpParams {
    if (pagination == null) {
      return new HttpParams();
    }

    const offset = pagination.offset;
    const length = pagination.length;
    const sorts = pagination.sorts || undefined;
    const groups = pagination.groups || undefined;
    const cols = pagination.displayColumns || undefined;

    const offsetParam = offset != null ? pagination.offset.toString() : undefined;
    const lengthParam = length != null ? pagination.length.toString() : undefined;

    let params = new HttpParams();
    if (offsetParam != null) {
      params = params.append('offset', offsetParam);
    }
    if (lengthParam != null) {
      params = params.append('length', lengthParam);
    }
    if (sorts != null) {
      const sortsValue = sorts
        .filter(s => s.field != null)
        .map(sort => {
          const orderParam = sort.order > 0 ? 'asc' : 'desc';
          const sortParam = `${sort.field}:${orderParam}`;
          return sortParam;
        })
        .reduce((c, n) => c == null ? n : `${c},${n}`, undefined);
      if (sortsValue) {
        params = params.append('sorts', sortsValue);
      }
    }
    if (groups != null) {
      const groupsValue = groups
        .reduce((c, n) => c == null ? n : `${c},${n}`, undefined);
      params = params.append('groups', groupsValue);
    }
    if (cols != null) {
      const colsValue = cols
        .reduce((c, n) => c == null ? n : `${c},${n}`, undefined);
      params = params.append('cols', colsValue);
    }

    return params;
  }

}
