import {Injectable} from '@angular/core';
import {InsuranceProvider, InsuranceProviderService} from '@lifeislife/lifeislife-domain';
import {forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {InsuranceProviderSelectItem} from './insurance-provider-select-item';

@Injectable({
  providedIn: 'root',
})
export class InsuranceProviderItemService {

  constructor(
    private insuranceProviderService: InsuranceProviderService,
  ) {
  }

  createInsuranceProviderItems$(insuranceProviders: InsuranceProvider[], options: {
    noSelectionLabel: string,
    includeNoSelectionOption: boolean,
    usePublicLabels: boolean,
  }): Observable<InsuranceProviderSelectItem[]> {
    let allInsuranceProviders: InsuranceProvider[];
    if (options.includeNoSelectionOption) {
      allInsuranceProviders = [null, ...insuranceProviders];
    } else {
      allInsuranceProviders = insuranceProviders;
    }
    const item$List = allInsuranceProviders.map(type => this.createInsuranceProviderItem$(type, options));
    const itemList$ = item$List.length === 0 ? of([]) : forkJoin(item$List);
    return itemList$;
  }

  createInsuranceProviderItem$(insuranceProvider: InsuranceProvider | null, options: {
    noSelectionLabel: string,
    usePublicLabels: boolean,
  }): Observable<InsuranceProviderSelectItem> {
    if (insuranceProvider == null) {
      return of({
        value: null,
        label: options.noSelectionLabel,
        insuranceProvider: null,
      });
    } else {
      return this.insuranceProviderService.getInsuranceProviderName$(insuranceProvider, options.usePublicLabels).pipe(
        map(name => <InsuranceProviderSelectItem>{
          value: {id: insuranceProvider.id},
          label: name,
          insuranceProvider: insuranceProvider,
        }),
      );
    }
  }

}
