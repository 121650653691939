import {Observable, Observer, of, Subscription, throwError} from 'rxjs';
import {LifeisLifeWsAuth} from '../domain/auth/lifeis-life-ws-auth';
import {catchError, map, switchMap, throwIfEmpty} from 'rxjs/operators';
import {StoredFile} from '../../domain/file/stored-file';
import {Ref} from '../../domain/shared/ref';
import {SingleFileUploadProgress} from '../../util/upload/upload-progress';
import {fromPromise} from 'rxjs/internal-compatibility';

export class FileUploadUtils {

  static getSanitizedFilenameForUpload(file: File) {
    const fileName = file.name;
    const dotSplitted = fileName.split('.');
    let extension = '';
    if (dotSplitted.length > 1) {
      extension = dotSplitted[dotSplitted.length - 1];
    }
    if (fileName.startsWith('image\.')) {
      // File export bug fix : multiple files with the same name exported on a file system overwrite each other.
      // Since iphone value uploading photos send 'image.jpg' files, we suffix them with a timestamp to avoid
      // this behaviour.
      const suffix = new Date().valueOf(); // unix time
      return `image-${suffix}.${extension}`;
    }

    return fileName;
  }


  static readFileData$(file: File): Observable<Blob> {
    const reader = new FileReader();
    const subscription = new Subscription(() => {
      try {
        if (reader != null && reader.readyState === reader.LOADING) {
          reader.abort();
        }
      } catch (e) {
        console.warn('Failed to unsubscribe from filereader');
        console.warn(e);
      }
    });

    const result$ = Observable.create((observer: Observer<Blob>) => {
      reader.onload = () => {
        if (reader.result == null) {
          observer.error(`No file content`);
        } else if (typeof reader.result === 'string') {
          const stringValue = reader.result as string;
          const encoder = new TextEncoder();
          const arrayBufferValue = encoder.encode(stringValue).buffer;
          const blob: Blob = new Blob([arrayBufferValue]);
          observer.next(blob);
          observer.complete();
        } else if (reader.result instanceof ArrayBuffer) {
          const blob: Blob = new Blob([reader.result]);
          observer.next(blob);
          observer.complete();
        } else {
          observer.error(`Unhandled file content type: ${typeof reader.result}`);
        }
      };
      reader.readAsArrayBuffer(file);
      return subscription;
    });
    return result$;
  }

  static sendUploadRequestWithInMemoryFallback$(data: File, url: string, auth: LifeisLifeWsAuth | null,
                                                errorHandler: (error) => void = e => console.warn(e)): Observable<SingleFileUploadProgress> {
    const result$ = FileUploadUtils.sendUploadRequestXhr$(data, url, auth, 'XHR: file streaming').pipe(
      catchError((error) => {
        errorHandler(error);
        return FileUploadUtils.sendUploadRequestFromMemory$(data, url, auth);
      }),
      catchError((error) => {
        errorHandler(error);
        return FileUploadUtils.sendUploadRequestWithFetchApi$(data, url, auth);
      }),
      catchError((error) => {
        errorHandler(error);
        return throwError(`Failed to send file data`);
      }),
      throwIfEmpty(() => new Error(`No file ref in response body`)),
    );
    return result$;
  }

  private static sendUploadRequestFromMemory$(data: File, url: string, auth: LifeisLifeWsAuth | null): Observable<SingleFileUploadProgress> {
    const result$ = FileUploadUtils.readFileData$(data).pipe(
      switchMap(fileData => FileUploadUtils.sendUploadRequestXhr$(fileData, url, auth, 'XHR: preloaded data')),
    );
    return result$;
  }

  static singleFileUploadWithFetchApi$(file: File, url: string, auth: LifeisLifeWsAuth | null,
                                       contentType = 'application/octet-stream',
                                       accept = '*/*'): Observable<any> {
    if (fetch == null) {
      return throwError('Fetch API not supported');
    }

    const headers = new Headers();
    headers.append('Content-Type', contentType);
    headers.append('Accept', accept);
    headers.append('X-Requested-With', 'fetch');
    if (auth != null) {
      const authHeader = auth.authorizationHeader;
      headers.append('Authorization', authHeader);
    }

    const uploadPromise$ = fetch(url, {
      method: 'POST',
      headers: headers,
      body: file,
    }).then(r => {
      if (r.ok) {
        return r;
      } else {
        return Promise.reject(new Error(`Erreur lors de l'envoi du fichier: ${r.status}`));
      }
    });
    return fromPromise(uploadPromise$);
  }

  private static sendUploadRequestWithFetchApi$(file: File, url: string, auth: LifeisLifeWsAuth | null): Observable<SingleFileUploadProgress> {
    if (fetch == null) {
      return throwError('Fetch API not supported');
    }

    const headers = new Headers();
    headers.append('Content-Type', 'multipart/form-data; charset=UTF-8');
    headers.append('X-Requested-With', 'fetch');
    if (auth != null) {
      const authHeader = auth.authorizationHeader;
      headers.append('Authorization', authHeader);
    }

    const uploadPromise$ = fetch(url, {
      method: 'POST',
      headers: headers,
      body: file,
    }).then(r => {
      if (r.ok) {
        return r.json();
      } else {
        return Promise.reject(new Error(`Erreur lors de l'envoi du fichier: ${r.status}`));
      }
    });
    return fromPromise(uploadPromise$).pipe(
      map(ref => ref as Ref<StoredFile>),
    );
  }

  private static sendUploadRequestXhr$(data: File | Blob, url: string, auth: LifeisLifeWsAuth | null, xRequestedWith?: string)
    : Observable<SingleFileUploadProgress> {
    const request = new XMLHttpRequest();
    const subscription = new Subscription(() => {
      if (request) {
        request.abort();
      }
    });

    const result$ = Observable.create((observer: Observer<SingleFileUploadProgress>) => {
      request.upload.addEventListener('progress', function (e: ProgressEvent) {
        if (e.lengthComputable) {
          observer.next(e.loaded);
        }
      }, false);

      request.open('POST', url);

      request.onreadystatechange = function () {
        if (request.readyState !== 4) {
          return;
        }
        if (request.status !== 200 && request.status !== 204) {
          observer.error(new Error(`Erreur lors de l'envoi du fichier (${request.status} ${request.statusText})`));
          return;
        }
        // Response body should be ref of created file
        const responseText = request.responseText;
        FileUploadUtils.parseCreatedFileRef$(responseText)
          .subscribe(observer);
      };
      request.onerror = function () {
        observer.error(new Error(`Erreur lors de l'envoi du fichier`));
      };

      request.setRequestHeader('Content-Type', 'multipart/form-data; charset=UTF-8');
      request.setRequestHeader('Accept', 'application/json');
      request.setRequestHeader('X-Requested-With', xRequestedWith);
      if (auth != null) {
        const authHeader = auth.authorizationHeader;
        request.setRequestHeader('Authorization', authHeader);
      }
      request.send(data);
      return subscription;
    });
    return result$;
  }

  private static parseCreatedFileRef$(responseText: string): Observable<Ref<StoredFile>> {
    try {
      const responseJson: Ref<StoredFile> = JSON.parse(responseText);
      return of(responseJson);
    } catch (e) {
      return throwError(e);
    }
  }
}
