import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsInvestmentProvider, WsInvestmentProviderSearch, WsRef} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../../util/pagination/simple-pagination';
import {SearchResult} from '../../../client/domain/search/search-result';
import {ValidationResult} from '../../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../../object-converter-util';
import {isValidRef, Ref} from '../../../domain/shared/ref';
import {WithId} from '../../../client/domain/with-id';
import {InvestmentProviderWsClient} from '../../../client/resources/front/corebusiness/investment-provider-ws-client';
import {InvestmentProviderConverter} from './investment-provider-converter';
import {InvestmentProvider} from '../../../domain/corebusiness/investment/investment-provider';
import {InvestmentProviderSearch} from '../../../domain/corebusiness/investment/investment-provider-search';
import {ProductProviderService} from '../product-provider.service';
import {ProductProvider} from '../../../domain/corebusiness/product-provider';
import {SimplePaginationUtils} from '../../../util/pagination/simple-pagination-utils';

@Injectable({
  providedIn: 'root',
})
export class InvestmentProviderService {


  constructor(private client: InvestmentProviderWsClient,
              private prouctProviderService: ProductProviderService,
  ) {
  }

  getInvestmentProvider$(ref: Ref<InvestmentProvider>, forceFetch?: boolean): Observable<InvestmentProvider> {
    return ref == null ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsInvestmentProvider => InvestmentProviderConverter.convertIn(wsInvestmentProvider)),
    );
  }


  getInvestmentForProductProvider$(providerRef: Ref<ProductProvider>): Observable<InvestmentProvider> {
    const investmentSearch: InvestmentProviderSearch = {
      productProviderSearch: {
        productProviderRef: providerRef,
      },
    };
    const pagination = SimplePaginationUtils.newPagination(1);
    return this.searchInvestmentProviders$(investmentSearch, pagination).pipe(
      map(r => r.count === 0 ? null : r.list[0]),
    );
  }

  getInvestmentProviderName$(provider: InvestmentProvider): Observable<string> {
    if (provider == null || provider.id == null || provider.productProviderRef == null) {
      return of(`Nouvelle compagnie d'investisements`);
    }
    return this.prouctProviderService.getProductProvider$(provider.productProviderRef).pipe(
      map(p => p.internalLabel),
    );
  }

  searchInvestmentProviders$(investmentFilter: InvestmentProviderSearch, pagination: SimplePagination)
    : Observable<SearchResult<InvestmentProvider>> {
    const wsSearch = InvestmentProviderConverter.convertFilterOut(investmentFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateInvestmentProvider$(investment: InvestmentProvider): Observable<ValidationResult<InvestmentProvider>> {
    const wsInvestmentProvider = InvestmentProviderConverter.convertOut(investment);
    return this.client.doValidate(wsInvestmentProvider).pipe(
      map((results: WsInvestmentProvider | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveInvestmentProvider$(investment: InvestmentProvider): Observable<WithId> {
    const wsInvestmentProvider = InvestmentProviderConverter.convertOut(investment);
    return this.client.doSave(wsInvestmentProvider);
  }

  deleteInvestmentProvider$(ref: Ref<InvestmentProvider>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  serializeFilter(investmentFilter: InvestmentProviderSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(investmentFilter);
    const wsInvestmentProviderSearch = InvestmentProviderConverter.convertFilterOut(investmentFilter);
    return JSON.stringify(wsInvestmentProviderSearch);
  }

  deserializeFilter(valueString: string | null): InvestmentProviderSearch {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsInvestmentProviderSearch = JSON.parse(valueString);
      const filter = InvestmentProviderConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsInvestmentProvider | WsConstraintViolation[]): ValidationResult<InvestmentProvider> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsInvestmentProvider => InvestmentProviderConverter.convertIn(wsInvestmentProvider),
      propertyNameMappings: InvestmentProviderConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsInvestmentProvider>>): Observable<SearchResult<InvestmentProvider>> {
    const rowTasks = results.list.map(ref => this.getInvestmentProvider$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<InvestmentProvider>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<InvestmentProvider>>>{
          list: rows,
        }),
      ));
  }
}
