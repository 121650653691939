import {WsLifeisLifeLogSearch} from '@lifeislife/lifeislife-ws-api';
import {LifeislifeLogSearch} from '../../domain/log/lifeislife-log-search';

export class LifeislifeLogSearchConverter {

  static convertIn(value: WsLifeisLifeLogSearch): LifeislifeLogSearch {
    if (value == null) {
      return undefined;
    }
    return {
      exactRef: value.exactLogRef,
    };
  }

  static convertOut(value: LifeislifeLogSearch): WsLifeisLifeLogSearch {
    if (value == null) {
      return undefined;
    }
    return {
      exactLogRef: value.exactRef,
    };
  }

}
