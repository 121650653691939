import {ApplicationAuthConfig} from './application-auth-config';

/**
 * Application configuration settings.
 * Primitive value types, so that may be provided easily from multiple sources.
 *
 * Atm, settings are resolved by concatenating the following soureces:
 *  - compile time settings (from environment or source files)
 *  - deployment settings (HTTP GET on /app-settings.json + legacy)
 * Those settings are then augmented at runtime with any of the following, applied sequentially:
 *  - trustee context (app service activates a trustee context)
 *  - auth state settings (Auth service activates role-granted features)
 *  - contact context settings (Application service activates a Role/TrusteeContact/CustomerContact context)
 */
export interface ApplicationConfigSettings {
  /**
   * Map of config key/values.
   */
  config?: { [key: string]: string };
  /**
   * Map of config key/boolean switches.
   */
  switches?: { [key: string]: boolean };
  /**
   * Keycloak client auth config
   */
  authConfig?: ApplicationAuthConfig;

  /**
   * Map of features granted by specific roles.
   * This will be used to augment the application config switches, on authentication as
   * well as on context set. So an user with roles 'CUSTOMER ADMIN' that log ins get granted
   * admin features. When he activates a CUSTOMER context, admin features are dropped.
   * Map per feature. {[MyFeatureType.my_feature_name]: [Role.ADMIN, Role.USER]}
   * **/
  roleGrantedSwitches?: { [key: string]: string[] };

  /**
   * Map of features granted by specific trustee contacts types.
   * This is used to augment app config switches, when application activates a TrusteeContact context.
   * Map per feature. {[MyFeatureType.my_feature_name]: [TrusteeContact.LEGAL_REPRESENTATIVE]}**/
  trusteeContactGrantedSwitches?: { [key: string]: string[] };

  /**
   * Map of features granted by specific customer contacts types.
   * This is used to augment app config switches, when application activates a CustomerContact context.
   * Map per feature. {[MyFeatureType.my_feature_name]: [CustomerContact.LEGAL_REPRESENTATIVE]}
   *
   **/
  customerContactGrantedSwitches?: { [key: string]: string[] };
}

/**
 * Compile-time application settings to be provided by angular DI.
 */
export abstract class ApplicationCompileTimeSettings {
  config?: { [key: string]: string };
  switches?: { [key: string]: boolean };
  authConfig?: ApplicationAuthConfig;

  roleGrantedSwitches?: { [key: string]: string[] };
  trusteeContactGrantedSwitches?: { [key: string]: string[] };
  customerContactGrantedSwitches?: { [key: string]: string[] };
}
