<div class="trustee-dropdown-select">
  <p-autoComplete name="trustee"
                  [ngModel]="nameValue"
                  (ngModelChange)="onNameValueChange($event)"
                  [suggestions]="suggestions"
                  (onDropdownClick)="onDropDownClick()"
                  (completeMethod)="onComplete($event)"
                  (onSelect)="onItemSelect($event)"
                  [completeOnFocus]="!disabled"
                  [delay]="200"
                  [readonly]="disabled"
                  [disabled]="disabled"
                  [required]="required"
                  [dropdown]="true"
                  [forceSelection]="forceSelection"
                  [placeholder]="placeHolder"
  >
    <ng-template let-trusteeItem pTemplate="item">
      <div class="ui-helper-clearfix">
        {{ trusteeItem.label}}
      </div>
    </ng-template>
  </p-autoComplete>
</div>
