import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {AppHomeScreenInstallerService} from './app-home-screen-installer.service';


/**
 * This services requires events to be passed in from the components.
 * In your app compnent, listen for beforeinstallprompt and forward the event to this service:
 *
 * ```
 @HostListener('window:beforeinstallprompt', ['$event'])
 onBeforeInstallPrompt(event: Event) {
    this.appInstallService.onBeforeInstallPromt(event);
  }

 @HostListener('window:appInstalled', ['$event'])
 onAppInstalled(event: Event) {
    this.appInstallService.onAppInstalled(event);
  }
 ```
 */
@Injectable()
export class AppHomeScreenInstallerNoopService extends AppHomeScreenInstallerService {

  canInstall$ = of(false);

  constructor() {
    super();
  }

  canInstallOnHomeScreen(): boolean {
    return false;
  }

  isLaunchedFromHomeScreen(): boolean {
    return false;
  }

  discardAppInstall() {

  }

  hadDiscardedAppInstall() {
    return false;

  }

  askInstallToHomeScreen() {

  }

  onBeforeInstallPromt(event: Event) {

  }

  onAppInstalled(e: Event) {

  }

}
