<div class="trustee-contact-multi-view">
  <span class="contact">
    <app-contact-view [ref]="contactRefSource$ | async"
    ></app-contact-view>
  </span>
  <span class="contact-types">
    <span *ngFor="let trusteeContact of trusteeContacts$ | async">
      <app-trustee-contact-type-badge [trusteeContact]="trusteeContact"
      ></app-trustee-contact-type-badge>
    </span>
  </span>
</div>
