import {Injectable} from '@angular/core';
import {
  WsConstraintViolation,
  WsCreditSubscription,
  WsCreditSubscriptionSearch,
  WsRef,
  WsSubscriptionGroupSummary,
} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../../util/pagination/simple-pagination';
import {SearchResult} from '../../../client/domain/search/search-result';
import {ValidationResult} from '../../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../../object-converter-util';
import {isValidRef, Ref} from '../../../domain/shared/ref';
import {WithId} from '../../../client/domain/with-id';
import {CreditStatus} from '../../../domain/corebusiness/credit/credit-status';
import {CreditSubscriptionWsClient} from '../../../client/resources/front/corebusiness/credit-subscription-ws-client';
import {CreditSubscriptionConverter} from './credit-subscription-converter';
import {CreditSubscription} from '../../../domain/corebusiness/credit/credit-subscription';
import {CreditSubscriptionSearch} from '../../../domain/corebusiness/credit/credit-subscription-search';
import {SubscriptionGroupSummary} from '../../../domain/corebusiness/subscription-group-summary';
import {SubscriptionGroupSummaryConverter} from '../subscription-group-summary.converter';
import {SubscriptionService} from '../subscription.service';
import {SimplePaginationUtils} from '../../../util/pagination/simple-pagination-utils';
import {Subscription} from '../../../domain/corebusiness/subscription';
import {Customer} from '../../../domain/customer/customer';

@Injectable({
  providedIn: 'root',
})
export class CreditSubscriptionService {


  constructor(private client: CreditSubscriptionWsClient,
              private subscriptionService: SubscriptionService,
  ) {
  }

  getCreditSubscription$(ref: Ref<CreditSubscription>, forceFetch?: boolean): Observable<CreditSubscription> {
    return ref == null ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsCreditSubscription => CreditSubscriptionConverter.convertIn(wsCreditSubscription)),
    );
  }

  getCreditForProductSubscription$(subscriptionRef: Ref<Subscription>, customerRef: Ref<Customer>): Observable<CreditSubscription> {
    const creditSearch: CreditSubscriptionSearch = {
      subscriptionSearch: {
        subscriptionRef: subscriptionRef,
        customerSearch: {
          exactCustomerRef: customerRef,
        },
      },
    };
    const pagination = SimplePaginationUtils.newPagination(1);
    return this.searchCreditSubscriptions$(creditSearch, pagination).pipe(
      map(r => r.count === 0 ? null : r.list[0]),
    );
  }

  getCreditSubscriptionName$(creditSubscription: CreditSubscription): Observable<string> {
    if (creditSubscription == null || creditSubscription.id == null || creditSubscription.subscriptionRef == null) {
      return of(`Nouveau contrat de crédit`);
    }
    return this.subscriptionService.getSubscription$(creditSubscription.subscriptionRef).pipe(
      switchMap(s => this.subscriptionService.getSubscriptionName$(s)),
    );
  }

  getCreditSubscriptionStatusName(creditSubscriptionStatus: CreditStatus): string {
    if (creditSubscriptionStatus == null) {
      return null;
    }
    switch (creditSubscriptionStatus) {
      case CreditStatus.ARCHIVED:
        return `Archivé`;
      case CreditStatus.ACTIVE:
        return `Actif`;
      case CreditStatus.CANCELED:
        return `Annulé`;
      case CreditStatus.DOCUMENTS_PENDING:
        return `En attente de documents`;
      case CreditStatus.ACCEPTED_BY_PROVIDER:
        return `Accepté par la compagnie`;
      case CreditStatus.REFUSED_BY_PROVIDER:
        return `Refusé par la compagnie`;
      case CreditStatus.REQUESTED_BY_CUSTOMER:
        return `Demandé par le client`;
      case CreditStatus.REQUESTED_TO_PROVIDER:
        return `Demandé à la compagnie`;
      default:
        throw new Error(`Unahdnled CreditStatus: ${creditSubscriptionStatus}`);
    }
  }

  searchCreditSubscriptions$(creditSubscriptionSearch: CreditSubscriptionSearch, pagination: SimplePagination)
    : Observable<SearchResult<CreditSubscription>> {
    const wsSearch = CreditSubscriptionConverter.convertFilterOut(creditSubscriptionSearch);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  searchSubscriptionsGroupSummaries$(subscriptionSearch: CreditSubscriptionSearch, pagination: SimplePagination)
    : Observable<SearchResult<SubscriptionGroupSummary>> {
    const wsSearch = CreditSubscriptionConverter.convertFilterOut(subscriptionSearch);
    return this.client.doSearchGroupSummaries(wsSearch, pagination).pipe(
      switchMap(results => this.convertGroupSummariesResultsIn$(results)),
    );
  }

  validateCreditSubscription$(creditSubscription: CreditSubscription): Observable<ValidationResult<CreditSubscription>> {
    const wsCreditSubscription = CreditSubscriptionConverter.convertOut(creditSubscription);
    return this.client.doValidate(wsCreditSubscription).pipe(
      map((results: WsCreditSubscription | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveCreditSubscription$(creditSubscription: CreditSubscription): Observable<WithId> {
    const wsCreditSubscription = CreditSubscriptionConverter.convertOut(creditSubscription);
    return this.client.doSave(wsCreditSubscription);
  }

  deleteCreditSubscription$(ref: Ref<CreditSubscription>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  serializeFilter(creditSubscriptionSearch: CreditSubscriptionSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(creditSubscriptionSearch);
    const wsCreditSearch = CreditSubscriptionConverter.convertFilterOut(creditSubscriptionSearch);
    return JSON.stringify(wsCreditSearch);
  }

  deserializeFilter(valueString: string | null): CreditSubscriptionSearch | null {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsCreditSubscriptionSearch = JSON.parse(valueString);
      const filter = CreditSubscriptionConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsCreditSubscription | WsConstraintViolation[]): ValidationResult<CreditSubscription> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsCreditSubscription => CreditSubscriptionConverter.convertIn(wsCreditSubscription),
      propertyNameMappings: CreditSubscriptionConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsCreditSubscription>>): Observable<SearchResult<CreditSubscription>> {
    const rowTasks = results.list.map(ref => this.getCreditSubscription$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<CreditSubscription>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<CreditSubscription>>>{
          list: rows,
        }),
      ));
  }

  private convertGroupSummariesResultsIn$(results: SearchResult<WsSubscriptionGroupSummary>) {
    const groupSummaries = results.list.map(wsSummary => SubscriptionGroupSummaryConverter.convertIn(wsSummary));
    const convertedResult = Object.assign({}, results, {
      list: groupSummaries,
    });
    return of(convertedResult);
  }
}
