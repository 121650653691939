<span class="contact-view lifeislife-inline-label">

  <span *ngIf="!hideContactName">
    <span class="lastname name">{{ lastName$  | async }}</span>
    <span class="firstname name">{{ firstName$ | async }}</span>
  </span>

  <span *ngIf="showCompanyName">
    <span class="company">{{ companyName$ | async }}</span>
  </span>

   <a class="lifeislife-jump-link" *ngIf="(jumpLinkVisible$ | async) && (jumpLink$ | async) != null"
      [routerLink]="jumpLink$ | async"
      (click)="onClick($event)"
      title="Vers la fiche contact" i18n-title
   >
      <i class="fa fa-external-link-square"></i>
  </a>
</span>
