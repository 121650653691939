import {Injectable} from '@angular/core';
import {WsPensionPlanStatus, WsRef} from '@lifeislife/lifeislife-ws-api';
import {RequestService} from '../../../service/request.service';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {FrontendResourceType} from '../../../../util/cache/cache-resource-type';

@Injectable({
  providedIn: 'root',
})
export class PensionPlanStatusWsClient extends CachedWsResourceClient<WsPensionPlanStatus, WsRef<WsPensionPlanStatus>> {

  constructor(
    private injectedRequestService: RequestService,
    private injectectedCacheService: ResourceCacheService,
    private injectedAuthService: AuthProvider,
    private injectedAppConfigService: AppConfigService,
  ) {
    super('/front/pensionPlanStatus', injectedAppConfigService, injectedRequestService, injectedAuthService,
      FrontendResourceType.PENSION_PLAN_STATUS, injectectedCacheService);
  }

}
