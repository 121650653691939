import {Injectable} from '@angular/core';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {TicketConverter} from './ticket.converter';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {Ticket} from '../../domain/ticket/ticket';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {TicketSearch} from '../../domain/ticket/ticket-search';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {TicketStatus} from '../../domain/ticket/ticket-status';
import {TicketWsClient} from '../../client/resources/front/ticketing/ticket-ws-client';
import {SimplePaginationUtils} from '../../util/pagination/simple-pagination-utils';
import {TicketContactSearch} from '../../domain/ticket/ticket-contact-search';
import {TicketType} from '../../domain/ticket/ticket-type';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {FileSubresourcePath} from '../../domain/file/file-subresource-path';
import {WsConstraintViolation, WsRef, WsTicket, WsTicketStatus} from '@lifeislife/lifeislife-ws-api';
import {Contact} from '../../domain/contact/contact';
import {Customer} from '../../domain/customer/customer';
import {TicketCustomer} from '../../domain/ticket/ticket-customer';
import {TicketSearchConverter} from './ticket-search.converter';

@Injectable({
  providedIn: 'root',
})
export class TicketService {

  constructor(private client: TicketWsClient,
  ) {
  }

  getTicket$(ref?: Ref<Ticket>, forceFetch?: boolean): Observable<Ticket> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doGet(ref.id, forceFetch).pipe(
      map(wsTicket => TicketConverter.convertIn(wsTicket)),
    );
  }

  searchTickets$(ticketFilter: TicketSearch, pagination: SimplePagination): Observable<SearchResult<Ticket>> {
    const wsSearch = TicketSearchConverter.convertFilterOut(ticketFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateTicket$(ticket: Ticket): Observable<ValidationResult<Ticket>> {
    const wsTicket = TicketConverter.convertOut(ticket);
    return this.client.doValidate(wsTicket).pipe(
      map((results: WsTicket | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveTicket$(ticket: Ticket): Observable<Ref<Ticket>> {
    const wsTicket = TicketConverter.convertOut(ticket);
    return this.client.doSave(wsTicket);
  }

  updateTicketStatus$(ticketRef: Ref<Ticket>, status: TicketStatus): Observable<Ref<Ticket>> {
    const wsStatus = EnumConverterUtils.convertValue(status, WsTicketStatus);
    return this.client.updateTicketStatus$(ticketRef.id, wsStatus);
  }

  deleteTicket$(id: number): Observable<any> {
    return this.client.doRemove(id);
  }


  getNewTicketFilePath(ref: Ref<Ticket>): FileSubresourcePath<Ticket> {
    if (ref == null) {
      return null;
    }
    const path = this.client.getNewTicketFilePath(ref.id);
    return {
      ref: ref,
      fileWsPath: path,
    };
  }

  addTicketCustomer$(ref: Ref<Ticket>, customerRef: Ref<Customer>): Observable<Ref<TicketCustomer>> {
    return this.client.addTicketCustomer$(ref.id, customerRef.id);
  }

  searchTicketCountWithUnacknowledgedChanges$(contactRef: Ref<Contact>): Observable<number> {
    if (!isValidRef(contactRef)) {
      return throwError(new Error('Invalid ref'));
    }
    const ticketFilter: TicketSearch = {
      ticketContactSearch: {
        contactSearch: {
          exactContactRef: contactRef,
        },
        ticketArchived: false,
        ticketSilenced: false,
        withChangesSinceLastAcknowledged: true,
      } as TicketContactSearch,
      ticketStatusList: [
        TicketStatus.CREATED,
        TicketStatus.WAITING,
        TicketStatus.IN_PROGRESS,
        TicketStatus.RESOLVED,
      ],
    } as TicketSearch;
    const pagination: SimplePagination = SimplePaginationUtils.newPagination(0);
    return this.searchTickets$(ticketFilter, pagination).pipe(
      map(results => results.count),
    );
  }


  getTicketStatusLabel(status: TicketStatus): string {
    switch (status) {
      case TicketStatus.DRAFT:
        return 'Brouillon';
      case TicketStatus.CREATED:
        return 'Nouveau';
      case TicketStatus.IN_PROGRESS:
        return 'En cours';
      case TicketStatus.WAITING:
        return 'En attente';
      case TicketStatus.RESOLVED:
        return 'Terminé';
      case TicketStatus.ARCHIVED:
        return 'Archivé';
    }
    throw new Error('Unhandled status: ' + status);
  }

  getTicketTypeLabel(type: TicketType): string {
    switch (type) {
      case TicketType.CUSTOMER_INSURANCE_REQUEST:
        return `Demande d'informations pour les produits d'assurance`;
      case TicketType.GENERAL_DISCUSSION:
        return 'Discussion';
    }
    throw new Error('Unhandled type: ' + type);
  }


  serializeFilter(filter: Partial<TicketSearch>): string {
    ObjectConverterUtil.cleanUnsetFilterValues(filter);
    const wsFilter = TicketSearchConverter.convertFilterOut(<TicketSearch>filter);
    const encodedFilter = JSON.stringify(wsFilter);
    return encodedFilter;
  }

  deserializeFilter(valueString: string | null): TicketSearch | null {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: any = JSON.parse(valueString);
      const filter = TicketSearchConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsTicket | WsConstraintViolation[]): ValidationResult<Ticket> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsTicket => TicketConverter.convertIn(wsTicket),
      propertyNameMappings: TicketConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsTicket>>): Observable<SearchResult<Ticket>> {
    const rowTasks = results.list.map(ref => this.getTicket$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<Ticket>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<Ticket>>>{
          list: rows,
        }),
      ));
  }

}
