import {WsLifeisLifeLog} from '@lifeislife/lifeislife-ws-api';
import {LifeislifeLog} from '../../domain/log/lifeislife-log';

export class LifeisLifeLogConverter {

  static convertIn(value: WsLifeisLifeLog): LifeislifeLog {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      version: value.version,
    };
  }

  static convertOut(value: LifeislifeLog): WsLifeisLifeLog {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      version: value.version,

    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsLifeisLifeLog]: keyof LifeislifeLog } {
    return {
      id: 'id',
      version: 'version',
    };
  }

}
