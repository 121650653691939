import {Injectable} from '@angular/core';
import {
  WsConstraintViolation,
  WsRef,
  WsSubscription,
  WsSubscriptionGroupSummary,
  WsSubscriptionSearch,
} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SubscriptionConverter} from './subscription-converter';
import {Subscription} from '../../domain/corebusiness/subscription';
import {SubscriptionSearch} from '../../domain/corebusiness/subscription-search';
import {SubscriptionGroupSummaryConverter} from './subscription-group-summary.converter';
import {SubscriptionWsClient} from '../../client/resources/front/corebusiness/subscription-ws-client';
import {SubscriptionGroupSummary} from '../../domain/corebusiness/subscription-group-summary';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {ValidationResult} from '../../domain/shared/validation-result';
import {WithId} from '../../client/domain/with-id';
import {Ref} from '../../domain/shared/ref';
import {EXCEL_MIME_TYPE} from '../../util/file/file-utils';
import {ProductService} from './product.service';
import {Recurrence} from '../../domain/corebusiness/recurrence';
import {DateUtils} from '../../util/date/date-utils';
import {FileSubresourcePath} from '../../domain/file/file-subresource-path';
import {Customer} from '../../domain/customer/customer';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {

  constructor(private client: SubscriptionWsClient,
              private productService: ProductService,
  ) {
  }

  getSubscriptionName$(subscription: Subscription): Observable<string> {
    if (subscription == null || subscription.id == null) {
      return of(`Nouveau contrat`);
    }
    if (subscription.description != null && subscription.description.length > 0) {
      return of(subscription.description);
    }
    if (subscription.productRef) {
      return this.productService.getProduct$(subscription.productRef).pipe(
        map(p => this.productService.getProductLabel(p)),
      );
    }

    return of(`Contrat #${subscription.id}`);
  }

  getSubscription$(ref: Ref<Subscription>, forceFetch = false): Observable<Subscription> {
    return this.client.doGet(ref.id, forceFetch).pipe(
      map(ws => SubscriptionConverter.convertIn(ws)),
    );
  }

  getNewSubscriptionFilePath(ref: Ref<Subscription>): FileSubresourcePath<Subscription> {
    const path = this.client.getNewSubscriptionFilePath(ref);
    return {
      ref: ref,
      fileWsPath: path,
    };
  }

  refetchSubscription$(ref: Ref<Subscription>): Observable<Subscription> {
    return this.getSubscription$(ref, true);
  }

  validateSubscription$(subscription: Subscription): Observable<ValidationResult<Subscription>> {
    const wsSubscription = SubscriptionConverter.convertOut(subscription);
    return this.client.doValidate(wsSubscription).pipe(
      map((results: WsSubscription | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveSubscription$(subscription: Subscription): Observable<WithId> {
    const wsSubscription = SubscriptionConverter.convertOut(subscription);
    return this.client.doSave(wsSubscription);
  }

  updateSubscriptionCustomer$(subscription: Ref<Subscription>, customerRef: Ref<Customer>): Observable<Ref<Subscription>> {
    return this.client.updateSubscriptionCustomer$(subscription, customerRef);
  }

  searchSubscriptions$(baseSearch: SubscriptionSearch, pagination: SimplePagination): Observable<SearchResult<Subscription>> {
    const wsSearch = SubscriptionConverter.convertFilterOut(baseSearch);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  searchSubscriptionsExcelReport$(baseSearch: SubscriptionSearch, pagination: SimplePagination): Observable<Blob> {
    const wsSearch = SubscriptionConverter.convertFilterOut(baseSearch);
    return this.client.doSearchReport(wsSearch, pagination, EXCEL_MIME_TYPE);
  }

  searchSubscriptionsGroupSummaries$(baseSearch: SubscriptionSearch, pagination: SimplePagination)
    : Observable<SearchResult<SubscriptionGroupSummary>> {
    const wsSearch = SubscriptionConverter.convertFilterOut(baseSearch);
    return this.client.doSearchGroupSummaries(wsSearch, pagination).pipe(
      switchMap(results => this.convertGroupSummariesResultsIn$(results)),
    );
  }

  serializeFilter(SubscriptionFilter: SubscriptionSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(SubscriptionFilter);
    const wsCreditSearch = SubscriptionConverter.convertFilterOut(SubscriptionFilter);
    return JSON.stringify(wsCreditSearch);
  }

  deserializeFilter(valueString: string | null): SubscriptionSearch {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsSubscriptionSearch = JSON.parse(valueString);
      const filter = SubscriptionConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  getRecurrencelabel(recurrence: Recurrence) {
    if (recurrence == null) {
      return '';
    }
    switch (recurrence) {
      case Recurrence.MONTH:
        return 'Mensuel';
      case Recurrence.QUARTER:
        return 'Trimestriel';
      case Recurrence.SEMESTER:
        return 'Semestriel';
      case Recurrence.YEAR:
        return 'Annuel';
      case Recurrence.ONCE:
        return 'Unique';
      default:
        console.warn(`No label for recurrence ${recurrence}`);
        return `${recurrence}`;
    }
  }

  getSubscriptionNextBirthdayDate(subscription: Subscription): Date | null {
    if (subscription == null) {
      return null;
    }
    const startDate = subscription.startDate;
    return DateUtils.nextInFutureByRecurrenceSteps(startDate, Recurrence.YEAR);
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsSubscription>>): Observable<SearchResult<Subscription>> {
    const rowTasks = results.list.map(ref => this.getSubscription$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<Subscription>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<Subscription>>>{
          list: rows,
        }),
      ));
  }

  private convertGroupSummariesResultsIn$(results: SearchResult<WsSubscriptionGroupSummary>) {
    const groupSummaries = results.list.map(wsSummary => SubscriptionGroupSummaryConverter.convertIn(wsSummary));
    const convertedResult = Object.assign({}, results, {
      list: groupSummaries,
    });
    return of(convertedResult);
  }

  private createValidationResult(results: WsSubscription | WsConstraintViolation[]): ValidationResult<Subscription> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsSubscription => SubscriptionConverter.convertIn(wsSubscription),
      propertyNameMappings: SubscriptionConverter.getValidationPropertyNameMappings(),
    });
  }

}
