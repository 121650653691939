import {Injectable} from '@angular/core';
import {RequestService} from '../../service/request.service';
import {Observable, of} from 'rxjs';
import {LifeisLifeWsAuth} from '../../domain/auth/lifeis-life-ws-auth';
import {WsContact, WsCustomerContact, WsLifeIsLifeUser, WsRef, WsRole, WsTrusteeContact, WsUserToken} from '@lifeislife/lifeislife-ws-api';
import {HttpHeaders} from '@angular/common/http';
import {FrontendAppConfigKey} from '../../../domain/config/frontend-app-config-key';
import {AppConfigService} from '../../../service/config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class UserAuthWsClient {

  constructor(private requestService: RequestService,
              private appConfigService: AppConfigService,
  ) {

  }


  getLoggedUser$(auth: LifeisLifeWsAuth): Observable<WsLifeIsLifeUser> {
    const url = `${this.getWsResourceUri()}/me`;

    return this.requestService.sendRequest<WsLifeIsLifeUser>({
      method: 'GET', url: url,
    }, auth);
  }

  getLoggedUserContactRef$(auth: LifeisLifeWsAuth): Observable<WsRef<WsContact>> {
    const url = `${this.getWsResourceUri()}/me/contact`;

    return this.requestService.sendRequest<WsRef<WsContact>>({
      method: 'GET', url: url,
    }, auth);
  }

  getLoggedUserToken$(auth: LifeisLifeWsAuth): Observable<WsUserToken> {
    const url = `${this.getWsResourceUri()}/me/token`;

    return this.requestService.sendRequest<WsUserToken>({
      method: 'GET', url: url,
    }, auth);
  }

  getLoggedUserFeatures$(auth: LifeisLifeWsAuth): Observable<any[]> {
    // TODO: global app-scoped role-based permisions/displayed features from backend
    return of([]);
  }

  getLoggedUserRoles$(auth: LifeisLifeWsAuth): Observable<WsRole[]> {
    const url = `${this.getWsResourceUri()}/me/roles`;

    return this.requestService.sendRequest<WsRole[]>({
      method: 'GET', url: url,
    }, auth);
  }

  getLoggedUserTrustees$(auth: LifeisLifeWsAuth): Observable<WsRef<WsTrusteeContact>[]> {
    const url = `${this.getWsResourceUri()}/me/trusteeContacts`;

    return this.requestService.sendRequest<WsTrusteeContact[]>({
      method: 'GET', url: url,
    }, auth);
  }

  getLoggedUserCustomers$(auth: LifeisLifeWsAuth): Observable<WsRef<WsCustomerContact>[]> {
    const url = `${this.getWsResourceUri()}/me/customerContacts`;

    return this.requestService.sendRequest<WsCustomerContact[]>({
      method: 'GET', url: url,
    }, auth);
  }

  refreshUserToken$(auth: LifeisLifeWsAuth, refreshToken: string): Observable<WsUserToken> {
    const url = `${this.getWsResourceUri()}/me/token`;

    return this.requestService.sendRequest<WsUserToken>({
      method: 'POST', url: url, body: refreshToken,
    }, auth);
  }

  getServerSentEventToken$(auth: LifeisLifeWsAuth): Observable<string> {
    const url = `${this.getWsResourceUri()}/me/serverEvent/token`;

    return this.requestService.sendRequest<string>({
      method: 'POST', url: url, responseType: 'text',
    }, auth);
  }


  setLoggedUserPassword$(auth: LifeisLifeWsAuth, newPassword: string): Observable<WsRef<WsLifeIsLifeUser>> {
    const url = `${this.getWsResourceUri()}/me/password`;
    const headers = new HttpHeaders()
      .append('Content-Type', 'text/plain');

    return this.requestService.sendRequest<WsRef<WsLifeIsLifeUser>>({
      method: 'POST', url: url, body: newPassword,
      headers: headers,
    }, auth);
  }


  isLoggedUserPasswordSet$(auth: LifeisLifeWsAuth): Observable<boolean> {
    const url = `${this.getWsResourceUri()}/me/password/set`;
    const headers = new HttpHeaders()
      .append('Content-Type', 'text/plain');

    return this.requestService.sendRequest<boolean>({
      method: 'GET', url: url, headers: headers,
    }, auth);
  }


  private getWsResourceUri() {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const resourceUrl = `${wsUri}/front/user`;
    return resourceUrl;
  }
}
