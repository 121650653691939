import {Component, Input, OnInit} from '@angular/core';
import {ContactService, CustomerContact, CustomerContactService, CustomerContactType, CustomerService} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import {map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {CustomerContactTypeModel} from '@lifeislife/lifeislife-domain';

@Component({
  selector: 'app-customer-contact-type-badge',
  templateUrl: './customer-contact-type-badge.component.html',
  styleUrls: ['./customer-contact-type-badge.component.scss'],
})
export class CustomerContactTypeBadgeComponent implements OnInit {

  @Input()
  set customerContact(value: CustomerContact) {
    this.value$.next(value);
  }

  @Input()
  showCustomerLabelTitle = true;

  value$ = new BehaviorSubject<CustomerContact>(null);
  contactTypeModel$: Observable<CustomerContactTypeModel>;
  customerLabel$: Observable<string>;

  constructor(
    private customerService: CustomerService,
    private contactService: ContactService,
    private customerContactService: CustomerContactService,
  ) {
  }

  ngOnInit() {
    this.customerLabel$ = this.value$.pipe(
      switchMap(c => c == null ? of(null) : this.getCustomerLabel$(c)),
      publishReplay(1), refCount(),
    );
    this.contactTypeModel$ = this.value$.pipe(
      map(v => v == null ? null : v.customerContactType),
      switchMap(t => this.customerContactService.getContactTypeModel$(t)),
      publishReplay(1), refCount(),
    );
  }

  private getCustomerLabel$(customerContact: CustomerContact) {
    if (customerContact == null) {
      return of(null);
    }

    const customerName$ = this.customerService.getCustomerLabel$(customerContact.customerRef);
    const typeModel$ = this.customerContactService.getContactTypeModel$(customerContact.customerContactType);
    return forkJoin([customerName$, typeModel$]).pipe(
      map(r => {
        const customerName = r[0];
        const model = r[1];
        const typeName = model == null ? '' : model.label;
        const customerAccess = model == null ? false : model.customerDataAccess;
        const accessSuffix = customerAccess ? ` (donne accès à l'application client)` : '';
        return `${typeName} chez ${customerName}${accessSuffix}`;
      }),
    );
  }
}
