/**
 * Created by cghislai on 10/04/16.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectUtils} from '../../../../commons/util/ObjectUtils';
import {RadioButton} from 'primeng/radiobutton';
import {SelectItem} from 'primeng/api';


/**
 * Use primitives for select values! This is serialized in the dom for component ids.
 */
@Component({
  selector: 'radio-group',
  templateUrl: 'radioGroup.html',
  styleUrls: ['radioGroup.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioGroupComponent), multi: true},
  ],
})
export class RadioGroupComponent implements OnChanges, OnInit, ControlValueAccessor {

  @Input()
  group: string;
  @Input()
  disabled = false;
  @Input()
  hasError = false;
  @Input()
  items: SelectItem[];

  selectedValue: any;
  focus: boolean;

  @ViewChildren(RadioButton)
  private radioButtonQuery: QueryList<RadioButton>;

  private onChangeFunction: Function;
  private onTouchedFunction: Function;


  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.updateRadioValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    const itemChange = changes['items'];
    if (itemChange) {
      this.updateRadioValues();
    }
  }

  writeValue(obj: any): void {
    if (this.selectedValue !== obj) {
      this.selectedValue = obj;
      this.updateRadioValues();
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFunction = fn;
  }

  onChange(value: any, updateRadio: boolean) {
    if (this.disabled) {
      return;
    }
    const changed = this.selectedValue !== value;
    if (!changed) {
      return;
    }
    this.selectedValue = value;
    if (updateRadio) {
      this.updateRadioValues();
    }
    this.onTouchedFunction();
    this.onChangeFunction(value);
  }

  onFocus() {
    this.focus = true;
  }

  onBlur() {
    this.focus = false;
  }

  private updateRadioValues() {
    if (this.radioButtonQuery != null) {
      this.radioButtonQuery.forEach((radio) => {
        if (this.selectedValue === radio.value || ObjectUtils.isSameRef(radio.value, this.selectedValue)) {
          radio.writeValue(true);
        } else {
          radio.writeValue(false);
        }
      });
      this.changeDetectorRef.markForCheck();
    }
  }

}
