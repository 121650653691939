import {ResourceCache} from './resource-cache';
import {CacheResourceType} from './cache-resource-type';
import {Observable, of} from 'rxjs';
import {WithId} from '../../client/domain/with-id';
import {InMemoryResourceTypeCache} from './in-memory-resource-type-cache';
import {Injectable} from '@angular/core';

@Injectable()
export class InMemoryResourceCache implements ResourceCache {

  private resourceTypeCaches: { [K in CacheResourceType]?: InMemoryResourceTypeCache<WithId> } = {};
  private skippedResourceTypes: CacheResourceType[] = [];

  cacheRequest$<T extends WithId>(type: CacheResourceType, id: number, request$: Observable<T>): Observable<T> {
    const cache = this.getResourceTypeCache<T>(type);
    return cache.cacheRequest$(id, request$);
  }

  cacheValue<T extends WithId>(type: CacheResourceType, id: number, value: T): void {
    const cache = this.getResourceTypeCache(type);
    cache.cacheValue(id, value);
  }

  getCachedValue$<T extends WithId>(type: CacheResourceType, id: number): Observable<T> {
    const cache = this.getResourceTypeCache(type);
    return cache.getCachedValue$(id) as Observable<T>;
  }

  hasCachedValue$(type: CacheResourceType, id: number): Observable<boolean> {
    const cache = this.getResourceTypeCache(type);
    const hasValue = cache.hasCachedValue(id);
    return of(hasValue);
  }

  clear(type: CacheResourceType, id: number): void {
    const cache = this.getResourceTypeCache(type);
    cache.clear(id);
  }


  clearAll(type: CacheResourceType): void {
    const cache = this.getResourceTypeCache(type);
    cache.clearAll();
  }

  isSkipped(type: CacheResourceType): boolean {
    return this.skippedResourceTypes.findIndex(t => t === type) >= 0;
  }

  skip(type: CacheResourceType): void {
    this.skippedResourceTypes.push(type);
  }

  unskip(type: CacheResourceType): void {
    this.skippedResourceTypes = this.skippedResourceTypes
      .filter(t => t !== type);
  }


  private getResourceTypeCache<T extends WithId>(type: CacheResourceType): InMemoryResourceTypeCache<T> {
    if (this.resourceTypeCaches[type]) {
      return this.resourceTypeCaches[type] as InMemoryResourceTypeCache<T>;
    } else {
      const cache = new InMemoryResourceTypeCache<T>();
      this.resourceTypeCaches[type] = cache;
      return cache;
    }
  }


}
