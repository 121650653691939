import {Injectable} from '@angular/core';
import {
  AppConfigService,
  AuthState,
  CustomerContact,
  CustomerContactService,
  TrusteeContact,
  TrusteeContactService,
  UserAuthService,
} from '@lifeislife/lifeislife-domain';
import {forkJoin, Observable, of} from 'rxjs';
import {publishReplay, refCount, switchMap, take} from 'rxjs/operators';
import {AppFeature} from '../model/app-feature';

@Injectable({
  providedIn: 'root',
})
export class AppFeatureService {

  authState$: Observable<AuthState>;
  authTrusteeContact$: Observable<TrusteeContact[]>;
  authCustomerContacts$: Observable<CustomerContact[]>;

  appFeatures$: Observable<AppFeature[]>;

  constructor(
    private configService: AppConfigService,
    private userAuthService: UserAuthService,
    private trusteeContactService: TrusteeContactService,
    private customerContactService: CustomerContactService,
  ) {
    this.authState$ = this.userAuthService.state$;
    this.authTrusteeContact$ = this.authState$.pipe(
      switchMap(s => this.fetchTrusteeContacts$(s)),
      publishReplay(1), refCount(),
    );
    this.authCustomerContacts$ = this.authState$.pipe(
      switchMap(s => this.fetchCustomerContacts$(s)),
      publishReplay(1), refCount(),
    );

    this.appFeatures$ = this.configService.getEnabledTypedSwitches$<AppFeature>(AppFeature);
  }

  hasAppFeature$(feature: AppFeature): Observable<boolean> {
    return this.configService.geTypedSwitchEnabled$<AppFeature>(feature, AppFeature);
  }

  hasCurrentlyAppFeature(feature: AppFeature): boolean {
    return this.configService.isTypedSwitchCurrentlyEnabled<AppFeature>(feature, AppFeature);
  }

  getCurrentApplicationScopedFeatures$(): Observable<AppFeature[]> {
    return this.appFeatures$.pipe(
      take(1),
    );
  }

  private fetchTrusteeContacts$(state: AuthState): Observable<TrusteeContact[]> {
    if (state == null || state.trusteeContactRefs.length === 0) {
      return of([]);
    }
    const contact$List = state.trusteeContactRefs.map(ref => this.trusteeContactService.getTrusteeContact$(ref));
    return forkJoin(contact$List);
  }

  private fetchCustomerContacts$(state: AuthState): Observable<CustomerContact[]> {
    if (state == null || state.customerContactRefs.length === 0) {
      return of([]);
    }
    const contact$List = state.customerContactRefs.map(ref => this.customerContactService.getCustomerContact$(ref));
    return forkJoin(contact$List);
  }

}
