import {WsProductProvider, WsProductProviderSearch} from '@lifeislife/lifeislife-ws-api';
import {ProductProvider} from '../../domain/corebusiness/product-provider';
import {ProductProviderSearch} from '../../domain/corebusiness/product-provider-search';

export class ProductProviderConverter {


  static convertIn(value: WsProductProvider): ProductProvider {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      archived: value.archived,
      internalLabel: value.internalLabel,
      publicLabel: value.publicLabel,
      agencyReference: value.agencyReference,
      signingPackageFolderName: value.signingPackageFolderName,
      logoStoredFileRef: value.logoStoredFile,
      publicUri: value.publicUri,
    };
  }

  static convertOut(value: ProductProvider): WsProductProvider {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      archived: value.archived,
      internalLabel: value.internalLabel,
      publicLabel: value.publicLabel,
      agencyReference: value.agencyReference,
      signingPackageFolderName: value.signingPackageFolderName,
      logoStoredFile: value.logoStoredFileRef,
      publicUri: value.publicUri,
    };
  }

  static convertFilterIn(value: WsProductProviderSearch): ProductProviderSearch {
    if (value == null) {
      return undefined;
    }
    return {
      nameContains: value.nameContains,
      archived: value.archived,
      productProviderRef: value.productProviderWsRef,
    };

  }

  static convertFilterOut(value: ProductProviderSearch): WsProductProviderSearch {
    if (value == null) {
      return undefined;
    }
    return {
      nameContains: value.nameContains,
      archived: value.archived,
      productProviderWsRef: value.productProviderRef,
    };

  }

  static getValidationPropertyNameMappings(): { [M in keyof WsProductProvider]: keyof ProductProvider } {
    return {
      id: 'id',
      archived: 'archived',
      internalLabel: 'internalLabel',
      publicLabel: 'publicLabel',
      agencyReference: 'agencyReference',
      signingPackageFolderName: 'signingPackageFolderName',
      logoStoredFile: 'logoStoredFileRef',
      publicUri: 'publicUri',
    };
  }

}
