import {Component, ElementRef, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import {GoogleMapsService} from '../../../services/google-maps.service';
import {DomSanitizer} from '@angular/platform-browser';
import {WindowRef} from '@lifeislife/lifeislife-domain';

@Component({
  selector: 'google-maps',
  templateUrl: 'google-maps.template.html',
  styleUrls: ['google-maps.style.scss'],
})
export class GoogleMapsComponent implements OnInit {

  @Input()
  latitude = 0;
  @Input()
  longitude = 0;
  @Input()
  zoom = 10;
  @Input()
  showMarker = false;

  @ViewChild('googleMapsContainer', {static: true})
  private mapsContainer: ElementRef;

  @HostBinding()
  private style = this.domSanitizer.bypassSecurityTrustStyle('position: absolute; width: 100%; height: 100%;');

  loading: boolean;
  errored: boolean;
  errorMessage: string;

  private map: any;
  private marker: any;

  constructor(private mapService: GoogleMapsService,
              private windowRef: WindowRef,
              private domSanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    const coordinates = {
      lat: this.latitude,
      lng: this.longitude,
    };
    this.loading = true;
    const window = this.windowRef.getWindow();
    this.mapService.loadMap(this.mapsContainer.nativeElement, coordinates, this.zoom)
      .subscribe(map => {
        this.loading = false;
        this.errored = false;
        this.map = map;
        if (this.showMarker) {
          this.marker = new window.google.maps.Marker({
            position: coordinates,
            map: map,
          });
        }
      }, (error) => {
        this.loading = false;
        this.map = null;
        this.errored = true;
        if (error instanceof Error) {
          this.errorMessage = error.message;
        } else {
          this.errorMessage = null;
        }
      });
  }

}
