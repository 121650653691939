import {CanLoad, convertToParamMap, Route, Router, UrlSegment} from '@angular/router';
import {Injectable} from '@angular/core';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {
  AppConfigService,
  AuthState,
  FrontendAppSwitch,
  InviteAuthService,
  LifeisLifeWsAuth,
  Role,
  TrusteeService,
  UserAuthService,
} from '@lifeislife/lifeislife-domain';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {ApplicationRouteData} from '../routing/application-route-data';
import {ApplicationMenuItemData} from '../routing/application-menu-item-data';
import {AppFeatureService} from './app-feature.service';
import {AppFeature} from '../model/app-feature';

@Injectable({
  providedIn: 'root',
})
export class AppModuleLoaderGuard implements CanLoad {

  constructor(private userAuthService: UserAuthService,
              private userInviteAuthService: InviteAuthService,
              private trusteeService: TrusteeService,
              private appFeatureService: AppFeatureService,
              private configService: AppConfigService,
              private router: Router) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const requestedUriPath = segments.map(s => s.path)
      .reduce((c, n) => `${c}/${n}`, '');

    const routeData = route.data as ApplicationRouteData;
    if (routeData != null && routeData.menuData != null) {
      const menuData = routeData.menuData;
      const queryParams: { [key: string]: string } = {};

      return this.userAuthService.restoreAuthIfRequired$(convertToParamMap(queryParams), Role.TRUSTEE).pipe(
        switchMap(a => this.checkAcccessGrantedOrThrow$(a, route, menuData)),
        catchError(e => {
          if (this.configService.isSwitchCurrentlyEnabled(FrontendAppSwitch.front_dev_debug)) {
            console.warn(`Module load at ${requestedUriPath} denied: ${e}`);
          }
          this.redirectOnAccessDenied(null, requestedUriPath, queryParams, menuData);
          return of(false);
        }),
      );
    }
    return true;
  }

  private redirectOnAccessDenied(auth: LifeisLifeWsAuth, requestedUriPath: string, queryParams: { [key: string]: string }, menuDate: ApplicationMenuItemData) {
    this.router.navigate(['/login'], {
      queryParamsHandling: 'merge',
      queryParams: {
        'redirectUrl': requestedUriPath,
      },
    });
  }

  private checkAcccessGrantedOrThrow$(state: AuthState, route: Route, menuData: ApplicationMenuItemData) {
    const allFeatures = menuData.allAppFeatures;
    const anyFeature = menuData.anyAppFeature;

    const allFeaturesCheck$ = this.checkAllAppScopedAppFeature(allFeatures);
    const anyFeaturesCheck$ = this.checkAnyAppScopedAppFeature(anyFeature);
    return forkJoin(allFeaturesCheck$, anyFeaturesCheck$).pipe(
      map(checks => checks[0] && checks[1]),
      map(accessGranted => {
        if (!accessGranted) {
          throw new Error(`Access denied`);
        } else {
          return true;
        }
      }),
    );
  }

  private checkAllAppScopedAppFeature(features: AppFeature[]): Observable<boolean> {
    if (features == null || features.length === 0) {
      return of(true);
    }
    return this.checkAppFeaturesOrThrow$(features, true);
  }

  private checkAnyAppScopedAppFeature(features: AppFeature[]): Observable<boolean> {
    if (features == null || features.length === 0) {
      return of(true);
    }
    return this.checkAppFeaturesOrThrow$(features, false);
  }

  private checkAppFeaturesOrThrow$(features: AppFeature[], allFeatures?: boolean): Observable<boolean> {
    const grantedList = features.map(f => this.appFeatureService.hasCurrentlyAppFeature(f));
    let granted: boolean;
    if (allFeatures) {
      granted = grantedList.indexOf(false) < 0;
    } else {
      granted = grantedList.indexOf(true) >= 0;
    }
    if (!granted) {
      return throwError(new Error(`Access denied`));
    } else {
      return of(true);
    }
  }
}
