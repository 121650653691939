export enum SubscriptionSortField {

  SUBSCRIPTION_NAME = 'SUBSCRIPTION_NAME',
  SUBSCRIPTION_STATUS = 'SUBSCRIPTION_STATUS',
  PRODUCT_NAME = 'PRODUCT_NAME',
  PRODUCT_PROVIDER_NAME = 'PRODUCT_PROVIDER_NAME',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  TRUSTEE_NAME = 'TRUSTEE_NAME',
  CREATION_DATE_TIME = 'CREATION_DATE_TIME',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  TOTAL_AMOUNT = 'TOTAL_AMOUNT',
  YEARLY_PRICING = 'YEARLY_PRICING',
}
