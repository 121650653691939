import {WsBatchExecution, WsJobName, WsStepExecution} from '@lifeislife/lifeislife-ws-api';
import {StepExecution} from '../../domain/batch/step-execution';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';
import {BatchExecution} from '../../domain/batch/batch-execution';
import {BatchJobName} from '../../domain/batch/batch-job-name';

export class BatchConverter {


  static fromWsStepExecution(wsStep: WsStepExecution): StepExecution {
    return {
      commitCount: wsStep.commitCount,
      endTime: DateConverterUtils.parseIsoDateTime(wsStep.endTime),
      processFilterCount: wsStep.processFilterCount,
      processSkipCount: wsStep.processSkipCount,
      readCount: wsStep.readCount,
      readSKipCount: wsStep.readSkipCount,
      rollbackCount: wsStep.rollbackCount,
      startTime: DateConverterUtils.parseIsoDateTime(wsStep.startTime),
      statusMessage: wsStep.statusMessage,
      statusName: wsStep.statusName,
      stepName: wsStep.stepName,
      writeCount: wsStep.writeCount,
      writeSkipCount: wsStep.writeSkipCount,
    };
  }

  static fromWsBatchExecution(wsBatch: WsBatchExecution): BatchExecution {
    return {
      endTime: DateConverterUtils.parseIsoDateTime(wsBatch.endTime),
      executionId: wsBatch.executionId,
      jobName: wsBatch.jobName,
      jobNameMessage: wsBatch.jobNameMessage,
      jobParameters: wsBatch.jobParameters,
      startTime: DateConverterUtils.parseIsoDateTime(wsBatch.startTime),
      statusMessage: wsBatch.statusMessage,
      statusName: wsBatch.statusName,
      wsStepExecutionList: wsBatch.wsStepExecutionList == null ? [] : wsBatch.wsStepExecutionList
        .map(wsStep => BatchConverter.fromWsStepExecution(wsStep)),
    };
  }

  static fromWsJobName(wsJob: WsJobName): BatchJobName {
    return {
      name: wsJob.name,
      label: wsJob.label,
    };
  }
}
