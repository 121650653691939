import {Inject, Injectable, Optional} from '@angular/core';
import {AuthService} from './auth.service';
import {Observable, of, throwError} from 'rxjs';
import {AuthState} from './auth-state';
import {catchError, defaultIfEmpty, filter, map, publishReplay, refCount, switchMap, take, tap} from 'rxjs/operators';
import {INVITE_AUTH_TOKEN} from './invite-auth-token';
import {UserInviteAuthWsClient} from '../../client/resources/front/user-invite-auth-ws-client';
import {WsInviteTokenAuth} from '../../client/domain/auth/ws-invite-token-auth';
import {TrusteeInviteCode} from '../../domain/invite/trustee-invite-code';
import {CustomerInviteCode} from '../../domain/invite/customer-invite-code';
import {UserInviteCodeConverter} from '../../service/invite/user-invite-code.converter';
import {Role} from '../../domain/role/role';

@Injectable()
export class InviteAuthService {

  state$: Observable<AuthState>;

  constructor(private authService: AuthService,
              private userInviteWsClient: UserInviteAuthWsClient,
              private userInviteAuthWsClient: UserInviteAuthWsClient,
              @Inject(INVITE_AUTH_TOKEN) @Optional()
              private queryStringToken: string) {

    this.state$ = this.authService.getState$();
  }

  authenticateCode$(code: string, role: Role.TRUSTEE | Role.CUSTOMER): Observable<TrusteeInviteCode | CustomerInviteCode> {
    const auth = WsInviteTokenAuth.fromCodeAndRole(code, role);
    return this.authenticate$(auth, true).pipe(
      switchMap(wsAuth => this.findInviteCode$(wsAuth, role)),
    );
  }

  deauthenticate(reason?: string) {
    this.authService.deauthtenticate(reason, {});
  }

  isAuthenticated() {
    const curState = this.getCurAuthState();
    return curState.status === 'authenticated'
      && curState.user != null;
  }

  getInviteAuth(): WsInviteTokenAuth {
    const auth = this.authService.getAuth();
    if (auth.type === 'invite-token') {
      return auth as WsInviteTokenAuth;
    } else {
      return null;
    }
  }

  attemptRestoreFromQueryString$(role: Role.TRUSTEE | Role.CUSTOMER, code?: string)
    : Observable<CustomerInviteCode | TrusteeInviteCode | null> {
    const curState = this.getCurAuthState();
    if (curState.inviteRestoreFromQueryTokenAttempted) {
      return of(null);
    }
    if (this.isAuthenticated()) {
      return of(null);
    }
    // Never prevent restoring from invite_token.
    // With all the async events with the oauth silent refresh, we may loose the state
    // and need to parse this token multiple times
    // this.authService.updateAuthState({
    //   inviteRestoreFromQueryTokenAttempted: true,
    // });
    const inviteCode = code || this.queryStringToken;
    if (inviteCode == null) {
      return of(null);
    }
    const auth = WsInviteTokenAuth.fromCodeAndRole(inviteCode, role);
    if (auth == null || auth.type !== 'invite-token') {
      return of(null);
    }
    this.authService.updateAuthState({
      inviteRestoreFromQueryTokenAttempted: true,
    });
    // Attempt to authenticate using the invite code. On success, clear storage, which might contain
    // another token for another user; but do not save the invite code - link is always required, and refresh is thus broken.
    return this.authService.authenticate$(auth).pipe(
      filter(s => s.status === 'authenticated'),
      tap(c => this.authService.removeAuthFromStorage()),
      switchMap(s => this.fetchInviteCode$(s, role)),
      defaultIfEmpty(null),
    );
  }


  private authenticate$(auth: WsInviteTokenAuth, showError: boolean): Observable<WsInviteTokenAuth> {
    const authTask$ = this.authService.authenticate$(auth, {showError: showError}).pipe(
      publishReplay(1), refCount(),
    );
    return authTask$.pipe(
      map((state: AuthState) => auth),
    );
  }

  private getCurAuthState(): AuthState {
    const curState = this.authService.getState();
    return curState;
  }

  private fetchInviteCode$(state: AuthState, role: Role.TRUSTEE | Role.CUSTOMER)
    : Observable<CustomerInviteCode | TrusteeInviteCode | null> {
    if (state == null || state.wsAuth == null) {
      return of(null);
    }
    const wsAuth = state.wsAuth;
    if (wsAuth.type !== 'invite-token') {
      return of(null);
    }
    const inviteAuth = wsAuth as WsInviteTokenAuth;
    return this.findInviteCode$(inviteAuth, role);
  }

  // These work as method overrides
  findInviteCode$(wsAuth: WsInviteTokenAuth, role: Role.TRUSTEE): Observable<TrusteeInviteCode | null>;
  findInviteCode$(wsAuth: WsInviteTokenAuth, role: Role.CUSTOMER): Observable<CustomerInviteCode | null>;
  findInviteCode$(wsAuth: WsInviteTokenAuth, role: Role.CUSTOMER | Role.TRUSTEE): Observable<TrusteeInviteCode | CustomerInviteCode | null>;

  findInviteCode$(wsAuth: WsInviteTokenAuth, role: Role.TRUSTEE | Role.CUSTOMER)
    : Observable<TrusteeInviteCode | CustomerInviteCode | null> {
    let code$;
    if (role === Role.CUSTOMER) {
      code$ = this.userInviteAuthWsClient.getCustomerInviteCode$(wsAuth).pipe(
        map(wsCode => UserInviteCodeConverter.convertCustomerInviteCodeIn(wsCode)),
        catchError(e => of(null)),
      );
    } else if (role === Role.TRUSTEE) {
      code$ = this.userInviteAuthWsClient.getTrusteeInviteCode$(wsAuth).pipe(
        map(wsCode => UserInviteCodeConverter.convertTrusteeInviteCodeIn(wsCode)),
        catchError(e => of(null)),
      );
    } else {
      return throwError(new Error(`Unhandled invite role : ${role}`));
    }
    return code$.pipe(
      filter(code => code != null),
      take(1),
      defaultIfEmpty(null),
    );
  }
}
