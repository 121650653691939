import {Injectable, Optional} from '@angular/core';
import {OAuthStorage} from 'angular-oauth2-oidc';
import {KeyValueStore} from '../peristence/key-value-store';
import {ApplicationCompileTimeSettings} from '../../domain/config/application-config-settings';
import {FrontendAppConfigKey} from '../../domain/config/frontend-app-config-key';

/**
 * We need cross-tab storage, so that a request initiated in one tab can be restored in another tab when receiving code.
 * This implementation uses the keyvaluestore provider, which is implemented using localStorage in browsers.
 */
@Injectable()
export class OauthStorageService implements OAuthStorage {

  constructor(
    private keyValueStore: KeyValueStore,
    @Optional()
    private compileTimeSettings: ApplicationCompileTimeSettings,
  ) {
  }

  getItem(key: string): string | null {
    const myKey = this.getMyKey(key);
    return this.keyValueStore.getValue(myKey);
  }

  removeItem(key: string): void {
    const myKey = this.getMyKey(key);
    this.keyValueStore.clearValue(myKey);
  }

  setItem(key: string, data: string): void {
    const myKey = this.getMyKey(key);
    this.keyValueStore.putValue(myKey, data);
  }

  private getMyKey(key: string) {
    if (this.compileTimeSettings) {
      const prefix = this.compileTimeSettings.config[FrontendAppConfigKey.app_configKey];
      const mykey = `${prefix}.oauth.${key}`;
      return mykey;
    } else {
      const mykey = `oauth.${key}`;
      return mykey;
    }
  }
}
