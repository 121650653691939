import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerFormComponent} from './customer-form.component';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {MainModule} from '../../../main.module';
import {MainCustomerContactComponentsModule} from '../../customer-contact/main-customer-contact-components.module';
import {MainCustomerModule} from '../main-customer.module';
import {MainTrusteeComponentsModule} from '../../trustee/main-trustee-components.module';
import {InputSwitchModule} from 'primeng/inputswitch';
import {MainCountryModule} from '../../country/main-country.module';
import {EditorModule} from 'primeng/editor';
import {MainPricingModule} from '../../pricing/main-pricing.module';
import {CheckboxModule} from 'primeng/checkbox';
import {DateModule} from '@lifeislife/lifeislife-components';


@NgModule({
  declarations: [CustomerFormComponent],
  exports: [CustomerFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    MainModule,
    MainCustomerContactComponentsModule,
    MainCustomerModule,
    MainTrusteeComponentsModule,
    DateModule,
    InputSwitchModule,
    MainCountryModule,
    EditorModule,
    MainPricingModule,
    CheckboxModule,
  ],
})
export class CustomerFormModule {
}
