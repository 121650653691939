import {WsTrusteeContact} from '@lifeislife/lifeislife-ws-api';
import {Injectable} from '@angular/core';
import {TrusteeContactTypeConverter} from './trustee-contact-type.converter';
import {TrusteeContact} from '../../domain/trustee/trustee-contact';

export class TrusteeContactConverter {

  static convertIn(value: WsTrusteeContact): TrusteeContact {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      contactRef: value.contactWsRef,
      trusteeRef: value.trusteeWsRef,
      trusteeContactType: TrusteeContactTypeConverter.convertIn(value.wsTrusteeContactType),
    };
  }

  static convertOut(value: TrusteeContact): WsTrusteeContact {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      contactWsRef: value.contactRef,
      trusteeWsRef: value.trusteeRef,
      wsTrusteeContactType: TrusteeContactTypeConverter.convertOut(value.trusteeContactType),
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsTrusteeContact]: keyof TrusteeContact } {
    return {
      id: 'id',
      contactWsRef: 'contactRef',
      trusteeWsRef: 'trusteeRef',
      wsTrusteeContactType: 'trusteeContactType',
    };
  }

}
