import {WsPensionPlanType} from '@lifeislife/lifeislife-ws-api';
import {PensionPlanType} from '../../domain/retire/pension-plan-type';

export class PensionPlanTypeConverter {

  static convertIn(wsPensionPlanType: WsPensionPlanType): PensionPlanType {
    if (wsPensionPlanType == null) {
      return null;
    }
    return wsPensionPlanType as string as PensionPlanType;
  }


  static convertOut(pensionPlanType: PensionPlanType): WsPensionPlanType {
    if (pensionPlanType == null) {
      return null;
    }
    return pensionPlanType as string as WsPensionPlanType;
  }
}
