import {Injectable} from '@angular/core';
import {WsDaoEventEntityType, WsRef, WsTrustee} from '@lifeislife/lifeislife-ws-api';
import {RequestService} from '../../service/request.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {AppConfigService} from '../../../service/config/app-config.service';
import {CachedWsResourceClient} from '../../private_util/client/cached-http-ws-Resource-client';
import {ResourceCacheService} from '../../../util/cache/resource-cache.service';

@Injectable({
  providedIn: 'root',
})
export class TrusteeWsClient extends CachedWsResourceClient<WsTrustee, WsRef<WsTrustee>> {
  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private injectedAppConfigService: AppConfigService,
  ) {
    super('/front/trustee', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.TRUSTEE, injectectedCacheService);
  }

  getMinCustomerEfficency$(trusteeId: number): Observable<number | null> {
    const url = `${this.getResourceUrl()}/${trusteeId}/minCustomerEfficiency`;

    return this.requestService.sendRequest<{ value: number }>({
      method: 'GET', url: url,
    }, this.authService.getAuth())
      .pipe(
        // TODO(migration): check if value is still wrapped
        map(response => response == null ? null : response.value),
      );
  }

}
