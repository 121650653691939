import {WsInvestmentStatus, WsInvestmentSubscription, WsInvestmentSubscriptionSearch} from '@lifeislife/lifeislife-ws-api';
import {InvestmentSubscription} from '../../../domain/corebusiness/investment/investment-subscription';
import {EnumConverterUtils} from '../../../client/private_util/enum-converter-utils';
import {InvestmentSubscriptionFilter} from '../../../domain/corebusiness/investment/investment-subscription-filter';
import {InvestmentConverter} from './investment-converter';
import {InvestmentStatus} from '../../../domain/corebusiness/investment/investment-status';
import {SubscriptionConverter} from '../subscription-converter';

export class InvestmentSubscriptionConverter {

  static convertIn(value: WsInvestmentSubscription): InvestmentSubscription {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      subscriptionRef: value.subscriptionWsRef,
      investmentRef: value.investmentWsRef,
      investmentStatus: EnumConverterUtils.convertValue(value.wsInvestmentStatus, InvestmentStatus),
      fundName: value.fundName,
    };
  }

  static convertOut(value: InvestmentSubscription): WsInvestmentSubscription {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      subscriptionWsRef: value.subscriptionRef,
      investmentWsRef: value.investmentRef,
      wsInvestmentStatus: EnumConverterUtils.convertValue(value.investmentStatus, WsInvestmentStatus),
      fundName: value.fundName,
    };
  }


  static convertFilterIn(value?: WsInvestmentSubscriptionSearch): InvestmentSubscriptionFilter {
    if (value == null) {
      return undefined;
    }
    return {
      subscriptionSearch: SubscriptionConverter.convertFilterIn(value.subscriptionSearch),
      investmentStatusList: value.investmentStatusList == null ? null : value.investmentStatusList
        .map(status => EnumConverterUtils.convertValue(status, InvestmentStatus)),
      investmentSearch: InvestmentConverter.convertFilterIn(value.investmentSearch),
      investmentSubscriptionRef: value.investmentSubscriptionWsRef,
    };
  }

  static convertFilterOut(value: InvestmentSubscriptionFilter): WsInvestmentSubscriptionSearch {
    if (value == null) {
      return undefined;
    }
    return {
      subscriptionSearch: SubscriptionConverter.convertFilterOut(value.subscriptionSearch),
      investmentStatusList: value.investmentStatusList == null ? null : value.investmentStatusList
        .map(status => EnumConverterUtils.convertValue(status, WsInvestmentStatus)),
      investmentSearch: InvestmentConverter.convertFilterOut(value.investmentSearch),
      investmentSubscriptionWsRef: value.investmentSubscriptionRef,
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsInvestmentSubscription]: keyof InvestmentSubscription } {
    return {
      id: 'id',
      wsInvestmentStatus: 'investmentStatus',
      investmentWsRef: 'investmentRef',
      subscriptionWsRef: 'subscriptionRef',
      fundName: 'fundName',
    };
  }

}
