import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsCreditProvider, WsCreditProviderSearch, WsRef} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../../util/pagination/simple-pagination';
import {SearchResult} from '../../../client/domain/search/search-result';
import {ValidationResult} from '../../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../../object-converter-util';
import {isValidRef, Ref} from '../../../domain/shared/ref';
import {WithId} from '../../../client/domain/with-id';
import {CreditProviderWsClient} from '../../../client/resources/front/corebusiness/credit-provider-ws-client';
import {CreditProviderConverter} from './credit-provider-converter';
import {CreditProvider} from '../../../domain/corebusiness/credit/credit-provider';
import {CreditProviderSearch} from '../../../domain/corebusiness/credit/credit-provider-search';
import {ProductProviderService} from '../product-provider.service';
import {SimplePaginationUtils} from '../../../util/pagination/simple-pagination-utils';
import {ProductProvider} from '../../../domain/corebusiness/product-provider';

@Injectable({
  providedIn: 'root',
})
export class CreditProviderService {


  constructor(private client: CreditProviderWsClient,
              private prouctProviderService: ProductProviderService,
  ) {
  }

  getCreditProvider$(ref: Ref<CreditProvider>, forceFetch?: boolean): Observable<CreditProvider> {
    return ref == null ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsCreditProvider => CreditProviderConverter.convertIn(wsCreditProvider)),
    );
  }


  getCreditForProductProvider$(providerRef: Ref<ProductProvider>): Observable<CreditProvider> {
    const creditSearch: CreditProviderSearch = {
      productProviderSearch: {
        productProviderRef: providerRef,
      },
    };
    const pagination = SimplePaginationUtils.newPagination(1);
    return this.searchCreditProviders$(creditSearch, pagination).pipe(
      map(r => r.count === 0 ? null : r.list[0]),
    );
  }

  getCreditProviderName$(credit: CreditProvider): Observable<string> {
    if (credit == null || credit.id == null || credit.productProviderRef == null) {
      return of('Nouveau frounisseur de céedit');
    }
    return this.prouctProviderService.getProductProvider$(credit.productProviderRef).pipe(
      map(p => p.internalLabel),
    );
  }

  searchCreditProviders$(creditFilter: CreditProviderSearch, pagination: SimplePagination): Observable<SearchResult<CreditProvider>> {
    const wsSearch = CreditProviderConverter.convertFilterOut(creditFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateCreditProvider$(credit: CreditProvider): Observable<ValidationResult<CreditProvider>> {
    const wsCreditProvider = CreditProviderConverter.convertOut(credit);
    return this.client.doValidate(wsCreditProvider).pipe(
      map((results: WsCreditProvider | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveCreditProvider$(credit: CreditProvider): Observable<WithId> {
    const wsCreditProvider = CreditProviderConverter.convertOut(credit);
    return this.client.doSave(wsCreditProvider);
  }

  deleteCreditProvider$(ref: Ref<CreditProvider>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  serializeFilter(creditFilter: CreditProviderSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(creditFilter);
    const wsCreditProviderSearch = CreditProviderConverter.convertFilterOut(creditFilter);
    return JSON.stringify(wsCreditProviderSearch);
  }

  deserializeFilter(valueString: string | null): CreditProviderSearch | null {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsCreditProviderSearch = JSON.parse(valueString);
      const filter = CreditProviderConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsCreditProvider | WsConstraintViolation[]): ValidationResult<CreditProvider> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsCreditProvider => CreditProviderConverter.convertIn(wsCreditProvider),
      propertyNameMappings: CreditProviderConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsCreditProvider>>): Observable<SearchResult<CreditProvider>> {
    const rowTasks = results.list.map(ref => this.getCreditProvider$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<CreditProvider>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<CreditProvider>>>{
          list: rows,
        }),
      ));
  }
}
