<div class="row">

  <div class="list-item">

    <div class="icon">
      <i [ngClass]="packageIcon$ | async"></i>
    </div>

    <div class="name" [title]=" (package$ | async)?.publicDescription">
      {{ (package$ | async)?.publicDescription }}
    </div>

    <div class="status" *ngIf="showStatus">
      <llc-signing-package-status [status]="(package$ | async)?.status"
      ></llc-signing-package-status>
    </div>

    <div class="creation" *ngIf="showCreated">
      {{ (package$ | async)?.creationDateTime | cDateTime }}
    </div>

    <div class="update" *ngIf="showUpdate">
      {{ (package$ | async)?.updateDateTime | cDateTime }}
    </div>

    <div class="action">

      <a class="delete" *ngIf="showDelete"
         (click)="onDeleteClick($event)"
         title="Supprimer" i18n-title
      >
        <i class="fa fa-times"></i>
      </a>
    </div>
  </div>
</div>
