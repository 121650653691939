import {SmtpAccount} from '../../domain/mail/smtp-account';
import {EmailAccount} from '../../domain/mail/email-account';
import {WsEmailAccount, WsSmtpAccount} from '@lifeislife/lifeislife-ws-api';

export class MailAccountConverter {

  static convertSmtpAccountIn(wsAccount: WsSmtpAccount): SmtpAccount {
    return {
      id: wsAccount.id,
      host: wsAccount.host,
      port: wsAccount.port,
      userName: wsAccount.username,
      password: wsAccount.password,
      archived: wsAccount.archived,
      debug: wsAccount.debug,
      ssl: wsAccount.ssl,
      tls: wsAccount.tls,
      maxMessagesPerMinute: wsAccount.maxMessagesPerMinute,
    };
  }

  static convertSmtpAccountOut(account: SmtpAccount): WsSmtpAccount {
    return {
      id: account.id,
      host: account.host,
      port: account.port,
      username: account.userName,
      password: account.password,
      archived: account.archived,
      debug: account.debug,
      ssl: account.ssl,
      tls: account.tls,
      maxMessagesPerMinute: account.maxMessagesPerMinute,
    };
  }


  static convertEmailAccountIn(wsAccount: WsEmailAccount): EmailAccount {
    return {
      id: wsAccount.id,
      fromAddress: wsAccount.fromAddress,
      smtpAccountRef: wsAccount.smtpAccountWsRef,
    };
  }

  static convertEmailAccountOut(account: EmailAccount): WsEmailAccount {
    return {
      id: account.id,
      fromAddress: account.fromAddress,
      smtpAccountWsRef: account.smtpAccountRef,
    };
  }


}
