import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsProduct} from '@lifeislife/lifeislife-ws-api';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProductWsClient} from '../../client/resources/front/corebusiness/product-ws-client';
import {ProductConverter} from './product-converter';
import {Ref} from '../../domain/shared/ref';
import {Product} from '../../domain/corebusiness/product';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {WithId} from '../../client/domain/with-id';
import {ProductType} from '../../domain/corebusiness/product-type';

@Injectable({
  providedIn: 'root',
})
export class ProductService {


  constructor(private client: ProductWsClient,
  ) {
  }

  getProduct$(ref: Ref<Product>, forceFetch?: boolean): Observable<Product> {
    return ref == null ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsProduct => ProductConverter.convertIn(wsProduct)),
    );
  }

  validateProduct$(product: Product): Observable<ValidationResult<Product>> {
    const wsProduct = ProductConverter.convertOut(product);
    return this.client.doValidate(wsProduct).pipe(
      map((results: WsProduct | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveProduct$(product: Product): Observable<WithId> {
    const wsProduct = ProductConverter.convertOut(product);
    return this.client.doSave(wsProduct);
  }

  getProductLabel(product: Product): string {
    return product.name;
  }

  getProductTypeLabel(productType: ProductType): string {
    switch (productType) {
      case ProductType.CREDIT:
        return `Crédit`;
      case ProductType.INSURANCE:
        return `Assurance`;
      case ProductType.INVESTMENT:
        return `Placement`;
      default:
        return `${productType}`;
    }
  }

  private createValidationResult(results: WsProduct | WsConstraintViolation[]): ValidationResult<Product> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsProduct => ProductConverter.convertIn(wsProduct),
      propertyNameMappings: ProductConverter.getValidationPropertyNameMappings(),
    });
  }

}
