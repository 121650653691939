import {Injectable} from '@angular/core';
import {Country} from '@lifeislife/lifeislife-domain';
import {CountrySelectItem} from './country-select-item';

@Injectable({
  providedIn: 'root',
})
export class CountryItemsService {

  createCountryItems(countrys: Country[], options: {
    codeAsLabel: boolean
    noSelectionLabel: string
  }): CountrySelectItem[] {
    return countrys.map(type => this.createCountryItem(type, options));
  }

  createCountryItem(country: Country, options: {
    codeAsLabel: boolean,
    noSelectionLabel: string
  }): CountrySelectItem {
    return {
      value: country == null ? null : {id: country.id},
      label: this.getLabel(options, country),
      country: country,
    };
  }

  private getLabel(options: { codeAsLabel: boolean; noSelectionLabel: string }, country) {
    if (country == null) {
      return options.noSelectionLabel;
    }
    return options.codeAsLabel ? country.code : country.nameFr;
  }
}
