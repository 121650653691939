import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrusteeContactTypeBadgeComponent} from './trustee-contact-type-badge.component';
import {TrusteeContactTypeModule} from '../trustee-contact-type/trustee-contact-type.module';


@NgModule({
  declarations: [TrusteeContactTypeBadgeComponent],
  exports: [TrusteeContactTypeBadgeComponent],
  imports: [
    CommonModule,
    TrusteeContactTypeModule,
  ],
})
export class TrusteeContactTypeBadgeModule {
}
