import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerContactTypeBadgeComponent} from './customer-contact-type-badge.component';
import {CustomerContactTypeModule} from '../customer-contact-type/customer-contact-type.module';


@NgModule({
  declarations: [CustomerContactTypeBadgeComponent],
  exports: [CustomerContactTypeBadgeComponent],
  imports: [
    CommonModule,
    CustomerContactTypeModule,
  ],
})
export class CustomerContactTypeBadgeModule {
}
