import {Component, Input, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {OptionalBooleanRadioSelectService} from './optional-boolean-radio-select-service';

@Component({
  selector: 'optional-boolean-radio-select',
  templateUrl: 'optional-boolean-radio-select.template.html',
  styleUrls: ['./optional-boolean-radio-select.style.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR, useExisting: OptionalBooleanRadioSelectComponent, multi: true,
  }],
})
export class OptionalBooleanRadioSelectComponent implements ControlValueAccessor, OnInit {
  @Input()
  disabled = false;
  @Input()
  nullLabel = 'Peu importe';
  @Input()
  yesLabel = 'Oui';
  @Input()
  noLabel = 'Non';
  @Input()
  group = 'optional-boolean';

  allItems: SelectItem[];
  selectedValue: boolean = null;
  groupSuffix: string;

  private onChangeFunction: Function;
  private onTouchedFunction: Function;

  constructor(private service: OptionalBooleanRadioSelectService) {
    this.groupSuffix = service.getNextGroupSuffix();
  }

  ngOnInit() {
    this.allItems = this.createSelectItems();
  }


  writeValue(value: boolean): void {
    const fixedValue = value == null ? null : value;
    this.selectedValue = fixedValue;
  }

  registerOnChange(fn: any): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFunction = fn;
  }

  onValueChanged(value: boolean) {
    this.selectedValue = value;
    this.fireChange();
  }

  fireChange() {
    this.onTouchedFunction();
    this.onChangeFunction(this.selectedValue);
  }

  private createSelectItems(): SelectItem[] {
    const items: SelectItem[] = [{
      label: this.nullLabel,
      value: null,
    }, {
      label: this.yesLabel,
      value: true,
    }, {
      label: this.noLabel,
      value: false,
    }];
    return items;
  }
}
