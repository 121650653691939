import {Role} from '../../../domain/role/role';
import {WsUserTokenAuth} from './ws-user-token-auth';

export const AUTH_TYPE_BASIC = 'basic';
export const AUTH_TYPE_OAUTH = 'oauth';
export const AUTH_TYPE_USER_TOKEN = 'user-token';

// basic is used for tests and user:password flow (to obtain a token)
// user-token is the basic, refreshable token (long-term refresh token kept clear in local storage)
// oauth is deprectade and probably not used anymore.
// invite-token is parsed from the app url query string and sent in the Bearer as well.
//    it grants the INVITEE role replacing user iirc.
// oidc-token is the recent oidc token obtained from the keycloak server. Token refresh is delegated to the lib.
export type SupportedAuthType = 'basic' | 'oauth' | 'user-token' | 'invite-token' | 'oidc-token';

export interface LifeisLifeWsAuth {
  authorizationHeader: string;
  authorizationQueryString: string;
  type: SupportedAuthType;
  // Allow to differenciate customer invites from accountant/trustee invites
  role?: Role;
}

export function isUserTokenAuth(auth: LifeisLifeWsAuth): auth is WsUserTokenAuth {
  return auth != null && auth.type === 'user-token';
}

export function isOidcAuth(auth: LifeisLifeWsAuth): auth is WsUserTokenAuth {
  return auth != null && auth.type === 'oidc-token';
}


