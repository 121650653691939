import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PercentageInputComponent} from './percentage-input.component';
import {FormsModule} from '@angular/forms';
import {SliderModule} from 'primeng/slider';
import {SpinnerModule} from 'primeng/spinner';
import {InputNumberModule} from 'primeng/inputnumber';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        SpinnerModule,
        SliderModule,
        InputNumberModule,

    ],
  declarations: [
    PercentageInputComponent,
  ],
  exports: [
    PercentageInputComponent,
  ],
})
export class PercentageInputModule {

}
