<div class="lifeislife-application">
  <div class="app-loading" *ngIf="(authenticating$ | async)">
    <div class="loading-bloc">
      <div class="logo">
        <div class="loader"></div>
        <div class="image"></div>
      </div>
      <div class="app-loading-message">
        Vérification de vos informations...
      </div>
    </div>
  </div>
  <div  [hidden]="authenticating$ | async">
    <router-outlet>
    </router-outlet>
  </div>
</div>
