import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {TrusteeSearch} from '../../domain/trustee/trustee-search';
import {Trustee} from '../../domain/trustee/trustee';
import {TrusteeConverter} from './trustee.converter';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {WsConstraintViolation, WsRef, WsTrustee} from '@lifeislife/lifeislife-ws-api';
import {ObjectConverterUtil} from '../object-converter-util';
import {ValidationResult} from '../../domain/shared/validation-result';
import {TrusteeWsClient} from '../../client/resources/front/trustee-ws-client';
import {TrusteePartnersWsClient} from '../../client/resources/front/trustee-partners-ws-client';
import {TrusteeStatus} from '../../domain/trustee/trustee-status';
import {UnrestrictedTrusteeClient} from '../../client/resources/unrestricted/unresticted-trustee-client';
import {TrusteeSearchConverter} from './trustee-search.converter';

@Injectable({
  providedIn: 'root',
})
export class TrusteeService {

  constructor(private client: TrusteeWsClient,
              private partnerClient: TrusteePartnersWsClient,
              private unrestrictedClient: UnrestrictedTrusteeClient,
  ) {
  }

  getTrustee$(ref: Ref<Trustee>, forceFetch?: boolean): Observable<Trustee> {
    return !isValidRef(ref) ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsTrustee => TrusteeConverter.convertIn(wsTrustee)),
    );
  }

  getPublicTrusteeView$(ref: Ref<Trustee>): Observable<Partial<Trustee>> {
    return !isValidRef(ref) ? of(null) : this.unrestrictedClient.getTrustee$(ref).pipe(
      map(wsTrustee => TrusteeConverter.convertPublicIn(wsTrustee)),
    );
  }

  getTrusteePreferences$(ref: Ref<Trustee>): Observable<{ [key: string]: string }> {
    return !isValidRef(ref) ? of(null) : this.unrestrictedClient.getTrusteePreferencesMap$(ref.id);
  }

  saveTrustee$(trustee: Trustee): Observable<Trustee> {
    const wsTrustee = TrusteeConverter.convertOut(trustee);
    if (trustee.id == null) {
      return this.client.doCreate(wsTrustee).pipe(
        switchMap(wsRef => this.getTrustee$(wsRef)),
      );
    } else {
      return this.client.doUpdate(wsTrustee).pipe(
        switchMap(wsRef => this.getTrustee$(wsRef)),
      );
    }
  }


  validateTrustee$(trustee: Trustee): Observable<ValidationResult<Trustee>> {
    const wsTrustee = TrusteeConverter.convertOut(trustee);
    return this.client.doValidate(wsTrustee).pipe(
      map(results => this.convertValidationResuls$(results)),
    );
  }

  getTrusteeName$(ref: Ref<Trustee>): Observable<string> {
    return this.getTrustee$(ref).pipe(
      map(trustee => trustee.name),
    );
  }

  getTrusteeRefByKey$(key: string): Observable<Ref<Trustee> | null> {
    if (key == null) {
      return of(null);
    }
    return this.unrestrictedClient.findTrusteeForKey$(key);
  }

  searchTrustees$(trusteeSearch: TrusteeSearch, pagination: SimplePagination): Observable<SearchResult<Trustee>> {
    const wsSearch = TrusteeSearchConverter.toWsTrusteeSearch(trusteeSearch);
    return this.client.doSearch(wsSearch, pagination).pipe(
      mergeMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  /**
   * Uses the unrestricted resource. May have different filter/validation at some point
   */
  publicTrusteeSearch$(trusteeSearch: TrusteeSearch, pagination: SimplePagination): Observable<SearchResult<Partial<Trustee>>> {
    const wsSearch = TrusteeSearchConverter.toWsTrusteeSearch(trusteeSearch);
    return this.unrestrictedClient.searchTrustees$(wsSearch, pagination).pipe(
      mergeMap(results => this.convertPublicSearchResultsIn$(results)),
    );
  }

  getMinCustomerEfficiency$(trusteeRef?: Ref<Trustee>): Observable<number | null> {
    return !isValidRef(trusteeRef) ? of(null) : this.client.getMinCustomerEfficency$(trusteeRef.id);
  }

  getTrusteePartners$(trusteeRef: Ref<Trustee>): Observable<Ref<Trustee>[]> {
    return this.partnerClient.searchPartnersRefs(trusteeRef);
  }


  getValidationPropertyNameMappings() {
    return TrusteeConverter.getValidationPropertyNameMappings();
  }

  getTrusteeStatusName(status: TrusteeStatus) {
    // TODO: i18n:
    if (status == null) {
      return null;
    }
    switch (status) {
      case TrusteeStatus.ACTIVE:
        return 'Actif';
      case TrusteeStatus.INACTIVE:
        return 'Inactif';
      case TrusteeStatus.ARCHIVED:
        return 'Archivé';
    }
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsTrustee>>): Observable<SearchResult<Trustee>> {
    const rowTasks = results.list.map(ref => this.getTrustee$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<Trustee>()) : forkJoin(rowTasks).pipe(
      map((rows: Trustee[]) => Object.assign({} as SearchResult<Trustee>, results, {
          list: rows,
        }),
      ));
  }

  private convertPublicSearchResultsIn$(results: SearchResult<WsRef<WsTrustee>>): Observable<SearchResult<Partial<Trustee>>> {
    const rowTasks = results.list.map(ref => this.getPublicTrusteeView$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<WsRef<WsTrustee>>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, {
          list: rows,
        }),
      ));
  }

  private convertValidationResuls$(results: WsConstraintViolation[] | WsTrustee): ValidationResult<Trustee> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsTicket => TrusteeConverter.convertIn(wsTicket),
      propertyNameMappings: TrusteeConverter.getValidationPropertyNameMappings(),
    });
  }
}
