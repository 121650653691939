import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {InsuranceCategoryFamily, InsuranceService} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, publishReplay, refCount} from 'rxjs/operators';

@Component({
  selector: 'llc-insurance-category-family',
  templateUrl: './insurance-category-family.component.html',
  styleUrls: ['./insurance-category-family.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceCategoryFamilyComponent implements OnInit {

  @Input()
  set family(value: InsuranceCategoryFamily) {
    this.familySource$.next(value);
  }

  @Input()
  badge: boolean;

  label$: Observable<string>;
  familySource$ = new BehaviorSubject<InsuranceCategoryFamily | null>(null);


  constructor(
    private insuranceService: InsuranceService,
  ) {
  }

  ngOnInit() {
    this.label$ = this.familySource$.pipe(
      map(family => this.insuranceService.getInsuranceFamilyLabel(family)),
      publishReplay(1), refCount(),
    );
  }

}
