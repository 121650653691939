import {Injectable} from '@angular/core';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {CustomerTypeConverter} from './customer-type.converter';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {WsConstraintViolation, WsCustomerType, WsRef} from '@lifeislife/lifeislife-ws-api';
import {CustomerType} from '../../domain/customer/customer-type';
import {CustomerTypeWsClient} from '../../client/resources/front/customer/customer-type-ws-client';
import {CustomerTypeSearch} from '../../domain/customer/customer-type-search';
import {CustomerTypeSearchConverter} from './customer-type-search.converter';

@Injectable({
  providedIn: 'root',
})
export class CustomerTypeService {


  constructor(private client: CustomerTypeWsClient,
  ) {
  }

  getCustomerType$(ref: Ref<CustomerType>): Observable<CustomerType> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doGet(ref.id).pipe(
      map(wsCustomerType => CustomerTypeConverter.convertIn(wsCustomerType)),
    );
  }

  searchCustomerTypes$(customerTypeSearch: CustomerTypeSearch, pagination: SimplePagination): Observable<SearchResult<CustomerType>> {
    const wsSearch = CustomerTypeSearchConverter.toWsCustomerTypeSearch(customerTypeSearch);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateCustomerType$(customerType: CustomerType): Observable<ValidationResult<CustomerType>> {
    const wsCustomerType = CustomerTypeConverter.convertOut(customerType);
    return this.client.doValidate(wsCustomerType).pipe(
      map((results: WsCustomerType | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveCustomerType$(customerType: CustomerType): Observable<any> {
    const wsCustomerType = CustomerTypeConverter.convertOut(customerType);
    return this.client.doSave(wsCustomerType);
  }

  deleteCustomerType$(ref: Ref<CustomerType>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }


  getCustomerTypeLabel$(ref: Ref<CustomerType>): Observable<string> {
    if (ref == null) {
      return of(null);
    }
    return this.getCustomerType$(ref).pipe(
      map(type => type.name),
    );
  }

  private createValidationResult(results: WsCustomerType | WsConstraintViolation[]): ValidationResult<CustomerType> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsCustomerType => CustomerTypeConverter.convertIn(wsCustomerType),
      propertyNameMappings: CustomerTypeConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsCustomerType>>): Observable<SearchResult<CustomerType>> {
    const rowTasks = results.list.map(wsRef => this.getCustomerType$(wsRef));
    return rowTasks.length === 0 ? of(new SearchResult<CustomerType>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<CustomerType>>>{
          list: rows,
        }),
      ));
  }

}
