<div class="radio-group">
    <span class="radio-option ui-widget"
          [class.invalid]="hasError"
          *ngFor="let item of items">
        <p-radioButton [name]="group"
                       [disabled]="disabled"
                       [value]="item.value"
                       [attr.id]="group+'-'+item.value"
                       [ngModel]="selectedValue"
                       (ngModelChange)="onChange($event, false)"
        >
        </p-radioButton>
        <label [attr.for]="group+'-'+item.value"
               [class.disabled]="disabled"
               (click)="onChange(item.value, true)"
               >
            {{ item.label }}
        </label>
    </span>
</div>
