<div class="insurance-provider-select">
  <p-autoComplete [ngModel]="selectedItem$ | async"
                  field="label"
                  dataKey="value"
                  [suggestions]="suggestions$ | async"
                  [dropdown]="!(readonly || disabled)"
                  [readonly]="readonly || disabled"
                  [autoHighlight]="true"
                  [forceSelection]="true"
                  [completeOnFocus]="!disabled && !readonly"
                  [minLength]="1"
                  [appendTo]="appendTo"
                  scrollHeight="400px"
                  (onSelect)="onItemSelect($event)"
                  (onClear)="onItemClear()"
                  (completeMethod)="searchSuggestions($event.query)"
                  (onDropdownClick)="searchAllSuggestions()"
                  [inputStyleClass]="hasError ? 'ng-dirty ng-invalid' : '' "
  ></p-autoComplete>
</div>
