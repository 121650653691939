import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, publishReplay, refCount, switchMap, tap} from 'rxjs/operators';
import {Contact, Customer, CustomerContact, CustomerContactSearch, Ref, Trustee} from '@lifeislife/lifeislife-domain';
import {ObjectUtils} from '../../../../commons/util/ObjectUtils';
import {CustomerContactItemsService} from '../customer-contact-items.service';
import {SelectItem} from 'primeng/api';

/**
 * Selects a contact id from all contacts linked to a customer
 */
@Component({
  selector: 'app-customer-contact-dropdown-select',
  templateUrl: './customer-contact-select.component.html',
  styleUrls: ['./customer-contact-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomerContactSelectComponent),
    multi: true,
  }],
})
export class CustomerContactSelectComponent implements OnInit, ControlValueAccessor {

  @Input()
  disabled = false;
  @Input()
  hasError = false;
  @Input()
  includeNoSelectionOption = true;
  @Input()
  noSelectionLabel = '<Aucun>';
  @Input()
  filterOverride: Partial<CustomerContactSearch>;
  @Input()
  appendTo = 'body';
  @Input()
  showFilter = true;
  @Input()
  itemCount = 100;

  @Input()
  set trusteeRef(ref: Ref<Trustee>) {
    this.trusteeRefSource$.next(ref);
  }

  @Input()
  set customerRef(ref: Ref<Customer>) {
    this.customerRefSource$.next(ref);
  }

  customerContactFilter$: Observable<CustomerContactSearch>;
  selectedValue: Ref<CustomerContact>;
  selectItems$: Observable<SelectItem<Ref<CustomerContact>>[]>;

  customerRefSource$ = new BehaviorSubject<Ref<Customer>>(null);
  trusteeRefSource$ = new BehaviorSubject<Ref<Trustee>>(null);
  searchFilterSource$ = new BehaviorSubject<string | null>(null);

  private onChangeFunction: Function;
  private onTouchedFunction: Function;


  constructor(private customerContactItemService: CustomerContactItemsService) {
  }

  ngOnInit() {
    this.customerContactFilter$ = combineLatest(
      this.trusteeRefSource$.pipe(distinctUntilChanged(ObjectUtils.isSameRef)),
      this.customerRefSource$.pipe(distinctUntilChanged(ObjectUtils.isSameRef)),
      this.searchFilterSource$.pipe(distinctUntilChanged()),
    ).pipe(
      map(results => this.createContactFilter(results[0], results[1], results[2])),
      publishReplay(1), refCount(),
    );

    this.selectItems$ = this.customerContactFilter$.pipe(
      switchMap(f => this.customerContactItemService.createCustomerContactItemsForSearchFilter$(f, this.itemCount, {
        includeNoValueOption: this.includeNoSelectionOption,
        noSelectionLabel: this.noSelectionLabel,
      })),
      publishReplay(1), refCount(),
    );
  }

  writeValue(contactRef: Ref<Contact>): void {
    this.selectedValue = contactRef;
  }

  registerOnChange(fn: any): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFunction = fn;
  }

  onChange(value: Ref<Contact>) {
    this.selectedValue = value;
    this.fireChange(value);
  }

  fireChange(value: Ref<Contact>) {
    this.onTouchedFunction();
    this.onChangeFunction(value);
  }

  private createContactFilter(trusteeRef: Ref<Trustee> | null, customerRef: Ref<Customer> | null, query: string | null): CustomerContactSearch {
    const cusotmerContactSearch: CustomerContactSearch = {
      trusteeRef: trusteeRef,
      customerRef: customerRef,
      contactFilter: {
        anyNameContains: query,
      },
    };
    if (this.filterOverride) {
      return Object.assign({}, cusotmerContactSearch, this.filterOverride);
    } else {
      return cusotmerContactSearch;
    }
  }

}
