import {WsConstraintViolation} from '@lifeislife/lifeislife-ws-api';
import {ValidationResult} from '../domain/shared/validation-result';

/**
 * @deprecated Do not use
 */
export class ObjectConverterUtil {

  /**
   * @deprecated Should remain private in lifeislife-domain once all Ws* references
   * have been removed from the applications
   * // TODO: move to own class
   */
  static createValidationResult<T, U, K extends keyof T = keyof T, L extends keyof U = keyof U>
  (value: T | WsConstraintViolation[], options: {
    converter: (wsVal: T) => U,
    propertyNameMappings: { [M in keyof T]?: L | L[] } // Allow to map a key to multiple properties
  }): ValidationResult<U> {
    if (value == null) {
      return <ValidationResult<U>>{
        propertiesErrors: {},
        errors: [],
        valid: true,
      };
    }
    if (value instanceof Array) {
      return this.createValidationErrorResult(value, options);
    } else {
      const updatedValue = options.converter(value);
      return <ValidationResult<U>>{
        propertiesErrors: {},
        errors: [],
        updatedValue: updatedValue,
        valid: true,
      };
    }
  }

  static filterValidationResult<T>(value: ValidationResult<T>, ...ignoredKeys: (keyof T | string)[]): ValidationResult<T> {
    const propertiesErrors: { [K in keyof T]?: string[] } = {};
    for (const propKey in value.propertiesErrors) {
      if (typeof propKey !== 'string') {
        continue;
      }
      const key: keyof T = propKey as keyof T;
      if (ignoredKeys.indexOf(key) >= 0) {
        continue;
      }
      propertiesErrors[propKey] = value.propertiesErrors[propKey];
    }
    const errorKeys = Object.getOwnPropertyNames(propertiesErrors);
    const valid = value.errors.length === 0 && errorKeys.length === 0;
    return {
      propertiesErrors: propertiesErrors,
      updatedValue: value.updatedValue,
      errors: value.errors,
      valid: valid,
    };
  }


  static cleanUnsetFilterValues(searchFilter: any) {
    for (const key in searchFilter) {
      if (typeof key !== 'string') {
        continue;
      }
      const value = searchFilter[key];
      if (value == null) {
        searchFilter[key] = undefined;
      } else if (typeof value === 'string') {
        if (value.length < 1) {
          searchFilter[key] = undefined;
        }
      } else if (typeof value === 'object') {
        if (value.length != null && value.splice != null) {
          // array
          if (value.length === 0) {
            searchFilter[key] = undefined;
          }
        } else {
          this.cleanUnsetFilterValues(value);
        }
      }
    }
  }


  private static mapPropertyName<T, U, K = keyof T, L = keyof U>(key: K | string, keyOverride?: L | L[]): L[] {
    if (keyOverride == null) {
      return [key as unknown as L];
    }
    // check array
    if (Array.isArray(keyOverride)) {
      return keyOverride as L[];
    } else {
      return [keyOverride as L];
    }
  }

  private static createValidationErrorResult<T, U, K = keyof T, L = keyof U>
  (value: WsConstraintViolation[], options: {
    converter: (wsVal: T) => U,
    propertyNameMappings: { [M in keyof T]?: L | L[] }
  }): ValidationResult<U> {
    const mainErrors: string[] = [];
    const propertiesErrors: { [M in keyof U]?: string[] } = {};

    value.forEach(violation => {
      const message = violation.localizedMessage;
      const violationKey = violation.propertyName;
      if (violationKey == null || violationKey.length === 0) {
        mainErrors.push(message);
      } else {
        const propertyNames: L[] = ObjectConverterUtil.mapPropertyName<T, U, K, L>(violationKey, options.propertyNameMappings[violationKey]);
        ObjectConverterUtil.setPropertyErrors<U, L>(propertiesErrors, propertyNames, message);
      }
    });

    return {
      valid: false,
      updatedValue: null,
      errors: mainErrors,
      propertiesErrors: propertiesErrors,
    };
  }

  private static setPropertyErrors<U, L = keyof U>(propertiesErrors: { [M in keyof U]?: string[] }, propertyNames: L[], message: string): void {
    for (const propertyName of propertyNames) {
      const propKey = propertyName as unknown as keyof U;
      let propertyErrors: string[];
      if (propertiesErrors[propKey] != null) {
        propertyErrors = propertiesErrors[propKey];
      } else {
        const errors: string[] = [];
        propertiesErrors[propKey] = errors;
        propertyErrors = errors;
      }
      propertyErrors.push(message);
    }
  }


  static mergeValidationResult<T, U, V, K extends keyof T = keyof T, L extends keyof U = keyof U, M extends keyof V = keyof V>
  (sourceResultsA: ValidationResult<T>, sourceResultsB: ValidationResult<U>, options: {
    converter: (valA: T, valB: U) => V,
    propertyNameMappings: [{ [N in keyof T]?: M }, { [N in keyof U]?: M }],
    ignoreUnmapped?: boolean;
  }): ValidationResult<V> {
    if (sourceResultsA == null && sourceResultsB == null) {
      return <ValidationResult<V>>{
        propertiesErrors: {},
        errors: [],
        valid: true,
        updatedValue: undefined,
      };
    }
    const safeSourceResultA: ValidationResult<T> = sourceResultsA || {
      propertiesErrors: {},
      errors: [],
      valid: true,
      updatedValue: undefined,
    };
    const safeSourceResultB: ValidationResult<U> = sourceResultsB || {
      propertiesErrors: {},
      errors: [],
      valid: true,
      updatedValue: undefined,
    };

    let updatedValue: V;
    const updatedValueA = safeSourceResultA.updatedValue;
    const updatedValueB = safeSourceResultB.updatedValue;
    if (updatedValueA || updatedValueB) {
      updatedValue = options.converter(updatedValueA, updatedValueB);
    }

    const propertiesErrors: { [N in keyof V]?: string[] } = {};
    let valid = true;
    const propertiesErrorsA = safeSourceResultA.propertiesErrors;
    const propertiesErrorsB = safeSourceResultB.propertiesErrors;

    const allMainErrors = [
      ...safeSourceResultA.errors,
      ...safeSourceResultB.errors,
    ];

    for (const propertyA in propertiesErrorsA) {
      if (typeof propertyA === 'string') {
        const mappings = options.propertyNameMappings[0];
        const updatedPropertyName: keyof V = mappings && mappings.hasOwnProperty(propertyA) && mappings[propertyA] ? mappings[propertyA] : null;
        if (updatedPropertyName) {
          propertiesErrors[updatedPropertyName] = propertiesErrorsA[propertyA];
        } else if (options.ignoreUnmapped) {
          console.warn(`Unmapped validation property name: ${propertyA}`);
          allMainErrors.push(`${propertyA}: ${propertiesErrorsA[propertyA]}`);
        }
        valid = false;
      }
    }
    for (const propertyB in propertiesErrorsB) {
      if (typeof propertyB === 'string') {
        const mappings = options.propertyNameMappings[1];
        const updatedPropertyName: keyof V = mappings && mappings.hasOwnProperty(propertyB) && mappings[propertyB] ? mappings[propertyB] : null;
        if (updatedPropertyName) {
          propertiesErrors[updatedPropertyName] = propertiesErrorsB[propertyB];
        } else if (options.ignoreUnmapped) {
          console.warn(`Unmapped validation property name: ${propertyB}`);
          allMainErrors.push(`${propertyB}: ${propertiesErrorsB[propertyB]}`);
        }
        valid = false;
      }
    }

    return <ValidationResult<V>>{
      propertiesErrors: propertiesErrors,
      errors: allMainErrors,
      updatedValue: updatedValue,
      valid: valid,
    };
  }
}
