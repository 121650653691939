import {Injectable} from '@angular/core';
import {RequestService} from '../../../service/request.service';
import {WsDaoEventEntityType, WsMaritalStatus} from '@lifeislife/lifeislife-ws-api';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MaritalStatusWsClient extends CachedWsResourceClient<WsMaritalStatus, WsMaritalStatus> {

  private cachedStatusList: WsMaritalStatus[] | null = null;

  constructor(private injectedRequestService: RequestService,
              private injectedAuthService: AuthProvider,
              private injectectedCacheService: ResourceCacheService,
              private injectedAppConfigService: AppConfigService) {
    super('/front/maritalStatus', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.MARITAL_STATUS, injectectedCacheService);
  }

  doGet(id: number, forceFetch?: boolean): Observable<WsMaritalStatus> {
    if (!forceFetch && this.cachedStatusList) {
      const wsStatus = this.cachedStatusList.find(s => s.id === id);
      if (wsStatus) {
        return of(wsStatus);
      }
    }
    return super.doGet(id, forceFetch);
  }

  listAllStatuses$(forceFetch?: boolean): Observable<WsMaritalStatus[]> {
    if (this.cachedStatusList && !forceFetch) {
      return of(this.cachedStatusList);
    }
    const url = `${this.getResourceUrl()}/list`;
    return this.requestService.sendRequest<WsMaritalStatus[]>({
      url: url,
      method: 'GET',
    }, this.authService.getAuth()).pipe(
      tap(l => this.cachedStatusList = l),
    );
  }
}
