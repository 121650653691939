import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserComponent} from './user/user.component';
import {MainContactComponentsModule} from '../contact/main-contact-components.module';
import {UserTypeComponent} from './user-type/user-type.component';
import {UserTypeSelectComponent} from './user-type-select/user-type-select.component';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import {UserFormComponent} from './user-form/user-form.component';
import {InputTextModule} from 'primeng/inputtext';
import {MainModule} from '../../main.module';
import {UserSelectComponent} from './user-select/user-select.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {InputSwitchModule} from 'primeng/inputswitch';

@NgModule({
  declarations: [UserComponent, UserTypeComponent, UserTypeSelectComponent, UserFormComponent, UserSelectComponent],
  exports: [UserComponent, UserTypeComponent, UserTypeSelectComponent, UserFormComponent, UserSelectComponent],
    imports: [
        CommonModule,
        MainContactComponentsModule,
        DropdownModule,
        FormsModule,
        InputTextModule,
        MainModule,
        AutoCompleteModule,
        InputSwitchModule,
    ],
})
export class MainUserModule {
}
