<div class="login-body">

  <header>
    <img src="{{baseHref}}assets/logo-lifeislife-128.png" class="logo">
    <h1 i18n>Bienvenue dans LifeisLife</h1>
  </header>

  <p-tabView [(activeIndex)]="loginMethodTabIndex">
    <p-tabPanel header="Email">
      <div *ngIf="emailSent; else emailNotSent" class="info-panel">
        <p>
          Un email vous a été envoyé. Cliquez sur le lien qu'il contient pour ouvrir votre session.
        </p>
      </div>
      <ng-template #emailNotSent>
        <p>Pour vous authentifier par mail, entrez-votre adresse ci-dessous et cliquez sur lien qui vous sera
          envoyé.</p>
        <form #emailForm="ngForm" (ngSubmit)="onEmailLogin(emailForm.valid)">
          <div class="form-table">
            <div class="row">
              <label for="email" i18n>Adresse email</label>
              <input type="email" autocomplete="off" autofocus
                     placeholder="Email" i18n-placeholder
                     class="ui-inputtext ui-widget"
                     id="email" pInputText
                     [(ngModel)]="email"
                     required [minLength]="1"
                     name="email">
            </div>
          </div>
          <div class="button-container">
            <button type="submit" hidden></button>
            <button type="button" pButton
                    [icon]="(authenticating$ | async) ? 'fa fa-circle-o-notch' : 'fa fa-sign-in'"
                    label="Envoyer l'email" i18n-label
                    [disabled]="authenticating$ | async"
                    (click)="onEmailLogin(emailForm.valid)"
            ></button>
          </div>
        </form>
      </ng-template>
    </p-tabPanel>


    <p-tabPanel header="Mot de passe">
      <form class="login-panel" #loginForm="ngForm" (ngSubmit)="onLogin(loginForm.valid)">
        <div class="form-table">
          <div class="row">
            <label for="username" i18n>Login</label>
            <div>
              <input type="text" autocomplete="off" autofocus
                     placeholder="Login" i18n-placeholder
                     class="ui-inputtext ui-widget"
                     id="username" pInputText
                     [(ngModel)]="login"
                     required [minLength]="1"
                     name="login">
            </div>
          </div>

          <div class="row">
            <label for="password" i18n>Mot de passe</label>
            <div class="input-wrapper">
              <input type="password" autocomplete="off"
                     placeholder="Mot de passe" i18n-placeholder
                     class="ui-inputtext ui-widget"
                     id="password"
                     required pPassword [feedback]="false"
                     [(ngModel)]="password"
                     name="password">

            </div>
          </div>
        </div>

        <div class="button-container">
          <button type="submit" hidden></button>
          <button type="button" pButton
                  [icon]="(authenticating$ | async) ? 'fa fa-circle-o-notch' : 'fa fa-sign-in'"
                  label="Se connecter" i18n-label
                  [disabled]="authenticating$ | async"
                  (click)="onLogin(loginForm.valid)"
          ></button>
        </div>
      </form>
    </p-tabPanel>
  </p-tabView>


  <div class="messages p-card" *ngIf="(message$ | async) != null">
    <div class="message">
      {{ message$ | async }}
    </div>
    <div class="error-details" *ngIf="(debug$ | async) && (error$ | async) != null">
      {{ (error$ | async) | json }}
    </div>
  </div>

  <footer class="action-links">
    <div class="action" hidden>
      <a href="" i18n>Mot de passe oublié?</a>
    </div>
  </footer>

</div>
