<div class="validation-error-message ui-widget">

  <ul class="errors">
    <li *ngFor="let msg of errorMessages;trackBy: trackMsg" [@flyInOut]="'in'"
        class="lil-validation-error-message"
        [title]="msg">
      {{ msg }}
    </li>
    <li *ngIf="errorMessage != null"
        class="lil-validation-error-message"
        [title]="errorMessage">
      {{ errorMessage }}
    </li>
  </ul>
  <div [class.hidden]="helpMessage == null" class="tip">
    {{ helpMessage }}
  </div>
</div>
