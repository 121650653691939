import {WsMessageTemplate} from '@lifeislife/lifeislife-ws-api';
import {MessageTemplate} from '../../domain/mail/message-template';
import {FlavouredMessageConverter} from './flavoured-message-converter';

export class MessageTemplateConverter {

  static convertIn(value: WsMessageTemplate): MessageTemplate {
    return {
      id: value.id,
      messageTypeName: value.messageTypeName,
      to: value.to,
      from: value.from,
      cc: value.cc,
      bcc: value.bcc,
      templateTexts: value.templateTexts == null ? [] : value.templateTexts.map(
        text => FlavouredMessageConverter.convertIn(text),
      ),
      subject: value.subject,
    };
  }

  static convertOut(value?: MessageTemplate): WsMessageTemplate {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      messageTypeName: value.messageTypeName,
      to: value.to,
      from: value.from,
      cc: value.cc,
      bcc: value.bcc,
      templateTexts: value.templateTexts == null ? [] : value.templateTexts.map(
        text => FlavouredMessageConverter.convertOut(text),
      ),
      subject: value.subject,
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsMessageTemplate]: keyof MessageTemplate } {
    return {
      id: 'id',
      messageTypeName: 'messageTypeName',
      to: 'to',
      from: 'from',
      cc: 'cc',
      bcc: 'bcc',
      templateTexts: 'templateTexts',
      subject: 'subject',
    };
  }

}
