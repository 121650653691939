import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OptionalBooleanRadioSelectService {

  private counter = 0;

  constructor() {

  }

  getNextGroupSuffix(): string {
    this.counter = this.counter + 1;
    return this.counter.toFixed(0);
  }

}
