import {WsInsurance, WsInsuranceSearch, WsInsuranceType} from '@lifeislife/lifeislife-ws-api';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {ProductConverter} from '../corebusiness/product-converter';
import {InsuranceType} from '../../domain/insurance/insurance-type';
import {Insurance} from '../../domain/insurance/insurance';
import {InsuranceSearch} from '../../domain/insurance/insurance-search';
import {InsuranceCategoryConverter} from './insurance-category-converter';
import {InsuranceProviderConverter} from './insurance-provider-converter';

export class InsuranceConverter {

  static convertIn(value: WsInsurance): Insurance {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      insuranceProviderRef: value.insuranceProviderWsRef,
      insuranceType: EnumConverterUtils.convertValue(value.wsInsuranceType, InsuranceType),
      productRef: value.productWsRef,
      insuranceCategoryRef: value.insuranceCategoryWsRef,
      insuranceCategoryName: value.insuranceCategoryName,
    };
  }

  static convertOut(value: Insurance): WsInsurance {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      insuranceProviderWsRef: value.insuranceProviderRef,
      wsInsuranceType: EnumConverterUtils.convertValue(value.insuranceType, WsInsuranceType),
      productWsRef: value.productRef,
      insuranceCategoryWsRef: value.insuranceCategoryRef,
      insuranceCategoryName: value.insuranceCategoryName,
    };
  }


  static convertFilterIn(value?: WsInsuranceSearch): InsuranceSearch {
    if (value == null) {
      return undefined;
    }
    return {
      insuranceType: EnumConverterUtils.convertValue(value.wsInsuranceType, InsuranceType),
      insuranceProviderSearch: InsuranceProviderConverter.convertFilterIn(value.insuranceProviderSearch),
      insuranceRef: value.insuranceWsRef,
      productSearch: ProductConverter.convertFilterIn(value.wsProductSearch),
      categorySearch: InsuranceCategoryConverter.convertFilterIn(value.wsInsuranceCategorySearch),
    };
  }

  static convertFilterOut(value: InsuranceSearch): WsInsuranceSearch {
    if (value == null) {
      return undefined;
    }
    return {
      wsInsuranceType: EnumConverterUtils.convertValue(value.insuranceType, WsInsuranceType),
      insuranceProviderSearch: InsuranceProviderConverter.convertFilterOut(value.insuranceProviderSearch),
      insuranceWsRef: value.insuranceRef,
      wsProductSearch: ProductConverter.convertFilterOut(value.productSearch),
      wsInsuranceCategorySearch: InsuranceCategoryConverter.convertFilterOut(value.categorySearch),
    };
  }


  static getValidationPropertyNameMappings(): { [M in keyof WsInsurance]: keyof Insurance } {
    return {
      id: 'id',
      insuranceProviderWsRef: 'insuranceProviderRef',
      productWsRef: 'productRef',
      wsInsuranceType: 'insuranceType',
      insuranceCategoryWsRef: 'insuranceCategoryRef',
      insuranceCategoryName: 'insuranceCategoryName',
    };
  }

}
