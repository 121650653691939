import {Injectable} from '@angular/core';
import {WsDaoEventEntityType, WsNotification, WsRef, WsStoredFile, WsTicket} from '@lifeislife/lifeislife-ws-api';
import {Observable} from 'rxjs';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {AppConfigService} from '../../../service/config/app-config.service';
import {CachedWsResourceClient} from '../../private_util/client/cached-http-ws-Resource-client';
import {RequestService} from '../../service/request.service';
import {ResourceCacheService} from '../../../util/cache/resource-cache.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationWsClient extends CachedWsResourceClient<WsNotification, WsNotification> {

  constructor(private injectedRequestService: RequestService,
              private injectectedCacheService: ResourceCacheService,
              private injectedAuthService: AuthProvider,
              private injectedAppConfigService: AppConfigService) {

    super('/front/notification', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.NOTIFICATION, injectectedCacheService);
  }

  acknowledgeNotification$(ref: WsRef<WsNotification>): Observable<any> {
    const url = `${this.getResourceUrl()}/${ref.id}/acknowledged`;
    return this.requestService.sendRequest({
      method: 'PUT', url: url,
    }, this.authService.getAuth());
  }

  getNotificationTicketRef$(ref: WsRef<WsNotification>): Observable<WsRef<WsTicket>> {
    const url = `${this.getResourceUrl()}/${ref.id}/ticket`;
    return this.requestService.sendRequest<WsRef<WsTicket>>({
      method: 'GET', url: url,
    }, this.authService.getAuth());
  }

  getNotificationFileRef$(ref: WsRef<WsNotification>): Observable<WsRef<WsStoredFile>> {
    const url = `${this.getResourceUrl()}/${ref.id}/file`;
    return this.requestService.sendRequest<WsRef<WsStoredFile>>({
      method: 'GET', url: url,
    }, this.authService.getAuth());
  }
}
