<div class="insurance-partner" [title]="name$ | async"
>

  <a class="link" *ngIf="asLink && (linkUrl$ | async); else content"
     [href]="linkUrl$ | async" target="_blank">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>

  <ng-template #content>
    <div class="logo" *ngIf="showLogo && (logoUrl$ | async)">
      <img [src]="logoUrl$ | async">
    </div>

    <div class="title" *ngIf="showTitle && (name$  | async)">
      {{ name$ | async }}
    </div>
  </ng-template>
</div>
