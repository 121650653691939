/**
 * Created by cghislai on 03/08/16.
 */

import {Pipe, PipeTransform} from '@angular/core';
import {DateUtils} from '@lifeislife/lifeislife-domain';

@Pipe({name: 'cDate'})
export class DatePipe implements PipeTransform {

  transform(value: Date | string, ...args: any[]) {
    if (value == null) {
      return '';
    }
    return DateUtils.formatDateToHumanFormat(value);
  }
}
