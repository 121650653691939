import {CustomerSourcingWsClient} from '../../client/resources/front/customer/customer-sourcing-ws-client';
import {RetirementSimulatorPotentialCustomerConverter} from './retirement-simulator-potential-customer.converter';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RetirementSimulatorPotentialCustomer} from '../../domain/customer/retirement-simulator-potential-customer';
import {CustomerFrontCustomerSourcingConverter} from './customer-front-customer-sourcing.converter';
import {CustomerFrontCustomerSourcing} from '../../domain/customer/customer-front-customer-sourcing';


@Injectable({
  providedIn: 'root',
})
export class CustomerSourcingService {

  constructor(
    private client: CustomerSourcingWsClient,
  ) {
  }

  notifyPotentialCustomerFromRetirementSimulator$(potentialCustomer: RetirementSimulatorPotentialCustomer): Observable<any> {
    const wsPotentialCustomer = RetirementSimulatorPotentialCustomerConverter.convertOut(potentialCustomer);
    return this.client.notifyPotentialCustomerFromRetirementSimulator$(wsPotentialCustomer);
  }

  /**
   * @deprecated use a ticketinsurancerequest flow
   * @param souring
   */
  notifyPotentialCustomerFromCustomerFront$(souring: CustomerFrontCustomerSourcing): Observable<any> {
    const wsCustomerFrontCustomerSourcing = CustomerFrontCustomerSourcingConverter.convertOut(souring);
    return this.client.notifyCustomerFrontSourcing$(wsCustomerFrontCustomerSourcing);
  }
}
