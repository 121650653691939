import {WsLifeisLifeLogEntry} from '@lifeislife/lifeislife-ws-api';
import {LifeislifeLogEntry} from '../../domain/log/lifeislife-log-entry';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';

export class LifeisLifeLogEntryConverter {


  static convertIn(value: WsLifeisLifeLogEntry): LifeislifeLogEntry {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      version: value.version,

      content: value.content,
      dateTime: DateConverterUtils.parseIsoDateTime(value.dateTime),
      lifeislifeLogRef: value.lifeislifeLogRef,
      updaterContactRef: value.updaterContactRef,
    };
  }

  static convertOut(value: LifeislifeLogEntry): WsLifeisLifeLogEntry {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      version: value.version,

      content: value.content,
      dateTime: DateConverterUtils.formatDateToIsoDateTime(value.dateTime),
      lifeislifeLogRef: value.lifeislifeLogRef,
      updaterContactRef: value.updaterContactRef,

    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsLifeisLifeLogEntry]: keyof LifeislifeLogEntry } {
    return {
      id: 'id',
      version: 'version',
      content: 'content',
      dateTime: 'dateTime',
      lifeislifeLogRef: 'lifeislifeLogRef',
      updaterContactRef: 'updaterContactRef',
    };
  }

}
