import {Injectable} from '@angular/core';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {WsCustomer, WsCustomerGroupSummary, WsCustomerSearch, WsDaoEventEntityType, WsRef} from '@lifeislife/lifeislife-ws-api';
import {RequestService} from '../../../service/request.service';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {Observable} from 'rxjs';
import {EXCEL_MIME_TYPE, ZIP_MIME_TYPE} from '../../../../util/file/file-utils';

@Injectable({
  providedIn: 'root',
})
export class CustomerWsClient extends CachedWsResourceClient<WsCustomer, WsRef<WsCustomer>, WsCustomerGroupSummary> {


  constructor(
    private injectedRequestService: RequestService,
    private injectedAuthService: AuthProvider,
    private injectectedCacheService: ResourceCacheService,
    private injectedAppConfigService: AppConfigService,
  ) {
    super('/front/customer', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.CUSTOMER, injectectedCacheService);
  }


  searchSubscriptionSnapshotReport$(customerId: number): Observable<Blob> {
    const url = this.getResourceUrl() + `/${customerId}/report/subscriptionSnapshot`;
    return this.requestService.downloadBlob({
      method: 'POST', url: url,
    }, this.authService.getAuth(), EXCEL_MIME_TYPE);
  }

  searchSubscriptionSnapshotReports$(filter: WsCustomerSearch): Observable<Blob> {
    const url = this.getResourceUrl() + `/report/subscriptionSnapshots`;
    return this.requestService.downloadBlob({
      method: 'POST', url: url,
      body: filter,
    }, this.authService.getAuth(), ZIP_MIME_TYPE);
  }

  searchMailingContactsReport$(filter: WsCustomerSearch): Observable<Blob> {
    const url = this.getResourceUrl() + `/report/mailingContacts`;
    return this.requestService.downloadBlob({
      method: 'POST', url: url,
      body: filter,
    }, this.authService.getAuth(), EXCEL_MIME_TYPE);
  }

}
