import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Contact, ContactService, Customer, Ref, Role, Trustee} from '@lifeislife/lifeislife-domain';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {debounceTime, map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {AppShellContextProviderService} from '../../../../shell/app-shell-context-provider.service';
import {ApplicationNavigationService} from '../../../../application-navigation.service';

@Component({
  selector: 'app-contact-view',
  templateUrl: './contact-view.component.html',
  styleUrls: ['./contact-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactViewComponent implements OnInit {

  @Input()
  set ref(value: Ref<Contact>) {
    this.refSource$.next(value);
  }

  @Input()
  hideContactName = false;
  @Input()
  showCompanyName = false;
  @Input()
  showIdExpiryDate = false;
  @Input()
  showJumpLink = true;
  @Input()
  jumpLinkTrusteeRef: Ref<Trustee>;
  @Input()
  jumpLinkCustomerRef: Ref<Customer>;
  @Input()
  wrap: boolean;
  @Input()
  wrapText = true;

  private refSource$ = new BehaviorSubject<Ref<Contact>>(null);

  firstName$: Observable<string>;
  lastName$: Observable<string>;
  companyName$: Observable<string>;
  idExpiryData$: Observable<Date>;
  jumpLinkVisible$: Observable<boolean>;
  jumpLink$: Observable<any[]>;

  constructor(private contactService: ContactService,
              private appContextservice: AppShellContextProviderService,
              private navigationService: ApplicationNavigationService,
  ) {
  }

  ngOnInit() {
    const contact$ = this.refSource$.pipe(
      switchMap(ref => ref == null ? of(null) : this.contactService.getContact$(ref)),
      publishReplay(1), refCount(),
    );
    this.firstName$ = contact$.pipe(
      map(c => c == null ? '' : c.firstName),
      publishReplay(1), refCount(),
    );
    this.lastName$ = contact$.pipe(
      map(c => c == null ? '' : c.lastName),
      publishReplay(1), refCount(),
    );
    this.companyName$ = contact$.pipe(
      map(c => c == null ? '' : c.company),
      publishReplay(1), refCount(),
    );
    this.idExpiryData$ = contact$.pipe(
      map(c => c == null ? null : c.idExpiryDate),
      publishReplay(1), refCount(),
    );


    this.jumpLinkVisible$ = this.appContextservice.getActiveContext$().pipe(
      map(c => c != null && c.activeRole != null && c.activeRole !== Role.CUSTOMER && this.showJumpLink),
      publishReplay(1), refCount(),
    );
    this.jumpLink$ = combineLatest([this.refSource$]).pipe(
      debounceTime(100),
      map(r => this.navigationService.createContactDetailsRouterLink(r[0], this.jumpLinkTrusteeRef, this.jumpLinkCustomerRef)),
      publishReplay(1), refCount(),
    );

  }

  onClick(event: MouseEvent) {
    event.stopImmediatePropagation();
  }
}
