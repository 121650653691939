import {WsTicketCommentSearch, WsTicketContactSearch, WsTicketSearch, WsTicketStatus, WsTicketType} from '@lifeislife/lifeislife-ws-api';
import {TicketSearch} from '../../domain/ticket/ticket-search';
import {TicketStatus} from '../../domain/ticket/ticket-status';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {TicketType} from '../../domain/ticket/ticket-type';
import {DateTimeSearchConverter} from '../shared/date-time-search-converter';
import {TicketContactSearch} from '../../domain/ticket/ticket-contact-search';
import {TicketCommentSearch} from '../../domain/ticket/ticket-comment-search';
import {CustomerSearchConverter} from '../customer/customer-search.converter';
import {TrusteeSearchConverter} from '../trustee/trustee-search.converter';
import {ContactSearchConverter} from '../contact/contact-search.converter';

export class TicketSearchConverter {

  static convertFilterIn(value?: WsTicketSearch): TicketSearch {
    if (value == null) {
      return undefined;
    }
    return {
      exactTicketRef: value.exactTicketRef,
      ticketContactSearch: this.convertContactFilterIn(value.authorContactSearch),
      creationTimeSearch: DateTimeSearchConverter.convertIn(value.creationTimeSearch),
      customerSearch: CustomerSearchConverter.toCustomerSearch(value.wsCustomerSearch),
      commentSearch: this.convertCommentFilterIn(value.wsTicketCommentSearch),
      discussionContains: value.discussionContains,
      subjectContains: value.subjectContains,
      ticketTypeList: value.ticketTypeList == null ? undefined : value.ticketTypeList
        .map(wsType => EnumConverterUtils.convertValue(wsType, TicketType)),
      ticketStatusList: value.ticketStatusList == null ? undefined : value.ticketStatusList
        .map(wsStatus => EnumConverterUtils.convertValue(wsStatus, TicketStatus)),
      trusteeSearch: TrusteeSearchConverter.toTrusteeSearch(value.wsTrusteeSearch),
    };
  }

  static convertFilterOut(value: TicketSearch): WsTicketSearch {
    if (value == null) {
      return undefined;
    }
    return {
      exactTicketRef: value.exactTicketRef,
      authorContactSearch: this.convertContactFilterOut(value.ticketContactSearch),
      creationTimeSearch: DateTimeSearchConverter.convertOut(value.creationTimeSearch),
      wsCustomerSearch: CustomerSearchConverter.toWsCustomerSearch(value.customerSearch),
      wsTicketCommentSearch: this.convertCommentFilterOut(value.commentSearch),
      discussionContains: value.discussionContains,
      subjectContains: value.subjectContains,
      ticketTypeList: value.ticketTypeList == null ? undefined : value.ticketTypeList
        .map(ticketType => EnumConverterUtils.convertValue(ticketType, WsTicketType)),
      ticketStatusList: value.ticketStatusList == null ? undefined : value.ticketStatusList
        .map(status => EnumConverterUtils.convertValue(status, WsTicketStatus)),
      wsTrusteeSearch: TrusteeSearchConverter.toWsTrusteeSearch(value.trusteeSearch),
    };
  }


  static convertContactFilterIn(value?: WsTicketContactSearch): TicketContactSearch {
    if (value == null) {
      return undefined;
    }
    return {
      contactSearch: ContactSearchConverter.toContactSearch(value.wsContactSearch),
      ticketSearch: undefined,
      withChangesSinceLastAcknowledged: value.withChangesSinceLastAcknowledged,
      ticketArchived: value.ticketArchived,
      ticketSilenced: value.ticketSilenced,
      ticketStarred: value.ticketStarred,
    };
  }

  static convertContactFilterOut(value: TicketContactSearch): WsTicketContactSearch {
    if (value == null) {
      return undefined;
    }
    return {
      wsContactSearch: ContactSearchConverter.toWsContactSearch(value.contactSearch),
      wsTicketSearch: undefined,
      withChangesSinceLastAcknowledged: value.withChangesSinceLastAcknowledged,
      ticketArchived: value.ticketArchived,
      ticketSilenced: value.ticketSilenced,
      ticketStarred: value.ticketStarred,
    };
  }


  static convertCommentFilterIn(value?: WsTicketCommentSearch): TicketCommentSearch {
    if (value == null) {
      return undefined;
    }
    return {
      authorTicketContactSearch: this.convertContactFilterIn(value.authorTicketContactSearch),
      dateTimeSearch: DateTimeSearchConverter.convertIn(value.dateTimeSearch),
      ticketSearch: undefined,
    };
  }

  static convertCommentFilterOut(value: TicketCommentSearch): WsTicketCommentSearch {
    if (value == null) {
      return undefined;
    }
    return {
      authorTicketContactSearch: this.convertContactFilterOut(value.authorTicketContactSearch),
      dateTimeSearch: DateTimeSearchConverter.convertOut(value.dateTimeSearch),
      ticketSearch: undefined,
    };
  }
}
