import {WsInsuranceBranch, WsInsuranceSubscription, WsInsuranceSubscriptionStatus} from '@lifeislife/lifeislife-ws-api';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {InsuranceSubscription} from '../../domain/insurance/insurance-subscription';
import {InsuranceSubscriptionStatus} from '../../domain/insurance/insurance-subscription-status';
import {InsuranceBranch} from '../../domain/insurance/insurance-branch';
import {DateConverterUtils} from '../../client/private_util/date-converter-utils';

export class InsuranceSubscriptionConverter {

  static convertIn(value: WsInsuranceSubscription): InsuranceSubscription {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      version: value.version,
      subscriptionRef: value.subscriptionWsRef,
      insuranceRef: value.insuranceWsRef,
      insuranceSubscriptionStatus: EnumConverterUtils.convertValue(value.wsInsuranceSubscriptionStatus, InsuranceSubscriptionStatus),
      insuredInfo: value.insuredInfo,
      iardGuarantee: value.iardGuarantee,
      iardMainDeadlineDate: DateConverterUtils.parseIsoDate(value.iardMainDeadlineDate),
      franchise: value.franchise,
      fundDescription1: value.fundDescription1,
      fundDescription2: value.fundDescription2,
      fundDescription3: value.fundDescription3,
      fundDescription4: value.fundDescription4,
      maxDeductibleAmount: value.maxDeductibleAmount,
      possibleBackserviceAmount: value.possibleBackserviceAmount,
      backserviceTakenYear: value.backserviceTakenYear,
      incomesGuaranteeCoveredAmount: value.incomesGuaranteeCoveredAmount,
      deathInsuranceCoveredAmount: value.deathInsuranceCoveredAmount,
      realEstateAdvanceTaken: value.realEstateAdvanceTaken,
      takenAdvanceAmount: value.takenAdvanceAmount,
      takenAdvanceYear: value.takenAdvanceYear,

      insuredAmount: value.insuredAmount,
      referenceIncomeUsed: value.referenceIncomeUsed,
      insuredCustomerContactRef: value.insuredCustomerContactRef,

      amountsBs: value.amountsBs,
      amountsTaxExclusiveBs: value.amountsTaxExclusiveBs,
      amountsPayrollBsBase: value.amountsPayrollBsBase,
      amountsTax: value.amountsTax,
      amountsCompanyFees: value.amountsCompanyFees,
      amountsTaxExclusivePremiumWithoutEntryFees: value.amountsTaxExclusivePremiumWithoutEntryFees,
      amountsCommissionRate: value.amountsCommissionRate,
      amountsYearlyCommission: value.amountsyearlyCommission,
      amountsManagementPayrollBase: value.amountsManagementPayrollBase,
      amountsYearlyReminderDecade: value.amountsYearlyReminderDecade,
      amountsReminderTotal: value.amountsReminderTotal,
      insuranceBranch: EnumConverterUtils.convertValue(value.wsInsuranceBranch, InsuranceBranch),

      vestedReserveAmountUpdateDate: DateConverterUtils.parseIsoDate(value.vestedReserveAmountUpdateDate),
      vestedReserveAmount: value.vestedReserveAmount,
      estimatedRetirementAmount: value.estimatedRetirementAmount,
      estimatedRetirementIncomeOver20years: value.estimatedRetirementIncomeOver20years,

      agProductType: value.agProductType,
      premiumEndDate: DateConverterUtils.parseIsoDate(value.premiumEndDate),
      mainPremiumAmount: value.mainPremiumAmount,
      annualRecurringPremiumBaseWithoutTaxes: value.annualRecurringPremiumBaseWithoutTaxes,
      risksPremium: value.risksPremium,
      uniquePremiumsTotal: value.uniquePremiumsTotal,
      uniquePremiumsTotalTaxesExclusive: value.uniquePremiumsTotalTaxesExclusive,
      currentYearProductionWithoutTaxes: value.currentYearProductionWithoutTaxes,
      initialDeathCoverAmount: value.initialDeathCoverAmount,
      deathCoverAmount: value.deathCoverAmount,
      agDsmlNames: value.agDsmlNames,
      agSmlNames: value.agSmlNames,
      investmentComponent: value.investmentComponent,
      amountsCommissionsStartDate: DateConverterUtils.parseIsoDate(value.amountsCommissionsStartDate),
    };
  }

  static convertOut(value: InsuranceSubscription): WsInsuranceSubscription {
    if (value == null) {
      return undefined;
    }
    return {
      id: value.id,
      version: value.version,
      subscriptionWsRef: value.subscriptionRef,
      insuranceWsRef: value.insuranceRef,
      wsInsuranceSubscriptionStatus: EnumConverterUtils.convertValue(value.insuranceSubscriptionStatus, WsInsuranceSubscriptionStatus),
      insuredInfo: value.insuredInfo,
      iardGuarantee: value.iardGuarantee,
      iardMainDeadlineDate: DateConverterUtils.formatDateToIsoDate(value.iardMainDeadlineDate),
      franchise: value.franchise,
      fundDescription1: value.fundDescription1,
      fundDescription2: value.fundDescription2,
      fundDescription3: value.fundDescription3,
      fundDescription4: value.fundDescription4,
      maxDeductibleAmount: value.maxDeductibleAmount,
      possibleBackserviceAmount: value.possibleBackserviceAmount,
      backserviceTakenYear: value.backserviceTakenYear,
      incomesGuaranteeCoveredAmount: value.incomesGuaranteeCoveredAmount,
      deathInsuranceCoveredAmount: value.deathInsuranceCoveredAmount,


      insuredAmount: value.insuredAmount,
      referenceIncomeUsed: value.referenceIncomeUsed,
      insuredCustomerContactRef: value.insuredCustomerContactRef,


      realEstateAdvanceTaken: value.realEstateAdvanceTaken,
      takenAdvanceAmount: value.takenAdvanceAmount,
      takenAdvanceYear: value.takenAdvanceYear,
      amountsBs: value.amountsBs,
      amountsTaxExclusiveBs: value.amountsTaxExclusiveBs,
      amountsPayrollBsBase: value.amountsPayrollBsBase,
      amountsTax: value.amountsTax,
      amountsCompanyFees: value.amountsCompanyFees,
      amountsTaxExclusivePremiumWithoutEntryFees: value.amountsTaxExclusivePremiumWithoutEntryFees,
      amountsCommissionRate: value.amountsCommissionRate,
      amountsyearlyCommission: value.amountsYearlyCommission,
      amountsManagementPayrollBase: value.amountsManagementPayrollBase,
      amountsYearlyReminderDecade: value.amountsYearlyReminderDecade,
      amountsReminderTotal: value.amountsReminderTotal,

      wsInsuranceBranch: EnumConverterUtils.convertValue(value.insuranceBranch, WsInsuranceBranch),

      vestedReserveAmountUpdateDate: DateConverterUtils.formatDateToIsoDate(value.vestedReserveAmountUpdateDate),
      vestedReserveAmount: value.vestedReserveAmount,
      estimatedRetirementAmount: value.estimatedRetirementAmount,
      estimatedRetirementIncomeOver20years: value.estimatedRetirementIncomeOver20years,

      agProductType: value.agProductType,
      premiumEndDate: DateConverterUtils.formatDateToIsoDate(value.premiumEndDate),
      mainPremiumAmount: value.mainPremiumAmount,
      annualRecurringPremiumBaseWithoutTaxes: value.annualRecurringPremiumBaseWithoutTaxes,
      risksPremium: value.risksPremium,
      uniquePremiumsTotal: value.uniquePremiumsTotal,
      uniquePremiumsTotalTaxesExclusive: value.uniquePremiumsTotalTaxesExclusive,
      currentYearProductionWithoutTaxes: value.currentYearProductionWithoutTaxes,
      initialDeathCoverAmount: value.initialDeathCoverAmount,
      deathCoverAmount: value.deathCoverAmount,
      agDsmlNames: value.agDsmlNames,
      agSmlNames: value.agSmlNames,
      investmentComponent: value.investmentComponent,
      amountsCommissionsStartDate: DateConverterUtils.formatDateToIsoDate(value.amountsCommissionsStartDate),
    };
  }

  static getValidationPropertyNameMappings(): { [M in keyof WsInsuranceSubscription]: keyof InsuranceSubscription } {
    return {
      id: 'id',
      version: 'version',
      wsInsuranceSubscriptionStatus: 'insuranceSubscriptionStatus',
      insuranceWsRef: 'insuranceRef',
      subscriptionWsRef: 'subscriptionRef',
      insuredInfo: 'insuredInfo',
      iardGuarantee: 'iardGuarantee',
      franchise: 'franchise',
      fundDescription1: 'fundDescription1',
      fundDescription2: 'fundDescription2',
      fundDescription3: 'fundDescription3',
      fundDescription4: 'fundDescription4',
      maxDeductibleAmount: 'maxDeductibleAmount',
      possibleBackserviceAmount: 'possibleBackserviceAmount',
      backserviceTakenYear: 'backserviceTakenYear',
      incomesGuaranteeCoveredAmount: 'incomesGuaranteeCoveredAmount',
      deathInsuranceCoveredAmount: 'deathInsuranceCoveredAmount',
      realEstateAdvanceTaken: 'realEstateAdvanceTaken',
      takenAdvanceAmount: 'takenAdvanceAmount',
      takenAdvanceYear: 'takenAdvanceYear',
      amountsBs: 'amountsBs',
      amountsTaxExclusiveBs: 'amountsTaxExclusiveBs',
      amountsPayrollBsBase: 'amountsPayrollBsBase',
      amountsTax: 'amountsTax',
      amountsCompanyFees: 'amountsCompanyFees',
      amountsTaxExclusivePremiumWithoutEntryFees: 'amountsTaxExclusivePremiumWithoutEntryFees',
      amountsCommissionRate: 'amountsCommissionRate',
      amountsyearlyCommission: 'amountsYearlyCommission',
      amountsManagementPayrollBase: 'amountsManagementPayrollBase',
      amountsYearlyReminderDecade: 'amountsYearlyReminderDecade',
      amountsReminderTotal: 'amountsReminderTotal',

      wsInsuranceBranch: 'insuranceBranch',
      insuredCustomerContactRef: 'insuredCustomerContactRef',

      insuredAmount: 'insuredAmount',
      referenceIncomeUsed: 'referenceIncomeUsed',

      vestedReserveAmountUpdateDate: 'vestedReserveAmountUpdateDate',
      vestedReserveAmount: 'vestedReserveAmount',
      estimatedRetirementAmount: 'estimatedRetirementAmount',
      estimatedRetirementIncomeOver20years: 'estimatedRetirementIncomeOver20years',

      agProductType: 'agProductType',
      premiumEndDate: 'premiumEndDate',
      mainPremiumAmount: 'mainPremiumAmount',
      annualRecurringPremiumBaseWithoutTaxes: 'annualRecurringPremiumBaseWithoutTaxes',
      risksPremium: 'risksPremium',
      uniquePremiumsTotal: 'uniquePremiumsTotal',
      uniquePremiumsTotalTaxesExclusive: 'uniquePremiumsTotalTaxesExclusive',
      currentYearProductionWithoutTaxes: 'currentYearProductionWithoutTaxes',
      initialDeathCoverAmount: 'initialDeathCoverAmount',
      deathCoverAmount: 'deathCoverAmount',
      agDsmlNames: 'agDsmlNames',
      agSmlNames: 'agSmlNames',
      investmentComponent: 'investmentComponent',
      amountsCommissionsStartDate: 'amountsCommissionsStartDate',
      iardMainDeadlineDate: 'iardMainDeadlineDate',
    };
  }

}
