<div class="row" #itemContainer>
  <form *ngIf="renaming" class="list-item-form file-rename-form"
        (submit)="onFileRenameConfirm()"
        (click)="discardClick($event)">
    <div class="icon">
      <i [ngClass]="fileIcon$ | async"></i>
    </div>

    <div class="name">
      <input type="text" pInputText
             name="newFileName"
             [disabled]="disabled"
             [(ngModel)]="newFileName"
             #renameInputCompnent
      >
    </div>
    <div class="action">
      <input type="submit" hidden
             [disabled]="disabled"
      >
      <a class="confirm"
         [class.disabled]="disabled"
         (click)="onFileRenameConfirm($event)"
         title="Confirmer" i18n-title
      >
        <i class="fa fa-check"></i>
      </a>

      <a class="cancel"
         [class.disabled]="disabled"
         (click)="onFileRenameCancel($event)"
         title="Annuler" i18n-title
      >
        <i class="fa fa-times"></i>
      </a>
    </div>
  </form>

  <div class="list-item" *ngIf="!renaming">

    <div class="icon">
      <i [ngClass]="fileIcon$ | async"></i>
    </div>


    <div class="name" [title]=" (file$ | async)?.fileName">
      {{ (file$ | async)?.fileName }}
    </div>

    <div class="badges">
      <span class="signed lifeislife-signature-badge signature-status-colored">
        <i class="fa fa-certificate"
           *ngIf="showBadgeSigned && (fileSigningPackage$ | async) != null"
           title="Signature"
           (click)="op.toggle($event); $event.stopImmediatePropagation()"
           [class.mismatch]="(fileExpectedSignerMatches$ | async) === false"
           [class.match]="(fileExpectedSignerMatches$ | async) === true"
           [class.toggled]="op.overlayVisible"
        ></i>
      </span>
      <i class="fas fa-eye customer-visibility" [class.visible]="visibleToCustomer"
         *ngIf="showVisibleToCustomer"
         [title]="visibleToCustomer ? 'Consultable par le client' : 'Non consultable par le client'"
         (click)="onVisibleToCustomerClick($event)">
      </i>
      <p-overlayPanel #op [appendTo]="overlayAppendTo || 'body'" [dismissable]="true">
        <ng-template pTemplate>
          <div class="signed-document-panel">
            <div class="expected-signer" *ngIf="(fileExpectedSignerName$ | async)">
              À signer par {{ fileExpectedSignerName$ | async }}
            </div>
            <div class="actual-signer" *ngIf="(fileSignerName$ | async); else noSigner">
              Signature trouvée pour {{ fileSignerName$ | async }}
            </div>
            <ng-template #noSigner>
              <div class="no-signer">
                Aucune signature de contact connu trouvée dans le pdf
              </div>
            </ng-template>
            <div *ngIf="(fileExpectedSignerMatches$ | async) === false" class="invalid-signer">
              Les signatures ne correspondent pas, vérifiez le document
            </div>
            <div class="actions-toolbar">
              <a (click)="onCheckSignatureClick($event)"
                 [class.disabled]="checkingSigner$ | async">
                Revérifier la signature du document
              </a>
            </div>
          </div>
        </ng-template>
      </p-overlayPanel>
    </div>

    <div class="size" *ngIf="showSize">
      {{ (file$ | async)?.size |  fileSize }}
    </div>

    <div class="creation" *ngIf="showCreated">
      {{ (file$ | async)?.creationDateTime | cDateTime }}
    </div>

    <div class="update" *ngIf="showUpdate">
      {{ (file$ | async)?.updateDateTime | cDateTime }}
    </div>

    <div class="action">
      <a class="rename" *ngIf="showRename"
         [class.disabled]="disabled"
         (click)="onRenameClick($event)"
         title="Renommer" i18n-title
      >
        <i class="fa fa-edit"></i>
      </a>

      <a class="delete" *ngIf="showDelete"
         [class.disabled]="disabled"
         (click)="onDeleteClick($event)"
         title="Supprimer" i18n-title
      >
        <i class="fa fa-times"></i>
      </a>
    </div>
  </div>
</div>
