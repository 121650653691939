import {WsUserToken} from '@lifeislife/lifeislife-ws-api';
import {LifeisLifeWsAuth, SupportedAuthType} from './lifeis-life-ws-auth';

export class WsUserTokenAuth implements LifeisLifeWsAuth {
  token: string;
  refreshToken?: string;
  wsUserToken: WsUserToken | null;

  authorizationHeader: string;
  authorizationQueryString: string;
  type: SupportedAuthType;

  static fromUserToken(authToken: WsUserToken): WsUserTokenAuth {
    return {
      wsUserToken: authToken,
      token: authToken.token,
      authorizationHeader: WsUserTokenAuth.provideAuthorizationHeader(authToken.token),
      authorizationQueryString: WsUserTokenAuth.provideAuthorizationQueryString(authToken.token),
      type: 'user-token',
    };
  }

  static fromOldToken(oldToken: OldOAuth): WsUserTokenAuth {
    return {
      wsUserToken: null,
      token: oldToken.token,
      authorizationHeader: WsUserTokenAuth.provideAuthorizationHeader(oldToken.token),
      authorizationQueryString: WsUserTokenAuth.provideAuthorizationQueryString(oldToken.token),
      type: 'oauth',
    };
  }

  static forOidcToken(token: string, refreshToken?: string): WsUserTokenAuth {
    return {
      wsUserToken: null,
      token: token,
      refreshToken: refreshToken,
      authorizationHeader: WsUserTokenAuth.provideAuthorizationHeader(token),
      authorizationQueryString: null,
      type: 'oidc-token',
    };
  }

  private static provideAuthorizationHeader(token: string): string {
    if (token == null) {
      throw new Error('No auth token');
    }
    let header = 'Bearer ';
    const b64token = btoa(token);
    header += b64token;
    return header;
  }

  private static provideAuthorizationQueryString(token: string): string {
    const queryString = `oauth2_token=${btoa(token)}`;
    return queryString;
  }

}

export interface OldOAuth {
  token: string;
  refreshToken?: string;
}
