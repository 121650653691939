import {Injectable} from '@angular/core';
import {WsConstraintViolation, WsLifeisLifeEventRecord, WsLifeisLifeEventRecordSearch, WsRef} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {WithId} from '../../client/domain/with-id';
import {EventRecordWsClient} from '../../client/resources/front/event/event-record-ws-client';
import {EventRecordConverter} from './event-record-converter';
import {EventRecord} from '../../domain/event/event-record';
import {EventRecordSearch} from '../../domain/event/event-record-search';

@Injectable({
  providedIn: 'root',
})
export class EventRecordService {


  constructor(private client: EventRecordWsClient) {
  }

  getEventRecord$(ref: Ref<EventRecord>, forceFetch?: boolean): Observable<EventRecord> {
    return ref == null ? of(null) : this.client.doGet(ref.id, forceFetch).pipe(
      map(wsEventRecord => EventRecordConverter.convertIn(wsEventRecord)),
    );
  }

  searchEventRecords$(insuranceSearch: EventRecordSearch, pagination: SimplePagination): Observable<SearchResult<EventRecord>> {
    const wsSearch = EventRecordConverter.convertFilterOut(insuranceSearch);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateEventRecord$(insurance: EventRecord): Observable<ValidationResult<EventRecord>> {
    const wsEventRecord = EventRecordConverter.convertOut(insurance);
    return this.client.doValidate(wsEventRecord).pipe(
      map((results: WsLifeisLifeEventRecord | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveEventRecord$(insurance: EventRecord): Observable<WithId> {
    const wsEventRecord = EventRecordConverter.convertOut(insurance);
    return this.client.doSave(wsEventRecord);
  }

  deleteEventRecord$(ref: Ref<EventRecord>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  serializeFilter(InsuranceSearch: EventRecordSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(InsuranceSearch);
    const wsEventRecordSearch = EventRecordConverter.convertFilterOut(InsuranceSearch);
    return JSON.stringify(wsEventRecordSearch);
  }

  deserializeFilter(valueString: string | null): EventRecordSearch {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: WsLifeisLifeEventRecordSearch = JSON.parse(valueString);
      const filter = EventRecordConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsLifeisLifeEventRecord | WsConstraintViolation[]): ValidationResult<EventRecord> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsEventRecord => EventRecordConverter.convertIn(wsEventRecord),
      propertyNameMappings: EventRecordConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsLifeisLifeEventRecord>>): Observable<SearchResult<EventRecord>> {
    const rowTasks = results.list.map(ref => this.getEventRecord$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<EventRecord>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<EventRecord>>>{
          list: rows,
        }),
      ));
  }
}
