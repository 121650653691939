import {Injectable} from '@angular/core';
import {CustomerContactService, CustomerContactType, CustomerContactTypeModel} from '@lifeislife/lifeislife-domain';
import {SelectItem} from 'primeng/api';
import {EnumUtils} from '../../util/enum-utils';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerContactTypeItemsService {

  constructor(
    private customerContactService: CustomerContactService,
  ) {
  }

  public createAllCustomerContactTypeItems$(): Observable<SelectItem[]> {
    return this.customerContactService.getAllContactTypesModels$().pipe(
      map(list => list.map(m => this.createCustomerContactTypeItem(m))),
    );
  }

  public createCustomerContactTypeItem(model: CustomerContactTypeModel): SelectItem {
    return {
      value: model.contactType,
      label: model.label,
    };
  }

}
