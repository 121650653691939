import {Injectable} from '@angular/core';
import {RequestService} from '../../../service/request.service';
import {CachedWsResourceClient} from '../../../private_util/client/cached-http-ws-Resource-client';
import {AuthProvider} from '../../../domain/auth/auth-provider';
import {AppConfigService} from '../../../../service/config/app-config.service';
import {WsDaoEventEntityType, WsProductProvider, WsRef, WsStoredFile} from '@lifeislife/lifeislife-ws-api';
import {ResourceCacheService} from '../../../../util/cache/resource-cache.service';
import {Observable} from 'rxjs';
import {HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ProductProviderWsClient extends CachedWsResourceClient<WsProductProvider, WsRef<WsProductProvider>> {

  constructor(private injectedRequestService: RequestService,
              private injectedAuthService: AuthProvider,
              private injectectedCacheService: ResourceCacheService,
              private injectedAppConfigService: AppConfigService) {
    super('/front/productProvider', injectedAppConfigService, injectedRequestService, injectedAuthService,
      WsDaoEventEntityType.PRODUCT_PROVIDER, injectectedCacheService);
  }

  getLogoFile(subscriptionFileId: number): Observable<WsStoredFile> {
    const url = this.getLogoFileResourceUrl(subscriptionFileId);
    return this.requestService.sendRequest<WsStoredFile>({
      method: 'GET', url: url,
    }, this.authService.getAuth());
  }


  getLogoFileThumbnailUrl$(subscriptionFileId: number): Observable<string> {
    const url = `${this.getLogoFileResourceUrl(subscriptionFileId)}/thumbnailUrl`;
    const headers = new HttpHeaders()
      .append('Accept', 'text/plain');

    return this.requestService.sendRequest<string>({
      method: 'GET', url: url, headers: headers, responseType: 'text',
    }, this.authService.getAuth());
  }

  getLogoFileDownloadUrl$(subscriptionFileId: number, contentDisposition?: 'inline' | 'attachment'): Observable<string> {
    const url = `${this.getLogoFileResourceUrl(subscriptionFileId)}/contentUrl`;
    const headers = new HttpHeaders()
      .append('Accept', 'text/plain');
    let queryParams = new HttpParams();
    if (contentDisposition != null) {
      queryParams = queryParams.append('content-disposition', contentDisposition);
    }

    return this.requestService.sendRequest<string>({
      method: 'GET', url: url, headers: headers, responseType: 'text',
      params: queryParams,
    }, this.authService.getAuth());
  }

  getNewLogoFilePath(ref: WsRef<WsProductProvider>): string {
    return `${this.resourcePath}/${ref.id}/logo`;
  }


  private getLogoFileResourceUrl(providerId: number) {
    return `${this.getResourceUrl()}/${providerId}/logo/file`;
  }

}
