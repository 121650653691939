/**
 * Created by cghislai on 03/08/16.
 */

import {Pipe, PipeTransform} from '@angular/core';
import {DateUtils} from '@lifeislife/lifeislife-domain';

@Pipe({name: 'cDateTime'})
export class DateTimePipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    const withSeconds = args.indexOf('withSeconds') >= 0;
    return DateUtils.formatDateTimeToHumanFormat(value, withSeconds);
  }
}
