import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SharedModule} from 'primeng/api';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TreeTableModule} from 'primeng/treetable';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {ProgressBarModule} from 'primeng/progressbar';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {MessagesModule} from 'primeng/messages';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {DialogModule} from 'primeng/dialog';
import {CalendarModule} from 'primeng/calendar';
import {ButtonModule} from 'primeng/button';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {SlideMenuModule} from 'primeng/slidemenu';
import {TabMenuModule} from 'primeng/tabmenu';
import {RadioGroupComponent} from './components/radio/radioGroup/radioGroup';
import {PhoneNumberInputComponent} from './components/phoneNumber/phoneNumberInput/phoneNumberInput';
import {OptionalBooleanRadioSelectComponent} from './components/radio/optional-boolean-radio-select/optional-boolean-radio-select.component';
import {GoogleMapsComponent} from './components/map/google-maps/google-maps.component';
import {ValidationErrorMessageComponent} from './components/validation/errorMessage/errorMessageComponent';
import {SimplePhoneNumberInputComponent} from './components/phoneNumber/simple-phone-number-input/simple-phone-number-input.component';
import {TableModule} from 'primeng/table';
import {MainCountryModule} from './components/country/main-country.module';
import {CommonPipesModule} from '../commons/pipes/common-pipes.module';
import {CommonDirectivesModule} from '../commons/directives/common-directives.module';
import {StoredFileModule} from '@lifeislife/lifeislife-components';

const DECLARATIONS = [
  GoogleMapsComponent,
  OptionalBooleanRadioSelectComponent,
  PhoneNumberInputComponent,
  RadioGroupComponent,
  SimplePhoneNumberInputComponent,
  ValidationErrorMessageComponent,
];


@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    CommonPipesModule,
    CommonDirectivesModule,

    // Components
    AutoCompleteModule,
    CalendarModule,
    CheckboxModule,
    TableModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    SharedModule,
    SelectButtonModule,
    ButtonModule,
    MessagesModule,
    OverlayPanelModule,
    PanelModule,
    PaginatorModule,
    ProgressBarModule,
    RadioButtonModule,
    TieredMenuModule,
    TabMenuModule,
    TreeTableModule,
    MainCountryModule,
    SlideMenuModule,
    StoredFileModule,
  ],
  declarations: [
    ...DECLARATIONS,
  ],
  exports: [
    ...DECLARATIONS,

    CommonPipesModule,
    CommonDirectivesModule,
  ],
})
export class MainModule {
}
