import {LoginComponent} from './shell/login/login.component';
import {AccountCreationRouteComponent} from './shell/account-creation-route/account-creation-route.component';
import {UserLoggedInRouterGuard} from './main/services/user-logged-in.guard';
import {AppModuleLoaderGuard} from './main/services/app-module-loader-guard';
import {ApplicationMenuData} from './application-menu-data';
import {ApplicationRoute} from './main/routing/application-route';

export const ApplicationRoutes: any & Record<string, ApplicationRoute> = {

  login: {
    path: 'login',
    component: LoginComponent,
    data: {
      menuData: ApplicationMenuData.login,
    },
  },

  register: {
    path: 'register',
    canActivate: [UserLoggedInRouterGuard],
    component: AccountCreationRouteComponent,
    data: {
      menuData: ApplicationMenuData.register,
    },
    runGuardsAndResolvers: 'always',
    resolve: {
      // inviteCode: UserInviteResolver,
    },
  },

  welcome: {
    path: 'welcome',
    canActivate: [UserLoggedInRouterGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule),
    data: {
      menuData: ApplicationMenuData.welcome,
    },
  },

  customer: {
    path: 'customer',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./customer/customer.module').then(mod => mod.CustomerModule),
    data: {
      menuData: ApplicationMenuData.customer,
    },
    runGuardsAndResolvers: 'always',
  },

  contact: {
    path: 'contact',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./contact/contact.module').then(mod => mod.ContactModule),
    data: {
      menuData: ApplicationMenuData.contact,
    },
    runGuardsAndResolvers: 'always',
  },

  credit: {
    path: 'credit',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./credit/credit.module').then(mod => mod.CreditModule),
    data: {
      menuData: ApplicationMenuData.credit,
    },
    runGuardsAndResolvers: 'always',
  },

  creditProvider: {
    path: 'creditProvider',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./credit-provider/credit-provider.module').then(mod => mod.CreditProviderModule),
    data: {
      menuData: ApplicationMenuData.creditProvider,
    },
    runGuardsAndResolvers: 'always',
  },

  creditSubscription: {
    path: 'creditSubscription',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./credit-subscription/credit-subscription.module').then(mod => mod.CreditSubscriptionModule),
    data: {
      menuData: ApplicationMenuData.creditSubscription,
    },
    runGuardsAndResolvers: 'always',
  },

  insurance: {
    path: 'insurance',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./insurance/insurance.module').then(mod => mod.InsuranceModule),
    data: {
      menuData: ApplicationMenuData.insurance,
    },
    runGuardsAndResolvers: 'always',
  },

  insuranceCategory: {
    path: 'insuranceCategory',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./insurance-category/insurance-category.module').then(mod => mod.InsuranceCategoryModule),
    data: {
      menuData: ApplicationMenuData.insuranceCategory,
    },
    runGuardsAndResolvers: 'always',
  },

  insuranceProvider: {
    path: 'insuranceProvider',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./insurance-provider/insurance-provider.module').then(mod => mod.InsuranceProviderModule),
    data: {
      menuData: ApplicationMenuData.insuranceProvider,
    },
    runGuardsAndResolvers: 'always',
  },

  insuranceSubscription: {
    path: 'insuranceSubscription',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./insurance-subscription/insurance-subscription.module').then(mod => mod.InsuranceSubscriptionModule),
    data: {
      menuData: ApplicationMenuData.insuranceSubscription,
    },
    runGuardsAndResolvers: 'always',
  },

  investment: {
    path: 'investment',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./investment/investment.module').then(mod => mod.InvestmentModule),
    data: {
      menuData: ApplicationMenuData.investment,
    },
    runGuardsAndResolvers: 'always',
  },

  investmentProvider: {
    path: 'investmentProvider',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./investment-provider/investment-provider.module').then(mod => mod.InvestmentProviderModule),
    data: {
      menuData: ApplicationMenuData.investmentProvider,
    },
    runGuardsAndResolvers: 'always',
  },

  investmentSubscription: {
    path: 'investmentSubscription',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./investment-subscription/investment-subscription.module').then(mod => mod.InvestmentSubscriptionModule),
    data: {
      menuData: ApplicationMenuData.investmentSubscription,
    },
    runGuardsAndResolvers: 'always',
  },

  mail: {
    path: 'mail',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./mail/mail.module').then(mod => mod.MailModule),
    data: {
      menuData: ApplicationMenuData.mail,
    },
    runGuardsAndResolvers: 'always',
  },

  messageTemplate: {
    path: 'messageTemplate',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./message-template/message-template.module').then(mod => mod.MessageTemplateModule),
    data: {
      menuData: ApplicationMenuData.messageTemplate,
    },
    runGuardsAndResolvers: 'always',
  },

  preference: {
    path: 'preference',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./preference/app-preference.module').then(mod => mod.AppPreferenceModule),
    data: {
      menuData: ApplicationMenuData.preference,
    },
    runGuardsAndResolvers: 'always',
  },
  personTitle: {
    path: 'personTitle',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./person-title/person-title.module').then(mod => mod.PersonTitleModule),
    data: {
      menuData: ApplicationMenuData.personTitle,
    },
    runGuardsAndResolvers: 'always',
  },

  signingPackage: {
    path: 'signingPackage',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./signing-package/signing-package.module').then(mod => mod.SigningPackageModule),
    data: {
      menuData: ApplicationMenuData.signingPackage,
    },
    runGuardsAndResolvers: 'always',
  },

  emailAccount: {
    path: 'emailAccount',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./email-account/email-account.module').then(mod => mod.EmailAccountModule),
    data: {
      menuData: ApplicationMenuData.emailAccount,
    },
    runGuardsAndResolvers: 'always',
  },


  subscription: {
    path: 'subscription',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./subscription/subscription.module').then(mod => mod.SubscriptionModule),
    data: {
      menuData: ApplicationMenuData.subscription,
    },
    runGuardsAndResolvers: 'always',
  },

  smtpAccount: {
    path: 'smtpAccount',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./smtp-account/smtp-account.module').then(mod => mod.SmtpAccountModule),
    data: {
      menuData: ApplicationMenuData.smtpACcount,
    },
    runGuardsAndResolvers: 'always',
  },


  trustee: {
    path: 'trustee',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./trustee/trustee.module').then(mod => mod.TrusteeModule),
    data: {
      menuData: ApplicationMenuData.trustee,
    },
    runGuardsAndResolvers: 'always',
  },


  user: {
    path: 'user',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./user/user.module').then(mod => mod.UserModule),
    data: {
      menuData: ApplicationMenuData.user,
    },
    runGuardsAndResolvers: 'always',
  },

  eventRecord: {
    path: 'eventRecord',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./event-record/event-record.module').then(mod => mod.EventRecordModule),
    data: {
      menuData: ApplicationMenuData.eventRecord,
    },
    runGuardsAndResolvers: 'always',
  },

  batch: {
    path: 'batch',
    canActivate: [UserLoggedInRouterGuard],
    canLoad: [AppModuleLoaderGuard],
    loadChildren: () => import('./batch/batch.module').then(mod => mod.BatchModule),
    data: {
      menuData: ApplicationMenuData.batches,
    },
    runGuardsAndResolvers: 'always',
  },

};
