import {Injectable} from '@angular/core';
import {RequestService} from '../../service/request.service';
import {AuthProvider} from '../../domain/auth/auth-provider';
import {WsCustomerInvite, WsCustomerInviteCode, WsTrusteeInvite, WsTrusteeInviteCode} from '@lifeislife/lifeislife-ws-api';
import {Observable} from 'rxjs';
import {FrontendAppConfigKey} from '../../../domain/config/frontend-app-config-key';
import {AppConfigService} from '../../../service/config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class UserInviteWsClient {


  constructor(private requestService: RequestService,
              private authProvider: AuthProvider,
              private appConfigService: AppConfigService,
  ) {
  }

  inviteTrusteeContact$(wsInvite: WsTrusteeInvite): Observable<WsTrusteeInviteCode> {
    const auth = this.authProvider.getAuth();
    const url = `${this.getResourceUri()}/trustee`;

    return this.requestService.sendRequest({
      method: 'POST', url: url, body: wsInvite,
    }, auth);
  }

  inviteCustomer$(wsInvite: WsCustomerInvite): Observable<WsCustomerInviteCode> {
    const auth = this.authProvider.getAuth();
    const url = `${this.getResourceUri()}/customer`;

    return this.requestService.sendRequest({
      method: 'POST', url: url, body: wsInvite,
    }, auth);
  }

  private getResourceUri() {
    const wsUri = this.appConfigService.getCurrentConfigValue(FrontendAppConfigKey.lifeislife_ws_uri);
    const resourceUrl = `${wsUri}/front/invite`;
    return resourceUrl;
  }
}
