import {ContactConverter} from '../contact/contact.converter';
import {WsCustomerContactType, WsCustomerInvite, WsTrusteeInvite} from '@lifeislife/lifeislife-ws-api';
import {TrusteeInvite} from '../../domain/invite/trustee-invite';
import {CustomerInvite} from '../../domain/invite/customer-invite';
import {CustomerContactType} from '../../domain/customer-contact/customer-contact-type';
import {TrusteeContactTypeConverter} from '../trustee/trustee-contact-type.converter';

export class UserInviteConverter {

  static convertTrusteeInviteIn(value: WsTrusteeInvite): TrusteeInvite {
    if (value == null) {
      return undefined;
    }
    return {
      contact: ContactConverter.toContact(value.contact),
      inviteMessage: value.inviteMessage,
      contactType: TrusteeContactTypeConverter.convertIn(value.trusteeContactType),
      trusteeRef: value.trusteeRef,
    };
  }

  static convertTrusteeInviteOut(value: TrusteeInvite): WsTrusteeInvite {
    if (value == null) {
      return undefined;
    }
    return {
      contact: ContactConverter.toWsContact(value.contact),
      inviteMessage: value.inviteMessage,
      trusteeContactType: TrusteeContactTypeConverter.convertOut(value.contactType),
      trusteeRef: value.trusteeRef,
    };
  }

  static getTrusteeInviteValidationPropertyNameMappings(): { [M in keyof WsTrusteeInvite]: keyof TrusteeInvite } {
    return {
      contact: 'contact',
      inviteMessage: 'inviteMessage',
      trusteeContactType: 'contactType',
      trusteeRef: 'trusteeRef',
    };
  }

  static convertCustomerInviteIn(value: WsCustomerInvite): CustomerInvite {
    if (value == null) {
      return undefined;
    }
    return {
      contact: ContactConverter.toContact(value.contact),
      inviteMessage: value.inviteMessage,
      contactType: value.customerContactType as string as CustomerContactType,
      customerRef: value.customerRef,
      sendInviteMail: value.sendInviteMail,
    };
  }

  static convertCustomerInviteOut(value: CustomerInvite): WsCustomerInvite {
    if (value == null) {
      return undefined;
    }
    return {
      contact: ContactConverter.toWsContact(value.contact),
      inviteMessage: value.inviteMessage,
      customerContactType: value.contactType as string as WsCustomerContactType,
      customerRef: value.customerRef,
      sendInviteMail: value.sendInviteMail,
    };
  }

  static getCustomerInviteValidationPropertyNameMappings(): { [M in keyof WsCustomerInvite]: keyof CustomerInvite } {
    return {
      contact: 'contact',
      inviteMessage: 'inviteMessage',
      customerContactType: 'contactType',
      customerRef: 'customerRef',
      sendInviteMail: 'sendInviteMail',
    };
  }


}
