<footer class="lifeislife-footer">
  <div class="footer-content">
    <div class="logo-footer">
    </div>
    <div class="main-footer">
      <div class="footer-address">
        <span>
          <i class="fa fa-map-signs" aria-hidden="true"></i>
          {{ companyAddress$ | async }}
        </span>

        <span>
          <i class="fa fa-phone" aria-hidden="true"></i>
          <a [href]="'tel:' + (companyPhone$ | async)">
            {{ companyPhone$ | async }}
          </a>
        </span>

        <span>
          <i class="fa fa-paper-plane" aria-hidden="true"></i>
          <a [href]="'mailto:'+ (mailSupportAddress$ | async)">
            {{ mailSupportAddress$ | async }}
          </a>
        </span>

        <span>
          <i class="fa fa-building" aria-hidden="true"></i>
          BCE et agrément FSMA : {{ (companyVat$ | async) }}
        </span>
      </div>
      <div class="separator"></div>
      <div class="footer-legals">
        <span>© LifeisLife. Tous droits réservés</span>

        <a [href]="privacyPolicyUri$ | async">Privacy Policy</a>

        <span *ngIf="versionString">Version {{versionString}}</span>
      </div>
    </div>
  </div>
</footer>
