import {
  WsInsuranceBranch,
  WsInsuranceSubscriptionSearch,
  WsInsuranceSubscriptionStatus,
  WsInsuranceSubscriptionStatusSearch,
} from '@lifeislife/lifeislife-ws-api';
import {EnumConverterUtils} from '../../client/private_util/enum-converter-utils';
import {InsuranceConverter} from './insurance-converter';
import {SubscriptionConverter} from '../corebusiness/subscription-converter';
import {InsuranceSubscriptionStatus} from '../../domain/insurance/insurance-subscription-status';
import {InsuranceSubscriptionSearch} from '../../domain/insurance/insurance-subscription-search';
import {InsuranceSubscriptionStatusSearch} from '../../domain/insurance/insurance-subscription-status-search';
import {InsuranceBranch} from '../../domain/insurance/insurance-branch';
import {CustomerContactSearchConverter} from '../customer-contact/customer-contact-search.converter';

export class InsuranceSubscriptionSearchConverter {

  static convertFilterIn(value?: WsInsuranceSubscriptionSearch): InsuranceSubscriptionSearch {
    if (value == null) {
      return undefined;
    }
    return {
      insuranceSubscriptionStatusSearch: this.convertStatusSearchIn(value.wsInsuranceSubscriptionStatusSearch),
      subscriptionSearch: SubscriptionConverter.convertFilterIn(value.subscriptionSearch),
      insuranceSubscriptionRef: value.insuranceSubscriptionWsRef,
      insuranceSearch: InsuranceConverter.convertFilterIn(value.insuranceSearch),
      anyBranch: value.anyBranch == null ? null : value.anyBranch
        .map(wsBranch => EnumConverterUtils.convertValue(wsBranch, InsuranceBranch)),
      insuredCustomerContactSearch: CustomerContactSearchConverter.toCustomerContactSearch(value.insuredCustomerContactSearch),
    };
  }

  static convertFilterOut(value: InsuranceSubscriptionSearch): WsInsuranceSubscriptionSearch {
    if (value == null) {
      return undefined;
    }
    return {
      wsInsuranceSubscriptionStatusSearch: this.convertStatusSearchOut(value.insuranceSubscriptionStatusSearch),
      subscriptionSearch: SubscriptionConverter.convertFilterOut(value.subscriptionSearch),
      insuranceSubscriptionWsRef: value.insuranceSubscriptionRef,
      insuranceSearch: InsuranceConverter.convertFilterOut(value.insuranceSearch),
      anyBranch: value.anyBranch == null ? null : value.anyBranch
        .map(branch => EnumConverterUtils.convertValue(branch, WsInsuranceBranch)),
      insuredCustomerContactSearch: CustomerContactSearchConverter.toWsCustomerContactSearch(value.insuredCustomerContactSearch),
    };
  }

  static convertStatusSearchIn(value: WsInsuranceSubscriptionStatusSearch): InsuranceSubscriptionStatusSearch {
    if (value == null) {
      return undefined;
    }
    return {
      allActives: value.allActives,
      allVisibleToCustomers: value.allVisibleToCustomers,
      anyStatusList: value.anyStatusList == null ? null : value.anyStatusList
        .map(status => EnumConverterUtils.convertValue(status, WsInsuranceSubscriptionStatus)),
    };
  }

  static convertStatusSearchOut(value: InsuranceSubscriptionStatusSearch): WsInsuranceSubscriptionStatusSearch {
    if (value == null) {
      return undefined;
    }
    return {
      allActives: value.allActives,
      allVisibleToCustomers: value.allVisibleToCustomers,
      anyStatusList: value.anyStatusList == null ? null : value.anyStatusList
        .map(status => EnumConverterUtils.convertValue(status, InsuranceSubscriptionStatus)),
    };
  }

}
