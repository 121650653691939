import {Injectable} from '@angular/core';
import {
  WsConstraintViolation,
  WsRef,
  WsSigningPackageType,
  WsSubscriptionSigningPackage,
  WsSubscriptionSigningPackageRequest,
} from '@lifeislife/lifeislife-ws-api';
import {forkJoin, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {SimplePagination} from '../../util/pagination/simple-pagination';
import {SearchResult} from '../../client/domain/search/search-result';
import {ValidationResult} from '../../domain/shared/validation-result';
import {ObjectConverterUtil} from '../object-converter-util';
import {isValidRef, Ref} from '../../domain/shared/ref';
import {SubscriptionSigningPackageWsClient} from '../../client/resources/front/corebusiness/subscription-signing-package-ws-client';
import {SubscriptionSigningPackageConverter} from './subscription-signing-package.converter';
import {SubscriptionSigningPackage} from '../../domain/corebusiness/subscription-signing-package';
import {SubscriptionSigningPackageSearch} from '../../domain/corebusiness/subscription-signing-package-search';
import {SigningPackage} from '../../domain/file/signing-package';
import {SigningPackageService} from '../file/signing-package.service';
import {Subscription} from '../../domain/corebusiness/subscription';
import {SigningPackageRequest} from '../../domain/file/signing-package-request';
import {SimplePaginationUtils} from '../../util/pagination/simple-pagination-utils';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionSigningPackageService {


  constructor(private client: SubscriptionSigningPackageWsClient,
              private signingPackageService: SigningPackageService,
  ) {
  }

  createSubscriptionSigningPackage$(subscriptoinRef: Ref<Subscription>,
                                    request: SigningPackageRequest,
  ): Observable<Ref<SubscriptionSigningPackage>> {
    const req: WsSubscriptionSigningPackageRequest = {
      subscriptionWsRef: subscriptoinRef,
      signingPackageRequest: {
        description: request.description,
        fileRefs: request.files,
        signersContactRefs: request.signers,
        packageType: WsSigningPackageType.DEFAULT,
        wsCustomerWsRef: undefined,
        sendMailInvites: request.notifySigners,
      },
    };
    return this.client.createPackage(req);
  }

  getSubscriptionSigningPackage$(ref: Ref<SubscriptionSigningPackage>): Observable<SubscriptionSigningPackage> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doGet(ref.id).pipe(
      map(value => SubscriptionSigningPackageConverter.convertIn(value)),
    );
  }

  searchSubscriptionsForPackage$(signingPackageRef: Ref<SigningPackage>): Observable<SubscriptionSigningPackage[]> {
    if (signingPackageRef == null) {
      return of([]);
    }
    return this.searchSubscriptionSigningPackages$({
      subscriptionSearch: {
        productSearch: {},
      },
      signingPackageSearch: {
        exactPackageWsRef: {id: signingPackageRef.id},
      },
    }, SimplePaginationUtils.newPagination(50))
      .pipe(map(r => r.list));
  }

  searchSubscriptionSigningPackages$(subscriptionSigningPackageFilter: SubscriptionSigningPackageSearch, pagination: SimplePagination)
    : Observable<SearchResult<SubscriptionSigningPackage>> {
    const wsSearch = SubscriptionSigningPackageConverter.convertFilterOut(subscriptionSigningPackageFilter);
    return this.client.doSearch(wsSearch, pagination).pipe(
      switchMap(results => this.convertSearchResultsIn$(results)),
    );
  }

  validateSubscriptionSigningPackage$(subscriptionSigningPackage: SubscriptionSigningPackage)
    : Observable<ValidationResult<SubscriptionSigningPackage>> {
    const wsSubscriptionSigningPackage = SubscriptionSigningPackageConverter.convertOut(subscriptionSigningPackage);
    return this.client.doValidate(wsSubscriptionSigningPackage).pipe(
      map((results: WsSubscriptionSigningPackage | WsConstraintViolation[]) => this.createValidationResult(results)),
    );
  }

  saveSubscriptionSigningPackage$(subscriptionSigningPackage: SubscriptionSigningPackage): Observable<any> {
    const wsSubscriptionSigningPackage = SubscriptionSigningPackageConverter.convertOut(subscriptionSigningPackage);
    return this.client.doUpdate(wsSubscriptionSigningPackage);
  }

  deleteSubscriptionSigningPackage$(ref: Ref<SubscriptionSigningPackage>): Observable<any> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }
    return this.client.doRemove(ref.id);
  }

  getSigningPackage$(ref: Ref<SubscriptionSigningPackage>): Observable<SigningPackage> {
    if (!isValidRef(ref)) {
      return throwError(new Error('Invalid ref'));
    }

    return this.client.doGet(ref.id).pipe(
      switchMap(s => this.signingPackageService.getSigningPackage$(s.signingPackageWsRef)),
    );
  }

  serializeFilter(subscriptionSigningPackageFilter: SubscriptionSigningPackageSearch): string {
    ObjectConverterUtil.cleanUnsetFilterValues(subscriptionSigningPackageFilter);
    const wsSubscriptionSigningPackageSearch = SubscriptionSigningPackageConverter.convertFilterOut(subscriptionSigningPackageFilter);
    return JSON.stringify(wsSubscriptionSigningPackageSearch);
  }


  deserializeFilter(valueString: string | null): SubscriptionSigningPackageSearch | null {
    if (valueString == null) {
      return null;
    }
    try {
      const wsSearch: any = JSON.parse(valueString);
      const filter = SubscriptionSigningPackageConverter.convertFilterIn(wsSearch);
      return filter;
    } catch (parseError) {
      return null;
    }
  }

  private createValidationResult(results: WsSubscriptionSigningPackage | WsConstraintViolation[])
    : ValidationResult<SubscriptionSigningPackage> {
    return ObjectConverterUtil.createValidationResult(results, {
      converter: wsSubscriptionSigningPackage => SubscriptionSigningPackageConverter.convertIn(wsSubscriptionSigningPackage),
      propertyNameMappings: SubscriptionSigningPackageConverter.getValidationPropertyNameMappings(),
    });
  }

  private convertSearchResultsIn$(results: SearchResult<WsRef<WsSubscriptionSigningPackage>>)
    : Observable<SearchResult<SubscriptionSigningPackage>> {
    const rowTasks = results.list.map(ref => this.getSubscriptionSigningPackage$(ref));
    return rowTasks.length === 0 ? of(new SearchResult<SubscriptionSigningPackage>()) : forkJoin(rowTasks).pipe(
      map(rows => Object.assign({}, results, <Partial<SearchResult<SubscriptionSigningPackage>>>{
          list: rows,
        }),
      ));
  }

}
